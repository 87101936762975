import Button from 'components/Button';
import { Row } from 'components/Layout';
import LoadingIndicator from 'components/LoadingIndicator';
import ProjectCard from 'components/ProjectCard';
import Searchbar from 'components/Searchbar';
// import faker from 'faker';
import useClickOutside from 'hooks/useClickOutside';
import React, { useCallback, useRef, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { ajax } from 'utils/ajax';
import querystring from 'querystring';
import {
  FilterResults,
  RecommendedResearchers,
  ResearcherContainer,
  ResultsContainer,
  ResultsContainerContent,
  SubContainer,
  ModalContent,
  ModalMsg,
  ModalButton,
} from './styled';
import { FaDollarSign, FaMedal, FaStar } from 'react-icons/fa';
import { Container } from 'pages/ShareProject';
import { RealtimeContainer } from 'pages/ResearcherList/styled';
import Modal from 'components/Modal';

// let data = {
//   projects: [
//     // {
//     //   id: 1,
//     //   title: 'Marketing Trends for Beauty SKUs',
//     //   description:
//     //     'Discover the biggest marketing trends in the beauty industry plus examples from leading beauty brands to inspire your own marketing strategy.',
//     //   researcher: {
//     //     name: 'Emmanuel Louis Sanchez',
//     //     title: 'Market Researcher',
//     //   },
//     //   tags: ['Retailer Understanding', 'Future/Trends'],
//     //   price: 500,
//     // },
//     // {
//     //   id: 2,
//     //   title: 'Cosmetics and Makeup Industry | Trends & Research',
//     //   description:
//     //     'Understand consumer behavior and emerging trends in the prestige beauty industry, at the category-, geographic-, and store-level.',
//     //   researcher: {
//     //     name: 'Marie Williams',
//     //     title: 'Market Researcher',
//     //   },
//     //   tags: ['Future/Trends'],
//     //   price: 500,
//     // },
//     // {
//     //   id: 3,
//     //   title: '13 Trends Shaping the Face of Beauty in 2018',
//     //   description:
//     //     'From major beauty brands investing in augmented reality to an industry-wide push towards customized beauty products and experiences, technology-backed cosmetics… brands will transform the sector in 2018.',
//     //   researcher: {
//     //     name: 'Raphaelle Schroeder',
//     //     title: 'Market & Algorithmic Researcher',
//     //   },
//     //   tags: ['Future/Trends'],
//     //   price: 500,
//     // },
//   ],
//   researchers: [
//     {
//       id: 1,
//       img: faker.image.avatar(),
//       name: 'Wilfrid Predovic',
//       title: 'Market Researcher',
//     },
//     {
//       id: 2,
//       img: faker.image.avatar(),
//       name: 'Dena Bergnaum',
//       title: 'Algorithmic Researcher',
//     },
//     {
//       id: 3,
//       img: faker.image.avatar(),
//       name: 'Stanley Lehner',
//       title: 'Market & Algorithmic Researcher',
//     },
//   ],
// };

const SearchResultsModal = ({
  searchInput,
  searchResults,
  visible,
  onClose,
  isLoggedIn,
  viewAllQuery,
  setViewAllQuery,
  setSearchInput,
}) => {
  const history = useHistory();
  const containerRef = useRef(null);
  const containerBox = containerRef.current
    ? containerRef.current.getBoundingClientRect()
    : {};
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false);
  const [searchResearchers, setSearchResearchers] = useState([]);
  const [researchers, setResearchers] = useState([]);
  const [flag, setFlag] = useState(false);
  // const [isOpen, setIsOpen] = useState(false);
  const [realtimeSearch, setRealtimeSearch] = useState([]);
  const [realtimeOptions, setRealtimeOptions] = useState([]);
  const [isFocus, setFocus] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    ajax('GET', '/member_reference_dropdown').then(res => {
      if (res.code === 200) {
        let arr = [];
        res.data.analytics_research.map(item => {
          arr.push({ ...item, type: 'specialization' });
        });
        res.data.market_research.map(item => {
          arr.push({ ...item, type: 'specialization' });
        });
        res.data.industry.map(item => {
          arr.push({ ...item, type: 'industry' });
        });
        setRealtimeOptions(arr);
      }
    });
  }, []);

  // const handleRealTimeSearch = search => {
  //   const realtimeArray = [];
  //   const realtimeArrayChecker = [];
  //   if (search !== '') {
  //     realtimeOptions.map(item => {
  //       if (
  //         item.name &&
  //         item.name.toLowerCase().includes(search.toLowerCase()) &&
  //         !realtimeArrayChecker.includes(item.name.toLowerCase())
  //       ) {
  //         realtimeArrayChecker.push(item.name);
  //         realtimeArray.push(item);
  //       }
  //       setRealtimeSearch(realtimeArray);
  //     });
  //   } else setRealtimeSearch([]);
  // };

  useClickOutside(
    containerRef,
    visible,
    useCallback(() => {
      onClose();
      console.log('567');
      localStorage.setItem('searchInput', '');
      setSearchInput('');
    }, [onClose])
  );

  const getResearchers = useCallback(page => {
    setFlag(false);
    ajax(
      'GET',
      `/member/researcher?${querystring.stringify({ page_size: 3, page })}`
    ).then(response => {
      if (response.code === 200 && response.data) {
        setResearchers(response.data);
        setFlag(true);
      } else if (response.code === 401) {
        setFlag(true);
        setLoading(false);
        // setIsOpen(true);
      } else {
        setFlag(true);
        setLoading(false);
      }
    });
  }, []);

  const setdataObject = dataResearcher => {
    const dataToSort = [...dataResearcher];
    dataToSort.sort((a, b) => (a.member.id > b.member.id ? 1 : -1));
    setResearchers(dataToSort);
  };

  useEffect(() => {
    setdataObject(researchers);
  }, [flag]);

  const parseSearchResults = results => {
    let initialResearcher = [];
    const res = results.map(result => {
      const searchResearcher = {
        id: result.member.id,
        full_name: result.member.full_name,
        address: `${result.member.city ? result.member.city : '-'}, ${
          result.member.state ? result.member.state : '-'
        }, ${result.member.country ? result.member.country : '-'}`,
        customer_ratings: result.member.customer_ratings,
        mastery_score: result.member.mastery_score,
        minimum_engagement: result.member.minimum_engagement,
      };

      initialResearcher.push(searchResearcher);
      return searchResearcher;
    });
    setSearchResearchers(initialResearcher);
  };

  const handleSearch = (search, filter) => {
    localStorage.removeItem('searchResults');
    setLoading(true);
    let query = {
      page_size: 5,
      page: 1,
      search: search,
    };

    if (filter && filter.type === 'specialization')
      query['specialization'] = filter.id;
    else if (filter && filter.type === 'industry')
      query['industry'] = filter.id;

    ajax('GET', `/member/researcher?${querystring.stringify(query)}`).then(
      result => {
        setViewAllQuery(query);
        setLoading(false);
        if (result.code === 200) {
          parseSearchResults(result.data);
        }
      }
    );
  };

  useEffect(() => {
    localStorage.setItem('searchResults', JSON.stringify(searchResults));
    parseSearchResults(searchResults);
  }, [searchResults]);

  useEffect(() => {
    localStorage.setItem('searchInput', searchInput);
    setSearch(searchInput);
  }, [searchInput]);

  useEffect(() => {
    getResearchers(1);
  }, []);

  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        onClose();
        localStorage.setItem('searchInput', '');
        setSearchInput('');
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [containerRef]);
  return (
    <ResultsContainerContent>
      {loading ? <LoadingIndicator /> : null}
      <ResultsContainer
        ref={containerRef}
        visible={visible}
        top={visible ? 64 : -containerBox.height || 0}
      >
        <div style={{ flex: 3, position: 'relative' }}>
          <Searchbar
            value={search}
            onChange={text => {
              setFocus(true);
              // handleRealTimeSearch(text);
              setSearch(text);
            }}
            onKeyPress={event => {
              if (event === 'Enter') {
                handleSearch(search);
              }
            }}
          />
          {isFocus && (
            <Container
              style={{
                minHeight: '5rem',
                maxHeight: '26rem',
                background: '#fff',
                position: 'absolute',
                zIndex: '99',
                width: 'fit-content',
                marginTop: 0,
              }}
            >
              {realtimeSearch.map((item, index) => {
                return (
                  <RealtimeContainer key={index}>
                    <div
                      style={{
                        color: '#4B4C5E',
                        fontSize: '1.3rem',
                        fontWeight: 'bold',
                        marginBottom: '1rem',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        setFocus(false);
                        setSearch('');

                        handleSearch('', item);
                      }}
                    >
                      {item.name}
                    </div>
                  </RealtimeContainer>
                );
              })}
            </Container>
          )}
        </div>
        <SubContainer>
          <Row justifyContent="space-between" alignItems="center">
            {searchResearchers.length > 0 ? (
              <p>We found these researchers to your search</p>
            ) : (
              <p>No results to show</p>
            )}
            <FilterResults
              onClick={() =>
                history.push({
                  pathname: `/researchers`,
                  state: { query: viewAllQuery },
                })
              }
            >
              FILTER RESULTS
            </FilterResults>
          </Row>
          <div>
            {searchResearchers.map(res => (
              <ProjectCard
                compact
                compressed
                key={res.id}
                {...res}
                onView={() => {
                  if (localStorage.getItem('isResearcher') === 'true') {
                    setModalOpen(true);
                  } else {
                    history.push(`/researcher/${res.id}`);
                  }
                }}
              />
            ))}
          </div>
          {isLoggedIn && !searchResearchers.length ? (
            <div>
              <RecommendedResearchers>
                Recommended Researchers
              </RecommendedResearchers>
              <Row
                justifyContent="space-between"
                alignItems="center"
                style={{ marginBottom: '2rem' }}
              >
                {researchers.map(researcher => (
                  <ResearcherContainer key={researcher.id} compressed>
                    <img src={researcher.img} alt="" />
                    <h6>{researcher.member.full_name}</h6>
                    <p>
                      {researcher.member.city}, {researcher.member.state},{' '}
                      {researcher.member.country}
                    </p>
                    <p style={{ marginTop: '5px' }}>
                      <span style={{ margin: '0 5px' }}>
                        <FaMedal /> {researcher.member.mastery_score}
                      </span>
                      <span style={{ margin: '0 5px' }}>
                        <FaDollarSign />
                        {researcher.member.minimum_engagement}
                      </span>
                      <span style={{ margin: '0 5px' }}>
                        <FaStar />
                        {researcher.member.customer_ratings > 0
                          ? researcher.member.customer_ratings
                          : '-'}
                      </span>
                    </p>
                  </ResearcherContainer>
                ))}
              </Row>
            </div>
          ) : (
            <div style={{ marginBottom: '2rem' }}> </div>
          )}
          <Modal visible={!!isModalOpen} setVisible={setModalOpen}>
            <ModalContent>
              <ModalMsg>Please login as a client to view researchers</ModalMsg>
              <ModalButton>
                <Button fill onClick={() => setModalOpen(false)}>
                  Ok
                </Button>
              </ModalButton>
            </ModalContent>
          </Modal>
          {localStorage.getItem('isResearcher') === 'false' && (
            <Button
              outline
              onClick={() =>
                history.push({
                  pathname: `/researchers`,
                  state: { query: viewAllQuery },
                })
              }
            >
              VIEW ALL RESEARCHERS
            </Button>
          )}
        </SubContainer>
      </ResultsContainer>
    </ResultsContainerContent>
  );
};

export default SearchResultsModal;
