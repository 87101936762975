import React, { useState, useEffect, useLayoutEffect } from 'react';
import {
  FaFilePdf,
  FaPencilAlt,
  FaEnvelope,
  FaProjectDiagram,
} from 'react-icons/fa';
import {
  ProjectContent,
  ProjectTitle,
  ProjectUserDetails,
  ProjectActionMenuRow,
  ProjectActionMenuItem,
  ProjectActionMenuButton,
  DownloadPDFPopupContainer,
  DownloadPDFPopup,
  DLPDFCheckbox,
  ProjectContainer,
  ProjectNavbarButton,
  ProjectNavbar,
  ProjectDetailsContainer,
  DeleteProjectButton,
  ShareProjectButton,
  EditProjectButton,
  DeliverablesContainer,
  RequirementsContainer,
  SPReactTooltip,
} from './styled';
import Breadcrumb from 'components/Breadcrumb';
import Button from 'components/Button';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import { ajax } from 'utils/ajax';
import { useParams } from 'react-router-dom';
import LoadingIndicator from 'components/LoadingIndicator';
import { useHistory } from 'react-router-dom';
// import { useAuthDispatch, useAuthState } from 'context/AuthContext';
import { Details } from './Details';
import { Deliverables } from './Deliverables';
import { Requirements } from './Requirements';
import Modal from 'components/Modal';
import SessionModal from 'components/SessionModal';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import './style.css';
import mess from './mess.svg';
import BrowsePlanModal from 'components/BrowsePlanModal';
import { useAuthState } from 'context/AuthContext';
const DL_PDF_MODES = {
  ALL: 'all',
  PROJECT_DETAILS: 'project details',
  DELIVERABLES: 'deliverables',
  RESEARCHER_REQUIREMENTS: 'researcher requirements',
};

const DL_DELIVERABLE_PDF_MODES = {
  ATTACHMENTS: 'attachments',
};

export const Project = () => {
  const { isMemberSubscribed } = useAuthState();
  const [isBrowseModal, setIsBrowseModal] = useState(false);

  const history = useHistory();
  const params = useParams();
  const [project, setProject] = useState({});
  const [projectMastery, setProjectMastery] = useState({});
  const [user, setUser] = useState({});
  const [tab, setTab] = useState('Details');
  const [loading, setLoading] = useState(false);
  const [dlPDFPopupOpen, setDlPDFPopupOpen] = useState(false);
  const [pdfMode, setPDFMode] = useState(DL_PDF_MODES.ALL);
  const [pdfDeliverableMode, setPDFDeliverableMode] = useState([]);
  const user_id = parseInt(localStorage.getItem('br-userId'));
  const [isShowingModal, setIsShowingModal] = useState(null);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [memberId, setMemberId] = useState();
  const [userDetail, setUserDetail] = useState({});
  const memberType = parseInt(localStorage.getItem('br-memberType'));

  const handleChangePDFMode = value => {
    setPDFMode(value);
    setPDFDeliverableMode([]);
  };

  const handleChangePDFDeliverableMode = value => {
    if (pdfMode === DL_PDF_MODES.DELIVERABLES) {
      if (pdfDeliverableMode.includes(value)) {
        setPDFDeliverableMode(
          pdfDeliverableMode.filter(item => item !== value)
        );
      } else {
        setPDFDeliverableMode([...pdfDeliverableMode, value]);
      }
    } else {
      setPDFDeliverableMode([]);
    }
  };

  useEffect(() => {
    (async () => {
      const userId = localStorage.getItem('br-userId');
      if (userId) {
        const res = await ajax('GET', `/member/${userId}`);

        if (res.code === 200) {
          setUserDetail(res?.data?.member);
        }
      }
    })();
  }, []);

  const handleSendMessage = async () => {
    const projectOwnerId = memberId;
    const researcherId = parseInt(localStorage.getItem('br-userId'));
    localStorage.setItem('flag', 1);
    localStorage.setItem('project_id', 0);
    let userData = {};
    const userId = localStorage.getItem('br-userId');
    if (userId) {
      const res = await ajax('GET', `/member/${userId}`);
      if (res.code === 200) {
        userData = res.data;
      } else {
        localStorage.removeItem('br-authToken');
        localStorage.removeItem('br-userId');
        localStorage.removeItem('isResearcher');
      }
    }
    history.push('/messages', {
      conversation_id: user?.conversation_id,
      receiver_id: parseInt(projectOwnerId),
      receiver: user,
      sender: userData?.member,
      sender_id: researcherId,
      project_id: parseInt(params.id),
    });
  };

  const handleSendProposal = async () => {
    const projectOwnerId = memberId;
    const researcherId = parseInt(localStorage.getItem('br-userId'));
    localStorage.setItem('project_id', project?.id);
    localStorage.setItem('project_name', project?.title);
    localStorage.setItem('endDate', project?.end_date);

    localStorage.setItem('sender_id', parseInt(projectOwnerId));

    if (user.conversation_id && user.conversation_id !== undefined) {
      history.push('/messages', {
        conversation_id: user?.conversation_id,
        receiver_id: parseInt(projectOwnerId),
        receiver: user,
        // sender: userData?.member,
        sender_id: researcherId,
        project_id: parseInt(params.id),
      });
    } else {
      history.push('/messages', {
        member_id: projectOwnerId,
        researcher_id: researcherId,
      });
    }
  };

  const toggleDlPDFPopup = () => {
    if (dlPDFPopupOpen) {
      setDlPDFPopupOpen(false);
    } else {
      setDlPDFPopupOpen(true);
    }
  };

  const deleteProject = async () => {
    setLoading(true);
    setIsDeleted(false);
    try {
      const res = await ajax('DELETE', `/project/${params.id}`, params.id);
      if (res.code === 200) {
        setLoading(false);
        setIsDeleted(true);
      }
    } catch (err) {
      setLoading(false);
    }
  };

  const downloadPDF = () => {
    if (pdfMode !== '') {
      let payload = {
        project_id: parseInt(params.id),
      };
      switch (pdfMode) {
        case DL_PDF_MODES.ALL:
          payload['project_details'] = 1;
          payload['deliverables'] = 1;
          payload['attachments'] = 1;
          payload['researcher_requirements'] = 1;
          break;
        case DL_PDF_MODES.PROJECT_DETAILS:
          payload['project_details'] = 1;
          break;
        case DL_PDF_MODES.DELIVERABLES:
          payload['deliverables'] = 1;
          if (
            pdfDeliverableMode.includes(DL_DELIVERABLE_PDF_MODES.ATTACHMENTS)
          ) {
            payload['attachments'] = 1;
          }
          break;
        case DL_PDF_MODES.RESEARCHER_REQUIREMENTS:
          payload['researcher_requirements'] = 1;
          break;
        default:
          break;
      }
      setLoading(true);
      ajax('POST', '/download/pdf', payload).then(result => {
        if (result.code === 200 && result.data && result.data.length > 0) {
          setLoading(false);
          for (var i in result.data) {
            if (result.data[i]['url']) {
              window.open(result.data[i]['url'], '_blank');
            }
          }
        } else if (result.code === 401) {
          setLoading(false);
          setIsOpen(true);
        } else {
          setLoading(false);
          setIsShowingModal('Failed to download project brief.');
        }
      });
    } else {
      setIsShowingModal('Please select options to download.');
    }
  };

  useEffect(() => {
    const getMemberID = async () => {
      const res = await ajax('GET', `/project/${params.id}`);
      setMemberId(res.data.project.created_by_id);
    };
    getMemberID();
    (async () => {
      setLoading(true);
      const res = await ajax('GET', `/project/${params.id}`);
      const url =
        '/messages/conversation/member/' +
        res.data.project.created_by_id +
        '/researcher/' +
        parseInt(localStorage.getItem('br-userId'));
      const response = await ajax('GET', `/project/${params.id}`);
      if (response.code === 200 && response.data && response.data.project) {
        getReferenceData(response.data.project);
        const user_res = await ajax(
          'GET',
          `/member/${response.data.project.created_by.id}`
        );

        if (user_res.code === 200 && user_res.data && user_res.data.member) {
          const convo_response = await ajax('GET', url);
          if (convo_response.code === 200) {
            user_res.data.member['conversation_id'] = convo_response.data['id'];
            setUser(user_res.data.member);
          } else {
            setUser(user_res.data.member);
          }
        } else if (user_res.code === 401) {
          setLoading(false);
          setIsOpen(true);
        }
      } else if (response.code === 401) {
        setLoading(false);
        setIsOpen(true);
      } else {
        setLoading(false);
      }
    })();
  }, [params.id]);

  const getReferenceData = project => {
    (async () => {
      const ref_response = await ajax('GET', '/member_reference_dropdown');
      if (
        ref_response.code === 200 &&
        ref_response.data &&
        ref_response.data.industry
      ) {
        const ind = ref_response.data.industry.find(
          elem => elem.id === project.industry_id
        );
        if (ind) {
          project['industry_label'] = ind['name'];
        }
        setProjectMastery(ref_response.data);
      }
      const out_response = await ajax('GET', '/reference/outline_approach');
      if (out_response.code === 200 && out_response.data) {
        const ind = out_response.data.find(
          elem => elem.id === project.outline_approach_id
        );
        if (ind) {
          project['outline_approach_label'] = ind['description'];
        }
      }
      const budget_response = await ajax('GET', '/reference/budget_rate');
      if (budget_response.code === 200 && budget_response.data) {
        const ind = budget_response.data.find(
          elem => elem.id === project.budget_rate_id
        );
        if (ind) {
          project['budget_rate_label'] = ind['description'];
        }
      }
      setProject(project);
      setLoading(false);
    })();
  };

  const isMobileMode = () => {
    return window.matchMedia('(max-width: 768px)').matches;
  };

  const [isMobile, setIsMobile] = useState(isMobileMode());

  useLayoutEffect(() => {
    function setIsMobileMode() {
      setIsMobile(isMobileMode());
    }

    window.addEventListener('resize', setIsMobileMode);

    return function cleanup() {
      window.removeEventListener('resize', setIsMobileMode);
    };
  });

  const checkActivePlan = () => {
    (async () => {
      try {
        setLoading(true);
        const subs_url = '/member/' + user_id + '/subscription/active';
        const subs_res = await ajax('GET', subs_url);
        if (
          subs_res.code === 200 &&
          subs_res.data &&
          Object.keys(subs_res.data).length > 0
        ) {
          history.push(`/project/${params.id}/share`);
        } else {
          const user_id = parseInt(localStorage.getItem('br-userId'));
          history.push(`/member-subscription/${user_id}/project/${params.id}`);
        }
      } catch (err) {
        setLoading(false);
      }
    })();
  };

  const editProject = () => {
    if (isMemberSubscribed) {
      history.push(`/project/${params.id}/edit`);
    } else {
      setIsBrowseModal(true);
    }
  };

  const duplicateProject = () => {
    if (isMemberSubscribed) {
      history.push({
        pathname: `/create-project`,
        state: { duplicateDetails: project },
      });
    } else {
      setIsBrowseModal(true);
    }
  };

  const handleSendChatMessage = async id => {
    setLoading(true);
    localStorage.setItem('flag', 1);
    let userData = {};
    let reUser = {};
    const userId = localStorage.getItem('br-userId');
    if (userId) {
      const res = await ajax('GET', `/member/${userId}`);
      if (res.code === 200) {
        userData = res.data;
      } else {
        localStorage.removeItem('br-authToken');
        localStorage.removeItem('br-userId');
        localStorage.removeItem('isResearcher');
      }
    }
    if (id) {
      const res = await ajax('GET', `/member/${id}`);
      if (res.code === 200) {
        reUser = res.data.member;
      }
    }
    const url =
      '/messages/conversation/member/' +
      parseInt(localStorage.getItem('br-userId')) +
      '/researcher/' +
      id;
    const convo_response = await ajax('GET', url);
    if (convo_response.code === 200) {
      let arrObj = {
        conversation_id: convo_response.data.id,
        receiver_id: id,
        receiver: reUser,
        sender: userData?.member,
        sender_id: parseInt(localStorage.getItem('br-userId')),
      };
      setLoading(false);
      history.push('/messages', arrObj);
    }
    setLoading(false);
  };

  const ShareprojectButton = (
    <ShareProjectButton
      onClick={() => {
        if (isMemberSubscribed) {
          checkActivePlan();
        } else {
          setIsBrowseModal(true);
        }
      }}
    >
      INVITE RESEARCHER
    </ShareProjectButton>
  );

  const DuplicateprojectButton = (
    <ShareProjectButton onClick={duplicateProject}>
      DUPLICATE PROJECT
    </ShareProjectButton>
  );

  const InviteResearcherButton = (
    <Accordion className="accordianBox">
      <AccordionSummary
        expandIcon={
          <ExpandMoreIcon style={{ color: '#FF9E08', fontSize: '2.5rem' }} />
        }
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography>Invited Researchers</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography>
          {project?.invited_researcher?.length > 0
            ? project?.invited_researcher?.map(item => {
                return (
                  <>
                    <div
                      style={{ display: 'flex', cursor: 'pointer' }}
                      onClick={() => handleSendChatMessage(item?.id)}
                    >
                      <div className="nameAcc">
                        {item?.full_name !== ' ' ? item?.full_name : '-'}
                      </div>
                      <div>
                        <img src={mess} alt="message_icon" />
                      </div>
                    </div>
                    <hr className="accHr" />
                  </>
                );
              })
            : ''}
        </Typography>
      </AccordionDetails>
    </Accordion>
  );

  const ProposalByButton = (
    <Accordion className="accordianBox">
      <AccordionSummary
        expandIcon={
          <ExpandMoreIcon style={{ color: '#FF9E08', fontSize: '2.5rem' }} />
        }
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography>Proposal shared by</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography>
          {project?.proposal_shared_by?.length > 0
            ? project?.proposal_shared_by?.map(item => {
                return (
                  <>
                    <div
                      style={{ display: 'flex', cursor: 'pointer' }}
                      onClick={() => handleSendChatMessage(item?.id)}
                    >
                      <div className="nameAcc">
                        {item?.full_name !== ' ' ? item?.full_name : '-'}
                      </div>
                      <div>
                        <img src={mess} alt="message_icon" />
                      </div>
                    </div>
                    <hr className="accHr" />
                  </>
                );
              })
            : ''}
        </Typography>
      </AccordionDetails>
    </Accordion>
  );

  const SelectResearcherButton = (
    <Accordion className="accordianBox">
      <AccordionSummary
        expandIcon={
          <ExpandMoreIcon style={{ color: '#FF9E08', fontSize: '2.5rem' }} />
        }
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography>Selected Researchers</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography>
          {project?.finalized_researcher?.id ? (
            <>
              <div
                style={{ display: 'flex', cursor: 'pointer' }}
                onClick={() =>
                  handleSendChatMessage(project?.finalized_researcher?.id)
                }
              >
                <div className="nameAcc">
                  {project?.finalized_researcher?.full_name !== ' '
                    ? project?.finalized_researcher?.full_name
                    : '-'}
                </div>
                <div>
                  <img src={mess} alt="message_icon" />
                </div>
              </div>
              <hr className="accHr" />
            </>
          ) : (
            ''
          )}
        </Typography>
      </AccordionDetails>
    </Accordion>
  );

  const DeleteprojectButton = (
    <DeleteProjectButton onClick={() => setDeleteModalOpen(true)}>
      DELETE PROJECT
    </DeleteProjectButton>
  );

  const EditprojectButton = (
    <EditProjectButton onClick={editProject}>EDIT PROJECT</EditProjectButton>
  );

  return (
    <ProjectContent>
      {loading ? <LoadingIndicator /> : null}
      <Breadcrumb
        path={[
          { label: 'MY PROJECTS', to: '/my-projects' },
          { label: project.title ? project.title.toUpperCase() : '' },
        ]}
      />
      <ProjectTitle>{project.title ? project.title : ''}</ProjectTitle>
      <ProjectUserDetails>
        <img
          src={
            user && user.avatar_link
              ? user.avatar_link
              : require('../../components/UserMenu/default.webp')
          }
          alt={user && user.first_name ? user.first_name : ''}
        />
        <Link to={`${user_id === user.id ? '/register' : '#'}`}>
          {user && user.first_name && user.last_name
            ? user.first_name + ' ' + user.last_name
            : user && user.first_name
            ? user.first_name
            : user && user.last_name
            ? user.last_name
            : ''}
        </Link>
        <span>
          {project.start_date
            ? format(new Date(project.start_date), 'MMM d, yyyy hh:mm a')
            : ''}
        </span>
      </ProjectUserDetails>
      <ProjectActionMenuRow>
        {project.created_by === parseInt(localStorage.getItem('br-userId')) &&
          project.project_status_id !== 3 &&
          project.project_status_id !== 2 && (
            <ProjectActionMenuItem>
              <ProjectActionMenuButton
                onClick={() => {
                  history.push(`/project/${params.id}/edit`);
                }}
              >
                <FaPencilAlt />
                Edit
              </ProjectActionMenuButton>
            </ProjectActionMenuItem>
          )}
        {memberType === 3 ? (
          <>
            <span data-tip data-for="approveButton">
              <ProjectActionMenuItem>
                <ProjectActionMenuButton
                  disabled={project?.project_status_id !== 3}
                  onClick={() => {
                    handleSendProposal();
                  }}
                >
                  <FaProjectDiagram />
                  Send proposal
                </ProjectActionMenuButton>
              </ProjectActionMenuItem>
            </span>
            {userDetail?.stripe_account_id === null && (
              <SPReactTooltip
                id="approveButton"
                effect="solid"
                backgroundColor="#393939"
                multiline={true}
                style={{
                  fontSize: '1.2rem',
                  color: '#fff',
                }}
              >
                <div>Please connect to Stripe in the profile section</div>
              </SPReactTooltip>
            )}
          </>
        ) : (
          ''
        )}
        {memberType === 3 ? (
          <ProjectActionMenuItem>
            <ProjectActionMenuButton
              onClick={() => {
                handleSendMessage();
              }}
            >
              <FaEnvelope />
              Message Project Owner
            </ProjectActionMenuButton>
          </ProjectActionMenuItem>
        ) : (
          ''
        )}

        <ProjectActionMenuItem>
          <ProjectActionMenuButton
            onClick={() => {
              toggleDlPDFPopup();
            }}
          >
            <FaFilePdf />
            Download Project Brief
          </ProjectActionMenuButton>
          {dlPDFPopupOpen && (
            <DownloadPDFPopupContainer>
              <DownloadPDFPopup>
                <div>
                  <DLPDFCheckbox
                    type="radio"
                    value={DL_PDF_MODES.ALL}
                    label="All"
                    checked={pdfMode === DL_PDF_MODES.ALL}
                    onChange={e => {
                      handleChangePDFMode(e.target.value);
                    }}
                  />
                  <DLPDFCheckbox
                    type="radio"
                    value={DL_PDF_MODES.PROJECT_DETAILS}
                    label="Project Details"
                    checked={pdfMode === DL_PDF_MODES.PROJECT_DETAILS}
                    onChange={e => {
                      handleChangePDFMode(e.target.value);
                    }}
                  />
                  <DLPDFCheckbox
                    type="radio"
                    value={DL_PDF_MODES.DELIVERABLES}
                    label="Expected Deliverables"
                    checked={pdfMode === DL_PDF_MODES.DELIVERABLES}
                    onChange={e => {
                      handleChangePDFMode(e.target.value);
                    }}
                  />
                  {pdfMode === DL_PDF_MODES.DELIVERABLES ? (
                    <div style={{ marginLeft: '2rem' }}>
                      <DLPDFCheckbox
                        value={DL_DELIVERABLE_PDF_MODES.ATTACHMENTS}
                        label="Attachments"
                        checked={pdfDeliverableMode.includes(
                          DL_DELIVERABLE_PDF_MODES.ATTACHMENTS
                        )}
                        onChange={e => {
                          handleChangePDFDeliverableMode(e.target.value);
                        }}
                      />
                    </div>
                  ) : null}
                  <DLPDFCheckbox
                    type="radio"
                    value={DL_PDF_MODES.RESEARCHER_REQUIREMENTS}
                    label="Researcher Requirements"
                    checked={pdfMode === DL_PDF_MODES.RESEARCHER_REQUIREMENTS}
                    onChange={e => {
                      handleChangePDFMode(e.target.value);
                    }}
                  />
                </div>
                <Button outline onClick={downloadPDF}>
                  Download
                </Button>
              </DownloadPDFPopup>
            </DownloadPDFPopupContainer>
          )}
        </ProjectActionMenuItem>
      </ProjectActionMenuRow>
      <ProjectContainer>
        <ProjectNavbar>
          <ProjectNavbarButton
            active={tab === 'Details'}
            onClick={() => {
              setDlPDFPopupOpen(false);
              setTab('Details');
            }}
          >
            Project Details
          </ProjectNavbarButton>
          <ProjectNavbarButton
            active={tab === 'Expected Deliverables'}
            onClick={() => {
              setDlPDFPopupOpen(false);
              setTab('Expected Deliverables');
            }}
          >
            Expected Deliverables
          </ProjectNavbarButton>
          <ProjectNavbarButton
            active={tab === 'Researcher Requirements'}
            onClick={() => {
              setDlPDFPopupOpen(false);
              setTab('Researcher Requirements');
            }}
          >
            Researcher Requirements
          </ProjectNavbarButton>
          {/* {project.project_status_id && project.project_status_id !== 1 && (
            <ProjectNavbarButton
              active={tab === 'MESSAGES'}
              onClick={() => setTab('MESSAGES')}
            >
              Messages
            </ProjectNavbarButton>
          )} */}
          <div style={{ paddingLeft: '0px' }}>
            {project.project_status_id &&
              project.project_status_id === 3 &&
              project.created_by_id === user_id &&
              ShareprojectButton}
          </div>
          <div style={{ paddingLeft: '0px' }}>
            {project.project_status_id &&
              (project.project_status_id === 4 ||
                project.project_status_id === 3) &&
              project.created_by_id === user_id &&
              EditprojectButton}
          </div>
          <div style={{ paddingLeft: '0px' }}>
            {project.project_status_id &&
              project.created_by_id === user_id &&
              DuplicateprojectButton}
          </div>
          <div style={{ paddingLeft: '0px' }}>
            {project.project_status_id &&
              (project.project_status_id === 3 ||
                project.project_status_id === 4) &&
              project.created_by_id === user_id &&
              DeleteprojectButton}
          </div>
          <div style={{ paddingLeft: '0px' }}>
            {project.project_status_id &&
              project.created_by_id === user_id &&
              InviteResearcherButton}
          </div>
          <div style={{ paddingLeft: '0px' }}>
            {project.project_status_id &&
              project.created_by_id === user_id &&
              ProposalByButton}
          </div>
          <div style={{ paddingLeft: '0px' }}>
            {project.project_status_id &&
              project.created_by_id === user_id &&
              SelectResearcherButton}
          </div>
        </ProjectNavbar>

        {tab === 'Details' && (
          <ProjectDetailsContainer>
            <Details project={project} />
          </ProjectDetailsContainer>
        )}
        {tab === 'Expected Deliverables' && (
          <DeliverablesContainer>
            <Deliverables project={project} />
          </DeliverablesContainer>
        )}
        {tab === 'Researcher Requirements' && (
          <RequirementsContainer>
            <Requirements project={project} projectMastery={projectMastery} />
          </RequirementsContainer>
        )}
      </ProjectContainer>
      <Modal visible={!!isShowingModal} setVisible={setIsShowingModal}>
        <div
          style={{
            backgroundColor: 'white',
            padding: '2rem',
            flexDirection: 'column',
            justifyContent: 'flex-end',
          }}
        >
          <div style={{ marginBottom: '1rem' }}>{isShowingModal}</div>
          <Button
            fill
            onClick={() => {
              setIsShowingModal(null);
            }}
          >
            OK
          </Button>
        </div>
      </Modal>
      <Modal
        visible={deleteModalOpen}
        setVisible={setDeleteModalOpen}
        persistent
      >
        <div
          style={{
            backgroundColor: 'white',
            padding: '2rem',
            flexDirection: 'column',
            justifyContent: 'flex-end',
          }}
        >
          <div style={{ marginBottom: '2rem' }}>
            {isDeleted
              ? 'This Project has been successfully deleted.'
              : 'Are you sure you want to delete this project?'}
          </div>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            {isDeleted ? (
              <Button
                fill
                onClick={() => history.push('/my-projects')}
                style={{ marginRight: '10px', width: 'fit-content' }}
              >
                Go back to My Projects
              </Button>
            ) : (
              <>
                <Button
                  fill
                  onClick={() => deleteProject()}
                  style={{ marginRight: '10px', width: 'fit-content' }}
                >
                  Yes
                </Button>

                <Button
                  outline
                  onClick={() => setDeleteModalOpen(false)}
                  style={{ width: 'fit-content' }}
                >
                  Cancel
                </Button>
              </>
            )}
          </div>
        </div>
      </Modal>
      <BrowsePlanModal visible={isBrowseModal} setVisible={setIsBrowseModal} />

      <SessionModal
        isOpen={isOpen}
        setModalVisible={setIsOpen}
        message={'Your session has expired.'}
      />
    </ProjectContent>
  );
};
