import React, { useEffect } from 'react';
import { EarlyContent, EarlyFormContainer, EarlyTitle } from './styled';
import './style.css';
import bigRobin from './bigRobin.png';
localStorage.setItem('agree', 'false');
const ThankYou = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <EarlyContent>
      <EarlyFormContainer>
        <img src={bigRobin} alt={'big-robin'} className="bigRobin-img" />
        <EarlyTitle>Thanks for Signing up!</EarlyTitle>
        <p className="bigRobin-p">
          We're glad we can keep in touch! Watch this space!
        </p>
        <hr />
        <p className="bigRobin-footer">
          By submitting this form, you are consenting to receive marketing
          emails from: Big Robin LLC, Canada: Suite 210-186 8120 No. 2 Road
          Richmond BC V7C 5J8 & USA: 5965 Village Way Ste E105 #121 San Diego,
          CA 92130
        </p>
      </EarlyFormContainer>
    </EarlyContent>
  );
};

export default ThankYou;
