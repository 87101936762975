import Content from 'components/Content';
import { CardsLayout } from 'components/UserCard/styled';
import styled from 'styled-components/macro';

export const ResearchersSectionContent = styled(Content)`
  padding: 4rem 0;
  background: url(${require('./background.png')});
  background-size: cover;
  background-position: center;

  @media (max-width: 768px) {
    background-position: 25%;
  }
`;

export const Title = styled.h3`
  color: #ffffff;
  font-size: 3.5rem;
`;

export const ResearcherCardsLayout = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    margin-bottom: 4rem;
  }

  ${CardsLayout} {
    flex: 1;
  }
`;

export const RCNavArrow = styled.button`
  height: 6rem;
  min-width: 6rem;
  background-color: #4b4c5e;
  box-shadow: 0px 3px 6px #00000029;

  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 2rem;
  color: #ffffff;
  border-radius: 50%;
  margin: 0 2rem;
  cursor: pointer;
  z-index: 1;
  opacity: 0.23;

  visibility: ${props => (props.visible ? 'visible' : 'hidden')};

  @media (max-width: 768px) {
    height: 8rem;
    min-width: 8rem;
  }
`;
