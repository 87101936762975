import Content from 'components/Content';
import styled from 'styled-components/macro';
import { Row } from 'components/Layout';
import Button from 'components/Button';
import ReactTooltip from 'react-tooltip';

export const PageContent = styled(Content)`
  margin: 4rem 0;
`;

export const PageTitle = styled.h1`
  font-size: 2.2rem;
  color: #4b4c5e;
  margin-top: 2.15rem;

  @media (max-width: 768px) {
    font-size: 2.5rem;
    margin-top: 2.5rem;
  }
`;

export const PageContainer = styled.div`
  margin-top: 1.5rem;

  padding: 4rem;
  background-color: white;

  @media (max-width: 768px) {
    margin-top: 2rem;
  }
`;

export const PageLabel = styled.div`
  letter-spacing: 0px;
  color: #65637b;
  width: 100%;
  display: flex;

  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

export const BalanceContent = styled.div`
  margin-top: 4rem;
  margin-bottom: 60px;
`;

export const BalanceTitle = styled.div`
  color: #65637b;
  font-weight: bold;
  font-size: 24px;
  margin-bottom: 39px;

  @media (max-width: 768px) {
    font-size: 3rem;
  }
`;

export const BalanceBreakdown = styled(Row)`
  display: flex;
  margin-bottom: 24px;
`;

export const BalanceLabel = styled.div`
  width: 40%;

  @media (max-width: 768px) {
    width: 45%;
    font-size: 1.75rem;
  }
`;

export const BalanceAmount = styled.div`
  min-width: 100px;
  text-align: right;

  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

export const BalanceTotalLabel = styled.div`
  width: 40%;
  font-weight: bold;
  font-size: 24px;

  @media (max-width: 768px) {
    width: 45%;
    font-size: 2rem;
  }
`;

export const ContactNote = styled.p`
  font-size: 1.6rem;
  color: #4b4c5e;

  & a {
    //color: #ff9e08;
    color: #4b4c5e;
    font-weight: bold;
  }
`;

export const PPReactTooltip = styled(ReactTooltip)`
  max-width: 30%;
  word-wrap: normal;

  @media (max-width: 768px) {
    max-width: 80%;
  }
`;

export const PageButtonLayout = styled(Row)`
  margin: 4rem 0;
  width: 50%;

  @media (max-width: 768px) {
    margin: 6rem 0 4rem;
    width: 100%;
  }

  ${Button} {
    margin-right: 3rem;
    flex: 1;

    @media (max-width: 768px) {
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
      font-size: 1.75rem;
      margin-right: 1.5rem;
    }

    &:last-child {
      margin-right: 0;
    }
  }
`;
