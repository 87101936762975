import styled from 'styled-components/macro';

export const FileInputContainer = styled.div`
  padding: 1rem;
  // flex: 1;
  font-size: 1.6rem;
  color: #272e4c;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  outline: none;

  background-color: #eeeeee;
  border: 2px dashed #65637b;
  border-radius: 8px;

  @media (max-width: 768px) {
    font-size: 2rem;
    padding: 5rem 1rem;
  }

  // @media (max-width: 600px) {
  //   max-height: 30px;
  // }

  svg {
    margin-bottom: 1rem;
  }

  p {
    text-align: center;
    white-space: pre-line;
  }
`;

export const FilesContainer = styled.div`
  padding: 1rem;
  flex: 1;
  font-size: 1.6rem;
  color: #272e4c;
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  outline: none;
  border-radius: 8px;

  @media (max-width: 768px) {
    font-size: 2rem;
    padding: 5rem 1rem;
  }

  svg {
    margin-bottom: 1rem;
  }

  p {
    text-align: center;
    white-space: pre-line;
  }
`;
