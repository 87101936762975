import styled from 'styled-components/macro';
import { Row, Column } from 'components/Layout';
import Button from 'components/Button';
import ReactTooltip from 'react-tooltip';

export const MessagesConversationContainer = styled.div`
  flex: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0 2rem;

  @media (max-width: 768px) {
    // width: 100%;
    max-width: 100vw;
    // z-index: 1000;
    padding: 0rem;
    margin: 0rem;
    backgrounc-color: #ff00000;
  }
`;

export const MessagesConversation = styled.div`
  flex: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
`;

export const ConversationItemMessage = styled.div`
  padding: 1rem;
  background-color: rgba(218, 218, 223, 0.3);
  max-width: 75rem;
`;

export const ConversationItemUserImageContainer = styled(Column)`
  align-items: center;
  margin-right: 1rem;
  margin-left: 1rem;

  @media (max-width: 768px) {
    margin-left: 2rem;
    margin-right: 2rem;
  }
`;

export const ConversationItemUserImage = styled.img`
  height: 5.5rem;
  width: 5.5rem;
  border-radius: 50%;
  min-width: 5.5rem;
`;

// export const MessageConversationItem = styled.div`
//   display: flex;
//   margin-right: 2rem;
//   margin-bottom: 3rem;
//   align-items: flex-start;
//   justify-content: ${props => (props.isSender ? 'flex-end' : 'flex-start')};

//   ${props =>
//     props.isSender &&
//     css`
//       ${ConversationItemMessage} {
//         background-color: rgb(245, 140, 6, 0.2);
//       }

//       ${ConversationItemUserImageContainer} {
//         margin-right: 0rem;
//         margin-left: 1rem;
//       }
//     `};
// `;

export const DateDivider = styled(Row)`
  align-items: center;
  color: #65637b;
  opacity: 0.7;
  font-size: 1.6rem;
  margin-bottom: 2rem;
  margin-right: 2rem;

  @media (max-width: 768px) {
    margin-left: 2rem;
    margin-right: 2rem;
  }
`;

export const DateDividerBar = styled.div`
  flex: 1;
  height: 0.1rem;
  background-color: #65637b;
  opacity: 0.7;
`;

export const DividerDate = styled.span`
  margin: 0 1rem;

  @media (max-width: 768px) {
    font-size: 1.75rem;
  }
`;

export const ViewProjectButton = styled.button`
  margin-top: 1rem;
  display: block;
  font-size: 1.5rem;
  font-weight: bold;
  color: white;
  background-color: #65637b;
  width: 26rem;
  height: 4rem;
`;

export const MessageOption = styled.button`
  border: 1px solid #ff9e08;
  padding: 0.5rem 1rem;
  border-radius: 0.8rem;
  color: #ff9e08;
  margin-left: 1rem;
`;

export const MessageInputDiv = styled.div`
  display: flex;
`;

export const MessageInputContainer = styled.div`
  width: 100%;
`;

export const IconContainer = styled.div`
  display: flex;
`;

export const ActionButtons = styled.button`
  margin-top: 12px;
  margin-right: 1.3rem;
  font-size: 24px;
`;

export const UploadedFileContainer = styled.div`
  display: flex;
  overflow-x: auto;
  border-top: 1px solid #dadadf;
  padding-top: 10px;
  width: calc(55vw - 10px);

  @media (max-width: 768px) {
    display: block;
    width: 100%;
  }
`;

export const UploadedFileContainerModal = styled.div`
  display: flex;
  overflow-x: auto;
  padding-top: 10px;
  width: calc(55vw - 10px);

  @media (max-width: 768px) {
    display: block;
    width: 100%;
  }
`;

export const FileContainer = styled.div`
  display: flex;
  margin-right: 15px;
`;

export const FileIcon = styled.div`
  border: 1px solid #dadadf;
  border-radius: 9px 0px 0px 9px;
  padding: 15px;
  background: #4b4c5e 0% 0% no-repeat padding-box;
  font-size: 22px;
`;

export const FileDetails = styled.div`
  border: 1px solid #dadadf;
  border-radius: 0px 9px 9px 0px;
  padding: 15px;
  background: #ffffff 0% 0% no-repeat padding-box;
  max-width: 150px;
`;

export const FileNameSpan = styled.span`
  font-size: 9px;
  font-weight: bold;
  overflow: hidden;
  white-space: initial;
  text-overflow: ellipsis;
  width: 149px;
`;

export const CloseButton = styled.span`
  font-size: 10px;
  position: relative;
  float: right;
  right: -6px;
  top: -8px;
`;

export const MessageForm = styled.form`
  @media (max-width: 768px) {
    margin: 1rem 2rem 2rem;
    max-width: 100vw;
  }
`;

export const SendBtn = styled(Button)`
  margin-right: -1rem;

  @media (max-width: 768px) {
    padding: 2.25rem 3.2rem;
    font-size: 2rem;
  }
`;

export const BackBtn = styled.div`
  display: none;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-size: 2.75rem;
    margin: 2rem 1rem 2rem 2rem;
    font-weight: bold;
    color: #65637b;
  }
`;
export const SPReactTooltip = styled(ReactTooltip)`
  max-width: 30%;
  word-wrap: normal;

  @media (max-width: 768px) {
    max-width: 80%;
  }
`;

export const AddAttachment = styled.div`
  padding: 6px 8px;
  border: 1.5px solid #4b4c5ec2;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  @media (max-width: 768px) {
    padding: 1px 8px;
  }
`;
