import { logout, useAuthDispatch, useAuthState } from 'context/AuthContext';
import useClickOutside from 'hooks/useClickOutside';
import useNodeState from 'hooks/useNodeState';
import React, { useCallback, useState } from 'react';
import { FaCaretDown, FaCrown, FaPowerOff, FaUser } from 'react-icons/fa';
import {
  MdMessage,
  // MdSettings
} from 'react-icons/md';
import { useHistory } from 'react-router-dom';
import {
  UserMenuContainer,
  UserMenuOption,
  UserMenuOptionsContainer,
  UserMenuRow,
} from './styled';

export const UserMenu = () => {
  const history = useHistory();
  const { user } = useAuthState();
  const authDispatch = useAuthDispatch();
  const [isShowingMenu, setIsShowingMenu] = useState(false);

  const [refCB, node, ref] = useNodeState();

  useClickOutside(
    ref,
    isShowingMenu,
    useCallback(() => {
      setIsShowingMenu(false);
    }, [])
  );

  if (!user) return null;

  return (
    <UserMenuContainer ref={refCB}>
      <UserMenuRow onClick={() => setIsShowingMenu(true)}>
        <img
          src={
            (user.member && user.member.avatar_link) ||
            require('./default.webp')
          }
          alt={user.full_name}
        />
        <FaCaretDown />
      </UserMenuRow>
      {isShowingMenu && node && (
        <UserMenuOptionsContainer style={{ top: node.offsetHeight }}>
          {/* <UserMenuOption
            onClick={() => {
              history.push('/messages');
              setIsShowingMenu(false);
              localStorage.setItem('project_id', 0);
            }}
          >
            <MdMessage />
            Messages
          </UserMenuOption> */}
          <UserMenuOption
            onClick={() => {
              if (user.member.full_name && user.member.member_type_id === 3) {
                history.push(`/researcher/${user.member.id}`);
              } else {
                history.push(`/register`);
              }

              setIsShowingMenu(false);
            }}
          >
            <FaUser />
            Profile
          </UserMenuOption>
          {user?.member?.member_type_id === 2 && (
            <UserMenuOption
              onClick={() => {
                history.push(`/member-subscription`);
                setIsShowingMenu(false);
              }}
            >
              <FaCrown />
              Membership
            </UserMenuOption>
          )}

          <UserMenuOption
            style={{ borderTop: '1px solid #dadadf' }}
            onClick={async () => {
              await logout(authDispatch);
              history.push('/login');
            }}
          >
            <FaPowerOff />
            Log Out
          </UserMenuOption>
        </UserMenuOptionsContainer>
      )}
    </UserMenuContainer>
  );
};
