import Button from 'components/Button';
import Modal from 'components/Modal';
import React from 'react';
import { useHistory } from 'react-router-dom';

const BrowsePlanModal = ({ visible, setVisible }) => {
  const history = useHistory();

  return (
    <Modal visible={visible} setVisible={setVisible} persistent={true}>
      <div
        style={{
          backgroundColor: 'white',
          padding: '2rem',
          flexDirection: 'column',
          justifyContent: 'flex-end',
          maxWidth: `${'300px'}`,
          marginLeft: 'auto',
          marginRight: 'auto',
          borderRadius: '8px',
        }}
      >
        <div
          style={{
            marginBottom: '2rem',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <b style={{ textAlign: 'center' }}>
            Become a member to perform the action
          </b>
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            marginTop: '2rem',
          }}
        >
          <Button
            outline
            style={{ marginRight: '8px', width: '100px' }}
            onClick={() => {
              setVisible(false);
            }}
          >
            Cancel
          </Button>

          <Button
            style={{ marginLeft: '8px' }}
            onClick={() => {
              history.push('/member-subscription');
            }}
          >
            Browse plans
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default BrowsePlanModal;
