import React, { useState } from 'react';

const StarRating = ({ totalStars, initialRating, onChange }) => {
  const [rating, setRating] = useState(initialRating);

  const handleClick = selectedRating => {
    setRating(selectedRating);
    onChange(selectedRating);
  };

  return (
    <div>
      {[...Array(totalStars)].map((_, index) => {
        const starValue = index + 1;
        return (
          <span
            key={starValue}
            onClick={() => handleClick(starValue)}
            style={{ cursor: 'pointer', fontSize: '30px', marginRight: '20px' }}
          >
            {starValue <= rating ? (
              <span style={{ color: '#ff9e08' }}>★</span>
            ) : (
              <span style={{ color: '#ff9e08' }}>☆</span>
            )}
          </span>
        );
      })}
    </div>
  );
};

export default StarRating;
