import styled, { css } from 'styled-components/macro';

export const RadioDot = styled.div`
  border-radius: 50%;
  background-color: #4b4c5e;
  min-height: 1rem;
  min-width: 1rem;
`;

export const CheckboxContainer = styled.div`
  min-height: 2.2rem;
  min-width: 2.2rem;
  background-color: white;
  border: 1px solid #65637b3c;
  border-radius: 3px;
  margin-right: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;

  ${props =>
    props.type === 'radio' &&
    css`
      border-radius: 50%;
    `}
`;

export const CheckboxLayout = styled.label`
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 1.6rem;
  color: #4b4c5e;

  @media (max-width: 900px) {
    font-size: 2rem;
  }

  input {
    display: none;

    &:not(:checked) + ${CheckboxContainer} svg,
    &:not(:checked) + ${CheckboxContainer} ${RadioDot} {
      display: none;
    }
  }
`;
