import React, { useState, useMemo } from 'react';
import { useAuthState, useAuthDispatch } from 'context/AuthContext';
import { ajax } from 'utils/ajax';
import Steps from 'components/Steps';
import { EditProjectContainer, HeaderLayout, Instructions } from './styled';
import LoadingIndicator from 'components/LoadingIndicator';
import Modal from 'components/Modal';
import { useHistory, useParams } from 'react-router-dom';
import Button from 'components/Button';
import { ProjectForm } from 'components/ProjectForm';
import { upload } from 'utils/upload';
import SessionModal from 'components/SessionModal';
import ProjectPreview from 'components/ProjectPreview';
import cross from './cross.png';

export const EditProject = () => {
  const { user } = useAuthState();
  const authDispatch = useAuthDispatch();
  const history = useHistory();
  const params = useParams();

  const [loading, setLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [isShowingModal, setIsShowingModal] = useState(null);
  const [showSaveLaterModal, setShowSaveLaterModal] = useState(null);
  const [showingAction, setShowingAction] = useState('');
  const [project, setProject] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isComplete, setIsComplete] = useState(false);
  const [isDisable, setIsDisable] = useState(false);
  const [isPreview, setIsPreview] = useState(false);
  const [previewData, setPreviewData] = useState(null);
  const [previewDataValues, setPreviewDataValues] = useState(null);

  const handleProjectPreview = () => {
    setIsPreview(prev => !prev);
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const handleSubmitData = data => {
    const body = {
      modified_by_id: user.member.id,
    };

    try {
      setLoading(true);
      let new_files = [];
      for (var i in data) {
        if (i === 'reference_files') {
          const old_files = data[i]
            .filter(item => item.new_upload !== true)
            .map(item => {
              return {
                filename: item.filename,
                url: item.key,
                description: '',
                attachment_type: '',
              };
            });
          new_files = data[i].filter(item => item.new_upload === true);
          body[i] = old_files;
        } else if (i === 'start_date' || i === 'end_date') {
          if (data[i]) {
            body[i] = new Date(data[i]);
          } else {
            body[i] = data[i];
          }
        } else if (i === 'research_mastery' || i === 'analytics_mastery') {
          if (data[i]) {
            body[i] = data[i];
          }
        } else {
          body[i] = data[i];
        }
      }
      if (new_files.length > 0) {
        uploadingFiles(body, new_files, 0);
      } else {
        savingProject(body);
      }
    } catch (err) {
      setLoading(true);
    } finally {
      // console.log('finally');
    }
  };

  const uploadingFiles = (body, to_upload, ind) => {
    (async () => {
      let loadFlag = false;
      if (to_upload[ind]) {
        const upload_res = await upload(
          to_upload[ind]['url'],
          'project_reference_files',
          to_upload[ind]['filename']
        );
        if (upload_res && upload_res['status'] === 200 && upload_res['Key']) {
          body['reference_files'].push({
            filename: to_upload[ind]['filename'],
            url: upload_res['Key'],
            description: '',
            attachment_type: to_upload[ind]['attachment_type'],
          });
          setIsDisable(false);
        } else if (upload_res && upload_res.code === 401) {
          loadFlag = true;
          setLoading(false);
          setIsOpen(true);
          setIsDisable(false);
        }
      }
      const len = to_upload.length - 1;
      if (loadFlag === false && ind < len) {
        const ind_temp = ind + 1;
        uploadingFiles(body, to_upload, ind_temp);
      } else if (loadFlag === false) {
        savingProject(body);
      }
    })();
  };

  const savingProject = body => {
    (async () => {
      const res = await ajax('PUT', '/project/' + params.id, body);
      if (res.code === 200) {
        const getRes = await ajax('GET', `/member/${user.member.id}`);
        if (getRes.code === 200) {
          authDispatch({ type: 'SET_USER', payload: getRes.data });
          setShowingAction('saving');
          setLoading(false);
          setIsDisable(false);

          if (body.status === 3) setIsShowingModal(res.message);
        } else if (getRes.code === 401) {
          setLoading(false);
          setIsOpen(true);
          setIsDisable(false);
        }
      } else if (res.code === 401) {
        setLoading(false);
        setIsOpen(true);
        setIsDisable(false);
      }
      setLoading(false);
      setIsDisable(false);
    })();
  };

  useMemo(() => {
    (async () => {
      setLoading(true);
      const response = await ajax('GET', `/project/${params.id}`);

      if (response.code === 200 && response.data && response.data.project) {
        setLoading(false);
        setProject(response.data.project);
      } else if (response.code === 401) {
        setLoading(false);
        setIsOpen(true);
      } else {
        setLoading(false);
        setIsShowingModal('An error occurred while loading project data.');
        setShowingAction('loadDataError');
      }
    })();
  }, [params.id]);

  return (
    <div>
      {isPreview && (
        <ProjectPreview
          handleProjectPreview={handleProjectPreview}
          props={previewData}
          previewDataValues={previewDataValues}
        />
      )}
      <div style={{ display: isPreview ? 'none' : 'block' }}>
        <EditProjectContainer>
          {loading ? <LoadingIndicator /> : null}
          <HeaderLayout>
            <Instructions>
              <h4>Edit Project</h4>
            </Instructions>
            <Steps
              steps={['Project Description', 'Project Requirements']}
              current={currentStep}
            />
          </HeaderLayout>
          <ProjectForm
            setPreviewData={setPreviewData}
            setPreviewDataValues={setPreviewDataValues}
            onFormSubmit={handleSubmitData}
            setIsDisable={setIsDisable}
            onStepChange={setCurrentStep}
            isEdit={true}
            isLoading={loading}
            setIsShowingModal={setIsShowingModal}
            setShowSaveLaterModal={setShowSaveLaterModal}
            setShowingAction={setShowingAction}
            projectData={project}
            setIsOpen={setIsOpen}
            setLoading={setLoading}
            isOpen={isOpen}
            isComplete={isComplete}
            setIsComplete={setIsComplete}
            handleProjectPreview={handleProjectPreview}
          />
          <Modal
            visible={!!isShowingModal}
            setVisible={setIsShowingModal}
            persistent
          >
            <div
              style={{
                backgroundColor: 'white',
                padding: '2rem',
                flexDirection: 'column',
                justifyContent: 'flex-end',
                position: 'relative',
                borderRadius: '8px',
                minWidth: '250px',
              }}
            >
              <div
                style={{
                  textAlign: 'center',
                  paddingBottom: '16px',
                  position: 'relative',
                  // display: 'flex',
                  fontWeight: 'bold',
                }}
              >
                <div>Success</div>
                <span
                  style={{
                    textAlign: 'end',
                    position: 'absolute',
                    top: '0px',
                    right: '0px',
                  }}
                >
                  <img
                    src={cross}
                    alt="cross-icon"
                    className="crossIcon"
                    onClick={() => {
                      setIsShowingModal(null);
                      setShowingAction('');
                      if (
                        showingAction === 'saving' ||
                        showingAction === 'loadDataError'
                      ) {
                        history.push(`/project/${params.id}`);
                      }
                    }}
                  />
                </span>
              </div>

              <div style={{ textAlign: 'center', marginBottom: '2rem' }}>
                {isShowingModal}
              </div>
              <div>
                <Button
                  outline
                  fill
                  onClick={() => {
                    setIsShowingModal(null);
                    setShowingAction('');
                    if (showingAction === 'saving') {
                      history.push('/researchers');
                    }
                  }}
                >
                  Invite a researcher
                </Button>
              </div>
            </div>
          </Modal>
          <Modal
            visible={showSaveLaterModal}
            setVisible={setShowSaveLaterModal}
            persistent
          >
            <div
              style={{
                backgroundColor: 'white',
                padding: '2rem',
                flexDirection: 'column',
                justifyContent: 'flex-end',
              }}
            >
              <div style={{ textAlign: 'center', marginBottom: '2rem' }}>
                Successfully saved project
              </div>
              <div>
                <Button
                  fill
                  onClick={() => {
                    setShowSaveLaterModal(null);
                    history.push('/my-projects');
                  }}
                >
                  OK
                </Button>
              </div>
            </div>
          </Modal>
        </EditProjectContainer>
      </div>

      <SessionModal
        isOpen={isOpen}
        setModalVisible={setIsOpen}
        message={'Your session has expired.'}
      />
    </div>
  );
};
