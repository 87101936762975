import React from 'react';
import {
  AboutContent,
  PageTitle,
  PageDesc,
  AboutGrid,
  AboutGridItem,
  AGItemImageContainer,
} from './styled';

const aboutItems = [
  {
    text: 'Start-ups, SMBs and Large Enterprise',
    img: require('./assets/retail-store.svg'),
  },
  {
    text: 'Big Robin Research E-Marketplace',
    img: require('./assets/second-party-data.svg'),
  },
  {
    text: 'Best-of-Breed Market and AI Researchers',
    img: require('./assets/audience.svg'),
  },
];

const About = () => {
  return (
    <AboutContent>
      <PageTitle>Our Solutions</PageTitle>
      <PageDesc>
        Connecting business to researchers and researchers to business.
      </PageDesc>
      <AboutGrid>
        {aboutItems.map((item, index) => (
          <AboutGridItem>
            <AGItemImageContainer>
              <img src={item.img} alt="" />
            </AGItemImageContainer>
            <p>{item.text}</p>
          </AboutGridItem>
        ))}
      </AboutGrid>
    </AboutContent>
  );
};

export default About;
