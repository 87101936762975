import Content from 'components/Content';
import Searchbar from 'components/Searchbar';
import Button from 'components/Button';
import { Row } from 'components/Layout';
import {
  DropdownPopupContainer,
  DropdownPopupMenuContainer,
} from 'components/DropdownPopup/styled';
import { SpecializationDropdownPopup } from 'components/ResearcherFilter/styled';
import styled from 'styled-components/macro';

export const ProjectsContent = styled(Content)`
  margin: 8rem 2rem;

  @media (max-width: 900px) {
    margin: 6rem 0rem;
  }
`;

export const ProjectPageTitle = styled.h1`
  font-size: 2.2rem;
  margin-bottom: 4rem;

  @media (max-width: 900px) {
    font-size: 2.4rem;
    margin-bottom: 2rem;
  }
`;

export const ProjectsListContainer = styled.div`
  padding: 6rem;
  background-color: white;

  @media (max-width: 900px) {
    padding: 4rem;
  }
`;

export const ProjectsSearchbar = styled(Searchbar)`
  flex-grow: 1;

  input {
    color: #4b4c5e;

    &::placeholder {
      color: #bab9be;
    }
  }

  @media (max-width: 900px) {
    width: 100%;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
`;

export const ProjectsFilterToggle = styled(Button)`
  width: 100%;
  margin: 0.5rem 0rem;
  border-radius: 8px;

  @media (min-width: 901px) {
    flex-grow: 0;
    border-radius: 0px;
    margin: 0rem;
    background-color: transparent;
    width: auto;
    font-size: 1.6rem;
    color: #ff9e08;
    text-decoration: underline;
    font-weight: bold;

    &:hover,
    &:active {
      color: #4b4c5eff;
    }
  }
`;

export const ProjectsFilterRow = styled(Row)`
  margin: 2rem 0rem;

  @media (max-width: 900px) {
    flex-direction: column;
    margin: 0rem;
  }

  ${DropdownPopupContainer} {
    margin-right: 1.25rem;

    &:last-child {
      margin-right: 0rem;
    }

    @media (max-width: 900px) {
      margin: 0.25rem 0rem;
    }
  }
`;

export const PSDropdownPopup = styled(SpecializationDropdownPopup)`
  ${DropdownPopupMenuContainer} {
    left: -15vw;

    @media (max-width: 900px) {
      left: 2px;
      width: calc(100% - 4rem);
    }
  }
`;

export const NoProjectContainer = styled.div`
  padding: 12rem 0rem;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 900px) {
    padding: 8rem 0rem;
  }

  & p {
    font-size: 2rem;
    font-weight: bold;
    color: #4b4c5e;

    @media (max-width: 900px) {
      font-size: 2.4rem;
    }
  }
`;

export const ProjectItem = styled.div`
  display: flex;
  flex-direction: column;
  margin: 6rem 0rem 0rem;
  padding-bottom: 4rem;
  border-bottom: 1px solid ${props => props.theme.lightGray};
`;

export const ProjectItemTitle = styled.h5`
  color: #4b4c5e;
  font-size: 2rem;
  margin-bottom: 0.5rem;

  @media (max-width: 900px) {
    font-size: 2.4rem;
  }
`;

export const ProjectItemDesc = styled.p`
  margin-bottom: 2rem;
  color: #4b4c5e;
  font-size: 1.6rem;

  @media (max-width: 900px) {
    font-size: 2rem;
  }
`;

export const ProjectItemSpecRow = styled(Row)`
  align-items: center;

  @media (max-width: 900px) {
    flex-direction: row;
  }
`;

export const ProjectItemSpecIcon = styled.span`
  color: #97b5a9;
  font-size: 1.4rem;
  margin-right: 1.25rem;
  margin-top: 0.7rem;

  @media (max-width: 900px) {
    font-size: 1.6rem;
  }
`;

export const ProjectItemSpecs = styled.span`
  font-size: 1.4rem;

  @media (max-width: 900px) {
    font-size: 1.6rem;
  }
`;
