import Content, { MiddleContainer } from 'components/Content';
import { InputContainer } from 'components/Input/styled';
import Button from 'components/Button';
import Checkbox from 'components/Checkbox';
import { CheckboxContainer, RadioDot } from 'components/Checkbox/styled';
import { Row } from 'components/Layout';
import styled from 'styled-components/macro';
import { FaUserAlt } from 'react-icons/fa';
import ReactTooltip from 'react-tooltip';

export const HeaderLayout = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  margin-bottom: 4rem;

  @media (max-width: 768px) {
    display: block;
  }
`;

export const Instructions = styled.div`
  margin-top: 2rem;
  margin-bottom: 2rem;

  @media (max-width: 768px) {
    margin-bottom: 4rem;
  }

  h4 {
    font-size: 2.4rem;

    @media (max-width: 768px) {
      font-size: 3.2rem;
    }
  }

  p {
    font-style: italic;
    color: #65637b;

    @media (max-width: 768px) {
      font-size: 2rem;
    }
  }
`;

export const ProfileContent = styled(Content)`
  background-color: white;

  ${MiddleContainer} {
    padding: 4rem 0;
  }

  ${Row} {
    ${InputContainer} {
      margin-right: 2rem;
      flex: 1;

      @media (max-width: 768px) {
        margin-right: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }
`;

export const RPPageSectionTitle = styled.h5`
  margin: 0rem 0rem 2rem;
  font-size: 2rem;
  text-transform: uppercase;

  @media (max-width: 768px) {
    font-size: 2.5rem;
    margin-bottom: 2rem;
  }
`;

export const RPPageOneContainer = styled.div`
  margin-bottom: 8rem;
  display: grid;
  grid-template-columns: 2fr 1fr;
  column-gap: 2rem;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    column-gap: 0rem;
    row-gap: 2rem;
  }
`;

// export const UserProfileGrid = styled.div`
//   display: grid;
//   grid-template-areas:
//     'first_name last_name'
//     'address address'
//     'billing_information billing_information'
//     'email contact_number'
//     'linked_in_url bank_account_details'
//     'emptyspace emptyspace'
//     'id_image_link nda_template_link'
//     'id_image_link nda_template_link';
//   grid-template-rows: 1fr 1fr 1fr 1fr 1fr 5rem 2fr;
//   column-gap: 2rem;
//   row-gap: 2rem;
//   align-content: space-between;

//   @media (max-width: 768px) {
//     grid-template-areas:
//       'first_name'
//       'last_name'
//       'address'
//       'billing_information'
//       'email'
//       'contact_number'
//       'linked_in_url'
//       'bank_account_details'
//       'id_image_link'
//       'nda_template_link';
//     grid-template-rows: 1fr;
//     column-gap: 0rem;
//     row-gap: 2rem;
//     margin-bottom: 4rem;
//   }
// `;
export const UserProfileGrid = styled.div`
  display: grid;
  grid-template-areas:
    'first_name last_name'
    'address address'
    'address_2 address_2'
    'city state'
    'country postal_code'
    'email contact_number'
    'linked_in_url id_image_link'
    'nda_template_link nda_template_link'
    'payment_methods payment_methods';
  // grid-template-rows: repeat(8, 1fr);
  column-gap: 2rem;
  row-gap: 2rem;
  align-content: space-between;

  @media (max-width: 768px) {
    grid-template-areas:
      'profile_image'
      'first_name'
      'last_name'
      'address'
      'address_2'
      'city'
      'state'
      'country'
      'postal_code'
      'email'
      'contact_number'
      'linked_in_url'
      'id_image_link'
      'nda_template_link'
      'payment_methods';
    grid-template-rows: 1fr;
    column-gap: 0rem;
    row-gap: 2rem;
    margin-bottom: 4rem;
  }
`;

export const ServicesProfileGrid = styled.div`
  display: grid;
  grid-template-areas:
    'industry_expertise'
    'services_offered'
    'business_specialization'
    'scope_of_services'
    'minimum_engagement'
    'preferred_languages'
    'preferred_markets'
    'countries_not_served';
  column-gap: 0rem;
  row-gap: 2rem;
  align-content: space-between;

  @media (max-width: 768px) {
    grid-template-areas:
      'services_offered'
      'industry_expertise'
      'business_specialization'
      'scope_of_services'
      'minimum_engagement'
      'preferred_languages'
      'preferred_markets'
      'countries_not_served';
    column-gap: 0rem;
    row-gap: 2rem;
  }
`;

export const RPPageTwoContainer = styled.div`
  margin-bottom: 8rem;
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 0rem;
  row-gap: 2rem;
`;

export const RPPageTwoSection = styled.div`
  display: block;
  margin-bottom: 4rem;
  width: 100%;

  @media (max-width: 768px) {
    margin-bottom: 4rem;
  }
`;

export const RPPageTwoSectionHRow = styled.div`
  display: grid;
  grid-template-columns: 3fr 1fr 1fr 1fr 1fr;
  column-gap: 2rem;
  row-gap: 0rem;
  margin: 0rem 0rem 2rem;
  width: 80%;

  h6 {
    font-size: 1.8rem;
    color: #ff9e08;
    margin: 0;

    @media (max-width: 768px) {
      font-size: 2rem;
    }
  }

  p {
    font-size: 1.6rem;
    color: #4b4c5e;
    font-style: italic;
    text-align: center;
    margin: 0;
    display: block;

    @media (max-width: 768px) {
      display: none;
    }
  }

  @media (max-width: 900px) {
    width: 100%;
  }

  @media (max-width: 768px) {
    margin-bottom: 2rem;
    grid-template-columns: 1fr;
  }
`;

export const RPPageTwoSectionRow = styled.div`
  display: grid;
  grid-template-columns: 3fr 1fr 1fr 1fr 1fr;
  grid-template-areas: 'row-label notapplicable familiar proficient fluent';
  column-gap: 2rem;
  row-gap: 0rem;
  margin: 0rem 0rem 2rem;
  width: 80%;

  &:last-child {
    margin-bottom: 0rem;
  }

  @media (max-width: 900px) {
    width: 100%;
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-areas:
      'row-label row-label row-label row-label'
      'notapplicable familiar proficient fluent';
    margin-bottom: 4rem;
  }
`;

export const RPPageTwoSectionF500Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  column-gap: 2rem;
  row-gap: 0rem;
  margin: 0rem 0rem 2rem;
  width: 90%;

  &:last-child {
    margin-bottom: 0rem;
  }

  @media (max-width: 1000px) {
    width: 100%;
  }

  @media (max-width: 900px) {
    display: block;
    margin-bottom: 4rem;

    ${InputContainer} {
      margin-top: 1rem;
    }
  }
`;

export const RPPageTwoSectionRowLabel = styled.p`
  font-size: 1.6rem;
  color: #4b4c5e;
  font-style: italic;
  margin: 0rem;

  @media (max-width: 768px) {
    font-size: 2rem;
    margin-bottom: 1rem;
    font-weight: 700;
  }
`;

export const RPPageTwoSectionRowOptions = styled(Row)`
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    justify-content: flex-start;
  }

  p {
    font-size: 1.6rem;
    color: #4b4c5e;
    font-style: italic;
    margin: 0;
    margin-left: 1rem;
    display: none;

    @media (max-width: 768px) {
      display: block;
      font-size: 1.8rem;
    }
  }
`;

export const RPCheckbox = styled(Checkbox)`
  ${CheckboxContainer} {
    min-height: 1.5rem;
    min-width: 1.5rem;
    margin-right: 0;

    @media (max-width: 768px) {
      min-height: 2.2rem;
      min-width: 2.2rem;
    }
  }

  ${RadioDot} {
    min-height: 1.1rem;
    min-width: 1.1rem;

    @media (max-width: 768px) {
      min-height: 1.5rem;
      min-width: 1.5rem;
    }
  }
`;

export const RCCheckbox = styled(RPCheckbox)`
  font-style: italic;
  color: #4b4c5e;
  margin-top: 2rem;
  margin-bottom: 2rem;

  ${CheckboxContainer} {
    margin-right: 3.2rem;
  }
`;

export const RHECheckbox = styled(RPCheckbox)`
  color: #ff9e08;

  ${CheckboxContainer} {
    margin-right: 2rem;
  }
`;

export const RPPageTwoSectionRHERow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 2rem;
  row-gap: 0rem;
  margin: 1rem 0rem;
  width: 80%;

  &:last-child {
    margin-bottom: 0rem;
  }

  @media (max-width: 900px) {
    width: 100%;
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    column-gap: 0rem;
    row-gap: 2rem;
    margin-bottom: 4rem;
  }
`;

export const F500Button = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0.5rem;
  margin-bottom: 1rem;
  cursor: pointer;
  color: #7e82948a;

  @media (max-width: 900px) {
    color: #7e8294;
    justify-content: center;
  }

  &:hover,
  &:active {
    color: #7e8294;
  }

  svg {
    font-size: 2rem;

    @media (max-width: 900px) {
      font-size: 3.5rem;
      margin: 0.5rem;
    }
  }

  span {
    font-size: 1.6rem;
    font-style: italic;

    @media (max-width: 900px) {
      display: none;
    }
  }
`;

export const RPPageThreeContainer = styled.div`
  margin-bottom: 8rem;
  display: block;
`;

export const RPPageThreeSection = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 0rem;
  row-gap: 2rem;
  margin-bottom: 4rem;

  @media (max-width: 768px) {
    margin-bottom: 4rem;
  }
`;

export const RPPageThreeSectionCol = styled(RPPageThreeSection)`
  margin-bottom: 0rem;
`;

export const RPPageThreeSectionRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  column-gap: 2rem;
  row-gap: 0rem;
  align-content: start;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    column-gap: 0rem;
    row-gap: 2rem;
  }
`;

export const PageButtonsLayout = styled(Row)`
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const PageButtonsGroup = styled(Row)`
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const LeftButton = styled(Button)`
  min-width: 12rem;
  text-transform: uppercase;
  margin-right: 3rem;

  @media (max-width: 768px) {
    margin: 1rem 0;
    padding-top: 1.75rem;
    padding-bottom: 1.75rem;
    font-size: 2rem;
    flex: 1;
  }
`;

export const RightButton = styled(Button)`
  min-width: 12rem;
  text-transform: uppercase;
  margin-left: 3rem;

  @media (max-width: 768px) {
    margin: 1rem 0;
    padding-top: 1.75rem;
    padding-bottom: 1.75rem;
    font-size: 2rem;
    flex: 1;
  }
`;

export const ProfileGrid = styled.div`
  display: grid;
  grid-template-areas:
    'first_name last_name services_offered'
    'address address industry'
    'billing_information billing_information languages'
    'email contact_number linked_in_url'
    'minimum_engagement id_image_link nda_template_link';
  column-gap: 2rem;
  row-gap: 1rem;

  margin-bottom: 4rem;

  @media (max-width: 768px) {
    grid-template-areas:
      'first_name'
      'last_name'
      'address'
      'billing_information'
      'email'
      'contact_number'
      'linked_in_url'
      'minimum_engagement'
      'services_offered'
      'industry'
      'languages'
      'id_image_link'
      'nda_template_link';
    column-gap: 0rem;
    row-gap: 2rem;
  }
`;

export const ServiceArchitectureGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 2rem;
  margin-bottom: 4rem;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    column-gap: 0rem;
    row-gap: 2rem;
  }
`;

export const ServiceArchitectureLabel = styled.p`
  font-size: 1.8rem;
  margin-bottom: 4rem;

  @media (max-width: 768px) {
    font-size: 2.4rem;
    margin-bottom: 2rem;
  }
`;

export const UserProfileImageBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const UserProfileImageContainer = styled.div`
  position: relative;
  display: block;
  width: auto;
  height: auto;
`;

export const UserProfileImage = styled.img`
  width: 20rem;
  height: 20rem;
  border-radius: 50%;
  border: 0.3rem solid #f58c063c;
  cursor: ${props => (props.readOnly ? 'default' : 'pointer')};
`;

export const DefaultProfilePicture = styled(FaUserAlt)`
  width: 20rem;
  height: 20rem;
  border-radius: 50%;
  border: 0.3rem solid #f58c063c;
  cursor: ${props => (props.readOnly ? 'default' : 'pointer')};
`;

export const IconContainer = styled(Row)`
  justify-content: center;
  align-items: center;
  background-color: #f58c06;
  position: absolute;
  bottom: 6px;
  right: 32px;
  border-radius: 50%;
  padding: 0.5rem;
  cursor: pointer;
  svg {
    font-size: 1.5rem;
    color: white;
  }
`;

export const UserProfileInfo = styled.div`
  flex: 1;
  padding-left: 2rem;

  h3 {
    font-size: 2rem;
    margin-bottom: 2rem;
  }

  form {
    ${Row} {
      margin-bottom: 4rem;
    }
  }
`;

export const UserProfileMenu = styled.div`
  padding-right: 2rem;
`;

export const UserProfileContainer = styled(Row)`
  background-color: white;
  padding: 4rem;
  margin-bottom: 4rem;
`;

export const SPReactTooltip = styled(ReactTooltip)`
  max-width: 30%;
  word-wrap: break-word;
  margin: 0px 10px;

  @media (max-width: 768px) {
    max-width: 80%;
    margin: 0px 10px;
  }
`;
