import styled from 'styled-components/macro';

export const SpecializationDropdownHeader = styled.p`
  padding: 1rem 0.5rem;
  font-size: 1.6rem;
  font-weight: 700;
`;

export const SpecializationDropdownContainer = styled.div`
  padding: 0rem 0.75rem;
`;
