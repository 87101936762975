import Button from 'components/Button';
import { Row } from 'components/Layout';
import React from 'react';
import { FaDollarSign, FaMedal, FaStar } from 'react-icons/fa';
import { ProjectCardContainer } from './styled';

const ProjectCard = ({
  full_name,
  address,
  customer_ratings,
  mastery_score,
  minimum_engagement,
  compressed,
  onView,
  compact = false,
}) => {
  if (compact) {
    return (
      <ProjectCardContainer compact={compact} compressed={compressed}>
        <h4 onClick={onView}>{full_name}</h4>
        <p onClick={onView}>{address}</p>
        <Row justifyContent="space-between">
          <div>
            <Row>
              <span style={{ marginRight: '10px' }}>
                <FaStar /> {customer_ratings > 0 ? customer_ratings : '-'}
              </span>
              <span style={{ marginRight: '10px' }}>
                <FaMedal /> {mastery_score}
              </span>
              <span>
                <FaDollarSign />
                {minimum_engagement}
              </span>
            </Row>
          </div>
        </Row>
      </ProjectCardContainer>
    );
  } else {
    return (
      <ProjectCardContainer compressed={compressed}>
        <h4>{full_name}</h4>
        <p>{address}</p>
        <Row justifyContent="space-between">
          <div>
            <Row alignItems="center">
              <span>
                <FaStar /> {customer_ratings > 0 ? customer_ratings : '-'}
              </span>
              <span>
                <FaMedal /> {mastery_score}
              </span>
              <span>
                <FaDollarSign />
                {minimum_engagement}
              </span>
            </Row>
          </div>
          {onView && (
            <div>
              <Button outline onClick={onView}>
                VIEW
              </Button>
            </div>
          )}
        </Row>
      </ProjectCardContainer>
    );
  }
};

export default ProjectCard;
