import Content, { MiddleContainer } from 'components/Content';
import { Column } from 'components/Layout';
import { SearchbarContainer } from 'components/Searchbar/styled';
import styled, { css } from 'styled-components/macro';
import { Row } from 'components/Layout';
export const ResultsContainerContent = styled(Content)`
  position: absolute;
  top: 0;
  left: 0;

  ${MiddleContainer} {
    position: relative;
  }
`;

export const ResultsContainer = styled.div`
  font-size: 1.6rem;
  color: #4b4c5e;
  min-width: 70rem;
  background-color: white;
  position: absolute;
  top: ${props => props.top}px;
  left: 0;
  box-shadow: 0px 3px 6px #00000029;
  padding: 3rem;
  visibility: hidden;
  transition: all 0.3s ease;
  z-index: 99;

  ${props =>
    props.visible &&
    css`
      visibility: visible;
    `}

  ${SearchbarContainer} {
    svg {
      color: #ff9e08;
    }

    input {
      font-size: 1.6rem;
      color: #4b4c5e;
      border-color: #ff9e08;
    }
  }
`;

export const SubContainer = styled.div`
  margin-top: 2rem;
  margin-left: 2rem;
`;

export const FilterResults = styled.span`
  cursor: pointer;
  font-weight: bold;
  font-size: 1.4rem;
  color: #ff9e08;
`;

export const RecommendedResearchers = styled.h4`
  font-size: 1.6rem;
  color: #ff9e08;
  margin-bottom: 2rem;
`;

export const ResearcherContainer = styled(Column)`
  align-items: center;

  img {
    width: 3.6rem;
    object-fit: contain;
    border-radius: 50%;
  }
  h6 {
    font-size: 1.2rem;
  }
  p {
    font-size: 1.2rem;
  }
`;
export const ModalContent = styled(Row)`
  background-color: white;
  padding: 2rem;
  flex-direction: column;
  justify-content: flex-end;
  min-width: 200px;
  min-height: 100px;
}
`;

export const ModalMsg = styled(Column)`
  margin-bottom: 4rem;
  margin-top: 2rem;
`;

export const ModalButton = styled(Column)`
  margin-bottom: 1rem;
`;
