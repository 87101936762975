import CarouselSection from 'components/CarouselSection';
import HeroBanner from 'components/HeroBanner';
import HomeAbout from 'components/HomeAbout';
import LoadingIndicator from 'components/LoadingIndicator';
import ResearchersSection from 'components/ResearchersSection';
import SearchResultsModal from 'components/SearchResultsModal';
import HowItWorks from 'pages/Landing/HowItWorks';
import LandingJoinForm from 'pages/Landing/LandingJoinForm';
import React, { useState } from 'react';
import { HomeContainer } from './styled';
import { ajax } from 'utils/ajax';
import { useAuthState } from 'context/AuthContext';
import querystring from 'querystring';

const marketCarousel = [
  'Future / Trends',
  'Strategy / Business Models',
  'Segmentation / Targeting',
  'Habits, Usage & Attitudes',
  'Innovation / Ideas / Concepts',
  'UX / CX / NPS Research',
  'Technical / Performance Testing',
  'New Product Pricing',
  'Virtual Launch / Test Markets',
  'Creative Testing / Brand Design',
  'Shopper / Channel Understanding',
  'Other Research',
].map((title, index) => ({
  title,
  img: require(`../../assets/carousel-images/market-research/${index + 1}.svg`),
  group: 'markets',
  slug: title
    .toLowerCase()
    .replace(/[\W_]+/g, ' ')
    .split(' ')
    .filter(item => item.length > 0)
    .join('-'),
}));

const algorithmicCarousel = [
  'Decision Sciences',
  'People Analytics',
  'Behavioral Economics',
  'ROI / Mix Modeling',
  'Bio-Analytics / Telemetry',
  'Security / Cyber Defense',
  'Pricing, Promotions, Revenue Growth',
  'Scenario Planning / Simulation',
  'Retail / Supply Chain Analytics',
  'CRM, Digital / Personalized Marketing',
  'Market Sizing / Measurement / Analytics',
  'Other Data Science / Advanced Analytics',
].map((title, index) => ({
  title,
  img: require(`../../assets/carousel-images/data-analytics/${index + 1}.svg`),
  group: 'analytics',
  slug: title
    .toLowerCase()
    .replace(/[\W_]+/g, ' ')
    .split(' ')
    .filter(item => item.length > 0)
    .join('-'),
}));

const researchMLCarousel = [
  'Analytics Maturity and Culture Transformation',
  'Data Integration, Processing and Report Automation',
  'Data Strategy, Engineering, AI/ML Implementation',
  'Graphic Design, Storytelling and Creative Services',
  'Knowledge and Vendor Procurement and Management',
  'Research Operations - sampling, panels, questionnaires',
  'Web, Software, Dashboard Development and Support',
  'Other Research and ML Operations',
].map((title, index) => ({
  title,
  img: require(`../../assets/carousel-images/data-analytics/${index + 1}.svg`),
  group: 'research',
  slug: title
    .toLowerCase()
    .replace(/[\W_]+/g, ' ')
    .split(' ')
    .filter(item => item.length > 0)
    .join('-'),
}));

const Home = () => {
  const { user } = useAuthState();
  const [isShowingResults, setIsShowingResults] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchInput, setSearchInput] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [viewAllQuery, setViewAllQuery] = useState(null);

  const handleSearch = (search, filter) => {
    setLoading(true);
    let query = {
      page_size: 5,
      page: 1,
      search: search,
    };

    if (filter && filter.type === 'specialization')
      query['specialization'] = filter.id;
    else if (filter && filter.type === 'industry')
      query['industry'] = filter.id;

    ajax('GET', `/member/researcher?${querystring.stringify(query)}`).then(
      result => {
        setViewAllQuery(query);
        setLoading(false);
        if (result.code === 200) {
          setSearchResults(result.data);
          setIsShowingResults(true);
        }
      }
    );
  };

  return (
    <HomeContainer>
      {loading ? <LoadingIndicator /> : null}
      <HeroBanner
        showResults={(search, filter) => {
          setSearchInput(search);
          handleSearch(search, filter);
          // setIsShowingResults(!isShowingResults);
        }}
      />
      <HomeAbout />
      <CarouselSection
        data={marketCarousel}
        title="Explore Market Research"
        color="#dce6dd"
        textColor="#524c4a"
      />
      <CarouselSection
        data={algorithmicCarousel}
        title="Explore Data Science &amp; Analytics"
        color="#f7e7bc"
        textColor="#4b4c5e"
      />
      <CarouselSection
        data={researchMLCarousel}
        title="Research/ML Operations"
        color="#ffccbc"
        textColor="#4b4c5e"
      />
      <ResearchersSection />
      <HowItWorks />
      <LandingJoinForm />
      <SearchResultsModal
        visible={isShowingResults}
        searchInput={searchInput}
        searchResults={searchResults}
        onClose={() => setIsShowingResults(false)}
        isLoggedIn={user ? true : false}
        viewAllQuery={viewAllQuery}
        setViewAllQuery={setViewAllQuery}
        setSearchInput={value => setSearchInput(value)}
      />
    </HomeContainer>
  );
};

export default Home;
