import Content, { MiddleContainer } from 'components/Content';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';

export const NavbarLayout = styled(Content)`
  z-index: 1;
  background-color: white;
  border-bottom: 1px solid rgba(101, 99, 123, 0.2);
  height: 5.5rem;

  ${MiddleContainer} {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

export const BrandLogo = styled.img`
  height: 5rem;
  @media (max-width: 500px) {
    // display: none;
    height: 2.5rem;
  }
`;

export const NavbarItemsLayout = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  height: 100%;
`;

export const NavbarItem = styled(Link)`
  color: #4b4c5e;
  font-size: 1.4rem;
  font-weight: bold;
  text-decoration: none;
  // margin-right: 2rem;
  padding: 1rem 2rem;

  @media (max-width: 500px) {
    padding: 1rem 1.5rem;
  }
`;

export const NavbarItemMessage = styled(Link)`
  color: #4b4c5e;
  font-size: 1.4rem;
  font-weight: bold;
  text-decoration: none;
  margin-right: 2rem;
  padding: 1rem 0rem;
  position: relative;
`;

export const NotificationContainer = styled.div`
  position: relative;
  font-size: 2rem;
  color: #4b4c5e;
`;

export const NotificationBadge = styled.div`
  position: absolute;
  top: 0;
  right: -1rem;
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #cc687f;
  color: white;
  border-radius: 50%;
  width: 1.6rem;
  height: 1.6rem;
`;

export const MessageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 10px;
  border-radius: 50%;

  @media (min-width: 600px) {
    padding: 10px;

    &:hover {
      background-color: #f0f0f2;
    }
  }
`;

export const MessageBadge = styled.div`
  position: absolute;
  top: 10px;
  right: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 14px;
  height: 14px;
  // padding: 3px 5px;
  border-radius: 50%;
  font-size: 9px;

  @media (max-width: 600px) {
    top: 7px;
    font-size: 8px;
  }
`;
