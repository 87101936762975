import * as yup from 'yup';

export const clientRegistration = yup.object({
  first_name: yup
    .string()
    .trim()
    .required('First Name is required'),
  last_name: yup
    .string()
    .trim()
    .required('Last Name is required'),
  linked_in_url: yup
    .string()
    .matches(
      '^https?://((www|ww).)?linkedin.com/((in/[^/]+/?)|(pub/[^/]+/((w|d)+/?){3}))$',
      'Enter a valid linkedin URL'
    )
    .required('Linkedin Account ID is required'),
  address: yup
    .string()
    .trim()
    .required('Street Address is required'),
  country: yup
    .string()
    .trim()
    .required('Country is required'),
  postal_code: yup
    .string()
    .trim()
    .required('Zip/Postal Code is required'),
  billing_address: yup
    .string()
    .trim()
    .required('Billing Street Address is required'),
  billing_country: yup
    .string()
    .trim()
    .required('Billing Country is required'),
  billing_postal_code: yup
    .string()
    .trim()
    .required('Billing Zip/Postal Code is required'),
  email: yup
    .string()
    .matches(
      /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w{2,4}([-.]\w+)*$/,
      'Enter a valid email'
    )
    .required('Contact Email is required'),
});
