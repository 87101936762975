import React, { useState, Fragment, useEffect } from 'react';
import {
  PageContent,
  BreadcrumbEnd,
  BreadcrumbLayout,
  // BreadcrumbLink,
  // BreadcrumbMiddle,
  // ModalContent,
  // ModalMsg,
  // ModalButton,
} from './styled';
// import { useParams } from 'react-router-dom';
import { MembershipPlanFees } from 'pages/MembeshipPlanFees';
import { FaChevronRight } from 'react-icons/fa';
import { ajax } from 'utils/ajax';
import LoadingIndicator from 'components/LoadingIndicator';
// import { useHistory } from 'react-router-dom';

export const Fees = () => {
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [currentStep, setCurrentStep] = useState(1);
  const [plans, setPlans] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const url = '/subscription';
    ajax('GET', url).then(result => {
      setLoading(false);
      if (result.code === 200 && result.data && result.data.length > 0) {
        let res = result.data.map((plan, index) => {
          let plan_id = '';
          switch (plan.amount) {
            case 99:
              plan_id =
                process.env.REACT_APP_ENV === 'local' ||
                process.env.REACT_APP_ENV === 'dev' ||
                process.env.REACT_APP_ENV === 'stg'
                  ? // 'price_1KnMnCLbUtPCymV7jvDh6tci'
                    'price_1M97d9DVmERl1Ue4tC56j1tY'
                  : 'price_1KlbHvLbUtPCymV7nqSKdRFK';
              break;
            case 499:
              plan_id =
                process.env.REACT_APP_ENV === 'local' ||
                process.env.REACT_APP_ENV === 'dev' ||
                process.env.REACT_APP_ENV === 'stg'
                  ? //'price_1KnMnCLbUtPCymV7SlbYY3dn'
                    'price_1M97e4DVmERl1Ue4uZIbqzAB'
                  : 'price_1KlbHvLbUtPCymV7XoarVEMs';
              break;
            case 999:
              plan_id =
                process.env.REACT_APP_ENV === 'local' ||
                process.env.REACT_APP_ENV === 'dev' ||
                process.env.REACT_APP_ENV === 'stg'
                  ? //'price_1KnMnCLbUtPCymV743ZNU0dk'
                    'price_1M97eqDVmERl1Ue4pOeKEgui'
                  : 'price_1KlbHvLbUtPCymV7897NPBF0';
              break;
            default:
              plan_id = '';
          }
          return {
            ...plan,
            unit: '$',
            currency: 'usd',
            type: plan.billing_period,
            plan_id: plan_id,
          };
        });
        res = res.filter(
          elem => elem.status && elem.status.toLowerCase() === 'active'
        );
        res = res.sort((a, b) => a.amount - b.amount);
        setPlans(res);
      }
    });
  }, []);

  return (
    <PageContent>
      {loading ? <LoadingIndicator /> : null}
      <BreadcrumbLayout>
        {breadcrumbs.map((item, index) => (
          <Fragment key={item.label}>
            <BreadcrumbEnd>{item.label}</BreadcrumbEnd>

            {index !== breadcrumbs.length - 1 && <FaChevronRight />}
          </Fragment>
        ))}
      </BreadcrumbLayout>
      {currentStep === 1 && <MembershipPlanFees plans={plans} />}
    </PageContent>
  );
};

export default Fees;
