import Button from 'components/Button';
import { Column, Row } from 'components/Layout';
import Modal from 'components/Modal';
import { useAuthState } from 'context/AuthContext';
import React, { useState } from 'react';
import {
  FaDollarSign,
  // FaMedal,
  FaStar,
} from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import {
  CardInfo,
  CardInfoName,
  CardInfoBox,
  CardInfoView,
  CardLayout,
} from './styled';

const UserCard = ({
  id,
  full_name,
  avatar_link,
  // city,
  state,
  country,
  mastery_score,
  minimum_engagement,
  customer_ratings,
  className,
  industries,
  industryFilter,
  specializations,
  specializationsFilter,
  services,
  experience,
}) => {
  const history = useHistory();
  const { isResearcher } = useAuthState();

  const [isShowingModal, setIsShowingModal] = useState(null);
  return (
    <CardLayout className={className} data-freelancer={id}>
      <img
        src={avatar_link || require('../UserMenu/default.webp')}
        alt={full_name}
      />

      <CardInfo>
        <div>
          <CardInfoName style={{ padding: '3px 10px' }}>
            {full_name}
          </CardInfoName>
          <CardInfoBox>
            <div>
              {state ? state + ',' : '-'}
              {country ? country : '-'}
            </div>
            <div>Level {Math.round(mastery_score)} Master</div>
            <div
              style={{
                wordWrap: 'break-all',
                // width: '75%',
              }}
            >
              {services?.map((item, index) => (
                <small key={index}>
                  {' '}
                  {item.name}
                  {index !== services.length - 1 ? ',' : null}
                </small>
              ))}
            </div>
          </CardInfoBox>
          <br />
          {/* {industryFilter?.length > 0 && ( */}
          {/* <CardInfoName
            style={{
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
            }}
          >
            <small>
              <b>Industry:</b>
            </small>
            {industries?.map((item, index) => (
              <small key={index}>
                {' '}
                {item.name}
                {index !== industries.length - 1 ? ',' : null}
              </small>
            ))}
          </CardInfoName> */}
          <CardInfoBox
            style={{
              wordWrap: 'break-all',
              // width: '75%',
            }}
          >
            <small>
              <b>Experience:</b>
            </small>
            {experience?.map((item, index) => (
              <small key={index}>
                {' '}
                {item.company_name}
                {index !== experience.length - 1 ? ',' : null}
              </small>
            ))}
          </CardInfoBox>
          {/* )} */}

          {/* {specializationsFilter?.length > 0 && ( */}
          {/* <CardInfoName
            style={{
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
            }}
          >
            <small>
              <b>Specializations:</b>
            </small>
            {specializations?.map((item, index) => (
              <small key={index}>
                {' '}
                {item.name}
                {index !== specializations.length - 1 ? ',' : null}
              </small>
            ))}
          </CardInfoName> */}
          {/* )} */}

          <Row style={{ marginTop: '15px' }}>
            <Column style={{ display: 'flex', justifyContent: 'center' }}>
              <FaStar />
            </Column>
            <Column>
              <CardInfoBox style={{ display: 'flex' }}>
                {customer_ratings > 0 ? customer_ratings : '-'}
                <span style={{ marginLeft: '10px' }}>
                  FROM:
                  <span style={{ color: 'black' }}>
                    <FaDollarSign size={10} />
                    {minimum_engagement}
                    {'/project'}
                  </span>
                </span>
              </CardInfoBox>
            </Column>
            {/* <Column style={{ marginRight: '10px' }}>
              <span>
                <FaMedal /> {mastery_score}
              </span>
            </Column> */}
            {/* <Column>
              <CardInfoBox>
                Starting at
                <span style={{ color: 'black' }}>
                  <FaDollarSign />
                  {minimum_engagement}
                </span>
              </CardInfoBox>
            </Column> */}
          </Row>
          {/* <Row style={{ marginTop: '15px' }}>
            <Column style={{ marginRight: '10px' }}>
              <span>
                <FaStar /> {customer_ratings > 0 ? customer_ratings : '-'}
              </span>
            </Column>
            <Column style={{ marginRight: '10px' }}>
              <span>
                <FaMedal /> {mastery_score}
              </span>
            </Column>
            <Column>
              <span>
                Starting at
                <FaDollarSign />
                {minimum_engagement}
              </span>
            </Column>
          </Row> */}
          {/* <CardInfoDescription>{address}</CardInfoDescription> */}
        </div>
        {localStorage.getItem('br-authToken') && !isResearcher ? (
          <CardInfoView to={`/researcher/${id}`} style={{ marginTop: '15px' }}>
            View Profile
          </CardInfoView>
        ) : (
          <div
            onClick={() => setIsShowingModal(true)}
            style={{ marginTop: '15px' }}
          >
            View Profile
          </div>
        )}
      </CardInfo>
      <Modal visible={isShowingModal} setVisible={setIsShowingModal} persistent>
        <div
          style={{
            backgroundColor: 'white',
            padding: '2rem',
            flexDirection: 'column',
            justifyContent: 'flex-end',
            maxWidth: `${'300px'}`,
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        >
          <div
            style={{
              marginBottom: '20px',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <b style={{ textAlign: 'center' }}>
              In order to contact a researcher, please login as a client
            </b>
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              marginTop: '30px',
            }}
          >
            <Button
              outline
              style={{ marginRight: '8px', width: '100px' }}
              onClick={() => {
                setIsShowingModal(false);
              }}
            >
              CANCEL
            </Button>
            {!isResearcher && (
              <Button
                style={{ marginLeft: '8px', width: '100px' }}
                onClick={() => {
                  history.push('/login');
                }}
              >
                LOGIN
              </Button>
            )}
          </div>
        </div>
      </Modal>
    </CardLayout>
  );
};

export default UserCard;
