import Button from 'components/Button';
import Input from 'components/Input';
import React, { useState } from 'react';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import {
  BackgroundImage,
  JoinForm,
  LandingHeader,
  LandingJoinFormContent,
  LandingSubHeader,
  JoinButton,
} from './styled';
import { ajax } from 'utils/ajax';
import Modal from 'components/Modal';

const LandingJoinForm = () => {
  const [errorMsg, setErrorMsg] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [memberType, setMemberType] = useState(null);
  const history = useHistory();
  const formik = useFormik({
    initialValues: {
      full_name: '',
      email: '',
    },
    onSubmit: async data => {
      const res = await ajax('GET', `/member/email/${data.email}`);
      if (res.code === 200) {
        const memberData = res.data;
        const type = memberData.member_type_id === 2 ? 'Client' : 'Researcher';
        setMemberType(type);
        setErrorMsg(`User is already existing as a ${type} account.`);
        setShowModal(true);
      } else if (res.code === 404) {
        sessionStorage.setItem('join-fullname', data.full_name);
        sessionStorage.setItem('join-email', data.email);
        history.push({
          pathname: '/login',
          state: { isSignup: true, isResearcher: true },
        });
      }
    },
  });

  return (
    <LandingJoinFormContent>
      <BackgroundImage
        src={require('./assets/background.png')}
        alt="Big Robin"
      />
      <LandingHeader>Join as a researcher</LandingHeader>
      <LandingSubHeader>
        Research for Business. Business for Researchers.
      </LandingSubHeader>
      <JoinForm onSubmit={formik.handleSubmit}>
        <Input
          id="full_name"
          name="full_name"
          label="Full Name"
          required
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
        <Input
          id="email"
          name="email"
          type="email"
          label="Email Address"
          required
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
        <p
          style={{
            margin: '1rem 0',
            color: '#ef5350',
            fontSize: '1.6rem',
            textAlign: 'center',
          }}
        >
          {errorMsg}
        </p>
        <JoinButton type="submit">JOIN</JoinButton>
      </JoinForm>
      <Modal visible={showModal} setVisible={setShowModal} persistent={true}>
        <div
          style={{
            backgroundColor: 'white',
            padding: '2rem',
            flexDirection: 'column',
            justifyContent: 'flex-end',
            maxWidth: '350px',
          }}
        >
          <div style={{ marginBottom: '5rem' }}>{errorMsg}</div>

          <div>
            <Button
              fill
              style={{
                marginBottom: '1rem',
                backgroundColor: '#4b4c5e',
              }}
              onClick={() =>
                history.push({
                  pathname: '/login',
                  state: {
                    isSignup: false,
                    isResearcher: memberType === 'Client' ? false : true,
                  },
                })
              }
            >
              OK
            </Button>
          </div>
        </div>
      </Modal>
    </LandingJoinFormContent>
  );
};

export default LandingJoinForm;
