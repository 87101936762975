import Content from 'components/Content';
import Searchbar from 'components/Searchbar';
import styled, { css } from 'styled-components/macro';

export const ProjectsContent = styled(Content)`
  margin: 4rem 0;
`;

export const ProjectsHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  color: white;
  background-color: ${props => (props.isResearcher ? '#4b4c5e' : '#ff9e08')};
  height: 18rem;
  overflow: hidden;
  padding: 0 4rem;

  @media (max-width: 1000px) {
    height: 25rem;
    display: block;
    position: relative;
    width: auto;
  }

  img {
    transform: scale(1.5) translateY(1.5rem);

    @media (max-width: 1000px) {
      display: block;
      position: absolute;
      bottom: 2rem;
      right: 10rem;
      width: 35%;
    }
  }
`;

export const ProjectsHeaderLayout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: 1000px) {
    width: 100%;
    display: block;
    position: absolute;
    bottom: 0rem;
    left: 0rem;
    z-index: 1;
  }

  h1 {
    font-size: 3rem;

    @media (max-width: 1000px) {
      font-size: 3.2rem;
    }
  }

  p {
    font-size: 1.8rem;

    @media (max-width: 1000px) {
      font-size: 2rem;
    }
  }
`;

export const ProjectsHeader = styled.div`
  margin: 4rem 0;

  @media (max-width: 1000px) {
    margin-left: 4rem;
  }
`;

export const HeaderMenu = styled.div`
  @media (max-width: 1000px) {
    width: 100%;
    display: block;
    overflow-x: auto;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }

  &::-webkit-scrollbar {
    @media (max-width: 1000px) {
      height: 0.5rem;
      background-color: #00ff00;
    }
  }

  div {
    @media (max-width: 1000px) {
      padding-left: 4rem;
      white-space: nowrap;
    }
  }
`;

export const ProjectTabButton = styled.button`
  color: #ffffffbf;
  font-weight: bold;
  font-size: 1.6rem;
  margin-right: 3rem;
  padding: 1rem 0;

  @media (max-width: 1000px) {
    font-size: 1.6rem;
    margin-right: 2rem;
    padding: 2rem 0;
    opacity: 1;
  }

  ${props =>
    props.active &&
    css`
      color: #ffffffff;
    `}
`;

export const ProjectsListContainer = styled.div`
  padding: 2rem 4rem;
  background-color: white;
`;

export const ProjectsSearchbar = styled(Searchbar)`
  width: 50%;

  @media (max-width: 1000px) {
    width: 100%;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
`;

export const NoProjectContainer = styled.div`
  padding: 12rem 0rem;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    padding: 8rem 0rem;
  }

  & p {
    font-size: 2rem;
    font-weight: bold;
    color: #4b4c5e;

    @media (max-width: 768px) {
      font-size: 2.4rem;
    }
  }
`;

export const ProjectItem = styled.div`
  padding: 2rem 0;
  border-bottom: 1px solid #dadadf;

  @media (max-width: 1000px) {
    padding: 2.5rem 0;
  }
`;

export const ProjectItemDate = styled.div`
  color: #65637b;
  opacity: 0.6;
  font-size: 1.4rem;
  margin-bottom: 1rem;

  span {
    margin-right: 1rem;
  }

  @media (max-width: 1000px) {
    font-size: 1.6rem;
  }
`;

export const ProjectItemTitleLayout = styled.div`
  display: flex;
  justify-content: space-between;
  :hover {
    cursor: pointer;
  }
  margin-bottom: 0.25rem;
`;

export const ProjectItemTitle = styled.span`
  font-size: 1.8rem;
  font-weight: bold;
  color: #4b4c5e;

  @media (max-width: 1000px) {
    font-size: 2.2rem;
  }
`;

export const ProjectItemText = styled.span`
  font-size: 1.6rem;
  color: #4b4c5e;
  font-style: italic;

  @media (max-width: 1000px) {
    font-size: 2rem;
  }
`;
