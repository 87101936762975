import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';

export const CardsLayout = styled.div`
  margin: 5rem 0;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 5rem;

  @media (max-width: 900px) {
    grid-template-columns: none;
    grid-template-rows: 1fr;
  }
`;

export const CardLayout = styled.div`
  flex: 1;
  display: flex;

  border-radius: 8px;
  cursor: pointer;
  box-shadow: 0px 3px 6px #00000029;
  background-color: #f0f0f0;

  img {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    object-fit: cover;
    width: 10rem;
  }

  transition: box-shadow 0.3s ease;
  &:hover {
    box-shadow: 0px 3px 6px #00000058;
  }
`;

export const CardInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  padding: 1rem 2rem;
  font-size: 1.2rem;
  font-weight: bold;
`;

export const CardInfoName = styled.p`
  font-size: 1.4rem;
  color: #4b4c5e;
`;

export const CardInfoTitle = styled.p`
  color: #4b4c5e;
  margin-bottom: 1rem;
`;

export const CardInfoDescription = styled.p`
  color: #4b4c5e;
  opacity: 0.72;
  margin-bottom: 1rem;
`;

export const CardInfoView = styled(Link)`
  color: #41804c;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;
