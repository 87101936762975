import Breadcrumb from 'components/Breadcrumb';
import { Loading } from 'components/Loading';
// import { ServiceArchitecture } from 'components/ServiceArchitecture';
import React, { useEffect, useState } from 'react';
import {
  // FaFileAlt,
  FaLink,
  FaMapMarkerAlt,
  FaStar,
  FaStarHalf,
  FaDollarSign,
  FaMedal,
} from 'react-icons/fa';
// import { MdBook, MdLink } from 'react-icons/md';
import { Redirect, useParams, useHistory } from 'react-router-dom';
import { ajax } from 'utils/ajax';
import researchers from 'utils/researcher-data';
import * as SUserPage from './styled';
import Button from 'components/Button';
import SessionModal from 'components/SessionModal';
import './style.css';
import Modal from 'components/Modal';
function UserPage() {
  const [isGettingUser, setIsGettingUser] = useState(true);
  const [user, setUser] = useState(null);
  const params = useParams();
  const history = useHistory();
  const [isOpen, setIsOpen] = useState(false);
  const user_id = parseInt(localStorage.getItem('br-userId'));
  // const [loading, setLoading] = useState(false);
  const [isShowingModal, setIsShowingModal] = useState(null);

  useEffect(() => {
    let isClient = parseInt(localStorage.getItem('br-userId'));

    (async () => {
      const url =
        '/messages/conversation/member/' +
        parseInt(localStorage.getItem('br-userId')) +
        '/researcher/' +
        params.id;
      const response = await ajax('GET', `/member/${params.id}`);
      if (response.code === 200) {
        if (isClient) {
          const convo_response = await ajax('GET', url);
          if (convo_response.code === 200) {
            response.data['conversation_id'] = convo_response.data['id'];
          } else if (convo_response.code === 401) {
            setIsOpen(true);
          }
        }
        setUser(response.data);
      } else if (response.code === 401) {
        setIsOpen(true);
      } else {
        setUser(researchers[+params.id]);
      }
      setIsGettingUser(false);
    })();
  }, [params.id]);

  const handleSendMessage = async () => {
    // const url = '/messages';
    // const payload = {
    //   receiver_id: parseInt(params.id),
    //   sender_id: parseInt(localStorage.getItem('br-userId')),
    //   message_type: 'text',
    //   message: '',
    //   links: '',
    // };
    // if (user.conversation_id && user.conversation_id !== undefined) {
    //   payload['conversation_id'] = user.conversation_id;
    // }
    localStorage.setItem('flag', 1);
    let userData = {};
    const userId = localStorage.getItem('br-userId');
    if (userId) {
      const res = await ajax('GET', `/member/${userId}`);
      if (res.code === 200) {
        userData = res.data;
      } else {
        localStorage.removeItem('br-authToken');
        localStorage.removeItem('br-userId');
        localStorage.removeItem('isResearcher');
      }
    }
    history.push('/messages', {
      conversation_id: user.conversation_id,
      receiver_id: parseInt(params.id),
      receiver: user?.member,
      sender: userData?.member,
      sender_id: parseInt(localStorage.getItem('br-userId')),
    });
    // ajax('POST', url, payload).then(result => {
    //   if (result.code === 200) {
    //     if (user.conversation_id && user.conversation_id !== undefined) {
    //       history.push('/messages', {
    //         conversation_id: user.conversation_id,
    //         researcher_id: params.id,
    //       });
    //     } else {
    //       history.push('/messages');
    //     }
    //   } else if (result.code === 401) {
    //     setIsOpen(true);
    //   }
    // });
  };

  const [isMemberSubscribed, setIsMemberSubscribed] = useState(false);

  const checkActivePlan = () => {
    (async () => {
      try {
        // setLoading(true);
        const subs_url = '/member/' + user_id + '/subscription/active';
        const subs_res = await ajax('GET', subs_url);
        if (
          subs_res.code === 200 &&
          subs_res.data &&
          Object.keys(subs_res.data).length > 0
        ) {
          setIsMemberSubscribed(true);
        } else {
          setIsMemberSubscribed(false);
        }
      } catch (err) {
        console.log(err);
        // setLoading(false);
      }
    })();
  };

  useEffect(() => {
    checkActivePlan();
  }, []);

  if (isGettingUser) return <Loading />;

  if (!user) return <Redirect to="/researchers" />;
  return (
    <SUserPage.PageContent>
      <Breadcrumb
        path={[
          { label: 'RESEARCHERS', to: '/researchers' },
          { label: user.member.full_name?.toUpperCase() },
        ]}
      />
      <SUserPage.Container>
        <SUserPage.SummaryLayout>
          <SUserPage.Avatar
            src={
              user.profile_picture &&
              user.profile_picture.length > 0 &&
              user.profile_picture[0]['url']
                ? user.profile_picture[0]['url']
                : require('../../components/UserMenu/default.webp')
            }
            alt={user.full_name}
          />
          <SUserPage.Name>{user.member.full_name}</SUserPage.Name>
          <SUserPage.Title>{user.member.area || null}</SUserPage.Title>
          <SUserPage.Location>
            <FaMapMarkerAlt />
            {user.member.state}, {user.member.country}
          </SUserPage.Location>
          <p style={{ margin: '2rem 0 2rem' }}>
            <small>
              <b>EXPERTISE:</b>
            </small>
            {user?.services_offered?.length > 0
              ? user?.services_offered?.map((item, index) => (
                  <small key={index}>
                    {' '}
                    {item.name}
                    {index !== user.services_offered.length - 1 ? ',' : null}
                  </small>
                ))
              : '-'}
          </p>
          {/* <p style={{ margin: '1rem 0 2rem' }}>
            <small>
              <b>COMPANY:</b>
            </small>
            {user.member.years_of_experience !== null ? (
              <small>{user.member.years_of_experience}</small>
            ) : (
              '-'
            )}
          </p> */}
          <SUserPage.MetricsLayout style={{ marginBottom: '2rem' }}>
            {user.member.customer_ratings !== null && (
              <SUserPage.Metrics>
                <h3
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <FaStar style={{ width: '20px', margin: '5px' }} />{' '}
                  <span style={{ fontWeight: 'normal' }}>
                    {user.member.customer_ratings > 0
                      ? Number(user.member.customer_ratings).toFixed(1)
                      : '-'}
                    (
                    {user.member.customer_ratings > 0
                      ? user.member.customer_rating_count
                      : '-'}
                    )
                  </span>
                </h3>
                <h6>Reviews</h6>
              </SUserPage.Metrics>
            )}
            <span style={{ fontSize: '3rem' }}>|</span>
            {user.member.mastery_score !== null && (
              <SUserPage.Metrics>
                <h3
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <FaMedal style={{ width: '20px', margin: '5px' }} />{' '}
                  <span style={{ fontWeight: 'normal' }}>
                    {Math.round(user.member.mastery_score) || 0}
                    {/* {user.member.mastery_score || 0} */}
                  </span>
                </h3>
                <h6>Mastery level</h6>
              </SUserPage.Metrics>
            )}
            <span style={{ fontSize: '3rem' }}>|</span>
            {user.member.years_of_experience !== null && (
              <SUserPage.Metrics>
                <h3>
                  <span style={{ fontWeight: 'normal' }}>
                    {user.member.years_of_experience >= 15
                      ? '15+'
                      : user.member.years_of_experience}
                  </span>
                </h3>
                <h6>Years of Experience</h6>
              </SUserPage.Metrics>
            )}
          </SUserPage.MetricsLayout>
          {user.member.minimum_engagement !== null && (
            <SUserPage.Metrics>
              <h3
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  // justifyContent: 'center',
                }}
              >
                <small style={{ fontSize: '1.4rem' }}>
                  <b>FROM:</b>
                </small>
                <FaDollarSign
                  style={{ width: '8px', height: '1.4rem', margin: '5px' }}
                />{' '}
                {/* <span style={{ fontWeight: 'normal' }}> */}
                <small style={{ fontSize: '1.4rem' }}>
                  {user.member.minimum_engagement || 0}
                </small>
                {/* </span> */}
                <small style={{ fontSize: '1.4rem' }}>/project</small>
              </h3>
            </SUserPage.Metrics>
          )}
          {/* <SUserPage.MetricsLayout>
            {user.member.years_of_experience !== null && (
              <SUserPage.Metrics>
                <h3>{user.member.years_of_experience}</h3>
                <h6>YEARS OF EXPERIENCE</h6>
              </SUserPage.Metrics>
            )}
            {user.member.jobs_completed !== null && (
              <SUserPage.Metrics>
                <h3>{user.member.jobs_completed || 0}</h3>
                <h6>JOBS COMPLETED</h6>
              </SUserPage.Metrics>
            )}
          </SUserPage.MetricsLayout> */}
          {/* <SUserPage.AchievementsLayout>
            {researcher.achievements.map((achievement, index) => {
              const Icon = achievement.icon;
              return (
                <SUserPage.Achievement key={index}>
                  <SUserPage.AchievementIconContainer>
                    <Icon />
                  </SUserPage.AchievementIconContainer>
                  <p>{achievement.label}</p>
                </SUserPage.Achievement>
              );
            })}
          </SUserPage.AchievementsLayout> */}

          <SUserPage.ButtonsLayout>
            {localStorage.getItem('isResearcher') === 'false' &&
            Number(localStorage.getItem('br-userId')) ? (
              <>
                <Button
                  outline
                  onClick={() => {
                    // Number(localStorage.getItem('br-userId')) &&
                    isMemberSubscribed
                      ? handleSendMessage()
                      : setIsShowingModal(true);
                  }}
                >
                  SEND A MESSAGE
                </Button>
                <Button
                  onClick={() => {
                    // Number(localStorage.getItem('br-userId')) &&
                    isMemberSubscribed
                      ? history.push(`/my-projects-share/${params.id}`)
                      : setIsShowingModal(true);
                  }}
                >
                  SEND A PROJECT INVITE
                </Button>
              </>
            ) : Number(localStorage.getItem('br-userId')) ===
              Number(params.id) ? (
              <Button
                backgroundColor="#65637b"
                onClick={() => {
                  history.push(`/register`);
                }}
              >
                EDIT PROFILE
              </Button>
            ) : (
              <div>
                <Button outline onClick={() => setIsShowingModal(true)}>
                  SEND A MESSAGE
                </Button>
                <Button onClick={() => setIsShowingModal(true)}>
                  SEND A PROJECT INVITE
                </Button>
              </div>
            )}
          </SUserPage.ButtonsLayout>
        </SUserPage.SummaryLayout>

        <SUserPage.InfoLayout>
          <p>
            <autoCapitalize>
              <b>RESEARCHER HIGHLIGHTS :</b>
            </autoCapitalize>
            <br />
            <small> {user?.member?.selling_line}</small>
          </p>

          {(user.research_highlights || []).length > 0 && (
            <SUserPage.Label style={{ margin: '2rem 0' }}>
              <FaLink />
              FEATURED PROJECTS
            </SUserPage.Label>
          )}

          {(user.research_highlights || []).map((project, index) => (
            <SUserPage.Project key={index}>
              <SUserPage.ProjectTitle>{project.project}</SUserPage.ProjectTitle>
              <div className="flex-div">
                <SUserPage.ImageContainer>
                  <SUserPage.ProjectImage
                    src={
                      project.attachments &&
                      project.attachments.length > 0 &&
                      project.attachments[0] &&
                      (project.attachments[0]['url'] ||
                        project.attachments[0]['key'])
                        ? project.attachments[0]['url'] ||
                          project.attachments[0].key.split('?')[0]
                        : 'https://image.freepik.com/free-vector/business-performance-analysis-with-graphs_53876-66260.jpg'
                    }
                    alt={project.project}
                    title={project.project}
                  />
                </SUserPage.ImageContainer>
                <SUserPage.ProjectDescription>
                  {/* {project.description} */}
                  {project.synopsis && (
                    <>
                      <SUserPage.ProjectResponsibilityLabel>
                        SERVICES PROVIDED, ACTIONS AND RESULTS
                      </SUserPage.ProjectResponsibilityLabel>
                      <SUserPage.ProjectResponsbility>
                        {project.synopsis}
                      </SUserPage.ProjectResponsbility>
                    </>
                  )}
                </SUserPage.ProjectDescription>
              </div>
            </SUserPage.Project>
          ))}
          <p style={{ margin: '1rem 0 2rem' }}>
            <autoCapitalize>
              <b>SPECIALIZATION:</b>
            </autoCapitalize>
            {user?.business_specialization?.length > 0
              ? user?.business_specialization?.map((item, index) => (
                  <small key={index}>
                    {' '}
                    {item.name}
                    {index !== user.business_specialization.length - 1
                      ? ','
                      : null}
                  </small>
                ))
              : '-'}
          </p>
          <p style={{ margin: '1rem 0 2rem' }}>
            <autoCapitalize>
              <b>INDUSTRY EXPERTISE:</b>
            </autoCapitalize>
            {user?.industry?.length > 0
              ? user?.industry.map((item, index) => (
                  <small key={index}>
                    {' '}
                    {item.name}
                    {index !== user.industry.length - 1 ? ',' : null}
                  </small>
                ))
              : '-'}
          </p>
          <p style={{ margin: '1rem 0 2rem' }}>
            <autoCapitalize>
              <b>SIZE OF BUSINESSES WORKED:</b>
            </autoCapitalize>
            {user?.sizes_of_businesses_worked?.length > 0
              ? user?.sizes_of_businesses_worked.map((item, index) => (
                  <small key={index}>
                    {' '}
                    {item}
                    {index !== user.sizes_of_businesses_worked.length - 1
                      ? ','
                      : null}
                  </small>
                ))
              : '-'}
          </p>
          <p style={{ margin: '1rem 0 2rem' }}>
            <autoCapitalize>
              <b>SCOPE OF SERVICES:</b>
            </autoCapitalize>
            {user?.scope_of_services?.length > 0
              ? user?.scope_of_services.map((item, index) => (
                  <small key={index}>
                    {' '}
                    {item.name}
                    {index !== user.scope_of_services.length - 1 ? ',' : null}
                  </small>
                ))
              : '-'}
          </p>
          <p style={{ margin: '1rem 0 2rem' }}>
            <autoCapitalize>
              <b>PREFERRED LANGUAGES:</b>
            </autoCapitalize>
            {user?.languages?.length > 0
              ? user?.languages.map((item, index) => (
                  <small key={index}>
                    {' '}
                    {item.name}
                    {index !== user.languages.length - 1 ? ',' : null}
                  </small>
                ))
              : '-'}
          </p>
          <p style={{ margin: '1rem 0 2rem' }}>
            <autoCapitalize>
              <b>MARKET COVERAGE:</b>
            </autoCapitalize>
            {user?.preferred_markets?.length > 0
              ? user?.preferred_markets.map((item, index) => (
                  <small key={index}>
                    {' '}
                    {item.name}
                    {index !== user.preferred_markets.length - 1 ? ',' : null}
                  </small>
                ))
              : '-'}
          </p>
          {/* <SUserPage.Label>
            <FaFileAlt />
            SERVICE ARCHITECTURE
          </SUserPage.Label>
          <ServiceArchitecture data={user.member.service_architecture} /> */}
          {/* {user.member_experience.length > 0 && (
            <SUserPage.Label>
              <MdBook />
              EXPERIENCE
            </SUserPage.Label>
          )} */}
          {/* {[...user.member_experience].map((experience, index) => (
            <SUserPage.ExperienceLayout key={index}>
              <SUserPage.ExperienceCompanyLayout>
                <SUserPage.ExperienceCompany>
                  {experience.client_name}
                </SUserPage.ExperienceCompany>
                <SUserPage.ExperienceYear>
                  {experience.startYear}-{experience.endYear}
                </SUserPage.ExperienceYear>
              </SUserPage.ExperienceCompanyLayout>
              <SUserPage.ExperiencePosition>
                {experience.position}
              </SUserPage.ExperiencePosition>
              <SUserPage.ExperienceDescription>
                {experience.description}
              </SUserPage.ExperienceDescription>
              <SUserPage.ExperienceTagsLayout>
                {experience.tags !== undefined &&
                  experience.tags.map((tag, tagIndex) => (
                    <SUserPage.ExperienceTag key={tagIndex}>
                      {tag}
                    </SUserPage.ExperienceTag>
                  ))}
              </SUserPage.ExperienceTagsLayout>
            </SUserPage.ExperienceLayout>
          ))} */}

          {(user.ratings || []).length > 0 && (
            <>
              <SUserPage.Label>
                <FaStar />
                RATINGS
              </SUserPage.Label>
              <SUserPage.RatingsContainer>
                <SUserPage.RatingsLayout>
                  {user.ratings.map((rating, index) => (
                    <SUserPage.Rating key={index}>
                      <SUserPage.RatingInfoLayout>
                        <SUserPage.RatingStarsLayout>
                          {[...Array(Math.floor(rating.stars))].map(
                            (_, starIndex) => (
                              <FaStar key={starIndex} />
                            )
                          )}
                          {rating.stars % 1 !== 0 && <FaStarHalf />}
                        </SUserPage.RatingStarsLayout>
                        <SUserPage.RatingTitle>
                          {rating.title}
                        </SUserPage.RatingTitle>
                        <SUserPage.RatingDescription>
                          {rating.description}
                        </SUserPage.RatingDescription>
                      </SUserPage.RatingInfoLayout>
                    </SUserPage.Rating>
                  ))}
                </SUserPage.RatingsLayout>
                <SUserPage.RatingSeeMoreLayout>
                  <SUserPage.SeeMoreLink to="/">
                    SEE ALL REVIEWS
                  </SUserPage.SeeMoreLink>
                </SUserPage.RatingSeeMoreLayout>
              </SUserPage.RatingsContainer>
            </>
          )}
          {/* {user.member_reference.length > 0 && (
            <>
              <SUserPage.Label>
                <MdLink />
                BLOGS AND OTHER REFERENCES
              </SUserPage.Label>
              <SUserPage.BlogsLayout>
                {user.member_reference.map((reference, index) => (
                  <SUserPage.Blog key={index}>
                    <img src={reference.image} alt={reference.title} />
                    <SUserPage.BlogInfoLayout>
                      {reference.member_reference_type_id === 2 && <p>BLOG</p>}
                      {reference.member_reference_type_id === 1 && (
                        <p>ARTICLE</p>
                      )}
                      <h6>{reference.title}</h6>
                    </SUserPage.BlogInfoLayout>
                  </SUserPage.Blog>
                ))}
              </SUserPage.BlogsLayout>
            </>
          )} */}
        </SUserPage.InfoLayout>
      </SUserPage.Container>
      <SessionModal
        isOpen={isOpen}
        setModalVisible={setIsOpen}
        message={'Your session has expired.'}
      />
      <Modal visible={isShowingModal} setVisible={setIsShowingModal} persistent>
        <div
          style={{
            backgroundColor: 'white',
            padding: '2rem',
            flexDirection: 'column',
            justifyContent: 'flex-end',
            maxWidth: `${'300px'}`,
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        >
          <div
            style={{
              marginBottom: '2rem',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <b style={{ textAlign: 'center' }}>
              Become a member to connect with the researchers
            </b>
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              marginTop: '2rem',
            }}
          >
            <Button
              outline
              style={{ marginRight: '8px', width: '100px' }}
              onClick={() => {
                setIsShowingModal(false);
              }}
            >
              Cancel
            </Button>

            <Button
              style={{ marginLeft: '8px' }}
              onClick={() => {
                history.push('/member-subscription');
              }}
            >
              Browse plans
            </Button>
          </div>
        </div>
      </Modal>
    </SUserPage.PageContent>
  );
}

export default UserPage;
