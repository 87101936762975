import Content, { MiddleContainer } from 'components/Content';
import styled from 'styled-components/macro';

export const AboutContent = styled(Content)`
  flex: 1;

  ${MiddleContainer} {
    padding: 4rem;

    @media (max-width: 768px) {
      padding: 4rem 2rem;
    }
  }
`;

export const PageTitle = styled.h1`
  font-size: 3.2rem;
  margin-bottom: 2rem;
  color: #4b4c5e;

  @media (max-width: 768px) {
    font-size: 4rem;
    text-align: center;
  }
`;

export const PageDesc = styled.p`
  font-size: 1.8rem;

  @media (max-width: 768px) {
    font-size: 2.4rem;
    text-align: center;
  }
`;

export const AboutGrid = styled.div`
  margin: 4rem 0rem 8rem;
  display: grid;

  @media (max-width: 768px) {
    grid-template-rows: 1fr 1fr 1fr;
    grid-row-gap: 12rem;
    margin-top: 8rem;
  }

  @media (min-width: 769px) {
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 12rem;
  }
`;

export const AboutGridItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    font-size: 1.75rem;
    font-weight: bold;
    text-align: center;
    color: #4b4c5e;

    @media (max-width: 768px) {
      font-size: 2.25rem;
    }
  }
`;

export const AGItemImageContainer = styled.div`
  display: flex;
  align-item: center;
  justify-content: center;
  width: 8rem;
  height: 8rem;
  padding: 2rem;
  border-radius: 50%;
  background-color: #f0edec;
  margin-bottom: 2rem;

  img {
    width: 100%;
    height: 100%;
  }
`;
