import Content, { MiddleContainer } from 'components/Content';
import styled from 'styled-components/macro';

export const FooterContent = styled(Content)`
  padding: 2.5rem 0;
  background-color: rgb(101, 99, 123, 0.05);

  color: #4b4c5e;

  ${MiddleContainer} {
    display: flex;
    justify-content: space-between;

    @media (max-width: 768px) {
      display: block;
    }
  }

  div {
    @media (max-width: 768px) {
      padding-bottom: 4rem;
    }
  }

  p,
  svg {
    font-size: 1.4rem;

    @media (max-width: 768px) {
      font-size: 2.4rem;
    }
  }

  svg {
    vertical-align: middle;
  }

  p {
    margin-bottom: 1rem;

    @media (max-width: 768px) {
      margin-bottom: 1rem;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const AddressContent = styled.p`
  max-width: 32rem;
  margin-bottom: 0rem !important;

  @media (max-width: 768px) {
    max-width: 100%;
  }
`;

export const LeftFooterContent = styled.div`
  display: flex;

  @media (max-width: 768px) {
    display: block;
  }
`;

export const FooterHeader = styled.h3`
  font-size: 3.6rem;
  margin-bottom: 1rem;

  @media (max-width: 768px) {
    margin-bottom: 1.5rem;
  }
`;

export const FooterSocialLayout = styled.div`
  display: flex;
  a {
    margin-right: 2rem;
    color: inherit;

    @media (max-width: 768px) {
      margin-right: 4rem;

      &:last-child {
        margin-right: 0;
      }
    }
  }
  svg {
    font-size: 2.4rem;

    @media (max-width: 768px) {
      font-size: 5rem;
    }
  }
`;

export const DirectoryContainer = styled.div`
  margin-top: 1rem;
  margin-left: 5rem;

  @media (max-width: 768px) {
    margin-left: 0rem;
  }

  p {
    a {
      color: #4b4c5e;
      text-decoration: none;
    }
  }
`;
