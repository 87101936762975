import Button from 'components/Button';
import { format, parseJSON } from 'date-fns';
import React, { useCallback, useState, useEffect } from 'react';
import headerSvg from './header.svg';
import { useHistory } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import {
  ProjectItem,
  ProjectItemDate,
  ProjectItemText,
  ProjectItemTitle,
  ProjectItemTitleLayout,
  ProjectsContent,
  ProjectsHeader,
  HeaderMenu,
  ProjectsHeaderContainer,
  ProjectsHeaderLayout,
  ProjectsListContainer,
  ProjectsSearchbar,
  ProjectTabButton,
  NoProjectContainer,
} from './styled';
import { useAuthState, MEMBER_TYPES } from 'context/AuthContext';
import { ajax } from 'utils/ajax';
import querystring from 'querystring';
import LoadingIndicator from 'components/LoadingIndicator';
import SessionModal from 'components/SessionModal';
import styled from 'styled-components';
import ProjectListingTabular from 'components/ProjectListingTabular';
import BrowsePlanModal from 'components/BrowsePlanModal';

const formatDate = a => {
  try {
    a = parseJSON(a);
    a = format(a, 'MMM d, yyyy');
    return a;
  } catch (_err) {
    return 'x';
  }
};

const formatTime = a => {
  try {
    a = parseJSON(a);
    a = format(a, 'h:mm a');
    return a;
  } catch (_err) {
    return 'y';
  }
};

const MyPaginate = styled(ReactPaginate).attrs({
  activeClassName: 'active',
})`
  margin-bottom: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  list-style-type: none;
  padding: 0 5rem;
  li a {
    border-radius: 7px;
    padding: 0.1rem 1rem;
    cursor: pointer;
  }
  li.previous a,
  li.next a,
  li.break a {
    border-color: transparent;
  }
  li.active {
    background-color: #4b4c5e;
    border-color: transparent;
    color: white;
    min-width: 32px;
  }
  li.disabled a {
    color: grey;
  }
  li.disable,
  li.disabled a {
    cursor: default;
  }
`;

const MyProjects = () => {
  const { user, isMemberSubscribed, isResearcher } = useAuthState();
  const [isBrowseModal, setIsBrowseModal] = useState(false);

  const tabs =
    user.member.member_type_id === 2
      ? [
          { value: 'All', label: 'All' },
          { value: 4, label: 'Draft' },
          { value: 3, label: 'In Discussion' },
          { value: 2, label: 'In Progress' },
          { value: 5, label: 'Finalisation' },
          { value: 1, label: 'Complete' },
        ]
      : [
          { value: 'All', label: 'All' },
          { value: 3, label: 'In Discussion' },
          { value: 2, label: 'In Progress' },
          { value: 5, label: 'Finalisation' },
          { value: 1, label: 'Complete' },
        ];

  const project_labels =
    user.member.member_type_id === 2
      ? {
          1: 'Completed',
          2: 'In Progress',
          3: 'In Discussion',
          4: 'Draft',
          5: 'Finalisation',
        }
      : {
          1: 'Completed',
          2: 'In Progress',
          3: 'In Discussion',
          5: 'Finalisation',
        };
  const history = useHistory();
  const [tab, setTab] = useState('All');
  const [projects, setProjects] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const itemsPerPage = 5;
  const memberType = parseInt(localStorage.getItem('br-memberType'));
  const initStep = user.member.first_name;
  const getMyProjects = useCallback(async () => {
    (async () => {
      setLoading(true);
      const member_id = localStorage.getItem('br-userId');
      //let filter = { page_size: 9, page: page };
      let filter = {};
      if (page === 0) {
        return false;
      }
      let url = `/member/${member_id}/${
        memberType === 3 ? 'assigned_projects' : 'project'
      }?page=${page}&page_size=${itemsPerPage}`;
      if (tab !== 'All') {
        filter['status'] = tab;
      }

      if (searchText !== '') {
        filter['search'] = searchText;
      }

      if (Object.keys(filter).length > 0) {
        url += '&' + querystring.stringify(filter);
      }
      try {
        const projects = await ajax('GET', url);
        if (projects.code === 200 && projects.data.length === 0) {
          setLoading(false);
          setProjects([]);
          setPage(1);
          setTotalPages(1);
        } else if (projects.code === 200 && projects.data.length > 0) {
          if (!isResearcher) {
            const user_id = parseInt(localStorage.getItem('br-userId'));
            const subs_url = '/member/' + user_id + '/subscription/active';
            const subs_res = await ajax('GET', subs_url);
            if (subs_res.code === 200 && subs_res.data) {
              projects.data.map(project => {
                const member_subs = subs_res.data;
                const percentage = member_subs['downpayment_percentage'];
                const mobilisation_percent = percentage / 100;
                // const total_mobilisation_fee =
                //   project.actual_price -
                //   project.actual_price * mobilisation_percent;
                // if (project.project_status_id === 1) project['balance'] = 0;
                // if (project.project_status_id === 2)
                project['balance'] = member_subs['remaining_balance'];
              });
              setLoading(false);
            } else if (subs_res.code === 401) {
              setLoading(false);
              setIsOpen(true);
            } else {
              setLoading(false);
            }
          }
          setProjects(projects.data);
          setTotalPages(Math.ceil(projects.meta.total_number / itemsPerPage));
          setLoading(false);
        } else if (projects.code === 401) {
          setLoading(false);
          setIsOpen(true);
        }
      } catch (err) {
        setLoading(false);
      }
    })();
  });

  const handleTabChange = tab => {
    setPage(1);
    setTab(tab);
  };

  const handleSearch = () => {
    getMyProjects();
  };

  const handlePageClick = event => {
    setPage(event.selected + 1);
  };

  useEffect(() => {
    getMyProjects();
  }, [tab, page]);

  const handleRowClick = (id = '') => {
    history.push(`/project/${id}`);
  };

  return (
    <>
      {loading ? <LoadingIndicator /> : null}
      <ProjectsContent>
        <ProjectsHeaderContainer
          isResearcher={
            user && user.member.member_type_id === +MEMBER_TYPES.RESEARCHER
          }
        >
          <ProjectsHeaderLayout>
            <ProjectsHeader>
              <h1>
                Hey there,{' '}
                {user?.member?.full_name
                  ? user.member.full_name
                  : user?.member?.first_name
                  ? user.member.first_name + ' ' + user.member.last_name
                  : localStorage.getItem('isResearcher') === 'false'
                  ? 'Client'
                  : 'Researcher'}
                !
              </h1>
              <p>Have a productive day today.</p>
            </ProjectsHeader>

            <HeaderMenu>
              <div>
                {tabs.map((t, index) => (
                  <ProjectTabButton
                    key={index}
                    active={tab === t.value}
                    onClick={() => handleTabChange(t.value)}
                  >
                    {t.label}
                  </ProjectTabButton>
                ))}
              </div>
            </HeaderMenu>
          </ProjectsHeaderLayout>
          <img src={headerSvg} alt="" />
        </ProjectsHeaderContainer>
        <ProjectsListContainer>
          <ProjectsSearchbar
            placeholder="Search Project"
            value={searchText}
            onChange={text => {
              // const trimmedText = text ? text.trim() : '';
              setSearchText(text);
            }}
            onKeyPress={event => {
              if (event === 'Enter') {
                handleSearch();
              }
            }}
          />
          {user.member.member_type_id === +MEMBER_TYPES.MEMBER && (
            <Button
              onClick={() =>
                initStep === '' || !initStep
                  ? history.push('/register')
                  : isMemberSubscribed
                  ? history.push('/create-project')
                  : setIsBrowseModal(true)
              }
              style={
                user.member.member_type_id === +MEMBER_TYPES.RESEARCHER
                  ? { backgroundColor: '#4b4c5e', marginTop: '12px' }
                  : { backgroundColor: '#ff9e08', marginTop: '12px' }
              }
            >
              CREATE A PROJECT
            </Button>
          )}

          <div style={{ marginTop: '16px' }}>
            <ProjectListingTabular
              projects={projects}
              project_labels={project_labels}
              handleRowClick={handleRowClick}
            />
          </div>

          {projects && projects.length > 0 ? (
            projects.map((project, index) => {
              return false;
              // return (
              //   <ProjectItem
              //     key={index}
              //     onClick={() => history.push(`/project/${project.id}`)}
              //   >
              //     <ProjectItemDate>
              //       <span>{formatDate(project.created_date)}</span>
              //       <span>{formatTime(project.created_date)}</span>
              //     </ProjectItemDate>
              //     <ProjectItemTitleLayout>
              //       {project.title !== '' ? (
              //         <ProjectItemTitle>{project.title}</ProjectItemTitle>
              //       ) : (
              //         <ProjectItemTitle
              //           style={{
              //             fontStyle: 'italic',
              //             color: '#4B4C5E',
              //             opacity: 0.6,
              //           }}
              //         >
              //           Untitled
              //         </ProjectItemTitle>
              //       )}
              //       {project.project_status_id === 1 ||
              //       project.project_status_id === 2 ? (
              //         <p style={{ textAlign: 'right' }}>
              //           <ProjectItemText>
              //             {project_labels[project.project_status_id]}
              //           </ProjectItemText>
              //           <br />
              //           <ProjectItemText>
              //             (Project Cost: ${project.actual_price}
              //             {project.balance >= 0
              //               ? `, Remaining Balance: $${project.balance}`
              //               : null}
              //             )
              //           </ProjectItemText>
              //         </p>
              //       ) : (
              //         <ProjectItemText>
              //           {project_labels[project.project_status_id]}
              //         </ProjectItemText>
              //       )}
              //     </ProjectItemTitleLayout>
              //     <ProjectItemText>{project.description}</ProjectItemText>
              //   </ProjectItem>
              // );
            })
          ) : loading ? (
            <NoProjectContainer>
              <p>Loading . . .</p>
            </NoProjectContainer>
          ) : (
            <NoProjectContainer>
              <p>No Projects to display.</p>
            </NoProjectContainer>
          )}
          <MyPaginate
            breakLabel="..."
            nextLabel="Next >"
            onPageChange={handlePageClick}
            initialPage={page}
            pageRangeDisplayed={itemsPerPage}
            pageCount={totalPages}
            previousLabel="< Previous"
            renderOnZeroPageCount={null}
            forcePage={page - 1}
          />
        </ProjectsListContainer>
      </ProjectsContent>
      <SessionModal
        isOpen={isOpen}
        setModalVisible={setIsOpen}
        message={'Your session has expired.'}
      />
      <BrowsePlanModal visible={isBrowseModal} setVisible={setIsBrowseModal} />
    </>
  );
};

export default MyProjects;
