import Button from 'components/Button';
import {
  DropdownLayout,
  DropdownValueLayout,
} from 'components/Dropdown/styled';
import useClickOutside from 'hooks/useClickOutside';
import useNodeState from 'hooks/useNodeState';
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { FaCaretDown } from 'react-icons/fa';
import {
  DropdownPopupContainer,
  DropdownPopupMenuButtonsContainer,
  DropdownPopupMenuContainer,
} from './styled';

const DropdownPopup = ({
  className,
  disabled,
  icon,
  label,
  isHighlighted,
  children,
  onClear = () => {},
  onApply = () => {},
  onChangeVisibility,
}) => {
  const [isShowingMenu, setIsShowingMenu] = useState(false);
  const [menuStyle, setMenuStyle] = useState({ top: '0px' });
  const [containerRefCB, containerEl, containerRef] = useNodeState();
  const isSubmittedRef = useRef(false);

  useClickOutside(
    containerRef,
    isShowingMenu,
    useCallback(() => {
      setIsShowingMenu(false);
    }, [])
  );

  useLayoutEffect(() => {
    let isMobile = window.matchMedia('(max-width: 768px)').matches;
    if (isShowingMenu && containerEl && !isMobile) {
      const box = containerEl.getBoundingClientRect();
      setMenuStyle({ top: `calc(${box.height}px + 1rem)` });
    }
  }, [containerEl, isShowingMenu]);

  useEffect(() => {
    if (onChangeVisibility) {
      onChangeVisibility(isShowingMenu, { submitted: isSubmittedRef.current });
      if (isSubmittedRef.current) {
        isSubmittedRef.current = false;
      }
    }
  }, [isShowingMenu, onChangeVisibility]);

  return (
    <DropdownPopupContainer
      isShowing={isShowingMenu}
      isHighlighted={isHighlighted}
      ref={containerRefCB}
      className={className}
    >
      <DropdownLayout
        onClick={() =>
          !disabled && setIsShowingMenu(isShowingMenu => !isShowingMenu)
        }
      >
        {icon || null}
        <DropdownValueLayout>{label}</DropdownValueLayout>
        {!disabled && <FaCaretDown />}
      </DropdownLayout>
      {isShowingMenu && (
        <DropdownPopupMenuContainer style={menuStyle}>
          {children}
          <DropdownPopupMenuButtonsContainer>
            <Button
              simple
              onClick={() => {
                onClear();
                setIsShowingMenu(false);
              }}
            >
              CLEAR
            </Button>
            <Button
              onClick={() => {
                onApply();
                if (localStorage.getItem('allow') === 'false') {
                  isSubmittedRef.current = true;
                  setIsShowingMenu(false);
                }
                setIsShowingMenu(false);
              }}
            >
              APPLY
            </Button>
          </DropdownPopupMenuButtonsContainer>
        </DropdownPopupMenuContainer>
      )}
    </DropdownPopupContainer>
  );
};

export default DropdownPopup;
