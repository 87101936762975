import { SPReactTooltip } from 'pages/ShareProject';
import React from 'react';
import { FaInfoCircle } from 'react-icons/fa';
import {
  PlanContainer,
  PlanMainContent,
  PlanTitle,
  PlanLabel,
  PlanAmount,
  PlanType,
  PlanDescription,
  CurrentPlanLabel,
  RecommendedPlanLabel,
} from './styled';
// import { BsSlash } from 'react-icons/bs';
import './style.css';

const PlanCard = ({
  title,
  amount,
  unit,
  type,
  description,
  selected,
  currentPlan = false,
  recommended = false,
  ...props
}) => {
  const splitDesc = description.split(/\n/);

  return (
    <PlanContainer
      style={{ cursor: 'pointer', maxWidth: '350px', position: 'relative' }}
      selected={selected}
      {...props}
    >
      <PlanMainContent>
        <PlanTitle style={{}}>{title}</PlanTitle>
        <PlanLabel style={{ position: 'relative' }}>
          <PlanAmount>{unit + amount}</PlanAmount>
          <PlanType>{' / ' + type}</PlanType>
        </PlanLabel>
        {amount === 999 && (
          <h6
            style={{
              marginBottom: '10px',
              // display: 'flex',
            }}
            className="tooltip-mobile"
          >
            FREE for a limited time with Promo code
            <FaInfoCircle
              style={{
                marginLeft: '0.5rem',
                width: '1.5rem',
                position: 'relative',
                top: '3px',
              }}
              data-tip
              data-for="identity"
              color="#ff9e08"
            />
            <p
              style={{
                fontSize: '2rem',
                fontWeight: 'lighter',
                fontStyle: 'italic',
                textTransform: 'initial',
              }}
            >
              <SPReactTooltip
                id="identity"
                effect="solid"
                backgroundColor="#393939"
                multiline={true}
                style={{
                  fontSize: '1.2rem',
                  color: '#fff',
                }}
              >
                <div>
                  You can avail of this annual membership for free using the
                  promo code 'BIGROBINEXCLUSIVE999'
                </div>
              </SPReactTooltip>
            </p>
            {/* Make it free via promocode - BIGROBINEXCLUSIVE999 */}
          </h6>
        )}
        <PlanDescription>
          {splitDesc.map((item, index) => {
            return (
              <p
                // style={{ fontWeight: 'bold' }}
                key={`${title}-${index}`}
                dangerouslySetInnerHTML={{ __html: item }}
              />
            );
          })}
        </PlanDescription>
      </PlanMainContent>
      {currentPlan && <CurrentPlanLabel>subscribed</CurrentPlanLabel>}
      {recommended && <RecommendedPlanLabel>recommended</RecommendedPlanLabel>}
    </PlanContainer>
  );
};

export default PlanCard;
