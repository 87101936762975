import styled from 'styled-components/macro';
import Content, { MiddleContainer } from 'components/Content';

export const CreateProjectContainer = styled(Content)`
  ${MiddleContainer} {
    padding: 4rem;

    @media (max-width: 768px) {
      padding 4rem 0rem;
    }
  }
`;

export const HeaderLayout = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  margin-bottom: 4rem;

  @media (max-width: 768px) {
    display: block;
  }
`;

export const Instructions = styled.div`
  @media (max-width: 768px) {
    margin-bottom: 4rem;
  }

  h4 {
    font-size: 2.4rem;

    @media (max-width: 768px) {
      font-size: 3.2rem;
    }
  }
`;
