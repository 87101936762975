export const PROFICIENCY = {
  NOT_APPLICABLE: 'Not Applicable',
  FAMILIAR: 'Familiar',
  PROFICIENT: 'Proficient',
  FLUENT: 'Fluent',
};

export const practice_maturity_choices = [
  {
    choiceLabel: 'Business Team Member',
    choiceValue: 'Member',
  },
  {
    choiceLabel: 'Business Unit or Functional Team Leader',
    choiceValue: 'Unit',
  },
  {
    choiceLabel: 'C-Suite, Cross-Functional Enterprise Leadership',
    choiceValue: 'Enterprise',
  },
  {
    choiceLabel: 'Business Owner/CEO/Industry Thought leader',
    choiceValue: 'Owner',
  },
];

export const STACKHOLDER_ITEM_LABELS = {
  Member: 'Business Team Member',
  Unit: 'Business Unit or Functional Team Leader',
  Enterprise: 'C-Suite, Cross-Functional Enterprise Leadership',
  Owner: 'Business Owner/CEO/Industry Thought leader',
};

export const STACKHOLDER_ITEM_VALUES = [
  'Member',
  'Unit',
  'Enterprise',
  'Owner',
];

export const relevant_higher_education_items = [
  {
    fieldKey: 'phd',
    fieldLabel: 'PHD',
  },
  {
    fieldKey: 'masters',
    fieldLabel: 'Masters',
  },
  {
    fieldKey: 'post_graduate_certificate',
    fieldLabel: 'Post Graduate Certificate',
  },
  {
    fieldKey: 'bachelor_of_science',
    fieldLabel: 'Bachelor of Science',
  },
  {
    fieldKey: 'bachelor_of_arts',
    fieldLabel: 'Bachelor of Arts',
  },
];

export const F500_SIDES = {
  CLIENT: 'client',
  VENDOR: 'vendor',
};

export const F500_SIDE_LABELS = {
  client: 'Client',
  vendor: 'Vendor',
};

export const ReferDrop = {
  CLIENT: 'client',
  RESEARCHER: 'researcher',
};

export const ReferDrop_LABELS = {
  client: 'Client',
  researcher: 'Researcher',
};

export const SIZES_OF_BUSINESSES_WORKED_VALUES = [
  '0-9',
  '10-249',
  '250-499',
  '500-999',
  '1000+',
];

export const SIZES_OF_BUSINESSES_WORKED_LABELS = {
  '0-9': '0-9 employees',
  '10-249': '10-249 employees',
  '250-499': '250-499 employees',
  '500-999': '500-999 employees',
  '1000+': '1000+ employees',
};

export const EDUCATION_ITEM_LABELS = {
  phd: 'PHD',
  masters: 'Masters',
  post_graduate_certificate: 'Post Graduate Certificate',
  bachelor_of_science: 'Bachelor of Science',
  bachelor_of_arts: 'Bachelor of Arts',
};

export const EDUCATION_ITEM_VALUES = [
  'phd',
  'masters',
  'post_graduate_certificate',
  'bachelor_of_science',
  'bachelor_of_arts',
];
