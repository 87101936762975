import React, { useState, useEffect } from 'react';
import ResearcherList from 'pages/ResearcherList';
import CarouselSection from 'components/CarouselSection';
import { Row, Column } from 'components/Layout';
import {
  ResearcherSpecializationContainer,
  ResearcherSpecializationHeader,
  HeaderImageCard,
  HeaderTitle,
  HeaderDescriptionLabel,
  HeaderDescription,
  ResearcherSpecializationRL,
  ResearcherSpecializationCarousel,
  ResearcherSpecializationSignup,
  RLTitle,
  RLSignupLabel,
  RLSignupButton,
} from './styled';
import { FaPlus } from 'react-icons/fa';
import { useParams, useHistory } from 'react-router-dom';
import { useAuthState } from 'context/AuthContext';

const marketCarouselDesc = [
  [
    'What will impact my industry in the next 5 years?',
    'What is the next big thing in my category?',
    'How might the future of X look like in this country?',
  ],
  [
    'Which opportunities should we pursue?',
    'Where should we play? How do we win?',
    'How do we capture and/or create value?',
  ],
  [
    'Who should we go after for long-term growth?',
    'Who are our short-term prime prospects?',
    'Who are our current and potential customers?',
  ],
  [
    'What are their (unmet)needs and desires?',
    'How do they (not) interact, use the category or brand?',
    'Why do they (not) know, buy, use, use more, spend on, love this category or brand?',
  ],
  [
    'Why and for whom will we make this idea?',
    'How will it work, look, feel, smell, taste, move?',
    'What will it be? What will it do? What will it solve?',
  ],
  [
    'Is my design truly relevant, worth it for the end-user?',
    'Is my solution pleasurable and easy to use?',
    'Does my design drive/grow sales and/or equity?',
  ],
  [
    'How does this feature/prototype/formula perform in terms of speed, responsiveness, stability?',
    'How does it perform in a lab or under a limited set of test vs. control conditions?',
    'How can I legally prove/disprove a superiority claim?',
  ],
  [
    'What are customers (not) willing to pay for this?',
    'How will this impact B2C/B2B profitability and revenue?',
    'How does this compare vs. the category, competition?',
  ],
  [
    'How can I best estimate and optimize demand for my idea/product?',
    'How can I anticipate issues and plan contingencies?',
    'How do I minimize costs and risks of a full-scale launch?',
  ],
  [
    'What does my brand stand for?',
    'Which of my creative assets are worth investing in?',
    'Do I stand out and connect in an omni-channel way?',
  ],
  [
    'How do I optimize the last mile of path to (re)purchase?',
    'How do I evolve for omnichannel consistency and personalization at point of sale?',
    'How do we jointly create and capture value with priority channel partners?',
  ],
  [
    'What does the customer want to accomplish?',
    'How will the customer get there?',
    'What are the main touchpoints and pain points in the process and how can we help fix/improve?',
  ],
];

const researchMLCarouselDesc = [];

const algorithmicCarouselDesc = [
  [
    'How do I use data to make the right M&A decisions?',
    'Which physical locations should I keep to maximize profit but minimize attrition?',
    'Which policies or programs minimize youth (re) incarceration?',
  ],
  [
    'How and where do I recruit top talent?',
    'How do I better manage turnover and improve retention rates?',
    'How do I improve workplace productivity, collaboration and performance?',
  ],
  [
    'What is the biggest driver of hate crimes?',
    'How do we increase adoption of new habit?',
    'Why might this design feature reduce fatigue?',
  ],

  [
    "What's the minimum threshold of reach and frequency beneath which it is not worth investing in media?",
    "How can we automate content and media's distribution real time to maximize efficiency and effectivenes?",
    'What does the optimal omni-channel mix for brand and shopper/channel marketing for my product?',
  ],
  [
    'How can I detect any issue along with a root cause analysis of the problem?',
    'How can I  communicate with stakeholders to act accurately on a reported issue?',
    'How can model future or long-term response to prevent similar problems?',
  ],
  [
    'How can I detect patterns that indicate a potential attack?',
    'How can I detecting insider threats/data exfiltration?',
    'How can I identify compromised accounts?',
  ],
  [
    'What price maximizes penetration and loyalty?',
    'When is the right time to launch a price change/promotion?',
    'What price tiers should I play within to maximize revenue?',
  ],
  [
    'What if I delayed this product launch in China?',
    'What if we did a how-high-is-up media plan on this?',
    'What if we entered this competitive stronghold?',
  ],
  [
    'What is the optimal route to market in terms of distribution and fulfillment?',
    'What is the benefit-cost of real-time Inventory management?',
    'What is the optimal queue length and staff count to minimize wait time in the restaurant?',
  ],
  [
    'What product should I recommend to maximize customer satisfaction, minimize customer returns?',
    'What script should our call center use  to minimize customer attrition, regain NPS?',
    'What content and channel combo maximizes customer delight?',
  ],
  [
    'How big is this market globally today?',
    'Who are the biggest players in each of these markets?',
    'What might be the biggest losers/winners post-COVID?',
  ],
  [
    'How might I  train my team on Data Science?',
    'How might I retain or AI/Algorithmic services?',
    'How might I engage a speaker, thought leader?',
  ],
];

const marketCarousel = [
  { id: 1, name: 'Future / Trends' },
  { id: 2, name: 'Strategy / Business Models' },
  { id: 3, name: 'Segmentation / Targeting' },
  { id: 4, name: 'Habits, Usage & Attitudes' },
  { id: 5, name: 'Innovation / Ideas / Concepts' },
  { id: 6, name: 'UX / CX / NPS Research' },
  { id: 7, name: 'Technical / Performance Testing' },
  { id: 8, name: 'New Product Pricing' },
  { id: 9, name: 'Virtual Launch / Test Markets' },
  { id: 10, name: 'Creative Testing / Brand Design' },
  { id: 11, name: 'Shopper / Channel Understanding' },
  { id: 12, name: 'Other Research' },
].map((item, index) => ({
  title: item.name,
  img: require(`../../assets/carousel-images/market-research/${index + 1}.svg`),
  group: 'markets',
  slug: item.name
    .toLowerCase()
    .replace(/[\W_]+/g, ' ')
    .split(' ')
    .filter(itemF => itemF.length > 0)
    .join('-'),
  description: marketCarouselDesc[index],
  id: item.id,
}));

const algorithmicCarousel = [
  { id: 13, name: 'Decision Sciences' },
  { id: 14, name: 'People Analytics' },
  { id: 15, name: 'Behavioral Economics' },
  { id: 16, name: 'ROI / Mix Modeling' },
  { id: 17, name: 'Bio-Analytics / Telemetry' },
  { id: 18, name: 'Security / Cyber Defense' },
  { id: 19, name: 'Pricing, Promotions, Revenue Growth' },
  { id: 20, name: 'Scenario Planning / Simulation' },
  { id: 21, name: 'Retail / Supply Chain Analytics' },
  { id: 22, name: 'CRM, Digital / Personalized Marketing' },
  { id: 23, name: 'Market Sizing / Measurement / Analytics' },
  { id: 24, name: 'Other Data Science / Advanced Analytics' },
].map((item, index) => ({
  title: item.name,
  img: require(`../../assets/carousel-images/data-analytics/${index + 1}.svg`),
  group: 'analytics',
  slug: item.name
    .toLowerCase()
    .replace(/[\W_]+/g, ' ')
    .split(' ')
    .filter(itemF => itemF.length > 0)
    .join('-'),
  description: algorithmicCarouselDesc[index],
  id: item.id,
}));

const researcherCarousel = [
  { id: 30, name: 'Analytics Maturity and Culture Transformation' },
  { id: 26, name: 'Data Integration, Processing and Report Automation' },
  { id: 29, name: 'Data Strategy, Engineering, AI/ML Implementation' },
  { id: 27, name: 'Graphic Design, Storytelling and Creative Services' },
  { id: 31, name: 'Knowledge and Vendor Procurement and Management' },
  { id: 25, name: 'Research Operations - sampling, panels, questionnaires' },
  { id: 28, name: 'Web, Software, Dashboard Development and Support' },
  { id: 32, name: 'Other Research and ML Operations' },
].map((item, index) => ({
  title: item.name,
  img: require(`../../assets/carousel-images/data-analytics/${index + 1}.svg`),
  group: 'research',
  slug: item.name
    .toLowerCase()
    .replace(/[\W_]+/g, ' ')
    .split(' ')
    .filter(itemF => itemF.length > 0)
    .join('-'),
  description: researchMLCarouselDesc[index],
  id: item.id,
}));

const ResearcherSpecialization = () => {
  const { user } = useAuthState();
  const history = useHistory();
  const { group, slug } = useParams();
  const [carouselItem, setCarouselItem] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (group && slug) {
      let item = null;
      switch (group) {
        case 'markets':
          item = marketCarousel.find(item => item.slug === slug);
          break;
        case 'analytics':
          item = algorithmicCarousel.find(item => item.slug === slug);
          break;
        case 'research':
          item = researcherCarousel.find(item => item.slug === slug);
          break;
        default:
          break;
      }

      if (item) {
        setCarouselItem(item);
      } else {
        history.push('/');
      }
    } else {
      history.push('/');
    }
  }, [slug, group, history]);

  return (
    <ResearcherSpecializationContainer>
      <ResearcherSpecializationHeader>
        <Row alignItems="flex-start">
          <Column>
            <HeaderImageCard>
              <img src={carouselItem?.img} alt="" />
            </HeaderImageCard>
          </Column>
          <Column>
            <HeaderTitle>{carouselItem?.title}</HeaderTitle>
            {carouselItem?.description && (
              <HeaderDescriptionLabel>
                If you are asking:
              </HeaderDescriptionLabel>
            )}
            {carouselItem?.description?.map(desc => (
              <HeaderDescription>
                <span>
                  <FaPlus />
                </span>
                <span>{desc}</span>
              </HeaderDescription>
            ))}
          </Column>
        </Row>
      </ResearcherSpecializationHeader>
      {carouselItem && (
        <ResearcherSpecializationRL key={carouselItem.id}>
          <RLTitle>Here are Big Robin Researchers who can help</RLTitle>
          <ResearcherList selectedSpecialization={carouselItem.id} />
        </ResearcherSpecializationRL>
      )}

      {!user && (
        <ResearcherSpecializationSignup alignItems="center">
          <RLSignupLabel>
            Gain access to ex-bluechip researchers at the fraction of the cost,
            time and effort.
          </RLSignupLabel>
          <RLSignupButton
            onClick={() => {
              history.push('/login?mode=su&type=researcher');
            }}
          >
            Join Big Robin
          </RLSignupButton>
        </ResearcherSpecializationSignup>
      )}

      <ResearcherSpecializationCarousel>
        <CarouselSection
          data={marketCarousel}
          title="Explore Market Research"
          color="#dce6dd"
          textColor="#524c4a"
          currentGroup={group}
          currentSlug={slug}
        />
        <CarouselSection
          data={algorithmicCarousel}
          title="Explore Data Science &amp; Analytics"
          color="#f7e7bc"
          textColor="#4b4c5e"
          currentGroup={group}
          currentSlug={slug}
        />
        <CarouselSection
          data={researcherCarousel}
          title="Research/ML Operations"
          color="#ffccbc"
          textColor="#4b4c5e"
          currentGroup={group}
          currentSlug={slug}
        />
      </ResearcherSpecializationCarousel>
    </ResearcherSpecializationContainer>
  );
};

export default ResearcherSpecialization;
