import CheckboxGroup from 'components/CheckboxGroup';
import DropdownPopup from 'components/DropdownPopup';
import {
  DropdownPopupContainer,
  DropdownPopupMenuContainer,
} from 'components/DropdownPopup/styled';
import Input from 'components/Input';
import Button from 'components/Button';
import { Row } from 'components/Layout';
import styled from 'styled-components/macro';

export const ResearcherFilterContainer = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 0.5rem 0;
  border-top: 1px solid #e2e2e2;
  border-bottom: 1px solid #e2e2e2;

  @media (max-width: 768px) {
    display: block;
    border: none;
  }
`;

export const ResearcherFilterRow = styled(Row)`
  margin: 0.5rem 0rem;
  row-gap: 5px;

  @media (max-width: 768px) {
    flex-direction: column;
    margin: 0rem;
    row-gap: 0px;
  }

  ${DropdownPopupContainer} {
    margin-right: 1.25rem;

    &:last-child {
      margin-right: 0rem;
    }

    @media (max-width: 768px) {
      margin: 0.25rem 0rem;
    }
  }
`;

export const ResearcherFilterSide = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 1rem;
  margin-left: 2rem;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 0rem;
    margin-left: 0rem;
  }
`;

export const FilterTitle = styled.span`
  color: #4b4c5e;
  font-size: 1.4rem;
  font-weight: bold;
  margin-right: 2rem;
  margin-top: 1.75rem;

  @media (max-width: 768px) {
    font-size: 2.1rem;
    display: block;
    margin-top: 0rem;
    margin-bottom: 2rem;
  }
`;

export const ShowAllFilterButton = styled(Button)`
  width: 100%;
  margin: 0.5rem 0rem;
  border-radius: 8px;
  text-transform: uppercase;

  @media (min-width: 769px) {
    border-radius: 0px;
    margin: 0rem;
    background-color: transparent;
    width: auto;
    font-size: 1.5rem;
    color: #4b4c5e8a;
    font-style: italic;
    text-transform: uppercase;
    font-weight: normal;

    &:hover,
    &:active {
      color: #4b4c5eff;
    }
  }
`;

export const PriceFilterDash = styled.div`
  position: relative;
  top: 4rem;
  font-size: 1.6rem;
  color: #4b4c5e;
  margin: 0 2rem;
`;

export const PriceInput = styled(Input)`
  input {
    width: 10rem;
  }
`;

export const CheckboxRow = styled(Row)`
  svg {
    margin-right: 1rem;
  }

  @media (max-width: 768px) {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
`;

export const CheckboxValue = styled.span`
  font-weight: bold;
  font-size: 1.4rem;

  @media (max-width: 768px) {
    font-size: 2.1rem;
  }
`;

export const RatingValue = styled(CheckboxValue)`
  margin-right: 1rem;
`;

export const RatingResearcherCount = styled(RatingValue)`
  white-space: pre;
  color: rgba(75, 76, 94, 0.54);
`;

export const ShowMore = styled.button`
  color: rgba(75, 76, 94, 0.54);
  font-size: 1.4rem;

  @media (max-width: 768px) {
    font-size: 2.1rem;
  }
`;

export const TwoColumnCheckboxGroup = styled(CheckboxGroup)`
  min-width: 28rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

export const SpecializationDropdownPopup = styled(DropdownPopup)`
  ${DropdownPopupMenuContainer} {
    width: max-content;
    left: -35vw;

    @media (max-width: 768px) {
      width: calc(100% - 4rem);
      left: 0px;
      margin-left: 2px;
    }
  }
`;

export const SpecializationDropdownPopupRow = styled(Row)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap-column: 0.25rem;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    grid-gap-row: 0.25rem;
  }

  & h6 {
    color: #4b4c5e;
    font-size: 1.5rem;
    margin-bottom: 1rem;
    text-transform: uppercase;

    @media (max-width: 768px) {
      font-size: 2rem;
      margin-top: 1.5rem;
      margin-bottom: 1rem;
    }
  }
`;
