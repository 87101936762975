import React from 'react';
import {
  ProjectDetailLabel,
  DocumentDiv,
  FilesContainer,
  FileIcon,
  FilesMainDiv,
  FileNameSpan,
} from './styled';
import {
  FaFile,
  FaFilePdf,
  FaFileExcel,
  FaFilePowerpoint,
  FaFileWord,
  FaFileImage,
} from 'react-icons/fa';

export const Deliverables = props => {
  const getFileIcon = file => {
    let fileicon = <FaFile color="#FFFFFF" />;
    if (file.type && file.type !== '') {
      switch (true) {
        case file.type.includes('image'):
          fileicon = <FaFileImage color="#FFFFFF" />;
          break;
        case file.type.includes('pdf'):
          fileicon = <FaFilePdf color="#FFFFFF" />;
          break;
        case file.type.includes('doc'):
          fileicon = <FaFileWord color="#FFFFFF" />;
          break;
        case file.type.includes('excel'):
          fileicon = <FaFileExcel color="#FFFFFF" />;
          break;
        case file.type.includes('powerpoint'):
          fileicon = <FaFilePowerpoint color="#FFFFFF" />;
          break;
        default:
          fileicon = <FaFile color="#FFFFFF" />;
      }
    } else if (file.filename) {
      const ext = file.filename
        .split('.')
        .slice(-1)
        .pop();
      if (ext) {
        switch (ext.toLowerCase()) {
          case 'jpg':
          case 'jpeg':
          case 'png':
            if (file.url) {
              fileicon = <img src={file.url} alt={file.filename} />;
            } else {
              fileicon = <FaFileImage color="#FFFFFF" />;
            }
            break;
          case 'pdf':
            fileicon = <FaFilePdf color="#FFFFFF" />;
            break;
          case 'doc':
          case 'docx':
            fileicon = <FaFileWord color="#FFFFFF" />;
            break;
          case 'xls':
          case 'xlsx':
            fileicon = <FaFileExcel color="#FFFFFF" />;
            break;
          case 'ppt':
          case 'pptx':
            fileicon = <FaFilePowerpoint color="#FFFFFF" />;
            break;
          default:
            fileicon = <FaFile color="#FFFFFF" />;
        }
      }
    }
    return fileicon;
  };

  const getFiles = files => {
    let files_component = null;
    files_component = files.map((item, index) => {
      return (
        <FilesMainDiv href={item.url}>
          <FileIcon>{getFileIcon(item)}</FileIcon>
          <FileNameSpan>{item.filename}</FileNameSpan>
        </FilesMainDiv>
      );
    });
    return files_component;
  };

  return (
    <>
      <div style={{ gridArea: 'milestone' }}>
        <ProjectDetailLabel>Project Milestones</ProjectDetailLabel>
        <div>
          {/* {props.project.project_milestone
            ? props.project.project_milestone
            : ''} */}
          {props?.project?.key_deliverables
            ? props.project.key_deliverables?.length > 0 &&
              props.project.key_deliverables?.map(item => {
                if (item.id === 11) {
                  return (
                    <div key={item.id}>
                      {item.name}
                      {' - '}
                      {props?.project?.other_key_deliverable ? (
                        <span>{props?.project?.other_key_deliverable}</span>
                      ) : (
                        '-'
                      )}
                    </div>
                  );
                } else {
                  return <div key={item.id}>{item.name}</div>;
                }
              })
            : '-'}
        </div>
      </div>
      <DocumentDiv>
        <ProjectDetailLabel>REFERENCES/OTHER FILES</ProjectDetailLabel>
        <FilesContainer>
          {props.project.reference_files && props.project.reference_files.length
            ? getFiles(props.project.reference_files)
            : 'No uploaded documents.'}
        </FilesContainer>
      </DocumentDiv>
    </>
  );
};
