import React from 'react';
import Content from 'components/Content';
import {
  CarouselSectionContainer,
  Title,
  CarouselCard,
  CarouselLayout,
  CarouselCardImage,
  CarouselCardContent,
} from './styled';

const CarouselSection = ({
  data,
  title,
  color,
  textColor,
  currentGroup,
  currentSlug,
}) => {
  return (
    <CarouselSectionContainer>
      <Content>
        <Title>{title}</Title>
        <CarouselLayout>
          {data.map(({ img, title, group, slug }, index) => (
            <CarouselCard
              style={{
                filter:
                  currentGroup === group && currentSlug === slug
                    ? 'grayscale(100%)'
                    : 'none',
              }}
              key={index}
              color={color}
              to={
                currentGroup === group && currentSlug === slug
                  ? '#'
                  : `/researchers/specialization/${group}/${slug}`
              }
            >
              <CarouselCardImage>
                <img src={img} alt={title} />
              </CarouselCardImage>
              <CarouselCardContent textColor={textColor}>
                <p>{title}</p>
              </CarouselCardContent>
            </CarouselCard>
          ))}
        </CarouselLayout>
      </Content>
    </CarouselSectionContainer>
  );
};

export default CarouselSection;
