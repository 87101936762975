// import Button from 'components/Button';
import { format, parseJSON } from 'date-fns';
import React, { useCallback, useState, useEffect } from 'react';
import headerSvg from './header.svg';
import { useHistory, useParams } from 'react-router-dom';
import {
  ProjectItem,
  ProjectItemDate,
  ProjectItemText,
  ProjectItemTitle,
  ProjectItemTitleLayout,
  ProjectsContent,
  ProjectsHeader,
  HeaderMenu,
  ProjectsHeaderContainer,
  ProjectsHeaderLayout,
  ProjectsListContainer,
  ProjectsSearchbar,
  ProjectTabButton,
  NoProjectContainer,
} from './styled';
import { useAuthState, MEMBER_TYPES } from 'context/AuthContext';
import { ajax } from 'utils/ajax';
import querystring from 'querystring';
import LoadingIndicator from 'components/LoadingIndicator';
import SessionModal from 'components/SessionModal';
import ReactPaginate from 'react-paginate';
import styled from 'styled-components';
import Button from 'components/Button';
import BrowsePlanModal from 'components/BrowsePlanModal';

const tabs = [{ value: 'In Discussion', label: 'In Discussion' }];

const project_labels = {
  1: 'Completed',
  2: 'In Progress',
  3: 'In Discussion',
};

const formatDate = a => {
  try {
    a = parseJSON(a);
    a = format(a, 'MMM d, yyyy');
    return a;
  } catch (_err) {
    return 'x';
  }
};

const formatTime = a => {
  try {
    a = parseJSON(a);
    a = format(a, 'h:mm a');
    return a;
  } catch (_err) {
    return 'y';
  }
};

const MyPaginate = styled(ReactPaginate).attrs({
  activeClassName: 'active',
})`
  margin-bottom: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  list-style-type: none;
  padding: 0 5rem;
  li a {
    border-radius: 7px;
    padding: 0.1rem 1rem;
    cursor: pointer;
  }
  li.previous a,
  li.next a,
  li.break a {
    border-color: transparent;
  }
  li.active {
    background-color: #4b4c5e;
    border-color: transparent;
    color: white;
    min-width: 32px;
  }
  li.disabled a {
    color: grey;
  }
  li.disable,
  li.disabled a {
    cursor: default;
  }
`;

const MyProjectsShare = () => {
  const { user, isMemberSubscribed } = useAuthState();
  const history = useHistory();
  const [projects, setProjects] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const params = useParams();
  let { id } = useParams();
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const itemsPerPage = 5;
  const user_id = parseInt(localStorage.getItem('br-userId'));
  const [isBrowseModal, setIsBrowseModal] = useState(false);

  const checkActivePlan = () => {
    (async () => {
      try {
        setLoading(true);
        const subs_url = '/member/' + user_id + '/subscription/active';
        const subs_res = await ajax('GET', subs_url);
        if (subs_res.code !== 200) {
          history.push(`/member-subscription/${user_id}/${id}`);
        }
      } catch (err) {
        setLoading(false);
      }
    })();
  };

  const handleInvite = projectId => {
    (async () => {
      setLoading(true);
      const response = await ajax('GET', `/project/${projectId}`);
      if (response.code === 200 && response.data && response.data.project) {
        const proj_obj = response.data.project;
        if (proj_obj.project_status_id === 3) {
          sendInvite(projectId);
        }
      } else if (response.code === 401) {
        setLoading(false);
        setIsOpen(true);
      } else {
        setLoading(false);
      }
    })();
  };

  const sendInvite = projectId => {
    const receiver_id = params.id;
    if (receiver_id) {
      (async () => {
        const url =
          '/messages/conversation/member/' +
          parseInt(localStorage.getItem('br-userId')) +
          '/researcher/' +
          receiver_id;
        const convo_response = await ajax('GET', url);
        if (convo_response.code === 200) {
          const payload = {
            receiver_id: parseInt(receiver_id),
            sender_id: parseInt(localStorage.getItem('br-userId')),
            message_type: 'invite',
            message: "Here is a research project that we're working on.",
            links: '',
            conversation_id: convo_response.data['id'],
            project_id: projectId,
          };
          const msg_response = await ajax('POST', '/messages', payload);
          if (msg_response.code === 200) {
            history.push('/messages');
          } else if (msg_response.code === 401) {
            setLoading(false);
            setIsOpen(true);
          }
        } else if (convo_response.code === 401) {
          setLoading(false);
          setIsOpen(true);
        }
      })();
    }
  };

  const getMyProjects = useCallback(() => {
    checkActivePlan();
    setLoading(true);
    setProjects([]);
    const member_id = localStorage.getItem('br-userId');
    //let filter = { page_size: 9, page: page };
    let filter = {};
    let url = `/member/${member_id}/project?page=${page}&page_size=${itemsPerPage}&status=3`;

    if (searchText !== '') {
      filter['search'] = searchText;
    }

    if (Object.keys(filter).length > 0) {
      url += '&' + querystring.stringify(filter);
    }
    ajax('GET', url).then(result => {
      setLoading(false);
      if (result.code === 200) {
        if (result.data && result.data.length === 0) {
          setProjects([]);
        } else {
          setProjects([...result.data]);
        }
        setTotalPages(Math.ceil(result.meta.total_number / itemsPerPage));
      } else if (result.code === 401) {
        setIsOpen(true);
      }
    });
  });

  const handleSearch = () => {
    getMyProjects();
  };

  const handlePageClick = event => {
    setPage(event.selected + 1);
  };

  useEffect(() => {
    getMyProjects();
  }, [page]);

  return (
    <>
      {loading ? <LoadingIndicator /> : null}
      <ProjectsContent>
        <ProjectsHeaderContainer
          isResearcher={
            user && user.member.member_type_id === +MEMBER_TYPES.RESEARCHER
          }
        >
          <ProjectsHeaderLayout>
            <ProjectsHeader>
              <h1>
                Hey there,{' '}
                {user?.member?.full_name
                  ? user.member.full_name
                  : user?.member?.first_name
                  ? user.member.first_name + ' ' + user.member.last_name
                  : localStorage.getItem('isResearcher') === 'false'
                  ? 'Client'
                  : 'Researcher'}
                !
              </h1>
              <p>Have a productive day today.</p>
            </ProjectsHeader>

            <HeaderMenu>
              <div>
                {tabs.map((t, index) => (
                  <ProjectTabButton
                    key={index}
                    active={t.value === 'In Discussion'}
                  >
                    {t.label}
                  </ProjectTabButton>
                ))}
              </div>
            </HeaderMenu>
          </ProjectsHeaderLayout>
          <img src={headerSvg} alt="" />
        </ProjectsHeaderContainer>
        <ProjectsListContainer>
          <ProjectsSearchbar
            placeholder="Search Project"
            value={searchText}
            onChange={text => {
              const trimmedText = text ? text.trim() : '';
              setSearchText(trimmedText);
            }}
            onKeyPress={event => {
              if (event === 'Enter') {
                handleSearch();
              }
            }}
          />

          {user.member.member_type_id === +MEMBER_TYPES.MEMBER && (
            <Button
              onClick={() =>
                isMemberSubscribed
                  ? history.push('/create-project')
                  : setIsBrowseModal(true)
              }
              style={
                user.member.member_type_id === +MEMBER_TYPES.RESEARCHER
                  ? { backgroundColor: '#4b4c5e', marginTop: '12px' }
                  : { backgroundColor: '#ff9e08', marginTop: '12px' }
              }
            >
              CREATE A PROJECT
            </Button>
          )}

          {projects && projects.length > 0 ? (
            projects.map((project, index) => {
              return (
                <ProjectItem key={index}>
                  <ProjectItemDate>
                    <span>{formatDate(project.created_date)}</span>
                    <span>{formatTime(project.created_date)}</span>
                  </ProjectItemDate>
                  <ProjectItemTitleLayout>
                    <ProjectItemTitle
                      onClick={() => {
                        handleInvite(project.id);
                      }}
                    >
                      {project.title}
                    </ProjectItemTitle>
                    <ProjectItemText>
                      {project_labels[project.project_status_id]}
                    </ProjectItemText>
                  </ProjectItemTitleLayout>
                  <ProjectItemText>{project.description}</ProjectItemText>
                </ProjectItem>
              );
            })
          ) : loading ? (
            <NoProjectContainer>
              <p>Loading . . .</p>
            </NoProjectContainer>
          ) : (
            <NoProjectContainer>
              <p>No Projects to display.</p>
            </NoProjectContainer>
          )}
          <MyPaginate
            breakLabel="..."
            nextLabel="Next >"
            onPageChange={handlePageClick}
            pageRangeDisplayed={itemsPerPage}
            pageCount={totalPages}
            previousLabel="< Previous"
            renderOnZeroPageCount={null}
          />
        </ProjectsListContainer>
      </ProjectsContent>
      <SessionModal
        isOpen={isOpen}
        setModalVisible={setIsOpen}
        message={'Your session has expired.'}
      />
      <BrowsePlanModal visible={isBrowseModal} setVisible={setIsBrowseModal} />
    </>
  );
};

export default MyProjectsShare;
