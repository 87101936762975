import styled from 'styled-components/macro';
import { Row, Column } from 'components/Layout';
import Button from 'components/Button';

export const ResearcherSpecializationContainer = styled.div`
  background-color: #f7f7f8;
`;

export const ResearcherSpecializationHeader = styled.div`
  padding: 4rem 8rem;
  background-color: #f0edec;

  @media (max-width: 768px) {
    padding: 4rem 4rem;

    ${Row} {
      display: grid;
      grid-template-columns: 1fr 5fr;
    }
  }
`;

export const HeaderImageCard = styled.div`
  width: 8rem;
  margin-right: 4rem;

  @media (max-width: 768px) {
    margin-right: 2rem;
  }

  & img {
    width: 100%;
    height: auto;
  }
`;

export const HeaderTitle = styled.h2`
  font-size: 3.6rem;
`;

export const HeaderDescriptionLabel = styled.p`
  font-size: 2.4rem;
  font-style: italic;
  margin: 1.5rem 0rem 1rem;
`;

export const HeaderDescription = styled.p`
  display: flex;
  flex-direction: row;
  font-size: 2rem;
  font-style: italic;
  margin: 0.5rem 0rem;

  & svg {
    margin-right: 0.75rem;
  }
`;

export const ResearcherSpecializationRL = styled.div`
  margin: 0 8rem;

  @media (max-width: 768px) {
    margin: 0 4rem;
  }
`;

export const RLTitle = styled.h5`
  font-size: 2.4rem;
  margin-top: 4rem;
`;

export const ResearcherSpecializationCarousel = styled.div`
  margin: 0rem 4rem;

  @media (max-width: 768px) {
    margin: 0rem 0rem;
  }
`;

export const ResearcherSpecializationSignup = styled(Column)`
  padding: 8rem 8rem;
  background: url(${require('./background.png')});
  background-size: cover;
  background-position: center;
  margin-bottom: 4rem;

  @media (max-width: 768px) {
    background-position: 25%;
    padding: 8rem 4rem;
  }
`;

export const RLSignupLabel = styled.h6`
  color: white;
  font-size: 2.8rem;
  text-align: center;
  margin: 0rem 16rem 6rem;

  @media (max-width: 768px) {
    margin: 0rem 0rem 8rem;
  }
`;

export const RLSignupButton = styled(Button)`
  padding-top: 1rem;
  padding-bottom: 1rem;
  width: 40rem;
  text-transform: uppercase;

  @media (max-width: 600px) {
    width: 100%;
  }
`;
