import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Row } from 'components/Layout';
import { FaBookOpen } from 'react-icons/fa';
import ReactPaginate from 'react-paginate';
import {
  ProjectsContent,
  ProjectPageTitle,
  ProjectsListContainer,
  ProjectsSearchbar,
  ProjectsFilterToggle,
  NoProjectContainer,
  ProjectItem,
  ProjectItemTitle,
  ProjectItemDesc,
  ProjectItemSpecRow,
  ProjectItemSpecIcon,
  ProjectItemSpecs,
} from './styled';
import { INITIAL_PROJECT_FILTERS } from './ProjectsConstants';
import ProjectsFilter from './ProjectsFilter';
// import { useAuthState } from 'context/AuthContext';
import LoadingIndicator from 'components/LoadingIndicator';
import styled from 'styled-components';
import { ajax } from 'utils/ajax';
import querystring from 'querystring';
// const TestProjectList = [
//   {
//     id: 1,
//     title: 'Marketing Trends for Beauty SKUs',
//     description:
//       'Discover the biggest marketing trends in the beauty industry plus examples from leading beauty brands to inspire your own marketing strategy.',
//     specializations: ['Retailer Understanding', 'Future/Trends'],
//   },
//   {
//     id: 2,
//     title: 'Cosmetics and Makeup Industry | Trends & Research',
//     description:
//       'Understand consumer behavior and emerging trends in the prestige beauty industry, at the category-, geographic-, and store-level.',
//     specializations: ['Future/Trends'],
//   },
//   {
//     id: 3,
//     title: '13 Trends Shaping the Face of Beauty in 2018',
//     description:
//       'From major beauty brands investing in augmented reality to an industry-wide push towards customized beauty products and experiences, technology-backed cosmetic brands will transform the sector in 2018.',
//     specializations: ['Future/Trends'],
//   },
// ];

const MyPaginate = styled(ReactPaginate).attrs({
  activeClassName: 'active',
})`
  margin-bottom: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  list-style-type: none;
  padding: 0 5rem;
  li a {
    border-radius: 7px;
    padding: 0.1rem 1rem;
    cursor: pointer;
  }
  li.previous a,
  li.next a,
  li.break a {
    border-color: transparent;
  }
  li.active {
    background-color: #4b4c5e;
    border-color: transparent;
    color: white;
    min-width: 32px;
  }
  li.disabled a {
    color: grey;
  }
  li.disable,
  li.disabled a {
    cursor: default;
  }
`;

const Projects = () => {
  // const { user } = useAuthState();
  const history = useHistory();
  const [searchText, setSearchText] = useState('');
  const [filterVisible, setFilterVisible] = useState(false);
  const [filterValues, setFilterValues] = useState(INITIAL_PROJECT_FILTERS);
  const [projectList, setProjectList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const itemsPerPage = 5;
  const [search, setSearch] = useState(null);
  const [options, setOptions] = useState({});

  const handleFilterToggle = () => {
    if (filterVisible) {
      setFilterValues(INITIAL_PROJECT_FILTERS);
      setFilterVisible(false);
    } else {
      setFilterVisible(true);
    }
  };

  const handleFilterValueChange = values => {
    setFilterValues(values);
  };

  const Projs = ({ projectList }) => {
    return (
      <>
        {projectList &&
          projectList.map((item, index) => (
            <ProjectItem
              key={index}
              onClick={() => history.push(`/project/${item.id}`)}
              style={{ cursor: 'pointer' }}
            >
              <ProjectItemTitle>{item.title}</ProjectItemTitle>
              <ProjectItemDesc>{item.description}</ProjectItemDesc>
              <ProjectItemSpecRow>
                <ProjectItemSpecIcon>
                  <FaBookOpen />
                </ProjectItemSpecIcon>
                <ProjectItemSpecs>
                  {item.specializations ? item.specializations.join(', ') : ''}
                </ProjectItemSpecs>
              </ProjectItemSpecRow>
            </ProjectItem>
          ))}
      </>
    );
  };

  const handlePageClick = event => {
    setPage(event.selected + 1);
  };

  const handleSearch = searchText => {
    setSearch(searchText);
  };

  const clearFilter = async filter => {
    setLoading(true);
    try {
      let filters = {};
      if (search) filters['search'] = search;
      if (filterValues.minPrice > 0)
        filters['min_budget'] = filterValues.minPrice;
      if (filterValues.maxPrice > 0)
        filters['max_budget'] = filterValues.maxPrice;
      if (filterValues.industry.length > 0)
        filters['industry'] = filterValues.industry.join(',');
      if (filterValues.mastery.length > 0)
        filters['mastery_score'] = filterValues.mastery.join(',');
      if (filterValues.specialization.length > 0)
        filters['specialization'] = filterValues.specialization.join(',');
      switch (filter) {
        case 'search':
          filters['search'] = '';
          break;
        case 'min_budget':
          filters['min_budget'] = '';
          break;
        case 'max_budget':
          filters['max_budget'] = '';
          break;
        case 'industry':
          filters['industry'] = '';
          break;
        case 'mastery_score':
          filters['search'] = '';
          break;
        case 'specialization':
          filters['search'] = '';
          break;
        default:
          break;
      }

      let response = await ajax(
        'GET',
        `/project?page=${page}&page_size=${itemsPerPage}&${querystring.stringify(
          filters
        )}
      `
      );
      setProjectList(response.data);
      setTotalPages(Math.ceil(response.meta.total_number / itemsPerPage));
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  const getProjectsFromApi = async () => {
    setLoading(true);
    try {
      let filters = {};
      if (search) filters['search'] = search;
      if (filterValues.minPrice > 0)
        filters['min_budget'] = filterValues.minPrice;
      if (filterValues.maxPrice > 0)
        filters['max_budget'] = filterValues.maxPrice;
      if (filterValues.industry.length > 0)
        filters['industry'] = filterValues.industry.join(',');
      if (filterValues.mastery.length > 0)
        filters['mastery_score'] = filterValues.mastery.join(',');
      if (filterValues.specialization.length > 0)
        filters['specialization'] = filterValues.specialization.join(',');

      let response = await ajax(
        'GET',
        `/project?page=${page}&page_size=${itemsPerPage}&${querystring.stringify(
          filters
        )}
      `
      );
      setProjectList(response.data);
      setTotalPages(Math.ceil(response.meta.total_number / itemsPerPage));
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  // useEffect(() => {
  //   // Fetch items from another resources.
  //   if (projectList) {
  //     const endOffset = itemOffset + itemsPerPage;
  //   }
  // }, [itemOffset, itemsPerPage, projectList]);

  useEffect(() => {
    window.scrollTo(0, 0);
    getProjectsFromApi();
  }, [page, search, filterValues]);

  useEffect(() => {
    ajax('GET', '/member_reference_dropdown').then(res => {
      if (res.code === 200) {
        setOptions(res.data);
      } else if (res.code === 401) {
        console.log(res);
      }
    });
  }, []);

  return (
    <>
      {loading ? <LoadingIndicator /> : null}
      <ProjectsContent>
        <ProjectsListContainer>
          <ProjectPageTitle>Projects</ProjectPageTitle>
          <Row>
            <ProjectsSearchbar
              placeholder="Search Project"
              onChange={text => {
                const trimmedText = text ? text.trim() : '';
                setSearchText(trimmedText);
              }}
              onKeyPress={event => {
                if (event === 'Enter') {
                  handleSearch(searchText);
                }
              }}
            />
            <ProjectsFilterToggle onClick={handleFilterToggle}>
              {filterVisible ? 'Reset' : 'Sort and Filter'}
            </ProjectsFilterToggle>
          </Row>
          {filterVisible ? (
            <ProjectsFilter
              className="ProjectFilter"
              filterValues={filterValues}
              onFilterValueChange={handleFilterValueChange}
              options={options}
              onClear={cleared => {
                clearFilter(cleared);
              }}
            />
          ) : null}
          {projectList && projectList.length > 0 ? (
            <>
              <Projs projectList={projectList} />
              {/*Pagination part*/}
              <MyPaginate
                breakLabel="..."
                nextLabel="Next >"
                onPageChange={handlePageClick}
                pageRangeDisplayed={itemsPerPage}
                pageCount={totalPages}
                previousLabel="< Previous"
                renderOnZeroPageCount={null}
              />
            </>
          ) : loading ? (
            <NoProjectContainer>
              <p>Loading . . .</p>
            </NoProjectContainer>
          ) : (
            <NoProjectContainer>
              <p>No Projects to display.</p>
            </NoProjectContainer>
          )}
        </ProjectsListContainer>
      </ProjectsContent>
    </>
  );
};

export default Projects;
