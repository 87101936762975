import { Row } from 'components/Layout';
import styled, { css } from 'styled-components/macro';

export const ChipContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 1rem;
  margin-bottom: 1rem;
  border-radius: 1.6rem;
  background-color: #dadadf;
  padding: 0.5rem 1rem;
  font-size: 1.3rem;
  color: #4b4c5e;
  margin-bottom: 0.25rem;
  svg {
    cursor: pointer;
    margin-left: 0.25rem;
  }
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  height
  label {
    font-size: 1.6rem;
    color: #4b4c5e;

    @media (max-width: 900px) {
      font-size: 2rem;
    }
  }

  ${Row} {
    overflow: hidden;
    border: 1px solid #65637b3c;
    border-radius: 0.8rem;
    padding: calc(1rem - 0.5px) 20px;
    background-color: white;

    ${props =>
      props.isFocused &&
      css`
        border: 2px solid #035b96;
        padding: calc(1rem - 1.5px);
      `}

    ${props =>
      !!props.error &&
      css`
        border-color: #ef3777;
      `}

    ${props =>
      props.readOnly &&
      css`
        background-color: transparent;
      `}

    input {
      padding: 0;
      flex: 1;
      color: #39374c;
      outline: none;
      border: 0;
    }
  }
`;

export const InputLayout = styled.div`
  display: flex;
  align-items: center;
  border: none;
  // border-radius: 8px;
  display: flex;
  overflow: hidden;
  background-color: #ffffff;
  height: 3rem;
  width: 100%;

  input,
  a {
    color: #4b4c5e;
    flex: 1;
    font-size: 1.6rem;
    padding: 0.95rem 1rem;

    @media (max-width: 900px) {
      font-size: 2.4rem;
      padding: 0.5rem 1rem;
    }

    &:disabled {
      background-color: #f5f5f5;
    }

    &::placeholder {
      color: #bab9be;
    }
  }

  a {
    flex: 0;
  }
`;

export const LabelLayout = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 1rem;
`;
