import React from 'react';
import {
  HowItWorksContent,
  HowItWorksHeader,
  HowItWorksImageContainer,
  HowItWorksStepLayout,
  HowItWorksStepsLayout,
} from './styled';

const steps = [
  {
    text:
      'Robin researchers join, create their profile, pricing, and deliverables.',
    img: require('./assets/step-1.png'),
  },
  {
    text: 'Robin members look for help, brief and book Robin researchers.',
    img: require('./assets/step-2.png'),
  },
  {
    text: 'Robin Researcher agrees to do project. Collaboration begins!',
    img: require('./assets/step-3.png'),
  },
];

const HowItWorks = () => {
  return (
    <HowItWorksContent>
      <HowItWorksHeader>How It Works</HowItWorksHeader>
      <HowItWorksStepsLayout>
        {steps.map((step, index) => (
          <HowItWorksStepLayout key={index}>
            <HowItWorksImageContainer>
              <span>{index + 1}</span> <img src={step.img} alt="" />
            </HowItWorksImageContainer>
            <p>{step.text}</p>
          </HowItWorksStepLayout>
        ))}
      </HowItWorksStepsLayout>
    </HowItWorksContent>
  );
};

export default HowItWorks;
