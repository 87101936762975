import Content from 'components/Content';
import { LandingJoinFormContent } from 'pages/Landing/styled';
import styled from 'styled-components/macro';

export const ResearchersContainer = styled.div`
  ${LandingJoinFormContent} {
    padding-bottom: 4rem;
  }
`;

export const ResearcherContent = styled(Content)`
  margin: 4rem 0;
`;

export const ResearchersTitle = styled.h1`
  font-size: 3.6rem;
  color: #4b4c5e;
  margin-bottom: 2.75rem;
`;
