import { InputContainer, LabelLayout } from 'components/Input/styled';
import React from 'react';
import { TextareaCharacterCount, TextareaContainer } from './styled';

const Textarea = ({
  name,
  label,
  placeHolder,
  className,
  value,
  onChange,
  maxCharacters,
  rows = 3,
  error,
  errorMessage,
  required,
}) => {
  return (
    <InputContainer className={className}>
      <LabelLayout>
        <label>
          {label}
          {required && <sup style={{ color: 'red' }}>*</sup>}
        </label>
      </LabelLayout>

      <TextareaContainer>
        <textarea
          name={name}
          placeholder={placeHolder}
          rows={rows}
          value={value}
          onChange={event => {
            const newValue = event.target.value;
            onChange({
              target: {
                name,
                value: maxCharacters
                  ? newValue.slice(0, maxCharacters)
                  : newValue,
              },
            });
          }}
        />
        {maxCharacters && (
          <TextareaCharacterCount>
            {value ? value.length : 0}/{maxCharacters}
          </TextareaCharacterCount>
        )}
      </TextareaContainer>
      {error && errorMessage ? (
        <p
          style={{
            color: 'red',
            marginTop: '4px',
            fontSize: '14px',
            textAlign: 'left',
          }}
        >
          {errorMessage}
        </p>
      ) : null}
    </InputContainer>
  );
};

export default Textarea;
