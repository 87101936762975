import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';

export const BreadcrumbLayout = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    flex-flow: row wrap;
  }

  svg {
    font-size: 1.4rem;
    margin-right: 1rem;
  }
`;

export const BreadcrumbLink = styled(Link)`
  font-size: 1.6rem;
  font-weight: lighter;
  color: #65637b;
  text-decoration: underline;
  margin-right: 1rem;

  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

export const BreadcrumbEnd = styled.span`
  font-size: 1.6rem;
  color: #65637b;

  @media (max-width: 768px) {
    font-size: 2rem;
    font-weight: bold;
  }
`;
