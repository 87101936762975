import { InputContainer, InputLayout } from 'components/Input/styled';
import styled, { css } from 'styled-components/macro';

export const DatepickerContainer = styled(InputContainer)`
  position: relative;
  color: #65637b;
  font-size: 1.6rem;

  @media (max-width: 768px) {
    font-size: 2.4rem;
  }

  input {
    display: none;
  }
`;

export const DatepickerLayout = styled(InputLayout)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.7rem 1rem;
  cursor: pointer;
  border: 1px solid ${props => (props.error ? '#ef5350' : '#65637b3c')};

  @media (max-width: 768px) {
    padding: 1rem;
  }
`;

export const DatepickerValueLayout = styled.div`
  display: flex;
  flex: 1;
  min-height: 2.1rem;

  @media (max-width: 768px) {
    min-height: 4.2rem;
  }
`;

export const PickerContainer = styled.div`
  z-index: 10;
  position: absolute;
  background-color: white;
  border: 1px solid #65637b3c;

  border-radius: 8px;
  overflow: hidden;

  display: flex;
  flex-direction: column;
`;

export const MonthWeekContainer = styled.div`
  background-color: #65637b3c;
`;

export const MonthLayout = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  button {
    padding: 1rem;
    font-size: 1.6rem;
    svg {
      color: #65637b;
      cursor: pointer;
    }
  }
`;

export const WeekLayout = styled.div`
  display: flex;
`;

export const WeekDayContainer = styled.div`
  min-height: 3rem;
  min-width: 3rem;
  text-align: center;

  @media (max-width: 768px) {
    min-height: 6rem;
    min-width: 6rem;
  }
`;

export const DayContainer = styled.button`
  min-width: 3rem;
  min-height: 3rem;
  text-align: center;
  font-size: 1.6rem;
  color: #65637b;

  background-color: transparent;

  transition: all 0.15s ease;

  &:hover {
    background-color: #65637b3c;
  }

  @media (max-width: 768px) {
    min-height: 6rem;
    min-width: 6rem;
    font-size: 1.8rem;
  }

  ${props =>
    props.selected &&
    css`
      background-color: #ff9e08;
      color: white;
    `}

  ${props =>
    props.disabled &&
    css`
      cursor: default;
      color: #bdbdbd;
      &:hover {
        background-color: transparent;
      }
    `}
`;
