import { useAuthState } from 'context/AuthContext';
import React from 'react';

const NavbarFooter = () => {
  const userType = parseInt(localStorage.getItem('br-memberType'));
  const { user, isGettingUser } = useAuthState();

  return (
    <>
      {!isGettingUser && (
        <>
          {user && userType === 2 && (
            <hr
              style={{
                color: '#ff9e08',
                backgroundColor: '#ff9e08',
                height: '20px',
                border: 'none',
                width: '100%',
                margin: '0px 0px',
              }}
            />
          )}

          {user && userType === 3 && (
            <hr
              style={{
                color: '#4b4c5e',
                backgroundColor: '#4b4c5e',
                height: '20px',
                border: 'none',
                width: '100%',
                margin: '0px 0px',
              }}
            />
          )}
        </>
      )}
    </>
  );
};

export default NavbarFooter;
