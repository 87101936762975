import { ProjectDetailLabel } from 'pages/Project';
import { EDUCATION_ITEM_LABELS } from 'pages/ResearcherProfileForm/rpf_constants';
import React from 'react';

export const RequirementsPreview = props => {
  return (
    <>
      {/* <div style={{ gridArea: 'research' }}>
        <ProjectDetailLabel>RESEARCH MASTERY</ProjectDetailLabel>
        <div>
          {props?.project?.research_mastery &&
          props?.project?.research_mastery?.name
            ? props?.project?.research_mastery?.name
            : '-'}
        </div>
      </div>
      <div style={{ gridArea: 'analytics' }}>
        <ProjectDetailLabel>ANALYTICS MASTERY</ProjectDetailLabel>
        <div>
          {props?.project?.analytics_mastery &&
          props?.project?.analytics_mastery?.name
            ? props?.project?.analytics_mastery?.name
            : '-'}
        </div>
      </div> */}
      <div style={{ gridArea: 'technical_mastery' }}>
        <ProjectDetailLabel>
          Functional & Technical Mastery, Fluency in Insights and Analytics
          (1-5)
        </ProjectDetailLabel>
        <div>
          {props?.project?.technical_mastery
            ? props?.project?.technical_mastery
            : '-'}
        </div>
      </div>
      <div style={{ gridArea: 'industry_expertise' }}>
        <ProjectDetailLabel>
          Highest Stakeholder Management Experience
        </ProjectDetailLabel>
        <div>
          {props?.project?.industry_expertise
            ? props?.project?.industry_expertise
            : '-'}
        </div>
      </div>
      <div style={{ gridArea: 'experience' }}>
        <ProjectDetailLabel>YEARS OF EXPERIENCE</ProjectDetailLabel>
        <div>
          {props?.project?.years_of_experience
            ? props?.project?.years_of_experience
            : '-'}
        </div>
      </div>
      <div style={{ gridArea: 'sizes_of_businesses_worked' }}>
        <ProjectDetailLabel>Size of Businesses Worked</ProjectDetailLabel>
        <div>
          {props?.project?.sizes_of_businesses_worked
            ? props?.project?.sizes_of_businesses_worked?.length > 0
              ? props?.project?.sizes_of_businesses_worked?.map(item => {
                  return <div key={item + 'size'}>{item} employees</div>;
                })
              : '-'
            : '-'}
        </div>
      </div>
      <div style={{ gridArea: 'fortune_exp' }}>
        <ProjectDetailLabel>Fortune 500</ProjectDetailLabel>
        <div>
          {props?.project?.fortune_exp ? props?.project?.fortune_exp : '-'}
        </div>
      </div>
      <div style={{ gridArea: 'minimum_researcher_qualification' }}>
        <ProjectDetailLabel>Relevant higher education</ProjectDetailLabel>
        <div>
          {props?.project?.minimum_researcher_qualification
            ? EDUCATION_ITEM_LABELS[
                props?.project?.minimum_researcher_qualification
              ]
            : '-'}
        </div>
      </div>
    </>
  );
};
