import React from 'react';
import styled, { css } from 'styled-components/macro';

const ButtonComponent = ({
  children,
  style,
  className,
  type = 'button',
  fill,
  outline,
  simple,
  backgroundColor,
  ...props
}) => {
  return (
    <button className={className} style={style} type={type} {...props}>
      <span>{children}</span>
    </button>
  );
};

export const Button = styled(ButtonComponent)`
  align-items: center;

  padding: 1rem 2rem;
  background-color: ${props => props.backgroundColor || '#ff9e08'};
  color: ${props => props.color || 'white'};
  font-size: 1.5rem;
  font-weight: bold;
  border-radius: 8px;
  transition: all 0.2s ease;

  ${props =>
    props.outline &&
    css`
      background-color: transparent;
      color: ${props => props.color || '#ff9e08'};
      border: 1px solid ${props => props.color || '#ff9e08'};
    `}

  ${props =>
    props.fill &&
    css`
      width: 100%;
      justify-content: center;
    `}

  ${props =>
    props.disabled &&
    css`
      filter: grayscale(0.4);
      cursor: not-allowed !important;
    `}

  ${props =>
    props.simple &&
    css`
      color: #ff9e08;
      background: #f2f2f2;
      width: 100%;
      justify-content: center;
      padding: 1rem 2rem;
    `}

  @media (max-width: 768px) {
    padding: 1.5rem 2rem;
    font-size: 1.75rem;
  }

  @media (max-width: 900px) {
    padding: 1.5rem 2rem;
    font-size: 1.75rem;
  }

  svg {
    margin-right: 1rem;
    font-size: 1.8rem;

    &.spin {
      font-size: 1.4rem;
    }
  }
`;

export default Button;
