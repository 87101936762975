import React, { useState, useEffect, useLayoutEffect } from 'react';
import {
  MessagesContent,
  MobileMessageContainer,
  MessagesTitle,
  MessagesListContainer,
  MessageListItem,
  MessageListItemName,
  MessageListItemDate,
  MessageListItemPeek,
} from './styled';
import { Row } from 'components/Layout';
import Input from 'components/Input';
import { FaSearch } from 'react-icons/fa';
// import researchers from 'utils/researcher-data';
import animateChat from './animateChat.gif';
// import faker from 'faker';
import { format } from 'date-fns';
import useNodeState from 'hooks/useNodeState';
import deepEqual from 'fast-deep-equal';
import usePrevious from 'hooks/usePrevious';
import Conversation from 'pages/Conversation';
import { ajax } from 'utils/ajax';
import { useLocation, useHistory } from 'react-router-dom';
import SessionModal from 'components/SessionModal';
import { upload } from 'utils/upload';
import { Loading } from 'components/Loading';
import { useAuthDispatch } from 'context/AuthContext';

export const Messages = () => {
  const [conversationRef, conversationEl] = useNodeState();
  const authDispatch = useAuthDispatch();

  const [message, setMessage] = useState('');
  const [chats, setChats] = useState([]);
  const location = useLocation();
  const [selectedChat, setSelectedChat] = useState(
    location?.state?.selectedChat ? location?.state?.selectedChat : null
  );
  const prevSelectedChat = usePrevious(selectedChat);
  const [message_type, setMessageType] = useState('text');
  const [uploaded_files, setUploadedFiles] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [unreadCount, setUnreadCount] = useState(0);
  const [conversationId, setConversationId] = useState(null);
  const [lastReadDate, setLastReadDate] = useState(null);
  const [hideUnread, setHideUnread] = useState(false);

  const isMobileMode = () => {
    return window.matchMedia('(max-width: 768px)').matches;
  };

  const [isMobile, setIsMobile] = useState(isMobileMode());

  useLayoutEffect(() => {
    function setIsMobileMode() {
      setIsMobile(isMobileMode());
    }

    window.addEventListener('resize', setIsMobileMode);

    return function cleanup() {
      window.removeEventListener('resize', setIsMobileMode);
    };
  });
  useEffect(() => {
    getSelectedConversation(selectedChat?.conversation_id);
    if (selectedChat?.conversation_id) {
      authDispatch({
        type: 'CONVERSATION',
        payload: { conversationId: conversationId },
      });
    } else {
      authDispatch({
        type: 'CONVERSATION',
        payload: { conversationId: null },
      });
    }
  }, [selectedChat]);

  const getConversationChats = async (convId = null) => {
    setLoading(true);
    let senderRes;
    if (location?.state?.sender_id) {
      senderRes = await ajax('GET', `/member/${location?.state?.sender_id}`);
    }
    const url = location?.state?.conversation_id
      ? '/messages/' +
        parseInt(localStorage.getItem('br-userId')) +
        `?required_conv_id=${location?.state?.conversation_id || null}`
      : '/messages/' + parseInt(localStorage.getItem('br-userId'));

    ajax('GET', url).then(result => {
      if (result.code === 200 && result.data) {
        let messages = result?.data;
        if (result?.data?.length > 0) {
          messages = messages.map(message => {
            return {
              ...message,
              receiver_id:
                message.receiver && message.receiver.id
                  ? message.receiver.id
                  : null,
              sender_id:
                message.sender && message.sender.id ? message.sender.id : null,
              project_id: message.project_id ? message.project_id : null,
              unread_msg_count: message?.unread_msg_count || 0,
            };
          });
        }
        messages = messages.sort(
          (a, b) => new Date(b.created_date) - new Date(a.created_date)
        );
        setChats(messages);

        if (
          !selectedChat ||
          (localStorage.getItem('project_id') &&
            localStorage.getItem('project_id') !== '0')
        ) {
          setLoading(false);
        }

        if (convId) {
          const chat = messages.find(chat => chat.conversation_id === convId);
          if (chat) {
            console.log('here', chat);
            getConversation(chat, false);
          } else {
            // if (res.code === 200) {
            const userObj = {
              conversation_id: location?.state?.conversation_id,
              receiver: location?.state?.receiver,
              sender: senderRes?.data?.member,
              project_id: location?.state?.project_id
                ? location?.state?.project_id
                : null,
              created_date: new Date(),
              is_read_by_recipient: false,
              links: '',
              message: '',
              message_type: 'text',
              receiver_id: location?.state?.receiver_id,
              sender_id: location?.state?.sender_id,
            };
            getConversationNew(userObj, false);
          }
        }
      } else if (result.code === 401) {
        setIsSending(false);
        setIsOpen(true);
        setLoading(false);
      }
    });
  };

  const getSelectedConversation = user => {
    getConversationChats(user?.conversation_id);
  };

  useEffect(() => {
    if (location?.state?.conversation_id) {
      getConversationChats(location?.state?.conversation_id);
    } else {
      getConversationChats();
    }
  }, []);

  useEffect(() => {
    if (conversationEl && !deepEqual(prevSelectedChat, selectedChat)) {
      setMessage('');
      conversationEl.scrollTop = conversationEl.scrollHeight;
    }
  }, [conversationEl, conversationRef, prevSelectedChat, selectedChat]);

  const getConversation = async (user, flag) => {
    setLoading(true);
    const url = '/messages/conversation/' + parseInt(user.conversation_id);
    const result = await ajax('GET', url);
    if (result?.code === 200 && result?.data) {
      setMessage('');
      setUnreadCount(user?.unread_msg_count || 0);
      setConversationId(user?.conversation_id);

      let messages = result?.data?.messages;
      localStorage.setItem(
        'show_final_output',
        result?.data?.show_final_output
      );
      if (messages?.length > 0) {
        messages = messages.map(message => {
          return {
            ...message,
            receiver_id:
              message.receiver && message.receiver.id
                ? message.receiver.id
                : null,
            sender_id:
              message.sender && message.sender.id ? message.sender.id : null,
            project_id: message.project_id ? message.project_id : null,
          };
        });
      }
      user['chat'] = messages.sort(
        (a, b) => new Date(a.created_date) - new Date(b.created_date)
      );

      if (user.project_id) {
        const project = await ajax('GET', `/project/${user.project_id}`);
        user['project_status_id'] = project.data.project.project_status_id;
      }
      setIsSending(false);
      setSelectedChat(user);
      setLoading(false);

      if (flag) {
        setMessage('');
        setMessageType('text');
        setUploadedFiles([]);
      }
      setLoading(false);
    } else if (result.code === 401) {
      setUnreadCount(user?.unread_msg_count || 0);
      setConversationId(user?.conversation_id || null);
      setIsSending(false);
      setIsOpen(true);
      setLoading(false);
    }
  };

  const getConversationNew = async (user, flag) => {
    console.log('user: ', user);
    setLoading(true);
    const url = '/messages/conversation/' + parseInt(user.conversation_id);
    const result = await ajax('GET', url);
    if (result.code === 200 && result?.data) {
      console.log('result: ', result);
      localStorage.setItem(
        'show_final_output',
        result?.data?.show_final_output
      );
      let messages = result?.data?.messages;
      user['chat'] = messages.sort(
        (a, b) => new Date(a.created_date) - new Date(b.created_date)
      );
      if (user.project_id) {
        const project = await ajax('GET', `/project/${user.project_id}`);
        user['project_status_id'] = project.data.project.project_status_id;
      }
      setIsSending(false);
      setChats(prevState => [...prevState, user]);
      setSelectedChat(user);
      if (flag) {
        setMessage('');
        setMessageType('text');
        setUploadedFiles([]);
      }
      setLoading(false);
    } else if (result.code === 401) {
      setIsSending(false);
      setIsOpen(true);
      setLoading(false);
    }
  };

  const sendMessage = async (data, message, message_type) => {
    setIsSending(true);
    if (!hideUnread) {
      setHideUnread(true);
    }
    const attachments = [];
    if (uploaded_files.length > 0) {
      const res = await upload(
        uploaded_files[0].file,
        'messages',
        uploaded_files[0].filename
      );
      if (res.status === 200) {
        getConversationChats();
        attachments.push({
          attachment_type: 'file_upload',
          description: '',
          filename: res.filename,
          url: res.Key,
        });
      }
    }

    let receiver = data.sender.id;
    const user_id = parseInt(localStorage.getItem('br-userId'));
    const payload = {
      receiver_id: parseInt(receiver),
      sender_id: parseInt(localStorage.getItem('br-userId')),
      message_type: message_type,
      message: message,
      links: '',
      conversation_id: data.conversation_id,
      attachments: attachments,
    };
    if (
      (selectedChat.project_id && payload.message_type === 'send proposal') ||
      payload.message_type === 'send final output'
    ) {
      payload['project_id'] = selectedChat.project_id;
    }
    if (data.sender.id === user_id) {
      payload['receiver_id'] = parseInt(data.receiver.id);
    }
    setLoading(true);
    const url = '/messages';
    await ajax('POST', url, payload).then(result => {
      if (result.code === 200) {
        setLoading(false);
        getConversation(data, true);
      } else if (result.code === 401) {
        setLoading(false);
        setIsSending(false);
        setIsOpen(true);
      }
    });
  };

  const sendMessageModal = async (
    data,
    message,
    projectId,
    bitAmount,
    type,
    isProposal = false
  ) => {
    setIsSending(true);
    const attachments = [];
    if (uploaded_files.length > 0) {
      const res = await upload(
        uploaded_files[0].file,
        'messages',
        uploaded_files[0].filename
      );
      if (res.status === 200) {
        attachments.push({
          attachment_type: 'file_upload',
          description: '',
          filename: res.filename,
          url: res.Key,
        });
      }
    }

    let receiver = data.sender.id;
    const user_id = parseInt(localStorage.getItem('br-userId'));
    const payload = {
      receiver_id: parseInt(projectId?.sender_id) || parseInt(receiver),
      sender_id: user_id,
      message_type: type,
      message: message,
      links: '',
      conversation_id: data.conversation_id,
      attachments: attachments,
      project_id: projectId.id,
    };
    if (bitAmount) {
      payload['proposed_amount'] = bitAmount;
    }
    if (data.sender.id === user_id) {
      payload['receiver_id'] = data.receiver.id;
    }
    const url = '/messages';
    await ajax('POST', url, payload).then(result => {
      if (result.code === 200) {
        setUploadedFiles([]);
        if (isProposal) {
          const body = {
            receiver_id: parseInt(receiver),
            sender_id: user_id,
            message_type: 'text',
            message: `Please find my proposal for ‘${projectId?.name}’`,
            links: '',
            conversation_id: data.conversation_id,
            project_id: projectId.id,
          };
          if (data.sender.id === user_id) {
            body['receiver_id'] = data.receiver.id;
          }
          // console.log('object==receiver', data.sender.id);
          // console.log('object==send', user_id);
          // console.log('body: ', body);
          ajax('POST', url, body).then(result => {
            if (result.code === 200) {
              getConversation(data, true);
            } else if (result.code === 401) {
              setIsSending(false);
              setIsOpen(true);
            }
          });
        } else {
          getConversation(data, true);
        }
      } else if (result.code === 401) {
        setIsSending(false);
        setIsOpen(true);
      }
    });
  };

  const proposalActions = (
    project_id,
    researcher_id,
    action,
    amount,
    message_id
  ) => {
    if (action === 'view' && project_id) {
      history.push('/project/' + project_id);
    } else if (project_id) {
      localStorage.setItem('amount', amount);
      history.push({
        pathname:
          '/project-payment/' +
          project_id +
          '/' +
          researcher_id +
          '/' +
          action +
          '/' +
          message_id,
        state: { selectedChat: selectedChat },
      });
    }
  };

  const clearSelectedChat = () => {
    if (isMobile) {
      setSelectedChat(null);
    }
  };

  if (isMobile) {
    return (
      <MessagesContent>
        <MobileMessageContainer>
          <MessagesListContainer chats={selectedChat}>
            <MessagesTitle>Messages</MessagesTitle>
            <Input
              placeholder="Type sender name or message"
              leftLabel={<FaSearch />}
            />
            <div style={{ overflowY: 'auto' }}>
              {chats.map((user, idx) => {
                const {
                  is_read_by_recipient,
                  latest_message,
                  conversation_id,
                  unread_msg_count,
                } = user;
                const { avatar_link, full_name, first_name, last_name, id } =
                  user.receiver_id ===
                  parseInt(localStorage.getItem('br-userId'))
                    ? user.sender
                    : user.receiver;

                return (
                  <MessageListItem
                    key={conversation_id}
                    selected={
                      selectedChat &&
                      selectedChat.conversation_id === conversation_id
                    }
                    notRead={!is_read_by_recipient}
                    onClick={() => {
                      //setSelectedChat(user);
                      localStorage.setItem('sender_id', id);
                      if (idx === 0) {
                        setLastReadDate(user?.created_date);
                      }
                      getConversation(user, false);
                    }}
                  >
                    <img
                      src={
                        avatar_link
                          ? avatar_link
                          : require('../../components/UserMenu/default.webp')
                      }
                      alt={full_name}
                    />
                    <div style={{ minWidth: 0 }}>
                      <Row wrap="nowrap">
                        <MessageListItemName>
                          {first_name} {last_name}
                        </MessageListItemName>

                        {/* {!is_read_by_recipient && (
                          <div
                            style={{
                              // height: '1.2rem',
                              // minWidth: '1.2rem',
                              borderRadius: '50%',
                              marginTop: '0.5rem',
                              margin: '0 1rem',
                              backgroundColor: '#F58C06',
                              display: 'inline',
                              color: '#ffffff',
                              fontSize: '12px',
                            }}
                          >
                            {unread_msg_count}
                          </div>
                        )} */}
                      </Row>

                      <Row wrap="nowrap" alignItems="center">
                        <MessageListItemPeek>
                          {latest_message}
                        </MessageListItemPeek>
                      </Row>
                      <MessageListItemDate>
                        {format(
                          new Date(user.created_date),
                          'MMMM d, yyyy'
                        ).toUpperCase()}
                      </MessageListItemDate>
                    </div>
                    <div
                      style={{
                        marginLeft: 'auto',
                        marginRight: '20px',
                        paddingLeft: '10px',
                        marginTop: 'auto',
                        marginBottom: 'auto',
                      }}
                    >
                      {unread_msg_count > 0 && (
                        <div
                          style={{
                            width: '22px',
                            height: '22px',
                            borderRadius: '50%',
                            backgroundColor: '#F58C06',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            color: '#ffffff',
                            fontSize: '10px',
                          }}
                        >
                          {unread_msg_count || 0}
                        </div>
                      )}
                    </div>
                  </MessageListItem>
                );
              })}
            </div>
          </MessagesListContainer>
          {loading ? (
            <div style={{ width: '100%', height: '100%' }}>
              <Loading />
            </div>
          ) : selectedChat !== null ? (
            <Conversation
              style={{ display: 'none' }}
              key={selectedChat}
              unreadCount={unreadCount}
              lastReadDate={lastReadDate}
              conversationId={conversationId}
              conversationRef={conversationRef}
              chats={selectedChat}
              message={message}
              isMobile={isMobile}
              getConversationChats={getConversationChats}
              clearSelectedChat={clearSelectedChat}
              setSelectedChat={setSelectedChat}
              setMessage={setMessage}
              setChats={setChats}
              handleSendMessage={sendMessage}
              sendMessageModal={sendMessageModal}
              setMessageType={setMessageType}
              message_type={message_type}
              uploaded_files={uploaded_files}
              setUploadedFiles={setUploadedFiles}
              handleActionButtons={proposalActions}
              isSending={isSending}
              getConversation={getConversation}
              hideUnread={hideUnread}
              setLoading={setLoading}
            />
          ) : null}
        </MobileMessageContainer>
        <SessionModal
          isOpen={isOpen}
          setModalVisible={setIsOpen}
          message={'Your session has expired.'}
        />
      </MessagesContent>
    );
  } else {
    return (
      <MessagesContent>
        <MessagesTitle>Messages</MessagesTitle>
        <Row style={{ height: '100%' }}>
          <MessagesListContainer>
            <Input
              placeholder="Type sender name or message"
              leftLabel={<FaSearch />}
            />
            <div style={{ overflowY: 'auto' }}>
              {chats.map((user, idx) => {
                const {
                  is_read_by_recipient,
                  latest_message,
                  conversation_id,
                  unread_msg_count,
                } = user;
                const { avatar_link, full_name, first_name, last_name, id } =
                  user.receiver_id ===
                  parseInt(localStorage.getItem('br-userId'))
                    ? user.sender
                    : user.receiver;

                return (
                  <MessageListItem
                    key={conversation_id}
                    selected={
                      selectedChat &&
                      selectedChat.conversation_id === conversation_id
                    }
                    notRead={!is_read_by_recipient}
                    onClick={async () => {
                      //setSelectedChat(user);
                      localStorage.setItem('sender_id', id);
                      setLoading(true);
                      if (idx === 0) {
                        setLastReadDate(user?.created_date);
                      }
                      await getConversation(user, false);
                      setLoading(false);
                    }}
                  >
                    <img
                      src={
                        avatar_link
                          ? avatar_link
                          : require('../../components/UserMenu/default.webp')
                      }
                      alt={full_name}
                    />
                    <div style={{ minWidth: 0 }}>
                      <Row wrap="nowrap">
                        <MessageListItemName>
                          {first_name} {last_name}
                        </MessageListItemName>

                        {/* {!is_read_by_recipient && (
                          <div
                            style={{
                              height: '1.2rem',
                              minWidth: '1.2rem',
                              borderRadius: '50%',
                              marginTop: '0.5rem',
                              margin: '0 1rem',
                              backgroundColor: '#F58C06',
                              display: 'inline',
                            }}
                          />
                        )} */}
                      </Row>

                      <Row wrap="nowrap" alignItems="center">
                        <MessageListItemPeek>
                          {latest_message}
                        </MessageListItemPeek>
                      </Row>
                      <MessageListItemDate>
                        {format(
                          new Date(user.created_date),
                          'MMMM d, yyyy'
                        ).toUpperCase()}
                      </MessageListItemDate>
                    </div>
                    <div
                      style={{
                        marginLeft: 'auto',
                        marginRight: '20px',
                        paddingLeft: '10px',
                        marginTop: 'auto',
                        marginBottom: 'auto',
                      }}
                    >
                      {unread_msg_count > 0 && (
                        <div
                          style={{
                            width: '22px',
                            height: '22px',
                            borderRadius: '50%',
                            backgroundColor: '#F58C06',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            color: '#ffffff',
                            fontSize: '10px',
                          }}
                        >
                          {unread_msg_count || 0}
                        </div>
                      )}
                    </div>
                  </MessageListItem>
                );
              })}
            </div>
          </MessagesListContainer>
          {loading ? (
            <div style={{ flex: 'auto' }}>
              <Loading />
            </div>
          ) : selectedChat !== null ? (
            <Conversation
              key={selectedChat}
              conversationRef={conversationRef}
              unreadCount={unreadCount}
              lastReadDate={lastReadDate}
              conversationId={conversationId}
              getConversationChats={getConversationChats}
              chats={selectedChat}
              message={message}
              isMobile={isMobile}
              selectedChat={selectedChat}
              setMessage={setMessage}
              setChats={setChats}
              handleSendMessage={sendMessage}
              sendMessageModal={sendMessageModal}
              setMessageType={setMessageType}
              message_type={message_type}
              uploaded_files={uploaded_files}
              setUploadedFiles={setUploadedFiles}
              handleActionButtons={proposalActions}
              isSending={isSending}
              getConversation={getConversation}
              hideUnread={hideUnread}
              setLoading={setLoading}
            />
          ) : (
            <div style={{ flex: 'auto' }}>
              <h3 style={{ textAlign: 'center' }}>Welcome to Messages</h3>
              <div style={{ textAlign: 'center', marginTop: '8rem' }}>
                <h5
                  style={{
                    textAlign: 'center',
                    fontWeight: 'normal',
                    color: 'rgb(245, 140, 6)',
                  }}
                >
                  {localStorage.getItem('isResearcher') === 'true'
                    ? 'Chat with clients'
                    : 'Chat with researchers'}
                </h5>
                <img
                  src={animateChat}
                  alt="loading..."
                  style={{ width: '20rem' }}
                />
              </div>
            </div>
          )}
        </Row>
        <SessionModal
          isOpen={isOpen}
          setModalVisible={setIsOpen}
          message={'Your session has expired.'}
        />
      </MessagesContent>
    );
  }
};
