import Content from 'components/Content';
import styled from 'styled-components/macro';
import { Row, Column } from 'components/Layout';
import { Link } from 'react-router-dom';

export const PageContent = styled(Content)`
  margin: 4rem 0;
`;

export const PageTitle = styled.h1`
  font-size: 2.2rem;
  color: #4b4c5e;
  margin-top: 2.15rem;
`;

export const PageContainer = styled.div`
  margin-top: 1.5rem;

  padding: 4rem;
  background-color: white;
`;

export const PageLabel = styled.div`
  letter-spacing: 0px;
  color: #65637b;
  width: 100%;
  display: flex;
`;

export const PageButtonLayout = styled(Row)`
  margin: 4rem 0;
`;

export const BalanceContent = styled.div`
  margin-top: 4rem;
  margin-bottom: 60px;
`;

export const BalanceTitle = styled.div`
  color: #65637b;
  font-weight: bold;
  font-size: 24px;
  margin-bottom: 39px;
`;

export const BalanceBreakdown = styled(Row)`
  display: flex;
  margin-bottom: 24px;
`;

export const BalanceLabel = styled.div`
  width: 40%;
`;

export const BalanceAmount = styled.div`
  min-width: 100px;
  text-align: right;
`;

export const BalanceTotalLabel = styled.div`
  width: 40%;
  font-weight: bold;
  font-size: 24px;
`;

export const BreadcrumbLayout = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    flex-flow: row wrap;
  }

  svg {
    font-size: 1.4rem;
    margin-right: 1rem;
  }
`;

export const BreadcrumbLink = styled(Link)`
  font-size: 1.6rem;
  font-weight: lighter;
  color: #65637b;
  text-decoration: underline;
  margin-right: 1rem;

  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

export const BreadcrumbEnd = styled.span`
  font-size: 1.6rem;
  color: #65637b;

  @media (max-width: 768px) {
    font-size: 2rem;
    font-weight: bold;
  }
`;

export const BreadcrumbMiddle = styled.span`
  font-size: 1.6rem;
  font-weight: lighter;
  color: #65637b;
  text-decoration: underline;
  margin-right: 1rem;

  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

export const ModalContent = styled(Row)`
  background-color: white;
  padding: 2rem;
  flex-direction: column;
  justify-content: flex-end;
  min-width: 200px;
  min-height: 90px;
}
`;

export const ModalMsg = styled(Column)`
  margin-bottom: 2rem;
  margin-top: 2rem;
  text-align: center;
`;

export const ModalButton = styled(Column)`
  margin-bottom: 1rem;
`;
