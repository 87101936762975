import React, { useEffect, useState } from 'react';
import { format, isSameDay } from 'date-fns';
import { useRef } from 'react';
import {
  FaPaperclip,
  FaChevronCircleRight,
  FaTimesCircle,
  FaList,
  FaFile,
  // FaPhoneAlt,
  FaChevronLeft,
  FaInfoCircle,
  FaFileUpload,
  FaUpload,
} from 'react-icons/fa';
import { Fragment } from 'react';
import { Row } from 'components/Layout';
import Input from 'components/Input';
import Message from 'components/Message';
import FileUploaded from './FileUploaded';
import './style.css';
import {
  MessagesConversationContainer,
  MessagesConversation,
  ConversationItemUserImage,
  ConversationItemUserImageContainer,
  DateDivider,
  DateDividerBar,
  DividerDate,
  MessageInputDiv,
  MessageInputContainer,
  IconContainer,
  ActionButtons,
  UploadedFileContainer,
  MessageForm,
  SendBtn,
  BackBtn,
  UploadedFileContainerModal,
  SPReactTooltip,
  AddAttachment,
} from './styled';
import ReactTooltip from 'react-tooltip';
import Modal from 'components/Modal';
import Button from 'components/Button';
import cross from './cross.png';
import attach from './attach.png';
import { ajax } from 'utils/ajax';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import HandleMandatePopup from 'components/HandleMandatePopup';
import moment from 'moment';
import { MessageListItemName } from 'pages/Messages';
import Textarea from 'components/Textarea/Textarea';
const SEND_PROPOSAL = 'send proposal';
const SEND_FINAL_OUTPUT = 'send final output';
const UPLOAD_FILE = 'upload file';
// const BOOK_A_CALL = 'book call';
const TEXT = 'text';

const Conversation = props => {
  const fileInputRef = useRef();
  const fileSendInputRef = useRef();
  const unreadRef = useRef();

  const [showButton, setShowButton] = useState(false);
  const USER_ID = parseInt(localStorage.getItem('br-userId'));
  const MEMBER_TYPE = parseInt(localStorage.getItem('br-memberType'));
  const bottomRef = useRef(null);
  const [isShowingModal, setIsShowingModal] = useState(null);
  const [proList, setProList] = useState([]);
  const [msgType, setmsgType] = useState(null);
  const [proListValue, setProListValue] = useState(null);
  const [inproList, setInProList] = useState(null);
  const [bitAmount, setBitAmount] = useState('');
  const [modalValid, setModalValid] = useState(false);
  const [userDetail, setUserDetail] = useState({});
  const [deductionPopup, setDeductionPopup] = useState(false);
  const [hasHandle, setHasHandle] = useState(false);
  // useEffect(() => {

  // });

  useEffect(() => {
    (async () => {
      const userId = localStorage.getItem('br-userId');
      if (userId) {
        const res = await ajax('GET', `/member/${userId}`);

        if (res.code === 200) {
          setUserDetail(res?.data?.member);
        }
      }
    })();
  }, []);

  useEffect(() => {
    if (isShowingModal) {
      projectList();
      inProgressproList();
    }
  }, [isShowingModal]);

  useEffect(() => {
    if (
      localStorage.getItem('project_id') !== '0' &&
      localStorage.getItem('project_id')
    ) {
      let pro = {
        id: Number(localStorage.getItem('project_id')),
        name: localStorage.getItem('project_name'),
        endDate: localStorage.getItem('endDate'),
        sender_id: localStorage.getItem('sender_id'),
      };
      props.setUploadedFiles([]);
      setProListValue(pro);
      setBitAmount('');
      setIsShowingModal(SEND_PROPOSAL);
      setmsgType(null);
      setModalValid(false);
    }
  }, []);

  const markRead = async () => {
    const res = await ajax('POST', `/update-messages`, {
      conversation_id: props?.conversationId,
      created_date: props?.lastReadDate || moment(new Date()).format(),
    });
    if (res?.code === 200) {
      console.log('res');
      // clg
    }
  };

  useEffect(() => {
    if (
      props?.unreadCount > 0 &&
      props?.unreadCount <= props?.chats?.chat?.length &&
      !props?.hideUnread
    ) {
      unreadRef.current.scrollIntoView({
        block: 'center',
        inline: 'nearest',
        // behavior: 'smooth',
      });
    } else {
      bottomRef.current.scrollIntoView({
        block: 'nearest',
        inline: 'start',
      });
    }
    if (props?.unreadCount > 0) {
      markRead();
    }
  }, []);

  const projectList = async () => {
    const userId = localStorage.getItem('br-userId');
    if (localStorage.getItem('isResearcher') === 'true') {
      const res = await ajax(
        'GET',
        `/related-project-list?client_id=${localStorage.getItem(
          'sender_id'
        )}&researcher_id=${userId}`
      );
      if (res?.code === 200) {
        const listUpdate =
          res?.data?.length > 0
            ? res?.data?.map(item => {
                return {
                  id: item?.project_id,
                  name: item?.project_name,
                  endDate: item?.end_date,
                };
              })
            : [];
        if (
          localStorage.getItem('project_id') === '0' &&
          localStorage.getItem('project_id')
        ) {
          const filterInvite =
            res?.data?.length > 0
              ? res?.data?.filter(item => item?.is_invited === true)
              : null;
          setProListValue(
            filterInvite?.length > 0
              ? {
                  id: filterInvite[0]?.project_id,
                  name: filterInvite[0]?.project_name,
                }
              : null
          );
        }
        setProList(listUpdate);
      }
    }
  };

  const inProgressproList = async () => {
    const userId = localStorage.getItem('br-userId');
    if (localStorage.getItem('isResearcher') === 'true') {
      const res = await ajax(
        'GET',
        `/in-progress-project-list?client_id=${localStorage.getItem(
          'sender_id'
        )}&researcher_id=${userId}`
      );
      if (res?.code === 200) {
        const listUpdate =
          res?.data?.length > 0
            ? res?.data?.map(item => {
                return {
                  id: item?.project_id,
                  name: item?.project_name,
                };
              })
            : [];
        setInProList(listUpdate);
      }
    }
  };

  const handleSendProposal = () => {
    if (userDetail?.borderless_handle) {
      props.setUploadedFiles([]);
      setProListValue(null);
      setBitAmount('');
      setIsShowingModal(SEND_PROPOSAL);
      localStorage.setItem('project_id', 0);
      setmsgType(null);
      setModalValid(false);
    } else {
      setHasHandle(true);
    }
  };

  const handleFinalOutput = () => {
    if (userDetail?.borderless_handle) {
      props.setUploadedFiles([]);
      setProListValue(null);
      setBitAmount('');
      setIsShowingModal(SEND_FINAL_OUTPUT);
      localStorage.setItem('project_id', 0);
      setmsgType(null);
      setModalValid(false);
    } else {
      setHasHandle(true);
    }
  };

  const handleUpload = async e => {
    props.setUploadedFiles([]);
    const files = e.target.files;
    // e.target.value = '';
    if (files && files.length > 0) {
      let uploaded_file = [];
      for (var i in files) {
        if (typeof files[i] === 'object') {
          uploaded_file.push({
            id: Math.random() * 1000,
            filename: files[i].name,
            filetype: files[i].type,
            file: files[i],
          });
        }
      }
      props.setUploadedFiles(uploaded_file);
    } else {
      props.setMessageType(TEXT);
      props.setUploadedFiles([]);
    }
  };

  const getFiles = () => {
    let files_component = null;
    files_component = props.uploaded_files.map((item, index) => {
      return (
        <FileUploaded
          key={index}
          id={item.id}
          filename={item.filename}
          deleteFile={removeFiles}
          type={item.filetype}
        />
      );
    });
    return files_component;
  };

  const getFilesData = () => {
    let files_component = null;
    files_component = props.uploaded_files.map((item, index) => {
      return (
        <FileUploaded
          key={index}
          id={item.id}
          filename={item.filename}
          deleteFile={removeFiles}
          type={item.filetype}
        />
      );
    });
    return files_component;
  };
  const removeFiles = id => {
    const uploaded = props.uploaded_files;
    const new_files = uploaded.filter(file => file.id !== id);
    props.setUploadedFiles(new_files);
  };

  const MenuButtons = () => {
    let menuItems = [
      // {
      //   message_type: SEND_PROPOSAL,
      //   icon: <FaList color="#ff9e08" />,
      //   tooltip: 'Send your proposal',
      // },
      // {
      //   message_type: SEND_FINAL_OUTPUT,
      //   icon: <FaFile color="#ff9e08" />,
      //   tooltip: 'Send your final output',
      // },
      {
        message_type: UPLOAD_FILE,
        icon: <FaPaperclip color="#ff9e08" />,
        tooltip: 'Upload a file',
      },
    ];
    if (MEMBER_TYPE === 2) {
      menuItems = [
        {
          message_type: UPLOAD_FILE,
          icon: <FaPaperclip color="#ff9e08" />,
          tooltip: 'Upload a file',
        },
      ];
    }
    const menubuttons = (
      <>
        {!showButton && (
          <button
            type="button"
            style={{
              marginRight: '1rem',
              // marginTop: '12px',
              fontSize: '24px',
            }}
            onClick={() => {
              setShowButton(true);
            }}
          >
            <FaChevronCircleRight color="#65637B" />
          </button>
        )}
        {showButton && (
          <IconContainer>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <button
                type="button"
                style={{
                  marginRight: '1.3rem',
                  // marginTop: '12px',
                  fontSize: '24px',
                  height: 'fit-content',
                  display: 'flex',
                  alignItems: 'center',
                }}
                onClick={() => {
                  setShowButton(false);
                }}
              >
                <FaTimesCircle color="#DADADF" />
              </button>
              {localStorage?.getItem('isResearcher') === 'true' && (
                <span
                  data-tip
                  data-for="approveButton"
                  style={{
                    height: 'fit-content',
                  }}
                >
                  <ActionButtons
                    type="button"
                    onClick={handleSendProposal}
                    data-tip={'Send your proposal'}
                    data-for="button-tooltip"
                  >
                    <FaList
                      color={
                        userDetail?.stripe_account_id === null
                          ? 'gray'
                          : '#ff9e08'
                      }
                    />
                  </ActionButtons>
                </span>
              )}
              {localStorage?.getItem('isResearcher') === 'true' &&
                localStorage?.getItem('show_final_output') === 'true' && (
                  <span
                    data-tip
                    data-for="approveButton"
                    style={{
                      height: 'fit-content',
                    }}
                  >
                    <ActionButtons
                      type="button"
                      onClick={handleFinalOutput}
                      data-tip={'Send your final output'}
                      data-for="button-tooltip"
                    >
                      <FaFile
                        color={
                          userDetail?.stripe_account_id === null
                            ? 'gray'
                            : '#ff9e08'
                        }
                      />
                    </ActionButtons>
                  </span>
                )}
              {userDetail?.stripe_account_id === null && (
                <SPReactTooltip
                  id="approveButton"
                  effect="solid"
                  backgroundColor="#393939"
                  multiline={true}
                  style={{
                    fontSize: '1.2rem',
                    color: '#fff',
                  }}
                >
                  <div>Please connect to Stripe in the profile section</div>
                </SPReactTooltip>
              )}
              {menuItems.map((item, index) => {
                return (
                  <ActionButtons
                    key={index}
                    type="button"
                    onClick={() => {
                      if (fileInputRef.current) {
                        fileInputRef.current.click();
                      }
                      props.setMessageType(item.message_type);
                      setmsgType(item.message_type);
                    }}
                    data-tip={item.tooltip}
                    data-for="button-tooltip"
                  >
                    {item.icon}
                  </ActionButtons>
                );
              })}
              <ReactTooltip id="button-tooltip" effect="solid" />
            </div>
          </IconContainer>
        )}
        <input
          type="file"
          ref={fileInputRef}
          style={{ display: 'none' }}
          accept="image/*, .pdf, .doc, .docs, .ppt, .pptx, .xls, .xlsx"
          onChange={e => {
            e.target.value = '';
            // handleUpload(e);
          }}
          onInput={e => {
            handleUpload(e);
          }}
          multiple
        />
      </>
    );
    return menubuttons;
  };

  return (
    <MessagesConversationContainer chats={props.chats}>
      {props.isMobile ? (
        <Row justify-content="flex-start">
          <BackBtn
            type="button"
            onClick={() => {
              if (props.isMobile && props.clearSelectedChat) {
                props.clearSelectedChat();
              }
            }}
          >
            <FaChevronLeft color="#65637B" />
          </BackBtn>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <img
              style={{
                height: '4.5rem',
                minWidth: '4.5rem',
                borderRadius: '50%',
                margin: '0 1.5rem',
              }}
              src={
                props?.chats?.receiver?.avatar_link || require('./default.webp')
              }
              alt={props?.chats?.receiver?.full_name || 'profile'}
            />
            <MessageListItemName
              style={{
                overflowV: 'hidden',
              }}
            >
              {props?.chats?.receiver?.last_name
                ? props?.chats?.receiver?.first_name +
                  ' ' +
                  props?.chats?.receiver?.last_name
                : props?.chats?.receiver?.first_name}
            </MessageListItemName>
          </div>
        </Row>
      ) : null}
      <MessagesConversation
        ref={props.conversationRef}
        key={props.chats && props.chats.conversation_id}
      >
        {props.chats &&
          props.chats.chat &&
          props.chats.chat.map(
            (
              {
                attachments,
                conversation_id,
                sender,
                receiver,
                message,
                created_date,
                message_type,
                receiver_id,
                sender_id,
                project_id,
                is_payment_in_progress,
                project_name,
                proposed_amount,
                bid_amount,
                project_status_id,
                id,
              },
              index
            ) => {
              const length = props.chats.chat.length;
              let hasPleaseRevise = false;
              if (index !== length - 1) {
                if (props.chats.chat[index + 1]['message'] === 'Please revise')
                  hasPleaseRevise = true;
              }
              const isSender = sender.id === USER_ID ? true : false;
              const receiverName =
                receiver?.first_name + ' ' + receiver?.last_name;
              let receiver_name = '';
              const senderEmail = sender?.email;
              if (sender.id !== USER_ID && sender.full_name) {
                receiver_name = sender.full_name.split(' ')[0];
              } else if (receiver.id !== USER_ID && receiver.full_name) {
                receiver_name = receiver.full_name.split(' ')[0];
              }
              const image = (
                <ConversationItemUserImageContainer alignItems="center">
                  <ConversationItemUserImage
                    src={
                      // USER_ID === sender?.id
                      // ?
                      sender?.avatar_link ||
                      require('../../components/UserMenu/default.webp')
                      // : sender?.avatar_link ||
                      //   require('../../components/UserMenu/default.webp')
                    }
                  />
                  <div>{isSender ? 'You' : receiver_name}</div>
                </ConversationItemUserImageContainer>
              );
              return (
                <Fragment key={index}>
                  {(index === 0 ||
                    !isSameDay(
                      new Date(props.chats.chat[index - 1].created_date),
                      new Date(created_date)
                    )) && (
                    <DateDivider>
                      <DateDividerBar />
                      <DividerDate>
                        {format(
                          new Date(created_date),
                          'MMMM d, yyyy'
                        ).toUpperCase()}
                      </DividerDate>
                      <DateDividerBar />
                    </DateDivider>
                  )}

                  {index === props?.chats?.chat?.length - props?.unreadCount &&
                    !props?.hideUnread && (
                      <div
                        ref={unreadRef}
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          marginBottom: '24px',
                          position: 'relative',
                        }}
                      >
                        {/* <DateDividerBar /> */}

                        <div
                          style={{
                            position: 'absolute',
                            top: '50%',
                            height: '2px',
                            width: '100%',
                            background: '#65637b',
                            opacity: '0.7',
                            zIndex: 0,
                          }}
                        />

                        <div
                          style={{
                            padding: '10px 20px',
                            width: 'fit-content',
                            background: 'rgb(225 225 228)',
                            color: '#000000',
                            borderRadius: '16px',
                            fontSize: '12px',
                            fontWeight: '600',
                            zIndex: 1,
                          }}
                        >
                          Unread Messages ({props?.unreadCount})
                        </div>
                        {/* <DateDividerBar /> */}
                      </div>
                    )}

                  <Message
                    key={conversation_id}
                    isSender={isSender}
                    image={image}
                    message_id={id}
                    bid_amount={bid_amount}
                    created_date={format(new Date(created_date), 'h:mm a')}
                    attachments={attachments}
                    project_name={project_name}
                    proposed_amount={proposed_amount}
                    message={message}
                    type={message_type}
                    member_type={MEMBER_TYPE}
                    receiver_id={receiver_id}
                    sender_id={sender_id}
                    project_id={project_id}
                    senderEmail={senderEmail}
                    handleActionButtons={props.handleActionButtons}
                    chats={props.chats}
                    project_status_id={project_status_id}
                    message_type={props.message_type}
                    handleSendMessage={props.handleSendMessage}
                    hasPleaseRevise={hasPleaseRevise}
                    receiverName={receiverName}
                    is_payment_in_progress={is_payment_in_progress}
                    getConversation={props.getConversation}
                    setLoading={props?.setLoading}
                  />
                </Fragment>
              );
            }
          )}
        <div style={{ float: 'left', clear: 'both' }} ref={bottomRef} />
      </MessagesConversation>
      <MessageForm
        onSubmit={event => {
          event.preventDefault();
          const _message = `${props.message.trim()}`;
          if (_message || props.uploaded_files.length > 0) {
            //props.setMessage('');

            props.handleSendMessage(props.chats, _message, props.message_type);
          }
        }}
      >
        {props.uploaded_files.length > 0 && msgType ? (
          <UploadedFileContainer>{getFiles()}</UploadedFileContainer>
        ) : null}
        <MessageInputDiv>
          {MenuButtons()}
          <MessageInputContainer style={{ display: 'flex' }}>
            <div
              style={{
                width: 'calc(100% - 90px)',
              }}
            >
              <Textarea
                name="message"
                // label="Type a message..."
                placeHolder="Type a message..."
                value={props.message}
                onChange={e => {
                  props.setMessage(e.target.value);
                }}
                // rightLabel={}
                rows={3}
                // value={values.background}
              />
            </div>
            <Row alignItems="end" style={{ marginLeft: '7px' }}>
              <SendBtn
                type="submit"
                disabled={
                  (props.isSending || !props.message.trim()) &&
                  !props?.uploaded_files.length === 0
                }
              >
                {props.isSending ? 'SENDING' : 'SEND'}
              </SendBtn>
            </Row>
            {/* <Input
              placeholder="Type a message..."
              value={props.message}
              onChange={e => {
                props.setMessage(e.target.value);
              }}
              rightLabel={
                <Row alignItems="center">
                  <SendBtn
                    type="submit"
                    disabled={
                      (props.isSending || !props.message.trim()) &&
                      !props?.uploaded_files.length === 0
                    }
                  >
                    {props.isSending ? 'SENDING' : 'SEND'}
                  </SendBtn>
                </Row>
              }
            /> */}
          </MessageInputContainer>
        </MessageInputDiv>
      </MessageForm>
      <Modal visible={!!isShowingModal} setVisible={setIsShowingModal}>
        <div
          style={{
            backgroundColor: 'white',
            // padding: '2rem',
            flexDirection: 'column',
            justifyContent: 'flex-end',
            width: '300px',
            borderRadius: '10px',
          }}
        >
          <div className="modalHead">
            <div className="modalTxt">
              <span>
                {isShowingModal === SEND_PROPOSAL
                  ? 'Send Proposal'
                  : 'Final Output'}
              </span>
            </div>
            <div className="modalCross">
              <img
                src={cross}
                alt="cross-icon"
                className="crossIcon"
                onClick={() => {
                  setIsShowingModal(null);
                  localStorage.setItem('project_id', 0);
                }}
              />
            </div>
          </div>
          <div className="modalContent">
            <div className="contHead">Please Fill the following Details</div>
            <div style={{ paddingTop: '8px', paddingBottom: '10px' }}>
              <Autocomplete
                id={'project_list'}
                disablePortal
                getOptionLabel={option => option.name}
                name={'project_list'}
                options={isShowingModal === SEND_PROPOSAL ? proList : inproList}
                onChange={(e, value) => {
                  setProListValue(value);
                  setModalValid(false);
                }}
                componentsProps={{
                  paper: {
                    style: {
                      maxWidth: '240px',
                    },
                  },
                }}
                value={proListValue}
                renderInput={params => (
                  <TextField
                    {...params}
                    variant="standard"
                    placeholder="Select Project Name*"
                    margin="normal"
                    fullWidth
                  />
                )}
              />
            </div>
            {isShowingModal === SEND_PROPOSAL ? (
              <>
                {' '}
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div
                    style={{
                      width: 'calc(100% - 23px)',
                    }}
                  >
                    <Input
                      name="bid_amount"
                      placeholder={'Bid Amount(USD)*'}
                      value={bitAmount}
                      // endTool={true}
                      onChange={e => {
                        const re = /^\+?[1-9]{1}[0-9]{0,3}$/;
                        if (e.target.value === '' || re.test(e.target.value)) {
                          setBitAmount(e.target.value);
                        }
                        setModalValid(false);
                      }}
                    />
                  </div>
                  <div
                    style={{
                      marginLeft: '4px',
                      paddingTop: '3px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      cursor: 'pointer',
                    }}
                    onClick={() => setDeductionPopup(true)}
                  >
                    <FaInfoCircle
                      data-tip
                      data-for="deductionTooltip"
                      color="#ff9e08"
                      size={20}
                    />
                  </div>
                </div>
                {proListValue?.endDate && (
                  <div style={{ marginTop: '10px', fontSize: '12px' }}>
                    Estimated completion date: {proListValue?.endDate}
                  </div>
                )}
              </>
            ) : (
              ''
            )}

            <div>
              {props.uploaded_files.length > 0 ? (
                <UploadedFileContainerModal>
                  {getFilesData()}
                </UploadedFileContainerModal>
              ) : (
                <>
                  <ActionButtons
                    style={{ width: '100%', cursor: 'pointer' }}
                    type="button"
                    onClick={() => {
                      if (fileSendInputRef.current) {
                        fileSendInputRef.current.click();
                      }
                    }}
                  >
                    <AddAttachment>
                      <FaUpload size={'12px'} />

                      <span
                        className="attach-file"
                        style={{ fontWeight: '500', fontSize: '12px' }}
                      >
                        {isShowingModal === SEND_PROPOSAL
                          ? 'Attach proposal'
                          : 'Attach output'}
                        <span style={{ color: 'red' }}>*</span>
                      </span>
                    </AddAttachment>
                  </ActionButtons>

                  <input
                    type="file"
                    ref={fileSendInputRef}
                    style={{ display: 'none' }}
                    accept="image/*, .pdf, .doc, .docs, .ppt, .pptx, .xls, .xlsx"
                    onChange={e => {
                      handleUpload(e);
                    }}
                    multiple
                  />
                </>
              )}
            </div>
          </div>
          <div>
            {(props.uploaded_files.length === 0 ||
              bitAmount === '' ||
              proListValue === null) &&
            modalValid ? (
              <p
                style={{
                  color: '#ef5350',
                  fontSize: '12px',
                  textAlign: 'center',
                }}
              >
                All fields are mandatory
              </p>
            ) : (
              ''
            )}
          </div>
          <div className="buttonModal">
            <Button
              simple
              onClick={() => {
                setIsShowingModal(null);
                localStorage.setItem('project_id', 0);
              }}
              style={{ margin: '5px' }}
            >
              Cancel
            </Button>
            <Button
              fill
              style={{ margin: '5px' }}
              onClick={event => {
                if (isShowingModal === SEND_PROPOSAL) {
                  if (
                    props.uploaded_files.length === 0 ||
                    bitAmount === '' ||
                    proListValue === null
                  ) {
                    setModalValid(true);
                  } else {
                    event.preventDefault();
                    const _message = `${props.message.trim()}`;
                    if (_message || props.uploaded_files.length > 0) {
                      props.sendMessageModal(
                        props.chats,
                        _message,
                        proListValue,
                        bitAmount,
                        isShowingModal,
                        true
                      );
                      setIsShowingModal(null);
                      props.getConversationChats();
                      localStorage.setItem('project_id', 0);
                    }
                  }
                } else {
                  if (
                    props.uploaded_files.length === 0 ||
                    proListValue === null
                  ) {
                    setModalValid(true);
                  } else {
                    event.preventDefault();
                    const _message = `${props.message.trim()}`;
                    if (_message || props.uploaded_files.length > 0) {
                      props.sendMessageModal(
                        props.chats,
                        _message,
                        proListValue,
                        bitAmount,
                        isShowingModal
                      );
                      setIsShowingModal(null);
                      localStorage.setItem('project_id', 0);
                    }
                  }
                }
              }}
            >
              Send
            </Button>
          </div>
        </div>
      </Modal>
      <Modal visible={deductionPopup} setVisible={setDeductionPopup}>
        <div className="modal_container">
          <div
            style={{
              fontWeight: '800',
              textAlign: 'center',
              fontSize: '20px',
              color: '#000000',
            }}
          >
            Pricing your Services
          </div>
          <div
            style={{
              fontWeight: '800',
              textAlign: 'center',
              fontSize: '14px',
              color: '#000000',
              marginTop: '5px',
            }}
          >
            *Pricing is at the researcher’s sole discretion
          </div>
          <div style={{ marginTop: '20px', textAlign: 'justify' }}>
            The deductions from the bidding amount paid by client will include
            Stripe fees, Borderless fees, BigRobin Application fee, & the
            currency conversion charges (if any). After all these deductions,
            the remaining amount will be transferred to the researcher. Please
            quote the amount accordingly.
          </div>
          <div>
            <div style={{ marginTop: '10px' }}>For example -</div>
            <div style={{ marginTop: '10px' }}>
              Your bidding price to client for a project = $1000
            </div>
            <div style={{ marginTop: '10px' }}>
              Deductible expenses on big Robin:
            </div>
            <div style={{ marginTop: '10px', paddingLeft: '20px' }}>
              To Accept Payments (Stripe) = $29
            </div>
            <div style={{ marginTop: '10px', paddingLeft: '20px' }}>
              To Receive Payment (Borderless) = $30
            </div>
            <div style={{ marginTop: '10px', paddingLeft: '20px' }}>
              Big Robin App (5, 10, 15%) = $50
            </div>
            <div style={{ marginTop: '10px', paddingLeft: '20px' }}>
              Currency conversion charges (if any) = 0
            </div>
            <div style={{ marginTop: '10px', paddingLeft: '20px' }}>
              Total charges = $109
            </div>
            <div style={{ marginTop: '20px' }}>
              Your net big Robin payout = $1000 - $109 = $891
            </div>
          </div>
          <div
            style={{
              marginTop: '20px',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <div style={{ width: '200px' }}>
              <Button
                fill
                onClick={() => {
                  setDeductionPopup(false);
                }}
              >
                Close
              </Button>
            </div>
          </div>
        </div>
      </Modal>
      <HandleMandatePopup visible={hasHandle} setVisible={setHasHandle} />
    </MessagesConversationContainer>
  );
};
export default Conversation;
