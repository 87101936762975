import { ajax } from 'utils/ajax';
import { format } from 'date-fns';

export const upload = async (file, dir, filename) => {
  const new_filename =
    format(new Date(), 'MMDDDyyyyHHmmssSSS') + '/' + filename;
  const res = await ajax(
    'GET',
    '/s3/signed_url/' + dir + '?object_name=' + new_filename
  );
  if (res.code === 200 && res.data && res.data['signed_url']) {
    const headers = {};
    const upload_res = await fetch(res.data['signed_url'], {
      method: 'PUT',
      body: file,
      headers,
    });
    return await {
      filename: filename,
      status: upload_res.status,
      Key: dir + '/' + new_filename,
    };
  } else {
    return await res;
  }
};
