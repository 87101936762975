import { LandingJoinFormContent } from 'pages/Landing/styled';
import styled from 'styled-components/macro';

export const HomeContainer = styled.div`
  background-color: white;

  ${LandingJoinFormContent} {
    margin-bottom: 5rem;
  }
`;
