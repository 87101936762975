import React from 'react';
import {
  FaFile,
  FaTimesCircle,
  FaFilePdf,
  FaFileExcel,
  FaFilePowerpoint,
  FaFileWord,
  FaFileImage,
} from 'react-icons/fa';
import {
  FileDetails,
  FileIcon,
  FileNameSpan,
  CloseButton,
  FileContainer,
} from './styled';

const FileUploaded = props => {
  const getFileIcon = () => {
    let fileicon = <FaFile color="#FFFFFF" />;
    if (props.type && props.type !== '') {
      switch (true) {
        case props.type.includes('image'):
          fileicon = <FaFileImage color="#FFFFFF" />;
          break;
        case props.type.includes('pdf'):
          fileicon = <FaFilePdf color="#FFFFFF" />;
          break;
        case props.type.includes('doc'):
          fileicon = <FaFileWord color="#FFFFFF" />;
          break;
        case props.type.includes('excel'):
          fileicon = <FaFileExcel color="#FFFFFF" />;
          break;
        case props.type.includes('powerpoint'):
          fileicon = <FaFilePowerpoint color="#FFFFFF" />;
          break;
        default:
          fileicon = <FaFile color="#FFFFFF" />;
      }
    } else if (props.filename) {
      const ext = props.filename
        .split('.')
        .slice(-1)
        .pop();
      if (ext) {
        switch (ext.toLowerCase()) {
          case 'jpg':
          case 'jpeg':
          case 'png':
            fileicon = <FaFileImage color="#FFFFFF" />;
            break;
          case 'pdf':
            fileicon = <FaFilePdf color="#FFFFFF" />;
            break;
          case 'doc':
          case 'docx':
            fileicon = <FaFileWord color="#FFFFFF" />;
            break;
          case 'xls':
          case 'xlsx':
            fileicon = <FaFileExcel color="#FFFFFF" />;
            break;
          case 'ppt':
          case 'pptx':
            fileicon = <FaFilePowerpoint color="#FFFFFF" />;
            break;
          default:
            fileicon = <FaFile color="#FFFFFF" />;
        }
      }
    }
    return fileicon;
  };

  return (
    <FileContainer>
      <FileIcon>{getFileIcon()}</FileIcon>
      <FileDetails>
        <CloseButton
          onClick={() => {
            props.deleteFile(props.id);
          }}
        >
          <FaTimesCircle color="#65637B" />
        </CloseButton>
        <FileNameSpan>{props.filename}</FileNameSpan>
      </FileDetails>
    </FileContainer>
  );
};
export default FileUploaded;
