export const INITIAL_PROJECT_FILTERS = {
  minPrice: null,
  maxPrice: null,
  industry: [],
  specialization: [],
  mastery: [],
};

export const INDUSTRY_EXPERTISE = [
  'Agriculture and Mining',
  'Banking, Insurance and Finance',
  'Energy & Transportation',
  'Education and Public Affairs',
  'Information Technology, Telecommunications',
  'Arts, Media and Entertainment',
  'Utilities',
  'Real Estate',
  'Healthcare/Pharma',
  'Consumer Packaged Goods',
  'Consumer Durables',
  'Consumer and Professional Services',
  'Fashion and Apparel',
  'Business Services',
  'Wholesale/Retailing',
  'Other',
];

export const SPECIALIZATION_MARKET = [
  'Future / Trends',
  'Strategy / Business Models',
  'Segmentation / Targeting',
  'Habits, Usage & Attitudes',
  'Innovation / Ideas / Concepts',
  'UX / CX / NPS Research',
  'Technical / Performance Testing',
  'New Product Pricing',
  'Virtual Launch / Test Markets',
  'Creative Testing / Brand Design',
  'Shopper / Channel Understanding',
  'Other Research',
];

export const SPECIALIZATION_ALGORITHMIC = [
  'Decision Sciences',
  'People Analytics',
  'Behavioral Economics',
  'ROI / Mix Modeling',
  'Bio-Analytics / Telemetry',
  'Security / Cyber Defense',
  'Pricing, Promotions, Revenue Growth',
  'Scenario Planning / Simulation',
  'Retail / Supply Chain Analytics',
  'CRM, Digital / Personalized Marketing',
  'Market Sizing / Measurement / Analytics',
  'Other Data Science / Advanced Analytics',
];
