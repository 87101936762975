/* eslint-disable react-hooks/rules-of-hooks */
import Button from 'components/Button';
import Dropdown from 'components/Dropdown';
import SpecializationDropdown from 'components/SpecializationDropdown';
import FileInput from 'components/FileInput';
import Input, { LabelLayout } from 'components/Input';
// import ChipsInput from 'components/ChipsInput';
import Textarea from 'components/Textarea';
import Steps from 'components/Steps';
import Modal from 'components/Modal';
import { Row, Column } from 'components/Layout';
import { useAuthDispatch, useAuthState } from 'context/AuthContext';
import { useFormik } from 'formik';
import React, {
  useEffect,
  useMemo,
  useState,
  useCallback,
  useRef,
} from 'react';
import TextField from '@mui/material/TextField';
import {
  FaSpinner,
  FaExclamationCircle,
  FaPlus,
  FaTimes,
  FaPencilAlt,
  FaInfoCircle,
  FaExternalLinkAlt,
} from 'react-icons/fa';
import { ajax } from 'utils/ajax';
import { useHistory } from 'react-router-dom';
// import LinkedInPageResearcher from '../../components/LinkedInPageResearcher';
import Autocomplete from '@mui/material/Autocomplete';
import {
  HeaderLayout,
  Instructions,
  ProfileContent,
  RPPageSectionTitle,
  RPPageOneContainer,
  UserProfileGrid,
  ServicesProfileGrid,
  RPPageTwoContainer,
  RPPageTwoSection,
  RPPageTwoSectionHRow,
  RPPageTwoSectionF500Row,
  RPPageTwoSectionRow,
  RPPageTwoSectionRowLabel,
  RPPageTwoSectionRowOptions,
  // RPPageTwoSectionRHERow,
  RPCheckbox,
  RCCheckbox,
  F500Button,
  RHECheckbox,
  RPPageThreeContainer,
  RPPageThreeSection,
  RPPageThreeSectionCol,
  RPPageThreeSectionRow,
  PageButtonsLayout,
  PageButtonsGroup,
  LeftButton,
  RightButton,
  UserProfileImageBox,
  UserProfileImageContainer,
  UserProfileImage,
  DefaultProfilePicture,
  IconContainer,
  SPReactTooltip,
} from './styled';
import {
  PROFICIENCY,
  practice_maturity_choices,
  relevant_higher_education_items,
  F500_SIDES,
  F500_SIDE_LABELS,
  ReferDrop,
  ReferDrop_LABELS,
  SIZES_OF_BUSINESSES_WORKED_VALUES,
  SIZES_OF_BUSINESSES_WORKED_LABELS,
} from './rpf_constants';
import { COUNTRIES } from './country_list';
//import AWS from 'aws-sdk';
import { format } from 'date-fns';
import LoadingIndicator from 'components/LoadingIndicator';
import SessionModal from 'components/SessionModal';
// import { number } from 'yup';
import {
  INDUSTRY_VALUES,
  INDUSTRY_LABELS,
  errorMessage,
} from '../../constants';
import DropdownWithName from 'components/DropdownWithName';
import './style.css';
import Checkbox from 'components/Checkbox';
import ProfileCrop from 'components/ProfileCrop';
import SubmitConfirmPopup from 'components/SubmitConfirmPopup';
import ForgotHandlePopup from 'components/ForgotHandlePopup';
import { MdWarning } from 'react-icons/md';
const getOptionValues = options => options.map(({ id }) => id);

const getOptionLabels = options =>
  options.reduce((options, { name, id }) => ({ ...options, [id]: name }), {});

const getCountryOptionValues = () => COUNTRIES.map(({ name }) => name);

const getCountryOptionLabels = () =>
  COUNTRIES.reduce((options, { name }) => ({ ...options, [name]: name }), {});
localStorage.setItem('agreeNda', 'false');

export const ResearcherProfileForm = props => {
  const { user, ip_address, is_profile_completed } = useAuthState();
  const [submitPopup, setSubmitPopup] = useState(false);
  const [forgotHandlePopup, setForgotHandlePopup] = useState(false);

  const history = useHistory();

  const [isShowingModalProfile, setIsShowingModalProfile] = useState(
    !is_profile_completed && history?.location?.state?.isPopup
  );

  const handleForgotPopup = () => {
    window.open(
      process.env.REACT_APP_ENV === 'local' ||
        process.env.REACT_APP_ENV === 'dev' ||
        process.env.REACT_APP_ENV === 'stg'
        ? 'https://sandbox.secure.getborderless.com/login' //sandbox
        : 'https://secure.getborderless.com/login', //live
      '_blank'
    );
    setForgotHandlePopup(false);
  };

  // const history = useHistory();
  const authDispatch = useAuthDispatch();
  const [options, setOptions] = useState({});
  const [isShowingModal, setIsShowingModal] = useState(null);
  const [isErrorShowingModal, setIsErrorShowingModal] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isPreview, setIsPreview] = useState(false);
  const [isStripe, setIsStripe] = useState(false);
  const [isNext, setIsNext] = useState(false);
  const [specialist, setSpecialist] = useState(0);
  const [ndaTemplate, setndaTemplate] = useState(false);
  const [verifyIdentity, setverifyIdentity] = useState(false);
  const [message, setMessage] = useState('');
  const firstNameRef = useRef();
  const contactRef = useRef();
  const postalRef = useRef();
  const countryRef = useRef();
  const cityRef = useRef();
  const stateRef = useRef();
  const userProfileRef = useRef();
  const titleRef = useRef();
  const addressRef = useRef();
  const industryRef = useRef();
  const serviceRef = useRef();
  const businessRef = useRef();
  const scopeRef = useRef();
  const langRef = useRef();
  const borderlessRef = useRef();
  const minEngRef = useRef();

  const headingRef = useRef();
  const headingRef1 = useRef();
  const otherMenadate = useRef();
  const profileRef = useRef();
  const codeEthics = useRef();
  const emailAddress = useRef();
  const yearExp = useRef();
  const businessSize = useRef();
  const relevantEdu = useRef();
  const workExp = useRef();
  const researchTechinal = useRef();
  const researchMLRef = useRef();
  const analyticsTechinal = useRef();
  const referredList = useRef();
  const [serviceOption, setServiceOption] = useState(false);
  const [serviceOptionObj, setServiceOptionObj] = useState(false);
  const [fortuneOption, setfortuneOption] = React.useState(null);
  const [countryId, setCountryId] = useState('');
  const [billCountryId, setBillCountryId] = useState('');
  const [hasChangeHandle, setHasChangeHandle] = useState(false);
  // const [preferdMarketId, setpreferdMarketId] = useState([]);
  // const [stepUpdate, setStepUpdate] = useState(1);
  const [otherDSSToolVal, setOtherDSSToolVal] = useState('');
  // const [otherCodelVal, setOtherCodelVal] = useState('');
  const [dataSoftwareSkills, setDataSoftwareSkills] = useState([]);
  const [dataSoftwareSkillsItems, setDataSoftwareSkillsItems] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [stripeData, setStripeData] = useState();
  const [verifyProfileHead, setverifyProfileHead] = useState(false);
  const [agreeNda, setAgreeNda] = useState(false);
  const paymentRef = useRef(); // Reference to preferred payment method
  const [image, setImage] = useState({
    preview: user.member?.avatar_link || '',
    raw: user.profile_picture.url,
    new_upload: false,
    key: user.profile_picture.url,
    filename: user.profile_picture.filename,
  });
  const [isError, setIsError] = useState(false);
  const [isErrorNext, setIsErrorNext] = useState(false);
  const [isErrorEducation, setIsErrorEducation] = useState(false);
  const [isErrorWork, setIsErrorWork] = useState(false);
  // const [error] = useState({});
  const [updateSave, setUpdateSave] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [f500fieldRows, setF500FieldRows] = useState(
    user.fortune_experience && user.fortune_experience.length > 0
      ? user.fortune_experience.length
      : 1
  );
  const [referFieldRows, setReferFieldRows] = useState(
    user.member.referrals && user.member.referrals.length > 0
      ? user.member.referrals.length
      : 1
  );
  const [isStripeError, setIsStripeError] = useState(false);
  const [isStripeMsg, setIsStripeMsg] = useState('');
  const [firstName, setfirstName] = useState(false);
  const [phoneNumber, setphoneNumber] = useState(false);

  const [paymentMethod, setPaymentMethod] = useState(true);

  useEffect(() => {
    if (countryId) {
      const data = props?.fullCountryList.filter(
        country => country.iso === countryId
      );
      if (data) {
        setPaymentMethod(data[0]?.is_on_stripe);
      }
    }
  }, [countryId]);

  const handlePaymentType = () => {
    // if (values?.country && !paymentMethod) {
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <FaExclamationCircle color="#ff9e08" />
        <span style={{ paddingLeft: '5px' }}>
          We're working on payments to offer a better experience, please try
          after some time!
          {/* Payment method supported in your country will be shown soon! */}
        </span>
      </div>
    );
    // } else {
    //   return (
    //     <Dropdown
    //       style={{
    //         gridArea: 'payment_methods',
    //         maxHeight: 82,
    //       }}
    //       id="payment_methods"
    //       label="Preferred method of payment"
    //       name="payment_methods"
    //       value={values.payment_methods}
    //       disabled={!countryId}
    //       onChange={handleChange}
    //       onBlur={handleBlur}
    //       options={['Stripe']} //originally includes Paypal and Bank (bank input field already available below)
    //       optionLabels={['Stripe']}
    //       multi
    //     />
    //   );
    // }
  };

  const handlePaymentTooltip = country => {
    if (country === 'asdf') {
      return (
        <>
          <FaInfoCircle
            style={{ paddingLeft: '0.5rem' }}
            data-tip
            data-for="pProfile"
            color="#ff9e08"
          />
          <p
            style={{
              fontSize: '2rem',
              fontWeight: 'lighter',
              fontStyle: 'italic',
              textTransform: 'initial',
            }}
          >
            <SPReactTooltip
              id="pProfile"
              effect="solid"
              backgroundColor="#393939"
              multiline={true}
              style={{
                fontSize: '1.2rem',
                color: '#fff',
              }}
            >
              <div>Please select the country to get the payment methods</div>
            </SPReactTooltip>
          </p>
        </>
      );
    }
  };

  const handleBillingAddress = () => {
    if (values?.country && !paymentMethod) {
      return <></>;
    } else if (values?.country === 'asdf') {
      return (
        <>
          <Input
            style={{ gridArea: 'billing_address' }}
            label="Billing Street Address"
            name="billing_address"
            value={values.billing_address}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={values.same_billing_information}
            checkboxProps={{
              label: 'Same as Street Address',
              name: 'same_billing_information',
              value: values.same_billing_information,
              onChange: handleChange,
              checked: values?.same_billing_information,
            }}
          />
          <Input
            style={{ gridArea: 'billing_address_2' }}
            label="Billing Street Address Line 2 (optional)"
            name="billing_address_2"
            value={values.billing_address_2}
            onChange={handleChange}
            disabled={values.same_billing_information}
            onBlur={handleBlur}
          />
          <Input
            style={{ gridArea: 'billing_city' }}
            label="City"
            name="billing_city"
            value={values.billing_city}
            onChange={handleChange}
            disabled={values.same_billing_information}
            onBlur={handleBlur}
          />
          <Input
            style={{ gridArea: 'billing_state' }}
            label="State"
            name="billing_state"
            value={values.billing_state}
            onChange={handleChange}
            disabled={values.same_billing_information}
            onBlur={handleBlur}
          />
          <div style={{ gridArea: 'billing_country' }}>
            <LabelLayout>
              <label>Country</label>
            </LabelLayout>
            <Autocomplete
              id={'billing_country'}
              freeSolo
              autoSelect
              // disableClearable
              getOptionLabel={option => option.title || option}
              name="billing_country"
              options={props?.countryList}
              onChange={(e, newValue) => {
                setFieldValue('billing_country', newValue ? newValue : '');
                if (newValue) {
                  let id = newValue?.split(' (');
                  const filterISO = props?.fullCountryList?.filter(
                    item => item.name === id[0]
                  );
                  setBillCountryId(filterISO[0].iso);
                } else {
                  setBillCountryId('');
                }
              }}
              value={values.billing_country}
              disabled={values.same_billing_information}
              renderInput={params => (
                <TextField
                  {...params}
                  value={values.billing_country}
                  variant="standard"
                  placeholder="Country"
                  margin="normal"
                  fullWidth
                />
              )}
            />
          </div>

          <Input
            style={{ gridArea: 'billing_postal_code' }}
            label="Zip/Postal Code"
            name="billing_postal_code"
            value={values.billing_postal_code}
            onChange={handleChange}
            disabled={values.same_billing_information}
            onBlur={handleBlur}
          />
        </>
      );
    }
  };

  useEffect(() => {
    if (history.location.hash === '#BankDetails') {
      let timer1 = setTimeout(() => scrollToPaymentDetails(), 1000);

      // this will clear Timeout
      // when component unmount like in willComponentUnmount
      // and show will not change to true
      return () => {
        clearTimeout(timer1);
      };
    }
  }, []);

  //sets if market or analytics specialist
  useEffect(() => {
    var isMarket = false;
    var isAnalytics = false;
    for (let i in user.services_offered) {
      if (user.services_offered[i].name.includes('INSIGHTS/MARKET RESEARCH'))
        isMarket = true;
      if (user.services_offered[i].name.includes('DATA SCIENCE/ML/AI'))
        isAnalytics = true;
    }
    if (isMarket && isAnalytics) setSpecialist(3);
    else if (isMarket) setSpecialist(1);
    else if (isAnalytics) setSpecialist(2);
  }, [user]);

  useEffect(() => {
    let countryValue = props?.fullCountryList?.filter(
      item => item?.iso === user.member.country
    )[0];
    let billcountryValue = props?.fullCountryList?.filter(
      item => item?.iso === user.member.billing_country
    )[0];
    setFieldValue(
      'country',
      countryValue
        ? countryValue?.nicename + ' (' + countryValue?.iso + ')'
        : ''
    );
    setFieldValue(
      'billing_country',
      billcountryValue
        ? billcountryValue?.name + ' (' + billcountryValue?.iso + ')'
        : ''
    );
    setCountryId(countryValue?.iso);
    setBillCountryId(billcountryValue?.iso);
    setAgreeNda(
      user?.member?.is_nda_approve ? user?.member?.is_nda_approve : false
    );
  }, [props?.fullCountryList, user, currentStep]);

  useEffect(() => {
    setIsLoading(true);
    ajax('GET', '/member_reference_dropdown').then(res => {
      if (res.code === 200) {
        setOptions(res.data);
        setServiceOption(
          res?.data?.services_offered?.length > 0
            ? res?.data?.services_offered.map(a => a.name)
            : []
        );
        setServiceOptionObj(
          res?.data?.services_offered?.length > 0
            ? res?.data?.services_offered
            : []
        );
        getMemberCompetency();
      } else if (res.code === 401) {
        setIsLoading(false);
        setIsOpen(true);
      }
    });
    const getStripeData = async () => {
      setIsLoading(true);
      const res = await ajax('GET', `/payment/account/${user.member.id}`);
      if (res.code === 200) {
        setIsLoading(false);
        setStripeData(res.data);
      } else {
        setIsLoading(false);
        setStripeData(null);
      }
    };

    getStripeData();
  }, [user]);

  useEffect(() => {
    if (currentStep === 0 && Object.keys(options)?.length > 0) {
      let list = user?.services_offered
        ? user?.services_offered.map(_ => _.name)
        : [];
      return getSpecializationOptions(list);
    }
  }, [options]);

  useEffect(() => {
    if (
      user.profile_picture &&
      user.profile_picture.length > 0 &&
      user.profile_picture[0]['url']
    ) {
      setImage({
        preview: user.profile_picture[0]['url'],
        raw: '',
        key: user.profile_picture[0]['key'],
        filename: user.profile_picture[0]['filename'],
        new_upload: false,
      });
    }
    fortuneList();
  }, [user.profile_picture]);

  let { optionValues, optionLabels, optionMaps } = useMemo(() => {
    const optionValues = {};
    const optionLabels = {};
    const optionMaps = {};

    for (const key in options) {
      switch (key) {
        // case 'preferred_markets':
        case 'industry': {
          const sortedOpts = options[key].sort((a, b) => {
            return +a.id - +b.id;
          });
          optionValues[key] = getOptionValues(sortedOpts);
          optionLabels[key] = getOptionLabels(sortedOpts);
          break;
        }
        case 'market_research':
        case 'analytics_research':
          break;
        default: {
          optionValues[key] = getOptionValues(options[key]);
          optionLabels[key] = getOptionLabels(options[key]);
          break;
        }
      }
    }

    optionValues['countries_not_served'] = getCountryOptionValues();
    optionLabels['countries_not_served'] = getCountryOptionLabels();

    optionValues['business_specialization'] = [];
    optionLabels['business_specialization'] = {};
    optionMaps['business_specialization'] = [];

    return { optionValues, optionLabels, optionMaps };
  }, [options]);

  useEffect(() => {
    window.addEventListener('storage', () => {
      // When storage changes refetch
      setFieldValue(
        'is_nda_approve',
        localStorage.getItem('agreeNda') === 'true' ? true : false
      );
      setAgreeNda(true);
    });
  }, []);

  const profilePage = () => {
    setIsPreview(true);
    setFieldValue('is_final_submission', false);
    handleSubmit();
  };
  const saveForNext = () => {
    setIsNext(true);
    handleSubmit();
  };

  const scrollToPaymentDetails = () => {
    paymentRef.current.scrollIntoView({
      behavior: 'smooth',
    });
  };

  const fixSpecializationForm = smap => {
    console.log('smap: ', smap);
    if (smap !== undefined && smap[0]?.name === 'DATA SCIENCE/ML/AI') {
      return [
        {
          name: smap[0]?.name,
          items: [15, 17, 22, 13, 23, 14, 19, 21, 16, 20, 18, 24],
        },
      ];
    } else if (smap !== undefined && smap[1]?.name === 'DATA SCIENCE/ML/AI') {
      return [
        {
          name: 'DATA SCIENCE/ML/AI',
          items: [15, 17, 22, 13, 23, 14, 19, 21, 16, 20, 18, 24],
        },
        {
          name: 'INSIGHTS/MARKET RESEARCH',
          items: [10, 1, 4, 5, 8, 3, 11, 2, 7, 6, 9, 12],
        },
      ];
    } else {
      return smap;
    }
  };

  const fixLanguageMap = lmap => {
    return [
      1,
      2,
      4,
      20,
      9,
      3,
      14,
      15,
      12,
      8,
      5,
      10,
      16,
      17,
      11,
      18,
      6,
      7,
      19,
      13,
    ];
  };

  // const fixLanguageMarket = (mmap, label) => {
  //   return mmap;
  // };

  if (localStorage.getItem('isLinkedInResearcher') === '1') {
    localStorage.setItem('isLinkedInResearcher', '0');
    window.location.reload();
  }

  const {
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: {
      first_name: user.member.first_name
        ? user.member.first_name
        : sessionStorage.getItem('join-fullname')
        ? sessionStorage.getItem('join-fullname').split(' ')[0]
        : '',
      last_name: user.member.last_name
        ? user.member.last_name
        : sessionStorage.getItem('join-fullname')
        ? sessionStorage.getItem('join-fullname').split(' ')[1]
        : '',
      address: user.member.address || '',
      address_2: user.member.address_2 || '',
      city: user.member.city || '',
      state: user.member.state || '',
      country: '',
      is_nda_approve: user.member.is_nda_approve || false,
      postal_code: user.member.postal_code || '',
      billing_information: user.member.billing_information || '',
      billing_address: user.member.billing_address || '',
      billing_address_2: user.member.billing_address_2 || '',
      billing_city: user.member.billing_city || '',
      billing_state: user.member.billing_state || '',
      billing_country: '',
      billing_postal_code: user.member.billing_postal_code || '',
      email: user.member.email || '',
      contact_number: user.member.contact_number || '',
      linked_in_url: user.member.linked_in_url || '',
      bank_account_number: user.member.bank_account_number || null,
      borderless_handle: user.member.borderless_handle || '',
      id_image_link: [],
      nda_template_link: [],
      same_billing_information:
        (user?.member?.address &&
          user?.member?.address === user?.member?.billing_address &&
          user?.member?.address_2 === user?.member?.billing_address_2 &&
          user?.member?.city === user?.member?.billing_city &&
          user?.member?.state === user?.member?.billing_state &&
          user?.member?.country === user?.member?.billing_country &&
          user?.member?.postal_code === user?.member?.billing_postal_code) ||
        false,
      services_offered: user.services_offered
        ? user.services_offered.map(_ => _.name)
        : [],
      industry_expertise: user.industry ? user.industry.map(_ => _.id) : [],
      business_specialization: user.business_specialization
        ? user.business_specialization.map(_ => _.id)
        : [],
      scope_of_services: user.scope_of_services
        ? user.scope_of_services.map(_ => _.id)
        : [],
      minimum_engagement: user.member.minimum_engagement || '',
      preferred_languages: user.languages ? user.languages.map(_ => _.id) : [],
      preferred_markets: user?.preferred_markets || [],
      payment_methods: user.member.payment_methods || [],
      countries_not_served: user.member.countries_not_served
        ? user.member.countries_not_served.split(',')
        : [],
      research_technical_mastery:
        user.research_technical_mastery &&
        user.research_technical_mastery.length > 0
          ? user.research_technical_mastery.reduce(function(obj, item) {
              obj[item.id] = item.rating;
              return obj;
            }, {})
          : {},
      researcher_ml_operations:
        user.research_operations_mastery &&
        user.research_operations_mastery.length > 0
          ? user.research_operations_mastery.reduce(function(obj, item) {
              obj[item.id] = item.rating;
              return obj;
            }, {})
          : {},
      research_analytics_mastery:
        user.research_analytics_mastery &&
        user.research_analytics_mastery.length > 0
          ? user.research_analytics_mastery.reduce(function(obj, item) {
              obj[item.id] = item.rating;
              return obj;
            }, {})
          : {},
      code_of_ethics: user.member.code_of_ethics || '',
      insights_association: user.member.insights_association || '',
      market_association_society: user.member.market_association_society || '',
      ai_advancement: user.member.ai_advancement || '',
      ai_ethics: user.member.ai_ethics || '',
      other_ethics: user.member.other_ethics || '',
      other_associations: user.member.other_associations || '',
      data_software_skills:
        user.data_software_skills && user.data_software_skills.length > 0
          ? user.data_software_skills.reduce(function(obj, item) {
              obj[item.id] = item.rating;
              return obj;
            }, {})
          : {},
      other_data_software_skills: {},
      practice_maturity: user.member.practice_maturity || '',
      years_of_experience: user.member.years_of_experience,
      //size_of_businesses_worked: user.member.size_of_businesses_worked,
      sizes_of_businesses_worked: user.sizes_of_businesses_worked
        ? user.sizes_of_businesses_worked
        : [],
      fortune_500_experience:
        user.fortune_experience && user.fortune_experience.length > 0
          ? user.fortune_experience.map(item => {
              return {
                company: item['company_name'],
                side: item['side'],
                years_worked: item['years_worked'],
              };
            })
          : [
              {
                company: '',
                side: F500_SIDES.CLIENT,
                years_worked: 0,
              },
            ],
      referrals:
        user.member.referrals && user.member.referrals.length > 0
          ? user.member.referrals.map(item => {
              return {
                name: item['name'],
                email: item['email'],
                type: item['type'],
              };
            })
          : [
              {
                name: '',
                email: '',
                type: '',
              },
            ],
      relevant_higher_education: relevant_higher_education_items.reduce(
        function(obj, item) {
          let checked = false;
          let major = '';
          let academic_institution = '';
          if (user.relevant_higher_education) {
            if (
              user.relevant_higher_education.find(
                e => e.degree === item.fieldKey
              )
            ) {
              const filterValue = user?.relevant_higher_education?.filter(
                i => i.degree === item.fieldKey
              );
              obj[item.fieldKey] = filterValue?.map(itemValue => {
                return {
                  checked: true,
                  major_area_of_study: itemValue.major,
                  academic_institution: itemValue.academic_institution,
                };
              });
            } else {
              obj[item.fieldKey] = [
                {
                  checked,
                  major_area_of_study: major,
                  academic_institution: academic_institution,
                },
              ];
            }
          }

          // if (user.relevant_higher_education) {
          //   for (var i in user.relevant_higher_education) {
          //     const rheitem = user.relevant_higher_education[i];
          //     if (rheitem['degree'] === item.fieldKey) {
          //       checked = true;
          //       major = rheitem['major'];
          //       academic_institution = rheitem['academic_institution'];
          //       break;
          //     }
          //   }
          // }
          // obj[item.fieldKey] = {
          //   checked: checked,
          //   major_area_of_study: major,
          //   academic_institution: academic_institution,
          // };

          return obj;
        },
        {}
      ),
      big_robin_user_referee: user.member.user_referee || '',
      research_highlight_logo:
        user.research_highlight_logo && user.research_highlight_logo.length > 0
          ? user.research_highlight_logo.map(item => {
              return {
                filename: item['filename'],
                url: item['url'],
                key: item['key'],
                new_upload: false,
              };
            })
          : [],
      selling_line: user?.member?.selling_line || '',
      research_highlights:
        user.research_highlights && user.research_highlights.length > 0
          ? user.research_highlights
          : [
              {
                attachments: [],
                project: '',
                tags: '',
                synopsis: '',
                context: '',
              },
              {
                attachments: [],
                project: '',
                tags: '',
                synopsis: '',
                context: '',
              },
            ],
      nda_templates:
        user.nda_templates && user.nda_templates.length > 0
          ? user.nda_templates.map(item => {
              return {
                filename: item['filename'],
                url: item['url'],
                key: item['key'],
                new_upload: false,
              };
            })
          : [],
      proof_of_employment:
        user.proof_of_employment && user.proof_of_employment.length > 0
          ? user.proof_of_employment.map(item => {
              return {
                filename: item['filename'],
                url: item['url'],
                key: item['key'],
                new_upload: false,
              };
            })
          : [],
      is_final_submission: false,
    },
    onSubmit: async data => {
      if (currentStep === 2) {
        if ((data?.selling_line === '' || !data?.selling_line) && !updateSave) {
          headingRef.current.scrollIntoView({ behavior: 'smooth' });
          setverifyProfileHead(true);
          return false;
        } else {
          setverifyProfileHead(false);
          //declaration of variables
          let mastery_score = 0;
          let catA = 0,
            catB = 0,
            catC = 0;
          let score1 = 0,
            score2 = 0,
            score3 = 0,
            score4 = 0,
            score5 = 0,
            score6 = 0;
          let sizeBusinessScore = 0,
            fortune500Score = 0,
            educationScore = 0;
          let linkedInScore = 0,
            referrerScore = 0,
            referFriendScore = 0;

          //computation for research and technical mastery
          let rtmObj = data.research_technical_mastery;
          let rtmScore = 0,
            rtmFluent = 0,
            rtmProficient = 0,
            rtmFamiliar = 0;
          let rtmItems = Object.keys(rtmObj).map(key => {
            if (rtmObj[key] === 'Fluent') rtmFluent++;
            else if (rtmObj[key] === 'Proficient') rtmProficient++;
            else if (rtmObj[key] === 'Familiar') rtmFamiliar++;

            return {
              id: key,
              rating: rtmObj[key],
            };
          });

          //if highest is familiar (1)
          if (rtmFamiliar > 0 && rtmProficient === 0 && rtmFluent === 0)
            rtmScore = 1;
          //if highest is proficient, upto 4 proficient (2)
          else if (rtmProficient > 0 && rtmProficient <= 4 && rtmFluent === 0)
            rtmScore = 2;
          //if there are 3 answers or more that are fluent or answers with 5 or more that are proficient, score is 5
          else if (rtmFluent > 2 || rtmProficient > 4) rtmScore = 5;
          //if there are answers of 7 or more with familiar and no fluent at all, score is 3
          else if (rtmFamiliar > 6 && rtmFluent === 0) rtmScore = 3;
          //else score is 4
          else rtmScore = 4;

          //computation for researcher_ml_operations
          let rmlObj = data.researcher_ml_operations;
          let rmlScore = 0,
            rmlFluent = 0,
            rmlProficient = 0,
            rmlFamiliar = 0;

          let rmlItems = Object.keys(rmlObj).map(key => {
            if (rmlObj[key] === 'Fluent') rmlFluent++;
            else if (rmlObj[key] === 'Proficient') rmlProficient++;
            else if (rmlObj[key] === 'Familiar') rmlFamiliar++;

            return {
              id: key,
              rating: rmlObj[key],
            };
          });

          //computation for analytics technical mastery
          let ramObj = data.research_analytics_mastery;
          let ramScore = 0,
            ramFluent = 0,
            ramProficient = 0,
            ramFamiliar = 0;
          let ramItems = Object.keys(ramObj).map(key => {
            if (ramObj[key] === 'Fluent') ramFluent++;
            else if (ramObj[key] === 'Proficient') ramProficient++;
            else if (ramObj[key] === 'Familiar') ramFamiliar++;

            return {
              id: key,
              rating: ramObj[key],
            };
          });
          //if highest is familiar (1)
          if (ramFamiliar > 0 && ramProficient === 0 && ramFluent === 0)
            ramScore = 1;
          //if highest is proficient, upto 4 proficient (2)
          else if (ramProficient > 0 && ramProficient <= 4 && ramFluent === 0)
            ramScore = 2;
          //if there are 3 answers or more that are fluent or answers with 5 or more that are proficient, score is 5
          else if (ramFluent > 2 || ramProficient > 4) ramScore = 5;
          //if there are answers of 7 or more with familiar and no fluent at all, score is 3
          else if (ramFamiliar > 6 && ramFluent === 0) ramScore = 3;
          //else score is 4
          else ramScore = 4;

          //computation for data/software skills
          let dssObj = data.data_software_skills || [];
          let dssScore = 0,
            dssFluent = 0,
            dssProficient = 0,
            dssFamiliar = 0;
          let dssRefMap = (dataSoftwareSkills.software_skills || []).reduce(
            function(obj, item) {
              obj[item.id] = {
                name: item.name,
                status: item.status,
              };
              return obj;
            },
            {}
          );
          let dssItems = Object.keys(dssObj).map(key => {
            if (dssObj[key] === 'Fluent') dssFluent++;
            else if (dssObj[key] === 'Proficient') dssProficient++;
            else if (dssObj[key] === 'Familiar') dssFamiliar++;

            return {
              id: key,
              name: dssRefMap[key]['name'],
              rating: dssObj[key],
              status: dssRefMap[key]['status'],
            };
          });
          //if highest is familiar (1)
          if (dssFamiliar > 0 && dssProficient === 0 && dssFluent === 0)
            dssScore = 1;
          //if highest is proficient, upto 4 proficient (2)
          else if (dssProficient > 0 && dssProficient <= 4 && dssFluent === 0)
            dssScore = 2;
          //if there are 3 answers or more with fluent , score is 5
          else if (dssFluent > 2) dssScore = 5;
          //if there are 3 or more answers familiar and  no fluent, score is 3
          else if (dssFamiliar > 2 && dssFluent === 0) dssScore = 3;
          //else score is 4
          else dssScore = 4;

          //Get the average of the three scores. This is (Score1)
          if (specialist === 1) {
            score1 = rtmScore;
          } else if (specialist === 2) {
            score1 = ramScore > dssScore ? ramScore : dssScore;
          } else if (specialist === 3) {
            score1 = Math.max(rtmScore, ramScore, dssScore);
          }

          //computation for stakeholder management. This is (Score2)
          //Business Team Member (3)
          if (data.practice_maturity === 'Member') score2 = 3;
          //Business Unit or Functional Team Leader (4)
          else if (data.practice_maturity === 'Unit') score2 = 4;
          //C-Suite, Cross-Functional Enterprise Leadership (5)
          else if (data.practice_maturity === 'Enterprise') score2 = 5;
          //Business Owner/CEO/Industry Thought leader (5)
          else if (data.practice_maturity === 'Owner') score2 = 5;

          //computation for years of experience. This is (Score3)
          //If < 5 score is (3)
          if (data.years_of_experience < 5) score3 = 3;
          //if 5-10 score is (4)
          else if (
            data.years_of_experience > 4 &&
            data.years_of_experience < 11
          )
            score3 = 4;
          //if 10+ score is (5)
          else score3 = 5;

          //get score of Category A
          catA = (score1 + score2 + score3) / 3;

          //computation for size of business worked
          //if selected 1000+ (5)
          if (
            data.sizes_of_businesses_worked.indexOf('1000+') > -1 ||
            data.sizes_of_businesses_worked.length > 1
          )
            sizeBusinessScore = 5;
          //0-9 only (3)
          else if (
            data.sizes_of_businesses_worked.indexOf('0-9') > -1 &&
            data.sizes_of_businesses_worked.length === 1
          )
            sizeBusinessScore = 3;
          //all other (4)
          else sizeBusinessScore = 4;

          //computation for fortune 500 experience
          //If researcher inputs a Fortune 500 experience (5)
          if (data.fortune_500_experience.length > 1) fortune500Score = 5;
          else if (
            data.fortune_500_experience.length === 1 &&
            data.fortune_500_experience[0].company !== ''
          )
            fortune500Score = 5;

          let odssObj = data.other_data_software_skills || [];
          Object.keys(odssObj).forEach(key => {
            dssItems.push({
              name: key,
              rating: odssObj[key],
              status: 'New',
            });
          });

          //computation for relevant higher education
          let rheObj = data.relevant_higher_education;
          let rheItems = [];
          for (let i in rheObj) {
            if (rheObj[i][0]['checked'] === true) {
              //PHD 5
              if (i === 'phd') educationScore = 5;
              //Masters 4
              else if (i === 'masters' && educationScore < 5)
                educationScore = 4;
              //Masters 4
              else if (i === 'post_graduate_certificate' && educationScore < 5)
                educationScore = 4;
              //BS/BA 3
              else if (i === 'bachelor_of_science' && educationScore < 4)
                educationScore = 3;
              //BS/BA 3
              else if (i === 'bachelor_of_arts' && educationScore < 4)
                educationScore = 3;

              rheObj[i].forEach(item => {
                rheItems.push({
                  degree: i,
                  major: item.major_area_of_study,
                  academic_institution: item.academic_institution,
                });
              });
            }
          }
          //Choose the highest score among these. This is (Score4)
          score4 = Math.max(sizeBusinessScore, fortune500Score, educationScore);

          //get score of category B
          catB = score4;

          //If there’s a LinkedIn, score is 5 else 0
          if (data.linked_in_url !== '') linkedInScore = 5;
          //If there’s a referrer, score 5 else 0
          if (data.user_referee) referrerScore = 5;
          //referred 1 (3)
          if (
            data.referrals.length === 1 &&
            (data.referrals[0].name !== '' ||
              data.referrals[0].email !== '' ||
              data.referrals[0].type !== '')
          )
            referFriendScore = 3;
          else if (data.referrals.length > 1) {
            //referred 2 (4)
            if (
              data.referrals.length === 2 &&
              (data.referrals[1].name !== '' ||
                data.referrals[1].email !== '' ||
                data.referrals[1].type !== '')
            )
              referFriendScore = 4;
            //referred 3 (5)
            else if (
              data.referrals.length > 2 &&
              (data.referrals[2].name !== '' ||
                data.referrals[2].email !== '' ||
                data.referrals[2].type !== '')
            )
              referFriendScore = 5;
          }

          //Get average and this is (Score5)
          score5 = Math.max(linkedInScore + referrerScore + referFriendScore);

          //computation for insights association and market researcher. This is (Score6)
          let codeOfEthicsArray = [
            // data.code_of_ethics,
            data.insights_association,
            data.market_association_society,
            data.other_associations,
            data.ai_advancement,
            data.ai_ethics,
          ];
          let coeFluent = 0,
            coeProficient = 0,
            coeFamiliar = 0;
          for (let i in codeOfEthicsArray) {
            if (codeOfEthicsArray[i] === 'Fluent') coeFluent++;
            if (codeOfEthicsArray[i] === 'Proficient') coeProficient++;
            if (codeOfEthicsArray[i] === 'Familiar') coeFamiliar++;
          }
          //Highest is Fluent in 1 or more (5)
          if (coeFluent > 0) score6 = 5;
          //highest is proficient in 1 or more is score (4)
          else if (coeFluent === 0 && coeProficient > 0) score6 = 4;
          //highest is familiar in 3 or more (3)
          else if (coeFamiliar > 2) score6 = 3;
          //else 2
          else score6 = 2;

          //get score of category C
          catC = Math.max(score5, score6);

          //Get average of category A, B, C
          mastery_score = (catA + catB + catC) / 6;

          let new_upload_ids = data.proof_of_employment
            .filter(file => file.new_upload === true)
            .map(item => {
              return {
                ...item,
                attribute_key: 'proof_of_employment',
                location: 'proof_of_employment',
                attachment_type: 'Proof of Employment',
              };
            });

          let new_upload_temp = data.nda_templates
            .filter(file => file.new_upload === true)
            .map(item => {
              return {
                ...item,
                attribute_key: 'nda_templates',
                location: 'nda_templates',
                attachment_type: 'NDA Template',
              };
            });

          let new_res_logo = data.research_highlight_logo
            .filter(file => file.new_upload === true)
            .map(item => {
              return {
                ...item,
                attribute_key: 'research_highlight_logo',
                location: 'research_highlight_logo',
                attachment_type: 'Research Highlight Logo',
              };
            });

          let old_upload_ids = data.proof_of_employment
            .filter(file => file.new_upload === false)
            .map(file => {
              return {
                filename: file.filename,
                url: file.key,
                description: '',
                attachment_type: 'Proof of Employment',
              };
            });

          let old_upload_temp = data.nda_templates
            .filter(file => file.new_upload === false)
            .map(file => {
              return {
                filename: file.filename,
                url: file.key,
                description: '',
                attachment_type: 'NDA Template',
              };
            });

          let profile_pic = [];
          let avatar_link = '';
          let research_highlight_logo = data.research_highlight_logo
            .filter(file => file.new_upload === false)
            .map(file => {
              return {
                filename: file.filename,
                url: file.key,
                description: '',
                attachment_type: 'Research Highlight Logo',
              };
            });

          if (image && image.new_upload === false && image.raw === '') {
            profile_pic = [
              {
                filename: image.filename,
                url: image.key,
                description: '',
                attachment_type: 'Profile Picture',
              },
            ];
            avatar_link = image.key;
          }

          let rhObj = data.research_highlights || [];

          let rh_to_upload = {};
          for (let rh in rhObj) {
            if (
              rhObj[rh]['attachments'] &&
              rhObj[rh]['attachments'].length > 0
            ) {
              const new_attachments = rhObj[rh]['attachments']
                .filter(file => !file.new_upload)
                .map(new_file => {
                  return {
                    filename: new_file.filename,
                    url: new_file.url || new_file.key,
                    description: '',
                    attachment_type: 'Research Highlight Logo',
                  };
                });
              rh_to_upload[rh] = rhObj[rh]['attachments']
                .filter(file => file.new_upload === true)
                .map(new_file => {
                  return {
                    ...new_file,
                    attribute_key: 'attachments',
                    location: 'research_highlights',
                    attachment_type: 'Research Highlight',
                  };
                });
              rhObj[rh]['attachments'] = new_attachments;
            }
          }

          const validatedBankAccountNumber =
            data.bank_account_number === '' || data.bank_account_number === null
              ? null
              : Number(data.bank_account_number.replace(/\D+/g, ''));

          const to_upload_files = [
            new_res_logo,
            new_upload_ids,
            new_upload_temp,
          ];
          let arr = [];
          if (data?.services_offered?.length > 0) {
            serviceOptionObj.filter(function(o1) {
              return data?.services_offered.some(function(o2) {
                //  for diffrent we use NOT (!) befor obj2 here
                // id is unnique both array object
                if (o1.name === o2) {
                  return arr.push(o1.id);
                }
              });
            });
          }
          const preferredList =
            data?.preferred_markets?.length > 0
              ? data?.preferred_markets?.map(item => item?.id)
              : [];
          const body = {
            member_id: user.member.id,
            ip_address,
            service_architecture: '',
            platform: 'email',
            first_name: data.first_name,
            last_name: data.last_name,
            full_name:
              data.first_name && data.last_name
                ? data.first_name + ' ' + data.last_name
                : '',
            address: data.address,
            address_2: data.address_2,
            city: data.city,
            code_of_ethics: data.code_of_ethics,
            state: data.state,
            country: countryId ? countryId : '',
            postal_code: data.postal_code,
            billing_information: data.billing_address
              ? `${data.billing_address} ${data.billing_address_2}, ${data.billing_city}, ${data.billing_state}, ${data.billing_country}, ${data.billing_postal_code}`
              : '',
            billing_address: data.billing_address,
            billing_address_2: data.billing_address_2,
            billing_city: data.billing_city,
            billing_state: data.billing_state,
            billing_country: billCountryId ? billCountryId : '',
            billing_postal_code: data.billing_postal_code,
            borderless_handle: data?.borderless_handle,
            contact_number: data.contact_number,
            id_image_link: '',
            insights_association: data.insights_association,
            ai_advancement: data.ai_advancement ? data.ai_advancement : '',
            ai_ethics: data.ai_ethics ? data.ai_ethics : '',
            other_ethics: data.other_ethics ? data.other_ethics : '',
            market_association_society: data.market_association_society,
            other_associations: data.other_associations,
            nda_template_link: '',
            minimum_engagement: String(data.minimum_engagement) || '0',
            linked_in_url: data.linked_in_url,
            languages: data.preferred_languages.filter(
              _ => typeof _ === 'number'
            ),
            services_offered: arr || [],
            industry: data.industry_expertise.filter(
              _ => typeof _ === 'number'
            ),
            years_of_experience: data.years_of_experience,
            selling_line: data?.selling_line || '',
            //size_of_businesses_worked: data.size_of_businesses_worked,
            sizes_of_businesses_worked: data.sizes_of_businesses_worked || [],
            payment_methods: data.payment_methods,
            practice_maturity: data.practice_maturity || '',
            bank_account_number:
              validatedBankAccountNumber > 0
                ? validatedBankAccountNumber
                : null,
            countries_not_served: data.countries_not_served.toString(),
            user_referee: data.big_robin_user_referee,
            research_technical_mastery: rtmItems,
            research_operations_mastery: rmlItems,
            research_analytics_mastery: ramItems,
            data_software_skills: dssItems,
            preferred_markets: preferredList,
            business_specialization: data.business_specialization,
            research_highlights: data.research_highlights,
            relevant_higher_education: rheItems,
            scope_of_services: data.scope_of_services,
            fortune_experience: data.fortune_500_experience,
            referrals:
              data.referrals?.length === 1 &&
              data.referrals[0]?.name === '' &&
              data.referrals[0]?.email === ''
                ? []
                : data.referrals,
            profile_picture: profile_pic,
            proof_of_employment: old_upload_ids,
            nda_templates: old_upload_temp,
            research_highlight_logo: research_highlight_logo,
            avatar_link: avatar_link,
            mastery_score: Number(mastery_score.toFixed(2)),
            is_nda_approve: data.is_nda_approve,
            is_final_submission: data.is_final_submission,
            is_profile_completed: true,
          };
          setIsLoading(true);
          try {
            if (
              (image && image.new_upload === true) ||
              new_upload_ids.length > 0 ||
              new_upload_temp.length > 0 ||
              new_res_logo.length > 0 ||
              Object.keys(rh_to_upload).length > 0
            ) {
              if (image && image.new_upload === true) {
                const upload_res = await uploadFileToS3(
                  image.raw,
                  'profile',
                  image.raw['name']
                );
                if (
                  upload_res &&
                  upload_res['status'] === 200 &&
                  upload_res['Key']
                ) {
                  body['profile_picture'] = [
                    {
                      filename: image.raw['name'],
                      url: upload_res['Key'],
                      description: '',
                      attachment_type: 'Profile Picture',
                    },
                  ];
                  body['avatar_link'] = upload_res['Key'];
                  uploadingFiles(body, to_upload_files, 0, 0, rh_to_upload);
                } else {
                  uploadingFiles(body, to_upload_files, 0, 0, rh_to_upload);
                }
              } else {
                uploadingFiles(body, to_upload_files, 0, 0, rh_to_upload);
              }
            } else {
              savingProfile(body);
            }
          } catch (err) {
            console.log(err);
          } finally {
            //setIsLoading(false);
          }
        }
      } else {
        // if (!isStripe) {
        //   setCurrentStep(currentStep + 1);
        // }
        setndaTemplate(false);
        setverifyIdentity(false);
        setverifyProfileHead(false);
        //declaration of variables
        let mastery_score = 0;
        let catA = 0,
          catB = 0,
          catC = 0;
        let score1 = 0,
          score2 = 0,
          score3 = 0,
          score4 = 0,
          score5 = 0,
          score6 = 0;
        let sizeBusinessScore = 0,
          fortune500Score = 0,
          educationScore = 0;
        let linkedInScore = 0,
          referrerScore = 0,
          referFriendScore = 0;

        //computation for research and technical mastery
        let rtmObj = data.research_technical_mastery;
        let rtmScore = 0,
          rtmFluent = 0,
          rtmProficient = 0,
          rtmFamiliar = 0;
        let rtmItems = Object.keys(rtmObj).map(key => {
          if (rtmObj[key] === 'Fluent') rtmFluent++;
          else if (rtmObj[key] === 'Proficient') rtmProficient++;
          else if (rtmObj[key] === 'Familiar') rtmFamiliar++;

          return {
            id: key,
            rating: rtmObj[key],
          };
        });

        //computation for research and technical mastery
        let rmlObj = data.researcher_ml_operations;

        let rmlScore = 0,
          rmlFluent = 0,
          rmlProficient = 0,
          rmlFamiliar = 0;
        let rmlItems = Object.keys(rmlObj).map(key => {
          if (rmlObj[key] === 'Fluent') rmlFluent++;
          else if (rmlObj[key] === 'Proficient') rmlProficient++;
          else if (rmlObj[key] === 'Familiar') rmlFamiliar++;

          return {
            id: key,
            rating: rmlObj[key],
          };
        });

        //computation for analytics technical mastery
        let ramObj = data.research_analytics_mastery;
        let ramScore = 0,
          ramFluent = 0,
          ramProficient = 0,
          ramFamiliar = 0;
        let ramItems = Object.keys(ramObj).map(key => {
          if (ramObj[key] === 'Fluent') ramFluent++;
          else if (ramObj[key] === 'Proficient') ramProficient++;
          else if (ramObj[key] === 'Familiar') ramFamiliar++;

          return {
            id: key,
            rating: ramObj[key],
          };
        });
        //if highest is familiar (1)
        if (ramFamiliar > 0 && ramProficient === 0 && ramFluent === 0)
          ramScore = 1;
        //if highest is proficient, upto 4 proficient (2)
        else if (ramProficient > 0 && ramProficient <= 4 && ramFluent === 0)
          ramScore = 2;
        //if there are 3 answers or more that are fluent or answers with 5 or more that are proficient, score is 5
        else if (ramFluent > 2 || ramProficient > 4) ramScore = 5;
        //if there are answers of 7 or more with familiar and no fluent at all, score is 3
        else if (ramFamiliar > 6 && ramFluent === 0) ramScore = 3;
        //else score is 4
        else ramScore = 4;

        //computation for data/software skills
        let dssObj = data.data_software_skills || [];
        let dssScore = 0,
          dssFluent = 0,
          dssProficient = 0,
          dssFamiliar = 0;
        let dssRefMap = (dataSoftwareSkills.software_skills || []).reduce(
          function(obj, item) {
            obj[item.id] = {
              name: item.name,
              status: item.status,
            };
            return obj;
          },
          {}
        );
        let dssItems = Object.keys(dssObj).map(key => {
          if (dssObj[key] === 'Fluent') dssFluent++;
          else if (dssObj[key] === 'Proficient') dssProficient++;
          else if (dssObj[key] === 'Familiar') dssFamiliar++;

          return {
            id: key,
            name: dssRefMap[key]['name'],
            rating: dssObj[key],
            status: dssRefMap[key]['status'],
          };
        });
        //if highest is familiar (1)
        if (dssFamiliar > 0 && dssProficient === 0 && dssFluent === 0)
          dssScore = 1;
        //if highest is proficient, upto 4 proficient (2)
        else if (dssProficient > 0 && dssProficient <= 4 && dssFluent === 0)
          dssScore = 2;
        //if there are 3 answers or more with fluent , score is 5
        else if (dssFluent > 2) dssScore = 5;
        //if there are 3 or more answers familiar and  no fluent, score is 3
        else if (dssFamiliar > 2 && dssFluent === 0) dssScore = 3;
        //else score is 4
        else dssScore = 4;

        //Get the average of the three scores. This is (Score1)
        if (specialist === 1) {
          score1 = rtmScore;
        } else if (specialist === 2) {
          score1 = ramScore > dssScore ? ramScore : dssScore;
        } else if (specialist === 3) {
          score1 = Math.max(rtmScore, ramScore, dssScore);
        }

        //computation for stakeholder management. This is (Score2)
        //Business Team Member (3)
        if (data.practice_maturity === 'Member') score2 = 3;
        //Business Unit or Functional Team Leader (4)
        else if (data.practice_maturity === 'Unit') score2 = 4;
        //C-Suite, Cross-Functional Enterprise Leadership (5)
        else if (data.practice_maturity === 'Enterprise') score2 = 5;
        //Business Owner/CEO/Industry Thought leader (5)
        else if (data.practice_maturity === 'Owner') score2 = 5;

        //computation for years of experience. This is (Score3)
        //If < 5 score is (3)
        if (data.years_of_experience < 5) score3 = 3;
        //if 5-10 score is (4)
        else if (data.years_of_experience > 4 && data.years_of_experience < 11)
          score3 = 4;
        //if 10+ score is (5)
        else score3 = 5;

        //get score of Category A
        catA = (score1 + score2 + score3) / 3;

        //computation for size of business worked
        //if selected 1000+ (5)
        if (
          data.sizes_of_businesses_worked.indexOf('1000+') > -1 ||
          data.sizes_of_businesses_worked.length > 1
        )
          sizeBusinessScore = 5;
        //0-9 only (3)
        else if (
          data.sizes_of_businesses_worked.indexOf('0-9') > -1 &&
          data.sizes_of_businesses_worked.length === 1
        )
          sizeBusinessScore = 3;
        //all other (4)
        else sizeBusinessScore = 4;

        //computation for fortune 500 experience
        //If researcher inputs a Fortune 500 experience (5)
        if (data.fortune_500_experience.length > 1) fortune500Score = 5;
        else if (
          data.fortune_500_experience.length === 1 &&
          data.fortune_500_experience[0].company !== ''
        )
          fortune500Score = 5;

        let odssObj = data.other_data_software_skills || [];
        Object.keys(odssObj).forEach(key => {
          dssItems.push({
            name: key,
            rating: odssObj[key],
            status: 'New',
          });
        });

        //computation for relevant higher education
        let rheObj = data.relevant_higher_education;
        let rheItems = [];
        for (let i in rheObj) {
          if (rheObj[i][0]['checked'] === true) {
            //PHD 5
            if (i === 'phd') educationScore = 5;
            //Masters 4
            else if (i === 'masters' && educationScore < 5) educationScore = 4;
            //Masters 4
            else if (i === 'post_graduate_certificate' && educationScore < 5)
              educationScore = 4;
            //BS/BA 3
            else if (i === 'bachelor_of_science' && educationScore < 4)
              educationScore = 3;
            //BS/BA 3
            else if (i === 'bachelor_of_arts' && educationScore < 4)
              educationScore = 3;

            rheObj[i].forEach(item => {
              rheItems.push({
                degree: i,
                major: item.major_area_of_study,
                academic_institution: item.academic_institution,
              });
            });
          }
        }
        //Choose the highest score among these. This is (Score4)
        score4 = Math.max(sizeBusinessScore, fortune500Score, educationScore);

        //get score of category B
        catB = score4;

        //If there’s a LinkedIn, score is 5 else 0
        if (data.linked_in_url !== '') linkedInScore = 5;
        //If there’s a referrer, score 5 else 0
        if (data.user_referee) referrerScore = 5;
        //referred 1 (3)
        if (
          data.referrals.length === 1 &&
          (data.referrals[0].name !== '' ||
            data.referrals[0].email !== '' ||
            data.referrals[0].type !== '')
        )
          referFriendScore = 3;
        else if (data.referrals.length > 1) {
          //referred 2 (4)
          if (
            data.referrals.length === 2 &&
            (data.referrals[1].name !== '' ||
              data.referrals[1].email !== '' ||
              data.referrals[1].type !== '')
          )
            referFriendScore = 4;
          //referred 3 (5)
          else if (
            data.referrals.length > 2 &&
            (data.referrals[2].name !== '' ||
              data.referrals[2].email !== '' ||
              data.referrals[2].type !== '')
          )
            referFriendScore = 5;
        }

        //Get average and this is (Score5)
        score5 = Math.max(linkedInScore + referrerScore + referFriendScore);

        //computation for insights association and market researcher. This is (Score6)
        let codeOfEthicsArray = [
          data.code_of_ethics,
          data.insights_association,
          data.market_association_society,
          data.other_associations,
          data.ai_advancement,
          data.ai_ethics,
        ];
        let coeFluent = 0,
          coeProficient = 0,
          coeFamiliar = 0;
        for (let i in codeOfEthicsArray) {
          if (codeOfEthicsArray[i] === 'Fluent') coeFluent++;
          if (codeOfEthicsArray[i] === 'Proficient') coeProficient++;
          if (codeOfEthicsArray[i] === 'Familiar') coeFamiliar++;
        }
        //Highest is Fluent in 1 or more (5)
        if (coeFluent > 0) score6 = 5;
        //highest is proficient in 1 or more is score (4)
        else if (coeFluent === 0 && coeProficient > 0) score6 = 4;
        //highest is familiar in 3 or more (3)
        else if (coeFamiliar > 2) score6 = 3;
        //else 2
        else score6 = 2;

        //get score of category C
        catC = Math.max(score5, score6);

        //Get average of category A, B, C
        mastery_score = (catA + catB + catC) / 6;

        let new_upload_ids = data.proof_of_employment
          .filter(file => file.new_upload === true)
          .map(item => {
            return {
              ...item,
              attribute_key: 'proof_of_employment',
              location: 'proof_of_employment',
              attachment_type: 'Proof of Employment',
            };
          });

        let new_upload_temp = data.nda_templates
          .filter(file => file.new_upload === true)
          .map(item => {
            return {
              ...item,
              attribute_key: 'nda_templates',
              location: 'nda_templates',
              attachment_type: 'NDA Template',
            };
          });

        let new_res_logo = data.research_highlight_logo
          .filter(file => file.new_upload === true)
          .map(item => {
            return {
              ...item,
              attribute_key: 'research_highlight_logo',
              location: 'research_highlight_logo',
              attachment_type: 'Research Highlight Logo',
            };
          });

        let old_upload_ids = data.proof_of_employment
          .filter(file => file.new_upload === false)
          .map(file => {
            return {
              filename: file.filename,
              url: file.key,
              description: '',
              attachment_type: 'Proof of Employment',
            };
          });

        let old_upload_temp = data.nda_templates
          .filter(file => file.new_upload === false)
          .map(file => {
            return {
              filename: file.filename,
              url: file.key,
              description: '',
              attachment_type: 'NDA Template',
            };
          });

        let profile_pic = [];
        let avatar_link = '';
        let research_highlight_logo = data.research_highlight_logo
          .filter(file => file.new_upload === false)
          .map(file => {
            return {
              filename: file.filename,
              url: file.key,
              description: '',
              attachment_type: 'Research Highlight Logo',
            };
          });

        if (image && image.new_upload === false && image.raw === '') {
          profile_pic = [
            {
              filename: image.filename,
              url: image.key,
              description: '',
              attachment_type: 'Profile Picture',
            },
          ];
          avatar_link = image.key;
        }

        let rhObj = data.research_highlights || [];

        let rh_to_upload = {};
        for (let rh in rhObj) {
          if (rhObj[rh]['attachments'] && rhObj[rh]['attachments'].length > 0) {
            const new_attachments = rhObj[rh]['attachments']
              .filter(file => !file.new_upload)
              .map(new_file => {
                return {
                  filename: new_file.filename,
                  url: new_file.url || new_file.key,
                  description: '',
                  attachment_type: 'Research Highlight Logo',
                };
              });
            rh_to_upload[rh] = rhObj[rh]['attachments']
              .filter(file => file.new_upload === true)
              .map(new_file => {
                return {
                  ...new_file,
                  attribute_key: 'attachments',
                  location: 'research_highlights',
                  attachment_type: 'Research Highlight',
                };
              });
            rhObj[rh]['attachments'] = new_attachments;
          }
        }

        const validatedBankAccountNumber =
          data.bank_account_number === '' || data.bank_account_number === null
            ? null
            : Number(data.bank_account_number.replace(/\D+/g, ''));

        const to_upload_files = [new_res_logo, new_upload_ids, new_upload_temp];
        let arr = [];
        if (data?.services_offered?.length > 0) {
          serviceOptionObj.filter(function(o1) {
            return data?.services_offered.some(function(o2) {
              //  for diffrent we use NOT (!) befor obj2 here
              // id is unnique both array object
              if (o1.name === o2) {
                return arr.push(o1.id);
              }
            });
          });
        }
        const preferredList =
          data?.preferred_markets?.length > 0
            ? data?.preferred_markets?.map(item => item?.id)
            : [];
        const body = {
          member_id: user.member.id,
          ip_address,
          service_architecture: '',
          platform: 'email',
          first_name: data.first_name,
          last_name: data.last_name,
          full_name:
            data.first_name && data.last_name
              ? data.first_name + ' ' + data.last_name
              : '',
          address: data.address,
          address_2: data.address_2,
          city: data.city,
          code_of_ethics: data.code_of_ethics,
          state: data.state,
          country: countryId ? countryId : '',
          postal_code: data.postal_code,
          borderless_handle: data.borderless_handle,
          billing_information: data.billing_address
            ? `${data.billing_address} ${data.billing_address_2}, ${data.billing_city}, ${data.billing_state}, ${data.billing_country}, ${data.billing_postal_code}`
            : '',
          billing_address: data.billing_address,
          billing_address_2: data.billing_address_2,
          billing_city: data.billing_city,
          billing_state: data.billing_state,
          billing_country: billCountryId ? billCountryId : '',
          billing_postal_code: data.billing_postal_code,
          contact_number: data.contact_number,
          id_image_link: '',
          insights_association: data.insights_association,
          market_association_society: data.market_association_society,
          ai_advancement: data.ai_advancement ? data.ai_advancement : '',
          ai_ethics: data.ai_ethics ? data.ai_ethics : '',
          other_ethics: data.other_ethics ? data.other_ethics : '',
          other_associations: data.other_associations,
          nda_template_link: '',
          minimum_engagement: String(data.minimum_engagement) || '0',
          linked_in_url: data.linked_in_url,
          languages: data.preferred_languages.filter(
            _ => typeof _ === 'number'
          ),
          services_offered: arr || [],
          industry: data.industry_expertise.filter(_ => typeof _ === 'number'),
          years_of_experience: data.years_of_experience,
          selling_line: data?.selling_line || '',
          //size_of_businesses_worked: data.size_of_businesses_worked,
          sizes_of_businesses_worked: data.sizes_of_businesses_worked || [],
          payment_methods: data.payment_methods,
          practice_maturity: data.practice_maturity || '',
          bank_account_number:
            validatedBankAccountNumber > 0 ? validatedBankAccountNumber : null,
          countries_not_served: data.countries_not_served.toString(),
          user_referee: data.big_robin_user_referee,
          research_technical_mastery: rtmItems,
          research_operations_mastery: rmlItems,
          research_analytics_mastery: ramItems,
          data_software_skills: dssItems,
          preferred_markets: preferredList,
          business_specialization: data.business_specialization,
          research_highlights: data.research_highlights,
          relevant_higher_education: rheItems,
          scope_of_services: data.scope_of_services,
          fortune_experience: data.fortune_500_experience,
          referrals:
            data.referrals?.length === 1 &&
            data.referrals[0]?.name === '' &&
            data.referrals[0]?.email === ''
              ? []
              : data.referrals,
          profile_picture: profile_pic,
          proof_of_employment: old_upload_ids,
          nda_templates: old_upload_temp,
          research_highlight_logo: research_highlight_logo,
          avatar_link: avatar_link,
          mastery_score: Number(mastery_score.toFixed(2)),
          is_nda_approve: data.is_nda_approve,
          is_final_submission: data.is_final_submission,
        };
        setIsLoading(true);
        try {
          if (
            (image && image.new_upload === true) ||
            new_upload_ids.length > 0 ||
            new_upload_temp.length > 0 ||
            new_res_logo.length > 0 ||
            Object.keys(rh_to_upload).length > 0
          ) {
            if (image && image.new_upload === true) {
              const upload_res = await uploadFileToS3(
                image.raw,
                'profile',
                image.raw['name']
              );
              if (
                upload_res &&
                upload_res['status'] === 200 &&
                upload_res['Key']
              ) {
                body['profile_picture'] = [
                  {
                    filename: image.raw['name'],
                    url: upload_res['Key'],
                    description: '',
                    attachment_type: 'Profile Picture',
                  },
                ];
                body['avatar_link'] = upload_res['Key'];
                uploadingFiles(body, to_upload_files, 0, 0, rh_to_upload);
              } else {
                uploadingFiles(body, to_upload_files, 0, 0, rh_to_upload);
              }
            } else {
              uploadingFiles(body, to_upload_files, 0, 0, rh_to_upload);
            }
          } else {
            savingProfile(body);
          }
        } catch (err) {
          console.log(err);
        } finally {
          //setIsLoading(false);
        }
      }
    },
  });

  const getSpecializationOptions = useCallback(
    services_offered => {
      const servicesLabels = getOptionLabels(options['services_offered']);
      optionValues['business_specialization'] = [];
      optionLabels['business_specialization'] = [];
      optionMaps['business_specialization'] = [];
      let business_spec_options = [];
      let analyticsMaps = [],
        marketsMaps = [],
        operationsMaps = [];
      if (services_offered.includes('DATA SCIENCE/ML/AI')) {
        business_spec_options = [
          ...business_spec_options,
          ...options['analytics_research'],
        ];
        analyticsMaps = [
          {
            name: servicesLabels['2'],
            items: options['analytics_research'].map(item => item.id),
          },
        ];
      }

      if (services_offered.includes('INSIGHTS/MARKET RESEARCH')) {
        business_spec_options = [
          ...business_spec_options,
          ...options['market_research'],
        ];
        marketsMaps = [
          {
            name: servicesLabels['1'],
            items: options['market_research'].map(item => item.id),
          },
        ];
      }

      if (services_offered.includes('RESEARCH/ML OPERATIONS')) {
        business_spec_options = [
          ...business_spec_options,
          ...options['researcher_ml_operations'],
        ];
        operationsMaps = [
          {
            name: servicesLabels['11'],
            items: options['researcher_ml_operations'].map(item => item.id),
          },
        ];
      }

      optionValues['business_specialization'] = getOptionValues(
        business_spec_options
      );
      optionLabels['business_specialization'] = getOptionLabels(
        business_spec_options
      );

      optionMaps['business_specialization'] = [
        ...analyticsMaps,
        ...marketsMaps,
        ...operationsMaps,
      ];
      console.log(optionLabels, optionMaps, optionValues);
    },
    [
      optionLabels,
      optionMaps,
      optionValues,
      options,
      setFieldValue,
      values.business_specialization,
    ]
  );
  const isRegexExactMatch = (value, regexp) => {
    const res = value.match(regexp);
    return res && res[0] && res[0] === res.input;
  };

  const validateLinkedin = e => {
    setverifyIdentity(false);
    setMessage('');
    setFieldValue('linked_in_url', e.target.value.trim());
    const linkedInProfileURLRegExp =
      '(https?:\\/\\/(www.)?linkedin.com\\/(mwlite\\/|m\\/)?in\\/[a-zA-Z0-9_.-]+\\/?)';
    const isValid = !!isRegexExactMatch(
      e.target.value,
      linkedInProfileURLRegExp
    );
    if (!isValid) {
      setverifyIdentity(true);
      return setMessage('Enter a valid linkedin URL');
    }
  };

  const uploadingFiles = (body, to_upload, ind, ind2, res_highlight_upload) => {
    (async () => {
      let loadFlag = false;
      if (to_upload[ind] && to_upload[ind][ind2]) {
        const upload_res = await uploadFileToS3(
          to_upload[ind][ind2]['url'],
          to_upload[ind][ind2]['location'],
          to_upload[ind][ind2]['filename']
        );

        if (upload_res && upload_res['status'] === 200 && upload_res['Key']) {
          body[to_upload[ind][ind2]['attribute_key']].push({
            filename: to_upload[ind][ind2]['filename'],
            url: upload_res['Key'],
            description: '',
            attachment_type: to_upload[ind][ind2]['attachment_type'],
          });
        } else if (upload_res && upload_res.code === 401) {
          loadFlag = true;
          setIsLoading(false);
          setIsOpen(true);
        }
      }
      const len = to_upload.length - 1;
      if (loadFlag === false && ind < len) {
        const len2 = to_upload[ind].length - 1;
        if (ind2 < len2) {
          const ind2_temp = ind2 + 1;
          uploadingFiles(body, to_upload, ind, ind2_temp, res_highlight_upload);
        } else {
          const ind_temp = ind + 1;
          uploadingFiles(body, to_upload, ind_temp, 0, res_highlight_upload);
        }
      } else if (loadFlag === false) {
        if (Object.keys(res_highlight_upload).length > 0) {
          uploadResearchHighlightAttachments(body, res_highlight_upload, 0, 0);
        } else {
          savingProfile(body);
        }
      }
    })();
  };

  const uploadResearchHighlightAttachments = (body, to_upload, ind, ind2) => {
    (async () => {
      let loadFlag = false;
      if (
        Object.keys(to_upload)[ind] &&
        to_upload[Object.keys(to_upload)[ind]][ind2]
      ) {
        const key = Object.keys(to_upload)[ind];
        const upload_res = await uploadFileToS3(
          to_upload[key][ind2]['url'],
          to_upload[key][ind2]['location'],
          to_upload[key][ind2]['filename']
        );
        if (upload_res && upload_res['status'] === 200 && upload_res['Key']) {
          body['research_highlights'][Object.keys(to_upload)[key]][
            'attachments'
          ].push({
            filename: to_upload[key][ind2]['filename'],
            url: upload_res['Key'],
            description: '',
            attachment_type: to_upload[key][ind2]['attachment_type'],
          });
        } else if (upload_res.code === 401) {
          loadFlag = true;
          setIsLoading(false);
          setIsOpen(true);
        }
      }
      const len = Object.keys(to_upload).length - 1;
      if (loadFlag === false && ind < len) {
        const len2 = Object.keys(to_upload)[ind].length - 1;
        if (ind2 < len2) {
          const ind2_temp = ind2 + 1;
          uploadResearchHighlightAttachments(body, to_upload, ind, ind2_temp);
        } else {
          const ind_temp = ind + 1;
          uploadResearchHighlightAttachments(body, to_upload, ind_temp, 0);
        }
      } else if (loadFlag === false) {
        savingProfile(body);
      }
    })();
  };

  const savingProfile = body => {
    (async () => {
      const res = await ajax('PUT', '/register', body);

      if (res.code === 200) {
        setSubmitPopup(false);
        const getRes = await ajax('GET', `/member/${user.member.id}`);

        setIsLoading(false);
        if (getRes.code === 200) {
          if (values?.is_final_submission) {
            history.push(`/researcher/${user.member.id}`);
          }

          authDispatch({ type: 'SET_USER', payload: getRes.data });
          if (!isStripe && currentStep < 2) {
            setCurrentStep(currentStep + 1);
          }
          if (isPreview) {
            history.push(`/researcher/${user.member.id}`);
            setIsPreview(false);
          } else if (isStripe) {
            getPaymentMethod();
            setIsStripe(false);
          } else if (isNext) {
            setIsNext(false);
          } else {
            setIsShowingModal(res.message);
            setIsPreview(false);
            setIsStripe(false);
          }
        } else if (getRes.code === 401) {
          setIsLoading(false);
          setIsOpen(true);
          setIsShowingModal(res.message);
        }
      } else if (res.code === 401) {
        setIsLoading(false);
        setIsOpen(true);
        setIsShowingModal(res.message);
      } else {
        setIsLoading(false);
        setIsShowingModal(res.message);
      }
    })();
  };

  const handleFileChange = (key, files) => {
    setFieldValue(key, files);
  };

  const getMemberCompetency = () => {
    const user_id = localStorage.getItem('br-userId');
    ajax('GET', '/member_competency_experience/' + user_id).then(res => {
      setIsLoading(false);
      if (res.code === 200) {
        setDataSoftwareSkills(res.data);
        setDataSoftwareSkillsItems(
          res.data.software_skills.map(item => item.name.trim())
        );
      } else if (res.code === 401) {
        setIsOpen(true);
      }
    });
  };

  const [profileImageToCrop, setProfileImageToCrop] = useState('');
  const [isProfileCropModal, setIsProfileCropModal] = useState(false);
  const [handleErrorMessage, setHandleErrorMessage] = useState('');
  const [handleEmail, setHandleEmail] = useState('');
  const [isHandleErrorMessage, setIsHandleErrorMessage] = useState(false);

  useEffect(() => {
    if (profileImageToCrop) {
      setIsProfileCropModal(true);
    }
  }, [profileImageToCrop]);

  const validateHandle = async val => {
    const res = await ajax('POST', '/validate-handle', {
      handle: val,
    });
    // .then(res => {
    if (res.code === 200) {
      setIsHandleErrorMessage(false);
      setHandleErrorMessage('');
      setHandleEmail(res?.data?.registered_email);
      saveForNext();
    } else {
      setHandleErrorMessage(res.message);
      setIsHandleErrorMessage(true);
      setHandleEmail('');
      return false;
    }
    // });
  };

  const handleProfileChange = e => {
    if (e.target.files.length) {
      const reader = new FileReader();
      reader.onloadend = function() {
        setProfileImageToCrop(reader.result);
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const generateProficiencyTableFields = (arr, tableKey, tableCode) => {
    return arr
      .sort((a, b) => a.id - b.id)
      .map((item, index) => {
        return (
          <RPPageTwoSectionRow key={tableCode + '-' + index}>
            <RPPageTwoSectionRowLabel style={{ gridArea: 'row-label' }}>
              {item.name}
            </RPPageTwoSectionRowLabel>
            <RPPageTwoSectionRowOptions>
              <RPCheckbox
                style={{ gridArea: 'NOT_APPLICABLE' }}
                type="radio"
                value={PROFICIENCY.NOT_APPLICABLE}
                label=""
                name={tableKey + '[' + item.id + ']'}
                checked={
                  values[tableKey][item.id] === PROFICIENCY.NOT_APPLICABLE
                }
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <p>Not Applicable</p>
            </RPPageTwoSectionRowOptions>
            <RPPageTwoSectionRowOptions>
              <RPCheckbox
                style={{ gridArea: PROFICIENCY.FAMILIAR }}
                type="radio"
                value={PROFICIENCY.FAMILIAR}
                label=""
                name={tableKey + '[' + item.id + ']'}
                checked={values[tableKey][item.id] === PROFICIENCY.FAMILIAR}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <p>Familiar</p>
            </RPPageTwoSectionRowOptions>
            <RPPageTwoSectionRowOptions>
              <RPCheckbox
                style={{ gridArea: PROFICIENCY.PROFICIENT }}
                type="radio"
                value={PROFICIENCY.PROFICIENT}
                label=""
                name={tableKey + '[' + item.id + ']'}
                checked={values[tableKey][item.id] === PROFICIENCY.PROFICIENT}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <p>Proficient</p>
            </RPPageTwoSectionRowOptions>
            <RPPageTwoSectionRowOptions>
              <RPCheckbox
                style={{ gridArea: PROFICIENCY.FLUENT }}
                type="radio"
                value={PROFICIENCY.FLUENT}
                label=""
                name={tableKey + '[' + item.id + ']'}
                checked={values[tableKey][item.id] === PROFICIENCY.FLUENT}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <p>Fluent</p>
            </RPPageTwoSectionRowOptions>
          </RPPageTwoSectionRow>
        );
      });
  };

  const generateDSSTableFields = (arr, tableKey, tableCode) => {
    return (
      <>
        {arr.map((item, index) => {
          return (
            <RPPageTwoSectionRow key={tableCode + '-' + index}>
              <RPPageTwoSectionRowLabel style={{ gridArea: 'row-label' }}>
                {item.name}
              </RPPageTwoSectionRowLabel>
              <RPPageTwoSectionRowOptions>
                <RPCheckbox
                  style={{ gridArea: PROFICIENCY.NOT_APPLICABLE }}
                  type="radio"
                  value={PROFICIENCY.NOT_APPLICABLE}
                  label=""
                  name={tableKey + '[' + item.id + ']'}
                  checked={
                    values[tableKey][item.id] === PROFICIENCY.NOT_APPLICABLE
                  }
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <p>Not Applicable</p>
              </RPPageTwoSectionRowOptions>
              <RPPageTwoSectionRowOptions>
                <RPCheckbox
                  style={{ gridArea: PROFICIENCY.FAMILIAR }}
                  type="radio"
                  value={PROFICIENCY.FAMILIAR}
                  label=""
                  name={tableKey + '[' + item.id + ']'}
                  checked={values[tableKey][item.id] === PROFICIENCY.FAMILIAR}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <p>Familiar</p>
              </RPPageTwoSectionRowOptions>
              <RPPageTwoSectionRowOptions>
                <RPCheckbox
                  style={{ gridArea: PROFICIENCY.PROFICIENT }}
                  type="radio"
                  value={PROFICIENCY.PROFICIENT}
                  label=""
                  name={tableKey + '[' + item.id + ']'}
                  checked={values[tableKey][item.id] === PROFICIENCY.PROFICIENT}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <p>Proficient</p>
              </RPPageTwoSectionRowOptions>
              <RPPageTwoSectionRowOptions>
                <RPCheckbox
                  style={{ gridArea: PROFICIENCY.FLUENT }}
                  type="radio"
                  value={PROFICIENCY.FLUENT}
                  label=""
                  name={tableKey + '[' + item.id + ']'}
                  checked={values[tableKey][item.id] === PROFICIENCY.FLUENT}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <p>Fluent</p>
              </RPPageTwoSectionRowOptions>
            </RPPageTwoSectionRow>
          );
        })}
        {Object.keys(values['other_' + tableKey]).map((item, index) => {
          return (
            <RPPageTwoSectionRow key={'o' + tableCode + '-' + index}>
              <Row alignItems="flex-start" style={{ gridArea: 'row-label' }}>
                <RPPageTwoSectionRowLabel style={{ flexGrow: 1 }}>
                  {item}
                </RPPageTwoSectionRowLabel>
                <F500Button
                  onClick={() => {
                    let oTableKeyObj = values['other_' + tableKey];
                    delete oTableKeyObj[item];
                    setFieldValue('other_' + tableKey, oTableKeyObj);
                  }}
                >
                  <FaTimes />
                  <span>&nbsp;Remove</span>
                </F500Button>
              </Row>
              <RPPageTwoSectionRowOptions>
                <RPCheckbox
                  style={{ gridArea: PROFICIENCY.NOT_APPLICABLE }}
                  type="radio"
                  value={PROFICIENCY.NOT_APPLICABLE}
                  label=""
                  name={'other_' + tableKey + '[' + item + ']'}
                  checked={
                    values['other_' + tableKey][item] ===
                    PROFICIENCY.NOT_APPLICABLE
                  }
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <p>Not Applicable</p>
              </RPPageTwoSectionRowOptions>
              <RPPageTwoSectionRowOptions>
                <RPCheckbox
                  style={{ gridArea: PROFICIENCY.FAMILIAR }}
                  type="radio"
                  value={PROFICIENCY.FAMILIAR}
                  label=""
                  name={'other_' + tableKey + '[' + item + ']'}
                  checked={
                    values['other_' + tableKey][item] === PROFICIENCY.FAMILIAR
                  }
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <p>Familiar</p>
              </RPPageTwoSectionRowOptions>
              <RPPageTwoSectionRowOptions>
                <RPCheckbox
                  style={{ gridArea: PROFICIENCY.PROFICIENT }}
                  type="radio"
                  value={PROFICIENCY.PROFICIENT}
                  label=""
                  name={'other_' + tableKey + '[' + item + ']'}
                  checked={
                    values['other_' + tableKey][item] === PROFICIENCY.PROFICIENT
                  }
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <p>Proficient</p>
              </RPPageTwoSectionRowOptions>
              <RPPageTwoSectionRowOptions>
                <RPCheckbox
                  style={{ gridArea: PROFICIENCY.FLUENT }}
                  type="radio"
                  value={PROFICIENCY.FLUENT}
                  label=""
                  name={'other_' + tableKey + '[' + item + ']'}
                  checked={
                    values['other_' + tableKey][item] === PROFICIENCY.FLUENT
                  }
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <p>Fluent</p>
              </RPPageTwoSectionRowOptions>
            </RPPageTwoSectionRow>
          );
        })}
        <RPPageTwoSectionRow>
          <Row alignItems="flex-end" style={{ gridArea: 'row-label' }}>
            <Input
              label="Other Tools"
              value={otherDSSToolVal}
              onChange={event => {
                setOtherDSSToolVal(event.target.value);
              }}
              onBlur={() => {}}
            />
            <F500Button
              onClick={() => {
                let newToolVal = otherDSSToolVal.trim();
                if (newToolVal === '') return;

                if (
                  dataSoftwareSkillsItems.findIndex(
                    item => item.toLowerCase() === newToolVal.toLowerCase()
                  ) < 0
                ) {
                  setFieldValue(
                    'other_' + tableKey + '[' + otherDSSToolVal + ']',
                    ''
                  );
                  setOtherDSSToolVal('');
                }
              }}
            >
              <FaPlus />
              <span>&nbsp;Add</span>
            </F500Button>
          </Row>
        </RPPageTwoSectionRow>
      </>
    );
  };

  const fortuneList = async () => {
    const res = await ajax('GET', `/company-suggestion`);
    if (res.code === 200) {
      setfortuneOption(res?.data?.length > 0 ? res?.data : []);
    }
  };

  const generateF500Fields = () => {
    let fields = [];
    for (let index = 0; index < f500fieldRows; index++) {
      if (index === 0) {
        fields.push(
          <RPPageTwoSectionF500Row key={'f500-' + index}>
            <div style={{ flex: 3 }}>
              <LabelLayout>
                <label>Company</label>
              </LabelLayout>
              <Autocomplete
                id={'fortune_500_experience[' + index + '].company'}
                freeSolo
                autoSelect
                getOptionLabel={option => option.title || option}
                name={'fortune_500_experience[' + index + '].company'}
                options={fortuneOption}
                onChange={handleChange}
                value={values.fortune_500_experience[index]['company']}
                renderInput={params => (
                  <TextField
                    {...params}
                    value={values.fortune_500_experience[index]['company']}
                    variant="standard"
                    // label="Keywords"
                    placeholder="Add keywords"
                    margin="normal"
                    fullWidth
                  />
                )}
              />
              {/* <Input
                label="Company"
                name={'fortune_500_experience[' + index + '].company'}
                value={values.fortune_500_experience[index]['company']}
                onChange={handleChange}
                onBlur={handleBlur}
              /> */}
            </div>
            <Row alignItems="flex-end" style={{ flex: 5, columnGap: '2rem' }}>
              <div style={{ flex: 2 }}>
                <Dropdown
                  label="Client or Vendor side"
                  name={'fortune_500_experience[' + index + '].side'}
                  value={values.fortune_500_experience[index]['side']}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  options={Object.values(F500_SIDES)}
                  optionLabels={F500_SIDE_LABELS}
                />
              </div>
              <div style={{ flex: 2 }}>
                <Input
                  label="Years Worked"
                  type="number"
                  min="0"
                  name={'fortune_500_experience[' + index + '].years_worked'}
                  value={values.fortune_500_experience[index]['years_worked']}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
              <div style={{ flex: 1 }}>
                <F500Button
                  title="Add More"
                  onClick={() => {
                    values.fortune_500_experience.push({
                      company: '',
                      side: F500_SIDES.CLIENT,
                      years_worked: 0,
                    });
                    setF500FieldRows(f500fieldRows + 1);
                  }}
                >
                  <FaPlus />
                  <span>&nbsp;Add More</span>
                </F500Button>
              </div>
            </Row>
          </RPPageTwoSectionF500Row>
        );
      } else {
        fields.push(
          <RPPageTwoSectionF500Row key={'f500-' + index}>
            <div style={{ flex: 3 }}>
              <LabelLayout>
                <label>Company</label>
              </LabelLayout>
              <Autocomplete
                id={'fortune_500_experience[' + index + '].company'}
                freeSolo
                autoSelect
                getOptionLabel={option => option.title || option}
                name={'fortune_500_experience[' + index + '].company'}
                options={fortuneOption}
                onChange={handleChange}
                value={values.fortune_500_experience[index]['company']}
                renderInput={params => (
                  <TextField
                    {...params}
                    value={values.fortune_500_experience[index]['company']}
                    variant="standard"
                    // label="Keywords"
                    placeholder="Add keywords"
                    margin="normal"
                    fullWidth
                  />
                )}
              />
              {/* <Input
                label="Company"
                name={'fortune_500_experience[' + index + '].company'}
                value={values.fortune_500_experience[index]['company']}
                onChange={handleChange}
                onBlur={handleBlur}
              /> */}
            </div>
            <Row alignItems="flex-end" style={{ flex: 5, columnGap: '2rem' }}>
              <div style={{ flex: 2 }}>
                <Dropdown
                  label="Client or Vendor side"
                  name={'fortune_500_experience[' + index + '].side'}
                  value={values.fortune_500_experience[index]['side']}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  options={Object.values(F500_SIDES)}
                  optionLabels={F500_SIDE_LABELS}
                />
              </div>
              <div style={{ flex: 2 }}>
                <Input
                  label="Years Worked"
                  type="number"
                  min="0"
                  name={'fortune_500_experience[' + index + '].years_worked'}
                  value={values.fortune_500_experience[index]['years_worked']}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
              <div style={{ flex: 1 }}>
                <F500Button
                  title="Remove"
                  onClick={() => {
                    let f500 = values.fortune_500_experience.filter(
                      (item, i) => i !== index
                    );
                    setFieldValue('fortune_500_experience', [...f500]);
                    setF500FieldRows(f500fieldRows - 1);
                  }}
                >
                  <FaTimes />
                  <span>&nbsp;Remove</span>
                </F500Button>
              </div>
            </Row>
          </RPPageTwoSectionF500Row>
        );
      }
    }

    return fields;
  };

  useEffect(() => {
    if (values.same_billing_information) {
      setBillCountryId(countryId);
      setFieldValue('billing_address', values.address);
      setFieldValue('billing_address_2', values.address_2);
      setFieldValue('billing_city', values.city);
      setFieldValue('billing_state', values.state);
      setFieldValue('billing_country', values.country);
      setFieldValue('billing_postal_code', values.postal_code);
    }
  }, [
    countryId,
    values.address,
    values.address_2,
    values.city,
    values.state,
    values.country,
    values.postal_code,
    values.same_billing_information,
  ]);

  const generateReferFriendColleagueField = () => {
    let fields = [];
    for (let index = 0; index < referFieldRows; index++) {
      if (index === 0) {
        fields.push(
          <RPPageTwoSectionF500Row key={'refer' + index}>
            <div style={{ flex: 3 }}>
              <Input
                label="Name"
                name={'referrals[' + index + '].name'}
                value={values.referrals[index]['name']}
                onChange={handleChange}
                onBlur={handleBlur}
                styleMsg={{
                  color: 'red',
                  fontSize: '12px',
                  marginBottom: '15px',
                }}
              />
            </div>
            <div style={{ flex: 3 }}>
              <Input
                label="Email"
                name={'referrals[' + index + '].email'}
                value={values.referrals[index]['email']}
                onChange={handleChange}
                onBlur={handleBlur}
                styleMsg={{
                  color: 'red',
                  fontSize: '12px',
                  marginBottom: '15px',
                }}
              />
            </div>
            {/* <Row alignItems="flex-end" style={{ flex: 5, columnGap: '2rem' }}> */}
            <div style={{ flex: 3, position: 'relative', bottom: '16px' }}>
              <Dropdown
                label="Client or Researcher side"
                name={'referrals[' + index + '].type'}
                value={values.referrals[index]['type']}
                onChange={handleChange}
                onBlur={handleBlur}
                options={Object.values(ReferDrop)}
                optionLabels={ReferDrop_LABELS}
              />
              {/* <Input
                  label="Name and Email (Researcher)"
                  name={'referrals[' + index + '].researcher'}
                  value={values.referrals[index]['researcher']}
                  onChange={handleChange}
                  styleMsg={{
                    color: 'red',
                    fontSize: '12px',
                    marginBottom: '15px',
                  }}
                  onBlur={handleBlur}
                /> */}
            </div>
            <div style={{ flex: 2 }}>
              <F500Button
                title="Add More"
                onClick={() => {
                  values.referrals.push({
                    name: '',
                    email: '',
                    type: '',
                  });
                  setReferFieldRows(referFieldRows + 1);
                }}
                style={{ marginBottom: '2rem' }}
              >
                <FaPlus />
                <span>&nbsp;Add More</span>
              </F500Button>
            </div>
            {/* </Row> */}
          </RPPageTwoSectionF500Row>
        );
      } else {
        fields.push(
          <RPPageTwoSectionF500Row key={'refer-' + index}>
            <div style={{ flex: 3 }}>
              <Input
                label="Name"
                name={'referrals[' + index + '].name'}
                value={values.referrals[index]['name']}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>
            {/* <Row alignItems="flex-end" style={{ flex: 5, columnGap: '2rem' }}> */}
            <div style={{ flex: 3 }}>
              <Input
                label="Email"
                name={'referrals[' + index + '].email'}
                value={values.referrals[index]['email']}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>
            <div style={{ flex: 3 }}>
              <Dropdown
                label="Client or Researcher side"
                name={'referrals[' + index + '].type'}
                value={values.referrals[index]['type']}
                onChange={handleChange}
                onBlur={handleBlur}
                options={Object.values(ReferDrop)}
                optionLabels={ReferDrop_LABELS}
              />
              {/* <Input
                  label="Name and Email (Researcher)"
                  name={'referrals[' + index + '].researcher'}
                  value={values.referrals[index]['researcher']}
                  onChange={handleChange}
                  styleMsg={{
                    color: 'red',
                    fontSize: '12px',
                    marginBottom: '15px',
                  }}
                  onBlur={handleBlur}
                /> */}
            </div>
            <div style={{ flex: 2 }}>
              <F500Button
                title="Remove"
                onClick={() => {
                  let refers = values.referrals.filter(
                    (item, i) => i !== index
                  );
                  setFieldValue('referrals', [...refers]);
                  setReferFieldRows(referFieldRows - 1);
                }}
              >
                <FaTimes />
                <span>&nbsp;Remove</span>
              </F500Button>
            </div>
            {/* </Row> */}
          </RPPageTwoSectionF500Row>
        );
      }
    }

    return fields;
  };

  const generateRadioChoices = (rcArr, rcKey, rcCode) => {
    return (
      <>
        {rcArr.map((item, index) => {
          return (
            <RCCheckbox
              key={rcCode + '-' + index}
              style={{ marginRight: '1rem' }}
              type="radio"
              value={item.choiceValue}
              label={item.choiceLabel}
              name={rcKey}
              checked={values[rcKey] === item.choiceValue}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          );
        })}
      </>
    );
  };

  const handleServicesChange = e => {
    setFieldValue('services_offered', e.target.value);
    setFieldValue('business_specialization', []);
    getSpecializationOptions(e.target.value);
  };

  const uploadFileToS3 = async (file, dir, filename) => {
    const new_filename =
      format(new Date(), 'MMDDDyyyyHHmmssSSS') + '/' + filename;
    const res = await ajax(
      'GET',
      '/s3/signed_url/' + dir + '?object_name=' + new_filename
    );
    if (res.code === 200 && res.data && res.data['signed_url']) {
      const headers = {};
      const upload_res = await fetch(res.data['signed_url'], {
        method: 'PUT',
        body: file,
        headers,
      });
      return await {
        status: upload_res.status,
        Key: dir + '/' + new_filename,
      };
    } else {
      return await res;
    }
  };

  // const handleUpload = async e => {
  //   e.preventDefault();
  //   const formData = new FormData();
  //   formData.append('image', image.raw);
  // };

  const getPaymentMethod = async () => {
    const res = await ajax('POST', '/payment/account', {
      member_id: user.member.id,
    });
    if (res.code === 200) {
      window.open(res.data.url, '_self');
    }
    return await res;
  };

  return (
    <ProfileContent>
      {isLoading ? <LoadingIndicator /> : null}
      <HeaderLayout>
        <Instructions>
          <h4>Complete Researcher Profile</h4>
          <p>To help you get started, fill in the required details first.</p>
          <p>
            Please input as much detail as possible as this will help increase
            your researcher score and matching with jobs and clients.
          </p>
        </Instructions>
        <Steps
          steps={['Profile', 'Competency and Experience', 'Highlights']}
          current={currentStep}
        />
      </HeaderLayout>
      {currentStep === 0 && (
        <RPPageOneContainer>
          <div>
            <RPPageSectionTitle>User Profile</RPPageSectionTitle>
            <UserProfileGrid>
              <div ref={firstNameRef}>
                <Input
                  style={{ gridArea: 'first_name' }}
                  label="First Name"
                  name="first_name"
                  value={values.first_name}
                  error={isError && !values.first_name.trim()}
                  errorMessage={errorMessage}
                  required
                  onChange={e => {
                    if (e.target.value === '') {
                      setfirstName(true);
                      setFieldValue(e.target.name, '');
                    } else {
                      setfirstName(false);
                      setFieldValue(e.target.name, e.target.value);
                    }
                  }}
                  onBlur={handleBlur}
                />
              </div>
              <div>
                <Input
                  style={{ gridArea: 'last_name' }}
                  label="Last Name"
                  name="last_name"
                  // required
                  value={values.last_name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
              <div ref={addressRef}>
                <Input
                  style={{ gridArea: 'address' }}
                  label="Street Address"
                  name="address"
                  required
                  value={values.address}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  errorMessage={errorMessage}
                  error={isError && !values.address.trim()}
                />
              </div>

              <div>
                <Input
                  style={{ gridArea: 'address_2' }}
                  label="Street Address Line 2 (optional)"
                  name="address_2"
                  value={values.address_2}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
              <div ref={cityRef}>
                <Input
                  style={{ gridArea: 'city' }}
                  label="City"
                  name="city"
                  value={values.city}
                  error={isError && !values.city.trim()}
                  errorMessage={errorMessage}
                  required
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>

              <div ref={stateRef}>
                <Input
                  style={{ gridArea: 'state' }}
                  label="State"
                  name="state"
                  required
                  error={isError && !values.state.trim()}
                  errorMessage={errorMessage}
                  value={values.state}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>

              <div style={{ gridArea: 'country' }} ref={countryRef}>
                <LabelLayout>
                  <label>
                    Country <sup style={{ color: 'red' }}>*</sup>
                  </label>
                </LabelLayout>
                <Autocomplete
                  id={'country'}
                  freeSolo
                  autoSelect
                  // disableClearable
                  getOptionLabel={option => option.title || option}
                  name="country"
                  options={props?.countryList}
                  onChange={(e, newValue) => {
                    setFieldValue('country', newValue ? newValue : '');
                    if (newValue) {
                      let id = newValue?.split(' (');
                      const filterISO = props?.fullCountryList?.filter(
                        item => item.nicename === id[0]
                      );
                      setCountryId(filterISO[0]?.iso || '');
                    } else {
                      setCountryId('');
                    }
                  }}
                  value={values?.country || ''}
                  required
                  renderInput={params => (
                    <TextField
                      {...params}
                      value={values?.country || ''}
                      variant="standard"
                      placeholder="Country"
                      margin="normal"
                      fullWidth
                      error={
                        (isError && !values?.country.trim()) ||
                        (isError &&
                          !props?.countryList.includes(values?.country.trim()))
                      }
                    />
                  )}
                />
                {isError &&
                  (!values.country.trim() ||
                  !props?.countryList.includes(values?.country.trim()) ? (
                    <p
                      style={{
                        color: 'red',
                        marginTop: '4px',
                        fontSize: '14px',
                        textAlign: 'left',
                      }}
                    >
                      {errorMessage}
                    </p>
                  ) : null)}

                {console.log(
                  values.country,
                  props?.countryList.includes(values.country)
                )}
              </div>
              <div ref={postalRef}>
                <Input
                  style={{ gridArea: 'postal_code' }}
                  label="Zip/Postal Code"
                  name="postal_code"
                  required
                  value={values.postal_code}
                  onChange={handleChange}
                  error={isError && !values.postal_code.trim()}
                  errorMessage={errorMessage}
                  onBlur={handleBlur}
                />
              </div>

              <Input
                style={{ gridArea: 'email' }}
                label="Email Address"
                name="email"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled
                required
              />
              <div ref={contactRef}>
                <Input
                  style={{ gridArea: 'contact_number' }}
                  label="Contact No."
                  name="contact_number"
                  required
                  value={values.contact_number}
                  error={isError && !values.contact_number.trim()}
                  errorMessage={errorMessage}
                  // required={true}
                  // errorMessage={'test'}
                  // error={!values.contact_number}
                  onChange={e => {
                    if (e.target.value === '') {
                      // setphoneNumber(`*Contact Number is required field`);
                      setFieldValue(e.target.name, '');
                    } else {
                      setphoneNumber('');
                      setFieldValue(e.target.name, e.target.value);
                    }
                  }}
                  onBlur={handleBlur}
                />
                {/* <div style={{ color: 'red', fontSize: '10px' }}>
                  {phoneNumber}
                </div> */}
              </div>
              <div
                style={{ display: 'block', gridArea: 'linked_in_url' }}
                ref={profileRef}
              >
                <LabelLayout style={{ justifyContent: 'flex-start' }}>
                  <label style={{ fontWeight: 'bold' }}>
                    {'Please verify your identity'}
                  </label>
                  <FaInfoCircle
                    style={{ paddingLeft: '0.5rem' }}
                    data-tip
                    data-for="identity"
                    color="#ff9e08"
                  />
                  <p
                    style={{
                      fontSize: '2rem',
                      fontWeight: 'lighter',
                      fontStyle: 'italic',
                      textTransform: 'initial',
                    }}
                  >
                    <SPReactTooltip
                      id="identity"
                      effect="solid"
                      backgroundColor="#393939"
                      multiline={true}
                      style={{
                        fontSize: '1.2rem',
                        color: '#fff',
                      }}
                    >
                      <div>
                        Please verify your identity via linkedin or a photo ID
                      </div>
                    </SPReactTooltip>
                  </p>
                </LabelLayout>
                <Input
                  style={{ gridArea: 'linked_in_url', marginBottom: '1rem' }}
                  label="LinkedIn"
                  name="linked_in_url"
                  placeholder="https://www.linkedin.com/in/username"
                  value={values.linked_in_url}
                  onChange={validateLinkedin}
                  onBlur={handleBlur}
                />
                {verifyIdentity && message !== '' ? (
                  <div style={{ color: 'red', fontSize: '10px' }}>
                    {message}
                  </div>
                ) : verifyIdentity && message === '' ? (
                  <div style={{ color: 'red', fontSize: '10px' }}>
                    *Please verify your identity
                  </div>
                ) : (
                  <div>{''}</div>
                )}
                {/* <LinkedInPageResearcher /> */}
                <LabelLayout style={{ justifyContent: 'center' }}>
                  <label style={{ fontWeight: 'bold' }}>{'OR'}</label>
                </LabelLayout>
                <FileInput
                  name="proof_of_employment"
                  label={
                    <>
                      <span>Attach Photo ID</span>
                      <FaInfoCircle
                        style={{ paddingLeft: '0.5rem' }}
                        data-tip
                        data-for="photoID"
                        color="#ff9e08"
                        data-tooltip-place="right"
                      />
                      <div
                        style={{
                          fontSize: '2rem',
                          fontWeight: 'lighter',
                          fontStyle: 'italic',
                          textTransform: 'initial',
                          // width: '90vw',
                        }}
                      >
                        <SPReactTooltip
                          id="photoID"
                          effect="solid"
                          backgroundColor="#393939"
                          multiline={true}
                          style={{
                            fontSize: '1.2rem',
                            color: '#fff',
                          }}
                          place="right"
                        >
                          <div>
                            Attach Photo ID, Government IDs, Passport, Driver’s
                            license to verify your identity
                          </div>
                        </SPReactTooltip>
                      </div>
                    </>
                  }
                  // style={{ gridArea: 'id_image_link' }}
                  // style={{ gridArea: 'linked_in_url', marginBottom: '1rem' }}
                  value={values.proof_of_employment}
                  onChange={files => {
                    setverifyIdentity(false);
                    handleFileChange('proof_of_employment', files);
                  }}
                  path="/ids"
                  multiple={true}
                />
                {verifyIdentity && message !== '' ? (
                  <div> </div>
                ) : verifyIdentity && message === '' ? (
                  <div style={{ color: 'red', fontSize: '10px' }}>
                    *Please verify your identity
                  </div>
                ) : (
                  <div> </div>
                )}
              </div>
              <UserProfileImageBox style={{ gridArea: 'id_image_link' }}>
                <UserProfileImageContainer>
                  <div
                    style={{ textAlign: 'center', marginBottom: 25 }}
                    ref={userProfileRef}
                  >
                    Upload Photo<sup style={{ color: 'red' }}>*</sup>
                  </div>
                  <label
                    htmlFor="upload-button"
                    style={{ position: 'relative', display: 'block' }}
                  >
                    {image.preview ? (
                      <UserProfileImage src={image.preview} />
                    ) : (
                      <DefaultProfilePicture />
                    )}
                    <IconContainer>
                      <FaPencilAlt />
                    </IconContainer>
                  </label>
                  {image && image?.preview && (
                    <div
                      style={{
                        // position: 'absolute',
                        marginTop: '5px',
                        padding: '10px',
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      <Button
                        outline
                        style={{ width: '100px' }}
                        onClick={e => {
                          e.stopPropagation();
                          setImage({
                            preview: '',
                            raw: '',
                            new_upload: false,
                            key: '',
                            filename: '',
                          });
                        }}
                      >
                        Remove
                      </Button>
                    </div>
                  )}
                  <input
                    type="file"
                    id="upload-button"
                    style={{ display: 'none' }}
                    onChange={e => {
                      e.target.value = '';
                      setProfileImageToCrop('');
                      // handleUpload(e);
                    }}
                    onInput={handleProfileChange}
                    // onChange={handleProfileChange}
                  />
                  {isError && !image?.preview && (
                    <div
                      style={{
                        color: 'red',
                        fontSize: '14px',
                        paddingTop: '5px',
                        textAlign: 'center',
                      }}
                    >
                      This field is required
                    </div>
                  )}
                </UserProfileImageContainer>
              </UserProfileImageBox>

              <div ref={titleRef} style={{ gridArea: 'nda_template_link' }}>
                <Checkbox
                  label={
                    <>
                      I have read and understood the NDA Template for Projects{' '}
                      <FaInfoCircle
                        style={{ paddingLeft: '0.5rem' }}
                        data-tip
                        data-for="ndaTemplate"
                        color="#ff9e08"
                      />
                      <p
                        style={{
                          fontSize: '2rem',
                          fontWeight: 'lighter',
                          fontStyle: 'italic',
                          textTransform: 'initial',
                        }}
                      >
                        <SPReactTooltip
                          id="ndaTemplate"
                          effect="solid"
                          backgroundColor="#393939"
                          multiline={true}
                          style={{
                            fontSize: '1.2rem',
                            color: '#fff',
                          }}
                        >
                          <div>
                            For your convenience, this signed umbrella NDA on
                            file protects all Big Robin project correspondence
                            and data as confidential between researchers and
                            respective clients
                          </div>
                        </SPReactTooltip>
                      </p>
                    </>
                  }
                  name="is_nda_approve"
                  checked={agreeNda}
                  onBlur={handleBlur}
                  onClick={() => {
                    if (localStorage.getItem('agreeNda') === 'true') {
                      localStorage.setItem('agreeNda', 'false');
                      setFieldValue('is_nda_approve', false);
                      setAgreeNda(false);
                    } else {
                      setndaTemplate(false);
                      window.open('/nda', '_blank');
                    }
                  }}
                  readOnly={true}
                />

                {isError && !values.is_nda_approve ? (
                  <div
                    style={{
                      color: 'red',
                      fontSize: '14px',
                      paddingTop: '5px',
                    }}
                  >
                    This field is required
                  </div>
                ) : (
                  <div> </div>
                )}
              </div>
              <div
                ref={paymentRef}
                style={{
                  gridArea: 'payment_methods',
                  marginTop: '20px',
                }}
              >
                <RPPageSectionTitle
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  Payments Profile {handlePaymentTooltip(values?.country)}
                </RPPageSectionTitle>
                {/* {handlePaymentType()} */}

                <div
                  style={{
                    display: 'inline',
                    alignItems: 'center',
                  }}
                >
                  <span style={{ fontSize: '2rem', lineHeight: '16px' }}>
                    In order to receive payments, you need an account on
                    Borderless.
                  </span>
                  <span
                    style={{
                      cursor:
                        user?.member?.borderless_handle && is_profile_completed
                          ? 'not-allowed'
                          : 'pointer',
                      marginLeft: '2px',
                      textDecoration: 'underline',
                      color: 'blue',
                    }}
                  >
                    <span
                      onClick={() =>
                        user?.member?.borderless_handle && is_profile_completed
                          ? null
                          : window.open(
                              process.env.REACT_APP_ENV === 'local' ||
                                process.env.REACT_APP_ENV === 'dev' ||
                                process.env.REACT_APP_ENV === 'stg'
                                ? 'https://sandbox.secure.getborderless.com/user-register' //sandbox
                                : 'https://secure.getborderless.com/user-register', //live
                              '_blank'
                            )
                      }
                      style={{ fontSize: '2rem', lineHeight: '16px' }}
                    >
                      Create your account
                      <FaExternalLinkAlt
                        style={{ paddingLeft: '5px' }}
                        size={12}
                      />
                    </span>
                  </span>
                  <span>
                    <FaInfoCircle
                      style={{
                        marginLeft: '5px',
                        padding: '0px 5px',
                        width: '14px',
                        height: '14px',
                        outline: 0,
                      }}
                      data-tip
                      data-for="borderless_create"
                      color="#ff9e08"
                    />

                    <p
                      style={{
                        fontSize: '2rem',
                        fontWeight: 'lighter',
                        fontStyle: 'italic',
                        textTransform: 'initial',
                      }}
                    >
                      <SPReactTooltip
                        id="borderless_create"
                        effect="solid"
                        backgroundColor="#393939"
                        multiline={true}
                        style={{
                          fontSize: '1.2rem',
                          color: '#fff',
                        }}
                      >
                        <span>
                          You need to provide your borderless handle to start
                          receiving payments!
                          <br />A handle is like your name, please choose a
                          unique & memorable one!
                        </span>
                      </SPReactTooltip>
                    </p>
                  </span>
                </div>

                <div style={{ marginTop: '20px' }}>
                  <Input
                    // style={{ gridArea: 'last_name' }}
                    placeholder={'@username'}
                    label={<span ref={borderlessRef}> Borderless Handle</span>}
                    name="borderless_handle"
                    required
                    value={values.borderless_handle}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={isError && !values.borderless_handle}
                    errorMessage={errorMessage}
                    disabled={
                      user?.member?.borderless_handle && is_profile_completed
                    }
                  />
                </div>
                <div style={{ marginTop: '12px' }}>
                  <span
                    onClick={() => setForgotHandlePopup(true)}
                    style={{
                      cursor: 'pointer',
                      marginLeft: '2px',
                      textDecoration: 'underline',
                      color: 'blue',
                      fontWeight: 'bold',
                    }}
                  >
                    Forgot/Change handle
                  </span>
                  {/* Know more about border-less */}
                  <span>
                    <FaInfoCircle
                      style={{ paddingLeft: '5px', cursor: 'pointer' }}
                      data-tip
                      data-for="borderless_know"
                      color="#ff9e08"
                      onClick={() => {
                        window.scrollTo(0, 0);
                        window.open(`/borderless-forgot-change`, '_blank');
                        // history.push('/borderless-forgot-change');
                      }}
                    />
                    <p
                      style={{
                        fontSize: '2rem',
                        fontWeight: 'lighter',
                        fontStyle: 'italic',
                        textTransform: 'initial',
                      }}
                    >
                      <SPReactTooltip
                        id="borderless_know"
                        effect="solid"
                        backgroundColor="#393939"
                        multiline={true}
                        style={{
                          fontSize: '1.2rem',
                          color: '#fff',
                        }}
                      >
                        <span>To know more about borderless, click here</span>
                      </SPReactTooltip>
                    </p>
                  </span>
                </div>

                {values.payment_methods.includes('Bank') && (
                  <Input
                    style={{ gridArea: 'bank_account_details' }}
                    label="Bank Account Details"
                    name="bank_account_number"
                    value={values.bank_account_number}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                )}
              </div>
              {handleBillingAddress()}
            </UserProfileGrid>
          </div>
          <div>
            <RPPageSectionTitle>Services Profile</RPPageSectionTitle>
            <ServicesProfileGrid>
              <div ref={industryRef}>
                <Dropdown
                  style={{ gridArea: 'industry_expertise' }}
                  label="Industry Expertise"
                  name="industry_expertise"
                  value={values.industry_expertise}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  options={INDUSTRY_VALUES || []}
                  optionLabels={INDUSTRY_LABELS || []}
                  multi
                  required
                  error={isError && !values.industry_expertise.length > 0}
                  errorMessage={errorMessage}
                />
              </div>
              <div ref={serviceRef}>
                <DropdownWithName
                  style={{ gridArea: 'services_offered' }}
                  label="Expertise"
                  name="services_offered"
                  value={values.services_offered}
                  onChange={handleServicesChange}
                  onBlur={handleBlur}
                  serviceOption={serviceOption || []}
                  options={optionValues.services_offered || []}
                  optionLabels={optionLabels.services_offered || []}
                  multi
                  required
                  error={isError && !values.services_offered.length > 0}
                  errorMessage={errorMessage}
                />
              </div>
              <div ref={businessRef}>
                <SpecializationDropdown
                  style={{ gridArea: 'business_specialization' }}
                  label="Specialization"
                  name="business_specialization"
                  value={values?.business_specialization}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  required
                  options={optionValues.business_specialization || []}
                  optionLabels={optionLabels.business_specialization || {}}
                  optionMap={optionMaps?.business_specialization || []}
                  error={isError && !values.business_specialization.length > 0}
                  errorMessage={errorMessage}
                />
              </div>
              <div ref={scopeRef}>
                <Dropdown
                  style={{ gridArea: 'scope_of_services' }}
                  label="Scope of Services"
                  name="scope_of_services"
                  value={values.scope_of_services}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  required
                  options={optionValues.method_of_work || []}
                  optionLabels={optionLabels.method_of_work || []}
                  multi
                  error={isError && !values.scope_of_services.length > 0}
                  errorMessage={errorMessage}
                />
              </div>
              <div ref={minEngRef}>
                <Input
                  style={{ gridArea: 'minimum_engagement' }}
                  label="Minimum Engagement (in US Dollars)"
                  name="minimum_engagement"
                  // type="number"
                  required
                  min={0}
                  pattern={6}
                  value={values.minimum_engagement}
                  onChange={e => {
                    setFieldValue(
                      e.target.name,
                      Number(e.target.value.replace(/[^0-9]/g, ''))
                    );
                  }}
                  onBlur={handleBlur}
                  error={isError && !values?.minimum_engagement}
                  errorMessage={errorMessage}
                />
              </div>
              <div ref={langRef}>
                <Dropdown
                  style={{ gridArea: 'preferred_languages' }}
                  label="Preferred Business Languages"
                  name="preferred_languages"
                  value={values.preferred_languages}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  options={fixLanguageMap(optionValues.languages) || []}
                  optionLabels={optionLabels.languages || []}
                  multi
                  required
                  error={isError && !values.preferred_languages.length > 0}
                  errorMessage={errorMessage}
                />
              </div>

              <div style={{ gridArea: 'preferred_markets' }}>
                <LabelLayout>
                  <label>Preferred Markets</label>
                </LabelLayout>
                <Autocomplete
                  id={'preferred_markets'}
                  multiple
                  getOptionLabel={option => option.name}
                  name="preferred_markets"
                  options={props?.countryObjList}
                  onChange={(e, newValue) => {
                    setFieldValue('preferred_markets', newValue);
                  }}
                  value={values.preferred_markets}
                  renderInput={params => (
                    <TextField
                      {...params}
                      variant="standard"
                      placeholder="Country"
                      margin="normal"
                      fullWidth
                    />
                  )}
                />
              </div>
              {/* <Dropdown
                style={{ gridArea: 'preferred_markets' }}
                label="Preferred Markets"
                name="preferred_markets"
                value={values.preferred_markets}
                onChange={handleChange}
                onBlur={handleBlur}
                options={
                  fixLanguageMarket(
                    optionValues.preferred_markets,
                    optionLabels.preferred_markets
                  ) || []
                }
                optionLabels={optionLabels.preferred_markets || []}
                multi
              /> */}
              {/* <Dropdown
                style={{ gridArea: 'countries_not_served' }}
                label="Countries not served, If any"
                name="countries_not_served"
                value={values.countries_not_served}
                onChange={handleChange}
                onBlur={handleBlur}
                options={optionValues.countries_not_served || []}
                optionLabels={optionLabels.countries_not_served || []}
                multi
              /> */}
            </ServicesProfileGrid>
          </div>
        </RPPageOneContainer>
      )}
      {currentStep === 1 && (
        <RPPageTwoContainer>
          <RPPageSectionTitle>
            Competency and Experience
            <FaInfoCircle
              style={{ paddingLeft: '0.5rem' }}
              data-tip
              data-for="competencyInfo"
              color="#ff9e08"
            />
            <p
              style={{
                fontSize: '1.5rem',
                fontWeight: 'lighter',
                fontStyle: 'italic',
                textTransform: 'initial',
              }}
            >
              <SPReactTooltip
                id="competencyInfo"
                effect="solid"
                backgroundColor="#ff9e08"
                multiline={true}
              >
                <div>
                  To better match what you bring with what clients need, we
                  assess a (1-5) score for your competencies and experience on
                  the following:
                  <br />
                  <br />
                  - Stakeholder Engagement <br />
                  - Education or Equivalent Work <br />
                  - Mastery of Insights and Analytics <br />
                  - Work Experience <br />
                  - Referral Network <br />
                  Profile questions that go into this score is market by
                </div>
              </SPReactTooltip>
              To help match you to jobs better
            </p>
          </RPPageSectionTitle>
          <RPPageTwoSection>
            <RPPageTwoSectionHRow>
              <h6 ref={researchTechinal}>
                Research Technical Mastery
                <span style={{ color: 'red' }}>*</span>
              </h6>
              <p>Not Applicable</p>
              <p>Familiar</p>
              <p>Proficient</p>
              <p>Fluent</p>
              <div>
                {Object.keys(values?.research_technical_mastery).length < 12 &&
                  isErrorNext && (
                    <p
                      style={{
                        color: 'red',
                        marginTop: '4px',
                        fontSize: '14px',
                        textAlign: 'left',
                      }}
                    >
                      {errorMessage}
                    </p>
                  )}
              </div>
            </RPPageTwoSectionHRow>

            {generateProficiencyTableFields(
              options.market_research || [],
              'research_technical_mastery',
              'rtm'
            )}
          </RPPageTwoSection>
          <RPPageTwoSection>
            <RPPageTwoSectionHRow>
              <h6 ref={analyticsTechinal}>
                Analytics Technical Mastery
                <span style={{ color: 'red' }}>*</span>
              </h6>
              <p>Not Applicable</p>
              <p>Familiar</p>
              <p>Proficient</p>
              <p>Fluent</p>
              <div>
                {Object.keys(values?.research_analytics_mastery).length < 12 &&
                  isErrorNext && (
                    <p
                      style={{
                        color: 'red',
                        marginTop: '4px',
                        fontSize: '14px',
                        textAlign: 'left',
                      }}
                    >
                      {errorMessage}
                    </p>
                  )}
              </div>
            </RPPageTwoSectionHRow>
            {generateProficiencyTableFields(
              options.analytics_research || [],
              'research_analytics_mastery',
              'ram'
            )}
          </RPPageTwoSection>
          <RPPageTwoSection>
            <RPPageTwoSectionHRow>
              <h6 ref={codeEthics}>
                Data/Software Skills <span style={{ color: 'red' }}>*</span>
              </h6>
              <p>Not Applicable</p>
              <p>Familiar</p>
              <p>Proficient</p>
              <p>Fluent</p>
              <div>
                {Object.keys(values?.data_software_skills).length < 17 &&
                  isErrorNext && (
                    <p
                      style={{
                        color: 'red',
                        marginTop: '4px',
                        fontSize: '14px',
                        textAlign: 'left',
                      }}
                    >
                      {errorMessage}
                    </p>
                  )}
              </div>
            </RPPageTwoSectionHRow>
            {generateDSSTableFields(
              dataSoftwareSkills.software_skills || [],
              'data_software_skills',
              'dss'
            )}
          </RPPageTwoSection>
          <RPPageTwoSection>
            <RPPageTwoSectionHRow>
              <h6 ref={researchMLRef}>
                Research/ML Operations <span style={{ color: 'red' }}>*</span>
              </h6>
              <p>Not Applicable</p>
              <p>Familiar</p>
              <p>Proficient</p>
              <p>Fluent</p>
              <div>
                {Object.keys(values?.researcher_ml_operations).length < 8 &&
                  isErrorNext && (
                    <p
                      style={{
                        color: 'red',
                        marginTop: '4px',
                        fontSize: '14px',
                        textAlign: 'left',
                      }}
                    >
                      {errorMessage}
                    </p>
                  )}
              </div>
            </RPPageTwoSectionHRow>
            {generateProficiencyTableFields(
              options?.researcher_ml_operations || [],
              'researcher_ml_operations',
              'rml'
            )}
          </RPPageTwoSection>
          <RPPageTwoSection>
            <RPPageTwoSectionHRow>
              <h6 ref={emailAddress}>
                Highest Stakeholder Management Experience
                <span style={{ color: 'red' }}>*</span>
                <FaInfoCircle
                  style={{ paddingLeft: '0.5rem' }}
                  data-tip
                  data-for="practiceMaturityInfo"
                  color="#ff9e08"
                />
              </h6>
              <SPReactTooltip
                id="practiceMaturityInfo"
                effect="solid"
                backgroundColor="#ff9e08"
                multiline={true}
              >
                <div>
                  To better match what you bring with what clients need, we
                  assess a (1-5) score for your competencies and experience on
                  the following:
                  <br />
                  <br />
                  - Stakeholder Engagement <br />
                  - Education or Equivalent Work <br />
                  - Mastery of Insights and Analytics <br />
                  - Work Experience <br />
                  - Referral Network <br />
                  Profile questions that go into this score is market by
                </div>
              </SPReactTooltip>
            </RPPageTwoSectionHRow>
            <div>
              {!values?.practice_maturity && isErrorNext && (
                <p
                  style={{
                    color: 'red',
                    marginTop: '4px',
                    fontSize: '14px',
                    textAlign: 'left',
                  }}
                >
                  {errorMessage}
                </p>
              )}
            </div>
            {generateRadioChoices(
              practice_maturity_choices,
              'practice_maturity',
              'pm'
            )}
          </RPPageTwoSection>
          <RPPageTwoSection>
            <RPPageTwoSectionHRow>
              <h6 ref={yearExp}>
                Years of Experience in this Field
                <span style={{ color: 'red' }}>*</span>
                <FaInfoCircle
                  style={{ paddingLeft: '0.5rem' }}
                  data-tip
                  data-for="experienceInfo"
                  color="#ff9e08"
                />
              </h6>
              <SPReactTooltip
                id="experienceInfo"
                effect="solid"
                backgroundColor="#ff9e08"
                multiline={true}
              >
                <div>
                  To better match what you bring with what clients need, we
                  assess a (1-5) score for your competencies and experience on
                  the following:
                  <br />
                  <br />
                  - Stakeholder Engagement <br />
                  - Education or Equivalent Work <br />
                  - Mastery of Insights and Analytics <br />
                  - Work Experience <br />
                  - Referral Network <br />
                  Profile questions that go into this score is market by
                </div>
              </SPReactTooltip>
            </RPPageTwoSectionHRow>
            <div>
              {values?.years_of_experience < 1 && isErrorNext && (
                <p
                  style={{
                    color: 'red',
                    marginTop: '4px',
                    fontSize: '14px',
                    textAlign: 'left',
                  }}
                >
                  {errorMessage}
                </p>
              )}
            </div>
            <RPPageTwoSectionRow>
              <div style={{ gridArea: 'row-label' }}>
                <Input
                  label=""
                  name="years_of_experience"
                  type="number"
                  min="0"
                  value={values.years_of_experience}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
              <div style={{ gridArea: PROFICIENCY.NOT_APPLICABLE }} />
              <div style={{ gridArea: PROFICIENCY.FAMILIAR }} />
              <div style={{ gridArea: PROFICIENCY.PROFICIENT }} />
              <div style={{ gridArea: PROFICIENCY.FLUENT }} />
            </RPPageTwoSectionRow>
          </RPPageTwoSection>
          <RPPageTwoSection>
            <RPPageTwoSectionHRow>
              <h6 ref={businessSize}>
                Size of Businesses Worked (click all that apply)
                <span style={{ color: 'red' }}>*</span>
                <FaInfoCircle
                  style={{ paddingLeft: '0.5rem' }}
                  data-tip
                  data-for="businessInfo"
                  color="#ff9e08"
                />
              </h6>

              <SPReactTooltip
                id="businessInfo"
                effect="solid"
                backgroundColor="#ff9e08"
                multiline={true}
              >
                <div>
                  To better match what you bring with what clients need, we
                  assess a (1-5) score for your competencies and experience on
                  the following:
                  <br />
                  <br />
                  - Stakeholder Engagement <br />
                  - Education or Equivalent Work <br />
                  - Mastery of Insights and Analytics <br />
                  - Work Experience <br />
                  - Referral Network <br />
                  Profile questions that go into this score is market by
                </div>
              </SPReactTooltip>
            </RPPageTwoSectionHRow>
            <div style={{ paddingBottom: '16px' }}>
              {values?.sizes_of_businesses_worked.length < 1 && isErrorNext && (
                <p
                  style={{
                    color: 'red',
                    marginTop: '4px',
                    fontSize: '14px',
                    textAlign: 'left',
                  }}
                >
                  {errorMessage}
                </p>
              )}
            </div>
            <RPPageTwoSectionRow>
              <div style={{ gridArea: 'row-label' }}>
                <Dropdown
                  style={{ gridArea: 'scope_of_services' }}
                  label=""
                  name="sizes_of_businesses_worked"
                  value={values.sizes_of_businesses_worked}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  options={SIZES_OF_BUSINESSES_WORKED_VALUES || []}
                  optionLabels={SIZES_OF_BUSINESSES_WORKED_LABELS || []}
                  multi
                />
              </div>
              <div style={{ gridArea: PROFICIENCY.NOT_APPLICABLE }} />
              <div style={{ gridArea: PROFICIENCY.FAMILIAR }} />
              <div style={{ gridArea: PROFICIENCY.PROFICIENT }} />
              <div style={{ gridArea: PROFICIENCY.FLUENT }} />
            </RPPageTwoSectionRow>
          </RPPageTwoSection>
          <RPPageTwoSection>
            <RPPageTwoSectionHRow>
              <h6 ref={relevantEdu}>
                Relevant Higher Education<span style={{ color: 'red' }}>*</span>
                <FaInfoCircle
                  style={{ paddingLeft: '0.5rem' }}
                  data-tip
                  data-for="higherEducInfo"
                  color="#ff9e08"
                />
              </h6>
              <SPReactTooltip
                id="higherEducInfo"
                effect="solid"
                backgroundColor="#ff9e08"
                multiline={true}
              >
                <div>
                  To better match what you bring with what clients need, we
                  assess a (1-5) score for your competencies and experience on
                  the following:
                  <br />
                  <br />
                  - Stakeholder Engagement <br />
                  - Education or Equivalent Work <br />
                  - Mastery of Insights and Analytics <br />
                  - Work Experience <br />
                  - Referral Network <br />
                  Profile questions that go into this score is market by
                </div>
              </SPReactTooltip>
            </RPPageTwoSectionHRow>
            {isErrorEducation && isErrorNext && (
              <div style={{ paddingBottom: '16px' }}>
                <p
                  style={{
                    color: 'red',
                    marginTop: '4px',
                    fontSize: '14px',
                    textAlign: 'left',
                  }}
                >
                  {'All fields with * are mandatory'}
                </p>
              </div>
            )}

            <RPPageTwoSectionRow>
              <RPPageTwoSectionRowLabel style={{ gridArea: 'row-label' }}>
                Degree Attained
              </RPPageTwoSectionRowLabel>
            </RPPageTwoSectionRow>

            {relevant_higher_education_items.map((item, index) => {
              return (
                <Row
                  style={{ marginTop: '2rem', marginBottom: '2rem' }}
                  key={'rhe-' + index}
                >
                  <Column>
                    <RHECheckbox
                      type="squarecheckbox"
                      label=""
                      name={
                        'relevant_higher_education.' +
                        item.fieldKey +
                        '.checked'
                      }
                      value={
                        values.relevant_higher_education[item.fieldKey][0][
                          'checked'
                        ]
                      }
                      checked={
                        values.relevant_higher_education[item.fieldKey][0][
                          'checked'
                        ]
                      }
                      onChange={() => {
                        let obj =
                          values.relevant_higher_education[item.fieldKey];
                        if (
                          values.relevant_higher_education[item.fieldKey][0][
                            'checked'
                          ]
                        ) {
                          setFieldValue(
                            'relevant_higher_education[' + item.fieldKey + ']',
                            obj.map(item => ({
                              ...item,
                              checked: false,
                            }))
                          );
                        } else {
                          setFieldValue(
                            'relevant_higher_education[' + item.fieldKey + ']',
                            obj.map(item => ({
                              ...item,
                              checked: true,
                            }))
                          );
                        }
                      }}
                      onBlur={handleBlur}
                    />
                  </Column>
                  <Column style={{ flexGrow: 1 }}>
                    <RPPageTwoSectionRowLabel style={{ gridArea: 'row-label' }}>
                      {item.fieldLabel}
                    </RPPageTwoSectionRowLabel>

                    {values?.relevant_higher_education[item?.fieldKey].map(
                      (dataItem, index) => {
                        let objItem =
                          values?.relevant_higher_education[item?.fieldKey];
                        if (dataItem?.checked) {
                          return (
                            <div style={{ marginTop: '10px' }}>
                              <RPPageTwoSectionF500Row>
                                <Input
                                  label={
                                    <span>
                                      Major/Area of Study
                                      <sup style={{ color: 'red' }}>*</sup>
                                    </span>
                                  }
                                  name={
                                    'relevant_higher_education.' +
                                    item.fieldKey +
                                    '.major_area_of_study'
                                  }
                                  value={dataItem.major_area_of_study}
                                  onChange={e => {
                                    let valueItem = [
                                      ...values?.relevant_higher_education[
                                        item?.fieldKey
                                      ],
                                    ];
                                    valueItem[index].major_area_of_study =
                                      e.target.value;
                                    setFieldValue(
                                      'relevant_higher_education[' +
                                        item.fieldKey +
                                        ']',
                                      valueItem
                                    );
                                  }}
                                  onBlur={handleBlur}
                                />
                                <Input
                                  label={
                                    <span>
                                      Academic Institution
                                      <sup style={{ color: 'red' }}>*</sup>
                                    </span>
                                  }
                                  name={
                                    'relevant_higher_education.' +
                                    item.fieldKey +
                                    '.academic_institution'
                                  }
                                  value={dataItem.academic_institution}
                                  onChange={e => {
                                    let valueItem = [
                                      ...values?.relevant_higher_education[
                                        item?.fieldKey
                                      ],
                                    ];
                                    valueItem[index].academic_institution =
                                      e.target.value;
                                    setFieldValue(
                                      'relevant_higher_education[' +
                                        item.fieldKey +
                                        ']',
                                      valueItem
                                    );
                                  }}
                                  onBlur={handleBlur}
                                />
                                {index === 0 ? (
                                  <div style={{ flex: 1 }}>
                                    <F500Button
                                      title="Add More"
                                      onClick={() => {
                                        const arrField = [
                                          ...values.relevant_higher_education[
                                            item.fieldKey
                                          ],
                                        ];
                                        arrField.push({
                                          checked: true,
                                          major_area_of_study: '',
                                          academic_institution: '',
                                        });
                                        setFieldValue(
                                          'relevant_higher_education[' +
                                            item.fieldKey +
                                            ']',
                                          arrField
                                        );
                                      }}
                                    >
                                      <FaPlus />
                                      <span>&nbsp;Add More</span>
                                    </F500Button>
                                  </div>
                                ) : (
                                  <div style={{ flex: 1 }}>
                                    <F500Button
                                      title="Remove"
                                      onClick={() => {
                                        let f500 = objItem.filter(
                                          (item, i) => i !== index
                                        );
                                        setFieldValue(
                                          'relevant_higher_education[' +
                                            item.fieldKey +
                                            ']',
                                          [...f500]
                                        );
                                      }}
                                    >
                                      <FaTimes />
                                      <span>&nbsp;Remove</span>
                                    </F500Button>
                                  </div>
                                )}
                              </RPPageTwoSectionF500Row>
                            </div>
                          );
                        }
                      }
                    )}
                  </Column>
                </Row>
              );
            })}
          </RPPageTwoSection>
          <RPPageTwoSection>
            <RPPageTwoSectionHRow>
              <h6 ref={workExp}>
                Work Experience<span style={{ color: 'red' }}>*</span>
                <FaInfoCircle
                  style={{ paddingLeft: '0.5rem' }}
                  data-tip
                  data-for="fortune500Info"
                  color="#ff9e08"
                />
              </h6>

              <SPReactTooltip
                id="fortune500Info"
                effect="solid"
                backgroundColor="#ff9e08"
                multiline={true}
              >
                <div>
                  To better match what you bring with what clients need, we
                  assess a (1-5) score for your competencies and experience on
                  the following:
                  <br />
                  <br />
                  - Stakeholder Engagement <br />
                  - Education or Equivalent Work <br />
                  - Mastery of Insights and Analytics <br />
                  - Work Experience <br />
                  - Referral Network <br />
                  Profile questions that go into this score is market by
                </div>
              </SPReactTooltip>
            </RPPageTwoSectionHRow>
            {isErrorWork && isErrorNext && (
              <div style={{ paddingBottom: '16px' }}>
                <p
                  style={{
                    color: 'red',
                    marginTop: '4px',
                    fontSize: '14px',
                    textAlign: 'left',
                  }}
                >
                  {errorMessage}
                </p>
              </div>
            )}
            {generateF500Fields()}
          </RPPageTwoSection>

          <RPPageTwoSection>
            <RPPageTwoSectionHRow>
              <h6>
                Big Robin User Referrer, if any{' '}
                <FaInfoCircle
                  style={{ paddingLeft: '0.5rem' }}
                  data-tip
                  data-for="bigRobinInfo"
                  color="#ff9e08"
                />
              </h6>

              <SPReactTooltip
                id="bigRobinInfo"
                effect="solid"
                backgroundColor="#ff9e08"
                multiline={true}
              >
                <div>
                  To better match what you bring with what clients need, we
                  assess a (1-5) score for your competencies and experience on
                  the following:
                  <br />
                  <br />
                  - Stakeholder Engagement <br />
                  - Education or Equivalent Work <br />
                  - Mastery of Insights and Analytics <br />
                  - Work Experience <br />
                  - Referral Network <br />
                  Profile questions that go into this score is market by
                </div>
              </SPReactTooltip>
            </RPPageTwoSectionHRow>

            <RPPageTwoSectionRow>
              <div style={{ gridArea: 'row-label' }}>
                <Input
                  label="Email Address"
                  name="big_robin_user_referee"
                  type="email"
                  value={values.big_robin_user_referee}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
              <div style={{ gridArea: PROFICIENCY.NOT_APPLICABLE }} />
              <div style={{ gridArea: PROFICIENCY.FAMILIAR }} />
              <div style={{ gridArea: PROFICIENCY.PROFICIENT }} />
              <div style={{ gridArea: PROFICIENCY.FLUENT }} />
            </RPPageTwoSectionRow>
          </RPPageTwoSection>
          <RPPageTwoSection>
            <RPPageTwoSectionHRow>
              <h6 ref={referredList}>
                Code of Ethics{' '}
                <FaInfoCircle
                  style={{ paddingLeft: '0.5rem' }}
                  data-tip
                  data-for="codeOfEthics"
                  color="#ff9e08"
                />
                <p
                  style={{
                    fontSize: '2rem',
                    fontWeight: 'lighter',
                    fontStyle: 'italic',
                    textTransform: 'initial',
                  }}
                >
                  <SPReactTooltip
                    id="codeOfEthics"
                    effect="solid"
                    backgroundColor="#393939"
                    multiline={true}
                    style={{
                      fontSize: '1.2rem',
                      color: '#fff',
                    }}
                  >
                    <div>
                      By choosing fluent, you promise to abide by the of conduct
                      for data and research users and providers
                    </div>
                  </SPReactTooltip>
                </p>
              </h6>
              <p>Not Applicable</p>
              <p>Familiar</p>
              <p>Proficient</p>
              <p>Fluent</p>
            </RPPageTwoSectionHRow>
            {(!values?.insights_association ||
              !values?.market_association_society ||
              !values?.ai_advancement ||
              !values?.ai_ethics) &&
              isErrorNext && (
                <div style={{ paddingBottom: '16px' }}>
                  <p
                    style={{
                      color: 'red',
                      marginTop: '4px',
                      fontSize: '14px',
                      textAlign: 'left',
                    }}
                  >
                    {errorMessage}
                  </p>
                </div>
              )}
            <RPPageTwoSectionRow>
              <RPPageTwoSectionRowLabel style={{ gridArea: 'row-label' }}>
                Insights Association (IA)
                <a
                  href="https://www.insightsassociation.org/Resources/Codes-of-Standards"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaInfoCircle
                    style={{
                      paddingLeft: '0.5rem',
                      position: 'relative',
                      top: '3px',
                    }}
                    color="#ff9e08"
                  />
                </a>
                <span style={{ color: 'red' }}>*</span>
              </RPPageTwoSectionRowLabel>
              <RPPageTwoSectionRowOptions>
                <RPCheckbox
                  style={{ gridArea: 'NOT_APPLICABLE' }}
                  type="radio"
                  value={PROFICIENCY.NOT_APPLICABLE}
                  label=""
                  name={`insights_association`}
                  checked={
                    values['insights_association'] ===
                    PROFICIENCY.NOT_APPLICABLE
                  }
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <p>Not Applicable</p>
              </RPPageTwoSectionRowOptions>
              <RPPageTwoSectionRowOptions>
                <RPCheckbox
                  style={{ gridArea: PROFICIENCY.FAMILIAR }}
                  type="radio"
                  value={PROFICIENCY.FAMILIAR}
                  label=""
                  name={`insights_association`}
                  checked={
                    values['insights_association'] === PROFICIENCY.FAMILIAR
                  }
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <p>Familiar</p>
              </RPPageTwoSectionRowOptions>
              <RPPageTwoSectionRowOptions>
                <RPCheckbox
                  style={{ gridArea: PROFICIENCY.PROFICIENT }}
                  type="radio"
                  value={PROFICIENCY.PROFICIENT}
                  label=""
                  name={`insights_association`}
                  checked={
                    values['insights_association'] === PROFICIENCY.PROFICIENT
                  }
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <p>Proficient</p>
              </RPPageTwoSectionRowOptions>
              <RPPageTwoSectionRowOptions>
                <RPCheckbox
                  style={{ gridArea: PROFICIENCY.FLUENT }}
                  type="radio"
                  value={PROFICIENCY.FLUENT}
                  label=""
                  name={`insights_association`}
                  checked={
                    values['insights_association'] === PROFICIENCY.FLUENT
                  }
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <p>Fluent</p>
              </RPPageTwoSectionRowOptions>
            </RPPageTwoSectionRow>
            <RPPageTwoSectionRow>
              <RPPageTwoSectionRowLabel style={{ gridArea: 'row-label' }}>
                Market Research Society (MRS){' '}
                <a
                  href="https://www.mrs.org.uk/standards/code-of-conduct"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaInfoCircle
                    style={{
                      paddingLeft: '0.5rem',
                      position: 'relative',
                      top: '3px',
                    }}
                    color="#ff9e08"
                  />
                </a>
                <span style={{ color: 'red' }}>*</span>
              </RPPageTwoSectionRowLabel>
              <RPPageTwoSectionRowOptions>
                <RPCheckbox
                  style={{ gridArea: 'NOT_APPLICABLE' }}
                  type="radio"
                  value={PROFICIENCY.NOT_APPLICABLE}
                  label=""
                  name={`market_association_society`}
                  checked={
                    values['market_association_society'] ===
                    PROFICIENCY.NOT_APPLICABLE
                  }
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <p>Not Applicable</p>
              </RPPageTwoSectionRowOptions>
              <RPPageTwoSectionRowOptions>
                <RPCheckbox
                  style={{ gridArea: PROFICIENCY.FAMILIAR }}
                  type="radio"
                  value={PROFICIENCY.FAMILIAR}
                  label=""
                  name={`market_association_society`}
                  checked={
                    values['market_association_society'] ===
                    PROFICIENCY.FAMILIAR
                  }
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <p>Familiar</p>
              </RPPageTwoSectionRowOptions>
              <RPPageTwoSectionRowOptions>
                <RPCheckbox
                  style={{ gridArea: PROFICIENCY.PROFICIENT }}
                  type="radio"
                  value={PROFICIENCY.PROFICIENT}
                  label=""
                  name={`market_association_society`}
                  checked={
                    values['market_association_society'] ===
                    PROFICIENCY.PROFICIENT
                  }
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <p>Proficient</p>
              </RPPageTwoSectionRowOptions>
              <RPPageTwoSectionRowOptions>
                <RPCheckbox
                  style={{ gridArea: PROFICIENCY.FLUENT }}
                  type="radio"
                  value={PROFICIENCY.FLUENT}
                  label=""
                  name={`market_association_society`}
                  checked={
                    values['market_association_society'] === PROFICIENCY.FLUENT
                  }
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <p>Fluent</p>
              </RPPageTwoSectionRowOptions>
            </RPPageTwoSectionRow>
            <RPPageTwoSectionRow>
              <RPPageTwoSectionRowLabel style={{ gridArea: 'row-label' }}>
                Association for the Advancement of AI (AAAI)
                <a
                  href="https://www.aaai.org/home.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaInfoCircle
                    style={{
                      paddingLeft: '0.5rem',
                      position: 'relative',
                      top: '3px',
                    }}
                    color="#ff9e08"
                  />
                </a>
                <span style={{ color: 'red' }}>*</span>
              </RPPageTwoSectionRowLabel>
              <RPPageTwoSectionRowOptions>
                <RPCheckbox
                  style={{ gridArea: 'NOT_APPLICABLE' }}
                  type="radio"
                  value={PROFICIENCY.NOT_APPLICABLE}
                  label=""
                  name={`ai_advancement`}
                  checked={
                    values['ai_advancement'] === PROFICIENCY.NOT_APPLICABLE
                  }
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <p>Not Applicable</p>
              </RPPageTwoSectionRowOptions>
              <RPPageTwoSectionRowOptions>
                <RPCheckbox
                  style={{ gridArea: PROFICIENCY.FAMILIAR }}
                  type="radio"
                  value={PROFICIENCY.FAMILIAR}
                  label=""
                  name={`ai_advancement`}
                  checked={values['ai_advancement'] === PROFICIENCY.FAMILIAR}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <p>Familiar</p>
              </RPPageTwoSectionRowOptions>
              <RPPageTwoSectionRowOptions>
                <RPCheckbox
                  style={{ gridArea: PROFICIENCY.PROFICIENT }}
                  type="radio"
                  value={PROFICIENCY.PROFICIENT}
                  label=""
                  name={`ai_advancement`}
                  checked={values['ai_advancement'] === PROFICIENCY.PROFICIENT}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <p>Proficient</p>
              </RPPageTwoSectionRowOptions>
              <RPPageTwoSectionRowOptions>
                <RPCheckbox
                  style={{ gridArea: PROFICIENCY.FLUENT }}
                  type="radio"
                  value={PROFICIENCY.FLUENT}
                  label=""
                  name={`ai_advancement`}
                  checked={values['ai_advancement'] === PROFICIENCY.FLUENT}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <p>Fluent</p>
              </RPPageTwoSectionRowOptions>
            </RPPageTwoSectionRow>
            <RPPageTwoSectionRow>
              <RPPageTwoSectionRowLabel style={{ gridArea: 'row-label' }}>
                UNESCO Ethics of AI
                <a
                  href="https://unesdoc.unesco.org/ark:/48223/pf0000381137"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaInfoCircle
                    style={{
                      paddingLeft: '0.5rem',
                      position: 'relative',
                      top: '3px',
                    }}
                    color="#ff9e08"
                  />
                </a>
                <span style={{ color: 'red' }}>*</span>
              </RPPageTwoSectionRowLabel>
              <RPPageTwoSectionRowOptions>
                <RPCheckbox
                  style={{ gridArea: 'NOT_APPLICABLE' }}
                  type="radio"
                  value={PROFICIENCY.NOT_APPLICABLE}
                  label=""
                  name={`ai_ethics`}
                  checked={values['ai_ethics'] === PROFICIENCY.NOT_APPLICABLE}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <p>Not Applicable</p>
              </RPPageTwoSectionRowOptions>
              <RPPageTwoSectionRowOptions>
                <RPCheckbox
                  style={{ gridArea: PROFICIENCY.FAMILIAR }}
                  type="radio"
                  value={PROFICIENCY.FAMILIAR}
                  label=""
                  name={`ai_ethics`}
                  checked={values['ai_ethics'] === PROFICIENCY.FAMILIAR}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <p>Familiar</p>
              </RPPageTwoSectionRowOptions>
              <RPPageTwoSectionRowOptions>
                <RPCheckbox
                  style={{ gridArea: PROFICIENCY.PROFICIENT }}
                  type="radio"
                  value={PROFICIENCY.PROFICIENT}
                  label=""
                  name={`ai_ethics`}
                  checked={values['ai_ethics'] === PROFICIENCY.PROFICIENT}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <p>Proficient</p>
              </RPPageTwoSectionRowOptions>
              <RPPageTwoSectionRowOptions>
                <RPCheckbox
                  style={{ gridArea: PROFICIENCY.FLUENT }}
                  type="radio"
                  value={PROFICIENCY.FLUENT}
                  label=""
                  name={`ai_ethics`}
                  checked={values['ai_ethics'] === PROFICIENCY.FLUENT}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <p>Fluent</p>
              </RPPageTwoSectionRowOptions>
            </RPPageTwoSectionRow>
            <RPPageTwoSectionRow>
              <RPPageTwoSectionRowLabel style={{ gridArea: 'row-label' }}>
                Others
              </RPPageTwoSectionRowLabel>
              <RPPageTwoSectionRowOptions>
                <RPCheckbox
                  style={{ gridArea: 'NOT_APPLICABLE' }}
                  type="radio"
                  value={PROFICIENCY.NOT_APPLICABLE}
                  label=""
                  name={`other_associations`}
                  checked={
                    values['other_associations'] === PROFICIENCY.NOT_APPLICABLE
                  }
                  onClick={e => {
                    setFieldValue('other_ethics', '');
                    if (
                      e.target.checked &&
                      e.target.value === PROFICIENCY.NOT_APPLICABLE
                    ) {
                      setFieldValue(e.target.name, '');
                    } else {
                      setFieldValue(e.target.name, e.target.value);
                    }
                  }}
                  onChange={e => {
                    setFieldValue(e.target.name, e.target.value);
                  }}
                  onBlur={handleBlur}
                />
                <p>Not Applicable</p>
              </RPPageTwoSectionRowOptions>
              <RPPageTwoSectionRowOptions>
                <RPCheckbox
                  style={{ gridArea: PROFICIENCY.FAMILIAR }}
                  type="radio"
                  value={PROFICIENCY.FAMILIAR}
                  label=""
                  name={`other_associations`}
                  checked={
                    values['other_associations'] === PROFICIENCY.FAMILIAR
                  }
                  onClick={e => {
                    setFieldValue('other_ethics', '');
                    if (
                      e.target.checked &&
                      e.target.value === PROFICIENCY.FAMILIAR
                    ) {
                      setFieldValue(e.target.name, '');
                    } else {
                      setFieldValue(e.target.name, e.target.value);
                    }
                  }}
                  onChange={e => {
                    setFieldValue(e.target.name, e.target.value);
                  }}
                  onBlur={handleBlur}
                />
                <p>Familiar</p>
              </RPPageTwoSectionRowOptions>
              <RPPageTwoSectionRowOptions>
                <RPCheckbox
                  style={{ gridArea: PROFICIENCY.PROFICIENT }}
                  type="radio"
                  value={PROFICIENCY.PROFICIENT}
                  label=""
                  name={`other_associations`}
                  checked={
                    values['other_associations'] === PROFICIENCY.PROFICIENT
                  }
                  onClick={e => {
                    setFieldValue('other_ethics', '');
                    if (
                      e.target.checked &&
                      e.target.value === PROFICIENCY.PROFICIENT
                    ) {
                      setFieldValue(e.target.name, '');
                    } else {
                      setFieldValue(e.target.name, e.target.value);
                    }
                  }}
                  onChange={e => {
                    setFieldValue(e.target.name, e.target.value);
                  }}
                  onBlur={handleBlur}
                />
                <p>Proficient</p>
              </RPPageTwoSectionRowOptions>
              <RPPageTwoSectionRowOptions>
                <RPCheckbox
                  style={{ gridArea: PROFICIENCY.FLUENT }}
                  type="radio"
                  value={PROFICIENCY.FLUENT}
                  label=""
                  name={`other_associations`}
                  checked={values['other_associations'] === PROFICIENCY.FLUENT}
                  onClick={e => {
                    setFieldValue('other_ethics', '');
                    if (
                      e.target.checked &&
                      e.target.value === PROFICIENCY.FLUENT
                    ) {
                      setFieldValue(e.target.name, '');
                    } else {
                      setFieldValue(e.target.name, e.target.value);
                    }
                  }}
                  onChange={e => {
                    setFieldValue(e.target.name, e.target.value);
                  }}
                  onBlur={handleBlur}
                />
                <p>Fluent</p>
              </RPPageTwoSectionRowOptions>
            </RPPageTwoSectionRow>
            <RPPageTwoSectionRow>
              <Row
                alignItems="flex-end"
                style={{ gridArea: 'row-label' }}
                ref={otherMenadate}
              >
                <Input
                  name={'other_ethics'}
                  value={values.other_ethics}
                  placeholder="Other"
                  onChange={handleChange}
                  onBlur={() => {}}
                />
                {isErrorNext &&
                  values?.other_associations &&
                  values?.other_associations !== PROFICIENCY?.NOT_APPLICABLE &&
                  !values?.other_ethics.trim() && (
                    <div
                      style={{
                        color: 'red',
                        marginTop: '4px',
                        fontSize: '14px',
                        textAlign: 'left',
                      }}
                    >
                      *This field is required
                    </div>
                  )}
              </Row>
            </RPPageTwoSectionRow>
          </RPPageTwoSection>
          <RPPageTwoSection>
            <RPPageTwoSectionHRow>
              <h6>Refer a Friend/Colleague to Big Robin </h6>
            </RPPageTwoSectionHRow>
            {generateReferFriendColleagueField()}
          </RPPageTwoSection>
        </RPPageTwoContainer>
      )}
      {currentStep === 2 && (
        <RPPageThreeContainer>
          <RPPageSectionTitle>
            Researcher Highlights
            <p
              style={{
                fontSize: '1.5rem',
                fontWeight: 'lighter',
                fontStyle: 'italic',
                textTransform: 'initial',
              }}
            >
              To help you stand out
            </p>
          </RPPageSectionTitle>
          <RPPageThreeSection>
            <RPPageThreeSectionRow>
              <div>
                <FileInput
                  name="research_highlight_logo"
                  label="Upload logo or key visual"
                  value={values.research_highlight_logo}
                  onChange={files => {
                    handleFileChange('research_highlight_logo', files);
                  }}
                  path="/rg"
                  multiple={false}
                />
              </div>
              <div ref={headingRef}>
                <Textarea
                  name="selling_line"
                  label="3 sentences selling line, e.g. Your Why, What you do, How you/your work is different"
                  maxCharacters={1000}
                  value={values.selling_line}
                  onChange={(e, newValue) => {
                    setFieldValue('selling_line', e.target.value);
                  }}
                  required
                  errorMessage={'*This field is required'}
                  error={verifyProfileHead && !values.selling_line.trim()}
                  // error={verifyProfileHead}
                  // errorMessage={'*This field is required'}
                />
              </div>
            </RPPageThreeSectionRow>
          </RPPageThreeSection>
          <RPPageThreeSection>
            <RPPageThreeSectionRow>
              <div>
                <FileInput
                  name={'research_highlights[' + 0 + '].attachments'}
                  label="Upload image of research highlight"
                  value={values.research_highlights[0].attachments}
                  isImage={true}
                  onChange={files => {
                    handleFileChange(
                      'research_highlights[' + 0 + '].attachments',
                      files
                    );
                  }}
                  path="/rhi"
                  multiple={false}
                />
              </div>
              <div ref={headingRef1}>
                <Textarea
                  style={{ gridArea: 'research_highlight_project1' }}
                  label={
                    <span>
                      Highlight Project #1<sup style={{ color: 'red' }}>*</sup>
                    </span>
                  }
                  name={'research_highlights[' + 0 + '].project'}
                  value={values.research_highlights[0].project}
                  onChange={e => {
                    setFieldValue(
                      'research_highlights[' + 0 + '].project',
                      e.target.value
                    );
                  }}
                  maxCharacters={50}
                  onBlur={handleBlur}
                  errorMessage={'*This field is required'}
                  error={
                    verifyProfileHead &&
                    !values?.research_highlights[0].project.trim()
                  }
                />
              </div>
            </RPPageThreeSectionRow>
            <RPPageThreeSectionRow>
              <div>
                <Textarea
                  label="Tags"
                  name="tags0"
                  value={values.research_highlights[0].tags}
                  onChange={e => {
                    setFieldValue(
                      'research_highlights[' + 0 + '].tags',
                      e.target.value
                    );
                  }}
                  maxCharacters={100}
                  onBlur={handleBlur}
                  placeholder={'Enter tags here'}
                />
              </div>
              <RPPageThreeSectionCol>
                <Textarea
                  name={'research_highlights[' + 0 + '].synopsis'}
                  label="30-Second Synopsis"
                  placeHolder={`Why this work was important\nHow did you approach the challenge\nWhat was the impact on the business`}
                  maxCharacters={1000}
                  value={values.research_highlights[0].synopsis}
                  onChange={handleChange}
                />
                {/* <Textarea
                  name={'research_highlights[' + 0 + '].context'}
                  label="Context, Action Results"
                  maxCharacters={1000}
                  value={values.research_highlights[0].context}
                  onChange={handleChange}
                /> */}
              </RPPageThreeSectionCol>
            </RPPageThreeSectionRow>
          </RPPageThreeSection>
          <RPPageThreeSection>
            <RPPageThreeSectionRow>
              <div>
                <FileInput
                  name={'research_highlights[' + 1 + '].attachments'}
                  label="Upload image of research highlight"
                  value={values.research_highlights[1].attachments}
                  isImage={true}
                  onChange={files => {
                    handleFileChange(
                      'research_highlights[' + 1 + '].attachments',
                      files
                    );
                  }}
                  path="/rhi"
                  multiple={false}
                />
              </div>
              <div>
                <Textarea
                  style={{ gridArea: 'research_highlight_project1' }}
                  label="Highlight Project #2"
                  name={'research_highlights[' + 1 + '].project'}
                  value={values.research_highlights[1].project}
                  onChange={e => {
                    setFieldValue(
                      'research_highlights[' + 1 + '].project',
                      e.target.value
                    );
                  }}
                  onBlur={handleBlur}
                  maxCharacters={50}
                />
              </div>
            </RPPageThreeSectionRow>
            <RPPageThreeSectionRow>
              <div>
                <Textarea
                  label="Tags"
                  name="tags1"
                  value={values.research_highlights[1].tags}
                  onChange={e => {
                    setFieldValue(
                      'research_highlights[' + 1 + '].tags',
                      e.target.value
                    );
                  }}
                  onBlur={handleBlur}
                  placeholder={'Enter tags here'}
                  maxCharacters={100}
                />
              </div>
              <RPPageThreeSectionCol>
                <Textarea
                  name={'research_highlights[' + 1 + '].synopsis'}
                  label="30-Second Synopsis"
                  placeHolder={`Why this work was important\nHow did you approach the challenge\nWhat was the impact on the business`}
                  maxCharacters={1000}
                  value={values.research_highlights[1].synopsis}
                  onChange={handleChange}
                />
                {/* <Textarea
                  name={'research_highlights[' + 1 + '].context'}
                  label="Context, Action Results"
                  maxCharacters={1000}
                  value={values.research_highlights[1].context}
                  onChange={handleChange}
                /> */}
              </RPPageThreeSectionCol>
            </RPPageThreeSectionRow>
          </RPPageThreeSection>
        </RPPageThreeContainer>
      )}
      <PageButtonsLayout>
        <PageButtonsGroup>
          {currentStep > 0 ? (
            <LeftButton
              onClick={() => {
                setCurrentStep(currentStep - 1);
                window.scrollTo(0, 0);
              }}
              backgroundColor="#65637b"
            >
              BACK
            </LeftButton>
          ) : null}
        </PageButtonsGroup>

        <PageButtonsGroup>
          <RightButton outline onClick={profilePage}>
            PREVIEW
          </RightButton>
          <RightButton
            outline
            onClick={() => {
              setUpdateSave(true);
              setFieldValue('is_final_submission', false);
              handleSubmit();
            }}
          >
            SAVE FOR LATER
          </RightButton>
          {currentStep < 2 ? (
            <RightButton
              onClick={async () => {
                console.log(values);
                const researchTech =
                  Object.keys(values?.research_technical_mastery).length > 0
                    ? Object.keys(values?.research_technical_mastery)
                    : [];
                const analyticsTech =
                  Object.keys(values?.research_analytics_mastery).length > 0
                    ? Object.keys(values?.research_analytics_mastery)
                    : [];
                const dataTech =
                  Object.keys(values?.data_software_skills).length > 0
                    ? Object.keys(values?.data_software_skills)
                    : [];
                const researcherML =
                  Object.keys(values?.researcher_ml_operations).length > 0
                    ? Object.keys(values?.researcher_ml_operations)
                    : [];
                const assoctiate =
                  // values?.code_of_ethics === '' ||
                  values?.insights_association === '' ||
                  values?.market_association_society === '' ||
                  values?.ai_advancement === '' ||
                  values?.ai_ethics === '';

                const referredPeople = values?.fortune_500_experience?.every(
                  item => item.company && item.years_worked
                );
                let rheObj = values?.relevant_higher_education;
                let rheItems = [];
                for (let i in rheObj) {
                  if (
                    rheObj[i][0]['checked'] === true &&
                    rheObj[i][0]['major_area_of_study'].trim() &&
                    rheObj[i][0]['academic_institution'].trim()
                  ) {
                    rheObj[i].forEach(item => {
                      rheItems.push({
                        degree: i,
                        major: item.major_area_of_study,
                        academic_institution: item.academic_institution,
                      });
                    });
                  }
                }
                const rheEdu = rheItems?.every(
                  item => item.academic_institution && item.major
                );
                if (
                  currentStep === 0 &&
                  (!values.first_name.trim() ||
                    !values.contact_number ||
                    !values.postal_code ||
                    !values.country ||
                    !props?.countryList.includes(values?.country.trim()) ||
                    !values.state ||
                    !values.city ||
                    !values.address ||
                    !values.borderless_handle ||
                    !values.industry_expertise.length > 0 ||
                    !values.services_offered.length > 0 ||
                    !values.business_specialization.length > 0 ||
                    !values.scope_of_services.length > 0 ||
                    !values.preferred_languages.length > 0 ||
                    !values?.is_nda_approve ||
                    !image?.preview ||
                    !values?.minimum_engagement)
                ) {
                  setIsError(true);
                  if (!values.first_name.trim()) {
                    firstNameRef.current.scrollIntoView({ behavior: 'smooth' });
                  } else if (!values.address.trim()) {
                    addressRef.current.scrollIntoView({
                      behavior: 'smooth',
                    });
                  } else if (!values.city.trim()) {
                    cityRef.current.scrollIntoView({
                      behavior: 'smooth',
                    });
                  } else if (!values.state.trim()) {
                    stateRef.current.scrollIntoView({
                      behavior: 'smooth',
                    });
                  } else if (!values.postal_code.trim()) {
                    postalRef.current.scrollIntoView({
                      behavior: 'smooth',
                    });
                  } else if (
                    !values.country.trim() ||
                    !props?.countryList.includes(values?.country.trim())
                  ) {
                    countryRef.current.scrollIntoView({
                      behavior: 'smooth',
                    });
                  } else if (!values.contact_number.trim()) {
                    contactRef.current.scrollIntoView({
                      behavior: 'smooth',
                    });
                  } else if (!image?.preview) {
                    userProfileRef.current.scrollIntoView({
                      behavior: 'smooth',
                    });
                  } else if (!values.is_nda_approve) {
                    titleRef.current.scrollIntoView({
                      behavior: 'smooth',
                    });
                  } else if (!values.borderless_handle.trim()) {
                    borderlessRef.current.scrollIntoView({
                      behavior: 'smooth',
                    });
                  } else if (!values.industry_expertise.length > 0) {
                    industryRef.current.scrollIntoView({
                      behavior: 'smooth',
                    });
                  } else if (!values.services_offered.length > 0) {
                    serviceRef.current.scrollIntoView({
                      behavior: 'smooth',
                    });
                  } else if (!values.business_specialization.length > 0) {
                    businessRef.current.scrollIntoView({
                      behavior: 'smooth',
                    });
                  } else if (!values.scope_of_services.length > 0) {
                    scopeRef.current.scrollIntoView({
                      behavior: 'smooth',
                    });
                  } else if (!values?.minimum_engagement) {
                    minEngRef.current.scrollIntoView({
                      behavior: 'smooth',
                    });
                  } else if (!values.preferred_languages.length > 0) {
                    langRef.current.scrollIntoView({
                      behavior: 'smooth',
                    });
                  }

                  return false;
                } else if (
                  currentStep === 0 &&
                  values.linked_in_url?.length === 0 &&
                  values.proof_of_employment?.length === 0
                ) {
                  setIsErrorShowingModal(
                    `LinkedIn Url OR Employment Proof is required field`
                  );
                  setverifyIdentity(true);
                  profileRef.current.scrollIntoView({
                    behavior: 'smooth',
                  });
                  return false;
                }
                // data for next page
                else if (
                  currentStep === 1 &&
                  (researchTech?.length < 12 ||
                    researcherML?.length < 8 ||
                    analyticsTech?.length < 12 ||
                    dataTech?.length < 17 ||
                    assoctiate ||
                    !values.years_of_experience ||
                    values?.sizes_of_businesses_worked?.length === 0 ||
                    !values.practice_maturity ||
                    !rheEdu ||
                    rheItems?.length === 0 ||
                    !referredPeople ||
                    (values?.other_associations &&
                      values?.other_associations !==
                        PROFICIENCY?.NOT_APPLICABLE &&
                      !values?.other_ethics.trim()))
                ) {
                  // if () {

                  // } else {
                  //   setIsErrorEducation(false);
                  // }
                  // if (!referredPeople) {
                  //   setIsErrorWork(true);
                  //   workExp.current.scrollIntoView({
                  //     behavior: 'smooth',
                  //   });
                  // } else {
                  //   setIsErrorWork(false);
                  // }
                  setIsErrorNext(true);
                  if (researchTech?.length < 12) {
                    researchTechinal.current.scrollIntoView({
                      behavior: 'smooth',
                    });
                  } else if (analyticsTech?.length < 12) {
                    analyticsTechinal.current.scrollIntoView({
                      behavior: 'smooth',
                    });
                  } else if (dataTech?.length < 17) {
                    codeEthics.current.scrollIntoView({
                      behavior: 'smooth',
                    });
                  } else if (researcherML?.length < 8) {
                    researchMLRef.current.scrollIntoView({
                      behavior: 'smooth',
                    });
                  } else if (!values.practice_maturity) {
                    emailAddress.current.scrollIntoView({
                      behavior: 'smooth',
                    });
                  } else if (!values.years_of_experience) {
                    yearExp.current.scrollIntoView({
                      behavior: 'smooth',
                    });
                  } else if (!rheEdu || rheItems?.length === 0) {
                    setIsErrorEducation(true);
                    relevantEdu.current.scrollIntoView({
                      behavior: 'smooth',
                    });
                  } else if (!referredPeople) {
                    setIsErrorWork(true);
                    workExp.current.scrollIntoView({
                      behavior: 'smooth',
                    });
                  } else if (values?.sizes_of_businesses_worked?.length === 0) {
                    businessSize.current.scrollIntoView({
                      behavior: 'smooth',
                    });
                  } else if (assoctiate) {
                    referredList.current.scrollIntoView({
                      behavior: 'smooth',
                    });
                  } else if (
                    values?.other_associations &&
                    values?.other_associations !==
                      PROFICIENCY?.NOT_APPLICABLE &&
                    !values?.other_ethics.trim()
                  ) {
                    otherMenadate.current.scrollIntoView({
                      behavior: 'smooth',
                    });
                  }
                  return false;
                  //here
                } else {
                  setFieldValue('is_final_submission', false);
                  setIsErrorEducation(false);
                  setIsErrorWork(false);
                  setIsError(false);
                  setIsErrorNext(false);
                  if (
                    currentStep === 0 &&
                    values?.borderless_handle &&
                    user?.member?.borderless_handle !==
                      values?.borderless_handle
                  ) {
                    const res = await ajax('POST', '/validate-handle', {
                      handle: values?.borderless_handle,
                    });
                    // .then(res => {
                    if (res.code === 200) {
                      setIsHandleErrorMessage(false);
                      setHandleErrorMessage('');
                      setHandleEmail(res?.data?.registered_email);
                      if (
                        currentStep === 0 &&
                        user?.member?.borderless_handle !==
                          values?.borderless_handle
                      ) {
                        setHasChangeHandle(true);
                      }
                    } else {
                      setHandleErrorMessage(res.message);
                      setIsHandleErrorMessage(true);
                      setHandleEmail('');
                      return false;
                    }
                  } else {
                    saveForNext();
                  }
                  window.scrollTo(0, 0);
                }
              }}
            >
              NEXT
            </RightButton>
          ) : (
            <RightButton
              onClick={() => {
                setIsStripe(false);
                setFieldValue('is_final_submission', true);

                if (!values?.selling_line.trim()) {
                  setverifyProfileHead(true);
                  headingRef.current.scrollIntoView({
                    behavior: 'smooth',
                  });
                  return false;
                } else if (!values?.research_highlights[0].project.trim()) {
                  setverifyProfileHead(true);
                  headingRef1.current.scrollIntoView({
                    behavior: 'smooth',
                  });
                  return false;
                } else {
                  setverifyProfileHead(false);
                  setSubmitPopup(true);
                }
              }}
            >
              {isLoading ? (
                <>
                  <FaSpinner className="spin" />
                  SUBMITTING...
                </>
              ) : (
                'Proceed'
              )}
            </RightButton>
          )}
        </PageButtonsGroup>
      </PageButtonsLayout>
      <Modal visible={!!isShowingModal} setVisible={setIsShowingModal}>
        <div
          style={{
            backgroundColor: 'white',
            padding: '2rem',
            flexDirection: 'column',
            justifyContent: 'flex-end',
          }}
        >
          <div style={{ marginBottom: '1rem', textAlign: 'center' }}>
            {isShowingModal}
          </div>

          <Button
            fill
            onClick={() => setIsShowingModal(null)}
            style={{ marginBottom: '1rem' }}
          >
            OK
          </Button>

          <Button fill onClick={profilePage}>
            VIEW MY PROFILE
          </Button>
        </div>
      </Modal>
      <Modal
        visible={!!isErrorShowingModal}
        setVisible={setIsErrorShowingModal}
      >
        <div
          style={{
            backgroundColor: 'white',
            padding: '2rem',
            flexDirection: 'column',
            justifyContent: 'flex-end',
          }}
        >
          <div className="msg-wrapper" style={{ marginBottom: '1rem' }}>
            {isErrorShowingModal}
          </div>

          <Button
            fill
            onClick={() => setIsErrorShowingModal(null)}
            style={{ marginBottom: '1rem' }}
          >
            OK
          </Button>
        </div>
      </Modal>
      <SessionModal
        isOpen={isOpen}
        setModalVisible={setIsOpen}
        message={'Your session has expired.'}
      />
      <Modal
        visible={isShowingModalProfile}
        setVisible={setIsShowingModalProfile}
        persistent
      >
        <div
          style={{
            backgroundColor: 'white',
            padding: '2rem',
            flexDirection: 'column',
            justifyContent: 'flex-end',
            maxWidth: `${'300px'}`,
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        >
          <div
            style={{
              marginBottom: '20px',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <b style={{ textAlign: 'center' }}>
              In order to proceed, please complete your profile first.
            </b>
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              marginTop: '30px',
            }}
          >
            <Button
              outline
              style={{ marginRight: '8px', width: '100px' }}
              onClick={() => {
                setIsShowingModalProfile(false);
              }}
            >
              Close
            </Button>
          </div>
        </div>
      </Modal>
      <Modal
        visible={isHandleErrorMessage}
        setVisible={setIsHandleErrorMessage}
        persistent
      >
        <div
          style={{
            backgroundColor: 'white',
            padding: '2rem',
            flexDirection: 'column',
            justifyContent: 'flex-end',
            maxWidth: `${'300px'}`,
            marginLeft: 'auto',
            marginRight: 'auto',
            borderRadius: '12px',
          }}
        >
          <div style={{ padding: '0px 10px 5px 10px', textAlign: 'center' }}>
            <MdWarning size={'20px'} color={'#ff9e08'} />
          </div>
          <div
            style={{
              marginBottom: '20px',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <b style={{ textAlign: 'center' }}>{handleErrorMessage}</b>
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              marginTop: '30px',
            }}
          >
            <Button
              outline
              style={{ marginRight: '8px', width: '100px' }}
              onClick={() => {
                borderlessRef.current.scrollIntoView({
                  behavior: 'smooth',
                });
                setIsHandleErrorMessage(false);
              }}
            >
              Close
            </Button>
          </div>
        </div>
      </Modal>
      <Modal
        visible={hasChangeHandle}
        setVisible={setHasChangeHandle}
        persistent
      >
        <div
          style={{
            backgroundColor: 'white',
            padding: '2rem',
            flexDirection: 'column',
            justifyContent: 'flex-end',
            maxWidth: `${'300px'}`,
            marginLeft: 'auto',
            marginRight: 'auto',
            borderRadius: '12px',
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginBottom: '20px',
              fontSize: '2rem',
              fontWeight: '700',
              color: '#000000',
              // maxWidth: '90%',
            }}
          >
            Confirm your handle
          </div>

          <div
            style={{
              marginBottom: '10px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              fontSize: '14px',
            }}
          >
            <div style={{ textAlign: 'start' }}>
              Please re-confirm the Borderless details
            </div>
            <div style={{ textAlign: 'start', marginTop: '10px' }}>
              1. Your handle -
              <span
                style={{
                  color: 'blue',
                  marginTop: '5px',
                  textAlign: 'center',
                  overflowWrap: 'break-word',
                }}
              >
                "{values?.borderless_handle || ''}"
              </span>
            </div>
            <div style={{ textAlign: 'start' }}>
              2. Your registered email -
              <span
                style={{
                  color: 'blue',
                  marginTop: '5px',
                  textAlign: 'center',
                  overflowWrap: 'break-word',
                }}
              >
                "{handleEmail || ''}"
              </span>
            </div>

            <div style={{ textAlign: 'start', marginTop: '10px' }}>
              All Big Robin payouts to you will be received in the account
              associated with this handle.
            </div>
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              marginTop: '30px',
            }}
          >
            <Button
              outline
              style={{
                marginRight: '12px',
                width: '100px',
              }}
              onClick={() => {
                setHasChangeHandle(false);
                borderlessRef.current.scrollIntoView({
                  behavior: 'smooth',
                });
              }}
            >
              Cancel
            </Button>

            <Button
              fill
              style={{ width: '100px' }}
              onClick={() => {
                saveForNext();
                setHasChangeHandle(false);
              }}
            >
              Confirm
            </Button>
          </div>
        </div>
      </Modal>
      {isProfileCropModal && (
        <ProfileCrop
          visible={isProfileCropModal}
          setVisible={setIsProfileCropModal}
          imageBlob={profileImageToCrop}
          setImage={setImage}
        />
      )}
      <SubmitConfirmPopup
        visible={submitPopup}
        setVisible={setSubmitPopup}
        handleSubmitButton={handleSubmit}
      />
      <ForgotHandlePopup
        visible={forgotHandlePopup}
        setVisible={setForgotHandlePopup}
        handleSubmitButton={handleForgotPopup}
      />
    </ProfileContent>
  );
};
