import { Row } from 'components/Layout';
import React, { useState } from 'react';
import { FaTimes } from 'react-icons/fa';
import Searchbar from 'components/Searchbar';
import {
  ChipContainer,
  InputContainer,
  InputLayout,
  LabelLayout,
} from './styled';

export const ShareChipsInput = ({
  name,
  label,
  className,
  error,
  errorMessage,
  isLink,
  leftLabel,
  rightLabel,
  focusColor,
  style,
  value,
  onChange,
  shareTo,
  setShareTo,
  setSearchResearcher,
  handleSearch,
  searchResearcher,
  ...inputProps
}) => {
  // const [inputValue, setInputValue] = useState('');
  const [isFocused, setIsFocused] = useState(false);

  // const addChip = useCallback(() => {
  //   if (inputValue) {
  //     const exists = value.find(v => v === inputValue);
  //     if (!exists) {
  //       onChange([...value, inputValue]);
  //       setInputValue('');
  //     }
  //   }
  // }, [inputValue, onChange, value]);

  return (
    <InputContainer className={className} style={style}>
      <LabelLayout>
        <label htmlFor={name}>{label}</label>
      </LabelLayout>

      <Row data-focused={isFocused} flexWrap="wrap" justifyContent="flex-start">
        {Object.keys(shareTo).map(id => {
          return (
            <ChipContainer key={id} style={{ maxWidth: 'fit-content' }}>
              {shareTo[id]}{' '}
              <FaTimes
                onClick={() => {
                  setShareTo(shareTo => {
                    const share = { ...shareTo };
                    delete share[id];
                    return share;
                  });
                }}
              />
            </ChipContainer>
          );
        })}
        {/* {value.map((v, index) => {
          return (
            <ChipContainer key={`${v}-${index}`}>
              {v}
              <FaTimes
                onClick={() => {
                  onChange(value.filter(_v => v !== _v));
                }}
              />
            </ChipContainer>
          );
        })} */}
        <InputLayout
          borderColor={error ? '#ef5350' : isFocused ? focusColor : undefined}
        >
          <Searchbar
            icon={false}
            onChange={text => {
              const trimmedText = text ? text.trim() : '';
              setSearchResearcher(trimmedText);
            }}
            onKeyPress={e => {
              if (e === 'Enter') {
                handleSearch(searchResearcher);
              }
            }}
            {...inputProps}
          />
        </InputLayout>
      </Row>
      {error && <p>{error}</p>}
    </InputContainer>
  );
};

export default ShareChipsInput;
