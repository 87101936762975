import Button from 'components/Button';
import Modal from 'components/Modal';
import React, { useRef, useState } from 'react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

const ProfileCrop = ({ visible, setVisible, setImage, imageBlob }) => {
  const imgRef = useRef(null);
  const prevRef = useRef(null);
  const [completedCrop, setCompletedCrop] = useState(null);
  const [crop, setCrop] = useState({
    unit: 'px',
    x: 0,
    y: 0,
    width: 40,
    height: 40,
  });
  const [resultImage, setResultImage] = useState(null);

  const onImageLoaded = image => {
    if (!image) console.error();
  };

  const getBlobFromUrl = async blobUrl => {
    return fetch(blobUrl)
      .then(response => response.blob())
      .then(blob => {
        const reader = new FileReader();
        return new Promise((resolve, reject) => {
          const filename = `${new Date().getTime()}.jpeg`;
          reader.onload = () => {
            const blobData = new Blob([reader.result], {
              type: blob.type,
              name: filename,
            });

            const newFile = new File([blobData], filename, {
              type: blobData.type,
            });

            resolve(newFile);
          };
          reader.onerror = () => {
            reader.abort();
            reject(new Error('Failed to read Blob data'));
          };
          reader.readAsArrayBuffer(blob);
        });
      });
  };
  const handleDone = async () => {
    try {
      const [result] = await Promise.all([getBlobFromUrl(resultImage)]);

      setImage({
        preview: URL.createObjectURL(result),
        raw: result,
        key: '',
        filename: 'profile_pic',
        new_upload: true,
      });

      setResultImage(null);
      setVisible(false);
      // do something with the result
    } catch (error) {
      console.error('There was a problem fetching the files:', error);
    }
  };

  async function canvasPreviewData() {
    let image = imgRef.current;
    let canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;

    const ctx = canvas.getContext('2d');

    const pixelRatio = window.devicePixelRatio;
    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;
    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = 'high';

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    ctx.save();

    ctx.restore();

    const base64Image = canvas.toDataURL('image/jpeg');
    setResultImage(base64Image);
  }
  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, {
      type: mime,
    });
  }
  return (
    <Modal visible={visible} setVisible={setVisible} persistent>
      <div
        style={{
          backgroundColor: 'white',
          padding: '2rem',
          flexDirection: 'column',
          justifyContent: 'flex-end',
          maxWidth: `${'600px'}`,
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
      >
        <div
          style={{
            marginBottom: '20px',
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            // maxWidth: '600px'
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              maxHeight: '80vh',
            }}
          >
            {resultImage ? (
              <div>
                <img
                  style={{
                    border: '2px dashed black',
                    borderRadius: '8px',
                    maxHeight: '80vh',

                    width: '100%',
                  }}
                  alt="profile"
                  ref={prevRef}
                  src={resultImage}
                />
              </div>
            ) : (
              <ReactCrop
                // className="react-Image-crop"
                src={imageBlob}
                aspect={1}
                onImageLoaded={onImageLoaded}
                // locked
                onComplete={c => setCompletedCrop(c)}
                ruleOfThirds
                crop={crop}
                onChange={c => setCrop(c)}
                style={{
                  borderRadius: '8px',
                  maxHeight: '80vh',
                }}
              >
                <img ref={imgRef} src={imageBlob} />
              </ReactCrop>
            )}
          </div>
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            marginTop: '30px',
          }}
        >
          <Button
            outline
            style={{ marginRight: '8px', width: '100px' }}
            onClick={() => {
              setVisible(false);
            }}
          >
            Close
          </Button>
          {resultImage ? (
            <Button
              //   outline
              style={{ marginRight: '8px', width: '100px' }}
              onClick={handleDone}
            >
              Done
            </Button>
          ) : (
            <Button
              //   outline
              style={{ marginRight: '8px', width: '100px' }}
              onClick={canvasPreviewData}
            >
              Crop
            </Button>
          )}
        </div>
      </div>
    </Modal>
  );
};
export default ProfileCrop;
