import React from 'react';
import { PageTitle, PageContainer, PageLabel, PlanContainer } from './styled';
import PlanCard from 'components/PlanCard';
import ReactTooltip from 'react-tooltip';

export const MembershipPlanFees = props => {
  return (
    <>
      <PageTitle>Membership Plan</PageTitle>
      <PageContainer>
        <PageLabel>
          Below are the membership plans before proceeding with a project.
          <a
            data-tip
            data-event="mouseenter click"
            data-for="feesWhy"
            style={{
              color: '#ff9e08',
              cursor: 'pointer',
              marginLeft: '10px',
            }}
            href={() => false}
          >
            Why?
          </a>
          <ReactTooltip globalEventOff="mouseout" id="feesWhy">
            <span>
              We all don’t have time to waste! Your non-refundable membership
              gives you elite access to only the best researchers in the world.
              <br />
              And, it lets researchers know you are seriously invested in a
              mutually beneficial collaboration.
            </span>
          </ReactTooltip>
        </PageLabel>
        <PlanContainer>
          {props.plans.map(plan => {
            return (
              <PlanCard
                title={plan.title}
                amount={plan.amount}
                unit={plan.unit}
                type={plan.type}
                description={plan.description}
                selected={
                  props.selectedPlan && props.selectedPlan.id === plan.id
                    ? true
                    : false
                }
              />
            );
          })}
        </PlanContainer>
      </PageContainer>
    </>
  );
};
