import React from 'react';
import Modal from 'components/Modal';
import {
  logout,
  MEMBER_TYPES,
  useAuthDispatch,
  useAuthState,
} from 'context/AuthContext';
import Button from 'components/Button';
import { useHistory } from 'react-router-dom';

const SessionModal = ({ isOpen, setModalVisible, message }) => {
  const authDispatch = useAuthDispatch();
  const { user } = useAuthState();
  const history = useHistory();

  return (
    <Modal visible={!!isOpen} setVisible={setModalVisible} persistent>
      <div
        style={{
          backgroundColor: 'white',
          padding: '2rem',
          flexDirection: 'column',
          justifyContent: 'flex-end',
        }}
      >
        <div style={{ marginBottom: '1rem' }}>{message}</div>

        {}
        <Button
          fill
          // Background of the button is Gray if researcher and Orange if Business
          backgroundColor={
            user?.member.member_type_id === +MEMBER_TYPES.MEMBER
              ? '#ff9e08'
              : '#4B4C5E'
          }
          onClick={() => {
            setModalVisible(false);
            logout(authDispatch);
            history.push({
              pathname: '/login',
              state: { isSignup: false, isResearcher: false },
            });
          }}
        >
          OK
        </Button>
      </div>
    </Modal>
  );
};

export default SessionModal;
