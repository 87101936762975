import Button from 'components/Button';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { PageContent } from './styled';
import { useLocation } from 'react-router-dom';

export const PaymentStatus = () => {
  const history = useHistory();
  const searchParams = new URLSearchParams(document.location.search);
  const isMembership = searchParams.get('isMembership') === 'true' || false;

  const continueToMessages = () => {
    history.push('/my-projects');
  };

  // const searchParams = new URLSearchParams();
  // console.log(searchParams.getAll('topic'));

  return (
    <PageContent>
      <div>
        <h1 style={{ fontSize: '3rem', marginBottom: 26 }}>
          Your payment is in process.
        </h1>
        <div style={{ fontSize: '1.8rem' }}>
          Transaction ID:{' '}
          <span style={{ fontWeight: 'bold' }}>
            "{searchParams.get('transactionId') || '-'}"
          </span>
        </div>
        <br />
        <div style={{ fontSize: '1.8rem', marginBottom: 20 }}>
          You'll receive a mail on completion of the payment. It may take up to
          3-5 business days. <br />
          For any queries, write us on{' '}
          <a
            href="mailto:support@bigrobin.io"
            rel={'noopener noreferrer'}
            target={'_blank'}
          >
            support@bigrobin.io
          </a>{' '}
          with your respective transaction ID.
        </div>
      </div>
      {isMembership ? (
        <Button
          fill
          onClick={() => history.push('/welcome')}
          style={{ width: 300, marginTop: '20px' }}
        >
          Go to Home
        </Button>
      ) : (
        <Button
          fill
          onClick={() => continueToMessages()}
          style={{ width: 300, marginTop: '20px' }}
        >
          Go back to projects
        </Button>
      )}
    </PageContent>
  );
};
