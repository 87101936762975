import styled, { css } from 'styled-components/macro';

export const PlanContainer = styled.div`
  margin-right: 2rem;
  margin-bottom: 2rem;
  box-shadow: 0px 3px 6px #00000029;

  ${props =>
    props.selected &&
    css`
       {
        box-shadow: 0px 3px 25px #ff9e0854;
      }
    `};
`;

export const PlanMainContent = styled.div`
  padding: 2rem 4rem;
`;

export const PlanTitle = styled.div`
  font-size: 1.65rem;
  letter-spacing: 0px;
  color: #4b4c5e;
  text-align: center;
  margin-bottom: 2rem;
`;

export const PlanLabel = styled.div`
  display: flex;
  padding-left: 3rem;
  margin-bottom: 2rem;
`;

export const PlanAmount = styled.div`
  letter-spacing: 0px;
  color: #4b4c5e;
  font-size: 5rem;
`;

export const PlanType = styled.div`
  letter-spacing: 0px;
  color: #4b4c5b;
  font-size: 1.65rem;
  padding-top: 3.6rem;
  padding-left: 0.6rem;
`;

export const PlanDescription = styled.div`
  letter-spacing: 0px;
  color: #65637b;
  font-size: 1.25rem;
`;

export const CurrentPlanLabel = styled.div`
  position: absolute;
  top: 14px;
  right: 0;
  background: #4b4c5e;
  color: white;
  padding: 10px;
  text-transform: uppercase;
  font-size: 12px;
  border-radius: 12px 0px 0px 12px;

  @media (max-width: 600px) {
    font-size: 9px;
    padding: 8px;
    top: 8px;
  }
`;

export const RecommendedPlanLabel = styled.div`
  position: absolute;
  top: 14px;
  right: 0;
  background: rgb(255, 158, 8);
  color: white;
  padding: 10px;
  text-transform: uppercase;
  font-size: 12px;
  border-radius: 12px 0px 0px 12px;

  @media (max-width: 600px) {
    font-size: 9px;
    padding: 8px;
    top: 8px;
  }
`;
