import React, {
  useState,
  // Fragment,
  useEffect,
} from 'react';
import {
  PageContent,
  // BreadcrumbEnd,
  // BreadcrumbLayout,
  // BreadcrumbLink,
  // BreadcrumbMiddle,
  ModalContent,
  ModalMsg,
  ModalButton,
} from './styled';
import { useParams } from 'react-router-dom';
import { MembershipPlan } from 'pages/MembeshipPlan';
// import { FaChevronRight } from 'react-icons/fa';
import { ajax } from 'utils/ajax';
import LoadingIndicator from 'components/LoadingIndicator';
import Modal from 'components/Modal';
import { useHistory } from 'react-router-dom';
import Button from 'components/Button';
import SessionModal from 'components/SessionModal';
import { useAuthDispatch, useAuthState } from 'context/AuthContext';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
// import { Payment } from 'pages/Payment';
import { Payment } from 'pages/StripePayment';
import moment from 'moment';

export const MemberSubscription = () => {
  const [selectedPlan, setSelectedPlan] = useState({});
  const params = useParams();
  const [currentPlan, setCurrentPlan] = useState(null);
  const [nextRenewDate, setNextRenewDate] = useState(null);
  const [paymentProgress, setPaymentProgress] = useState(false);
  const [confirmNextPlan, setConfirmNextPlan] = useState(false);
  const [confirmMaxPlan, setConfirmMaxPlan] = useState(false);
  const [confirmCancelPlan, setConfirmCancelPlan] = useState(false);
  const [subscriptionId, setSubscriptionId] = useState(null);
  const [recommendedPlan, setRecommendedPlan] = useState(1);
  const [currentStep, setCurrentStep] = useState(1);
  const [plans, setPlans] = useState([]);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [isFreeMembershipPlan, setIsFreeMembershipPlan] = useState(false);
  const authDispatch = useAuthDispatch();

  const [isModalOpen, setModalOpen] = useState(false);
  const [isModalOpenCong, setModalOpenCong] = useState(false);
  const [modalMsg, setModalMsg] = useState('');
  // const [isSuccess, setSuccess] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isSubsPop, setIsSubsPop] = useState(false);
  const [errorMembership, setErrorMembership] = useState(false);
  const user_id = parseInt(localStorage.getItem('br-userId'));
  const { user } = useAuthState();

  const [stripePromise, setStripePromise] = useState(null);
  const [clientStripeId, setClientStripeId] = useState(null);
  const [paymentId, setPaymentId] = useState(null);
  const [paymentStatusData, setPaymentStatusData] = useState({});
  const [membershipId, setMembershipId] = useState(null);

  const [isTrialUsed, setIsTrialUsed] = useState(true);
  const [nextPlanData, setNextPlanData] = useState({});
  const [trialConfirm, setTrialConfirm] = useState(false);
  const [changePlanData, setChangePlanData] = useState([]);

  const subFun = () => {
    setLoading(true);
    const url = '/subscription';
    ajax('GET', url).then(result => {
      setLoading(false);
      if (result.code === 200 && result.data && result.data.length > 0) {
        let res = result.data.map((plan, index) => {
          let plan_id = '';
          switch (plan.amount) {
            case 99:
              plan_id =
                process.env.REACT_APP_ENV === 'local' ||
                process.env.REACT_APP_ENV === 'dev' ||
                process.env.REACT_APP_ENV === 'stg'
                  ? 'price_1MnjbKG7008gCL3GzmDlUu6n' // test
                  : 'price_1N8pVNG7008gCL3GJkZ3SURR'; //live

              break;
            case 499:
              plan_id =
                process.env.REACT_APP_ENV === 'local' ||
                process.env.REACT_APP_ENV === 'dev' ||
                process.env.REACT_APP_ENV === 'stg'
                  ? 'price_1MnjbKG7008gCL3G07lgOII2' //test
                  : 'price_1N8pVNG7008gCL3Gz7lTedIj'; //live

              break;
            case 999:
              plan_id =
                process.env.REACT_APP_ENV === 'local' ||
                process.env.REACT_APP_ENV === 'dev' ||
                process.env.REACT_APP_ENV === 'stg'
                  ? 'price_1MnjbKG7008gCL3GTVJbrqDW' //test
                  : 'price_1N8pVNG7008gCL3GKV4IIRT4'; //live

              break;
            default:
              plan_id = '';
          }
          return {
            ...plan,
            unit: '$',
            currency: 'usd',
            type: plan.billing_period,
            plan_id: plan_id,
          };
        });
        res = res.filter(
          elem => elem.status && elem.status.toLowerCase() === 'active'
        );
        res = res.sort((a, b) => a.amount - b.amount);
        setPlans(res);
      } else if (result.code === 401) {
        setIsOpen(true);
      }
    });
  };
  useEffect(() => {
    subFun();
  }, [params.id]);

  useEffect(() => {
    setStripePromise(
      loadStripe(process.env.REACT_APP_RBN_STRIPE_API_KEY_US, {
        //  stripeAccount: researcher.stripe_account_id,
      })
    );
  }, []);

  const options = {
    clientSecret: clientStripeId,
    locale: 'en-US',
    appearance: {
      theme: 'stripe',
    },
  };

  const promoCodeSubscription = async (amount = 0, promo_code = '') => {
    setLoading(true);

    const res = await ajax('POST', '/create-subscription', {
      promo_code: promo_code,
      plan_id: selectedPlan?.plan_id,
      can_get_trial: false,
      member_subscription_id: membershipId,
      amount,
    });
    if (res.code === 200) {
      const subs_url =
        '/member/' + user_id + '/subscription/' + selectedPlan.id;
      const preMembership = await ajax('POST', subs_url, {
        member_subscription_id: res?.data?.subscription_id,
        is_free_trial: false,
      });
      if (preMembership.code === 200) {
        setLoading(false);
        authDispatch({
          type: 'MEMBER_SUBSCRIBED',
          payload: { is_subscribed: true },
        });

        window.location.reload();
        setIsSubsPop(true);
      } else {
        setErrorMembership(true);
        setLoading(false);
      }
    } else {
      setErrorMembership(true);
      setLoading(false);

      // setModalMsg(res.message);
      // setModalOpen(true);
    }
  };

  const handleCancelSubscription = async () => {
    const cancel_sub = await ajax(
      'DELETE',
      `/member/${user.member.id}/subscription/${subscriptionId}`
    );
    if (cancel_sub.code === 200) {
      window.location.reload();
    }
  };

  const continueToPayment = async step => {
    setLoading(true);
    const res = await ajax('POST', '/create-subscription', {
      // amount: Number(selectedPlan?.amount),
      plan_id: selectedPlan?.plan_id,
      can_get_trial: false,
      member_subscription_id: membershipId,
    });
    if (res.code === 200) {
      setMembershipId(res?.data?.subscription_id);
      setClientStripeId(res?.data?.client_secret);
      setPaymentId(res?.data?.payment_id);
      const subs_url =
        '/member/' + user_id + '/subscription/' + selectedPlan.id;
      const preMembership = await ajax('POST', subs_url, {
        member_subscription_id: res?.data?.subscription_id,
        is_free_trial: false,
      });
      if (preMembership.code === 200) {
        setLoading(false);
        setCurrentStep(step);
      } else {
        setErrorMembership(true);
        setLoading(false);
      }
    } else {
      setErrorMembership(true);
      setLoading(false);
    }
  };

  const getFreeTrial = async () => {
    setLoading(true);
    const res = await ajax('POST', '/create-subscription', {
      // amount: Number(selectedPlan?.amount),
      plan_id: selectedPlan?.plan_id,
      can_get_trial: true,
      member_subscription_id: membershipId,
    });
    if (res.code === 200) {
      setMembershipId(res?.data?.subscription_id);
      setClientStripeId(res?.data?.client_secret);
      setPaymentId(res?.data?.payment_id);
      const subs_url =
        '/member/' + user_id + '/subscription/' + selectedPlan.id;
      const preMembership = await ajax('POST', subs_url, {
        member_subscription_id: res?.data?.subscription_id,
        is_free_trial: true,
      });
      if (preMembership.code === 200) {
        authDispatch({
          type: 'MEMBER_SUBSCRIBED',
          payload: { is_subscribed: true },
        });
        setTrialConfirm(true);
        window.location.reload();

        // setTimeout(() => {
        //   getSubscription();
        // }, 5000);
        setLoading(false);
      } else {
        setErrorMembership(true);
        setLoading(false);
      }
    } else {
      setErrorMembership(true);
      setLoading(false);
    }
  };

  const continueToPaymentRetry = async step => {
    setLoading(true);

    const subs_url = '/member/' + user_id + '/subscription/' + selectedPlan.id;
    const preMembership = await ajax('POST', subs_url, {
      member_subscription_id: membershipId,
      is_free_trial: false,
    });
    if (preMembership.code === 200) {
      setLoading(false);
      authDispatch({
        type: 'MEMBER_SUBSCRIBED',
        payload: { is_subscribed: true },
      });

      setCurrentStep(step);
    } else {
      setErrorMembership(true);
      setLoading(false);
    }
  };

  const getChangedPlanData = async (promo_code = '') => {
    setLoading(true);
    let type = selectedPlan?.amount > currentPlan ? 'upgrade' : 'downgrade';
    const subs_url = '/plan-change-details';
    const preMembership = await ajax('POST', subs_url, {
      change_type: type,
      plan_id: selectedPlan?.plan_id,
      member_subscription_id: membershipId,
      promo_code: promo_code,
    });
    if (preMembership.code === 200) {
      setLoading(false);
      setChangePlanData(preMembership?.data);
      return preMembership;
    } else {
      setErrorMembership(true);
      setLoading(false);
    }
  };

  const requestChangedPlan = async () => {
    setLoading(true);
    let type = selectedPlan?.amount > currentPlan ? 'upgrade' : 'downgrade';
    const subs_url = '/change-plan';
    const preMembership = await ajax('POST', subs_url, {
      ...changePlanData,
      subscription_id: selectedPlan?.id,
    });
    if (preMembership.code === 200) {
      if (changePlanData?.payment_method) {
        window.location.reload();
      } else {
        setClientStripeId(preMembership?.data?.client_secret);
        setCurrentStep(4);
        setLoading(false);
      }
    } else {
      setErrorMembership(true);
      setLoading(false);
    }
  };

  // const continueToPayment = async step => {
  //   setCurrentStep(step);
  // };

  const continueToSharing = () => {
    if (params.receiver_id) {
      history.push(`/my-projects-share/${params.receiver_id}`);
    } else {
      history.push(`/project/${params.project_id}/share`);
    }
  };

  const getSubscription = async () => {
    const res = await ajax('GET', `/member/${user_id}/subscription/active`);

    if (res?.code === 200) {
      console.log('res: ', res);
      setMembershipId(res?.data?.member_subscription_id);

      setNextPlanData(res?.meta?.next_plan);
      setIsTrialUsed(!res?.meta?.can_get_trial);
      setIsFreeMembershipPlan(res?.data?.is_free_trial);
      setCurrentPlan(res?.data?.amount);
      setNextRenewDate(res?.data?.renewal_date);
      setSubscriptionId(res?.data?.id);
      if (res?.data?.amount) {
        setPaymentProgress(res?.data?.status !== 'Active');
      }
      if (res?.data?.amount === 99) {
        setRecommendedPlan(1);
      } else if (res?.data?.amount === 499) {
        setRecommendedPlan(2);
      } else if (res?.data?.amount === 999) {
        setRecommendedPlan(null);
      }
    } else if (res?.code === 204) {
      setIsTrialUsed(!res?.meta?.can_get_trial);
    }
  };

  useEffect(() => {
    if (currentStep === 1) {
      getSubscription();
    }
  }, [currentStep]);

  const handleSubmit = payload => {
    payload['amount'] = selectedPlan.amount;
    payload['currency'] = selectedPlan.currency;
    payload['plan_id'] = selectedPlan.plan_id;
    payload['email'] = user?.member?.email;
    if (selectedPlan.amount === 0) {
      payload['promo_code'] = selectedPlan.promo_code;
    }
    (async () => {
      setLoading(true);
      const url = '/subscribe';
      try {
        const res = await ajax('POST', url, payload);
        if (res.code === 200) {
          const subs_url =
            '/member/' + user_id + '/subscription/' + selectedPlan.id;
          const postSubsRes = await ajax('POST', subs_url, {
            member_subscription_id: res.data.id,
            is_free_trial: false,
          });
          if (postSubsRes.code === 200) {
            const trans_payload = {
              type: 'subscription',
              amount: selectedPlan.amount,
              payee_id: user_id,
              payer_id: user_id,
              payment_status: 'success',
              status: 'full_payment',
              description: 'Payment for membership plan',
              location: '',
            };
            const postTransRes = await ajax(
              'POST',
              '/transaction',
              trans_payload
            );

            if (postTransRes.code === 200) {
              if (params?.id) {
                setModalOpenCong(true);
              } else {
                setIsSubsPop(true);
              }
              // setSuccess(true);
              setLoading(false);
              // setModalMsg(
              //   'Congratulations! You can now connect with the researchers'
              // );
            } else if (postTransRes.code === 401) {
              setLoading(false);
              setIsOpen(true);
            } else {
              setLoading(false);
              if (postTransRes.message) {
                setModalMsg(postTransRes.message);
              } else {
                setModalMsg('Failed to insert a transaction.');
              }
              setModalOpen(true);
            }
          } else if (postSubsRes.code === 401) {
            setLoading(false);
            setIsOpen(true);
          } else {
            setLoading(false);
            if (postSubsRes.message) {
              setModalMsg(postSubsRes.message);
            } else {
              setModalMsg('Failed to insert member subscription.');
            }
            setModalOpen(true);
          }
        } else if (res.code === 401) {
          setLoading(false);
          setIsOpen(true);
        } else {
          setLoading(false);
          if (res.message) {
            setModalMsg(res.message);
          } else {
            setModalMsg('Failed to pay downpayment.');
          }
          setModalOpen(true);
        }
      } catch (err) {
        console.log(err);
        setLoading(false);
      }
    })();
  };

  return (
    <PageContent>
      {loading ? <LoadingIndicator /> : null}
      {/* <BreadcrumbLayout>
        {breadcrumbs.map((item, index) => (
          <Fragment key={item.label}>
            {index === breadcrumbs.length - 1 ? (
              <BreadcrumbEnd>{item.label}</BreadcrumbEnd>
            ) : item.to ? (
              <BreadcrumbLink to={item.to}>{item.label}</BreadcrumbLink>
            ) : (
              <BreadcrumbMiddle onClick={item.click}>
                {item.label}
              </BreadcrumbMiddle>
            )}
            {index !== breadcrumbs.length - 1 && <FaChevronRight />}
          </Fragment>
        ))}
      </BreadcrumbLayout> */}
      {currentStep === 1 && (
        <MembershipPlan
          isFreeMembershipPlan={isFreeMembershipPlan}
          selectedPlan={selectedPlan}
          setSelectedPlan={setSelectedPlan}
          plans={plans}
          continueToPayment={continueToPayment}
          recommendedPlan={recommendedPlan}
          currentPlan={currentPlan}
          nextRenewDate={nextRenewDate}
          isPaymentProgress={paymentProgress}
          promoCodeSubscription={promoCodeSubscription}
          isTrialUsed={isTrialUsed}
          confirmNextPlan={confirmNextPlan}
          setConfirmNextPlan={setConfirmNextPlan}
          confirmMaxPlan={confirmMaxPlan}
          confirmCancelPlan={confirmCancelPlan}
          setConfirmMaxPlan={setConfirmMaxPlan}
          setConfirmCancelPlan={setConfirmCancelPlan}
          handleCancelSubscription={handleCancelSubscription}
          setTrialConfirm={setTrialConfirm}
          trialConfirm={trialConfirm}
          getFreeTrial={getFreeTrial}
          getChangedPlanData={getChangedPlanData}
          requestChangedPlan={requestChangedPlan}
          changePlanData={changePlanData}
          nextPlanData={nextPlanData}
        />
      )}
      {currentStep === 2 && (
        // <Payment
        //   page_title={'Get the membership!'}
        //   page_description={
        //     'To proceed with your subscription, complete the payment of $' +
        //     selectedPlan.amount +
        //     '.'
        //   }
        //   rightbutton_label={'PROCEED TO PAY'}
        //   onCancel={() => {
        //     subFun();
        //     continueToPayment(1);
        //   }}
        //   handleSubmit={handleSubmit}
        // />
        <Elements stripe={stripePromise} options={options}>
          <Payment
            page_title={'Get the membership!'}
            payment_type={`client_membership_${selectedPlan?.amount}`}
            page_description={
              <span>
                {'To proceed with your subscription, complete the payment of $' +
                  selectedPlan?.amount +
                  '.'}
                <div style={{ margin: '12px 0px', fontSize: '13px' }}>
                  Please note that you can cancel your membership anytime.
                  However, the fees will be non-refundable after the free 30-day
                  trial period.
                </div>
              </span>
            }
            rightbutton_label={'PROCEED TO PAY'}
            onCancel={() => {
              // subFun();
              // continueToPayment(1);
              setCurrentStep(1);
            }}
            clientsecret={clientStripeId}
            setPaymentStatusData={setPaymentStatusData}
            setCurrentStep={setCurrentStep}
            isMembership={true}
            paymentID={paymentId}
          />
        </Elements>
      )}

      {currentStep === 3 && (
        <div style={{ marginTop: '2.15rem' }}>
          <div
            style={{
              fontSize: '2.2rem',
              color: '#4b4c5e',
            }}
          >
            Payment Failed!
          </div>
          <div
            style={{
              marginTop: '1.5rem',
              padding: '4rem',
              backgroundColor: 'white',
            }}
          >
            <div
              style={{
                marginBottom: '4rem',
              }}
            >
              Reason: {paymentStatusData?.message}
            </div>

            <Button
              style={{ width: '250px' }}
              onClick={() => {
                continueToPaymentRetry(2);
                // setCurrentStep(1);
              }}
            >
              RETRY
            </Button>
          </div>
        </div>
      )}

      {currentStep === 4 && (
        <Elements stripe={stripePromise} options={options}>
          <Payment
            page_title={'Get the membership!'}
            payment_type={`client_membership_${selectedPlan?.amount}`}
            page_description={
              <span>
                {changePlanData?.payment_method
                  ? `This membership plan will be applicable from ${moment(
                      nextRenewDate
                    ).format(
                      'DD/MM/YYYY'
                    )}  after the completion of your free trial. The payment will be deducted before its initiation.`
                  : 'This membership plan will be applicable from today.'}
                <div style={{ margin: '12px 0px', fontSize: '13px' }}>
                  Please note that you can cancel your membership anytime.
                  However, the fees will be non-refundable after the free 30-day
                  trial period.
                </div>
              </span>
            }
            rightbutton_label={'Save Card'}
            isFirstPaidPlan={true}
            onCancel={() => {
              // subFun();
              // continueToPayment(1);
              setCurrentStep(1);
            }}
            clientsecret={clientStripeId}
            setPaymentStatusData={setPaymentStatusData}
            setCurrentStep={setCurrentStep}
            isMembership={true}
            paymentID={paymentId}
          />
        </Elements>
      )}

      {currentStep === 5 && (
        <div style={{ marginTop: '2.15rem' }}>
          <div
            style={{
              fontSize: '2.2rem',
              color: '#4b4c5e',
            }}
          >
            Details saved successfully!
          </div>
          <div
            style={{
              marginTop: '1.5rem',
              padding: '4rem',
              backgroundColor: 'white',
            }}
          >
            <div
              style={{
                marginBottom: '4rem',
              }}
            >
              Your card details have been added successfully for future
              payments.
              {/* Reason: {paymentStatusData?.message} */}
            </div>

            <Button
              style={{ width: '110px' }}
              onClick={() => {
                window.location.reload();
              }}
            >
              Okay
            </Button>
          </div>
        </div>
      )}

      <Modal visible={!!isSubsPop} setVisible={setIsSubsPop} persistent>
        <ModalContent>
          <ModalMsg>
            {/* <div>Congratulations!</div> */}
            <div>
              You subscribed to ${' '}
              {selectedPlan?.amount === 0 ? 999 : selectedPlan?.amount}.{' '}
            </div>
          </ModalMsg>
          <ModalButton>
            <Button fill onClick={() => history.push('/welcome')}>
              Okay
            </Button>
          </ModalButton>
        </ModalContent>
      </Modal>

      <Modal
        visible={errorMembership}
        setVisible={setErrorMembership}
        persistent
      >
        <div
          style={{
            backgroundColor: 'white',
            padding: '2rem',
            flexDirection: 'column',
            justifyContent: 'flex-end',
            maxWidth: `${'400px'}`,
            marginLeft: 'auto',
            marginRight: 'auto',
            borderRadius: '12px',
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginBottom: '20px',
              fontSize: '2rem',
              fontWeight: '700',
              // maxWidth: '90%',
            }}
          >
            Error!
          </div>
          <div style={{ display: 'flex', textAlign: 'center' }}>
            An error occurred while purchasing the membership plan. Please try
            again.
          </div>

          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginBottom: '10px',
              marginTop: '20px',
            }}
          >
            <Button
              fill
              onClick={() => history.push('/welcome')}
              style={{ width: 'fit-content' }}
            >
              Back to Home
            </Button>
          </div>
        </div>
      </Modal>

      <SessionModal
        isOpen={isOpen}
        setModalVisible={setIsOpen}
        message={'Your session has expired.'}
      />
    </PageContent>
  );
};
