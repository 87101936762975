import {
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import Button from 'components/Button';
import { useAuthDispatch } from 'context/AuthContext';
import { useFormik } from 'formik';
import React from 'react';
import { useHistory } from 'react-router-dom';

import {
  ElementWrapper,
  PageButtonLayout,
  PageContainer,
  PageLabel,
  PageTitle,
} from './styled';

export const Payment = props => {
  const authDispatch = useAuthDispatch();

  const stripe = useStripe();
  const elements = useElements();
  const history = useHistory();

  const handleSubmit = async () => {
    if (props?.isFirstPaidPlan) {
      console.log('object', stripe);
      const response = await stripe.confirmSetup({
        //`Elements` instance that was used to create the Payment Element
        elements,
        redirect: 'if_required',
      });

      console.log('response: ', response);
      if (response?.error) {
        props.setCurrentStep(3);
      } else {
        props.setCurrentStep(5);
      }
    } else {
      const response = await stripe.confirmPayment({
        elements,

        redirect: 'if_required',
      });

      if (response?.error) {
        props.setPaymentStatusData(response?.error);
        props.setCurrentStep(3);
        console.log(response?.error.message);
      } else {
        if (response?.paymentIntent?.status === 'succeeded') {
          if (props?.isMembership) {
            authDispatch({
              type: 'MEMBER_SUBSCRIBED',
              payload: { is_subscribed: true },
            });
            history.push(
              `/payment-success?transactionId=${props?.paymentID ||
                response?.paymentIntent
                  ?.id}&isMembership=${props?.isMembership || false}`
            );
          } else {
            history.push(
              `/payment-success?transactionId=${props?.paymentID ||
                response?.paymentIntent?.id}`
            );
          }
        } else if (response?.paymentIntent?.status === 'processing') {
          history.push(
            `/payment-status?transactionId=${props?.paymentID ||
              response?.paymentIntent?.id}&isMembership=${props?.isMembership ||
              false}`
          );
        }
      }
    }
  };

  return (
    <form>
      <PageTitle>{props.page_title}</PageTitle>
      <PageContainer>
        <PageLabel>{props.page_description}</PageLabel>
        <ElementWrapper>
          <PaymentElement />
        </ElementWrapper>
        <PageButtonLayout justifyContent="space-between">
          <Button
            backgroundColor="#dadadf"
            color="#65637b"
            onClick={() => {
              props.onCancel();
            }}
          >
            CANCEL
          </Button>
          <Button
            style={{ width: '250px' }}
            onClick={() => {
              handleSubmit();
            }}
          >
            {props.rightbutton_label}
          </Button>
        </PageButtonLayout>
      </PageContainer>
    </form>
  );
};
