import Content from 'components/Content';
import styled from 'styled-components/macro';

export const Title = styled.h1`
  font-size: 2.8rem;
  margin-bottom: 2rem;
  margin-top: 4rem;
`;

export const Subtitle = styled.div`
  font-size: 1.8rem;
  margin-bottom: 2rem;
  margin-top: 2rem;
  margin-left: 1.5rem;
  opacity: 0.75;
  display: flex;
`;

export const RatingsContainer = styled.div`
  flex: 1;
  margin-left: 5rem;
  margin-right: 5rem;
  font-weight: bold;
`;

export const RecomParagraph = styled.p`
  margin-top: 1rem;
`;

export const RatingsTitle = styled.h4`
  font-size: 1.8rem;
`;

export const RatingsLayout = styled.div`
  flex: 1;
  flex-direction: row;
  display: flex;
  margin-top: 6rem;
`;

export const PageContent = styled(Content)`
  margin: 4rem 0;
`;

export const Container = styled.div`
  margin-top: 1.5rem;

  padding: 4rem 2rem;
  background-color: white;
  display: flex;
  flex-direction: column;
`;

export const RatingStarsLayout = styled.div`
  display: flex;
  min-height: 1.5rem;
  flex-direction: column;

  margin-bottom: 1rem;

  svg {
    color: #ff9e08;
    height: 2rem;
    width: 2rem;
    margin-left: 2rem;
  }
`;

export const RatingStarsContainer = styled.div`
  margin-top: 2rem;
  justify-content: center;
  flex-direction: row;
  display: flex;
  align-self: start;
`;

export const RatingStarsTitleContainer = styled.div`
  min-width: 15rem;
  margin-right: 3rem;
`;

export const SubmitRatingButton = styled.div`
  display: flex;
  justify-content: center;
`;
