import Button from 'components/Button';
import Content from 'components/Content';
import { InputContainer } from 'components/Input/styled';
import Checkbox from 'components/Checkbox';
import Dropdown from 'components/Dropdown';
import Datepicker from 'components/Datepicker';
import Textarea from 'components/Textarea';
import FileInput from 'components/FileInput';
import { Row } from 'components/Layout';
import styled from 'styled-components/macro';
import { FaUserAlt } from 'react-icons/fa';

export const RegistrationContent = styled(Content)`
  margin: 4rem 0;
`;

export const HeaderLayout = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 4rem;

  @media (max-width: 768px) {
    display: block;
  }
`;

export const Title = styled.h1`
  color: #4b4c5e;
  font-size: 2.6rem;

  @media (max-width: 768px) {
    font-size: 3rem;
    margin-bottom: 4rem;
  }
`;

export const Container = styled.div`
  background-color: white;
  padding: 4.5rem 7rem;
  margin-bottom: 5rem;

  @media (max-width: 768px) {
    padding: 4rem;
    margin-bottom: 4rem;
  }
`;

export const FormTitle = styled.h2`
  color: #4b4c5e;
  font-size: 2.2rem;
  margin-bottom: 1.5rem;

  @media (max-width: 768px) {
    font-size: 2.4rem;
    margin-bottom: 2rem;
  }
`;

export const FormInstructions = styled.p`
  color: #65637b;
  font-size: 1.6rem;
  margin-bottom: 1.5rem;

  @media (max-width: 768px) {
    font-size: 2rem;
    margin-bottom: 2rem;
  }
`;

export const FormRow = styled.div`
  min-width: 0;
  display: flex;
  margin-bottom: 3rem;
  flex: 1;

  @media (max-width: 768px) {
    display: block;
  }

  ${InputContainer} {
    min-width: 0;
    flex: 1;
    margin-right: 2.5rem;
    &:last-child {
      margin-right: 0;
    }

    @media (max-width: 768px) {
      margin-right: 0;
      margin-bottom: 2rem;
    }
  }

  /* & & {
    margin-right: 2.5rem;

    &:last-child {
      margin-right: 0;
    }
  } */
`;

export const ButtonsLayout = styled.div`
  display: flex;
  justify-content: flex-end;

  @media (max-width: 768px) {
    justify-content: space-between;
    margin-top: 4rem;
  }

  ${Button} {
    margin-right: 4rem;

    @media (max-width: 768px) {
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
      font-size: 1.75rem;
      flex: 1;
      margin-right: 2rem;
    }

    &:last-child {
      margin-right: 0;
    }
  }
`;

export const DatePickersLayout = styled.div`
  margin-right: 1.7rem;
  flex: 1;
  min-width: 0;

  @media (max-width: 768px) {
    margin-right: 0rem;
  }
`;

export const LinkedInButton = styled(Button)`
  margin-bottom: 2rem;

  svg {
    margin-bottom: -0.3rem;
  }

  @media (max-width: 768px) {
    width: 100%;
    padding-top: 1.75rem;
    padding-bottom: 1.75rem;
    font-size: 2rem;
    margin-top: 2rem;
    margin-bottom: 4rem;

    svg {
      font-size: 2.5rem;
      margin-bottom: -0.5rem;
    }
  }
`;

export const MRCheckbox = styled(Checkbox)`
  margin-top: 1rem;

  @media (max-width: 768px) {
    margin-top: 1rem;
    margin-bottom: 2rem;
  }
`;

export const MRDropdown = styled(Dropdown)`
  @media (max-width: 768px) {
    margin-bottom: 2rem;
  }
`;

export const MRDatepicker = styled(Datepicker)`
  @media (max-width: 768px) {
    margin-bottom: 2rem;
  }
`;

export const MRTextArea = styled(Textarea)`
  @media (max-width: 768px) {
    margin-bottom: 2rem;
  }
`;

export const MRFileInput = styled(FileInput)`
  @media (max-width: 768px) {
    margin-bottom: 2rem;
  }
`;

export const UserProfileImageContainer = styled.div`
  position: relative;
  margin-bottom: 2rem;
`;

export const UserProfileImage = styled.img`
  width: 10rem;
  height: 10rem;
  border-radius: 50%;
  border: 0.3rem solid #f58c063c;
  cursor: ${props => (props.readOnly ? 'default' : 'pointer')};
`;

export const IconContainer = styled(Row)`
  justify-content: center;
  align-items: center;
  background-color: #f58c06;
  position: absolute;
  bottom: 1rem;
  right: 0.25rem;
  border-radius: 50%;
  padding: 0.5rem;

  svg {
    font-size: 1rem;
    color: white;
  }
  cursor: ${props => (props.readOnly ? 'default' : 'pointer')};
`;

export const DefaultProfilePicture = styled(FaUserAlt)`
  width: 10rem;
  height: 10rem;
  border-radius: 50%;
  border: 0.3rem solid #f58c063c;
  cursor: ${props => (props.readOnly ? 'default' : 'pointer')};
`;

export const UserProfileInfo = styled.div`
  flex: 1;
  padding-left: 2rem;

  h3 {
    font-size: 2rem;
    margin-bottom: 2rem;
  }

  form {
    ${Row} {
      margin-bottom: 4rem;
    }
  }
`;

export const UserProfileMenu = styled.div`
  padding-right: 2rem;
`;

export const UserProfileContainer = styled(Row)`
  background-color: white;
  padding: 4rem;
`;

export const ConnectLinkedInContainer = styled.div`
  background-color: white;
  padding-top: 1rem;
`;
export const HeadersTextContainer = styled.div`
  background-color: white;
`;
export const HeadersContainer = styled.div`
  background-color: white;
`;
