import { useEffect, useRef } from 'react';

/**
 * Hook that alerts clicks outside of the passed ref
 */
const useClickOutside = (refs, attachCondition, onClickOutside) => {
  const hasAttachedRef = useRef(false);
  // refs can be one ref or an array of refs
  const refsArray = useRef(Array.isArray(refs) ? refs : [refs]);

  // make sure refsArray is always replaced with the newest refs value
  refsArray.current = Array.isArray(refs) ? refs : [refs];

  useEffect(() => {
    // check each element in the refs if it hits the target
    const handleClickOutside = event => {
      let hit = false;
      for (const ref of refsArray.current) {
        if (ref.current && ref.current.contains(event.target)) {
          hit = true;
          break;
        }
      }
      if (!hit) {
        onClickOutside();
      }
    };

    // Bind the event listener
    if (attachCondition && !hasAttachedRef.current) {
      document.addEventListener('mousedown', handleClickOutside);
      hasAttachedRef.current = true;
    }

    // make sure to reset if conditions are not met, as next time conditions are met
    // might not reattach the listener
    if (!attachCondition && hasAttachedRef.current) {
      hasAttachedRef.current = false;
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [attachCondition, onClickOutside]);
};

export default useClickOutside;
