import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { format, parseJSON } from 'date-fns';
import './style.css';
import moment from 'moment';

const columns = [
  { id: 'title', label: 'Project Name', minWidth: 200 },
  { id: 'created_date', label: 'Created', minWidth: 170 },
  {
    id: 'project_status_id',
    label: 'Status',
    minWidth: 100,
    align: 'start',
    format: value => value.toLocaleString('en-US'),
  },
  {
    id: 'actual_price',
    label: 'Project Cost',
    minWidth: 100,
    align: 'right',
    format: value => value.toLocaleString('en-US'),
  },
  {
    id: 'remaining_balance',
    label: 'Remaining Amount',
    minWidth: 100,
    align: 'right',
    format: value => value.toLocaleString('en-US'),
  },

  {
    id: 'initial_payment',
    label: 'Initial Payment ',
    minWidth: 100,
    align: 'center',
    format: value => value.toLocaleString('en-US'),
  },
  {
    id: 'final_payment',
    label: 'Final Payment',
    minWidth: 100,
    align: 'center',
    format: value => value.toLocaleString('en-US'),
  },
  {
    id: 'end_date',
    label: 'Target End Date',
    minWidth: 100,
    align: 'center',
    format: value => value.toLocaleString('en-US'),
  },
];

const formatDate = a => {
  try {
    a = parseJSON(a);
    a = format(a, 'MMM d, yyyy');
    return a;
  } catch (_err) {
    return 'x';
  }
};

const formatTime = a => {
  try {
    a = parseJSON(a);
    a = format(a, 'h:mm a');
    return a;
  } catch (_err) {
    return 'y';
  }
};

export default function ProjectListingTabular({
  projects,
  project_labels,
  handleRowClick,
}) {
  console.log('projects: ', projects);
  return (
    <Paper
      sx={{
        width: '100%',
        overflow: 'hidden',
        borderRadius: '0px',
      }}
    >
      <TableContainer sx={{ maxHeight: 440, borderRadius: '0px' }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead className="t_heading_container">
            <TableRow>
              {columns.map(column => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                  handleRowClick
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody style={{ fontSize: '14px' }} className="t_body_container">
            {projects.map(row => {
              return (
                <TableRow
                  hover
                  style={{ cursor: 'pointer' }}
                  role="checkbox"
                  tabIndex={-1}
                  key={row.code}
                  onClick={() => handleRowClick(row?.id)}
                >
                  {columns.map(column => {
                    let value;
                    if (column.id === 'created_date') {
                      value =
                        formatDate(row[column.id]) +
                        ' ' +
                        formatTime(row[column.id]);
                    } else if (column.id === 'end_date') {
                      value = moment(row[column.id]).format('MMMM DD, yyyy');
                    } else if (column.id === 'project_status_id') {
                      value = project_labels[row[column.id]];
                    } else if (
                      column.id === 'actual_price' ||
                      column.id === 'remaining_balance'
                    ) {
                      value =
                        row[column.id] === 0 || row[column.id]
                          ? '$' + row[column.id]
                          : '-';
                    } else {
                      value = row[column.id] || '-';
                    }
                    return (
                      <TableCell key={column.id} align={column.align}>
                        {column.format && typeof value === 'number'
                          ? column.format(value)
                          : value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}
