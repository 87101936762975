import styled from 'styled-components/macro';

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;

  label {
    font-size: 1.6rem;
    color: #4b4c5e;

    @media (max-width: 900px) {
      font-size: 2rem;
    }
  }
`;

export const LabelLayout = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 7px;
`;

export const InputLayout = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid ${props => props.borderColor || '#65637b3c'};
  border-radius: 8px;
  display: flex;
  overflow: hidden;
  background-color: #ffffff;

  input,
  a {
    color: #4b4c5e;
    flex: 1;
    font-size: 1.6rem;
    padding: 0.95rem 1rem;

    @media (max-width: 900px) {
      font-size: 2.4rem;
      padding: 0.5rem 1rem;
    }

    &:disabled {
      background-color: #f5f5f5;
    }
  }

  a {
    flex: 0;
  }
`;

export const SideLabel = styled.div`
  color: ${props => props.color || 'rgba(20, 28, 39, 0.5)'};
  line-height: 1;

  svg {
    font-size: 1.6rem;

    @media (max-width: 900px) {
      font-size: 2.4rem;
    }
  }
`;

export const LeftLabel = styled(SideLabel)`
  margin-left: 1rem;
`;

export const RightLabel = styled(SideLabel)`
  margin-right: 1rem;
`;
