import styled from 'styled-components/macro';

export const SearchbarContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  position: relative;

  svg {
    position: absolute;
    font-size: 1.6rem;
    color: #bab9be;
    left: 2rem;

    @media (max-width: 900px) {
      font-size: 2.4rem;
    }
  }

  input {
    flex: 1;
    border: 1px solid ${props => props.theme.lightGray};
    background-color: white;
    // border-radius: 8px;
    padding: 1.5rem 1.5rem 1.5rem 1rem;
    padding-left: 6rem;

    font-size: 1.6rem;
    font-weight: bold;

    color: #bab9be;

    &::placeholder {
      color: #bab9be;
    }

    @media (max-width: 900px) {
      font-size: 2rem;
      padding-top: 2.25rem;
      padding-bottom: 2.25rem;
    }
  }
`;
