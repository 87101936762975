import React, { useEffect, useState } from 'react';
import Modal from 'components/Modal';
import { FeedbackButton } from './styled';
import Button from 'components/Button';
import { useFormik } from 'formik';
import Textarea from 'components/Textarea';
import { useAuthState } from 'context/AuthContext';
import { ajax } from 'utils/ajax';
import { useHistory, useLocation } from 'react-router-dom';
import LoadingIndicator from 'components/LoadingIndicator';
import './style.css';
import Slider from '@mui/material/Slider';
import StarRating from './StarRating';
const Feedback = () => {
  const { user } = useAuthState();
  const location = useLocation();
  const history = useHistory();
  const params = new URLSearchParams(location.search);
  const [isShowingModal, setIsShowingModal] = useState(null);
  const [successfulModa, setSuccessfulModal] = useState(null);
  const [discardModal, setDiscardModal] = useState(null);
  const form = params.get('form');
  const users = params.get('user');
  const projectId = params.get('project-id');
  const [showingAction, setShowingAction] = useState({});

  useEffect(async () => {
    if (form === 'feedback' && projectId) {
      setLoading(true);
      let res = await ajax('GET', `/project-detail/${projectId}/${users}`);
      if (res?.code === 200) {
        setLoading(false);
        setShowingAction(res?.data);
        setIsShowingModal(true);
      } else if (res?.code === 400) {
        setLoading(false);
        setDiscardModal(true);
      } else {
        setLoading(false);
      }
    }
  }, []);
  const {
    values,
    // handleChange,
    handleBlur,
    // handleSubmit,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues: {
      textOne: '',
      textTwo: '',
    },
    onSubmit: async data => {},
  });
  const [itemOne, setItemOne] = useState(0);
  const [itemTwo, setItemTwo] = useState(0);
  const rating = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  const [loading, setLoading] = useState(false);
  const [clientRating, setClientRating] = useState(0);
  const [clientRatingDes, setClientRatingDes] = useState('');
  const [comment, setComment] = useState('');
  const [projectRating, setprojectRating] = useState(0);
  const [projectRatingDes, setprojectRatingDes] = useState('');
  const [researcherRating, setresearcherRating] = useState(0);
  const [researcherRatingDes, setresearcherRatingDes] = useState('');
  const [impact, setimpact] = useState(0);

  const [projectRatingErr, setprojectRatingErr] = useState(false);
  const [researcherRatingErr, setresearcherRatingErr] = useState(false);
  const [impactErr, setimpactErr] = useState(false);

  const [clientRatingErr, setclientRatingErr] = useState(false);
  const [itemOneErr, setitemOneErr] = useState(0);
  const [itemTwoErr, setitemTwoErr] = useState(0);
  // const getMobileWindow = () => { setprojectRatingErr setresearcherRatingErr setimpactErr
  //   let isMobile = window.matchMedia('(max-width: 768px)').matches;
  //   return isMobile ? true : false;
  // };
  // const [isMobile, setIsMobile] = useState(getMobileWindow());

  const getTextAreaMaxLine = () => {
    let isMobile = window.matchMedia('(max-width: 768px)').matches;
    return isMobile ? 5 : 3;
  };
  const [textAreaMaxLine, setTextAreaMaxLine] = useState(getTextAreaMaxLine());

  const sendFeedback = async () => {
    setLoading(true);
    let body = {
      email_address: user
        ? user.member.email
        : location.pathname === '/'
        ? 'not logged in user - homepage'
        : `not logged in user - ${location.pathname.split('/')[1]}`,
      recommendation_score: itemOne,
      recommendation_reason: values.textOne,
      satisfaction_score: itemTwo,
      satisfaction_reason: values.textTwo,
    };

    let res = await ajax('POST', '/feedback', body);
    setIsShowingModal(null);
    setItemOne(0);
    setItemTwo(0);
    // setShowingAction('');
    resetForm();
    setLoading(false);
    setSuccessfulModal(true);
  };

  const sendUserFeedback = async () => {
    setLoading(true);
    const body = {
      client_rating: clientRating || 0,
      client_rating_desc: clientRatingDes || '',
      comments: comment || '',
      user: 'researcher',
      recommendation_score: itemOne,
      recommendation_reason: values.textOne,
      satisfaction_score: itemTwo,
      satisfaction_reason: values.textTwo,
    };
    const bodyClient = {
      project_rating: projectRating || 0,
      project_rating_desc: projectRatingDes || '',
      researcher_rating: researcherRating || 0,
      researcher_rating_desc: researcherRatingDes || '',
      impact_score: impact || 0,
      comments: comment || '',
      user: 'client',
    };

    const obj = users === 'client' ? bodyClient : body;

    let mainObj = Object.assign({}, obj, showingAction);
    let res = await ajax('POST', '/project-feedback', mainObj);
    setIsShowingModal(null);
    setClientRating(0);
    setClientRatingDes('');
    setprojectRating(0);
    setprojectRatingDes('');
    setresearcherRating(0);
    setresearcherRatingDes('');
    setimpact(0);
    setComment('');
    setItemOne(0);
    setItemTwo(0);
    resetForm();
    setLoading(false);
    setSuccessfulModal(true);
  };

  function valuetext(value) {
    return `${value}%`;
  }

  const marks = [
    {
      value: 0,
      label: '0%',
    },
    {
      value: 10,
      label: '10%',
    },
    {
      value: 20,
      label: '20%',
    },
    {
      value: 30,
      label: '30%',
    },
    {
      value: 40,
      label: '40%',
    },
    {
      value: 50,
      label: '50%',
    },
    {
      value: 60,
      label: '60%',
    },
    {
      value: 70,
      label: '70%',
    },
    {
      value: 80,
      label: '80%',
    },
    {
      value: 90,
      label: '90%',
    },
    {
      value: 100,
      label: '100%',
    },
  ];

  const handleChange = (event, newValue) => {
    setimpactErr(false);
    if (typeof newValue === 'number') {
      setimpact(newValue);
    }
  };
  return (
    <>
      <FeedbackButton
        onClick={() => {
          setIsShowingModal(true);
        }}
      >
        <span style={{ writingMode: 'vertical-lr', textOrientation: 'mixed' }}>
          FEEDBACK
        </span>
      </FeedbackButton>
      <Modal
        visible={!!isShowingModal}
        setVisible={setIsShowingModal}
        persistent
      >
        <div style={{ padding: '5px' }}>
          <div
            style={{
              backgroundColor: 'white',
              padding: '2rem',
              flexDirection: 'column',
              // justifyContent: 'flex-end',
              maxWidth: `${'600px'}`,
              // height: `${'570px'}`,
              overflowY: 'auto',
              borderRadius: '12px',
              margin: '10px',
            }}
          >
            <h5 style={{ marginBottom: '6px' }}>
              {users === 'researcher'
                ? 'Share your rating'
                : users === 'client'
                ? 'Share your rating'
                : 'Submit Feedback'}
            </h5>
            {users === 'researcher' ? (
              <div
                style={{
                  padding: '3rem',
                  flexDirection: 'column',
                  maxHeight: `${'370px'}`,
                  overflowY: 'auto',
                }}
              >
                <div style={{ marginBottom: '12px' }}>
                  <b className="content_font">
                    1. On a scale of 1 to 10, 10 being extremely likely, how
                    likely would you recommend ‘{showingAction?.client_name}’ to
                    a friend or colleague?
                  </b>
                  <br />
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      margin: '12px auto 6px auto',
                      justifyContent: 'center',
                    }}
                  >
                    {rating.map(item => {
                      return (
                        <div
                          className="feed_count"
                          style={{
                            background:
                              item === clientRating ? '#ff9b2d' : '#fff',
                            color: item === clientRating ? '#fff' : '#111',
                          }}
                          onClick={() => {
                            setclientRatingErr(false);
                            setClientRating(item);
                          }}
                          key={item}
                        >
                          {item}
                        </div>
                      );
                    })}
                  </div>
                  <div>
                    {clientRatingErr ? (
                      <span style={{ color: 'red', fontSize: '10px' }}>
                        This field is required
                      </span>
                    ) : (
                      ''
                    )}
                  </div>
                  {clientRating > 0 && (
                    <Textarea
                      name="textOne"
                      label={
                        <span className="content_font">
                          {`Why did you rate this client ${
                            clientRating === 0 ? 1 : clientRating
                          } out of 10?`}
                        </span>
                      }
                      rows={textAreaMaxLine}
                      maxCharacters={500}
                      value={clientRatingDes}
                      onChange={e => {
                        setClientRatingDes(e.target.value);
                      }}
                      // onBlur={handleBlur}
                    />
                  )}
                </div>
                <div style={{ marginBottom: '12px' }}>
                  <b className="content_font">
                    2. On a scale of 1 to 10, 10 being extremely likely, how
                    likely would you recommend, Big Robin to a friend or
                    colleague?
                  </b>
                  <br />
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      margin: '12px auto 6px auto',
                      justifyContent: 'center',
                    }}
                  >
                    {rating.map(item => {
                      return (
                        <div
                          className="feed_count"
                          style={{
                            background: item === itemOne ? '#ff9b2d' : '#fff',
                            color: item === itemOne ? '#fff' : '#111',
                          }}
                          onClick={() => {
                            setitemOneErr(false);
                            setItemOne(item);
                          }}
                          key={item}
                        >
                          {item}
                        </div>
                      );
                    })}
                  </div>
                  <div>
                    {itemOneErr ? (
                      <span style={{ color: 'red', fontSize: '10px' }}>
                        This field is required
                      </span>
                    ) : (
                      ''
                    )}
                  </div>
                  {itemOne > 0 && (
                    <Textarea
                      name="textOne"
                      label={
                        <span className="content_font">
                          {`Why did you rate Big Robin ${
                            itemOne === 0 ? 1 : itemOne
                          } out of 10?`}
                        </span>
                      }
                      rows={textAreaMaxLine}
                      maxCharacters={500}
                      value={values.textOne}
                      onChange={e => {
                        setFieldValue(e.target.name, e.target.value);
                      }}
                      onBlur={handleBlur}
                    />
                  )}
                </div>
                <div>
                  <b className="content_font">
                    3. On a scale of 1 to 10, 10 being extremely satisfied, how
                    would you rate your overall experience here on the Big Robin
                    website?
                  </b>
                  <br />
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      margin: '12px auto 6px auto',
                      justifyContent: 'center',
                    }}
                  >
                    {rating.map(item => {
                      return (
                        <div
                          className="feed_count"
                          style={{
                            background: item === itemTwo ? '#ff9b2d' : '#fff',
                            color: item === itemTwo ? '#fff' : '#111',
                          }}
                          onClick={() => {
                            setitemTwoErr(false);
                            setItemTwo(item);
                          }}
                          key={item}
                        >
                          {item}
                        </div>
                      );
                    })}
                  </div>
                  <div>
                    {itemTwoErr ? (
                      <span style={{ color: 'red', fontSize: '10px' }}>
                        This field is required
                      </span>
                    ) : (
                      ''
                    )}
                  </div>
                  <Textarea
                    name="textTwo"
                    label={
                      <span className="content_font">
                        How might it be made better or improved?
                      </span>
                    }
                    rows={textAreaMaxLine}
                    maxCharacters={500}
                    value={values.textTwo}
                    onChange={e => {
                      setFieldValue(e.target.name, e.target.value);
                    }}
                    onBlur={handleBlur}
                  />
                </div>
                <div>
                  <b className="content_font">
                    4. Any other comments/suggestion
                  </b>
                  <br />

                  <Textarea
                    name="textTwo"
                    rows={textAreaMaxLine}
                    maxCharacters={200}
                    value={comment}
                    onChange={e => {
                      setComment(e.target.value);
                    }}
                    // onBlur={handleBlur}
                  />
                </div>
              </div>
            ) : users === 'client' ? (
              <div
                style={{
                  padding: '3rem',
                  flexDirection: 'column',
                  maxHeight: `${'370px'}`,
                  overflowY: 'auto',
                }}
              >
                <div style={{ marginBottom: '12px' }}>
                  <b className="content_font">
                    1. On a scale of 1 to 5, how would you rate the overall
                    project ‘{showingAction?.project_name}’?
                    <span style={{ color: 'red' }}>*</span>
                  </b>
                  <br />
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      margin: '12px auto 6px auto',
                      justifyContent: 'center',
                    }}
                  >
                    <StarRating
                      totalStars={5}
                      initialRating={0}
                      onChange={e => {
                        setprojectRating(e);
                        setprojectRatingErr(false);
                      }}
                    />
                    {/* {rating.map(item => {
                      return (
                        <div
                          className="feed_count"
                          style={{
                            background:
                              item === projectRating ? '#ff9b2d' : '#fff',
                            color: item === projectRating ? '#fff' : '#111',
                          }}
                          onClick={() => setprojectRating(item)}
                          key={item}
                        >
                          {item}
                        </div>
                      );
                    })} */}
                  </div>
                  {/* {itemOne > 0 && ( */}
                  <div>
                    {projectRatingErr ? (
                      <span style={{ color: 'red', fontSize: '10px' }}>
                        This field is required
                      </span>
                    ) : (
                      ''
                    )}
                  </div>
                  {projectRating > 0 && (
                    <Textarea
                      name="textOne"
                      label={
                        <span className="content_font">
                          {`Why did you rate this project ${
                            projectRating === 0 ? 1 : projectRating
                          } out of 5?`}
                        </span>
                      }
                      rows={textAreaMaxLine}
                      maxCharacters={500}
                      value={projectRatingDes}
                      onChange={e => {
                        setprojectRatingDes(e.target.value);
                      }}
                      // onBlur={handleBlur}
                    />
                  )}
                </div>

                <div>
                  <b className="content_font">
                    2. On a scale of 1 to 10, 10 being extremely likely, how
                    likely would you recommend ‘{showingAction?.researcher_name}
                    ’ to a friend or colleague?
                    <span style={{ color: 'red' }}>*</span>
                  </b>
                  <br />
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      margin: '12px auto 6px auto',
                      justifyContent: 'center',
                    }}
                  >
                    {rating.map(item => {
                      return (
                        <div
                          className="feed_count"
                          style={{
                            background:
                              item === researcherRating ? '#ff9b2d' : '#fff',
                            color: item === researcherRating ? '#fff' : '#111',
                          }}
                          onClick={() => {
                            setresearcherRating(item);
                            setresearcherRatingErr(false);
                          }}
                          key={item}
                        >
                          {item}
                        </div>
                      );
                    })}
                  </div>
                  <div>
                    {researcherRatingErr ? (
                      <span style={{ color: 'red', fontSize: '10px' }}>
                        This field is required
                      </span>
                    ) : (
                      ''
                    )}
                  </div>
                  {researcherRating > 0 && (
                    <Textarea
                      name="textOne"
                      label={
                        <span className="content_font">
                          {`Why did you rate this researcher ${
                            researcherRating === 0 ? 1 : researcherRating
                          } out of 10?`}
                        </span>
                      }
                      rows={textAreaMaxLine}
                      maxCharacters={200}
                      value={researcherRatingDes}
                      onChange={e => {
                        setresearcherRatingDes(e.target.value);
                      }}
                      onBlur={handleBlur}
                    />
                  )}
                </div>
                <div>
                  <b className="content_font">
                    3. How much would you estimate the impact of this study is
                    on your business outcomes?
                    <span style={{ color: 'red' }}>*</span>
                  </b>
                  <br />
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      margin: '25px auto 6px auto',
                      justifyContent: 'center',
                    }}
                  >
                    <Slider
                      aria-label="Always visible"
                      defaultValue={0}
                      getAriaValueText={valuetext}
                      // step={10}
                      value={impact}
                      onChange={handleChange}
                      marks={marks}
                      valueLabelDisplay="on"
                    />
                  </div>
                  <div>
                    {impactErr ? (
                      <span style={{ color: 'red', fontSize: '10px' }}>
                        This field is required
                      </span>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
                <div>
                  <b className="content_font">
                    4. Any other comments/suggestion
                  </b>
                  <br />

                  <Textarea
                    name="textTwo"
                    rows={textAreaMaxLine}
                    maxCharacters={200}
                    value={comment}
                    onChange={e => {
                      setComment(e.target.value);
                    }}
                    // onBlur={handleBlur}
                  />
                </div>
              </div>
            ) : (
              <div>
                <div style={{ marginBottom: '12px' }}>
                  <b className="content_font">
                    1. On a scale of 1 to 10, 10 being extremely likely, how
                    likely would you recommend, Big Robin to a friend or
                    colleague?
                  </b>
                  <br />
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      margin: '12px auto 6px auto',
                      justifyContent: 'center',
                    }}
                  >
                    {rating.map(item => {
                      return (
                        <div
                          className="feed_count"
                          style={{
                            background: item === itemOne ? '#ff9b2d' : '#fff',
                            color: item === itemOne ? '#fff' : '#111',
                          }}
                          onClick={() => setItemOne(item)}
                          key={item}
                        >
                          {item}
                        </div>
                      );
                    })}
                  </div>
                  {itemOne > 0 && (
                    <Textarea
                      name="textOne"
                      label={
                        <span className="content_font">
                          {`Why did you rate Big Robin ${
                            itemOne === 0 ? 1 : itemOne
                          } out of 10?`}
                        </span>
                      }
                      rows={textAreaMaxLine}
                      maxCharacters={500}
                      value={values.textOne}
                      onChange={e => {
                        setFieldValue(e.target.name, e.target.value);
                      }}
                      onBlur={handleBlur}
                    />
                  )}
                </div>
                <div>
                  <b className="content_font">
                    2. On a scale of 1 to 10, 10 being extremely satisfied, how
                    would you rate your overall experience here on the Big Robin
                    website?
                  </b>
                  <br />
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      margin: '12px auto 6px auto',
                      justifyContent: 'center',
                    }}
                  >
                    {rating.map(item => {
                      return (
                        <div
                          className="feed_count"
                          style={{
                            background: item === itemTwo ? '#ff9b2d' : '#fff',
                            color: item === itemTwo ? '#fff' : '#111',
                          }}
                          onClick={() => setItemTwo(item)}
                          key={item}
                        >
                          {item}
                        </div>
                      );
                    })}
                  </div>
                  <Textarea
                    name="textTwo"
                    label={
                      <span className="content_font">
                        How might it be made better or improved?
                      </span>
                    }
                    rows={textAreaMaxLine}
                    maxCharacters={500}
                    value={values.textTwo}
                    onChange={e => {
                      setFieldValue(e.target.name, e.target.value);
                    }}
                    onBlur={handleBlur}
                  />
                </div>
              </div>
            )}

            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                marginTop: '16px',
              }}
            >
              {loading ? (
                <>
                  <LoadingIndicator />
                </>
              ) : (
                <>
                  <Button
                    outline
                    fill
                    onClick={() => {
                      setIsShowingModal(null);
                      setItemOne(null);
                      setItemTwo(null);
                      // setShowingAction('');
                      resetForm();
                      history.push('/');
                    }}
                    style={{ margin: '0 5px' }}
                  >
                    Close
                  </Button>
                  <Button
                    fill
                    onClick={() => {
                      if (form === 'feedback') {
                        if (users === 'client') {
                          if (
                            projectRating === 0 &&
                            researcherRating === 0 &&
                            impact === 0
                          ) {
                            setprojectRatingErr(true);
                            setresearcherRatingErr(true);
                            setimpactErr(true);
                          } else if (
                            projectRating > 0 &&
                            researcherRating === 0 &&
                            impact === 0
                          ) {
                            setprojectRatingErr(false);
                            setresearcherRatingErr(true);
                            setimpactErr(true);
                          } else if (
                            projectRating === 0 &&
                            researcherRating > 0 &&
                            impact === 0
                          ) {
                            setprojectRatingErr(true);
                            setresearcherRatingErr(false);
                            setimpactErr(true);
                          } else if (
                            projectRating === 0 &&
                            researcherRating === 0 &&
                            impact > 0
                          ) {
                            setprojectRatingErr(true);
                            setresearcherRatingErr(true);
                            setimpactErr(false);
                          } else if (
                            projectRating > 0 &&
                            researcherRating > 0 &&
                            impact === 0
                          ) {
                            setprojectRatingErr(false);
                            setresearcherRatingErr(false);
                            setimpactErr(true);
                          } else if (
                            projectRating === 0 &&
                            researcherRating > 0 &&
                            impact > 0
                          ) {
                            setprojectRatingErr(true);
                            setresearcherRatingErr(false);
                            setimpactErr(false);
                          } else if (
                            projectRating > 0 &&
                            researcherRating === 0 &&
                            impact > 0
                          ) {
                            setprojectRatingErr(false);
                            setresearcherRatingErr(true);
                            setimpactErr(false);
                          } else {
                            sendUserFeedback();
                            history.push('/');
                          }
                        } else if (users === 'researcher') {
                          if (
                            clientRating === 0 &&
                            itemOne === 0 &&
                            itemTwo === 0
                          ) {
                            setclientRatingErr(true);
                            setitemOneErr(true);
                            setitemTwoErr(true);
                          } else if (
                            clientRating > 0 &&
                            itemOne === 0 &&
                            itemTwo === 0
                          ) {
                            setclientRatingErr(false);
                            setitemOneErr(true);
                            setitemTwoErr(true);
                          } else if (
                            clientRating === 0 &&
                            itemOne > 0 &&
                            itemTwo === 0
                          ) {
                            setclientRatingErr(true);
                            setitemOneErr(false);
                            setitemTwoErr(true);
                          } else if (
                            clientRating === 0 &&
                            itemOne === 0 &&
                            itemTwo > 0
                          ) {
                            setclientRatingErr(true);
                            setitemOneErr(true);
                            setitemTwoErr(false);
                          } else if (
                            clientRating > 0 &&
                            itemOne > 0 &&
                            itemTwo === 0
                          ) {
                            setclientRatingErr(false);
                            setitemOneErr(false);
                            setitemTwoErr(true);
                          } else if (
                            clientRating === 0 &&
                            itemOne > 0 &&
                            itemTwo > 0
                          ) {
                            setclientRatingErr(true);
                            setitemOneErr(false);
                            setitemTwoErr(false);
                          } else if (
                            clientRating > 0 &&
                            itemOne === 0 &&
                            itemTwo > 0
                          ) {
                            setclientRatingErr(false);
                            setitemOneErr(true);
                            setitemTwoErr(false);
                          } else {
                            sendUserFeedback();
                            history.push('/');
                          }
                        } else {
                          sendUserFeedback();
                          history.push('/');
                        }
                      } else {
                        sendFeedback();
                        history.push('/');
                      }
                    }}
                    style={{ margin: '0 5px' }}
                  >
                    Submit
                  </Button>
                </>
              )}
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        visible={!!successfulModa}
        setVisible={setSuccessfulModal}
        persistent
      >
        <div
          style={{
            backgroundColor: 'white',
            padding: '2rem',
            flexDirection: 'column',
            justifyContent: 'flex-end',
            maxWidth: `${'600px'}`,
          }}
        >
          <div style={{ marginBottom: '20px' }}>
            <b>Thanks for sharing your feedback!</b>
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginTop: '30px',
            }}
          >
            <Button
              fill
              onClick={() => {
                setSuccessfulModal(false);
              }}
            >
              OKAY
            </Button>
          </div>
        </div>
      </Modal>
      <Modal visible={!!discardModal} setVisible={setDiscardModal} persistent>
        <div
          style={{
            backgroundColor: 'white',
            padding: '2rem',
            flexDirection: 'column',
            justifyContent: 'flex-end',
            maxWidth: `${'400px'}`,
          }}
        >
          <div style={{ marginBottom: '20px' }}>
            <b>
              You've already submitted a response. In order to share additional
              feedback, write us to
              <a href={'mailto:feedback@bigrobin.com'}>
                {' '}
                feedback@bigrobin.com
              </a>
            </b>
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginTop: '30px',
            }}
          >
            <Button
              fill
              onClick={() => {
                setDiscardModal(false);
                history.push('/');
              }}
            >
              OKAY
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Feedback;
