import { CheckboxContainer, CheckboxLayout } from 'components/Checkbox/styled';
import { LabelLayout } from 'components/Input/styled';
import useClickOutside from 'hooks/useClickOutside';
import useNodeState from 'hooks/useNodeState';
import React, { useCallback, useLayoutEffect, useState } from 'react';
import { FaCaretDown, FaCheck } from 'react-icons/fa';
import {
  DropdownContainer,
  DropdownLayout,
  DropdownOption,
  DropdownOptionsContainer,
  DropdownValueLayout,
} from './styled';

const Dropdown = ({
  name,
  className,
  label,
  optionLabels = {},
  render = value => optionLabels[value] || value,
  options,
  value,
  onChange,
  onBlur,
  icon,
  disabled,
  style,
  multi,
  error,
  errorMessage,
  required,
}) => {
  const [isShowingMenu, setIsShowingMenu] = useState(false);
  const [menuStyle, setMenuStyle] = useState({ top: 0, width: 0 });
  const [containerRefCB, containerEl, containerRef] = useNodeState();
  const [areAllSelected, setAllSelected] = useState(false);

  useClickOutside(
    containerRef,
    isShowingMenu,
    useCallback(() => {
      if (onBlur) {
        onBlur({ target: { name } });
      }
      setIsShowingMenu(false);
    }, [name, onBlur])
  );

  useLayoutEffect(() => {
    if (isShowingMenu && containerEl) {
      const box = containerEl.getBoundingClientRect();
      setMenuStyle({ top: box.height, width: box.width });
    }
  }, [containerEl, isShowingMenu]);

  useLayoutEffect(() => {
    if (multi) setAllSelected(value?.length === options?.length);
  }, [value, options]);

  return (
    <DropdownContainer ref={containerRefCB} className={className} style={style}>
      <select name={name} onChange={onChange} onBlur={onBlur}>
        <option value="" />
        {options?.map(option => {
          return (
            <option value={option} key={option}>
              {render(option)}
            </option>
          );
        })}
      </select>
      {label && (
        <LabelLayout>
          <label>
            {label} {required && <sup style={{ color: 'red' }}>*</sup>}
          </label>
        </LabelLayout>
      )}

      <DropdownLayout
        // error={error}
        onClick={() =>
          !disabled && setIsShowingMenu(isShowingMenu => !isShowingMenu)
        }
      >
        {icon || null}
        <DropdownValueLayout>
          {value && (multi ? `${value?.length} selected` : render(value))}
        </DropdownValueLayout>
        {!disabled && <FaCaretDown />}
      </DropdownLayout>
      {isShowingMenu && (
        <DropdownOptionsContainer style={menuStyle}>
          {multi && options?.length > 0 && (
            <DropdownOption
              key={'Select All'}
              onClick={() => {
                onChange({
                  target: {
                    name,
                    value: areAllSelected ? [] : options,
                  },
                });
                setAllSelected(!areAllSelected);
              }}
            >
              <CheckboxLayout>
                <CheckboxContainer>
                  {areAllSelected && <FaCheck />}
                </CheckboxContainer>
                Select All
              </CheckboxLayout>
            </DropdownOption>
          )}
          {options?.map(option => {
            const isSelected =
              value?.length > 0 && multi && value?.find(v => v === option)
                ? true
                : false;
            return (
              <DropdownOption
                key={option}
                onClick={() => {
                  if (multi && isSelected) {
                    setAllSelected(false);
                  }
                  onChange({
                    target: {
                      name,
                      value: multi
                        ? isSelected
                          ? value?.filter(v => v !== option)
                          : [...value, option]
                        : option,
                    },
                  });
                  if (!multi) {
                    setIsShowingMenu(false);
                  }
                }}
              >
                {multi ? (
                  <CheckboxLayout>
                    <CheckboxContainer>
                      {isSelected && <FaCheck />}
                    </CheckboxContainer>
                    {render(option)}
                  </CheckboxLayout>
                ) : (
                  render(option)
                )}
              </DropdownOption>
            );
          })}
        </DropdownOptionsContainer>
      )}
      {error && errorMessage && (
        <p
          style={{
            color: 'red',
            marginTop: '4px',
            fontSize: '14px',
            textAlign: 'left',
          }}
        >
          {errorMessage}
        </p>
      )}
    </DropdownContainer>
  );
};

export default Dropdown;
