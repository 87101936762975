import { useAuthState } from 'context/AuthContext';
import React from 'react';
import {
  // FaFacebook,
  FaLinkedin,
  FaRegCopyright,
  // FaRssSquare,
  FaRegEnvelope,
} from 'react-icons/fa';
import { useLocation } from 'react-router';
import {
  DirectoryContainer,
  FooterContent,
  FooterHeader,
  FooterSocialLayout,
  LeftFooterContent,
  AddressContent,
} from './styled';

const directory = [
  [
    { title: 'Home', link: '/' },
    // { title: 'Researchers', link: '/researchers' },
    // { title: 'How It Works', link: '/' },
    // { title: 'Case Studies', link: '/' },
    // { title: 'Join', link: '/login?mode=su' },
    { title: 'Login', link: '/login' },
  ],
  [
    { title: 'About', link: '/about' },
    // { title: 'Blog', link: '/' },
    // { title: 'Support', link: '/' },
    // { title: 'Careers', link: '/' },
    { title: 'Terms and Conditions', link: '/terms-and-condition' },
    { title: 'Privacy Policy', link: '/privacy-policy' },
    // { title: 'Fees', link: '/fees' },
  ],
];

const Footer = () => {
  const location = useLocation();
  const { isGettingUser } = useAuthState();

  if (isGettingUser) return null;

  if (location.pathname.startsWith('/admin')) return null;

  return (
    <FooterContent>
      <LeftFooterContent>
        <div>
          <FooterHeader>Contact Us</FooterHeader>
          <FooterSocialLayout>
            {/* <a href="/">
              <FaFacebook />
            </a> */}
            <a
              href="https://www.linkedin.com/company/big-robin/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaLinkedin />
            </a>
            {/* <a href="/">
              <FaRssSquare />
            </a> */}
            <a href="mailto:support@bigrobin.com">
              <FaRegEnvelope />
            </a>
          </FooterSocialLayout>
        </div>
        {directory.map((column, index) => (
          <DirectoryContainer key={index}>
            {column.map((item, idx) => (
              <p key={index + '-' + idx}>
                <a href={item.link} target="_blank" rel="noopener noreferrer">
                  {item.title}
                </a>
              </p>
            ))}
          </DirectoryContainer>
        ))}
      </LeftFooterContent>
      <div>
        <AddressContent>
          Canada: <br />
          Suite 210-186 <br />
          8120 No. 2 Road <br />
          Richmond BC V7C 5J8 <br />
          <br />
          USA: <br />
          5965 Village Way <br />
          Ste E105 #121 <br />
          San Diego, CA 92130 <br />
        </AddressContent>
        {/* <AddressContent>
          Big Robin LLC 2244 Faraday Avenue, #185
          <br />
          Carlsbad, CA 92008
          <br />
          USA
        </AddressContent> */}
        <br />
        <br />
        <p>
          <FaRegCopyright /> {new Date().getFullYear()} All Rights Reserved -
          BigRobin.com
        </p>
      </div>
    </FooterContent>
  );
};

export default Footer;
