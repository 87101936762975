import React from 'react';
import {
  PageTitle,
  PageContainer,
  PageLabel,
  BalanceContent,
  BalanceTitle,
  BalanceBreakdown,
  BalanceLabel,
  BalanceAmount,
  BalanceTotalLabel,
  PPReactTooltip,
  PageButtonLayout,
  ContactNote,
} from './styled';
import { Link } from 'react-router-dom';
// import { Row } from 'components/Layout';
import Button from 'components/Button';
import { FaInfoCircle } from 'react-icons/fa';

export const PaymentBreakdown = props => {
  return (
    <>
      <PageTitle>
        {props.type === 'accept' ? 'Remaining Balance' : 'Mobilisation Fee'}
      </PageTitle>
      <PageContainer>
        {props.type === 'accept' ? (
          <PageLabel>
            Congratulations on finishing your project! Clicking Accept Output
            will mean the conclusion of your project and your contact with
            {props.researcher && props.researcher.first_name
              ? ' ' + props.researcher.first_name
              : ' '}
            . You can now pay your remaining balance to
            {props.researcher && props.researcher.first_name
              ? ' ' + props.researcher.first_name
              : ' '}
            .
          </PageLabel>
        ) : (
          <PageLabel>
            Thank you for approving the proposal - '{props?.project?.title}'.
            Please pay
            {props.researcher && props.researcher.first_name
              ? ' ' + props.researcher.first_name + ' '
              : ' '}
            the deposit for the project to start.
          </PageLabel>
        )}
        <BalanceContent>
          <BalanceTitle>BREAKDOWN</BalanceTitle>
          {props.type === 'accept' ? (
            <BalanceBreakdown>
              <BalanceLabel>Total Engagement</BalanceLabel>
              <BalanceAmount>
                {/* {props.project && props.project.actual_price
                  ? '$ ' + props.project.actual_price
                  : '$ 0'} */}
                {props?.bit_amount ? '$ ' + props.bit_amount : '$ 0'}
              </BalanceAmount>
            </BalanceBreakdown>
          ) : (
            <BalanceBreakdown>
              <BalanceLabel>Total Engagement</BalanceLabel>
              <BalanceAmount>
                {props?.bit_amount ? '$ ' + props.bit_amount : '$ 0'}
              </BalanceAmount>
            </BalanceBreakdown>
          )}
          {props.type === 'accept' ? (
            <BalanceBreakdown>
              <BalanceLabel>
                {'Downpayment (' + props.mobilisation_percent + '%)'}
              </BalanceLabel>
              <BalanceAmount>{'- $ ' + props.mobilisation_fee}</BalanceAmount>
            </BalanceBreakdown>
          ) : (
            <BalanceBreakdown>
              <BalanceLabel>
                Deposit Membership Rate&nbsp;
                <FaInfoCircle data-tip data-for="dmrInfo" color="#ff9e08" />
              </BalanceLabel>
              <PPReactTooltip
                id="dmrInfo"
                effect="solid"
                backgroundColor="#ff9e08"
                multiline={true}
              >
                <div>Membership Rate Schedule</div>
              </PPReactTooltip>
              <BalanceAmount>{props.mobilisation_percent + ' %'}</BalanceAmount>
            </BalanceBreakdown>
          )}
          {/* <BalanceBreakdown>
            <BalanceLabel>Stripe Fee</BalanceLabel>
            <BalanceAmount>{'3 %'}</BalanceAmount>
          </BalanceBreakdown> */}
          {props.type === 'accept' ? (
            <BalanceBreakdown>
              <BalanceTotalLabel>Total Remaining Balance</BalanceTotalLabel>
              <BalanceAmount>{'$ ' + props.remaining_balance}</BalanceAmount>
            </BalanceBreakdown>
          ) : (
            <BalanceBreakdown>
              <BalanceTotalLabel>Deposit due today</BalanceTotalLabel>
              <BalanceAmount>{'$ ' + props.mobilisation_fee}</BalanceAmount>
            </BalanceBreakdown>
          )}
          {props.type === 'accept' ? (
            <ContactNote>
              Please <Link to="#">contact Researcher</Link> if any changes need
              to be made to the amount or due date.
            </ContactNote>
          ) : null}
        </BalanceContent>
        <PageButtonLayout justifyContent="space-between">
          <Button
            backgroundColor="#dadadf"
            color="#65637b"
            onClick={() => {
              props.moveToPreviousPage();
            }}
          >
            CANCEL
          </Button>
          <Button
            style={{ width: '250px' }}
            onClick={() => {
              props.moveToNextPage(2);
            }}
          >
            CONTINUE TO PAYMENT
          </Button>
        </PageButtonLayout>
      </PageContainer>
    </>
  );
};
