import React from 'react';
import styled from 'styled-components/macro';

export const BaseContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const MiddleContainer = styled.div`
  width: 100%;
  max-width: 1400px;
  flex: 1;
  margin: 0 5rem;

  @media (min-width: 768px) and (max-width: 900px) {
    margin: 0 8rem;
  }
`;

const Content = ({ children, className }) => {
  return (
    <BaseContainer className={className}>
      <MiddleContainer>{children}</MiddleContainer>
    </BaseContainer>
  );
};

export default Content;
