import Button from 'components/Button';
import {
  DropdownContainer,
  DropdownLayout,
  DropdownValueLayout,
} from 'components/Dropdown/styled';
import { Row } from 'components/Layout';
import styled, { css } from 'styled-components/macro';

export const DropdownPopupContainer = styled(DropdownContainer)`
  ${DropdownLayout} {
    padding: 1.5rem 1rem;
    background-color: white;
    svg {
      &:first-child {
        color: rgba(164, 164, 164, 1);
      }
    }
    ${props =>
      props.isShowing &&
      css`
        border-color: #f58c06;
        border-width: 1px;
      `}

    ${props =>
      props.isHighlighted &&
      css`
        border-color: #f58c06;
        border-width: 2.5px;
      `}

    ${DropdownValueLayout} {
      font-weight: bold;
      margin: 0 1rem;
      min-height: auto;
      line-height: 1;
    }
  }
`;

export const DropdownPopupMenuContainer = styled.div`
  z-index: 10;
  position: absolute;
  top: 1rem;
  left: 0;

  box-shadow: 0px 3px 6px #00000029;
  background-color: white;
  padding: 1rem 2rem;

  @media (max-width: 768px) {
    position: relative;
    top: 0rem;
    left: 0rem;
    box-shadow: none;
    padding: 1.5rem;
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
    border: 1px solid #65637b3c;
    border-radius: 8px;
    overflow: hidden;
  }
`;

export const DropdownPopupMenuButtonsContainer = styled(Row)`
  margin-top: 2rem;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: nowrap;

  ${Button} {
    &:first-child {
      font-size: 1.4rem;
      font-weight: normal;
      color: rgba(75, 76, 94, 0.54);
      margin-right: 1rem;

      @media (max-width: 768px) {
        font-size: 1.5rem;
      }
    }
  }
`;
