import Content from 'components/Content';
import styled from 'styled-components/macro';
import { Row, Column } from 'components/Layout';
import { Link } from 'react-router-dom';

export const BreadcrumbLayout = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    flex-flow: row wrap;
  }

  svg {
    font-size: 1.4rem;
    margin-right: 1rem;
  }
`;

export const BreadcrumbLink = styled(Link)`
  font-size: 1.6rem;
  font-weight: lighter;
  color: #65637b;
  text-decoration: underline;
  margin-right: 1rem;

  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

export const BreadcrumbEnd = styled.span`
  font-size: 1.6rem;
  color: #65637b;

  @media (max-width: 768px) {
    font-size: 2rem;
    font-weight: bold;
  }
`;

export const BreadcrumbMiddle = styled.span`
  font-size: 1.6rem;
  font-weight: lighter;
  color: #65637b;
  text-decoration: underline;
  margin-right: 1rem;

  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

export const PageContent = styled(Content)`
  margin: 4rem 0;
`;

export const PageButtonLayout = styled(Row)`
  margin: 4rem 0;
`;

export const ModalContent = styled(Row)`
  background-color: white;
  padding: 2rem;
  flex-direction: column;
  justify-content: flex-end;
  min-width: 200px;
  min-height: 100px;
}
`;

export const ModalMsg = styled(Column)`
  margin-bottom: 4rem;
  margin-top: 2rem;
`;

export const ModalButton = styled(Column)`
  margin-bottom: 1rem;
`;
