import Searchbar from 'components/Searchbar';
import React, { useEffect, useState } from 'react';
import { useAuthState } from 'context/AuthContext';
import { Link, useHistory } from 'react-router-dom';
import {
  BackgroundImage,
  GrayGradientContainer,
  HeroBannerContainer,
  PopularTopics,
  TellProjectButton,
  JoinButton,
  HBButtonRow,
} from './styled';
import { ajax } from 'utils/ajax';
import { Container } from 'pages/ShareProject';
import { RealtimeContainer } from 'pages/ResearcherList/styled';

// const popularTopics = [
//   'Market Research Future/Trends',
//   'Algorithmic Research Scenario Planning',
//   'Market Research Clustering',
// ];

const popularTopics = ['Business Need/Specialization'];

const HeroBanner = ({ showResults }) => {
  const history = useHistory();
  const [search, setSearch] = useState('');
  const { user, isGettingUser } = useAuthState();
  const [realtimeSearch, setRealtimeSearch] = useState([]);
  const [realtimeOptions, setRealtimeOptions] = useState([]);
  const [isFocus, setFocus] = useState(false);

  useEffect(() => {
    ajax('GET', '/member_reference_dropdown').then(res => {
      if (res.code === 200) {
        let arr = [];
        res.data.analytics_research.map(item => {
          arr.push({ ...item, type: 'specialization' });
        });
        res.data.market_research.map(item => {
          arr.push({ ...item, type: 'specialization' });
        });
        res.data.industry.map(item => {
          arr.push({ ...item, type: 'industry' });
        });
        setRealtimeOptions(arr);
      }
    });
  }, []);

  // const handleRealTimeSearch = search => {
  //   const realtimeArray = [];
  //   const realtimeArrayChecker = [];
  //   if (search !== '') {
  //     realtimeOptions.map(item => {
  //       if (
  //         item.name &&
  //         item.name.toLowerCase().includes(search.toLowerCase()) &&
  //         !realtimeArrayChecker.includes(item.name.toLowerCase())
  //       ) {
  //         realtimeArrayChecker.push(item.name);
  //         realtimeArray.push(item);
  //       }
  //       setRealtimeSearch(realtimeArray);
  //     });
  //   } else setRealtimeSearch([]);
  // };

  return (
    <HeroBannerContainer>
      <GrayGradientContainer>
        <BackgroundImage src={require('./background.png')} alt="" />
      </GrayGradientContainer>

      <h1>{`What business challenge\ncan we help you solve?`}</h1>
      <div style={{ flex: 3, position: 'relative' }}>
        <div style={{ position: 'relative' }}>
          <Searchbar
            value={search}
            placeholder="Type in your topic or question"
            onChange={text => {
              setFocus(true);
              // handleRealTimeSearch(text);
              setSearch(text);
            }}
            onKeyPress={event => {
              if (event === 'Enter' && search !== '') {
                showResults(search);
              }
            }}
            isSearchBtn={true}
            handleSearchClick={() => search !== '' && showResults(search)}
          />
        </div>
        {isFocus && realtimeSearch && realtimeSearch.length > 0 && (
          <Container
            style={{
              minHeight: '5rem',
              maxHeight: '26rem',
              background: '#fff',
              position: 'absolute',
              zIndex: '99',
              width: 'fit-content',
              marginTop: '-2.5rem',
            }}
          >
            {realtimeSearch.map((item, index) => {
              return (
                <RealtimeContainer key={index}>
                  <div
                    style={{
                      color: '#4B4C5E',
                      fontSize: '1.3rem',
                      fontWeight: 'bold',
                      marginBottom: '1rem',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      setFocus(false);
                      setSearch('');

                      showResults('', item);
                    }}
                  >
                    {item.name}
                  </div>
                </RealtimeContainer>
              );
            })}
          </Container>
        )}
      </div>
      <PopularTopics>
        <span>Popular: </span>
        {popularTopics.map(topic => (
          <Link key={topic} onClick={() => showResults(topic)}>
            {topic}
          </Link>
        ))}
      </PopularTopics>

      {isGettingUser ? (
        <HBButtonRow justifyContent="space-between" />
      ) : user ? (
        <HBButtonRow justifyContent="space-between">
          <TellProjectButton onClick={() => history.push('/researchers')}>
            FIND A RESEARCHER
          </TellProjectButton>
        </HBButtonRow>
      ) : (
        <HBButtonRow justifyContent="space-between">
          <TellProjectButton onClick={() => history.push('/researchers')}>
            FIND A RESEARCHER
          </TellProjectButton>
          <JoinButton
            onClick={() => {
              history.push({
                pathname: '/login',
                state: { isSignup: true, isResearcher: true },
              });
            }}
          >
            JOIN AS A RESEARCHER
          </JoinButton>
        </HBButtonRow>
      )}
    </HeroBannerContainer>
  );
};

export default HeroBanner;
