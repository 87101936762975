import styled from 'styled-components/macro';
import Content, { MiddleContainer } from 'components/Content';
import { Row } from 'components/Layout';
import Button from 'components/Button';
import ReactTooltip from 'react-tooltip';
import Searchbar from 'components/Searchbar';

export const ProjectContent = styled(Content)`
  flex: 1;

  ${MiddleContainer} {
    margin-top: 4rem;
    margin-bottom: 4rem;
    padding: 4rem 2rem;

    @media (max-width: 768px) {
      padding: 2rem;
      margin: 2rem;
    }
  }
`;

export const ProjectsSearchbar = styled(Searchbar)`
  flex-grow: 1;

  input {
    color: #4b4c5e;

    &::placeholder {
      color: #bab9be;
    }
  }

  @media (max-width: 900px) {
    width: 100%;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
`;

export const ProjectTitle = styled.h1`
  margin-top: 3rem;
  font-size: 2.4rem;
  margin-bottom: 1rem;

  @media (max-width: 768px) {
    margin-top: 4rem;
    font-size: 3rem;
    margin-bottom: 2rem;
  }
`;

export const ProjectContainer = styled.div`
  background-color: white;
  padding: 4rem;
`;

export const ProjectDesc = styled.div`
  font-size: 1.8rem;
  margin-bottom: 4rem;

  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

export const ShareRow = styled(Row)`
  @media (max-width: 768px) {
    display: block;
  }
`;

export const ShareContainer = styled.div`
  flex: 1;
  margin-right: 2rem;

  @media (max-width: 768px) {
    margin-right: 0;
    margin-bottom: 4rem;
  }

  &:last-child {
    margin-right: 0;

    @media (max-width: 768px) {
      margin-bottom: 0;
    }
  }
`;

export const ShareLabel = styled.div`
  color: #4b4c5e;
  font-weight: bold;
`;

export const ShareInstructions = styled.div`
  color: #65637b;
  font-size: 1.3rem;

  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
`;

export const Container = styled.div`
  margin-top: 1rem;
  border-radius: 0.8rem;
  border: 1px solid #65637b3c;
  overflow-y: auto;
`;

export const RecommendedResearcher = styled.div`
  display: flex;
  align-items: center;
  padding: 1rem 2rem;

  border-bottom: 1px solid #65637b3c;

  &:last-child {
    border-bottom: 0;
  }

  img {
    height: 4.3rem;
    width: 4.3rem;
    object-fit: contain;
    border-radius: 50%;
    margin-right: 2rem;
  }

  button {
    border-radius: 0.8rem;
    span {
      display: flex;
      align-items: center;
      svg {
        font-size: 1.4rem;
      }
    }
  }
`;

export const ResearcherTag = styled.div`
  color: #4b4c5e;
  font-size: 1.3rem;
  border-radius: 1.6rem;
  padding: 0.5rem 1rem;
  background-color: #dadadf;
  margin-right: 1rem;
  margin-bottom: 1rem;

  display: flex;
  align-items: center;
  svg {
    cursor: pointer;
    margin-left: 0.5rem;
  }
`;

export const InviteButton = styled(Button)`
  width: 30rem;
  justify-content: center;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const SPReactTooltip = styled(ReactTooltip)`
  max-width: 25%;
  word-wrap: normal;

  @media (max-width: 1000px) {
    max-width: 50%;
  }
  @media (max-width: 600px) {
    max-width: 50%;
  }
`;
