import { InputContainer, LabelLayout } from 'components/Input/styled';
import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { FaFileUpload, FaTimes } from 'react-icons/fa';
import { FileInputContainer, FilesContainer } from './styled';

const FileInput = ({
  label,
  value,
  className,
  style,
  onChange,
  name,
  path,
  ndaTemplate,
  updateNdaTemplate,
  multiple = false,
  disabled = false,
  isImage = false,
  ...inputProps
}) => {
  const { getInputProps, getRootProps, isDragActive } = useDropzone({
    onDrop: async files => {
      if (isImage) {
        if (files[0]?.type.startsWith('image/')) {
          let newFile = files[0];
          if (multiple) {
            if (
              value.findIndex(
                f => f.new_upload === true && f.url.path === newFile.path
              ) > -1
            ) {
              return;
            }
            const file_temp = {
              filename: newFile.name,
              url: newFile,
              new_upload: true,
              key: '',
            };
            let newFileArr = [...value, file_temp];
            onChange(newFileArr);
          } else {
            const file_temp = {
              filename: newFile.name,
              url: newFile,
              new_upload: true,
              key: '',
            };
            onChange([file_temp]);
          }
        } else {
          alert('Please select a valid image file.');
          files = [];
          // event.target.value = '';
        }
      } else {
        let newFile = files[0];
        if (multiple) {
          if (
            value.findIndex(
              f => f.new_upload === true && f.url.path === newFile.path
            ) > -1
          ) {
            return;
          }
          const file_temp = {
            filename: newFile.name,
            url: newFile,
            new_upload: true,
            key: '',
          };
          let newFileArr = [...value, file_temp];
          onChange(newFileArr);
        } else {
          const file_temp = {
            filename: newFile.name,
            url: newFile,
            new_upload: true,
            key: '',
          };
          onChange([file_temp]);
        }
      }
    },
  });

  const handlePreviewFile = file => {
    let typeDate = typeof file;
    if (typeDate === 'object') {
      const fileURL = URL.createObjectURL(file);
      openPreviewInNewTab(fileURL);
    } else {
      openPreviewInNewTab(file);
    }
  };

  const openPreviewInNewTab = res => {
    window.open(res, '_blank');
  };

  return (
    <InputContainer className={className} style={style}>
      <LabelLayout>
        <label>{label}</label>
      </LabelLayout>

      <div
        style={{
          display: 'grid',
          // gridTemplateColumns: `${value.length > 0 ? 'auto auto' : 'auto'}`,
          // height: '100%',
        }}
      >
        <FileInputContainer
          {...getRootProps()}
          style={{ cursor: disabled ? 'not-allowed' : 'pointer' }}
        >
          <input
            {...getInputProps()}
            name={name}
            disabled={disabled}
            {...inputProps}
            multiple={multiple}
          />
          <FaFileUpload />

          {isDragActive ? (
            <p>Drop files here...</p>
          ) : (
            <>
              <p>{`Drag & drop file`}</p>
            </>
          )}
        </FileInputContainer>
        {ndaTemplate ? (
          <div style={{ color: 'red', fontSize: '10px' }}>
            *NDA is needed to proceed further
          </div>
        ) : (
          <div> </div>
        )}
        {value.length > 0 && (
          <FilesContainer>
            {value.map((file, index) => (
              <p key={index}>
                <span
                  style={{ textDecoration: 'underline', cursor: 'pointer' }}
                  onClick={() => {
                    handlePreviewFile(file.url);
                  }}
                >
                  {file.filename}
                </span>
                <FaTimes
                  onClick={() => {
                    onChange([
                      ...value.slice(0, index),
                      ...value.slice(index + 1, value.length),
                    ]);
                  }}
                  style={{
                    marginLeft: '0.5rem',
                    cursor: 'pointer',
                    marginBottom: 0,
                  }}
                />
              </p>
            ))}
          </FilesContainer>
        )}
      </div>
    </InputContainer>
  );
};

export default FileInput;
