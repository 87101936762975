import React from 'react';
import { FaSearch } from 'react-icons/fa';
import { SearchbarContainer } from './styled';

const Searchbar = ({
  className,
  value,
  onChange,
  onKeyPress = () => {},
  onBlur,
  placeholder = 'Search',
  icon = true,
  isSearchBtn,
  handleSearchClick,
}) => {
  return (
    <SearchbarContainer className={className}>
      {icon && <FaSearch />}
      <input
        type="text"
        value={value}
        onBlur={onBlur}
        onChange={event => {
          event.persist();
          onChange(event.target.value);
        }}
        onKeyPress={event => {
          event.persist();
          onKeyPress(event.key);
        }}
        placeholder={placeholder}
        maxLength={50}
      />
      {isSearchBtn && (
        <div
          style={{
            position: 'absolute',
            color: 'black',
            right: '10px',
            cursor: 'pointer',
          }}
          onClick={handleSearchClick}
        >
          <div
            style={{
              backgroundColor: '#ff9e08',
              color: 'white',
              fontSize: '1.5rem',
              fontWeight: 'bold',
              borderRadius: '4px',
              padding: '4px 8px',
            }}
          >
            SEARCH
          </div>
        </div>
      )}
    </SearchbarContainer>
  );
};

export default Searchbar;
