import Checkbox from 'components/Checkbox/Checkbox';
import { LabelLayout } from 'components/Input/styled';
import React from 'react';
import { CheckboxGroupContainer } from './styled';

const CheckboxGroup = ({
  type = 'checkbox',
  label,
  name,
  value,
  options,
  optionLabels,
  onChange,
  onBlur,
  className,
}) => {
  return (
    <CheckboxGroupContainer className={className}>
      {label && (
        <LabelLayout>
          <label htmlFor={name}>{label}</label>
        </LabelLayout>
      )}

      {options.map(option => {
        const new_option = typeof option === 'object' ? option.id : option;
        const label = typeof option === 'object' ? option.name : option;
        const optionLabel =
          typeof optionLabels === 'function'
            ? optionLabels(label)
            : optionLabels
            ? optionLabels[label]
            : label;
        return (
          <div key={new_option}>
            <Checkbox
              name={name}
              type={type}
              value={new_option}
              checked={
                type === 'checkbox'
                  ? value.indexOf(new_option.toString()) !== -1
                  : value === new_option
              }
              label={optionLabel}
              onChange={onChange}
              onBlur={onBlur}
            />
          </div>
        );
      })}
    </CheckboxGroupContainer>
  );
};

export default CheckboxGroup;
