import {
  InputContainer,
  InputLayout,
  LabelLayout,
  LeftLabel,
} from 'components/Input/styled';
import { SearchbarContainer } from 'components/Searchbar/styled';
import { ResearcherFilterContainer } from 'components/ResearcherFilter/styled';
import styled from 'styled-components/macro';

export const RealtimeContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 1rem 2rem;

  border-bottom: 1px solid #65637b3c;

  &:last-child {
    border-bottom: 0;
  }

  img {
    height: 4.3rem;
    width: 4.3rem;
    object-fit: contain;
    border-radius: 50%;
    margin-right: 2rem;
  }

  button {
    border-radius: 0.8rem;
    span {
      display: flex;
      align-items: center;
      svg {
        font-size: 1.4rem;
      }
    }
  }
`;

export const ResearcherListContainer = styled.div`
  margin: 4rem 0;

  ${ResearcherFilterContainer} {
    margin-top: 2rem;
  }
`;

export const LoadMoreLayout = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5rem;
`;

export const LoadMoreButton = styled.button`
  background-color: #ff9e08;
  color: white;
  font-size: 1.5rem;
  font-weight: bold;
  padding: 1.2rem 2.2rem;

  @media (max-width: 768px) {
    font-size: 2rem;
    padding: 1.75rem 0rem;
    width: 100%;
  }
`;

export const SearchbarLayout = styled.div`
  display: flex;

  @media (max-width: 768px) {
    display: block;
  }

  h6 {
    font-size: 1.4rem;
    margin-left: 2rem;
    margin-bottom: 0.5rem;
  }

  ${InputLayout} {
    background-color: white;
    input {
      &::placeholder {
        font-weight: bold;
        color: #4b4c5e;
        opacity: 0.5;
      }
      padding: 1.5rem;
    }
    ${LeftLabel} {
      margin-left: 1.5rem;
    }
  }

  ${LabelLayout} {
    display: none;
  }

  ${InputContainer} {
    flex: 2;
    &:first-child {
      margin-right: 4rem;
      flex: 3;

      @media (max-width: 768px) {
        margin-right: 0rem;
        margin-bottom: 0.5rem;
        flex: 2;
      }
    }
  }

  ${SearchbarContainer} {
    flex: 2;
    &:first-child {
      margin-right: 4rem;
      flex: 3;

      @media (max-width: 768px) {
        margin-right: 0rem;
        margin-bottom: 0.5rem;
        flex: 2;
      }
    }
  }
`;

export const FiltersLayout = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  color: #4b4c5e;

  & > * {
    margin-left: 2rem;
  }
`;

export const FilterContainer = styled.div`
  display: flex;
  padding: 1.5rem 1rem;
  padding-left: 2rem;
  background-color: white;
  border-radius: 10px;
  border: 1px solid ${props => props.theme.lightGray};
  cursor: pointer;

  svg {
    font-size: 2rem;

    &:first-child {
      margin-right: 2rem;
    }
  }
`;
