export const INDUSTRY_LABELS = {
  '1': 'Agriculture and Mining',
  '2': 'Banking, Insurance and Finance',
  '3': 'Energy & Transportation',
  '4': 'Education and Public Affairs',
  '5': 'Information Technology, Telecommunications',
  '6': 'Media and Entertainment',
  '7': 'Utilities',
  '8': 'Real Estate',
  '9': 'Healthcare/Pharma',
  '10': 'Consumer Packaged Goods',
  '11': 'Consumer Durables',
  '12': 'Consumer and Professional Services',
  '13': 'Fashion and Apparel',
  '14': 'Business Services',
  '15': 'Retailing',
  '16': 'Other',
};

export const INDUSTRY_VALUES = [
  1,
  2,
  14,
  12,
  11,
  10,
  4,
  3,
  13,
  9,
  5,
  6,
  8,
  15,
  7,
  16,
];

export const errorMessage = 'This field is required';
