import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import Button from 'components/Button';
import {
  AgreementContent,
  AgreementLayout,
  AgreementTitle,
  AgreementSubTitle,
  AgreementTextBlock,
  AgreementBlockTitle,
} from './styled';
import { RPCheckbox } from 'components/ProjectForm';
import { useHistory } from 'react-router-dom';
import { SPReactTooltip } from 'pages/ResearcherProfileForm';
import { useAuthState } from 'context/AuthContext';

export const TermsAndCondition = () => {
  const handleClose = () => {
    localStorage.setItem('agree', 'true');
    window.close();
  };

  const { isResearcher } = useAuthState();

  const isLogin = localStorage.getItem('br-userId');

  const history = useHistory();
  const queryParams = new URLSearchParams(history.location.search);

  const user = queryParams.get('user');

  const [age, setAge] = useState('');
  const [authorized, setAuthorized] = useState('');
  const [prohibited, setProhibited] = useState('');

  const [isDisabled, setIsDisabled] = useState(true);
  const [isTooltip, setIsTooltip] = useState(false);

  useEffect(() => {
    if (isLogin) {
      setAge('yes');
      setProhibited('no');
    }
  }, [isLogin]);

  useEffect(() => {
    if (isResearcher) {
      setAuthorized('yes');
    }
  }, [isResearcher]);

  useEffect(() => {
    if (user === 'r') {
      if (
        prohibited &&
        authorized &&
        age &&
        (authorized !== 'yes' || prohibited !== 'no' || age !== 'yes')
      ) {
        setIsTooltip(true);
      } else {
        setIsTooltip(false);
      }
      if (prohibited === 'no' && authorized === 'yes' && age === 'yes') {
        setIsDisabled(false);
      } else {
        setIsDisabled(true);
      }
    } else {
      if (prohibited === 'no' && age === 'yes') {
        setIsTooltip(false);
        setIsDisabled(false);
      } else {
        setIsTooltip(true);
        setIsDisabled(true);
      }
    }
  }, [age, authorized, prohibited]);

  return (
    <>
      <AgreementContent>
        <AgreementLayout>
          <AgreementTitle>Big Robin Terms of Use</AgreementTitle>
          <section>
            <AgreementTextBlock>
              These Terms of Use (“Terms”) govern your access to and use of the
              Bigrobin.com website (the “Website”) and any Big Robin mobile
              applications and Services (collectively, the “Services”). You
              agree that by visiting the Services or by registering or using the
              Services, you are entering a legally binding agreement with Big
              Robin Holdings Inc. and Big Robin USA LLC (“Big Robin”) based on
              the Terms, the Privacy Policy, the Non-Disclosure Agreement and
              any applicable Payment Terms, which are hereby incorporated by
              reference (collectively referred to as the “Agreement”) Your
              access to and use of the Services are conditioned on your
              acceptance of and compliance with the Agreement. By accessing or
              using the Services you agree to be bound by the Agreement.
            </AgreementTextBlock>
          </section>
          <section>
            <AgreementSubTitle>
              1. The Services as a Marketplace
            </AgreementSubTitle>
            <AgreementTextBlock>
              Big Robin operates as a marketplace connecting skilled data
              scientists, market and research analytic professionals
              (collectively, “Researchers”) with businesses and private
              individuals in need of these services (collectively, “Member
              Clients”). Big Robin is not a party to any agreement or contract
              that results from a connection made on or through the Services,
              including without limitation, any non-disclosure agreements
              related to the services, between and among Researchers and Member
              Clients (“Service Contracts”). The parties to any Service
              Contracts are solely responsible for verifying that the Service
              Contracts are executed and performed in accordance with all
              applicable laws and regulations, including without limitation, tax
              laws.&nbsp;
              <strong>
                You expressly agree that Big Robin has no obligations under any
                Service Contracts and that Big Robin shall not be included as a
                party in any dispute related to any Service Contracts.
              </strong>
            </AgreementTextBlock>
            <AgreementTextBlock>
              Big Robin makes no representations or endorsements regarding any
              user, Researcher, Member Client, business or credentials posted on
              or through the Services. Big Robin expressly disclaims any
              liability, including personal injury or death, that results from
              any interaction with any other user of the Services or otherwise
              related to your use of the Services. Please be aware that Big
              Robin does the following to verify the identities of its users:
              requiring Government-issued proof of identity, relevant employment
              and education attainment, professional profile on linked-in, and a
              referral from within Big Robin’s user network. However, we make no
              representations related to the background, identity,
              qualifications or suitability for a project for any Big Robin user
              and we will not be liable for any incorrect, false or inaccurate
              information provided by or related to any Big Robin user. We urge
              you to use reasonable caution before communicating with any other
              user of the Services. Please be thoughtful and selective about the
              information, including contact information, that you share with
              any other users of the Services.
            </AgreementTextBlock>
            <AgreementTextBlock>
              The Services may show content, prices, availability and other
              information provided by third-parties. Big Robin does not assume
              any liability for the accuracy and timeliness of such information.
              You agree that you are responsible for your own research and
              business decisions, and that Big Robin is not responsible or
              liable for any decisions or actions you take or authorize third
              parties to take on your behalf based on information you receive as
              a user of Big Robin.
            </AgreementTextBlock>
          </section>
          <section>
            <AgreementSubTitle>2. Basic Terms</AgreementSubTitle>
            <AgreementTextBlock>
              You are responsible for your use of the Services and for any
              consequences thereof. You may use the Services only if you can
              form a binding contract with Big Robin and are not a person barred
              from accessing the Services under the laws of the United States or
              other applicable jurisdiction. If you are under 18 years of age
              you may not access or use the Services for any reason. You may use
              the Services only in compliance with these Terms and all
              applicable local, state, national, and international laws, rules
              and regulations.
            </AgreementTextBlock>
            <AgreementTextBlock>
              The Services are always evolving and the form and nature of the
              Services may change from time to time without prior notice to you.
              In addition, Big Robin may stop (permanently or temporarily)
              providing the Services (or any features within the Services) to
              you or to users generally and may not be able to provide you with
              prior notice. We also retain the right to create limits on use and
              storage at our sole discretion at any time without prior notice to
              you.
            </AgreementTextBlock>
            <AgreementTextBlock>
              The Services may include advertisements, which may be targeted to
              the content or information on the Services, queries made through
              the Services, or other information. The types and extent of
              advertising on the Services are subject to change. In
              consideration for Big Robin granting you access to and use of the
              Services, you agree that Big Robin, third party providers and
              partners may place such advertising on the Services or in
              connection with the display of content or information from the
              Services whether submitted by you or others.
            </AgreementTextBlock>
          </section>
          <section>
            <AgreementSubTitle>3. Privacy</AgreementSubTitle>
            <AgreementTextBlock>
              Any information that you provide to Big Robin is subject to our
              Privacy Policy, which governs our collection and use of your
              information. You understand that through your use of the Services
              you consent to the collection and use (as set forth in the Privacy
              Policy) of this information, including the transfer of this
              information to the United States and/or other countries for
              storage, processing and use by Big Robin. As part of providing you
              the Services, we may need to provide you with certain
              communications, such as service announcements and administrative
              messages. These communications are considered part of the Services
              and your Big Robin account, which you may not be able to opt-out
              from receiving.
            </AgreementTextBlock>
          </section>
          <section>
            <AgreementSubTitle>4. Passwords</AgreementSubTitle>
            <AgreementTextBlock>
              You are responsible for safeguarding the password or credentials
              that you use to access the Services and for any activities or
              actions under your account. We encourage you to use “strong”
              passwords (passwords that use a combination of upper and lower
              case letters, numbers and symbols) with your account and with
              other accounts that you may connect to your Big Robin account. Big
              Robin cannot and will not be liable for any loss or damage arising
              from your failure to comply with the above requirements. You agree
              to notify us immediately upon becoming aware of any breach of
              security or unauthorized use of your account.
            </AgreementTextBlock>
            <AgreementTextBlock>
              You may not use as a username the name of another person or entity
              or a name or trademark that is subject to any rights of another
              person without appropriate authorization. You may not use as a
              username any name that is offensive, vulgar or obscene.
            </AgreementTextBlock>
          </section>
          <section>
            <AgreementSubTitle>5. Content on the Services</AgreementSubTitle>
            <AgreementTextBlock>
              Please be aware that Big Robin does not verify the accuracy any
              information available on the Services. Any use or reliance on any
              information you obtained through the Services is at your own risk.
              You understand that by using the Services, you may be exposed to
              content that might be offensive, harmful, inaccurate or otherwise
              inappropriate, or in some cases, postings that have been
              mislabeled or are otherwise deceptive. Under no circumstances will
              Big Robin be liable in any way for any content posted, emailed,
              transmitted or otherwise made available via the Services or
              broadcast elsewhere.
            </AgreementTextBlock>
          </section>
          <section>
            <AgreementSubTitle>6. Big Robin Rights</AgreementSubTitle>
            <AgreementTextBlock>
              All right, title, and interest in and to the Services and any
              content available on the Services are and will remain the
              exclusive property of the party that originated the content. The
              Services and its content are protected by copyright, trademark,
              and other laws of both the United States and foreign countries.
              Big Robin reserves all rights not expressly granted in these
              Terms. You acknowledge and agree that any feedback, comments, or
              suggestions you may provide regarding Big Robin, or the Services
              are entirely voluntary and we will be free to use such feedback,
              comments or suggestions as we see fit and without any obligation
              to you.
            </AgreementTextBlock>
          </section>
          <section>
            <AgreementSubTitle>7. Your Rights</AgreementSubTitle>
            <AgreementTextBlock>
              <em>Project Content:</em> You retain the full rights to any
              content you transmit using the Big Robin messaging service
              (“Project Content”). Big Robin will only access or use Project
              Content as reasonably necessary to provide you with access to the
              Services or as provided in our Privacy Policy.
            </AgreementTextBlock>
            <AgreementTextBlock>
              <em>Publicly Posted Content:</em> You retain your rights to any
              content post or display on or through the publicly available
              portions of the Services (“User Content”). By submitting, posting
              or displaying User Content on or through the Services, you grant
              us a worldwide, non-exclusive, royalty-free license (with the
              right to sublicense) to use, copy, reproduce, process, adapt,
              modify, publish, transmit, display and distribute such User
              Content in any and all media or distribution methods (now known or
              later developed).
            </AgreementTextBlock>
            <AgreementTextBlock>
              We may modify or adapt your Content (which includes Project
              Content and User Content) in order to transmit, display or
              distribute it over computer networks and in various media and/or
              make changes to your Content as are necessary to conform and adapt
              that Content to any requirements or limitations of any networks,
              devices, services or media.
            </AgreementTextBlock>
            <AgreementTextBlock>
              You represent and warrant that you have all the rights, power and
              authority necessary to grant the rights granted herein to any
              Content that you submit.
            </AgreementTextBlock>
          </section>
          <section>
            <AgreementSubTitle>
              8. Restrictions On Use Of The Services
            </AgreementSubTitle>
            <AgreementTextBlock>
              We reserve the right at all times (but will not have an
              obligation) to suspend and or terminate users or reclaim usernames
              without liability to you.
            </AgreementTextBlock>
            <AgreementTextBlock>
              You may not do any of the following while accessing or using the
              Services: (i) access, tamper with, or use non-public areas of the
              Services, Big Robin’s computer systems, or the technical delivery
              systems of Big Robin providers; (ii) probe, scan, or test the
              vulnerability of any system or network or breach or circumvent any
              security or authentication measures; (iii) access or search or
              attempt to access or search the Services by any means (automated
              or otherwise) other than through our currently available,
              published interfaces that are provided by Big Robin (and only
              pursuant to those terms and conditions), unless you have been
              specifically allowed to do so in a separate agreement with Big
              Robin (NOTE: scraping the Services without the prior consent of
              Big Robin is expressly prohibited); (iv) forge any TCP/IP packet
              header or any part of the header information in any email or
              posting, or in any way use the Services to send altered, deceptive
              or false source-identifying information; or (v) interfere with, or
              disrupt, (or attempt to do so), the access of any user, host or
              network, including, without limitation, sending a virus,
              overloading, flooding, spamming, mail-bombing the Services, or by
              scripting the creation of content in such a manner as to interfere
              with or create an undue burden on the Services.
            </AgreementTextBlock>
            <AgreementTextBlock>
              You agree not to post any content on the Services that:
              <ul>
                <li>
                  Is harassing, impersonates another person or entity in a
                  manner that does or is intended to mislead, confuse, or
                  deceive others;
                </li>
                <li>
                  Violates the rights of a third party, including copyright,
                  trademark, privacy, and publicity rights;
                </li>
                <li>Is a direct and specific threat of violence to others;</li>
                <li>
                  Violates any contractual obligation to refrain from
                  photographing, filming or streaming any performance, event,
                  film, concert, sporting event or other happening;
                </li>
                <li>Is furtherance of illegal activities; or</li>
                <li>Is abusive or constitutes spam.</li>
              </ul>
            </AgreementTextBlock>
          </section>
          <section>
            <AgreementSubTitle>9. Copyright Policy</AgreementSubTitle>
            <AgreementTextBlock>
              We respect the intellectual property rights of others. It is our
              policy to respond to any claim that Content posted on the Service
              infringes on the copyright or other intellectual property rights
              ("Infringement") of any person or entity.
            </AgreementTextBlock>
            <AgreementTextBlock>
              If you are a copyright owner, or authorized on behalf of one, and
              you believe that the copyrighted work has been copied in a way
              that constitutes copyright infringement, please submit your claim
              via email to Darcy.Brosky@ThompsonHine.com, with the subject line:
              "Copyright Infringement- Big Robin" and include in your claim a
              detailed description of the alleged Infringement as detailed
              below, under "DMCA Notice and Procedure for Copyright Infringement
              Claims"
            </AgreementTextBlock>
            <AgreementTextBlock>
              You may be held accountable for damages (including costs and
              attorneys' fees) for misrepresentation or bad-faith claims on the
              infringement of any Content found on and/or through the Service on
              your copyright.
            </AgreementTextBlock>
            <AgreementTextBlock>
              <strong>
                DMCA Notice and Procedure for Copyright Infringement Claims
              </strong>
              <br />
              You may submit a notification pursuant to the Digital Millennium
              Copyright Act (DMCA) by providing our Copyright Agent with the
              following information in writing (see 17 U.S.C 512(c)(3) for
              further detail):
              <ul>
                <li>
                  an electronic or physical signature of the person authorized
                  to act on behalf of the owner of the copyright's interest;
                </li>
                <li>
                  a description of the copyrighted work that you claim has been
                  infringed, including the URL (i.e., web page address) of the
                  location where the copyrighted work exists or a copy of the
                  copyrighted work;
                </li>
                <li>
                  identification of the URL or other specific location on the
                  Service where the material that you claim is infringing is
                  located;
                </li>
                <li>your address, telephone number, and email address;</li>
                <li>
                  a statement by you that you have a good faith belief that the
                  disputed use is not authorized by the copyright owner, its
                  agent, or the law; and
                </li>
                <li>
                  a statement by you, made under penalty of perjury, that the
                  above information in your notice is accurate and that you are
                  the copyright owner or authorized to act on the copyright
                  owner's behalf.
                </li>
              </ul>
            </AgreementTextBlock>
            <AgreementTextBlock>
              You can contact our Copyright Agent via email at&nbsp;
              <a href="mailto:darcy.brosky@thompsonhine.com">
                darcy.brosky@thompsonhine.com
              </a>{' '}
              or at
              <br />
              <br />
              Darcy Brosky
              <br />
              3900 Key Tower
              <br />
              127 Public Square
              <br />
              Cleveland, Ohio 44114
            </AgreementTextBlock>
          </section>
          <section>
            <AgreementSubTitle>10. Ending These Terms</AgreementSubTitle>
            <AgreementTextBlock>
              The Terms will continue to apply until terminated by either you or
              Big Robin as follows.
            </AgreementTextBlock>
            <AgreementTextBlock>
              You may end your legal agreement with Big Robin at any time for
              any reason by deactivating your accounts and discontinuing your
              use of the Services. In order to deactivate your account, please
              contact us at{' '}
              <a href="mailto:admin@bigrobin.io">admin@bigrobin.io</a>.
            </AgreementTextBlock>
            <AgreementTextBlock>
              We may suspend or terminate your accounts or cease providing you
              with all or part of the Services at any time for any reason,
              including, but not limited to, if we reasonably believe: (i) you
              have violated these Terms, (ii) you create risk or possible legal
              exposure for us; or (iii) our provision of the Services to you is
              no longer commercially viable. We will make reasonable efforts to
              notify you by the email address associated with your account or
              through the Services the next time you attempt to access your
              account.
            </AgreementTextBlock>
            <AgreementTextBlock>
              Upon termination of this Agreement, Big Robin will delete any
              Project Content stored on your Big Robin account within fifteen
              days from the date of termination. Contact{' '}
              <a href="mailto:admin@bigrobin.io">admin@bigrobin.io</a>&nbsp;
              within this time in the event you wish to download or make a copy
              of your Project Content prior to deletion by Big Robin. Big Robin
              reserves the right in its reasonable discretion to charge a fee
              for downloading your Project Content after termination of this
              Agreement. You agree that Big Robin will not be liable if you fail
              to contact us within 15 days after termination of this Agreement
              and we delete your Project Content.
            </AgreementTextBlock>
            <AgreementTextBlock>
              In all such cases, the Terms shall terminate, including, without
              limitation, your license to use the Services, except those
              Sections you would expect to survive termination.{' '}
            </AgreementTextBlock>
            <AgreementTextBlock>
              Nothing in this section shall affect Big Robin’s rights to change,
              limit or stop the provision of the Services without prior notice,
              as provided above.
            </AgreementTextBlock>
          </section>
          <section>
            <AgreementSubTitle>
              11. Disclaimers, Limitations of Liability and Indemnity
            </AgreementSubTitle>
            <section>
              <AgreementTextBlock>
                Please read this section carefully since it limits the liability
                of Big Robin and its parents, subsidiaries, affiliates, related
                companies, officers, directors, employees, agents,
                representatives, partners, and licensors (collectively, the “Big
                Robin Entities”). Each of the subsections below only applies up
                to the maximum extent permitted under applicable law. Some
                jurisdictions do not allow the disclaimer of implied warranties
                or the limitation of liability in contracts, and as a result the
                contents of this section may not apply to you. Nothing in this
                section is intended to limit any rights you may have which may
                not be lawfully limited.
              </AgreementTextBlock>
            </section>
            <section>
              <AgreementBlockTitle>
                A. The Services are Available “AS-IS”
              </AgreementBlockTitle>
              <AgreementTextBlock>
                Your access to and use of the Services are at your own risk. You
                understand and agree that the Services are provided to you on an
                “AS IS” and “AS AVAILABLE” basis. Without limiting the
                foregoing, THE BIG ROBIN ENTITIES DISCLAIM ALL WARRANTIES AND
                CONDITIONS, WHETHER EXPRESS OR IMPLIED, OF MERCHANTABILITY,
                FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT.
              </AgreementTextBlock>
              <AgreementTextBlock>
                The Big Robin Entities make no warranty and disclaim all
                responsibility and liability for: (i) any actions of Big Robin
                users or contributors; (ii) the completeness, accuracy,
                availability, timeliness, security or reliability of the
                Services; (iii) any harm to your computer system, loss of data,
                or other harm that results from your access to or use of the
                Services, or any content; (iv) the deletion of, or the failure
                to store or to transmit, any content and other communications
                maintained by the Services; (v) whether the Services will meet
                your requirements or be available on an uninterrupted, secure,
                or error-free basis; (vi) any advice or information you receive
                through the Services; (vii) the execution, enforceability,
                performance or any other aspect related to a Service Contract
                and (viii) any injuries or harm you or a third party incurs as a
                result of information or advice received through the Services.
                No advice or information, whether oral or written, obtained from
                the Big Robin Entities or through the Services, will create any
                warranty not expressly made herein.
              </AgreementTextBlock>
            </section>
            <section>
              <AgreementBlockTitle>B. Links</AgreementBlockTitle>
              <AgreementTextBlock>
                The Services may contain links to third-party services or
                resources. You acknowledge and agree that we are not responsible
                or liable for: (i) the availability or accuracy of such services
                or resources; or (ii) the content, products, or services on or
                available from such services or resources. Links to such
                services or resources do not imply any endorsement by the Big
                Robin Entities of such services or resources or the content,
                products, or services available from such services or resources.
                You acknowledge sole responsibility for and assume all risk
                arising from your use of any such services or resources.
              </AgreementTextBlock>
            </section>
            <section>
              <AgreementBlockTitle>
                C. Limitation of Liability
              </AgreementBlockTitle>
              <AgreementTextBlock>
                TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, THE BIG ROBIN
                ENTITIES SHALL NOT BE LIABLE FOR ANY INDIRECT, INCIDENTAL,
                SPECIAL, CONSEQUENTIAL OR PUNITIVE DAMAGES, OR ANY LOSS OF
                PROFITS OR REVENUES, WHETHER INCURRED DIRECTLY OR INDIRECTLY, OR
                ANY LOSS OF DATA, USE, GOOD-WILL, OR OTHER INTANGIBLE LOSSES,
                RESULTING FROM (i) YOUR ACCESS TO OR USE OF OR INABILITY TO
                ACCESS OR USE THE SERVICES; (ii) ANY CONDUCT OR CONTENT OF ANY
                THIRD PARTY ON THE SERVICES, INCLUDING WITHOUT LIMITATION, ANY
                DEFAMATORY, OFFENSIVE OR ILLEGAL CONDUCT OF OTHER USERS OR THIRD
                PARTIES; (iii) ANY CONTENT OBTAINED FROM THE SERVICES; OR (iv)
                UNAUTHORIZED ACCESS, USE OR ALTERATION OF YOUR TRANSMISSIONS OR
                CONTENT.
              </AgreementTextBlock>
              <AgreementTextBlock>
                IN NO EVENT SHALL THE AGGREGATE LIABILITY OF THE BIG ROBIN
                ENTITIES EXCEED THE GREATER OF ONE HUNDRED U.S. DOLLARS (U.S.
                $100.00) OR THE AMOUNT YOU PAID BIG ROBIN, IF ANY, IN THE PAST
                SIX MONTHS FOR ACCESS TO THE SERVICES GIVING RISE TO THE CLAIM.
              </AgreementTextBlock>
              <AgreementTextBlock>
                THE LIMITATIONS OF THIS SUBSECTION SHALL APPLY TO ANY THEORY OF
                LIABILITY, WHETHER BASED ON WARRANTY, CONTRACT, STATUTE, TORT
                (INCLUDING NEGLIGENCE) OR OTHERWISE, AND WHETHER OR NOT THE BIG
                ROBIN ENTITIES HAVE BEEN INFORMED OF THE POSSIBILITY OF ANY SUCH
                DAMAGE, AND EVEN IF A REMEDY SET FORTH HEREIN IS FOUND TO HAVE
                FAILED OF ITS ESSENTIAL PURPOSE.
              </AgreementTextBlock>
            </section>
            <section>
              <AgreementBlockTitle>D. Indemnification</AgreementBlockTitle>
              <AgreementTextBlock>
                To the extent not prohibited by law, you expressly agree to
                indemnify and hold harmless the Big Robin Entities from and
                against any and all liabilities, expenses, damages and costs,
                including, but not limited to, reasonable attorneys’ fees and
                costs, related to all third party claims, charges and
                investigations related to (1) your failure to comply with the
                Terms, the Privacy Policy any Service Contracts, or a
                non-disclosure agreement related to your use of the Services;
                (2) any content you upload to or transmit through the Services;
                (3) your interactions with any other Big Robin user; (4) any
                Service Contracts, including without limitation, issues related
                to execution, performance and payment and (5) any activity in
                which you engage on or through the Services.
              </AgreementTextBlock>
            </section>
          </section>
          <section>
            <AgreementSubTitle>
              12. GENERAL TERMS. Waiver and Severability
            </AgreementSubTitle>
            <section>
              <AgreementTextBlock>
                The failure of Big Robin to enforce any right or provision of
                these Terms will not be deemed a waiver of such right or
                provision. In the event that any provision of these Terms is
                held to be invalid or unenforceable, then that provision will be
                limited or eliminated to the minimum extent necessary, and the
                remaining provisions of these Terms will remain in full force
                and effect.
              </AgreementTextBlock>
            </section>
            <section>
              <AgreementBlockTitle>
                A. Controlling Law and Jurisdiction
              </AgreementBlockTitle>
              <AgreementTextBlock>
                These Terms and any action related thereto will be governed by
                the laws of the laws of the state of Ohio without regard to or
                application of its conflict of law provisions or your state or
                country of residence. All claims, legal proceedings or
                litigation arising in connection with the Services will be
                brought solely in the federal or state courts located in
                Hamilton County, Ohio, United States, and you consent to the
                jurisdiction of and venue in such courts and waive any objection
                as to inconvenient forum. You agree that you may only bring
                claims against Big Robin related to your use of the Services on
                an individual basis and not as a plaintiff or class member in
                any purported class or representative action or proceeding.
              </AgreementTextBlock>
            </section>
            <section>
              <AgreementBlockTitle>B. Entire Agreement</AgreementBlockTitle>
              <AgreementTextBlock>
                These Terms and our Privacy Policy are the entire and exclusive
                agreement between Big Robin and you regarding the Services
                (excluding any service for which you have a separate agreement
                with Big Robin that is explicitly in addition or in place of
                these Terms), and these Terms supersede and replace any prior
                agreements between Big Robin and you regarding the Services.
              </AgreementTextBlock>

              <section>
                <AgreementSubTitle>
                  13. Permit to work and use Big Robin:
                </AgreementSubTitle>
                <AgreementTextBlock>
                  Regardless of where you are living, you are responsible to
                  comply with any laws, authorization requirements, and
                  restrictions to work or conduct business on Big Robin. <br />
                  We are not able to advise on whether or not you are able to
                  use Big Robin beyond the following: <br />
                  In compliance with US law, Big Robin prohibits certain
                  businesses or individuals to register for accounts or utilize
                  our platform.
                </AgreementTextBlock>
                <AgreementTextBlock>
                  <ul>
                    <li>
                      <AgreementTextBlock>
                        Are you at least 18 years of age?
                        <div style={{ display: 'flex' }}>
                          <div style={{ display: 'flex' }}>
                            <RPCheckbox
                              type="radio"
                              label=""
                              name={'age'}
                              disabled={isLogin || !user}
                              style={{
                                cursor:
                                  isLogin || !user ? 'not-allowed' : 'pointer',
                              }}
                              value={age}
                              checked={age === 'yes'}
                              onChange={() => setAge('yes')}
                            />
                            <p style={{ marginLeft: '5px' }}>Yes</p>
                          </div>
                          <div style={{ display: 'flex', marginLeft: '20px' }}>
                            <RPCheckbox
                              type="radio"
                              label=""
                              name={'age'}
                              style={{
                                cursor:
                                  isLogin || !user ? 'not-allowed' : 'pointer',
                              }}
                              value={age}
                              disabled={isLogin || !user}
                              checked={age === 'no'}
                              onChange={() => setAge('no')}
                            />
                            <p style={{ marginLeft: '5px' }}>No</p>
                          </div>
                        </div>
                      </AgreementTextBlock>
                    </li>
                    <li>
                      <AgreementTextBlock>
                        (Researcher only) Regardless of where you are living,
                        are you legally authorized to work in the country you
                        will be delivering your services from?
                        <div style={{ display: 'flex', position: 'relative' }}>
                          <div style={{ display: 'flex' }}>
                            <RPCheckbox
                              type="radio"
                              style={{
                                cursor:
                                  user === 'c' || isLogin || !user
                                    ? 'not-allowed'
                                    : 'pointer',
                              }}
                              label=""
                              name={'authorized'}
                              disabled={user === 'c' || isLogin || !user}
                              value={authorized}
                              checked={authorized === 'yes'}
                              onChange={() => setAuthorized('yes')}
                            />
                            <p style={{ marginLeft: '5px' }}>Yes</p>
                          </div>
                          <div style={{ display: 'flex', marginLeft: '20px' }}>
                            <RPCheckbox
                              type="radio"
                              label=""
                              style={{
                                cursor:
                                  user === 'c' || isLogin || !user
                                    ? 'not-allowed'
                                    : 'pointer',
                              }}
                              name={'authorized'}
                              disabled={user === 'c' || isLogin || !user}
                              value={authorized}
                              checked={authorized === 'no'}
                              onChange={() => setAuthorized('no')}
                            />
                            <p style={{ marginLeft: '5px' }}>No</p>
                          </div>
                        </div>
                      </AgreementTextBlock>
                    </li>
                    <li>
                      <AgreementTextBlock>
                        Are you a business or individual located and traveling
                        in restricted areas prohibited by US law: Iran, North
                        Korea, Syria, Crimea (region of Ukraine), Cuba,
                        individual nationals of Cuba, Cuba, Luhansk People’s
                        Republic, Donetsk People’s Republic (DPR), Any other
                        country or region restricted by law (this changes
                        occasionally), SDN list (specially Designated
                        Nationals)?
                        <div style={{ display: 'flex' }}>
                          <div style={{ display: 'flex' }}>
                            <RPCheckbox
                              type="radio"
                              label=""
                              style={{
                                cursor:
                                  isLogin || !user ? 'not-allowed' : 'pointer',
                              }}
                              name={'prohibited'}
                              disabled={isLogin || !user}
                              value={prohibited}
                              checked={prohibited === 'yes'}
                              onChange={() => setProhibited('yes')}
                            />
                            <p style={{ marginLeft: '5px' }}>Yes</p>
                          </div>
                          <div style={{ display: 'flex', marginLeft: '20px' }}>
                            <RPCheckbox
                              type="radio"
                              value={prohibited}
                              disabled={isLogin || !user}
                              style={{
                                cursor:
                                  isLogin || !user ? 'not-allowed' : 'pointer',
                              }}
                              label=""
                              name={'prohibited'}
                              checked={prohibited === 'no'}
                              onChange={() => setProhibited('no')}
                            />
                            <p style={{ marginLeft: '5px' }}>No</p>
                          </div>
                        </div>
                      </AgreementTextBlock>
                    </li>
                  </ul>
                </AgreementTextBlock>
              </section>
              <AgreementTextBlock>
                We may revise these Terms from time to time, the most current
                version will always be at bigrobin.com. If the revision, in our
                sole discretion, is material we will notify you via email to the
                email associated with your account or through the Services. If
                you do not wish to be bound by any such revisions to the Terms,
                you must end these Terms with us as set forth in Section 10
                above. By continuing to access or use the Services after those
                revisions become effective, you agree to be bound by the revised
                Terms.
              </AgreementTextBlock>
              <AgreementTextBlock>
                If you have any questions about these Terms, please contact us.
              </AgreementTextBlock>
              <AgreementTextBlock>
                Effective: {dayjs(new Date()).format('MMMM DD, YYYY')}
              </AgreementTextBlock>
              <div style={{ width: '100%', margin: '0 auto;' }}>
                {isLogin || !user ? (
                  <Button onClick={() => window.close()}>Close</Button>
                ) : isTooltip ? (
                  <>
                    <span data-tip data-for="tool_terms">
                      <Button
                        disabled={isDisabled}
                        backgroundColor={isDisabled ? 'grey' : '#ff9e08'}
                      >
                        I Agree with the Terms and Conditions
                      </Button>
                    </span>
                    <p
                      style={{
                        fontSize: '2rem',
                        fontWeight: 'lighter',
                        fontStyle: 'italic',
                        textTransform: 'initial',
                      }}
                    >
                      <SPReactTooltip
                        id="tool_terms"
                        effect="solid"
                        backgroundColor="#393939"
                        multiline={true}
                        style={{
                          fontSize: '1.2rem',
                          color: '#fff',
                        }}
                      >
                        <div>
                          Unfortunately, you may not create a Big Robin account
                          at this time
                        </div>
                      </SPReactTooltip>
                    </p>
                  </>
                ) : (
                  <Button
                    onClick={handleClose}
                    disabled={isDisabled}
                    backgroundColor={isDisabled ? 'grey' : '#ff9e08'}
                  >
                    I Agree with the Terms and Conditions
                  </Button>
                )}
              </div>
            </section>
          </section>
        </AgreementLayout>
      </AgreementContent>
    </>
  );
};
