import React, { useState } from 'react';
import {
  LoginContent,
  LoginBannerContainer,
  LoginBannerTextContainer,
  LoginFormContainer,
  Title,
} from 'pages/Login/styled';
import * as Yup from 'yup';

import bannerImage from '../Login/banner.svg';
import { useFormik } from 'formik';
import Input from 'components/Input';
import Button from 'components/Button';
import Modal from 'components/Modal';
import { useHistory, useLocation } from 'react-router-dom';
import { ajax } from 'utils/ajax';
import LoadingIndicator from 'components/LoadingIndicator';

export const ResetPassword = () => {
  const history = useHistory();
  const search = useLocation();
  const params = new URLSearchParams(search.search);
  const [loading, setLoading] = useState(false);
  const [isShowingModal, setIsShowingModal] = useState(false);
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
  } = useFormik({
    initialValues: { password: '', confirm: '' },
    validationSchema: Yup.object().shape({
      password: Yup.string()
        .matches(
          /^(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+~()[*{!}=|]).*$/,
          'Minimum requirements for a valid password - 8 characters, 1 small letter, 1 capital letter, 1 number, 1 special character'
        )
        .required('Password is required'),
      confirm: Yup.string()
        .oneOf([Yup.ref('password')], 'Passwords must match')
        .required('Confirm Password is required'),
    }),
    onSubmit: async data => {
      setLoading(true);
      let res = await ajax('PUT', `/member/reset_password`, {
        email: params.get('email'),
        key: params.get('key'),
        new_password: data.password,
        confirm_password: data.confirm,
      });
      setLoading(false);
      if (res.code === 200) setIsShowingModal(true);
    },
  });

  return (
    <LoginContent>
      {loading && <LoadingIndicator />}
      <LoginBannerContainer>
        <LoginBannerTextContainer>
          <h2>DEEPER INSIGHTS</h2>
          <h1>WORLD-CLASS RESEARCH</h1>
        </LoginBannerTextContainer>
        <img src={bannerImage} alt="" />
      </LoginBannerContainer>
      <LoginFormContainer>
        <Title>Reset password</Title>
        <p
          style={{
            marginBottom: '1rem',
            fontSize: '1.8rem',
            fontWeight: 'bold',
          }}
        >
          Enter your new password
        </p>
        <form onSubmit={handleSubmit}>
          <Input
            label="New Password"
            name="password"
            type="password"
            value={values.password}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.password && Boolean(errors.password)}
            errorMessage={touched.password && errors.password}
          />
          <Input
            label="Confirm Password"
            name="confirm"
            type="password"
            value={values.confirm}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.confirm && Boolean(errors.confirm)}
            errorMessage={touched.confirm && errors.confirm}
          />
          <Button
            type="submit"
            // disabled={
            //   !values.password ||
            //   !values.confirm ||
            //   values.password !== values.confirm
            // }
          >
            Reset
          </Button>
        </form>
      </LoginFormContainer>
      <Modal
        visible={!!isShowingModal}
        setVisible={setIsShowingModal}
        persistent={true}
      >
        <div
          style={{
            backgroundColor: 'white',
            padding: '2rem',
            flexDirection: 'column',
            justifyContent: 'flex-end',
          }}
        >
          <div style={{ marginBottom: '1rem' }}>Password has been reset!</div>

          <Button fill onClick={() => history.push('/login')}>
            OK
          </Button>
        </div>
      </Modal>
    </LoginContent>
  );
};
