import Button from 'components/Button';
import Modal from 'components/Modal';
import React from 'react';
import { useHistory } from 'react-router-dom';
import './style.css';

const HandleMandatePopup = ({ visible, setVisible }) => {
  const history = useHistory();
  return (
    <Modal visible={visible} setVisible={setVisible}>
      <div className="modal_container_handle">
        <div style={{ fontWeight: '600', textAlign: 'center' }}>Required!</div>

        <div style={{ marginTop: '20px', textAlign: 'center' }}>
          In order to proceed, please provide your borderless handle in the
          profile section.
        </div>

        <div
          style={{
            marginTop: '20px',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <div style={{ width: '100px' }}>
            <Button
              outline
              onClick={() => {
                setVisible(false);
              }}
            >
              Close
            </Button>
          </div>
          <div style={{ width: '130px', marginLeft: '5px' }}>
            <Button
              fill
              onClick={() => {
                history.push('/register');
              }}
            >
              Go to Profile
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default HandleMandatePopup;
