import styled from 'styled-components/macro';

export const UserMenuContainer = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  position: relative;
`;

export const UserMenuRow = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  img {
    border-radius: 50%;
    height: 3rem;
    margin-right: 0.5rem;
  }

  svg {
    color: #65637b;
  }
`;

export const UserMenuOptionsContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  border: 1px solid #dadadf;
  background-color: white;
  box-shadow: 0px 3px 6px #00000012;
  border-radius: 5px;

  display: flex;
  flex-direction: column;
  z-index: 100;
  width: max-content;
`;

export const UserMenuOption = styled.button`
  display: flex;
  align-items: center;
  padding: 1rem;
  color: #4b4c5e;

  &:hover {
    background-color: #dadadf;
  }

  svg {
    margin-right: 1rem;
  }
`;
