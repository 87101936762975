import { useCallback, useRef, useState } from 'react';

function useNodeState() {
  const [node, setNode] = useState(null);
  const ref = useRef(null);

  const refCallback = useCallback(newNode => {
    setNode(newNode);
    ref.current = newNode;
  }, []);

  return [refCallback, node, ref];
}

export default useNodeState;
