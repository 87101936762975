import React, {
  useState,
  useEffect,
  // useCallback
} from 'react';
import {
  ProjectContent,
  ProjectTitle,
  ProjectContainer,
  ShareContainer,
  ShareLabel,
  ShareInstructions,
  // ProjectsSearchbar,
  RecommendedResearcher,
  Container,
  // ResearcherTag,
  ShareRow,
  ProjectDesc,
  InviteButton,
  SPReactTooltip,
} from './styled';
import Breadcrumb from 'components/Breadcrumb';
import { Row, Column } from 'components/Layout';
// import researchers from 'utils/researcher-data';
import Button from 'components/Button';
import { MdVerifiedUser, MdBook } from 'react-icons/md';
import {
  FaPlus,
  // FaTimes,
  FaInfoCircle,
  FaDollarSign,
  FaMedal,
  FaStar,
} from 'react-icons/fa';
import { useHistory, useParams } from 'react-router-dom';
import { ajax } from 'utils/ajax';
import LoadingIndicator from 'components/LoadingIndicator';
import querystring from 'querystring';
import SessionModal from 'components/SessionModal';
// import { useAuthState } from 'context/AuthContext';
import ShareChipsInput from './ShareChipsInput';
import { CardInfoView } from 'components/ResearcherCard/styled';

export const ShareProject = () => {
  const history = useHistory();
  // const { user } = useAuthState();
  const params = useParams();
  const [shareTo, setShareTo] = useState({});
  const [project, setProject] = useState({});
  const [loading, setLoading] = useState(false);
  const [recommendedResearchers, setRecommendedResearchers] = useState([]);
  const [researchersList, setResearchersList] = useState(null);
  const [searchResearcher, setSearchResearcher] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  // const [chips, setChips] = useState([]);
  const user_id = parseInt(localStorage.getItem('br-userId'));

  const checkActivePlan = () => {
    (async () => {
      try {
        setLoading(true);
        const subs_url = '/member/' + user_id + '/subscription/active';
        const subs_res = await ajax('GET', subs_url);
        if (subs_res.code !== 200) {
          history.push(`/member-subscription/${user_id}/project/${params.id}`);
        }
      } catch (err) {
        setLoading(false);
      }
    })();
  };

  const getRecommendedResearchers = async specializations => {
    await ajax(
      'GET',
      `/member/researcher?${querystring.stringify({
        page_size: 10,
        page: 1,
        specialization: specializations.join(','),
        project: params.id,
      })}`
    ).then(response => {
      if (response.code === 200 && response.data) {
        setRecommendedResearchers(response.data);
        setLoading(false);
      } else if (response.code === 401) {
        setLoading(false);
        setIsOpen(true);
      } else {
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    (async () => {
      checkActivePlan();
      setLoading(true);
      const response = await ajax('GET', `/project/${params.id}`);
      if (response.code === 200 && response.data && response.data.project) {
        const specializations = response.data.project.business_needspace.map(
          item => item.id
        );
        setProject(response.data.project);
        getRecommendedResearchers(specializations);
      } else if (response.code === 401) {
        setLoading(false);
        setIsOpen(true);
      } else {
        setLoading(false);
      }
    })();
  }, [params.id]);

  const handleSearch = async searchResearcher => {
    setLoading(true);
    setResearchersList([]);
    if (searchResearcher !== '') {
      await ajax(
        'GET',
        `/member/researcher?${querystring.stringify({
          page_size: 10,
          page: 1,
          search: searchResearcher,
          project: params.id,
        })}`
      ).then(response => {
        if (response.code === 200 && response.data) {
          setResearchersList(response.data);
          setLoading(false);
        } else if (response.code === 401) {
          setLoading(false);
          setIsOpen(true);
        } else {
          setLoading(false);
        }
      });
    } else {
      setLoading(false);
    }
  };

  const handleInvite = () => {
    (async () => {
      setLoading(true);
      const response = await ajax('GET', `/project/${params.id}`);
      if (response.code === 200 && response.data && response.data.project) {
        const proj_obj = response.data.project;
        if (proj_obj.project_status_id === 3) {
          sendInvite(0);
        }
      } else if (response.code === 401) {
        setLoading(false);
        setIsOpen(true);
      } else {
        setLoading(false);
      }
    })();
  };

  const sendInvite = cnt => {
    const receiver_id = Object.keys(shareTo)[cnt];
    if (receiver_id) {
      (async () => {
        const url =
          '/messages/conversation/member/' +
          parseInt(localStorage.getItem('br-userId')) +
          '/researcher/' +
          receiver_id;
        const convo_response = await ajax('GET', url);
        if (convo_response.code === 200) {
          const payload = {
            receiver_id: parseInt(receiver_id),
            sender_id: parseInt(localStorage.getItem('br-userId')),
            message_type: 'invite',
            message: "Here is a research project that we're working on.",
            links: '',
            conversation_id: convo_response.data['id'],
            project_id: params.id,
          };
          const msg_response = await ajax('POST', '/messages', payload);
          if (msg_response.code === 200) {
            const new_cnt = cnt + 1;
            if (Object.keys(shareTo)[new_cnt]) {
              sendInvite(new_cnt);
            } else {
              history.push('/messages');
            }
          } else if (msg_response.code === 401) {
            setLoading(false);
            setIsOpen(true);
          } else {
            const new_cnt = cnt + 1;
            if (Object.keys(shareTo)[new_cnt]) {
              sendInvite(new_cnt);
            } else {
              history.push('/messages');
            }
          }
        } else if (convo_response.code === 401) {
          setLoading(false);
          setIsOpen(true);
        } else {
          const new_cnt = cnt + 1;
          if (Object.keys(shareTo)[new_cnt]) {
            sendInvite(new_cnt);
          } else {
            history.push('/messages');
          }
        }
      })();
    }
  };

  return (
    <ProjectContent>
      {loading ? <LoadingIndicator /> : null}
      <Breadcrumb
        path={[
          { label: 'MY PROJECTS', to: '/my-projects' },
          {
            label: project && project.title ? project.title.toUpperCase() : '',
            to: '/project/' + project.id,
          },
          { label: 'SHARE PROJECT' },
        ]}
      />
      <ProjectTitle>INVITE RESEARCHERS</ProjectTitle>
      <ProjectContainer>
        <ProjectDesc>
          All the details of your project will be viewable once the researcher
          accepts your invite. It is encouraged to invite Big Robin researchers.
        </ProjectDesc>
        <ShareRow>
          <ShareContainer>
            <ShareLabel>
              Enter email/s or Researcher’s Name&nbsp;
              <FaInfoCircle
                data-tip
                data-for="researcherNameInfo"
                color="#ff9e08"
              />
            </ShareLabel>
            <SPReactTooltip
              id="researcherNameInfo"
              effect="solid"
              backgroundColor="#ff9e08"
              multiline={true}
            >
              <div>
                Your project will be viewable to the Big Robin Researchers you
                add, or if not yet a Big Robin Researcher we will invite the
                potential researcher to sign up. Please include their valid
                e-mail address.
              </div>
            </SPReactTooltip>
            <ShareInstructions>
              Want a particular Researcher to bid?{' '}
              {/* <span
                style={{
                  textDecoration: 'underline',
                  cursor: 'pointer',
                  color: '#ff9e08',
                }}
              > </span>{' '} */}
              Invite them here.
            </ShareInstructions>
            <ShareChipsInput
              name="searchChips"
              shareTo={shareTo}
              setShareTo={setShareTo}
              placeholder={'Search researchers'}
              searchResearcher={searchResearcher}
              handleSearch={handleSearch}
              setSearchResearcher={setSearchResearcher}
            />

            {researchersList && researchersList.length > 0 && (
              <Container style={{ height: '26rem' }}>
                {researchersList.map((researcher, index) => {
                  return (
                    <RecommendedResearcher key={index}>
                      <img
                        src={
                          researcher.member.avatar_link
                            ? researcher.member.avatar_link
                            : require('../../components/UserMenu/default.webp')
                        }
                        alt={researcher.member.full_name}
                      />
                      <Column style={{ flex: 1 }}>
                        <Row
                          alignItems="center"
                          style={{
                            color: '#4B4C5E',
                            fontSize: '1.4rem',
                            fontWeight: 'bold',
                            marginBottom: '0.5rem',
                          }}
                        >
                          {researcher.member.full_name}{' '}
                          <MdVerifiedUser style={{ marginLeft: '0.5rem' }} />
                          <CardInfoView
                            to={`/researcher/${researcher.member.id}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              color: '#ff9e08',
                              marginLeft: '1rem',
                            }}
                          >
                            View Profile
                          </CardInfoView>
                        </Row>
                        <Row
                          alignItems="center"
                          style={{
                            color: '#4B4C5E',
                            opacity: 0.8,
                            marginBottom: '0.5rem',
                          }}
                        >
                          <small>
                            <FaStar
                              style={{ width: '15px', marginRight: '0.2rem' }}
                            />{' '}
                            {researcher.member.customer_ratings > 0
                              ? researcher.member.customer_ratings
                              : '-'}
                            <FaMedal
                              style={{
                                width: '15px',
                                marginRight: '0.2rem',
                                marginLeft: '1rem',
                              }}
                            />{' '}
                            {researcher.member.mastery_score}
                            <FaDollarSign
                              style={{
                                width: '15px',
                                marginRight: '0.2rem',
                                marginLeft: '1rem',
                              }}
                            />
                            {researcher.member.minimum_engagement}
                          </small>
                        </Row>
                        <Row
                          style={{
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            color: '#4B4C5E',
                            opacity: 0.8,
                            marginBottom: '0.5rem',
                          }}
                        >
                          <small>
                            <b>Specializations:&nbsp; </b>
                          </small>{' '}
                          {researcher.business_specialization.length > 0 && (
                            <>
                              {researcher.business_specialization.length > 1 ? (
                                <small>
                                  {researcher.business_specialization[0].name},
                                  ...
                                </small>
                              ) : (
                                <small>
                                  {researcher.business_specialization[0].name}
                                </small>
                              )}
                            </>
                          )}
                        </Row>
                      </Column>
                      {shareTo[researcher.member.id] === undefined && (
                        <Button
                          outline
                          style={{ marginRight: '1rem' }}
                          onClick={() => {
                            setShareTo({
                              ...shareTo,
                              [researcher.member.id]:
                                researcher.member.full_name,
                            });
                          }}
                        >
                          <FaPlus />
                          ADD
                        </Button>
                      )}
                    </RecommendedResearcher>
                  );
                })}
              </Container>
            )}
          </ShareContainer>
          <ShareContainer>
            <ShareLabel>Recommended Researchers</ShareLabel>
            <ShareInstructions>
              Based on your keywords and specialization needed
            </ShareInstructions>
            <Container style={{ height: '32rem' }}>
              {recommendedResearchers.slice(0, 10).map(researcher => {
                return (
                  <RecommendedResearcher key={researcher.member.id}>
                    <img
                      src={
                        researcher.member.avatar_link
                          ? researcher.member.avatar_link
                          : require('../../components/UserMenu/default.webp')
                      }
                      alt={researcher.member.full_name}
                    />
                    <Column style={{ flex: 1 }}>
                      <Row
                        alignItems="center"
                        style={{
                          color: '#4B4C5E',
                          fontSize: '1.4rem',
                          fontWeight: 'bold',
                          marginBottom: '0.5rem',
                        }}
                      >
                        {researcher.member.full_name}{' '}
                        <MdVerifiedUser style={{ marginLeft: '0.5rem' }} />
                        <CardInfoView
                          to={`/researcher/${researcher.member.id}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{
                            color: '#ff9e08',
                            marginLeft: '1rem',
                          }}
                        >
                          View Profile
                        </CardInfoView>
                      </Row>
                      <Row
                        alignItems="center"
                        style={{
                          color: '#4B4C5E',
                          opacity: 0.8,
                          marginBottom: '0.5rem',
                        }}
                      >
                        <small>
                          <FaStar
                            style={{ width: '15px', marginRight: '0.2rem' }}
                          />{' '}
                          {researcher.member.customer_ratings > 0
                            ? researcher.member.customer_ratings
                            : '-'}
                          <FaMedal
                            style={{
                              width: '15px',
                              marginRight: '0.2rem',
                              marginLeft: '1rem',
                            }}
                          />{' '}
                          {researcher.member.mastery_score}
                          <FaDollarSign
                            style={{
                              width: '15px',
                              marginRight: '0.2rem',
                              marginLeft: '1rem',
                            }}
                          />
                          {researcher.member.minimum_engagement}
                        </small>
                      </Row>
                      <Row
                        style={{
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          color: '#4B4C5E',
                          opacity: 0.8,
                          marginBottom: '0.5rem',
                        }}
                      >
                        <small>
                          <b>Specializations:&nbsp; </b>
                        </small>{' '}
                        {researcher.business_specialization.length > 0 && (
                          <>
                            {researcher.business_specialization.length > 1 ? (
                              <small>
                                {researcher.business_specialization[0].name},
                                ...
                              </small>
                            ) : (
                              <small>
                                {researcher.business_specialization[0].name}
                              </small>
                            )}
                          </>
                        )}
                      </Row>
                      <Row
                        alignItems="center"
                        style={{ color: '#4B4C5E', opacity: 0.8 }}
                      >
                        <small>
                          <MdBook style={{ marginRight: '0.5rem' }} />
                          Worked on projects similar to yours
                        </small>
                      </Row>
                    </Column>
                    <Column>
                      {shareTo[researcher.member.id] === undefined && (
                        <Button
                          outline
                          onClick={() => {
                            setShareTo({
                              ...shareTo,
                              [researcher.member.id]:
                                researcher.member.full_name,
                            });
                          }}
                        >
                          <FaPlus />
                          ADD
                        </Button>
                      )}
                    </Column>
                  </RecommendedResearcher>
                );
              })}
            </Container>
          </ShareContainer>
        </ShareRow>
        <Row justifyContent="center" style={{ marginTop: '4rem' }}>
          <InviteButton
            disabled={Object.keys(shareTo).length === 0}
            onClick={handleInvite}
          >
            SEND REQUEST
          </InviteButton>
        </Row>
      </ProjectContainer>
      <SessionModal
        isOpen={isOpen}
        setModalVisible={setIsOpen}
        message={'Your session has expired.'}
      />
    </ProjectContent>
  );
};
