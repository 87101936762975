import Button from 'components/Button';
import Modal from 'components/Modal';
import React from 'react';
import { RiCloseCircleFill } from 'react-icons/ri';

const ForgotHandlePopup = ({
  visible = false,
  setVisible,
  handleSubmitButton,
}) => {
  return (
    <Modal visible={visible} setVisible={setVisible} persistent>
      <div
        style={{
          backgroundColor: 'white',
          padding: '2rem',
          flexDirection: 'column',
          justifyContent: 'flex-end',
          maxWidth: `${'400px'}`,
          marginLeft: 'auto',
          marginRight: 'auto',
          borderRadius: '12px',
        }}
      >
        <div style={{ padding: '12px 12px', position: 'relative' }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginBottom: '20px',
              fontSize: '2rem',
              fontWeight: '700',
              // maxWidth: '90%',
            }}
          >
            Forgot/Change handle?
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'self-start',
              marginBottom: '20px',
            }}
          >
            <p style={{ textAlign: 'justify' }}>
              A handle is like your name. Big Robin does not store any of your
              bank information or accounts to your name. Please find your
              permanent handle on Borderless. In order to make changes to the
              bank account for your Big Robin payments, you will also need to do
              that on Borderless.
            </p>
          </div>
          <div
            style={{
              position: 'absolute',
              top: '0px',
              right: '0px',
              cursor: 'pointer',
            }}
            onClick={() => setVisible(false)}
          >
            <RiCloseCircleFill color="grey" size={24} />
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginBottom: '10px',
          }}
        >
          <Button
            // outline
            style={{ width: 'fit-content' }}
            fill
            onClick={handleSubmitButton}
          >
            Go to Borderless Login
          </Button>
        </div>
      </div>
    </Modal>
  );
};
export default ForgotHandlePopup;
