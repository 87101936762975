import useBodyScrollLock from 'hooks/useBodyScrollLock';
import React, { useRef } from 'react';
import { createPortal } from 'react-dom';
import { OverlayBackground } from './styled';
import LoadingOverlay from 'react-loading-overlay';

const LoadingIndicator = ({ visible, setVisible, children, className }) => {
  const rootRef = useRef(document.getElementById('root'));

  useBodyScrollLock(visible);

  const childrenContainerRef = useRef(null);

  return createPortal(
    <OverlayBackground
      className={className}
      visible={true}
      onClick={event => {
        if (
          childrenContainerRef.current &&
          !childrenContainerRef.current.contains(event.target)
        ) {
          setVisible(false);
        }
      }}
    >
      <LoadingOverlay active={true} spinner text="" />
    </OverlayBackground>,
    rootRef.current
  );
};

export default LoadingIndicator;
