import { Row } from 'components/Layout';
import styled from 'styled-components/macro';

export const ProjectCardContainer = styled.div`
  padding: 1rem 0;
  h4 {
    font-size: ${props => (props.compressed ? 1.6 : 2)}rem;
    margin-bottom: ${props => (props.compressed ? 0.5 : 2)}rem;
    cursor: ${props => (props.compact ? 'pointer' : 'default')};
    color: ${props => (props.compact ? '#ff9e08' : '#4b4c5e')};
  }

  p {
    font-size: ${props => (props.compressed ? 1.3 : 1.6)}rem;
    margin-bottom: ${props => (props.compressed ? 1 : 2)}rem;
    cursor: ${props => (props.compact ? 'pointer' : 'default')};
  }

  ${Row} {
    margin-bottom: 0.5rem;
    font-size: ${props => (props.compressed ? 1.2 : 1.6)}rem;
    svg {
      opacity: 0.75;
    }
    span {
      opacity: 0.75;
    }
  }

  border-bottom: 0.5px solid #b8cdc6;
`;

export const ResearcherName = styled.span`
  font-weight: bold;
  opacity: 1 !important;
`;
