import React, { useState } from 'react';
import { useAuthState, useAuthDispatch } from 'context/AuthContext';
import { ajax } from 'utils/ajax';
import Steps from 'components/Steps';
import { CreateProjectContainer, HeaderLayout, Instructions } from './styled';
import LoadingIndicator from 'components/LoadingIndicator';
import Modal from 'components/Modal';
import { upload } from 'utils/upload';
import { useHistory } from 'react-router-dom';
import Button from 'components/Button';
import { ProjectForm } from 'components/ProjectForm';
import SessionModal from 'components/SessionModal';
import cross from './cross.png';
import ProjectPreview from 'components/ProjectPreview';

const MEMBER_KEYS = [
  'first_name',
  'last_name',
  'company_url',
  'contact_email',
  'linked_in_url',
  'address',
  'address_2',
  'city',
  'state',
  'country',
  'accounts',
  'postal_code',
  'billing_address',
  'billing_address_2',
  'billing_city',
  'billing_state',
  'billing_country',
  'billing_postal_code',
  'billing_information',
  'is_same_billing_address',
  'contact_number',
  'is_company_account',
  'company_affiliation',
  'new_account',
];

export const CreateProject = () => {
  //const location = useLocation();
  const { user } = useAuthState();
  const authDispatch = useAuthDispatch();
  const history = useHistory();
  const duplicateDetails = history.location?.state?.duplicateDetails
    ? history.location.state.duplicateDetails
    : null;
  const [projectId, setProjectId] = useState(null);
  const [loading, setLoading] = useState(false);

  //const shouldExit = useRef(false);
  // skip first step if user already completed profile setup

  const initStep = user.member.first_name ? 1 : 0;
  const [currentStep, setCurrentStep] = useState(initStep);
  const [isShowingModal, setIsShowingModal] = useState(null);
  const [showSaveLaterModal, setShowSaveLaterModal] = useState(null);
  const [showingAction, setShowingAction] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [isComplete, setIsComplete] = useState(false);
  const [isDisable, setIsDisable] = useState(false);

  const [isPreview, setIsPreview] = useState(false);
  const [previewData, setPreviewData] = useState(null);
  const [previewDataValues, setPreviewDataValues] = useState(null);

  const handleProjectPreview = () => {
    setIsPreview(prev => !prev);
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const handleSubmitData = data => {
    const body = {
      member_id: user.member.id,
      member: {},
      project: {},
    };

    try {
      setLoading(true);
      for (var i in data) {
        if (MEMBER_KEYS.includes(i)) {
          body['member'][i] = data[i];
        } else {
          if (i === 'reference_files') {
            body['project'][i] = [];
          } else if (i === 'start_date' || i === 'end_date') {
            if (data[i]) {
              body['project'][i] = new Date(data[i]);
            } else {
              body['project'][i] = data[i];
            }
          } else if (i === 'research_mastery' || i === 'analytics_mastery') {
            if (data[i]) {
              body['project'][i] = data[i];
            }
          } else {
            body['project'][i] = data[i];
          }
        }
        setLoading(false);
      }

      if (data.reference_files.length > 0) {
        uploadingFiles(body, data.reference_files, 0);
      } else {
        if (projectId === null) {
          savingProject(body);
        } else {
          body.project['modified_by_id'] = user.member.id;
          updatingProject(body.project);
        }
      }
    } catch (err) {
      setLoading(true);
    } finally {
      // console.log('finally');
    }
  };

  const uploadingFiles = (body, to_upload, ind) => {
    (async () => {
      let loadFlag = false;
      if (to_upload[ind]) {
        const upload_res = await upload(
          to_upload[ind]['url'],
          'project_reference_files',
          to_upload[ind]['filename']
        );
        if (upload_res && upload_res['status'] === 200 && upload_res['Key']) {
          body['project']['reference_files'].push({
            filename: to_upload[ind]['filename'],
            url: upload_res['Key'],
            description: '',
            attachment_type: to_upload[ind]['attachment_type'],
          });
        } else if (upload_res && upload_res.code === 401) {
          loadFlag = true;
          setLoading(false);
          setIsOpen(true);
        }
      }
      const len = to_upload.length - 1;
      if (loadFlag === false && ind < len) {
        const ind_temp = ind + 1;
        uploadingFiles(body, to_upload, ind_temp);
      } else if (loadFlag === false) {
        savingProject(body);
      }
    })();
  };

  // API Call to save a PROJECT, used when there is no existing Project ID
  const savingProject = body => {
    (async () => {
      const res = await ajax('POST', '/project', body);
      if (res.code === 200) {
        //localStorage.setItem('projectId', parseInt(res.data.project.id)); // Set Project ID in local storage
        setProjectId(parseInt(res.data.project.id)); // set project ID in main variable
        const getRes = await ajax('GET', `/member/${user.member.id}`);
        if (getRes.code === 200) {
          authDispatch({ type: 'SET_USER', payload: getRes.data });
          setShowingAction('saving');
          setLoading(false);
          setIsDisable(false);
          if (body?.project?.status === 3) {
            setIsShowingModal('Details saved!' || res?.message);
          }
        } else if (getRes.code === 401) {
          setLoading(false);
          setIsOpen(true);
          setIsDisable(false);
        }
      } else if (res.code === 401) {
        setLoading(false);
        setIsOpen(true);
        setIsDisable(false);
      }
      setLoading(false);
    })();
  };

  // API Call to edit a project, used when there is an existing Project ID
  const updatingProject = body => {
    (async () => {
      const res = await ajax('PUT', `/project/${projectId}`, body);

      if (res.code === 200) {
        const getRes = await ajax('GET', `/member/${user.member.id}`);
        if (getRes.code === 200) {
          authDispatch({ type: 'SET_USER', payload: getRes.data });
          setShowingAction('saving');
          setLoading(false);
          setIsDisable(false);

          if (body.status === 3) setIsShowingModal('Details saved!');
        } else if (getRes.code === 401) {
          setLoading(false);
          setIsDisable(false);

          setIsOpen(true);
        }
      } else if (res.code === 401) {
        setLoading(false);
        setIsDisable(false);

        setIsOpen(true);
      }
    })();
  };

  return (
    <div>
      {isPreview && (
        <ProjectPreview
          handleProjectPreview={handleProjectPreview}
          props={previewData}
          previewDataValues={previewDataValues}
        />
      )}
      <div style={{ display: isPreview ? 'none' : 'block' }}>
        <CreateProjectContainer>
          {loading ? <LoadingIndicator /> : null}
          <HeaderLayout>
            <Instructions>
              <h4>Start a Project</h4>
            </Instructions>
            <Steps
              steps={[
                'Review Account',
                'Project Description',
                'Project Requirements',
              ]}
              current={currentStep}
            />
          </HeaderLayout>
          <ProjectForm
            setPreviewData={setPreviewData}
            setPreviewDataValues={setPreviewDataValues}
            startStep={initStep}
            onFormSubmit={handleSubmitData}
            setIsDisable={setIsDisable}
            isDisable={isDisable}
            onStepChange={setCurrentStep}
            isEdit={false}
            duplicateDetails={duplicateDetails}
            isLoading={loading}
            setIsShowingModal={setIsShowingModal}
            setShowSaveLaterModal={setShowSaveLaterModal}
            setShowingAction={setShowingAction}
            setIsOpen={setIsOpen}
            setLoading={setLoading}
            isOpen={isOpen}
            isComplete={isComplete}
            setIsComplete={setIsComplete}
            handleProjectPreview={handleProjectPreview}
          />
          <Modal
            visible={!!isShowingModal}
            setVisible={setIsShowingModal}
            persistent
          >
            <div
              style={{
                backgroundColor: 'white',
                padding: '2rem',
                flexDirection: 'column',
                justifyContent: 'flex-end',
                position: 'relative',
                borderRadius: '8px',
                minWidth: '250px',
              }}
            >
              <div
                style={{
                  textAlign: 'center',
                  paddingBottom: '16px',
                  position: 'relative',
                  // display: 'flex',
                  fontWeight: 'bold',
                }}
              >
                <div>Success</div>
                <span
                  style={{
                    textAlign: 'end',
                    position: 'absolute',
                    top: '0px',
                    right: '0px',
                  }}
                >
                  <img
                    src={cross}
                    alt="cross-icon"
                    className="crossIcon"
                    onClick={() => {
                      setIsShowingModal(null);
                      setShowingAction('');
                      if (showingAction === 'saving') {
                        history.push('/my-projects');
                      }
                    }}
                  />
                </span>
              </div>

              <div style={{ textAlign: 'center', marginBottom: '2rem' }}>
                {isShowingModal}
              </div>
              <div>
                {/* <Button
                fill
                onClick={() => {
                  setIsShowingModal(null);
                  setShowingAction('');
                  if (showingAction === 'saving') {
                    history.push('/my-projects');
                  }
                }}
                style={{
                  padding: showingAction === 'saving' ? '0 50px' : '1rem 2rem',
                }}
              >
                OK
              </Button> */}
                {/* {showingAction === 'saving' && ( */}
                <Button
                  outline
                  fill
                  onClick={() => {
                    setIsShowingModal(null);
                    setShowingAction('');
                    if (showingAction === 'saving') {
                      history.push('/researchers');
                    }
                  }}
                >
                  Invite a researcher
                </Button>
              </div>
            </div>
          </Modal>
          <Modal
            visible={showSaveLaterModal}
            setVisible={setShowSaveLaterModal}
            persistent
          >
            <div
              style={{
                backgroundColor: 'white',
                padding: '2rem',
                flexDirection: 'column',
                justifyContent: 'flex-end',
              }}
            >
              <div style={{ textAlign: 'center', marginBottom: '2rem' }}>
                Successfully saved project
              </div>
              <div>
                <Button
                  fill
                  onClick={() => {
                    setShowSaveLaterModal(null);
                    history.push('/my-projects');
                  }}
                >
                  OK
                </Button>
              </div>
            </div>
          </Modal>
        </CreateProjectContainer>
      </div>

      <SessionModal
        isOpen={isOpen}
        setModalVisible={setIsOpen}
        message={'Your session has expired.'}
      />
    </div>
  );
};
