import { InputContainer, InputLayout } from 'components/Input/styled';
import styled from 'styled-components/macro';

export const DropdownContainer = styled(InputContainer)`
  position: relative;
  color: #65637b;

  select {
    display: none;
  }
`;

export const DropdownLayout = styled(InputLayout)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.7rem 1rem;
  font-size: 1.6rem;
  cursor: pointer;
  border: 1px solid ${props => (props.error ? '#ef5350' : '#65637b3c')};

  @media (max-width: 768px) {
    font-size: 2.4rem;
    padding: 1rem;
  }
`;

export const DropdownValueLayout = styled.div`
  display: flex;
  flex: 1;
  min-height: 2.1rem;
  // color: red;
  // text-overflow: ellipsis;
  //   overflow: hidden;
  // white-space: nowrap;

  @media (max-width: 768px) {
    min-height: 4.2rem;
  }
`;

export const DropdownOptionsContainer = styled.div`
  z-index: 10;
  position: absolute;
  background-color: white;
  border: 1px solid #65637b3c;
  border-top: 0;
  border-radius: 8px;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  max-height: 25rem;
  overflow-y: auto;
`;

export const DropdownOption = styled.div`
  cursor: pointer;
  padding: 0.7rem 1rem;
  font-size: 1.6rem;
  background-color: white;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: #dadadf;
  }
`;
