import React, { useState, useEffect } from 'react';
import {
  LoginContent,
  LoginBannerContainer,
  LoginBannerTextContainer,
  LoginFormContainer,
  Title,
} from 'pages/Login/styled';
import { ajax } from 'utils/ajax';

// import bannerImage from '../Login/banner.svg';
import { useFormik } from 'formik';
import Input from 'components/Input';
import Button from 'components/Button';
import LoadingIndicator from 'components/LoadingIndicator';

export const ForgotPassword = () => {
  const [isLinkSent, setIsLinkSent] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const { values, handleChange, handleBlur, handleSubmit } = useFormik({
    initialValues: { email: '' },
    onSubmit: async data => {
      setLoading(true);
      let res = await ajax('PUT', `/member/forgot_password`, {
        email: data.email,
      });
      setMessage(res.message);
      if (res.code === 200) setIsLinkSent(true);
      setLoading(false);
    },
  });

  useEffect(() => {
    setIsLinkSent(false);
  }, [values.email]);

  return (
    <LoginContent>
      <LoginBannerContainer>
        <LoginBannerTextContainer>
          <h2>Researchers for Business.</h2>
          <h2>Business for Researchers.</h2>
        </LoginBannerTextContainer>
      </LoginBannerContainer>
      <LoginFormContainer>
        <Title>Forgot password?</Title>
        <p
          style={{
            marginBottom: '1rem',
            fontSize: '1.8rem',
            fontWeight: 'bold',
          }}
        >
          We'll send a link to your email so you can reset your password.
        </p>
        <form onSubmit={handleSubmit}>
          <Input
            label="Email"
            name="email"
            type="email"
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <Button type="submit" disabled={!values.email}>
            Send
          </Button>
          {loading ? (
            <LoadingIndicator />
          ) : (
            <>
              {isLinkSent ? (
                <p
                  style={{
                    fontWeight: 'bold',
                    fontSize: '2rem',
                    marginTop: '2rem',
                  }}
                >
                  Link sent! {message}
                </p>
              ) : (
                <p
                  style={{
                    fontWeight: 'bold',
                    fontSize: '2rem',
                    marginTop: '2rem',
                    color: 'red',
                  }}
                >
                  {message}
                </p>
              )}
            </>
          )}
        </form>
      </LoginFormContainer>
    </LoginContent>
  );
};
