import Input from 'components/Input';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import {
  EarlyContent,
  EarlyFormContainer,
  SubmitButton,
  EarlyTitle,
} from './styled';
import './style.css';
import bigRobin from './bigRobin.png';
import { earlyEalidation } from 'utils/validation/earlyValidation';
import { ajax } from 'utils/ajax';
localStorage.setItem('agree', 'false');
const EarlySignUp = () => {
  const history = useHistory();
  const [errorMsg, setErrorMsg] = useState('');
  const formik = useFormik({
    initialValues: {
      email: sessionStorage.getItem('join-email') || '',
      firstName: '',
      lastName: '',
    },
    enableReinitialize: true,
    validationSchema: earlyEalidation,
    onSubmit: async data => {
      const body = {
        first_name: data.firstName,
        last_name: data.lastName,
        email: data.email,
      };
      const res = await ajax('POST', '/early-signup', body);
      if (res?.code === 200) {
        history.push('/thank-you');
      } else {
        setErrorMsg(res?.message);
      }
    },
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <EarlyContent>
      <EarlyFormContainer>
        <img src={bigRobin} alt={'big-robin'} className="bigRobin-img" />
        <EarlyTitle>Sign up, Early Bird!</EarlyTitle>
        <p className="bigRobin-p">
          We are inviting freelance data scientists and market researchers to
          join Big Robin -- a new freelance e-marketplace exclusively for expert
          AI, data analytics and research services. We are on beta and launching
          soon, so be the first to pre-register as a researcher before anyone
          else!
        </p>
        <form onSubmit={formik.handleSubmit}>
          <Input
            name="email"
            label="Email"
            type="email"
            required
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.email && Boolean(formik.errors.email)}
            errorMessage={formik.touched.email && formik.errors.email}
          />
          <Input
            name="firstName"
            label="First Name"
            required
            value={formik.values.firstName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.firstName && Boolean(formik.errors.firstName)}
            errorMessage={formik.touched.firstName && formik.errors.firstName}
          />
          <Input
            name="lastName"
            label="Last Name"
            required
            value={formik.values.lastName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.lastName && Boolean(formik.errors.lastName)}
            errorMessage={formik.touched.lastName && formik.errors.lastName}
          />
          {errorMsg !== '' ? (
            <p
              style={{ color: '#ef5350', fontSize: '12px', textAlign: 'left' }}
            >
              {errorMsg}
            </p>
          ) : (
            <></>
          )}
          <SubmitButton fill style={{ margin: '2rem 0' }} type="submit">
            <div style={{ flex: 1 }}>Sign Me Up!</div>
          </SubmitButton>
        </form>
        <hr />
        <p className="bigRobin-footer">
          By submitting this form, you are consenting to receive marketing
          emails from: Big Robin LLC, Canada: Suite 210-186 8120 No. 2 Road
          Richmond BC V7C 5J8 & USA: 5965 Village Way Ste E105 #121 San Diego,
          CA 92130
        </p>
      </EarlyFormContainer>
    </EarlyContent>
  );
};

export default EarlySignUp;
