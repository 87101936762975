import styled, { css } from 'styled-components/macro';
import { Column } from 'components/Layout';
import Button from 'components/Button';

export const MessageConversationItem = styled.div`
  display: flex;
  margin-right: 2rem;
  align-items: flex-start;
  justify-content: ${props => (props.isSender ? 'flex-end' : 'flex-start')};

  @media (max-width: 768px) {
    margin-left: 2rem;
  }

  ${props =>
    props.isSender &&
    css`
      ${ConversationItemMessage} {
        background-color: rgba(218, 218, 223, 0.3);
      }

      ${ConversationItemUserImageContainer} {
        margin-right: 0rem;
        margin-left: 1rem;
      }
    `};
`;

export const ConversationItemMessage = styled.div`
  background-color: rgb(245, 140, 6, 0.2);
  max-width: 30rem;
`;

export const ConversationItemUserImageContainer = styled(Column)`
  align-items: center;
  margin-right: 1rem;
  margin-left: 1rem;
`;

export const ActionButtonContainer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
`;

export const ConversationContainer = styled.div`
  margin-bottom: 4rem;

  @media (max-width: 768px) {
    font-size: 2.4rem;
  }
`;

export const MessageDate = styled.div`
  font-size: 1.3rem;
  margin-top: 0.5rem;
  color: rgba(75, 76, 94, 1);
  text-align: ${props => (props.isSender ? 'right' : 'left')};

  @media (max-width: 768px) {
    font-size: 1.75rem;
  }
`;

export const ActionButton = styled(Button)`
  border-radius: 8px;
`;

export const FileContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-right: 1rem;
  margin-top: 19px;
`;

export const FileNameContainer = styled.div`
  text-decoration: underline;
  text-transform: uppercase;
`;

export const ViewButtonContainer = styled.div`
  display: flex;
  align-items: ${props => (props.isSender ? 'flex-start' : 'flex-end')};
  justify-content: ${props => (props.isSender ? 'flex-start' : 'flex-end')};
  margin-top: 15px;
`;
