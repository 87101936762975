// import Button from 'components/Button';
import Checkbox from 'components/Checkbox';
import Input from 'components/Input';
// import { Column } from 'components/Layout';
import {
  login,
  MEMBER_TYPES,
  signUp,
  useAuthDispatch,
  useAuthState,
} from 'context/AuthContext';
import { useFormik } from 'formik';
import usePrevious from 'hooks/usePrevious';
import React, { useEffect, useRef, useState } from 'react';
// import { FaGoogle, FaSpinner } from 'react-icons/fa';
import { FaEye, FaEyeSlash, FaSpinner } from 'react-icons/fa';
import { useHistory, useLocation } from 'react-router';
import { validationSchema } from 'utils/validation/validations';
import Modal from 'components/Modal';
import Button from 'components/Button';
import {
  AccountTypeContainer,
  ConditionsLink,
  ConditionsText,
  // DividerBar,
  // DividerLayout,
  // DividerText,
  ErrorMessage,
  // GoogleLoginButton,
  LoginBannerContainer,
  LoginBannerTextContainer,
  LoginContent,
  LoginFormContainer,
  OtherFormText,
  SignUpSuccess,
  Title,
  SubmitButton,
  //FacebookLoginButton,
} from './styled';
// import { GoogleLogin } from 'react-google-login';
// import FacebookLogin from 'react-facebook-login';

localStorage.setItem('agree', 'false');
const Login = ({ className, ...props }) => {
  const location = useLocation();
  const history = useHistory();
  const { isSignup } = props.location.state || history.state || '';
  const [isResearcher, setIsResearcher] = useState();
  const [indexNumber, setIndexNumber] = useState(null);
  const [visibility, setVisibility] = useState(false);

  const handlePasswordVisibility = () => {
    setVisibility(prev => !prev);
  };

  const { ip_address, loginStatus, signUpStatus } = useAuthState();
  const authDispatch = useAuthDispatch();

  // const urlQuery = new URLSearchParams(props.location.search);

  const [formType, setFormType] = useState(isSignup ? 'SIGN_UP' : 'LOGIN');
  const [isGoogle, setIsGoogle] = useState(false);
  const [hasSignedUp, setHasSignedUp] = useState(false);
  const [isFormInvalid, setIsFormInvalid] = useState(false);
  const prevFormType = usePrevious(formType);
  const [showModal, setShowModal] = useState(false);
  const [modalMsg, setModalMsg] = useState();
  const textarea = useRef();
  const formik = useFormik({
    initialValues: {
      email: sessionStorage.getItem('join-email') || '',
      password: '',
      member_type_id: isResearcher
        ? MEMBER_TYPES.RESEARCHER
        : MEMBER_TYPES.MEMBER,
      understood: false,
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: async data => {
      let research = Number(data?.member_type_id) === 3 ? true : false;
      setIsGoogle(false);
      if (formType === 'LOGIN' && (!data.email || !data.password)) {
        setIsFormInvalid(true);
        return;
      }

      if (formType === 'SIGN_UP' && (!data.email || !data.password || !agree)) {
        setIsFormInvalid(true);
        return;
      }

      setIsFormInvalid(false);
      if (formType === 'LOGIN') {
        try {
          await login(
            authDispatch,
            {
              email: data.email,
              password: data.password,
              ip_address,
              platform: 'email',
              member_type_id: isResearcher ? 3 : 2,
            },
            isResearcher
          );
          signUpStatus.type = null;
          history.replace('/welcome');
        } catch (err) {
          //
        }
      }
      if (formType === 'SIGN_UP') {
        try {
          await signUp(
            authDispatch,
            {
              email: data.email,
              password: data.password,
              member_type_id: +data.member_type_id,
              ip_address,
              platform: 'email',
            },
            research
          );
          signUpStatus.type = undefined;
          history.replace('/register');
          // if (+data.member_type_id === 3) setIsResearcher(true);
          // else setIsResearcher(false);
          // setAgree(false);
          // loginStatus.type = undefined;
          // setIsGoogle(false);
          // setFormType('LOGIN');
          // setHasSignedUp(true);
        } catch (err) {
          //
        }
      }
    },
  });

  useEffect(() => {
    setVisibility(false);
  }, [formType, isResearcher]);
  const { resetForm } = formik;
  const [agree, setAgree] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (location.state !== undefined)
      setIsResearcher(location.state.isResearcher);
    else setIsResearcher(false);
  }, [location.state]);

  useEffect(() => {
    if (isSignup === true) {
      setFormType('SIGN_UP');
    } else {
      setFormType('LOGIN');
    }
  }, [isSignup]);

  useEffect(() => {
    if (formType !== prevFormType && prevFormType !== undefined) {
      if (prevFormType === 'LOGIN') {
        authDispatch({ type: 'LOGIN_RESET' });
      }

      resetForm();
      setIsFormInvalid(false);
    }
  }, [authDispatch, formType, prevFormType, resetForm]);

  useEffect(() => {
    window.addEventListener('storage', () => {
      // When storage changes refetch
      setAgree(true);
    });
  }, []);

  useEffect(() => {
    if (loginStatus?.data?.code === 403) {
      if (
        isResearcher === true &&
        loginStatus.data?.meta?.member_type_id === 3
      ) {
        setModalMsg(
          'A client with this email already exists. A different email is required to login as a researcher.'
        );
        setShowModal(true);
      } else {
        setModalMsg(
          'A researcher with this email already exists. A different email is required to login as a member'
        );
        setShowModal(true);
      }
    }
  }, [loginStatus]);

  return (
    <LoginContent className={className}>
      <LoginBannerContainer
        isSUResearcher={
          formType === 'SIGN_UP' &&
          formik.values.member_type_id === MEMBER_TYPES.RESEARCHER
        }
      >
        <LoginBannerTextContainer>
          <h2>Researchers for Business</h2>
          <h2>Business for Researchers</h2>
        </LoginBannerTextContainer>
      </LoginBannerContainer>
      <LoginFormContainer>
        <Title>
          {formType === 'LOGIN'
            ? `Login as a ${isResearcher ? 'Researcher' : 'Client'}`
            : 'Sign up'}
        </Title>
        {/* <Column alignItems="center">
          <div
            className="fb-login-button"
            data-width="10000"
            data-size="large"
            data-button-type="continue_with"
            data-auto-logout-link="false"
            data-use-continue-as="true"
          />
        </Column> */}
        {/* <FacebookLogin
          appId={process.env.REACT_APP_FACEBOOK_APP_ID}
          callback={responseFacebook}
          fields="name,email"
          render={renderProps => (
            <button
              onClick={renderProps.onClick}
              disabled={renderProps.disabled}
              style={{
                width: '100%',
              }}
            >
              <span>Continue with Facebook</span>
            </button>
          )}
          icon={<FaFacebook />}
        /> */}

        {/* <GoogleLogin
          clientId={process.env.REACT_APP_GOOGLE_APP_ID}
          render={renderProps => (
            <GoogleLoginButton
              onClick={renderProps.onClick}
              disabled={renderProps.disabled}
            >
              <FaGoogle />
              <span>Continue with Google</span>
            </GoogleLoginButton>
          )}
          buttonText="Continue with Google"
          onSuccess={responseSuccessGoogle}
          onFailure={responseFailGoogle}
          cookiePolicy={'single_host_origin'}
        /> */}

        {/* <GoogleLoginButton>
          <FaGoogle />
          <span>Continue with Google</span>
        </GoogleLoginButton> */}

        {/* <DividerLayout>
          <DividerBar />
          <DividerText>OR</DividerText>
          <DividerBar />
        </DividerLayout> */}

        <form onSubmit={formik.handleSubmit}>
          <Input
            name="email"
            label="Email address"
            type="text"
            ref={textarea}
            value={formik.values.email}
            onChange={e => {
              e.persist();
              let indexNumber_1;
              const myArray = e.target.value.split('');
              for (var i = 0; i < myArray.length; i++) {
                if (
                  myArray[i] === myArray[i].toUpperCase() &&
                  myArray[i] !== myArray[i].toLowerCase()
                ) {
                  indexNumber_1 = i;
                  setIndexNumber(indexNumber);
                  console.log(i + ': ' + true);
                } else {
                  console.log(i + ': ' + false);
                }
              }
              let emailValue = ('' + e.target.value).toLowerCase();
              formik.setFieldValue('email', emailValue);
              setTimeout(() => {
                if (indexNumber_1 != undefined) {
                  textarea.current.selectionStart = indexNumber_1 + 1;
                  textarea.current.selectionEnd = indexNumber_1 + 1;
                }
              }, 10);
            }}
            onBlur={formik.handleBlur}
            error={formik.touched.email && Boolean(formik.errors.email)}
            errorMessage={formik.touched.email && formik.errors.email}
          />
          <Input
            name="password"
            label="Password"
            type={visibility ? 'text' : 'password'}
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            endIcon={
              visibility ? (
                <FaEyeSlash color="#ff9e08" />
              ) : (
                <FaEye color="#ff9e08" />
              )
            }
            handleEndIcon={handlePasswordVisibility}
            error={formik.touched.password && Boolean(formik.errors.password)}
            errorMessage={formik.touched.password && formik.errors.password}
          />
          {formType === 'LOGIN' &&
            signUpStatus.type === 'SUCCESS' &&
            hasSignedUp && (
              <SignUpSuccess>
                Successfully signed up{' '}
                {isGoogle
                  ? 'Please login using google login above.'
                  : 'Please login using your email address and password.'}
              </SignUpSuccess>
            )}
          {formType === 'LOGIN' && loginStatus.type === 'ERROR' && (
            <ErrorMessage>
              {loginStatus.data.code === 404 ? (
                <div style={{ color: '#ef5350', fontSize: '10px' }}>
                  User does not exist.{' '}
                  <span
                    onClick={() => {
                      setFormType('SIGN_UP');
                    }}
                    style={{ cursor: 'pointer', textDecoration: 'underline' }}
                  >
                    Please sign up
                  </span>
                </div>
              ) : loginStatus?.data?.code === 403 ? (
                ''
              ) : (
                loginStatus.data.message
              )}
            </ErrorMessage>
          )}

          {formType === `LOGIN` && (
            <>
              <SubmitButton
                fill
                isSUResearcher={isResearcher}
                style={{ margin: '2rem 0' }}
                type="submit"
                // disabled={
                //   !formik.values.password ||
                //   !formik.values.email ||
                //   loginStatus.type === 'LOADING' ||
                //   signUpStatus.type === 'LOADING'
                // }
              >
                <div style={{ flex: 1 }}>
                  {loginStatus.type === 'LOADING' ? (
                    <>
                      <FaSpinner className="spin" />
                      LOGGING IN AS A {isResearcher ? 'RESEARCHER' : 'CLIENT'}
                      ...
                    </>
                  ) : (
                    `${isResearcher ? 'RESEARCHER' : 'CLIENT'} LOGIN`
                  )}
                </div>
              </SubmitButton>
              <OtherFormText>
                <span onClick={() => history.push('/forgot-password')}>
                  Forgot Password
                </span>
              </OtherFormText>
              <OtherFormText>
                Join Big Robin.{' '}
                <span onClick={() => setFormType('SIGN_UP')}>Sign up</span>
              </OtherFormText>
            </>
          )}
          {formType === 'SIGN_UP' && (
            <>
              <AccountTypeContainer>
                <Checkbox
                  type="radio"
                  value={MEMBER_TYPES.RESEARCHER}
                  label="Join as a Researcher"
                  name="member_type_id"
                  checked={
                    formik.values.member_type_id === MEMBER_TYPES.RESEARCHER
                  }
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <Checkbox
                  type="radio"
                  value={MEMBER_TYPES.MEMBER}
                  label="Join as a Client"
                  name="member_type_id"
                  checked={formik.values.member_type_id === MEMBER_TYPES.MEMBER}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </AccountTypeContainer>

              <Checkbox
                label={
                  <ConditionsText>
                    I have read and understood the{' '}
                    <ConditionsLink
                      onClick={event => {
                        event.stopPropagation();
                        window.open(
                          `/terms-and-condition?user=${
                            formik.values.member_type_id ===
                            MEMBER_TYPES.RESEARCHER
                              ? 'r'
                              : 'c'
                          }`,
                          '_blank'
                        );
                      }}
                    >
                      Terms and Conditions
                    </ConditionsLink>{' '}
                    &{' '}
                    <ConditionsLink
                      onClick={event => {
                        event.stopPropagation();
                        window.open('/privacy-policy', '_blank');
                      }}
                    >
                      Privacy Policy
                    </ConditionsLink>
                  </ConditionsText>
                }
                name="understood"
                checked={agree}
                onBlur={formik.handleBlur}
                onClick={() => {
                  window.open(
                    `/terms-and-condition?user=${
                      formik.values.member_type_id === MEMBER_TYPES.RESEARCHER
                        ? 'r'
                        : 'c'
                    }`,
                    '_blank'
                  );
                }}
                readOnly={true}
              />
              {loginStatus.type !== 'ERROR' &&
                isFormInvalid &&
                (!agree ? (
                  <ErrorMessage>
                    <>This field is mandatory to proceed further</>
                  </ErrorMessage>
                ) : (
                  <ErrorMessage>
                    <>Please enter required details</>
                  </ErrorMessage>
                ))}
              {signUpStatus.type === 'ERROR' && (
                <ErrorMessage>{signUpStatus.data.message}</ErrorMessage>
              )}

              <SubmitButton
                fill
                style={{ margin: '2rem 0' }}
                type="submit"
                isSUResearcher={
                  formType === 'SIGN_UP' &&
                  formik.values.member_type_id === MEMBER_TYPES.RESEARCHER
                }
                // disabled={
                //   !formik.values.email ||
                //   !formik.values.password ||
                //   !formik.values.member_type_id ||
                //   !agree ||
                //   signUpStatus === 'LOADING'
                // }
              >
                <div style={{ flex: 1 }}>
                  {signUpStatus.type === 'LOADING' ? (
                    <>
                      <FaSpinner className="spin" />
                      JOINING...
                    </>
                  ) : (
                    'JOIN BIG ROBIN'
                  )}
                </div>
              </SubmitButton>
              <OtherFormText>
                <span onClick={() => history.push('/forgot-password')}>
                  Forgot Password
                </span>
              </OtherFormText>
              <OtherFormText>
                Already a member?{' '}
                <span
                  onClick={() => {
                    setFormType('LOGIN');
                    loginStatus.type = null;
                  }}
                >
                  Log in
                </span>
              </OtherFormText>
            </>
          )}
        </form>
      </LoginFormContainer>
      <Modal visible={showModal} setVisible={setShowModal} persistent={true}>
        <div
          style={{
            backgroundColor: 'white',
            padding: '2rem',
            flexDirection: 'column',
            justifyContent: 'flex-end',
            maxWidth: '350px',
          }}
        >
          <div style={{ marginBottom: '5rem' }}>{modalMsg}</div>

          <div>
            <Button
              fill
              style={{
                marginBottom: '1rem',
                backgroundColor: '#4b4c5e',
              }}
              onClick={() => {
                history.push({
                  pathname: '/login',
                  state: { isSignup: false, isResearcher: true },
                });
                setShowModal(false);
              }}
            >
              I want to login as a Researcher
            </Button>
            <Button
              fill
              style={{
                marginBottom: '1rem',
                backgroundColor: '#ff9e08',
              }}
              onClick={() => {
                history.push({
                  pathname: '/login',
                  state: { isSignup: false, isResearcher: false },
                });
                setShowModal(false);
              }}
            >
              I want to login as a Client
            </Button>
          </div>
        </div>
      </Modal>
    </LoginContent>
  );
};

export default Login;
