import React, { Fragment } from 'react';
import { FaChevronRight } from 'react-icons/fa';
import { BreadcrumbEnd, BreadcrumbLayout, BreadcrumbLink } from './styled';

const Breadcrumb = ({ path }) => {
  return (
    <BreadcrumbLayout>
      {path.map((item, index) => (
        <Fragment key={item.label}>
          {index === path.length - 1 ? (
            <BreadcrumbEnd>{item.label}</BreadcrumbEnd>
          ) : (
            <BreadcrumbLink to={item.to}>{item.label}</BreadcrumbLink>
          )}
          {index !== path.length - 1 && <FaChevronRight />}
        </Fragment>
      ))}
    </BreadcrumbLayout>
  );
};

export default Breadcrumb;
