import styled from 'styled-components/macro';

export const StepsLayout = styled.div`
  position: relative;
  display: flex;

  @media (max-width: 768px) {
    justify-content: center;
  }
`;

export const StepLayout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-right: 5rem;

  &:last-child {
    margin-right: 0;
  }

  p {
    color: #4b4c5e;
    font-size: 1rem;

    @media (max-width: 768px) {
      font-size: 1.5rem;
    }
  }
`;

export const StepCircle = styled.div`
  height: 2rem;
  width: 2rem;
  background-color: ${props => (props.active ? '#ff9e08' : '#DADADF')};
  border-radius: 50%;
  z-index: 0;
  margin-bottom: 1rem;

  @media (max-width: 768px) {
    height: 3rem;
    width: 3rem;
    margin-bottom: 1.5rem;
  }
`;

export const StepLadder = styled.div`
  position: absolute;
  height: 0.3rem;
  background-color: #dadadf;
  top: calc(1rem - 0.15rem);

  @media (max-width: 768px) {
    height: 0.45rem;
    top: calc(2rem - 0.6rem);
  }
`;
