import { Row } from 'components/Layout';
import React from 'react';
import { FaSpinner } from 'react-icons/fa';
import styled from 'styled-components/macro';

export const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  height: 100vh;
  background-color: white;

  * {
    margin-bottom: 1rem;
  }

  img {
    width: 20rem;
  }

  svg {
    font-size: 2rem;
    margin-right: 1rem;
  }

  h1 {
    font-size: 2rem;
  }
`;
export const Loading = () => {
  return (
    <LoadingContainer>
      <img src={require('../Navbar/logo.png')} alt="Big Robin" />
      <Row alignItems="center">
        <FaSpinner className="spin" />
        <h1>Loading...</h1>
      </Row>
    </LoadingContainer>
  );
};
