import Button from 'components/Button';
import Input from 'components/Input';
import { useAuthDispatch, useAuthState } from 'context/AuthContext';
import { useFormik } from 'formik';
import React, { useState, useEffect, useRef } from 'react';
// import { FaLinkedin } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import { ajax } from 'utils/ajax';
import * as SRegistration from './styled';
import {
  IconContainer,
  DefaultProfilePicture,
  UserProfileImageContainer,
  UserProfileImage,
  UserProfileInfo,
  UserProfileMenu,
  UserProfileContainer,
  // ConnectLinkedInContainer,
  HeadersTextContainer,
  HeadersContainer,
} from './styled';
import { FaPencilAlt } from 'react-icons/fa';
import LoadingIndicator from 'components/LoadingIndicator';
import SessionModal from 'components/SessionModal';
// import LinkedInPageUser from '../../components/LinkedInPageUser';
import Modal from 'components/Modal';
import { format } from 'date-fns';
import { clientRegistration } from 'utils/validation/clientRegistration';
// import DropdownWithName from 'components/DropdownWithName';
import { LabelLayout } from 'components/Input';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import ProfileCrop from 'components/ProfileCrop';

const Registration = () => {
  const history = useHistory();
  const { user, ip_address, is_profile_completed } = useAuthState();

  const [isShowingModalProfile, setIsShowingModalProfile] = useState(
    !is_profile_completed && history?.location?.state?.isPopup
  );
  const authDispatch = useAuthDispatch();
  const shouldExit = useRef(false);
  const [image, setImage] = useState({
    preview: user.member?.avatar_link || '',
    raw: user.profile_picture?.url || '',
    new_upload: false,
    key: user.profile_picture?.url || '',
    filename: user.profile_picture?.filename || '',
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [subscriptionID, setSubscriptionID] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [error, setErrorMsg] = useState();
  const [countryList, setCountryList] = useState();
  const [countryId, setCountryId] = useState('');
  const [billCountryId, setBillCountryId] = useState('');
  const [fullCountryList, setFullCountryList] = useState([]);
  if (localStorage.getItem('isLinkedInUser') === '1') {
    localStorage.setItem('isLinkedInUser', '0');
    window.location.reload();
  }

  const [isError, setIsError] = useState(false);

  const fNameRef = useRef();
  const lNameRef = useRef();
  const linkedinRef = useRef();
  const streetRef = useRef();
  const stCountryRef = useRef();
  const stCodeRef = useRef();
  const billingRef = useRef();
  const biCountryRef = useRef();
  const biCodeRef = useRef();

  const uploadFileToS3 = async (file, dir, filename) => {
    const new_filename =
      format(new Date(), 'MMDDDyyyyHHmmssSSS') + '/' + filename;
    const res = await ajax(
      'GET',
      '/s3/signed_url/' + dir + '?object_name=' + new_filename
    );
    if (res.code === 200 && res.data && res.data['signed_url']) {
      const headers = {};
      const upload_res = await fetch(res.data['signed_url'], {
        method: 'PUT',
        body: file,
        headers,
      });
      return await {
        status: upload_res.status,
        Key: dir + '/' + new_filename,
      };
    } else {
      return await res;
    }
  };

  const {
    values,
    handleChange,
    handleSubmit,
    setFieldValue,
    errors,
    touched,
  } = useFormik({
    initialValues: {
      accounts: user?.member?.accounts || [],
      first_name: user?.member?.first_name || '',
      last_name: user?.member?.last_name || '',
      address: user?.member?.address || '',
      address_2: user?.member?.address_2 || '',
      city: user?.member?.city || '',
      state: user?.member?.state || '',
      country: '',
      postal_code: user?.member?.postal_code || '',
      billing_address: user?.member?.billing_address || '',
      billing_address_2: user?.member?.billing_address_2 || '',
      billing_city: user?.member?.billing_city || '',
      billing_state: user?.member?.billing_state || '',
      billing_country: '',
      billing_postal_code: user?.member?.billing_postal_code || '',
      same_billing_information:
        user?.member?.address &&
        user?.member?.address === user?.member?.billing_address &&
        user?.member?.address_2 === user?.member?.billing_address_2 &&
        user?.member?.city === user?.member?.billing_city &&
        user?.member?.state === user?.member?.billing_state &&
        user?.member?.country === user?.member?.billing_country &&
        user?.member?.postal_code === user?.member?.billing_postal_code,
      company_affiliation: user?.member?.company_affiliation || '',
      email: user?.member?.email || '',
      contact_number: user?.member?.contact_number || '',
      contact_email: user?.member?.contact_email || '',
      reference_code: user?.member?.reference_code || '',
      is_company_account: user?.member?.company_type_id === 2,
      company_url: user?.member?.company_url || '',
      new_account: '',
      linked_in_url: user?.member?.linked_in_url || '',
    },
    validationSchema: clientRegistration,
    onSubmit: async data => {
      console.log(data);
      if (!countryList.includes(data?.country.trim())) {
        setIsError(true);
        stCountryRef.current.scrollIntoView({
          behavior: 'smooth',
        });

        return false;
      } else if (!countryList.includes(data?.billing_country.trim())) {
        setIsError(true);

        biCountryRef.current.scrollIntoView({
          behavior: 'smooth',
        });

        return false;
      } else {
        setIsError(false);
      }
      setIsLoading(true);

      if (data.new_account !== '') data.accounts.push(data.new_account);

      let profile_pic = [];
      let avatar_link = '';
      if (image && image.new_upload === false && image.raw === '') {
        profile_pic = [
          {
            filename: image.filename,
            url: image.key,
            description: '',
            attachment_type: 'Profile Picture',
          },
        ];
        avatar_link = image.key;
      }

      const body = {
        accounts: data?.accounts,
        member_id: user?.member?.id,
        platform: 'email',
        ip_address,
        first_name: data?.first_name.trim(),
        last_name: data?.last_name.trim(),
        address: data?.address,
        address_2: data?.address_2,
        city: data?.city,
        state: data?.state,
        country: countryId,
        postal_code: data?.postal_code,
        billing_address: data?.billing_address,
        billing_address_2: data?.billing_address_2,
        billing_city: data?.billing_city,
        billing_state: data?.billing_state,
        billing_country: billCountryId,
        billing_postal_code: data?.billing_postal_code,
        reference_code: data?.reference_code || '',
        contact_number: data?.contact_number,
        company_affiliation: data?.company_affiliation,
        company_type_id: data?.is_company_account ? 2 : 1,
        company_url: data?.company_url.trim(),
        contact_email: data?.contact_email,
        email: data?.email,
        profile_picture: profile_pic,
        avatar_link: avatar_link,
        linked_in_url: data?.linked_in_url,
        is_profile_completed: true,
      };

      try {
        if (image && image.new_upload === true) {
          if (image && image.new_upload === true) {
            const upload_res = await uploadFileToS3(
              image.raw,
              'profile',
              image.raw['name']
            );
            if (
              upload_res &&
              upload_res['status'] === 200 &&
              upload_res['Key']
            ) {
              body['profile_picture'] = [
                {
                  filename: image.raw['name'],
                  url: upload_res['Key'],
                  description: '',
                  attachment_type: 'Profile Picture',
                },
              ];
              body['avatar_link'] = upload_res['Key'];
            }
          }
        }
      } catch (err) {
        console.log(err);
      } finally {
        //setIsLoading(false);
      }

      try {
        const res = await ajax('PUT', '/register', body);

        if (res.code === 200) {
          const getRes = await ajax('GET', `/member/${user.member.id}`);
          if (getRes.code === 200) {
            authDispatch({ type: 'SET_USER', payload: getRes.data });
            if (shouldExit.current) {
              history.push('/welcome');
            }
          } else if (getRes.code === 401) {
            setIsLoading(false);
            setIsOpen(true);
          }
        } else if (res.code === 401) {
          setIsLoading(false);
          setIsOpen(true);
        } else {
          setIsLoading(false);
        }
      } catch (err) {
        console.log(err);
      } finally {
        // setIsLoading(false);
      }
      shouldExit.current = false;
    },
  });

  useEffect(() => {
    if (
      user.profile_picture &&
      user.profile_picture.length > 0 &&
      user.profile_picture[0]['url']
    ) {
      setImage({
        preview: user.profile_picture[0]['url'],
        raw: '',
        key: user.profile_picture[0]['key'],
        filename: user.profile_picture[0]['filename'],
        new_upload: false,
      });
    }
  }, [user.profile_picture]);

  useEffect(() => {
    const checkSubscription = async () => {
      const subs_url = '/member/' + user.member.id + '/subscription/active';
      const subs_res = await ajax('GET', subs_url);
      if (subs_res.code === 200 && subs_res.data) {
        setIsSubscribed(true);
        setSubscriptionID(subs_res.data.id);
      }
    };
    checkSubscription();
  }, []);

  useEffect(() => {
    const getCountryList = async () => {
      const subs_res = await ajax('GET', '/country');
      if (subs_res?.code === 200 && subs_res?.data) {
        const list =
          subs_res?.data?.length > 0
            ? subs_res?.data.map(
                item => item?.nicename + ' (' + item?.iso + ')'
              )
            : [];
        setCountryList(list);
        setFullCountryList(subs_res?.data);
      }
    };
    getCountryList();
  }, []);

  useEffect(() => {
    let countryValue = fullCountryList?.filter(
      item => item?.iso === user.member.country
    )[0];
    let billcountryValue = fullCountryList?.filter(
      item => item?.iso === user.member.billing_country
    )[0];
    setFieldValue(
      'country',
      countryValue
        ? countryValue?.nicename + ' (' + countryValue?.iso + ')'
        : ''
    );
    setFieldValue(
      'billing_country',
      billcountryValue
        ? billcountryValue?.name + ' (' + billcountryValue?.iso + ')'
        : ''
    );
    setCountryId(countryValue?.iso);
    setBillCountryId(billcountryValue?.iso);
  }, [fullCountryList, user]);
  useEffect(() => {
    if (values.same_billing_information) {
      setFieldValue('billing_address', values.address);
      setFieldValue('billing_address_2', values.address_2);
      setFieldValue('billing_city', values.city);
      setFieldValue('billing_state', values.state);
      setFieldValue('billing_country', values.country);
      setFieldValue('billing_postal_code', values.postal_code);
      setBillCountryId(countryId);
    } else {
      setFieldValue('billing_address', '');
      setFieldValue('billing_address_2', '');
      setFieldValue('billing_city', '');
      setFieldValue('billing_state', '');
      setFieldValue('billing_country', '');
      setFieldValue('billing_postal_code', '');
      setBillCountryId('');
    }
  }, [
    values.address,
    values.address_2,
    values.city,
    values.state,
    values.country,
    values.postal_code,
    values.same_billing_information,
  ]);

  // const disabled =
  //   !values.first_name ||
  //   !values.last_name ||
  //   !values.address ||
  //   !values.billing_information ||
  //   !values.company_affiliation ||
  //   !values.contact_number ||
  //   !values.website;

  const [profileImageToCrop, setProfileImageToCrop] = useState('');
  const [isProfileCropModal, setIsProfileCropModal] = useState(false);

  useEffect(() => {
    if (profileImageToCrop) {
      setIsProfileCropModal(true);
    }
  }, [profileImageToCrop]);

  const handleProfileChange = e => {
    if (e.target.files.length) {
      const reader = new FileReader();
      reader.onloadend = function() {
        setProfileImageToCrop(reader.result);
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const handleCancelSubscription = async () => {
    setErrorMsg(null);
    const cancel_sub = await ajax(
      'DELETE',
      `/member/${user.member.id}/subscription/${subscriptionID}`
    );
    if (cancel_sub.code === 200) {
      authDispatch({
        type: 'MEMBER_SUBSCRIBED',
        payload: { is_subscribed: false },
      });
      setIsSubscribed(false);
    } else setErrorMsg(cancel_sub.message);
  };

  const handleSaveButton = () => {
    shouldExit.current = true;
    handleSubmit();
    if (!values.first_name.trim()) {
      fNameRef.current.scrollIntoView({ behavior: 'smooth' });
    } else if (!values.last_name.trim()) {
      lNameRef.current.scrollIntoView({ behavior: 'smooth' });
    } else if (errors.linked_in_url) {
      linkedinRef.current.scrollIntoView({
        behavior: 'smooth',
      });
    } else if (!values.address) {
      streetRef.current.scrollIntoView({
        behavior: 'smooth',
      });
    } else if (!values.country) {
      stCountryRef.current.scrollIntoView({
        behavior: 'smooth',
      });
    } else if (!values.postal_code) {
      stCodeRef.current.scrollIntoView({
        behavior: 'smooth',
      });
    } else if (!values.billing_address) {
      billingRef.current.scrollIntoView({
        behavior: 'smooth',
      });
    } else if (!values.billing_country) {
      biCountryRef.current.scrollIntoView({
        behavior: 'smooth',
      });
    } else if (!values.billing_postal_code) {
      biCodeRef.current.scrollIntoView({
        behavior: 'smooth',
      });
    }
  };
  return (
    <SRegistration.RegistrationContent>
      {isLoading ? <LoadingIndicator /> : null}
      <SRegistration.HeaderLayout>
        <SRegistration.Title>User Profile</SRegistration.Title>
      </SRegistration.HeaderLayout>
      <SRegistration.Container>
        <HeadersContainer>
          <HeadersTextContainer>
            <SRegistration.FormInstructions>
              Completing your client profile will help verify you represent a
              going business and give you access to Big Robin researchers best
              suited for your needs.
            </SRegistration.FormInstructions>
          </HeadersTextContainer>
          {/* <ConnectLinkedInContainer>
            <SRegistration.LinkedInButton color="#0173B0" outline>
              <FaLinkedin />
              Connect LinkedIn
            </SRegistration.LinkedInButton>
          </ConnectLinkedInContainer> */}
        </HeadersContainer>
        <UserProfileContainer>
          <UserProfileMenu>
            <UserProfileImageContainer>
              <label
                htmlFor="upload-button"
                style={{ position: 'relative', display: 'block' }}
              >
                {image.preview ? (
                  <UserProfileImage src={image.preview} />
                ) : (
                  <DefaultProfilePicture />
                )}

                <IconContainer>
                  <FaPencilAlt />
                </IconContainer>
              </label>
              {image && image?.preview && (
                <div
                  style={{
                    // position: 'absolute',
                    marginTop: '5px',
                    padding: '10px 0px',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Button
                    outline
                    style={{ width: '90px', padding: '9px 12px' }}
                    onClick={e => {
                      e.stopPropagation();
                      setImage({
                        preview: '',
                        raw: '',
                        new_upload: false,
                        key: '',
                        filename: '',
                      });
                    }}
                  >
                    Remove
                  </Button>
                </div>
              )}
              <input
                type="file"
                id="upload-button"
                style={{ display: 'none' }}
                onChange={handleProfileChange}
              />
            </UserProfileImageContainer>
          </UserProfileMenu>
          <UserProfileInfo>
            <SRegistration.FormRow>
              {/* <LinkedInPageUser /> */}
              <Input
                label="Company website/url"
                name="company_url"
                value={values.company_url}
                onChange={handleChange}
              />
            </SRegistration.FormRow>
            <SRegistration.FormRow>
              <Input
                ref={fNameRef}
                label="First Name"
                name="first_name"
                required
                value={values.first_name}
                onChange={handleChange}
                error={touched.first_name && !!errors.first_name}
                errorMessage={touched.first_name && errors.first_name}
              />
              <Input
                ref={lNameRef}
                label="Last Name"
                name="last_name"
                required
                value={values.last_name}
                onChange={handleChange}
                error={touched.last_name && !!errors.last_name}
                errorMessage={touched.last_name && errors.last_name}
              />
            </SRegistration.FormRow>
            <SRegistration.FormRow>
              <Input
                ref={linkedinRef}
                label="Linkedin Account ID"
                name="linked_in_url"
                value={values.linked_in_url}
                required
                onChange={handleChange}
                placeholder="https://www.linkedin.com/in/username"
                error={touched.linked_in_url && !!errors.linked_in_url}
                errorMessage={touched.linked_in_url && errors.linked_in_url}
              />
            </SRegistration.FormRow>
            <SRegistration.FormRow>
              <Input
                label="Email"
                name="email"
                disabled
                style={{ cursor: 'not-allowed' }}
                value={values.email}
                onChange={handleChange}
                error={touched.email && !!errors.email}
                errorMessage={touched.email && errors.email}
              />
            </SRegistration.FormRow>
            <SRegistration.FormRow>
              <Input
                ref={streetRef}
                label="Street Address"
                name="address"
                required
                value={values.address}
                onChange={handleChange}
                error={touched.address && !!errors.address}
                errorMessage={touched.address && errors.address}
              />
            </SRegistration.FormRow>
            <SRegistration.FormRow>
              <Input
                label="Street Address Line 2 (optional)"
                name="address_2"
                value={values.address_2}
                onChange={handleChange}
              />
            </SRegistration.FormRow>
            <SRegistration.FormRow>
              <Input
                label="City"
                name="city"
                value={values.city}
                onChange={handleChange}
              />
              <Input
                label="State"
                name="state"
                value={values.state}
                onChange={handleChange}
              />
              <div style={{ flex: 1 }} ref={stCountryRef}>
                <LabelLayout>
                  <label>
                    Country <span style={{ color: 'red' }}>*</span>
                  </label>
                </LabelLayout>
                <Autocomplete
                  id={'country'}
                  freeSolo
                  autoSelect
                  getOptionLabel={option => option.title || option}
                  name="country"
                  // disableClearable
                  options={countryList || []}
                  onChange={(e, newValue) => {
                    setFieldValue('country', newValue ? newValue : '');
                    if (newValue) {
                      let id = newValue?.split(' (');
                      const filterISO = fullCountryList?.filter(
                        item => item.nicename === id[0]
                      );
                      setCountryId(filterISO[0]?.iso || '');
                    } else {
                      setCountryId('');
                    }
                  }}
                  value={values?.country || ''}
                  renderInput={params => (
                    <TextField
                      {...params}
                      value={values?.country || ''}
                      variant="outlined"
                      placeholder="Country"
                      className="clientProfile"
                      margin="normal"
                      fullWidth
                      error={
                        (touched.country && Boolean(errors.country)) ||
                        (isError &&
                          !countryList.includes(values?.country.trim()))
                      }
                      helperText={
                        (touched.country && errors.country) ||
                        (isError &&
                          !countryList.includes(values?.country.trim()) &&
                          'Country is required')
                      }
                    />
                  )}
                />
              </div>
            </SRegistration.FormRow>
            <SRegistration.FormRow>
              <Input
                ref={stCodeRef}
                label="Zip/Postal Code"
                name="postal_code"
                required
                value={values.postal_code}
                onChange={handleChange}
                error={touched.postal_code && !!errors.postal_code}
                errorMessage={touched.postal_code && errors.postal_code}
              />
            </SRegistration.FormRow>
            <SRegistration.FormRow>
              <Input
                ref={billingRef}
                label="Billing Street Address"
                name="billing_address"
                required
                value={values.billing_address}
                onChange={handleChange}
                disabled={values.same_billing_information}
                checkboxProps={{
                  label: 'Same as Street Address',
                  name: 'same_billing_information',
                  value: values?.same_billing_information,
                  onChange: handleChange,
                  checked: values?.same_billing_information,
                }}
                error={touched.billing_address && !!errors.billing_address}
                errorMessage={touched.billing_address && errors.billing_address}
              />
            </SRegistration.FormRow>
            <SRegistration.FormRow>
              <Input
                label="Billing Street Address Line 2 (optional)"
                name="billing_address_2"
                value={values.billing_address_2}
                onChange={handleChange}
                disabled={values.same_billing_information}
              />
            </SRegistration.FormRow>
            <SRegistration.FormRow>
              <Input
                label="City"
                name="billing_city"
                value={values.billing_city}
                onChange={handleChange}
                disabled={values.same_billing_information}
              />
              <Input
                label="State"
                name="billing_state"
                value={values.billing_state}
                onChange={handleChange}
                disabled={values.same_billing_information}
              />
              <div style={{ flex: 1 }} ref={biCountryRef}>
                <LabelLayout>
                  <label>
                    Country
                    <span style={{ color: 'red' }}>*</span>
                  </label>
                </LabelLayout>
                <Autocomplete
                  id={'billing_country'}
                  freeSolo
                  // disableClearable
                  autoSelect
                  getOptionLabel={option => option.title || option}
                  name="billing_country"
                  options={countryList}
                  onChange={(e, newValue) => {
                    setFieldValue('billing_country', newValue ? newValue : '');
                    if (newValue) {
                      let id = newValue?.split(' (');
                      const filterISO = fullCountryList?.filter(
                        item => item.name === id[0]
                      );
                      setBillCountryId(filterISO[0].iso);
                    } else {
                      setBillCountryId('');
                    }
                  }}
                  value={values.billing_country}
                  disabled={values.same_billing_information}
                  renderInput={params => (
                    <TextField
                      {...params}
                      value={values.billing_country}
                      variant="outlined"
                      placeholder="Billing Country"
                      className="clientProfile"
                      margin="normal"
                      fullWidth
                      error={
                        touched.billing_country &&
                        Boolean(errors.billing_country)
                      }
                      helperText={
                        touched.billing_country && errors.billing_country
                      }
                    />
                  )}
                />
              </div>
            </SRegistration.FormRow>
            <SRegistration.FormRow>
              <Input
                ref={biCodeRef}
                label="Zip/Postal Code"
                name="billing_postal_code"
                required
                value={values.billing_postal_code}
                onChange={handleChange}
                disabled={values.same_billing_information}
                error={
                  touched.billing_postal_code && !!errors.billing_postal_code
                }
                errorMessage={
                  touched.billing_postal_code && errors.billing_postal_code
                }
              />
            </SRegistration.FormRow>
            <SRegistration.FormRow>
              <Input
                ref={biCodeRef}
                label="Reference Code (if any)"
                name="reference_code"
                value={values?.reference_code || ''}
                onChange={handleChange}
                disabled={user?.member?.reference_code}
              />
            </SRegistration.FormRow>
            <SRegistration.FormRow>
              <div style={{ flex: 1 }}>
                <Input
                  label="Company Affiliation"
                  name="company_affiliation"
                  value={values.company_affiliation}
                  onChange={handleChange}
                  error={
                    touched.company_affiliation && !!errors.company_affiliation
                  }
                  errorMessage={
                    touched.company_affiliation && errors.company_affiliation
                  }
                />
                <SRegistration.MRCheckbox
                  label="This is a company account"
                  name="is_company_account"
                  value={values?.is_company_account}
                  onChange={handleChange}
                  checked={values?.is_company_account}
                />
              </div>
              <Input
                label="Contact No."
                name="contact_number"
                value={values.contact_number}
                onChange={handleChange}
                error={touched.contact_number && !!errors.contact_number}
                errorMessage={touched.contact_number && errors.contact_number}
              />
              <Input
                label="Contact Email"
                name="contact_email"
                value={values.contact_email}
                onChange={handleChange}
                error={touched.contact_email && !!errors.contact_email}
                errorMessage={touched.contact_email && errors.contact_email}
              />
            </SRegistration.FormRow>
            {values.accounts.map((account, index) => {
              return (
                <SRegistration.FormRow key={index}>
                  <Input
                    label={`Account Alias ${index === 0 ? '(Primary)' : ''}`}
                    name={`account[${index}]`}
                    value={account}
                    onChange={handleChange}
                    style={{ maxWidth: '300px' }}
                  />
                  <Button
                    outline
                    style={{
                      height: '50px',
                      maxWidth: '230px',
                      top: '35px',
                      position: 'relative',
                    }}
                    onClick={() => {
                      const arr = values.accounts;
                      const start = arr.indexOf(values.accounts[index]);
                      if (index > -1) arr.splice(start, 1);
                      setFieldValue('account', arr);
                    }}
                  >
                    Remove Account
                  </Button>
                </SRegistration.FormRow>
              );
            })}

            {/* <SRegistration.FormRow>
              <Input
                label={`Account Alias ${
                  values.accounts.length === 0 ? '(Primary)' : ''
                }`}
                name="new_account"
                onChange={handleChange}
                style={{ maxWidth: '300px' }}
                value={values.new_account}
              />
              <Button
                outline
                style={{
                  height: '50px',
                  maxWidth: '220px',
                  top: '35px',
                  position: 'relative',
                }}
                onClick={() => {
                  if (values.new_account !== '') {
                    values.accounts.push(values.new_account);
                    setFieldValue('new_account', '');
                  }
                }}
              >
                + New Account
              </Button>
            </SRegistration.FormRow> */}
          </UserProfileInfo>
        </UserProfileContainer>
        <SRegistration.ButtonsLayout>
          <Button outline onClick={handleSaveButton}>
            SAVE & EXIT
          </Button>
        </SRegistration.ButtonsLayout>
      </SRegistration.Container>
      <SessionModal
        isOpen={isOpen}
        setModalVisible={setIsOpen}
        message={'Your session has expired.'}
      />
      <Modal visible={showModal} setVisible={setShowModal} persistent={true}>
        <div
          style={{
            backgroundColor: 'white',
            padding: '2rem',
            flexDirection: 'column',
            justifyContent: 'flex-end',
            maxWidth: '350px',
            textAlign: 'center',
          }}
        >
          <div style={{ marginBottom: '2rem' }}>
            {isSubscribed
              ? 'Are you sure you want to cancel your subscription?'
              : 'Your subscription has been successfully cancelled'}
          </div>

          <div>
            {error && (
              <p
                style={{
                  color: 'red',
                  marginBottom: '15px',
                  textAlign: 'center',
                }}
              >
                {error}
              </p>
            )}
            <Button
              outline
              style={{
                marginBottom: '1rem',
                width: '100%',
              }}
              onClick={() => {
                if (isSubscribed) handleCancelSubscription();
                else {
                  setShowModal(false);
                  window.scrollTo(0, 0);
                }
              }}
            >
              {isSubscribed ? 'Yes, cancel subscription' : 'OK'}
            </Button>
            {isSubscribed && (
              <Button
                fill
                style={{
                  marginBottom: '1rem',
                  backgroundColor: '#ff9e08',
                  width: '100%',
                }}
                onClick={() => {
                  setShowModal(false);
                }}
              >
                No
              </Button>
            )}
          </div>
        </div>
      </Modal>
      <Modal
        visible={isShowingModalProfile}
        setVisible={setIsShowingModalProfile}
        persistent
      >
        <div
          style={{
            backgroundColor: 'white',
            padding: '2rem',
            flexDirection: 'column',
            justifyContent: 'flex-end',
            maxWidth: `${'300px'}`,
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        >
          <div
            style={{
              marginBottom: '20px',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <b style={{ textAlign: 'center' }}>
              In order to proceed, please complete your profile first.
            </b>
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              marginTop: '30px',
            }}
          >
            <Button
              outline
              style={{ marginRight: '8px', width: '100px' }}
              onClick={() => {
                setIsShowingModalProfile(false);
              }}
            >
              Close
            </Button>
          </div>
        </div>
      </Modal>
      {isProfileCropModal && (
        <ProfileCrop
          visible={isProfileCropModal}
          setVisible={setIsProfileCropModal}
          imageBlob={profileImageToCrop}
          setImage={setImage}
        />
      )}
    </SRegistration.RegistrationContent>
  );
};

export default Registration;
