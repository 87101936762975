import styled, { css } from 'styled-components/macro';
import Content, { MiddleContainer } from 'components/Content';
import { InputContainer } from 'components/Input/styled';
import { Row, Column } from 'components/Layout';

export const MessagesContent = styled(Content)`
  flex: 1;

  ${MiddleContainer} {
    height: calc(100vh - 21.5rem);

    margin-top: 4rem;
    margin-bottom: 4rem;
    background-color: white;
    padding: 4rem 2rem;

    @media (max-width: 768px) {
      height: auto;
      display: block;
      margin: 0rem;
      padding: 0rem;
    }
  }
`;

export const MessagesTitle = styled.h1`
  font-size: 2.2rem;
  color: #4b4c5e;

  @media (max-width: 768px) {
    margin: 1.75rem 2rem 0.5rem;
    font-size: 3rem;
  }
`;

export const MobileMessageContainer = styled(Row)`
  width: 100%;
  height: 80rem;
  overflow: hidden;
  background-color: #77777700;
`;

export const MessagesListContainer = styled(Column)`
  border-right: 0.1rem solid #dadadf;
  height: 100%;

  @media (max-width: 768px) {
    width: 100%;
    margin-left: ${props => (props.chats ? '-100%' : '0%')};
    border: none;

    transition-property: margin-left;
    transition-duration: 0.5s;
    transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  }

  ${InputContainer} {
    margin-top: 1rem;
    margin-bottom: 1rem;
    margin-right: 2rem;

    @media (max-width: 768px) {
      margin-left: 2rem;
      margin-right: 2rem;
      margin-bottom: 1.5rem;
    }
  }
`;

export const MessageListItemName = styled.div`
  color: #65637b;
  font-size: 1.7rem;
  white-space: pre;
  text-overflow: ellipsis;
  overflow: hidden;

  @media (max-width: 768px) {
    font-size: 2.7rem;
    margin-bottom: 0.5rem;
  }
`;

export const MessageListItemPeek = styled.div`
  color: #65637b;
  font-size: 1.4rem;
  white-space: pre;
  text-overflow: ellipsis;
  overflow: hidden;

  @media (max-width: 768px) {
    font-size: 2.4rem;
  }
`;

export const MessageListItemDate = styled.div`
  font-size: 1rem;
  font-weight: bold;
  color: #65637b;
  opacity: 0.6;

  @media (max-width: 768px) {
    margin-top: 0.5rem;
    font-size: 2rem;
  }
`;

export const MessageListItem = styled(Row)`
  cursor: pointer;
  flex-wrap: nowrap;
  border-bottom: 0.1rem solid #dadadf;
  &:last-child {
    border-bottom: 0;
  }
  padding: 1.5rem 0;
  width: 40rem;
  overflow-x: hidden;

  @media (max-width: 768px) {
    width: auto;
    margin-left: 2rem;
    margin-right: 2rem;
  }

  img {
    height: 4.5rem;
    min-width: 4.5rem;
    border-radius: 50%;
    margin: 0 1.5rem;
  }

  ${props =>
    props.selected &&
    css`
      background-color: rgba(218, 218, 223, 0.4);
    `}

  ${props =>
    props.notRead &&
    css`
      ${MessageListItemName} {
        font-weight: bold;
      }

      ${MessageListItemPeek} {
        font-weight: bold;
      }

      ${MessageListItemDate} {
        opacity: 1;
      }
    `}
`;

export const MessagesConversationContainer = styled.div`
  flex: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0 2rem;
`;

export const MessagesConversation = styled.div`
  flex: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
`;

export const ConversationItemMessage = styled.div`
  padding: 1rem;
  background-color: rgba(218, 218, 223, 0.3);
  max-width: 75rem;
`;

export const ConversationItemUserImageContainer = styled(Column)`
  align-items: center;
  margin-right: 1rem;
`;

export const ConversationItemUserImage = styled.img`
  height: 5.5rem;
  width: 5.5rem;
  border-radius: 50%;
  min-width: 5.5rem;
`;

export const MessageConversationItem = styled.div`
  display: flex;
  margin-right: 2rem;
  margin-bottom: 3rem;
  align-items: flex-start;
  justify-content: ${props => (props.isSender ? 'flex-end' : 'flex-start')};

  ${props =>
    props.isSender &&
    css`
      ${ConversationItemMessage} {
        background-color: rgb(245, 140, 6, 0.2);
      }

      ${ConversationItemUserImageContainer} {
        margin-right: 0rem;
        margin-left: 1rem;
      }
    `};
`;

export const DateDivider = styled(Row)`
  align-items: center;
  color: #65637b;
  opacity: 0.7;
  font-size: 1.6rem;
  margin-bottom: 2rem;
  margin-right: 2rem;
`;

export const DateDividerBar = styled.div`
  flex: 1;
  height: 0.1rem;
  background-color: #65637b;
  opacity: 0.7;
`;

export const ViewProjectButton = styled.button`
  margin-top: 1rem;
  display: block;
  font-size: 1.5rem;
  font-weight: bold;
  color: white;
  background-color: #65637b;
  width: 26rem;
  height: 4rem;
`;

export const MessageOption = styled.button`
  border: 1px solid #ff9e08;
  padding: 0.5rem 1rem;
  border-radius: 0.8rem;
  color: #ff9e08;
  margin-left: 1rem;
`;
