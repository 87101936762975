import React from 'react';
import {
  FaCheck,
  FaToggleOff,
  FaToggleOn,
  FaCheckSquare,
} from 'react-icons/fa';
import { CheckboxContainer, CheckboxLayout, RadioDot } from './styled';

const Checkbox = ({
  type = 'checkbox',
  value,
  checked,
  className,
  disabled,
  label,
  name,
  style,
  onChange,
  onBlur,
  onClick,
  readOnly,
}) => {
  return (
    <CheckboxLayout style={style} className={className}>
      <input
        name={name}
        type={type === 'radio' ? 'radio' : 'checkbox'}
        value={value}
        checked={checked}
        onChange={onChange}
        onBlur={onBlur}
        onClick={onClick}
        disabled={disabled}
        readOnly={readOnly}
      />
      <CheckboxContainer type={type}>
        {type === 'checkbox' && <FaCheck />}
        {type === 'squarecheckbox' && <FaCheckSquare />}
        {type === 'radio' && <RadioDot />}
        {type === 'toggle' && (checked ? <FaToggleOn /> : <FaToggleOff />)}
      </CheckboxContainer>
      {label}
    </CheckboxLayout>
  );
};

export default Checkbox;
