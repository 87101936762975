import React, { createContext, useContext, useEffect, useReducer } from 'react';
import { ajax } from 'utils/ajax';

const AuthStateContext = createContext({});
const AuthDispatchContext = createContext();

export const MEMBER_TYPES = {
  MEMBER: '2',
  RESEARCHER: '3',
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_USER': {
      return {
        ...state,
        user: action.payload,
        isGettingUser: false,
        is_profile_completed:
          action?.payload?.member?.is_profile_completed || false,
        isResearcher: action?.payload?.member?.member_type_id === 3 || false,
        isMemberSubscribed:
          (action?.payload?.member?.member_type_id !== 3 &&
            action?.payload?.member?.is_subscribed) ||
          false,
      };
    }

    case 'MEMBER_SUBSCRIBED': {
      return {
        ...state,
        isMemberSubscribed: action?.payload?.is_subscribed || false,
      };
    }

    case 'CONVERSATION': {
      return {
        ...state,
        conversationId: action?.payload?.conversationId,
      };
    }

    case 'SET_IP_ADDRESS':
      return { ...state, ip_address: action.payload };

    case 'LOGIN_START':
      return { ...state, loginStatus: { type: 'LOADING' } };

    case 'LOGIN_SUCCESS':
      localStorage.setItem('br-authToken', action.payload.authentication.token);
      localStorage.setItem('br-userId', action.payload.member.id);
      localStorage.setItem(
        'br-memberType',
        action.payload.member.member_type_id
      );
      return {
        ...state,
        loginStatus: {},
        user: action.payload,
        is_profile_completed:
          action?.payload?.member?.is_profile_completed || false,
        isResearcher: action?.payload?.member?.member_type_id === 3 || false,
        isMemberSubscribed:
          (action?.payload?.member?.member_type_id !== 3 &&
            action?.payload?.member?.is_subscribed) ||
          false,
      };

    case 'LOGIN_ERROR':
      return {
        ...state,
        loginStatus: { type: 'ERROR', data: action.payload },
      };

    case 'LOGIN_RESET':
      return { ...state, signUpStatus: {} };

    case 'SIGNUP_START':
      return { ...state, signUpStatus: { type: 'LOADING' } };

    case 'SIGNUP_SUCCESS':
      localStorage.setItem('br-authToken', action.payload.authentication.token);
      localStorage.setItem('br-userId', action.payload.member.id);
      localStorage.setItem(
        'br-memberType',
        action.payload.member.member_type_id
      );
      return {
        ...state,
        signUpStatus: { type: 'SUCCESS' },
        user: action.payload,
        is_profile_completed:
          action?.payload?.member?.is_profile_completed || false,
        isResearcher: action?.payload?.member?.member_type_id === 3 || false,
        isMemberSubscribed:
          (action?.payload?.member?.member_type_id !== 3 &&
            action?.payload?.member?.is_subscribed) ||
          false,
      };

    case 'SIGNUP_ERROR':
      return {
        ...state,
        signUpStatus: { type: 'ERROR', data: action.payload },
      };

    case 'SIGNUP_RESET':
      return { ...state, signUpStatus: {} };

    default:
      return state;
  }
};

export const login = async (dispatch, credentials, isResearcher) => {
  dispatch({ type: 'LOGIN_START' });

  const response = await ajax('POST', '/login', credentials);

  // has error
  if (response.code !== 200) {
    dispatch({ type: 'LOGIN_ERROR', payload: response });
    throw response;
  } else {
    localStorage.setItem('isResearcher', isResearcher);
    dispatch({ type: 'LOGIN_SUCCESS', payload: response.data });
  }
};

export const signUp = async (dispatch, credentials, isResearcher) => {
  dispatch({ type: 'SIGNUP_START' });

  const response = await ajax('POST', '/signup', credentials);

  // has error
  if (response.code !== 200) {
    dispatch({ type: 'SIGNUP_ERROR', payload: response });
    throw response;
  } else {
    localStorage.setItem('isResearcher', isResearcher);
    dispatch({ type: 'SIGNUP_SUCCESS', payload: response.data });
  }
};

export const logout = async dispatch => {
  const member_id = localStorage.getItem('br-userId');
  if (member_id) {
    const res = await ajax('POST', '/logout', { member_id: +member_id });
    if (res.code === 200) {
      localStorage.removeItem('br-authToken');
      localStorage.removeItem('br-userId');
      localStorage.removeItem('isResearcher');
      localStorage.removeItem('attempt');
      sessionStorage.removeItem('join-email');
      sessionStorage.removeItem('join-fullname');
      localStorage.clear();
      sessionStorage.clear();
      dispatch({ type: 'SET_USER', payload: null });
    }
  }
};

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, {
    user: null,
    ip_address: '',
    loginStatus: {},
    signUpStatus: {},
    isGettingUser: true,
    is_profile_completed: false,
    isResearcher: null,
    conversationId: null,
    isMemberSubscribed: false,
  });

  useEffect(() => {
    (async () => {
      const data = await (
        await fetch(`https://api.ipify.org?format=json`)
      ).json();
      dispatch({ type: 'SET_IP_ADDRESS', payload: data.ip });
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const userId = localStorage.getItem('br-userId');
      if (userId) {
        const res = await ajax('GET', `/member/${userId}`);

        if (res.code === 200) {
          dispatch({ type: 'SET_USER', payload: res.data });
        } else {
          dispatch({ type: 'SET_USER', payload: null });
          localStorage.removeItem('br-authToken');
          localStorage.removeItem('br-userId');
          localStorage.removeItem('isResearcher');
        }
      } else {
        dispatch({ type: 'SET_USER', payload: null });
      }
    })();
  }, []);

  return (
    <AuthStateContext.Provider value={state}>
      <AuthDispatchContext.Provider value={dispatch}>
        {children}
      </AuthDispatchContext.Provider>
    </AuthStateContext.Provider>
  );
};

export const useAuthState = () => {
  return useContext(AuthStateContext);
};

export const useAuthDispatch = () => {
  return useContext(AuthDispatchContext);
};
