import Button from 'components/Button';
import Content, { MiddleContainer } from 'components/Content';
import styled from 'styled-components/macro';

export const WelcomeContent = styled(Content)`
  margin: 4rem 0;
  flex: 1;

  ${MiddleContainer} {
    background-color: white;
    padding: 7rem 20rem;

    @media (max-width: 1200px) {
      padding-left: 10rem;
      padding-right: 10rem;
    }

    @media (max-width: 900px) {
      padding: 5rem;
    }

    @media (max-width: 768px) {
      padding: 2.5rem;
    }
  }
`;

export const WelcomeName = styled.p`
  color: #4b4c5e;
  font-size: 2.2rem;
  font-weight: bold;
  margin-bottom: 2rem;

  @media (max-width: 900px) {
    font-size: 2.6rem;
  }

  @media (max-width: 768px) {
    font-size: 3rem;
  }
`;

export const WelcomeInstructions = styled.p`
  color: #4b4c5e;
  font-size: 1.6rem;
  margin-bottom: 7rem;

  @media (max-width: 900px) {
    font-size: 2rem;
  }

  @media (max-width: 768px) {
    font-size: 2.4rem;
  }
`;

export const WelcomeOptionsLayout = styled.div`
  display: flex;
  justify-content: space-around;

  @media (max-width: 900px) {
    display: block;
    width: 90%;
    margin-left: 5%;
    margine-right: 5%;
  }
`;

export const WelcomeOptionContainer = styled.div`
  border: 1px solid #dadadf;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 900px) {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }

  img {
    height: 16rem;

    @media (max-width: 900px) {
      height: auto;
      width: 100%;
    }
  }

  ${Button} {
    margin: 1rem;
    background-color: ${props => (props.isResearcher ? '#4b4c5e' : '#ff9e08')};

    @media (max-width: 900px) {
      margin-top: 2rem;
      margin-bottom: 2rem;
      padding-top: 1rem;
      padding-bottom: 1rem;
      font-size: 2rem;
    }
  }
`;
