import LandingJoinForm from 'pages/Landing/LandingJoinForm';
import ResearcherList from 'pages/ResearcherList';
import React from 'react';
import { useLocation } from 'react-router-dom';
import {
  ResearchersContainer,
  ResearchersTitle,
  ResearcherContent,
} from './styled';

const Researchers = ({ className }) => {
  const location = useLocation();
  const query = location.state?.query ? location.state?.query : null;
  return (
    <ResearchersContainer className={className}>
      <ResearcherContent>
        <ResearchersTitle>Researchers</ResearchersTitle>
        <ResearcherList query={query} />
      </ResearcherContent>
      <LandingJoinForm />
    </ResearchersContainer>
  );
};

export default Researchers;
