import styled, { css } from 'styled-components/macro';

export const OverlayBackground = styled.div`
  z-index: 1000;
  position: fixed;
  height: 100vh;
  width: 100vw;

  background-color: rgba(0, 0, 0, 0.5);

  display: flex;
  justify-content: center;
  align-items: center;

  visibility: hidden;
  opacity: 0;
  transition: all 0.2s;

  ${props =>
    props.visible &&
    css`
      visibility: visible;
      opacity: 1;
    `}
`;

export const ModalContainer = styled.div`
  opacity: 0;
  transform: scale(0.7);
  transition: all 0.2s;

  ${props =>
    props.visible &&
    css`
      transform: scale(1);
      opacity: 1;
    `}
`;
