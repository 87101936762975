import React, { useCallback, useState, useEffect } from 'react';
import Input from 'components/Input';
import LoadingIndicator from 'components/LoadingIndicator';
import ResearcherFilter from 'components/ResearcherFilter';
import UserCard from 'components/UserCard';
import ResearcherCard from 'components/ResearcherCard';
import { CardsLayout } from 'components/UserCard/styled';
import { FaMapMarkerAlt, FaSearch } from 'react-icons/fa';
import Searchbar from 'components/Searchbar';
import {
  LoadMoreButton,
  LoadMoreLayout,
  RealtimeContainer,
  ResearcherListContainer,
  SearchbarLayout,
} from './styled';
import { ajax } from 'utils/ajax';
import querystring from 'querystring';
import SessionModal from 'components/SessionModal';
import { Container } from 'pages/ShareProject';

const ResearcherList = ({ className, selectedSpecialization, query }) => {
  const [page, setPage] = useState(1);
  const [researchers, setResearchers] = useState(
    query && query.industry ? [query.industry] : []
  );
  const [search, setSearch] = useState(query ? query.search : '');
  const [location, setLocation] = useState('');
  const [industry, setIndustry] = useState([]);
  const [specializations, setSpecializations] = useState(
    selectedSpecialization
      ? [selectedSpecialization]
      : query && query.specialization
      ? [query.specialization]
      : []
  );
  const [realtimeSpecialization, setRealtimeSpecialization] = useState();
  const [realtimeIndustry, setRealtimeIndustry] = useState();
  const [mastery, setMastery] = useState([]);
  const [expertise, setExpertise] = useState([]);
  const [minPrice, setMinPrice] = useState(null);
  const [maxPrice, setMaxPrice] = useState(null);
  const [responseTime, setResponseTime] = useState([]);
  const [rating, setRating] = useState([]);
  const [language, setLanguage] = useState([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [realtimeSearch, setRealtimeSearch] = useState([]);
  const [realtimeOptions, setRealtimeOptions] = useState([]);
  const [isFocus, setFocus] = useState(false);
  const [flag, setFlag] = useState(true);

  const handleSearch = useCallback(
    (query, cleared, industryRT, specializationRT) => {
      setLoading(true);
      let filter = { page_size: 9, page: page };
      if (location) filter['location'] = location;
      if (language && language.length > 0 && cleared !== 'language') {
        filter['languages'] = language.join(',');
      }

      if (industry && industry.length > 0 && cleared !== 'industry') {
        filter['industry'] = industry.join(',');
      }

      if (industryRT) {
        filter['industry'] = industryRT;
      }

      if (
        specializations &&
        specializations.length > 0 &&
        cleared !== 'specializations'
      ) {
        filter['specialization'] = specializations.join(',');
      }

      if (specializationRT) {
        filter['specialization'] = specializationRT;
      }

      if (mastery && mastery.length > 0 && cleared !== 'mastery') {
        filter['mastery_score'] = mastery.join(',');
      }

      if (expertise && expertise.length > 0 && cleared !== 'expertise') {
        let newExp = [];
        expertise.forEach(val => {
          if (val === 'DATA SCIENCE/ML/AI') {
            newExp.push(2);
          }
          if (val === 'INSIGHTS/MARKET RESEARCH') {
            newExp.push(1);
          }
          if (val === 'RESEARCH/ML OPERATIONS') {
            newExp.push(11);
          }
        });
        filter['services_offered'] = newExp.join(',');
      }

      if (search !== '') {
        filter['search'] = query;
      }

      if (
        responseTime &&
        responseTime.length > 0 &&
        cleared !== 'responseTime'
      ) {
        filter['response_time'] = responseTime.join(',');
      }

      if (rating && rating.length > 0 && cleared !== 'rating') {
        filter['customer_ratings'] = rating.join(',');
      }

      if (minPrice > 0) {
        filter['min_budget'] = minPrice;
      }
      if (maxPrice > 0) {
        filter['max_budget'] = maxPrice;
      }
      handleGetResearchers(page, filter);
    }
  );

  const handleGetResearchers = (page, query) => {
    setLoading(true);
    ajax('GET', `/member/researcher?${querystring.stringify(query)}`).then(
      result => {
        setLoading(false);
        if (result.code === 200) {
          setTotal(result.meta.total_number);
          setFlag(!flag);
          if (result.data && result.data.length === 0) {
            setResearchers([]);
          } else {
            if (page === 1) {
              setResearchers([...result.data]);
            } else {
              setResearchers(researchers => [...researchers, ...result.data]);
            }
          }
        } else if (result.code === 401) {
          setIsOpen(true);
        }
      }
    );
  };

  // const handleRealTimeSearch = search => {
  //   const realtimeArray = [];
  //   const realtimeArrayChecker = [];
  //   if (search !== '') {
  //     realtimeOptions.map(item => {
  //       if (
  //         item.name &&
  //         item.name.toLowerCase().includes(search.toLowerCase()) &&
  //         !realtimeArrayChecker.includes(item.name.toLowerCase())
  //       ) {
  //         realtimeArrayChecker.push(item.name);
  //         realtimeArray.push(item);
  //       }
  //       setRealtimeSearch(realtimeArray);
  //     });
  //   } else setRealtimeSearch([]);
  // };

  useEffect(() => {
    if (isOpen === false) {
      handleSearch(search, '');
    }
  }, [page, selectedSpecialization]);

  useEffect(() => {
    if (isOpen === false) {
      ajax('GET', '/member_reference_dropdown').then(res => {
        if (res.code === 200) {
          let arr = [];
          res.data.analytics_research.map(item => {
            arr.push({ ...item, type: 'specialization' });
          });
          res.data.market_research.map(item => {
            arr.push({ ...item, type: 'specialization' });
          });
          res.data.industry.map(item => {
            arr.push({ ...item, type: 'industry' });
          });
          res.data.researcher_ml_operations.map(item => {
            arr.push({ ...item, type: 'research' });
          });
          setRealtimeOptions(arr);
          setOptions(res.data);
        } else if (res.code === 401) {
          setIsOpen(true);
        }
      });
    }
  }, []);

  const addOptions = (name, value) => {
    if (value !== 0 && value.length > 0) {
      switch (name) {
        case 'industry':
          if (industry.length === 0) {
            const val = [value];
            setIndustry(val);
          } else {
            setIndustry([...industry, value]);
          }
          break;
        case 'market_research':
        case 'mastery':
          if (mastery.length === 0) {
            const val = [value];
            setMastery(val);
          } else {
            setMastery([...mastery, value]);
          }
          break;
        case 'minPrice':
          if (minPrice > 0) {
            const val = value;
            setMinPrice(val);
          }
          break;
        case 'maxPrice':
          if (maxPrice > 0) {
            const val = value;
            setMaxPrice(val);
          }
          break;
        case 'specializations':
        case 'analytics_research':
          if (specializations.length === 0) {
            const val = [value];
            setSpecializations(val);
          } else {
            setSpecializations([...specializations, value]);
          }
          break;
        case 'language':
          if (language.length === 0) {
            const val = [value];
            setLanguage(val);
          } else {
            setLanguage([...language, value]);
          }
          break;
        case 'responseTime':
          if (responseTime.length === 0) {
            const val = [value];
            setResponseTime(val);
          } else {
            setResponseTime([...responseTime, value]);
          }
          break;
        case 'ratings':
          if (rating.length === 0) {
            const val = [value];
            setRating(val);
          } else {
            setRating([...rating, value]);
          }
          break;
        case 'expertise':
          if (expertise.length === 0) {
            const val = [value];
            console.log('val: ', val);
            setExpertise(val);
          } else {
            setExpertise([...expertise, value]);
          }
          break;
        default:
          break;
      }
    } else {
      switch (name) {
        case 'industry':
          setIndustry([value]);
          break;
        case 'market_research':
        case 'mastery':
          setMastery([value]);
          break;
        case 'expertise':
          setExpertise([value]);
          break;
        case 'minPrice':
          setMinPrice(value);
          break;
        case 'maxPrice':
          setMaxPrice(value);
          break;
        case 'analytics_research':
        case 'specializations':
          setSpecializations([value]);
          break;
        case 'language':
          setLanguage([value]);
          break;
        case 'responseTime':
          setResponseTime([value]);
          break;
        case 'ratings':
          setRating([value]);
          break;
        default:
          break;
      }
    }
  };

  const removeOptions = (name, value) => {
    switch (name) {
      case 'industry': {
        const ind = industry.filter(item => item !== value);
        setIndustry(ind);
        break;
      }
      case 'market_research':
      case 'specializations':
      case 'analytics_research': {
        const specs = specializations.filter(item => item !== value);
        setSpecializations(specs);
        break;
      }
      case 'mastery': {
        const mast = mastery.filter(item => item !== value);
        setMastery(mast);
        break;
      }
      case 'expertise': {
        const mast = expertise.filter(item => item !== value);
        setExpertise(mast);
        break;
      }
      case 'language': {
        const lang = language.filter(item => item !== value);
        setLanguage(lang);
        break;
      }
      case 'responseTime': {
        const resp = responseTime.filter(item => item !== value);
        setResponseTime(resp);
        break;
      }
      case 'ratings': {
        const rate = rating.filter(item => item !== value);
        setRating(rate);
        break;
      }
      default: {
        break;
      }
    }
  };

  const clearFilter = filter => {
    switch (filter) {
      case 'language':
        setLanguage([]);
        break;
      case 'industry':
        setIndustry([]);
        break;
      case 'market_research':
      case 'mastery':
        setMastery([]);
        break;
      case 'expertise':
        setExpertise([]);
        break;
      case 'minPrice':
        setMinPrice(null);
        break;
      case 'maxPrice':
        setMaxPrice(null);
        break;
      case 'analytics_research':
      case 'specializations':
        setSpecializations([]);
        break;
      case 'responseTime':
        setResponseTime([]);
        break;
      case 'ratings':
        setRating([]);
        break;
      default:
        break;
    }
    if (page === 1) {
      handleSearch(search, filter);
    } else {
      setPage(1);
    }
  };

  return (
    <ResearcherListContainer className={className}>
      {loading ? <LoadingIndicator /> : null}
      <SearchbarLayout>
        <div style={{ flex: 3, position: 'relative' }}>
          <Searchbar
            name="generalSearch"
            leftLabel={<FaSearch />}
            placeholder="Search by researcher name, email, or keyword"
            focusColor="#ff9e08"
            value={search}
            onChange={text => {
              setFocus(true);
              // handleRealTimeSearch(text);
              setSearch(text);
            }}
            onKeyPress={event => {
              if (event === 'Enter') {
                if (page === 1) {
                  setFocus(false);
                  handleSearch(search, '');
                } else {
                  setPage(1);
                }
              }
            }}
          />
          {isFocus && realtimeSearch && realtimeSearch.length > 0 && (
            <Container
              style={{
                minHeight: '5rem',
                maxHeight: '26rem',
                background: '#fff',
                position: 'absolute',
                zIndex: '99',
                width: '94%',
                marginTop: 0,
              }}
            >
              {realtimeSearch.map((item, index) => {
                return (
                  <RealtimeContainer key={index}>
                    <div
                      style={{
                        color: '#4B4C5E',
                        fontSize: '1.3rem',
                        fontWeight: 'bold',
                        marginBottom: '1rem',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        setFocus(false);
                        setSearch('');

                        if (item.type === 'specialization') {
                          setRealtimeSpecialization([item.id]);
                          setRealtimeIndustry(null);
                          handleSearch('', '', null, item.id);
                        } else if (item.type === 'industry') {
                          setRealtimeIndustry([item.id]);
                          setRealtimeSpecialization(null);
                          handleSearch('', '', item.id, null);
                        }
                      }}
                    >
                      {item.name}
                    </div>
                  </RealtimeContainer>
                );
              })}
            </Container>
          )}
        </div>
        <Input
          name="location"
          value={location}
          onChange={e => {
            setLocation(e.target.value);
          }}
          leftLabel={<FaMapMarkerAlt />}
          placeholder="Search by location"
          focusColor="#ff9e08"
          onKeyPress={event => {
            if (event === 'Enter') {
              if (page === 1) {
                handleSearch(search, '');
              } else {
                setPage(1);
              }
            }
          }}
        />
      </SearchbarLayout>
      <ResearcherFilter
        key={
          realtimeIndustry
            ? realtimeIndustry
            : realtimeSpecialization
            ? realtimeSpecialization
            : null
        }
        hasSelectedSpecialization={
          selectedSpecialization
            ? selectedSpecialization
            : query && query.specialization
            ? query.specialization
            : realtimeSpecialization
        }
        hasSelectedIndustry={
          query && query.industry ? query.industry : realtimeIndustry
        }
        options={options}
        onChange={(name, value, checked) => {
          if (name === 'minPrice' && value !== 0) addOptions(name, value);
          else if (name === 'minPrice' && value === 0) clearFilter(minPrice);
          if (name === 'maxPrice' && value !== 0) addOptions(name, value);
          else if (name === 'maxPrice' && value === 0) clearFilter(maxPrice);

          if (name !== 'minPrice' || name !== 'maxPrice') {
            if (checked === true) {
              addOptions(name, value);
            } else {
              removeOptions(name, value);
            }
          }
        }}
        onApply={() => {
          if (page === 1) {
            handleSearch(search, '');
          } else {
            setPage(1);
          }
        }}
        onClear={cleared => {
          clearFilter(cleared);
        }}
      />
      <CardsLayout>
        {researchers.map(researcher =>
          researcher !== undefined && researcher.member !== undefined ? (
            <UserCard
              key={researcher.member.id}
              {...researcher.member}
              industryFilter={industry}
              specializationsFilter={specializations}
              industries={researcher.industry}
              specializations={researcher.business_specialization}
              services={researcher.services_offered}
              experience={researcher.fortune_experience}
            />
          ) : researcher.researcher_id !== undefined &&
            researcher.researcher_id !== '' ? (
            <ResearcherCard key={researcher.researcher_id} {...researcher} />
          ) : null
        )}
      </CardsLayout>
      <LoadMoreLayout>
        {researchers.length < total && (
          <LoadMoreButton onClick={() => setPage(page => page + 1)}>
            LOAD MORE
          </LoadMoreButton>
        )}
      </LoadMoreLayout>
      <SessionModal
        isOpen={isOpen}
        setModalVisible={setIsOpen}
        message={'Your session has expired.'}
      />
    </ResearcherListContainer>
  );
};

export default ResearcherList;
