import Content from 'components/Content';
import styled from 'styled-components/macro';
import { Row } from 'components/Layout';
import Button from 'components/Button';

export const PageContent = styled(Content)`
  margin: 4rem 0;
`;

export const PageTitle = styled.h1`
  font-size: 2.2rem;
  color: #4b4c5e;
  margin-top: 2.15rem;

  @media (max-width: 768px) {
    font-size: 2.5rem;
    margin-top: 2.5rem;
  }
`;

export const PageContainer = styled.div`
  margin-top: 1.5rem;

  padding: 4rem;
  background-color: white;

  @media (max-width: 768px) {
    margin-top: 2rem;
  }
`;

export const ChoosePlan = styled.div`
  font-family: 'europa', sans-serif;
  font-size: 14px;
  padding-top: 10px;
  // font-weight: bold;
  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

export const PageLabel = styled.div`
  letter-spacing: 0px;
  color: #65637b;
  width: 100%;
  display: flex;

  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

export const PlanContainer = styled(Row)`
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 4rem;
  margin-top: 4rem;
`;

export const PageButtonLayout = styled(Row)`
  margin: 4rem 0;
`;

export const SubmitButton = styled(Button)`
  @media (max-width: 768px) {
    margin: 1rem 0;
    padding-top: 1.75rem;
    padding-bottom: 1.75rem;
    font-size: 2rem;
    flex: 1;
  }
`;
