import Button from 'components/Button';
import { MEMBER_TYPES, useAuthState } from 'context/AuthContext';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { HashLink as Link } from 'react-router-hash-link';
import { logout, useAuthDispatch } from 'context/AuthContext';
import Modal from 'components/Modal';
import {
  WelcomeContent,
  WelcomeInstructions,
  WelcomeName,
  WelcomeOptionContainer,
  WelcomeOptionsLayout,
} from './styled';
import BrowsePlanModal from 'components/BrowsePlanModal';

const Welcome = () => {
  const { user, isMemberSubscribed } = useAuthState();
  const authDispatch = useAuthDispatch();
  const [showModal, setShowModal] = useState(false);
  const [isBrowseModal, setIsBrowseModal] = useState(false);
  const [modalMsg, setModalMsg] = useState();
  const initStep = user.member.first_name;
  useEffect(() => {
    if (
      localStorage.getItem('isResearcher') === 'true' &&
      localStorage.getItem('br-memberType') === '2'
    ) {
      setModalMsg(
        'A client with this email already exists. A different email is required to login as a researcher.'
      );
      setShowModal(true);
    } else if (
      localStorage.getItem('isResearcher') === 'false' &&
      localStorage.getItem('br-memberType') === '3'
    ) {
      setModalMsg(
        'A researcher with this email already exists. A different email is required to login as a member'
      );
      setShowModal(true);
    }
  }, []);

  const history = useHistory();

  return (
    <>
      {!showModal && (
        <WelcomeContent>
          <WelcomeName>
            Hi,{' '}
            {user?.member?.full_name
              ? user.member.full_name
              : user?.member?.first_name
              ? user.member.first_name + ' ' + user.member.last_name
              : localStorage.getItem('isResearcher') === 'false'
              ? 'Client'
              : 'Researcher'}
            !
          </WelcomeName>
          {user.member.member_type_id === +MEMBER_TYPES.RESEARCHER ? (
            <WelcomeInstructions>
              Welcome to Big Robin. As a researcher, you can start by creating a
              profile to showcase your portfolio, experience, and
              specialisation. Alternatively, you can start looking around for
              projects first.
            </WelcomeInstructions>
          ) : (
            <WelcomeInstructions>
              Welcome to Big Robin. As a client member you can start creating a
              project here or consult with researchers on a potential project.
            </WelcomeInstructions>
          )}

          {user.member.member_type_id === +MEMBER_TYPES.RESEARCHER ? (
            <WelcomeOptionsLayout>
              <WelcomeOptionContainer isResearcher={true}>
                <img src={require('./profile.jpeg')} alt="" />
                <Button
                  onClick={() => {
                    if (user.member.full_name) {
                      history.push(`/researcher/${user.member.id}`);
                    } else {
                      history.push(`/register`);
                    }
                  }}
                >
                  MY PROFILE
                </Button>
              </WelcomeOptionContainer>

              <WelcomeOptionContainer isResearcher={true}>
                <img src={require('./projectlist.png')} alt="" />
                <Button onClick={() => history.push('/projects')}>
                  LOOK FOR PROJECTS
                </Button>
              </WelcomeOptionContainer>

              <WelcomeOptionContainer isResearcher={true}>
                <img src={require('./payments.png')} alt="" />
                <Link to="/register#BankDetails">
                  <Button>COMPLETE MY PAYMENT DETAILS</Button>
                </Link>
              </WelcomeOptionContainer>
            </WelcomeOptionsLayout>
          ) : (
            <WelcomeOptionsLayout>
              <WelcomeOptionContainer isResearcher={false}>
                <img src={require('./profile.jpeg')} alt="" />
                <Button
                  onClick={() => {
                    history.push(`/register`);
                  }}
                >
                  CLIENT PROFILE
                </Button>
              </WelcomeOptionContainer>
              <WelcomeOptionContainer isResearcher={false}>
                <img src={require('./project.png')} alt="" />
                <Button
                  onClick={() => {
                    initStep === '' || !initStep
                      ? history.push('/register')
                      : isMemberSubscribed
                      ? history.push('/create-project')
                      : setIsBrowseModal(true);
                  }}
                >
                  CREATE A PROJECT
                </Button>
              </WelcomeOptionContainer>
              <WelcomeOptionContainer isResearcher={false}>
                <img src={require('./researchers.png')} alt="" />
                <Button onClick={() => history.push('/researchers')}>
                  FIND A RESEARCHER
                </Button>
              </WelcomeOptionContainer>
            </WelcomeOptionsLayout>
          )}
        </WelcomeContent>
      )}

      <Modal visible={showModal} setVisible={setShowModal} persistent={true}>
        <div
          style={{
            backgroundColor: 'white',
            padding: '2rem',
            flexDirection: 'column',
            justifyContent: 'flex-end',
            maxWidth: '350px',
          }}
        >
          <div style={{ marginBottom: '5rem' }}>{modalMsg}</div>

          <div>
            <Button
              fill
              style={{
                marginBottom: '1rem',
                backgroundColor: '#4b4c5e',
              }}
              onClick={() => {
                logout(authDispatch);
                history.push({
                  pathname: '/login',
                  state: { isSignup: false, isResearcher: true },
                });
              }}
            >
              I want to login as a Researcher
            </Button>
            <Button
              fill
              style={{
                marginBottom: '1rem',
                backgroundColor: '#ff9e08',
              }}
              onClick={() => {
                logout(authDispatch);
                history.push({
                  pathname: '/login',
                  state: { isSignup: false, isResearcher: false },
                });
              }}
            >
              I want to login as a Client
            </Button>
          </div>
        </div>
      </Modal>
      <BrowsePlanModal visible={isBrowseModal} setVisible={setIsBrowseModal} />
    </>
  );
};

export default Welcome;
