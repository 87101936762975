import { LabelLayout } from 'components/Input/styled';
import {
  addMonths,
  format,
  getDay,
  // isAfter,
  isBefore,
  isSameDay,
} from 'date-fns';
import { addDays, startOfMonth } from 'date-fns/esm';
import useClickOutside from 'hooks/useClickOutside';
import useNodeState from 'hooks/useNodeState';
import React, { useCallback, useLayoutEffect, useMemo, useState } from 'react';
import { FaCalendar, FaCaretLeft, FaCaretRight } from 'react-icons/fa';
import chunkify from 'utils/chunkify';
import {
  DatepickerContainer,
  DatepickerLayout,
  DatepickerValueLayout,
  DayContainer,
  MonthLayout,
  MonthWeekContainer,
  PickerContainer,
  WeekDayContainer,
  WeekLayout,
} from './styled';

const Datepicker = ({
  label,
  className,
  value,
  onChange,
  after,
  name,
  error,
  errorMessage,
  required,
}) => {
  const [isShowingPicker, setIsShowingPicker] = useState(false);
  const [pickerStyle, setPickerStyle] = useState({ top: 0, width: 0 });
  const [containerRefCB, containerEl, containerRef] = useNodeState();

  useClickOutside(
    containerRef,
    isShowingPicker,
    useCallback(() => {
      setIsShowingPicker(false);
    }, [])
  );

  useLayoutEffect(() => {
    if (isShowingPicker && containerEl) {
      const box = containerEl.getBoundingClientRect();
      setPickerStyle({ top: box.height });
    }
  }, [containerEl, isShowingPicker]);

  const [calendarDate, setCalendarDate] = useState(
    startOfMonth(value ? new Date(+value) : new Date())
  );

  const calendarWeeks = useMemo(() => {
    const calendarDates = [];

    const firstDay = getDay(calendarDate);

    for (let day = 0; day < firstDay; day++) {
      calendarDates.unshift(addDays(calendarDate, -day - 1));
    }

    let day = 0;
    while (calendarDates.length < 35) {
      calendarDates.push(addDays(calendarDate, day));
      day++;
    }

    return chunkify(calendarDates, 7);
  }, [calendarDate]);

  return (
    <DatepickerContainer ref={containerRefCB} className={className}>
      <input type="text" name={name} />
      {label && (
        <LabelLayout>
          <label>
            {label} {required && <sup style={{ color: 'red' }}>*</sup>}
          </label>
        </LabelLayout>
      )}

      <DatepickerLayout
        error={error}
        onClick={() => setIsShowingPicker(isShowingPicker => !isShowingPicker)}
      >
        <DatepickerValueLayout>
          {value && format(new Date(+value), 'MM/dd/yyyy')}
        </DatepickerValueLayout>
        <FaCalendar />
      </DatepickerLayout>
      {isShowingPicker && (
        <PickerContainer style={pickerStyle}>
          <MonthWeekContainer>
            <MonthLayout>
              <button
                onClick={() => setCalendarDate(addMonths(calendarDate, -1))}
              >
                <FaCaretLeft />
              </button>
              {format(calendarDate, 'MMMM yyyy')}
              <button
                onClick={() => setCalendarDate(addMonths(calendarDate, 1))}
              >
                <FaCaretRight />
              </button>
            </MonthLayout>
            <WeekLayout>
              {['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'].map(day => (
                <WeekDayContainer key={day}>{day}</WeekDayContainer>
              ))}
            </WeekLayout>
          </MonthWeekContainer>
          {calendarWeeks.map((week, weekIndex) => {
            return (
              <WeekLayout key={weekIndex}>
                {week.map(date => {
                  let disabled = false;
                  var past = new Date();
                  past.setDate(past.getDate() - 1);

                  if (isBefore(date, past) || isBefore(date, after)) {
                    disabled = true;
                  }

                  return (
                    <DayContainer
                      key={date.toString()}
                      selected={isSameDay(date, new Date(+value))}
                      disabled={disabled}
                      onClick={() => {
                        if (!disabled) {
                          onChange({
                            target: {
                              name,
                              value: +date,
                            },
                          });
                          setIsShowingPicker(false);
                        }
                      }}
                    >
                      {format(date, 'dd')}
                    </DayContainer>
                  );
                })}
              </WeekLayout>
            );
          })}
        </PickerContainer>
      )}
    </DatepickerContainer>
  );
};

export default Datepicker;
