import Breadcrumb from 'components/Breadcrumb';
import { Loading } from 'components/Loading';
// import { ServiceArchitecture } from 'components/ServiceArchitecture';
import React, { useEffect, useState } from 'react';
import {
  // FaFileAlt,
  // FaLink,
  // FaMapMarkerAlt,
  FaStar,
  // FaStarHalf,
} from 'react-icons/fa';
// import { MdBook, MdLink } from 'react-icons/md';
import { Redirect, useParams } from 'react-router-dom';
import { ajax } from 'utils/ajax';
import researchers from 'utils/researcher-data';
import * as SUserPage from './styled';
// import Button from 'components/Button';
// import { Row, Column } from 'components/Layout';

function ResearcherRating() {
  const [isGettingUser, setIsGettingUser] = useState(true);
  const [user, setUser] = useState(null);
  const params = useParams();
  // const history = useHistory();

  const pagedata = {
    pathlabel: "Filipa Dy's Rating",
    title: "Filipa's Rating",
    subtitle: 'PROJECT: Researcher for FDG Groups in Remote Areas',
    rating: 4.92,
    recommendation:
      'It has been a pleasure working with him. His creativity and professionalism has been instrumental in delivering inspired and insightful results for our project. All the best!',
  };

  useEffect(() => {
    (async () => {
      const url =
        '/messages/conversation/member/' +
        parseInt(localStorage.getItem('br-userId')) +
        '/researcher/' +
        params.id;
      const response = await ajax('GET', `/member/${params.id}`);
      if (response.code === 200) {
        const convo_response = await ajax('GET', url);
        if (convo_response.code === 200) {
          response.data['conversation_id'] = convo_response.data['id'];
        }
        setUser(response.data);
      } else {
        setUser(researchers[+params.id]);
      }
      setIsGettingUser(false);
    })();
  }, [params.id]);

  if (isGettingUser) return <Loading />;

  if (!user) return <Redirect to="/researchers" />;

  return (
    <SUserPage.PageContent>
      <Breadcrumb
        path={[
          { label: 'RESEARCHERS', to: '/researchers' },
          { label: user.member.full_name.toUpperCase() },
          { label: pagedata.pathlabel.toUpperCase() },
        ]}
      />
      <SUserPage.Title>{pagedata.title}</SUserPage.Title>
      <SUserPage.Container>
        <SUserPage.Subtitle> {pagedata.subtitle} </SUserPage.Subtitle>
        <SUserPage.RatingsLayout>
          <SUserPage.RatingsContainer>
            <SUserPage.RatingsTitle>
              Rating: {pagedata.rating}
              <SUserPage.RatingStarsLayout>
                <SUserPage.RatingStarsContainer>
                  <SUserPage.RatingStarsTitleContainer>
                    Quality
                  </SUserPage.RatingStarsTitleContainer>
                  <FaStar /> <FaStar /> <FaStar /> <FaStar /> <FaStar />
                </SUserPage.RatingStarsContainer>
                <SUserPage.RatingStarsContainer>
                  <SUserPage.RatingStarsTitleContainer>
                    Professionalism
                  </SUserPage.RatingStarsTitleContainer>
                  <FaStar /> <FaStar /> <FaStar /> <FaStar /> <FaStar />
                </SUserPage.RatingStarsContainer>
                <SUserPage.RatingStarsContainer>
                  <SUserPage.RatingStarsTitleContainer>
                    Expertise
                  </SUserPage.RatingStarsTitleContainer>
                  <FaStar /> <FaStar /> <FaStar /> <FaStar /> <FaStar />
                </SUserPage.RatingStarsContainer>
                <SUserPage.RatingStarsContainer>
                  <SUserPage.RatingStarsTitleContainer>
                    Timeliness
                  </SUserPage.RatingStarsTitleContainer>
                  <FaStar /> <FaStar /> <FaStar /> <FaStar />
                </SUserPage.RatingStarsContainer>
              </SUserPage.RatingStarsLayout>
            </SUserPage.RatingsTitle>
          </SUserPage.RatingsContainer>
          <SUserPage.RatingsContainer>
            <SUserPage.RatingsTitle>Recommendation</SUserPage.RatingsTitle>
            <SUserPage.RecomParagraph>
              {pagedata.recommendation}
            </SUserPage.RecomParagraph>
          </SUserPage.RatingsContainer>
        </SUserPage.RatingsLayout>
      </SUserPage.Container>
    </SUserPage.PageContent>
  );
}

export default ResearcherRating;
