const researchers = [
  {
    full_name: 'Merylyn Then',
    photo_link: require('../assets/researcher-images/f1.jpg'),
    area: 'Ecology Researcher',
    city: 'Adelaide, Australia',
    customer_ratings: 4.5,
    years_of_experience: 5,
    jobs_completed: null,
    related_projects: [
      {
        title: 'Occupancy and abundance estimation',
        description:
          'The use of remote cameras has grown exponentially over the past decade and they are now often used to quantify local vertebrate diversity, especially medium- to large-sized mammals. However, limitations in the ability to make quantitative inferences from the data continue to impede the field of applied conservation at great financial and logistical costs.',
        responsibility:
          'I aim to test current model performance within a simulated landscape and develop them to address species-level ecological confounders such space use and movement speed.',
      },
      {
        title:
          'Conservation of tropical biodiversity through carbon offset schemes ',
        description:
          'If local-scale decisions are made according to carbon offset schemes that solely prioritise carbon preservation, what does it mean for biodiversity? The overall aim of this research is to understand the fine local-scale trade-offs between mammal conservation and carbon preservation, and how it can be interpreted and applied to support the integration of biodiversity into carbon offset schemes.',
        responsibility:
          'Conduct necessarity tests with regards to the carbon correlation between mammal conservation and carbon preservation.',
      },
    ],
    experiences: [
      {
        client_name: 'University of Adelaide',
        startYear: 2017,
        endYear: 2019,
        position: 'Lead Researcher for Sciences',
        description:
          "Handles oversight for all science related research. Manages team and research timeline with regards to projected budget. Individually checks each projects' accuracy and fidelity.",
        tags: ['SALES', 'MARKETING'],
      },
      {
        client_name: 'University of Sydney',
        startYear: 2013,
        endYear: 2017,
        position: 'Faculty',
        description: 'Teaches science courses to university students.',
        tags: ['SALES', 'MARKETING'],
      },
    ],
    ratings: [
      {
        stars: 4,
        title: '',
        description:
          'Easy to work with and very timely when it comes to delivery. I sure hope we can work together again.',
      },
      {
        stars: 5,
        title: '',
        description:
          'Takes research projects in to heart. Ensures that outputs are delivered with utmost quality',
      },
    ],
    references: [],
  },
  {
    full_name: 'Alan Robert Mercado',
    photo_link: require('../assets/researcher-images/m1.jpg'),
    area: 'Health ',
    city: 'Manila, Philippines',
    customer_ratings: 5,
    years_of_experience: 19,
    jobs_completed: null,
    related_projects: [
      {
        title: 'Central Philippines Renal Study Technical Report',
        description:
          'Update the prevalence, incidence and health economic data relating to chronic kidney disease in the Central Philippine Region',
        responsibility:
          'Identify the current burden of chronic kidney disease (CKD) in the Central Philippine region including the level of comorbid conditions amongst CKD patients and assess whether rates of progression to end stage kidney disease (ESKD)',
      },
      {
        title: 'Systematic review of effective chronic kidney disease programs',
        description:
          'To provide a better understanding of how positive clinical outcomes can be obtained and what components of chronic kidney disease (CKD) management programs influence or enhance acceptability for patients and result in cost-effective care for Aboriginal and Islander people.',
        responsibility:
          'Gather collective evidence in relation to CKD management programs for indigenous people',
      },
      {
        title: 'Cultural Awareness Resources: Improving Communication (CARIC)',
        description:
          'The resources will help health students recognise that patients and health care professionals often have different perspectives, values and beliefs about health and illness and that this can lead to conflict, especially when communication is affected by language and cultural differences',
        responsibility:
          'Create multi-media educational resources to assist clinicians and health science students better understand the situations that lead to misunderstandings, miscommunication and a lack of shared understanding between aboriginal and health service providers',
      },
    ],
    experiences: [
      {
        client_name: 'University of the Philippines',
        startYear: 2000,
        endYear: 2019,
        position: 'Medical Researcher',
        description:
          'Focuses on medical research, mainly with regards to kidneys. Also conducts community management to community areas wherein sample areas are observed and studied',
        tags: ['SALES', 'MARKETING'],
      },
    ],
    ratings: [
      {
        stars: 5,
        title: '',
        description: 'Superb output',
      },
      {
        stars: 5,
        title: '',
        description:
          "One of the best researcher I've had the pleasure of working with",
      },
      {
        stars: 5,
        title: '',
        description:
          'His studies are the best when it comes to his field. I would love to see his other works',
      },
    ],
    references: [
      {
        image: '',
        type: 'Article',
        title:
          'The economic impact of end-stage kidney disease in the Philippines',
      },
      {
        image: '',
        type: 'Blog',
        title: 'My Musings in the Medical Field',
      },
    ],
  },
  {
    full_name: 'Luisa Joy Belmonte',
    photo_link: require('../assets/researcher-images/f2.jpg'),
    area: 'Sales and Marketing',
    city: 'Cebu, Philippines',
    customer_ratings: null,
    years_of_experience: 1,
    jobs_completed: null,
    related_projects: [
      {
        title: 'Marketing Trends for Personal Care Brands',
        description:
          'Analyze the biggest trends with regards to the different personal care brands often used by the Philippine household segment',
        responsibility: 'Conduct interviews and analyze inteview results',
      },
    ],
    experiences: [
      {
        client_name: 'Unilever',
        startYear: 2018,
        endYear: 2019,
        position: 'Marketing Assistant',
        description:
          'Coordinates in-between teams for the different marketing efforts. Suggests marketing campaigns to be conducted within the given year. Also prepares budget proposals for the marketing collaterals.',
        tags: ['SALES', 'MARKETING'],
      },
    ],
    ratings: [],
    references: [],
  },
  {
    full_name: 'Alessandro Bianchi',
    photo_link: require('../assets/researcher-images/m2.jpg'),
    area: 'Technology',
    city: 'Bergamo, Italy',
    customer_ratings: 5,
    years_of_experience: 25,
    jobs_completed: null,
    related_projects: [
      {
        title: 'Luxury Portable Consumer Electronics',
        description:
          'Despite the increasing digitalisation of consumers’ everyday lives and reliance on digital devices that simplify their lives and allow them to save time, they find non-luxury mobile phones meet their needs. Consumers want the latest technology offering the highest performance. This is a comprehensive guide to the size and shape of the Luxury Portable Consumer Electronics market at a national level.',
        responsibility:
          'Analyze the different trends with regards to the usage of portable consumer electronics and correlate it with the different market segments',
      },
      {
        title: 'Global 5G - Market and Technology Forecast',
        description:
          'The global 5G market would be dominated by three regions – North America, Western Europe and Asia Pacific. The Asia Pacific market is expected to account to more than 35% of the total market. The increase in demand for online content and sports delivery, growth in support infrastructure, rapidly increasing IoT connection, increase in internet penetration and cloud gaming are some of the key drivers which would drive the 5Gmarket.',
        responsibility:
          'Research industries, market, demographics, trends, sales results, and other related data',
      },
      {
        title: 'Mobile Payments Market Outlook and Forecasts',
        description:
          'This report evaluates leading mobile payment companies, strategies, and provides a market outlook with forecasts for 2020. This includes analysis by technology, payment city, device type, payment type, and industry vertical.',
        responsibility:
          'Data gathering and analysis, create interview questions',
      },
    ],
    experiences: [
      {
        client_name: 'Dell Technologies',
        startYear: 2015,
        endYear: 2018,
        position: 'Data Science Division Head',
        description:
          "Created new data sciences capabilities for the company by envisioning and executing strategies that will influence improvement of the business's performance by enabling informed decision making.",
        tags: ['SALES', 'MARKETING'],
      },
      {
        client_name: 'IBM',
        startYear: 2012,
        endYear: 2015,
        position: 'Data Engineer',
        description:
          'Design, build, integrate data from various resources, and manage big data by preparing the necessary infrastructure',
        tags: ['SALES', 'MARKETING'],
      },
      {
        client_name: 'Sony',
        startYear: 2006,
        endYear: 2012,
        position: 'Product Manager',
        description:
          'Manage several projects by setting its direction setting business strategies. ',
        tags: ['SALES', 'MARKETING'],
      },
    ],
    ratings: [
      {
        stars: 5,
        title: '',
        description: 'One of the best',
      },
      {
        stars: 5,
        title: '',
        description: 'Great work output. Very knowledgeable in his field',
      },
      {
        stars: 5,
        title: '',
        description: 'Very concise and accommodating',
      },
    ],
    references: [
      {
        image: '',
        type: 'Article',
        title: "What are the biggest tech impact in today's time?",
      },
      {
        image: '',
        type: 'Article',
        title: 'Data Science - A hype in the current economic setting',
      },
      {
        image: '',
        type: 'Article',
        title: 'Why you need to embrace cloud computing',
      },
    ],
  },
  {
    full_name: 'Miguel Felix Cruz',
    photo_link: require('../assets/researcher-images/m3.jpg'),
    area: 'Sales and Marketing',
    city: 'Hougang, Singapore',
    customer_ratings: 3,
    years_of_experience: 3,
    jobs_completed: null,
    related_projects: [
      {
        title: 'Chips and Dips Consumer Research Report',
        description:
          'Know the different market trends in the chips and dips consumers segment.',
        responsibility: 'Conduct interviews and analyze results',
      },
      {
        title: 'Marketing Trends for Personal Care Brands',
        description:
          'Analyze the biggest trends with regards to the different personal care brands often used by the Philippine household segment',
        responsibility: 'Conduct interviews and analyze inteview results',
      },
    ],
    experiences: [
      {
        client_name: "Jack 'n Jill",
        startYear: 2018,
        endYear: 2019,
        position: 'Marketing Lead',
        description:
          'Work with department heads or staff to discuss topics such as budgets and contracts, marketing plans, and the selection of advertising media. Plan promotional campaigns such as contests, coupons, or giveaways.',
        tags: ['SALES', 'MARKETING'],
      },
      {
        client_name: "Jack 'n Jill",
        startYear: 2016,
        endYear: 2018,
        position: 'Marketing Executive',
        description:
          "Planning, developing and implementing effective marketing communication campaigns. Using the full marketing mix for the company's marketing communications. Writing copy for all marketing collateral, including brochures, letters, emails and websites.",
        tags: ['SALES', 'MARKETING'],
      },
    ],
    ratings: [
      {
        stars: 2,
        title: '',
        description:
          "Delivery was subpar. It's not what was expected. I had to mico manage his deadlines.",
      },
      {
        stars: 4,
        title: '',
        description:
          'I really appreciated the effort to deliver the requirements. Keep up the good work',
      },
    ],
    references: [
      {
        image: '',
        type: 'Blog',
        title: 'My Science of Trending',
      },
    ],
  },
  {
    full_name: 'José Avalos',
    photo_link: require('../assets/researcher-images/m4.jpg'),
    area: 'Science, Engineering, Energy',
    city: 'Princeton, New Jersey',
    customer_ratings: 5,
    years_of_experience: 20,
    jobs_completed: null,
    related_projects: [
      {
        title: 'Protein Enzymes as Energy Source',
        description:
          'To achieve the levels of production of fuels and chemicals required for economic viability, it will be necessary to understand the effects that complex whole-cell biological systems have on their production, and take them into consideration when engineering strains.',
        responsibility:
          'Use various methods of detection, including biosensors, to run genome-wide screens and measure the effects of different perturbations, on the production of molecules of interest',
      },
      {
        title: 'Biosensor Usage in Designing Metabolically Engineered Strains',
        description:
          'Biosensors and genetic switches are invaluable tools that help monitor or control cellular functions. In general, biosensors consist of a receptor (of a molecule or condition related to the engineered function), linked to a reporter via various possible mechanisms. Biosensors can be used to either monitor, screen, or select for a desired function.',
        responsibility:
          'Oversight a team of researcher that will be handling the usage of biosensors and analyze the scientific results that were generated',
      },
      {
        title: 'Sustainable Energy throught he use of Biotechnology',
        description:
          'Synthetic biology and metabolic engineering are rapidly expanding fields of bioengineering that involve the development of biological pathways, systems, or organisms with synthetic biological behaviors (analogous to synthetic chemistry being the design of series of chemical reactions to produce synthetic molecules).',
        responsibility:
          'Integrate principles from microbiology, cellular biology, genetics, biochemistry, biophysics, and engineering, to the application and further development of technologies in synthetic biology and metabolic engineering.',
      },
    ],
    experiences: [
      {
        client_name: 'Adlinger Center',
        startYear: 2010,
        endYear: 2019,
        position: 'Head Researcher',
        description:
          'Identifying & developing potential research & enterprise income sources, promoting these opportunities to relevant colleagues, supporting staff in preparing grant and commercial applications, managing the application/tendering/contracting process and providing post-award support',
        tags: ['SALES', 'MARKETING'],
      },
      {
        client_name: 'Princeton University',
        startYear: 2008,
        endYear: 2019,
        position: 'Assistant Professor of Chemical and Biological Engineering',
        description:
          'Preparing lessons, lecturing, managing courses, and facilitating learning for students in the subject area.',
        tags: ['SALES', 'MARKETING'],
      },
      {
        client_name: 'Southampton Research Centre',
        startYear: 1999,
        endYear: 2008,
        position: 'Science Researcher',
        description:
          'Part of the department focused on conducting research for the field of science (mainly for biotechnology)',
        tags: ['SALES', 'MARKETING'],
      },
    ],
    ratings: [
      {
        stars: 5,
        title: '',
        description:
          'His research brings a lot of additional insights, something which you initially thought was impossible',
      },
      {
        stars: 5,
        title: '',
        description: 'His rates are well justified',
      },
      {
        stars: 5,
        title: '',
        description:
          'I highly recommend Jose for your team if you are conducting science related researches',
      },
    ],
    references: [
      {
        image: '',
        type: 'Article',
        title: 'Keeping the Promises of Bioprinting Afloat',
      },
      {
        image: '',
        type: 'Publication',
        title: 'Injectable Cryogels for Biomedical Applications',
      },
      {
        image: '',
        type: 'Publication',
        title: 'Molecularly Imprinted Polymers for Cell Recognition',
      },
    ],
  },
  {
    full_name: 'Geneva Carmona',
    photo_link: require('../assets/researcher-images/f3.jpg'),
    area: 'Energy',
    city: 'Pasig, Philippines',
    customer_ratings: 4,
    years_of_experience: 15,
    jobs_completed: null,
    related_projects: [
      {
        title: 'Philippines: The Potential of its Geothermal Market ',
        description:
          "Recent changes in the Philippines' energy sector, combined with the government’s ambitious clean energy targets and a variety of other factors, have created favorable conditions for investment in the country’s geothermal energy sector. This is a comprehensive study of its potential market",
        responsibility: null,
      },
    ],
    experiences: [
      {
        client_name: 'Energy Development Corporation',
        startYear: 2004,
        endYear: 2019,
        position: 'Geologist',
        description:
          'Planning programmes for exploration of sites steam. Surveying and mapping geologically promising sites.',
        tags: ['SALES', 'MARKETING'],
      },
    ],
    ratings: [
      {
        stars: 4,
        title: '',
        description:
          'A little bit late on delivery but the output is well constructed',
      },
    ],
    references: [
      {
        image: '',
        type: 'Publication',
        title: "Albay's Renewable Energy Source",
      },
      {
        image: '',
        type: 'Publication',
        title: 'Steam as Replacement for Coal',
      },
    ],
  },
  {
    full_name: 'Christina Louca',
    photo_link: require('../assets/researcher-images/f4.jpg'),
    area: 'Music & Arts',
    city: 'Alberta, Canada',
    customer_ratings: 2,
    years_of_experience: 4,
    jobs_completed: null,
    related_projects: [
      {
        title: 'Evolving Musical Taste',
        description:
          'A research on how our music taste continues to change as we get older to meet our social and psychological needs',
        responsibility:
          'Conduct face to face interviews with the 13 to 20 age group. Collate interview results and conduct follow-up questions',
      },
      {
        title: 'How Psychological Needs Impact Preference in Art',
        description:
          "Is there a correlation? This research aimed to find out if psychology has impact on a person's art preference",
        responsibility: 'Conduct face to face interviews and collate results',
      },
    ],
    experiences: [
      {
        client_name: 'Alerta School of Music',
        startYear: 2014,
        endYear: 2019,
        position: 'Music Teacher',
        description:
          'Teaches voice lessons and several instruments (mainly percussion)',
        tags: ['SALES', 'MARKETING'],
      },
      {
        client_name: 'Art Gallery of Alberta',
        startYear: 2004,
        endYear: 2015,
        position: 'Admin Assistant',
        description:
          'In charge of overseeing art exhibits and coordination with exhibitors with regards to gallery setup',
        tags: ['SALES', 'MARKETING'],
      },
    ],
    ratings: [
      {
        stars: 1,
        title: '',
        description:
          "Sorry but one of the worst research output I've seen. Violated a lot of research guidelines",
      },
      {
        stars: 3,
        title: '',
        description: 'Satisfactory',
      },
    ],
    references: [],
  },
  {
    full_name: 'Nathaniel Benedict dela Costa Jr',
    photo_link: require('../assets/researcher-images/m5.jpg'),
    area: 'Education, Psychology',
    city: 'Makati, Philippines',
    customer_ratings: 4.5,
    years_of_experience: 7,
    jobs_completed: null,
    related_projects: [
      {
        title: 'Top Motivational Drivers for Elementary Students',
        description:
          'Identification of the highest contributor in increasing student motivation for students within the elementary age group',
        responsibility:
          'Identify research structure and team composition, delecate necessary tasks and prioritization',
      },
      {
        title: 'Segmentation of School Performance',
        description: 'Top performing schools - What are the main contributors',
        responsibility:
          'Identify research structure and team composition, delecate necessary tasks and prioritization',
      },
      {
        title: 'School and Classroom Effectiveness and its Trends',
        description:
          'The relation between teaching, instruction and children’s learning arises whenever models of the teaching–learning process are discussed or whenever problems of learning occur. Despite massive research efforts we still know little about how differences in learning activities are related to students’ learning',
        responsibility:
          'Conduct interviews and coordinate with the research sample (students and teachers)',
      },
    ],
    experiences: [
      {
        client_name: 'Natural Sciences Research Institute ',
        startYear: 2015,
        endYear: 2019,
        position: 'Head Researcher',
        description:
          'Identifying & developing potential research & enterprise income sources, promoting these opportunities to relevant colleagues, supporting staff in preparing grant and commercial applications, managing the application/tendering/contracting process and providing post-award support ',
        tags: ['SALES', 'MARKETING'],
      },
      {
        client_name: 'Ateneo de Manila University',
        startYear: 2014,
        endYear: 2015,
        position: 'Assistant Professor of Psychology',
        description:
          'Preparing lessons, lecturing, managing courses, and facilitating learning for students in the subject area.',
        tags: ['SALES', 'MARKETING'],
      },
      {
        client_name: 'De La Salle University',
        startYear: 2012,
        endYear: 2013,
        position: 'Science Researcher',
        description:
          'Part of the department focused on conducting research for the field of science (mainly for biotechnology)',
        tags: ['SALES', 'MARKETING'],
      },
    ],
    ratings: [
      {
        stars: 5,
        title: '',
        description:
          'His research brings a lot of additional insights, something which you initially thought was impossible',
      },
      {
        stars: 5,
        title: '',
        description: 'His rates are well justified',
      },
      {
        stars: 4,
        title: '',
        description:
          'I highly recommend Nathan for your team if you are conducting psychology related researches',
      },
    ],
    references: [
      {
        image: '',
        type: 'Article',
        title: 'Reverse Psychology and its Application to Kids',
      },
      {
        image: '',
        type: 'Blog',
        title: 'My Life and its Psychological Workings',
      },
    ],
  },
  {
    full_name: 'Luciano Oliveros',
    photo_link: require('../assets/researcher-images/m6.jpg'),
    area: 'Sales and Marketing',
    city: 'Ortigas, Philippines',
    customer_ratings: 5,
    years_of_experience: 15,
    jobs_completed: null,
    related_projects: [
      {
        title: 'Chips and Dips Consumer Research Report',
        description:
          'Know the different market trends in the chips and dips consumers segment.',
        responsibility: 'Conduct interviews and analyze results',
      },
      {
        title: 'Marketing Trends for Personal Care Brands',
        description:
          'Analyze the biggest trends with regards to the different personal care brands often used by the Philippine household segment',
        responsibility: 'Conduct interviews and analyze inteview results',
      },
      {
        title: 'Mobile Payments Market Outlook and Forecasts',
        description:
          'This report evaluates leading mobile payment companies, strategies, and provides a market outlook with forecasts for 2020. This includes analysis by technology, payment city, device type, payment type, and industry vertical.',
        responsibility:
          'Data gathering and analysis, create interview questions',
      },
    ],
    experiences: [
      {
        client_name: 'Unilever',
        startYear: 2018,
        endYear: 2019,
        position: 'Marketing Head',
        description:
          'Coordinates in-between teams for the different marketing efforts. Suggests marketing campaigns to be conducted within the given year. Also prepares budget proposals for the marketing collaterals.',
        tags: ['SALES', 'MARKETING'],
      },
      {
        client_name: "Jack 'n Jill",
        startYear: 2010,
        endYear: 2018,
        position: 'Marketing Executive Planning',
        description:
          "Developing and implementing effective marketing communication campaigns. Using the full marketing mix for the company's marketing communications. Writing copy for all marketing collateral, including brochures, letters, emails and websites.",
        tags: ['SALES', 'MARKETING'],
      },
    ],
    ratings: [
      {
        stars: 5,
        title: '',
        description: 'Superb output',
      },
      {
        stars: 5,
        title:
          "One of the best researchers I've had the pleasure of working with",
        description: '',
      },
      {
        stars: 5,
        title: '',
        description:
          'His studies are the best when it comes to his field. I would love to see his other works',
      },
    ],
    references: [],
  },
  {
    full_name: 'Jane Mariella Lastimoso',
    photo_link: require('../assets/researcher-images/f5.jpg'),
    area: 'Technology',
    city: 'Lisbon, Portugal',
    customer_ratings: 5,
    years_of_experience: 25,
    jobs_completed: null,
    related_projects: [
      {
        title: 'Global 5G - Market and Technology Forecast',
        description:
          'The global 5G market would be dominated by three regions – North America, Western Europe and Asia Pacific. The Asia Pacific market is expected to account to more than 35% of the total market. The increase in demand for online content and sports delivery, growth in support infrastructure, rapidly increasing IoT connection, increase in internet penetration and cloud gaming are some of the key drivers which would drive the 5Gmarket.',
        responsibility:
          'Research industries, market, demographics, trends, sales results, and other related data',
      },
      {
        title: 'Mobile Payments Market Outlook and Forecasts',
        description:
          'This report evaluates leading mobile payment companies, strategies, and provides a market outlook with forecasts for 2020. This includes analysis by technology, payment city, device type, payment type, and industry vertical.',
        responsibility:
          'Data gathering and analysis, create interview questions',
      },
    ],
    experiences: [
      {
        client_name: 'Dell Technologies',
        startYear: 2015,
        endYear: 2018,
        position: 'Data Science Division Head',
        description:
          "Created new data sciences capabilities for the company by envisioning and executing strategies that will influence improvement of the business's performance by enabling informed decision making.",
        tags: ['SALES', 'MARKETING'],
      },
      {
        client_name: 'IBM',
        startYear: 2012,
        endYear: 2015,
        position: 'Data Engineer',
        description:
          'Design, build, integrate data from various resources, and manage big data by preparing the necessary infrastructure',
        tags: ['SALES', 'MARKETING'],
      },
      {
        client_name: 'Sony',
        startYear: 2006,
        endYear: 2012,
        position: 'Product Manager',
        description:
          'Manage several projects by setting its direction setting business strategies. ',
        tags: ['SALES', 'MARKETING'],
      },
    ],
    ratings: [
      {
        stars: 5,
        title: '',
        description: 'One of the best',
      },
      {
        stars: 5,
        title: '',
        description: 'Great work output. Very knowledgeable in his field',
      },
      {
        stars: 5,
        title: '',
        description: 'Very concise and accommodating',
      },
    ],
    references: [
      {
        image: '',
        type: 'Article',
        title: "What are the biggest tech impact in today's time?",
      },
      {
        image: '',
        type: 'Article',
        title: 'Data Science - A hype in the current economic setting',
      },
      {
        image: '',
        type: 'Article',
        title: 'Why you need to embrace cloud computing',
      },
    ],
  },
  {
    full_name: 'Luisito Benjamin',
    photo_link: require('../assets/researcher-images/m7.jpg'),
    area: 'Health',
    city: 'Manila, Philippines',
    customer_ratings: 4.5,
    years_of_experience: 5,
    jobs_completed: null,
    related_projects: [
      {
        title:
          'Conservation of tropical biodiversity through carbon offset schemes ',
        description:
          'If local-scale decisions are made according to carbon offset schemes that solely prioritise carbon preservation, what does it mean for biodiversity? The overall aim of this research is to understand the fine local-scale trade-offs between mammal conservation and carbon preservation, and how it can be interpreted and applied to support the integration of biodiversity into carbon offset schemes.',
        responsibility:
          'Conduct necessarity tests with regards to the carbon correlation between mammal conservation and carbon preservation',
      },
      {
        title: 'Occupancy and abundance estimation ',
        description:
          'The use of remote cameras has grown exponentially over the past decade and they are now often used to quantify local vertebrate diversity, especially medium- to large-sized mammals. However, limitations in the ability to make quantitative inferences from the data continue to impede the field of applied conservation at great financial and logistical costs.',
        responsibility:
          'I aim to test current model performance within a simulated landscape and develop them to address species-level ecological confounders such space use and movement speed.',
      },
    ],
    experiences: [
      {
        client_name: 'University of Adelaide',
        startYear: 2017,
        endYear: 2019,
        position: 'Lead Researcher for Sciences',
        description:
          "Handles oversight for all science related research. Manages team and research timeline with regards to projected budget. Individually checks each projects' accuracy and fidelity.",
        tags: ['SALES', 'MARKETING'],
      },
      {
        client_name: 'University of Sydney',
        startYear: 2013,
        endYear: 2017,
        position: 'Faculty',
        description: 'Teaches science courses to university students',
        tags: ['SALES', 'MARKETING'],
      },
    ],
    ratings: [
      {
        stars: 4,
        title: '',
        description:
          'Easy to work with and very timely when it comes to delivery. I sure hope we can work together again.',
      },
      {
        stars: 5,
        title: '',
        description:
          'Takes research projects in to heart. Ensures that outputs are delivered with utmost quality',
      },
    ],
    references: [],
  },
];

let id = 0;
for (const researcher of researchers) {
  researcher.id = id++;
  for (let i = 0; i < researcher.references.length; i++) {
    researcher.references[
      i
    ].image = require(`../assets/reference-images/reference${i + 1}.png`);
  }
}

export default researchers;
