import { useLayoutEffect } from 'react';

function useBodyScrollLock(visible) {
  useLayoutEffect(() => {
    // Get original body overflow
    const originalStyle = window.getComputedStyle(document.body).overflow;
    if (visible) {
      // Prevent scrolling on mount
      document.body.style.overflow = 'hidden';
      // Re-enable scrolling when component unmounts
    }
    return () => (document.body.style.overflow = originalStyle);
  }, [visible]); // Empty array ensures effect is only run on mount and unmount
}

export default useBodyScrollLock;
