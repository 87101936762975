import Content from 'components/Content';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';
import Button from 'components/Button';

export const PageContent = styled(Content)`
  margin: 4rem 0;
`;

export const Container = styled.div`
  margin-top: 1.5rem;

  padding: 4rem 2rem;
  background-color: white;
  display: flex;

  @media (max-width: 768px) {
    display: block;
    padding: 4rem;
  }
`;

export const SummaryLayout = styled.div`
  flex: 1;
  padding-right: 2rem;
  display: flex;
  flex-direction: column;
  color: #4b4c5e;
  align-self: flex-start;

  @media (max-width: 768px) {
    padding-right: 0rem;
  }
`;

export const Avatar = styled.img`
  border-radius: 50%;
  border: 0.75rem solid #ff9e083c;
  align-self: center;
  height: 14rem;
  width: 14rem;
  margin-bottom: 4rem;
`;

export const Name = styled.h2`
  font-size: 2.2rem;
  margin-bottom: 1rem;

  @media (max-width: 768px) {
    text-align: center;
    font-size: 3.3rem;
  }
`;

export const Title = styled.p`
  font-size: 1.8rem;
  margin-bottom: 1.5rem;

  @media (max-width: 768px) {
    text-align: center;
    font-size: 2.7rem;
  }
`;

export const Location = styled.div`
  display: flex;
  align-items: center;

  font-size: 1.6rem;
  // margin-bottom: 4rem;
  opacity: 0.75;
  svg {
    margin-right: 0.5rem;
  }

  @media (max-width: 768px) {
    justify-content: center;
    font-size: 2.4rem;
    margin-bottom: 5rem;
  }
`;

export const MetricsLayout = styled.div`
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 4rem;

  @media (max-width: 768px) {
    margin-bottom: 5rem;
  }
`;

export const Metrics = styled.div`
  flex: 1;
  text-align: center;
  margin-right: 1rem;
  &:last-child {
    margin-right: 0;
  }
  h3 {
    font-weight: regular;
    font-size: 2.8rem;
    margin-bottom: 1rem;

    @media (max-width: 768px) {
      font-size: 4.2rem;
    }
    span {
      font-size: 2rem;
    }
  }
  h6 {
    font-size: 0.9rem;
    opacity: 0.75;

    @media (max-width: 768px) {
      font-size: 1.8rem;
    }
  }
`;

export const AchievementsLayout = styled.div`
  display: flex;
  justify-content: space-evenly;
`;

export const Achievement = styled.div`
  flex: 1;
  color: #ff9e08;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  p {
    font-weight: bold;
    font-size: 0.8rem;
  }

  margin-right: 1rem;
  &:last-child {
    margin-right: 0;
  }
`;

export const AchievementIconContainer = styled.div`
  padding: 1rem;
  border-radius: 50%;
  border: 0.2rem solid #ff9e08;
  margin-bottom: 0.5rem;
  svg {
    font-size: 1.8rem;
  }
`;

export const InfoLayout = styled.div`
  flex: 3;
  padding-left: 4rem;
  padding-right: 2rem;
  border-left: 1px solid rgba(101, 99, 123, 0.2);

  @media (max-width: 1000px) {
    flex: 2;
  }

  @media (max-width: 768px) {
    border: none;
    padding-left: 0rem;
    padding-right: 0rem;
  }
`;

export const Label = styled.h2`
  display: flex;
  align-items: center;
  color: #4b4c5e;
  font-size: 1.6rem;
  margin-bottom: 4rem;

  svg {
    margin-right: 1.5rem;

    @media (max-width: 768px) {
      font-size: 2.5rem;
    }
  }

  @media (max-width: 768px) {
    font-size: 2.5rem;
  }
`;

export const Project = styled.div`
  margin-bottom: 6.5rem;
`;

export const ProjectTitle = styled.h5`
  color: #ff9e08;
  font-size: 1.4rem;
  margin-bottom: 1rem;

  @media (max-width: 768px) {
    font-size: 2.4rem;
  }
`;

export const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2rem 0rem;
  width: 50%;
  @media (max-width: 900px) {
    margin: 2rem 0rem;
    width: 100%;
  }
`;

export const ProjectImage = styled.img`
  height: 15rem;
  z-index: 1;
  width: 16rem;

  @media (max-width: 900px) {
    // height: auto;
  }
`;

export const ProjectDescription = styled.p`
  color: #65637b;
  font-size: 1.8rem;
  margin-bottom: 2rem;
  width: 50%;
  @media (max-width: 768px) {
    font-size: 2.4rem;
    width: 100%;
  }
`;
export const ProjectResponsibilityLabel = styled.h6`
  color: #4b4c5e;
  font-size: 1.3rem;
  margin-bottom: 1rem;

  @media (max-width: 768px) {
    font-size: 1.8rem;
  }
`;

export const ProjectResponsbility = styled.p`
  color: #65637b;
  font-size: 1.4rem;

  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

export const ExperienceLayout = styled.div`
  margin-bottom: 6.5rem;
`;

export const ExperienceCompanyLayout = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.8rem;
`;

export const ExperienceCompany = styled.span`
  font-weight: bold;
  font-size: 1.8rem;
  color: #4b4c5e;

  @media (max-width: 768px) {
    font-size: 2.4rem;
  }
`;

export const ExperienceYear = styled.span`
  font-size: 1.6rem;
  color: #4b4c5e;

  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

export const ExperiencePosition = styled.h6`
  font-size: 1.6rem;
  color: #4b4c5e;
  margin-bottom: 2.6rem;

  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

export const ExperienceDescription = styled.p`
  color: #65637b;
  font-size: 1.6rem;
  margin-bottom: 1.4rem;

  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

export const ExperienceTagsLayout = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const ExperienceTag = styled.div`
  color: #65637b;
  background-color: #dadadf;
  margin-right: 1.5rem;
  font-weight: bold;
  font-size: 1rem;
  padding: 0.5rem 1rem;

  @media (max-width: 768px) {
    font-size: 1.5rem;
  }

  &:last-child {
    margin-right: 0;
  }
`;

export const SeeMoreLink = styled(Link)`
  color: #41804c;
  font-size: 1.2rem;
  white-space: nowrap;

  @media (max-width: 768px) {
    font-size: 1.6rem;
  }
`;

export const RatingsContainer = styled.div`
  margin-bottom: 6rem;
`;

export const RatingsLayout = styled.div`
  display: flex;
  margin-bottom: 2rem;
`;

export const Rating = styled.div`
  flex: 1;
  border: 1px solid #dadadf;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;

  margin-right: 2.5rem;
  &:last-child {
    margin-right: 0;
  }
`;

export const RatingInfoLayout = styled.div`
  flex: 1;
`;

export const RatingStarsLayout = styled.div`
  display: flex;
  min-height: 1.5rem;

  margin-bottom: 1rem;

  svg {
    color: #f7d33c;
    height: 1.5rem;
    width: 1.5rem;
  }
`;

export const RatingTitle = styled.h6`
  font-size: 1.4rem;
  color: #4b4c5e;
  margin-bottom: 2rem;
`;

export const RatingDescription = styled.p`
  font-size: 1.3rem;
  color: #65637b;
  margin-bottom: 2rem;
`;

export const RatingSeeMoreLayout = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const BlogsLayout = styled.div`
  display: flex;

  @media (max-width: 768px) {
    display: block;
  }
`;

export const Blog = styled.div`
  cursor: pointer;
  flex: 1;
  border: 1px solid #dadadf;
  margin-right: 2.5rem;
  &:last-child {
    margin-right: 0;
  }

  @media (max-width: 768px) {
    margin: 2.5rem 0rem;
  }

  img {
    width: 100%;
    height: 20rem;
    object-fit: cover;
  }
`;

export const BlogInfoLayout = styled.div`
  padding: 1.5rem;
  p {
    font-size: 1rem;
    color: #4b4c5e;

    @media (max-width: 768px) {
      font-size: 1.5rem;
    }
  }

  h6 {
    font-size: 1.4rem;

    @media (max-width: 768px) {
      font-size: 2.1rem;
    }
  }
`;

export const ButtonsLayout = styled.div`
  display: block;

  @media (max-width: 768px) {
    display: flex;
    justify-content: space-between;
  }

  ${Button} {
    margin-bottom: 1rem;
    width: 100%;
    justify-content: center;

    @media (max-width: 768px) {
      padding-top: 1.75rem;
      padding-bottom: 1.75rem;
      font-size: 1.75rem;
      margin-bottom: 4rem;
      margin-right: 2rem;
      &:last-child {
        margin-right: 0;
      }
    }
  }
`;
