import dayjs from 'dayjs';
import React from 'react';
import {
  AgreementContent,
  AgreementLayout,
  AgreementTitle,
  AgreementSubTitle,
  AgreementTextBlock,
  AgreementBlockTitle,
} from './styled';

export const PrivacyPolicy = () => {
  return (
    <>
      <AgreementContent>
        <AgreementLayout>
          <AgreementTitle>Big Robin Privacy Policy</AgreementTitle>
          <section>
            <AgreementTextBlock>
              Effective Date: {dayjs(new Date()).format('MMMM DD, YYYY')}
            </AgreementTextBlock>
            <AgreementTextBlock>
              Thank you for visiting the website (the “Site”) of Big Robin
              Holdings Inc, Big Robin USA LLC (“Big Robin”), contacting Big
              Robin, and/or using any Big Robin mobile applications or services
              (the “Services”). This Privacy Policy is intended to describe how
              Big Robin handles information that you provide, or that we learn
              about the individuals who: visit or use our website, contact us by
              mail, email or telephone or in person, or who provide us with
              information through any other means. We recommend that you
              carefully review this notice before providing us with any
              information. By accessing and using this Site, you agree to this
              Privacy Policy and our Terms of Use.
            </AgreementTextBlock>
            <AgreementTextBlock>
              BY VISITING OR USING THE SERVICES, YOU EXPRESSLY CONSENT TO THE
              PROCESSING OF YOUR PERSONAL INFORMATION ACCORDING TO THIS PRIVACY
              POLICY. IF YOU DO NOT AGREE WITH OUR POLICIES AND PRACTICES, YOUR
              CHOICE IS TO NOT USE THE SERVICES. YOUR PERSONAL INFORMATION MAY
              BE PROCESSED IN THE COUNTRY WHERE IT WAS COLLECTED AS WELL AS
              OTHER COUNTRIES (INCLUDING THE UNITED STATES) WHERE LAWS REGARDING
              PROCESSING OF PERSONAL INFORMATION MAY BE LESS STRINGENT THAN THE
              LAWS IN YOUR COUNTRY. BY UPLOADING PERSONAL INFORMATION TO THE
              SERVICES YOU WARRANT THAT YOU HAVE THE RIGHT TO TRANSFER SUCH
              INFORMATION OUTSIDE YOUR COUNTRY AND INTO THE UNITED STATES.
            </AgreementTextBlock>
          </section>
          <section>
            <AgreementSubTitle>Children’s Privacy</AgreementSubTitle>
            <AgreementTextBlock>
              The Big Robin website and Services are not intended for children
              under the age of 18 and Big Robin does not knowingly collect any
              information from children under 18 years old through its website.
              If the parent or guardian of a child under 18 believes that the
              child has provided us with any information, the parent or guardian
              of that child should contact us if they want this information
              deleted from our files. If Big Robin obtains knowledge from any
              source that it has information about a child under 18 in
              retrievable form in its files, we will delete that information
              from our existing files.
            </AgreementTextBlock>
          </section>
          <section>
            <AgreementSubTitle>
              What Information does Big Robin Collect and How is it Used?
            </AgreementSubTitle>
            <section>
              <AgreementBlockTitle>
                <em>Personal Information</em>
              </AgreementBlockTitle>
              <br />
              <AgreementTextBlock>
                Big Robin will only collect Personal Information that you
                voluntarily provide to us or our service providers. The Personal
                Information we may collect includes, among other things, your
                name, mailing address, e-mail address, telephone number, and
                your interest in specific types of products and/or services.
              </AgreementTextBlock>
            </section>
            <section>
              <AgreementBlockTitle>
                <em>Project Information</em>
              </AgreementBlockTitle>
              <br />
              <AgreementTextBlock>
                When you use the Services to connect with another Big Robin user
                or to facilitate execution of an agreement between yourself and
                another Big Robin user (“Service Contract”) you may share
                information with that Big Robin user. Any information you
                provide to another Big Robin user may be used by that user as if
                you provided them with the information directly in person or
                through any other setting. You may only use the Services to
                provide information, including Project Information, that you
                have the full right and authority to upload to the Services in
                accordance with this Policy and the Terms of Use. We recommend
                you execute a Non-Disclosure Agreement before providing any Big
                Robin user with any information that may be personal, sensitive,
                confidential or otherwise valuable. Big Robin is not a party to
                any Non-Disclosure Agreement or other Service Contract you
                execute with another Big Robin user and shall not be a party in
                any dispute related to any Service Contract, including
                Non-Disclosure Agreements.
              </AgreementTextBlock>
              <AgreementTextBlock>
                If you receive Project Information from another Big Robin user,
                you are strictly prohibited from using or disclosing that
                information for any reason other than to fulfill your
                obligations in any Service Contract.
              </AgreementTextBlock>
            </section>
            <section>
              <AgreementBlockTitle>
                <em>Purchase Information</em>
              </AgreementBlockTitle>
              <br />
              <AgreementTextBlock>
                Big Robin uses Stripe, a secure payment provider, to process
                your payments if you use the Services to make a purchase. Your
                payment information is subject to the privacy policy and terms
                of use of Stripe. We recommend you review these policies before
                uploading any credit card information. Big Robin does not have
                access to your credit card information and does not store this
                information on our servers.
              </AgreementTextBlock>
            </section>
            <section>
              <AgreementBlockTitle>
                <em>Profile Information and Public Posting</em>
              </AgreementBlockTitle>
              <br />
              <AgreementTextBlock>
                If you join the Services as a Researcher, you may provide us
                with certain information about your professional credentials,
                including education, work experience, project information,
                articles or projects or any other information you choose to
                provide. You may also choose to post or upload additional
                information, such as videos, photos or other content (“User
                Content”) to publicly available portions of the Services. User
                Content you post on a public forum or make publicly available
                will be available to other users of the Website and may be
                retrievable by third party search engines. Third parties may
                also be able to download or share your User Content to social
                media sites such as Facebook, Pinterest, Twitter, Google+ as
                well as via email. We recommend that you guard your privacy and
                anonymity and not upload any User Content that you are not
                comfortable being available to the general public. Any third
                party with access to your information via the Services will be
                permitted to use the information in the same manner as if you
                submitted the information directly to that third party. Publicly
                posting any information on the Website is entirely voluntary on
                your part and we recommend you carefully consider the
                information you choose to make publicly available.
              </AgreementTextBlock>
            </section>
            <section>
              <AgreementBlockTitle>
                <em>Non-Personal Information</em>
              </AgreementBlockTitle>
              <br />
              <AgreementTextBlock>
                Like most website operators, Big Robin collects
                non-personally-identifying information of the sort that web
                browsers and servers typically make available, such as the
                browser type, language preference, referring site, and the date
                and time of each visitor request when visitors use the Services.
                Big Robin’s purpose in collecting non-personally identifying
                information is to better understand how Big Robin’s visitors use
                the Services. From time to time, Big Robin may release
                non-personally-identifying information in the aggregate, e.g.,
                by publishing a report on trends in the usage of its Services.
                Big Robin also collects potentially personally-identifying
                information like Internet Protocol (IP) addresses and may
                collect statistics about the behavior of visitors to the
                Services. For instance, Big Robin may monitor the Services to
                help identify spam. We also may use these technologies to
                collect information about your online activities over time and
                across third-party websites or other online services (behavioral
                tracking).
              </AgreementTextBlock>
              <AgreementTextBlock>
                In addition to log data, we may also collect information about
                the device you’re using to access the Services, including the
                type of device, the operating system, device settings, device
                identifiers and crash data. Whether we collect some or all of
                this information often depends on what type of device you’re
                using and its settings. To learn more about the information your
                device makes available to us, please review the policies of your
                device manufacturer or software provider.
              </AgreementTextBlock>
              <AgreementTextBlock>
                We do not collect personal information automatically, but we may
                tie this information to personal information about you that we
                collect from other sources or you provide to us.
              </AgreementTextBlock>
              <AgreementTextBlock>
                In addition, we may use third party services such as Google
                Analytics that collect, monitor and analyze this type of
                information in order to increase the Services’ functionality.
                These third-party service providers have their own privacy
                policies addressing how they use such information.
              </AgreementTextBlock>
            </section>
          </section>
          <section>
            <AgreementSubTitle>How we use your information</AgreementSubTitle>
            <AgreementTextBlock>
              We use the information we collect to provide you full access and
              functionality of the Services. Accordingly, your information may
              be used for the following purposes: (i) to provide and improve our
              services, features and content; (ii) to administer your use of our
              services and accounts; (iii) to enable users to enjoy and easily
              navigate the Services; (iv) to better understand your needs and
              interests; (v) to fulfill requests or to respond to questions or
              comments you may make; (vi) to personalize your experience; (vii)
              to provide you with announcements, notifications and
              advertisements related to your interests and use of the Services
              and other communications such as electronic newsletters,
              promotional e-mails or similar messaging; (viii) to provide
              service announcements; (ix) to protect against users seeking to
              hack into the Services; (x) to assess the level of general
              interest in the Services and (xi) for any other purpose with your
              consent.may not be able to opt-out from receiving.
            </AgreementTextBlock>
          </section>
          <section>
            <AgreementSubTitle>
              With Whom Do We Share Your Information?
            </AgreementSubTitle>
            <section>
              <AgreementBlockTitle>
                <em>Personal Information</em>
              </AgreementBlockTitle>
              <br />
              <AgreementTextBlock>
                Big Robin may share Personal Information, with certain third
                parties such as our agents, service providers and other
                representatives acting on our behalf. The third parties with
                whom we conduct business are authorized to use your information
                only to perform the service for which they are hired. They are
                required to abide by the terms of our Privacy Policy including
                taking reasonable measures to ensure your personal information
                is secure. On occasion, we contract with trusted third-party
                providers who would receive your Personal Information and
                conduct anonymized aggregate analyses of the data. Through our
                contractual arrangements, we require our contracting partners to
                maintain adequate security of Personal Information provided to
                them. We do not permit such third parties to sell your Personal
                Information to other third parties.
              </AgreementTextBlock>
            </section>
            <section>
              <AgreementBlockTitle>
                <em>Other Ways We May Share your Personal Information</em>
              </AgreementBlockTitle>
              <br />
              <AgreementTextBlock>
                We may disclose non-personally identifiable aggregated
                information about our users without restriction.
              </AgreementTextBlock>
              <AgreementTextBlock>
                Other than to its employees, contractors and affiliated
                organizations or as described above, we disclose
                personally-identifying only when required to do so by law, or
                when we believe in good faith that disclosure is reasonably
                necessary to protect the property or rights of Big Robin, third
                parties or the public at large. If you send us a request (for
                example via chat, a support email or via one of our feedback
                mechanisms), we reserve the right to publish it in order to help
                us clarify or respond to your request or to help us support
                other users.
              </AgreementTextBlock>
              <AgreementTextBlock>
                In addition, in some cases we may choose to buy or sell assets.
                In these types of transactions, user information is typically
                one of the business assets that is transferred. Moreover, if Big
                Robin or substantially all of its assets were acquired, or in
                the unlikely event that Big Robin goes out of business or enters
                bankruptcy, user information would be one of the assets that is
                transferred or acquired by a third party. You acknowledge that
                such transfers may occur, and that any acquirer of Big Robin may
                continue to use your personal and non-personal information only
                as set forth in this policy. Otherwise, we will not rent or sell
                potentially personally-identifying and personally-identifying
                information to anyone.
              </AgreementTextBlock>
              <AgreementTextBlock>
                We may also disclose your personally-identifying information if
                you expressly consent to the disclosure.
              </AgreementTextBlock>
            </section>
          </section>
          <section>
            <AgreementSubTitle>Security</AgreementSubTitle>
            <AgreementTextBlock>
              We are committed to protecting your Personal Information. We
              regularly test our facilities and use a variety of security
              technologies and procedures to help protect your personal
              information from unauthorized access, use or disclosure.
            </AgreementTextBlock>
          </section>
          <section>
            <AgreementSubTitle>
              We Do Not Sell Your Personal Information
            </AgreementSubTitle>
            <AgreementTextBlock>
              Big Robin does not, to the best of our knowledge, sell or rent
              personal information that we have collected or retain about you to
              any other third-party for any purpose. Accordingly, we do not
              offer individuals the ability to “opt-out” of the selling or
              renting of personal information because we do not engage in those
              practices.
            </AgreementTextBlock>
          </section>
          <section>
            <AgreementSubTitle>Our Data Retention Criteria</AgreementSubTitle>
            <AgreementTextBlock>
              The period during which we store your personal information varies
              depending on the purpose for the processing. For example, we store
              personal information needed to provide you with products and
              services, or to facilitate transactions you have requested, for so
              long as you are a customer of Big Robin. We store your personal
              information for marketing purposes until you have opted-out of
              receiving further direct marketing communications in accordance
              with applicable law. In all other cases, we store your personal
              information for as long as is needed to fulfill the purposes
              outlined in this Privacy Policy, following which time it is either
              anonymized (where permitted by applicable law), deleted or
              destroyed.
            </AgreementTextBlock>
          </section>
          <section>
            <AgreementSubTitle>
              Our Use of Cookies and Analytical Tools
            </AgreementSubTitle>
            <AgreementTextBlock>
              Cookies are text files containing small amounts of information
              which are downloaded to your hard disk or to your browser's memory
              when you visit one of our Sites. Cookies are useful because they
              help arrange the content and layout of our Sites and allow us to
              recognize those computers or other devices that have been to our
              Sites before. Cookies do many different jobs, such as allowing our
              Sites to remember your preference settings and helping us to
              enhance the usability and performance of our Sites and your
              experience using them. Our Sites also may contain electronic
              images known as web beacons – sometimes called single-pixel gifs –
              that allow us to count the number of users who have visited
              specific pages. We may also include web beacons in promotional
              e-mail messages or newsletters in order to determine whether
              messages have been opened and acted upon. The type of cookie or
              similar technology that may be used on our Sites can be
              categorized as follows: Strictly Necessary, Performance,
              Functionality &amp; Profile and Advertising.
            </AgreementTextBlock>
            <ul>
              <li>
                <AgreementTextBlock>
                  <em>
                    <strong>Strictly Necessary Cookies.</strong>
                  </em>{' '}
                  These cookies are essential for basic functionalities of the
                  Site, and they enable you to move around our Sites and use
                  their features, particularly in connection with information
                  searches and order placement. Without these cookies, services
                  you have asked for cannot be provided. These cookies do not
                  gather information about you that could be used for marketing
                  or remembering where you have been on the internet. Examples
                  of strictly necessary cookies are a shopping basket cookie,
                  which is used to remember the products that you wish to
                  purchase when you add products to your shopping basket or
                  proceed to checkout, a login/ authentication cookie which
                  allows and manages your login to the Site and identifies you
                  after logging in for a single session, a session cookie which
                  is required to carry out the data transmission and provide the
                  Site to you, a security cookie that detects repeat failed
                  login attempts or similar abuses of the Sites. These types of
                  cookies are regularly stored only as long as required for
                  their purpose.
                </AgreementTextBlock>
              </li>
              <li>
                <AgreementTextBlock>
                  <em>
                    <strong>Performance Cookies.</strong>
                  </em>{' '}
                  These cookies collect information about how you use our Sites,
                  for example which pages you go to most often and if you get
                  any error messages from certain pages. These cookies collect
                  information that is used to improve how our Sites work.
                  Without these cookies we cannot learn how our Sites are
                  performing and make relevant improvements that could better
                  your browsing experience. Examples of performance cookies that
                  our Sites use include cookies from Google and Adobe Analytics
                  (see further discussion below).
                </AgreementTextBlock>
              </li>
              <li>
                <AgreementTextBlock>
                  <em>
                    <strong>Functionality &amp; Profile Cookies.</strong>
                  </em>{' '}
                  These cookies allow our Sites to store information that you
                  provide, such as preferences, and to store technical
                  information useful for your interactions with our Sites. For
                  instance, they remember your user ID and elements of your user
                  profile. They also ensure that your experience using the Sites
                  is relevant to you. They may also be used to provide services
                  you have asked for such as watching a video or commenting on a
                  blog. These cookies will not be used to track your browsing
                  activity on other websites. Without these cookies, a website
                  cannot remember choices you have previously made or
                  personalize your browsing experience. For example, we use a
                  cookie to store your language preferences, which allows us to
                  present you with product search results in the correct
                  language, and we use a cookie to store your choice about the
                  appearance of the cookie information banner that we display on
                  our Sites. This cookie will help us remember your choice about
                  the appearance of the cookie information banner when you
                  subsequently visit the same site where you made your choice
                  about the banner and any other Big Robin sites with the same
                  domain or the same top-level domain.
                </AgreementTextBlock>
              </li>
              <li>
                <AgreementTextBlock>
                  <em>
                    <strong>
                      Advertising Cookies and Similar Technologies.
                    </strong>
                  </em>{' '}
                  These cookies or similar technologies may be used to deliver
                  advertisements that are more relevant to you and your
                  interests. They may also be used to limit the times you see an
                  advertisement as well as help to measure the effectiveness of
                  the advertising campaign. These cookies may track your visits
                  to other websites. Without these cookies or other
                  technologies, online advertisements you encounter will be less
                  relevant to you and your interests.
                </AgreementTextBlock>
              </li>
            </ul>
            <section>
              <AgreementBlockTitle style={{ textDecoration: 'underline' }}>
                Setting your cookie preference
              </AgreementBlockTitle>
              <br />
              <AgreementTextBlock>
                You can usually modify your browser settings to decline cookies
                and you can withdraw your consent at any time by modifying the
                settings of your browser to reject or disable cookies or by
                opting out of specific cookies through the opt-out options
                shared below. If you choose to decline cookies altogether, you
                may not be able to fully experience the features of the Sites
                that you visit.
              </AgreementTextBlock>
            </section>
            <section>
              <AgreementBlockTitle style={{ textDecoration: 'underline' }}>
                Our use of web analytics
              </AgreementBlockTitle>
              <br />
              <AgreementTextBlock>
                We use different analytic tools which serve the purpose of
                measuring, analyzing and optimizing our marketing measures and
                provide you with customized advertisements that could be of
                particular interest to you. In particular, we use the following
                tools:
              </AgreementTextBlock>
              <ul>
                <li>
                  <AgreementTextBlock>
                    <em>
                      <strong>Google Analytics</strong>
                    </em>{' '}
                    uses cookies which enable an analysis of your use of the
                    Sites. The information collected (IP address, browsing
                    activities and other data linked to your usage of the Sites)
                    is usually transferred to a Google server in the USA and
                    stored there. You can prevent Google Analytics from
                    recognizing you on return visits to the Sites by disabling
                    cookies on your browser. To see how you can opt-out of
                    certain Google features, visit:{' '}
                    <a
                      href="http://tools.google.com/dlpage/gaoptout"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Google Analytics Opt Out Link
                    </a>
                    .
                  </AgreementTextBlock>
                </li>
                <li>
                  <AgreementTextBlock>
                    <em>
                      <strong>Google Tag Manager</strong>
                    </em>{' '}
                    helps to manage website tags through a single interface. The
                    tool itself (implementing the tags) triggers other tags that
                    may themselves collect (personal) data. However, Google Tag
                    Manager does not access this data.
                  </AgreementTextBlock>
                </li>
                <li>
                  <AgreementTextBlock>
                    The&nbsp;
                    <em>
                      <strong>LinkedIn Insight Tag</strong>
                    </em>{' '}
                    is a lightweight JavaScript tag that powers conversion
                    tracking, website audiences, and website demographics for
                    LinkedIn ad campaigns. The Insight Tag is incorporated as a
                    standard component of our Site to enable these LinkedIn
                    features. To see how you can opt-out of certain LinkedIn
                    features, visit{' '}
                    <a
                      href="https://www.linkedin.com/settings/?trk=nav_account_sub_nav_settings&modal=nsettings-enhanced-advertising"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Linkedin Opt Out Link
                    </a>
                    .
                  </AgreementTextBlock>
                </li>
              </ul>
            </section>
          </section>
          <section>
            <AgreementSubTitle>
              Your Rights and Responsibilities
            </AgreementSubTitle>
            <AgreementTextBlock>
              You are permitted, and hereby agree, to only provide personal
              information to Big Robin if such personal information is accurate,
              reliable, and relevant to our relationship and only to the extent
              such disclosure will not violate any applicable data protection
              law, statute, or regulation.
            </AgreementTextBlock>
            <AgreementTextBlock>
              You may have certain rights under applicable data protection law
              with respect to personal information about you that is collected
              through the Sites or when you contact or otherwise engage with us.
              To exercise any of these data privacy rights, please contact us,
              or have your authorized agent contact us, in accordance with the
              “Contact Us” section listed below. In the event you submit, or
              your authorized agent submits on your behalf, a data request, you
              (and your authorized agent) hereby acknowledge and agree, under
              penalty of perjury, that you are (or the authorized agent of) the
              consumer whose personal information is the subject of the request.
              We will respond to any data requests within the timeframes
              required by law, and we may charge a fee to facilitate your
              request where permitted by law.
            </AgreementTextBlock>
            <AgreementTextBlock>
              <em>
                <strong>Marketing.</strong>
              </em>{' '}
              You have the right to opt-out of receiving electronic direct
              marketing communications from us. All electronic direct marketing
              communications that you may receive from us, such as e-mail
              messages, will give you an option of not receiving such
              communications from us in the future.
            </AgreementTextBlock>
            <AgreementTextBlock>
              <em>
                <strong>California Privacy Rights.</strong>
              </em>{' '}
              California Civil Code Section § 1798.83 permits users of the Sites
              that are California residents to request certain information
              regarding our disclosure of personal information to third parties
              for their direct marketing purposes. Pursuant to the California
              Consumer Privacy Act of 2018, as amended (“CCPA”), California
              residents may have certain data privacy rights, such as the right
              to be notified about what personal information categories are
              collected about you, and our intended use and purpose for
              collecting your personal information. You have the right to
              request access to your personal information and, to the extent
              feasible, request that it be transmitted in certain forms and
              formats. You may have the right to request that we (and any
              applicable service provider) delete your personal information. You
              have the right not to be subject to discrimination for asserting
              your rights under the CCPA. If you make, or an authorized agent on
              your behalf makes, any request related to your personal
              information, Big Robin will ascertain your identity to the degree
              of certainty required under the CCPA before addressing your
              request. Big Robin may require you to match at least three pieces
              of personal information we have previously collected from you
              before granting you access or otherwise responding to your
              request.
            </AgreementTextBlock>
            <AgreementTextBlock>
              <em>
                <strong>Do Not Track.</strong>
              </em>{' '}
              Some web browsers may transmit “do-not-track” signals to the Sites
              with which the user communicates. Because of differences in how
              web browsers incorporate and activate this feature, it is not
              always clear whether users intend for these signals to be
              transmitted, or whether they even are aware of them. We currently
              do not take action in response to these signals.
            </AgreementTextBlock>
          </section>
          <section>
            <AgreementSubTitle>
              European Union (EU) Privacy Disclaimer
            </AgreementSubTitle>
            <AgreementTextBlock>
              Big Robin processes personal information in accordance with the
              legal bases set forth in the EU General Data Protection Regulation
              (GDPR) or EU Member State law. For example, our processing of
              Personal Data on individuals (as described above) is justified
              based on statutory provisions that (1) processing is based on the
              consent; (2) processing is necessary for Big Robin’s legitimate
              interests as set out herein; and (3) processing is necessary for
              the performance of a contract to which you are a party. If you are
              in the EU or afforded protection under the GDPR, you may have
              certain rights with respect to the Personal Data. To the extent
              permitted by applicable data protection laws, you may access the
              Personal Data we hold about you; request that inaccurate,
              outdated, or no longer necessary information be corrected, erased,
              or restricted; and, request that we provide your Personal Data in
              a format that allows you to transfer it to another service
              provider. You also may withdraw your consent at any time where we
              are relying on your consent for the processing of your Personal
              Data. You may object to our processing of your Personal Data where
              that processing is based on our legitimate interest. You have the
              right to lodge a complaint with your competent data protection
              authority. If you wish to exercise any of these rights, please
              contact us in accordance with the instructions provided below.
            </AgreementTextBlock>
          </section>
          <section>
            <AgreementSubTitle>Nevada Privacy Disclaimer</AgreementSubTitle>
            <AgreementTextBlock>
              Pursuant to Nevada law, a Nevada “consumer” (as the term is
              defined therein), may, at any time, submit a verified request
              through a designated request address to an “operator” directing
              the operator not to make any sale of his or her personal
              information that the operator has collected or will collect about
              the consumer. For clarity purposes, Big Robin does not sell or
              exchange your personal information for monetary consideration to a
              third party for the third party to license or sell the information
              to additional persons or parties.
            </AgreementTextBlock>
          </section>
          <section>
            <AgreementSubTitle>Links to Third-Party Websites</AgreementSubTitle>
            <AgreementTextBlock>
              The Services may contain links to other sites that are not
              operated by us. If you click on a third-party link, you will be
              directed to that third party's site. We strongly advise you to
              review the Privacy Policy of every site you visit.
            </AgreementTextBlock>
            <AgreementTextBlock>
              We have no control over, and assume no responsibility for the
              content, privacy policies or practices of any third-party sites or
              services.
            </AgreementTextBlock>
          </section>
          <section>
            <AgreementSubTitle>
              Changes to This Privacy Policy
            </AgreementSubTitle>
            <AgreementTextBlock>
              We may occasionally update this Privacy Policy. When we do, we
              will revise the “last updated” date at the top of the Privacy
              Policy and take such additional steps as may be required by law.
            </AgreementTextBlock>
          </section>
          <section>
            <AgreementSubTitle>Contact Us</AgreementSubTitle>
            <AgreementTextBlock>
              If you have questions regarding this Privacy Policy, our handling
              of your personal information, or would like to request more
              information or exercise a data right, please contact us by mail at{' '}
              <a href="mailto:admin@bigrobin.io">admin@bigrobin.io</a> or by
              regular mail at following address: <br />
              Canada: Suite 210-186, 8120 No. 2 Road, Richmond BC V7C 5J8.
              <br />
              USA: 5965 Village Way, Ste E105 #121, San Diego, CA 92130.
            </AgreementTextBlock>
          </section>
        </AgreementLayout>
      </AgreementContent>
    </>
  );
};
