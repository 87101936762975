import Content from 'components/Content';
import {
  ResearchersSectionContent,
  Title,
} from 'components/ResearchersSection/styled';
import { SearchbarLayout } from 'pages/ResearcherList/styled';
import styled from 'styled-components/macro';

export const SearchResultsContainer = styled.div`
  ${ResearchersSectionContent} {
    margin-bottom: 4rem;
    background: none;

    ${Title} {
      color: #ff9e08;
    }
  }
`;

export const SearchResultsContent = styled(Content)`
  color: #4b4c5e;
  margin-top: 4rem;

  h1 {
    font-size: 3.6rem;
  }

  h2 {
    font-size: 2.4rem;
    margin-bottom: 2rem;
  }

  ${SearchbarLayout} {
    margin-bottom: 3rem;
  }
`;

export const ProjectsContainer = styled.div`
  background-color: white;
  padding: 4rem 6rem;
  margin-bottom: 4rem;
`;

export const ThatsAll = styled.h6`
  margin-top: 4rem;
  font-size: 1.6rem;
  opacity: 0.54;
  text-align: center;
`;
