import Button from 'components/Button';
import Content, { MiddleContainer } from 'components/Content';
import { InputContainer } from 'components/Input/styled';
import styled from 'styled-components/macro';

export const LandingJoinFormContent = styled(Content)`
  position: relative;
  flex: 1;
  background-color: white;
  color: #4b4c5e;
  text-align: center;

  ${MiddleContainer} {
    margin: 5rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const LandingHeader = styled.h1`
  font-size: 3.6rem;
  margin-bottom: 2rem;
  z-index: 1;
`;

export const LandingSubHeader = styled.p`
  max-width: 90rem;
  font-size: 2.4rem;
  margin-bottom: 2rem;
  z-index: 1;

  @media (max-width: 900px) {
    width: 80%;
    font-size: 2.8rem;
    margin-bottom: 6rem;
  }
`;

export const JoinForm = styled.form`
  min-width: 40rem;
  z-index: 1;

  @media (max-width: 900px) {
    width: 60%;
  }

  @media (max-width: 768px) {
    width: 70%;
  }

  ${InputContainer} {
    margin-bottom: 2rem;
    z-index: 1;

    @media (max-width: 900px) {
      margin-bottom: 3rem;
    }
  }

  ${Button} {
    margin-top: 4rem;
    margin: 0 auto;
    padding-left: 5rem;
    padding-right: 5rem;
    z-index: 1;

    @media (max-width: 900px) {
      margin-top: 1.5rem;
      padding: 1.5rem 7rem;
      font-size: 2.3rem;
    }

    @media (max-width: 768px) {
      padding: 2rem 7rem;
    }
  }
`;

// export const BackgroundImage = styled.img`
//   width: 35rem;
//   position: absolute;
//   bottom: 0;
//   left: -6rem;

//   @media (max-width: 1200px) {
//     width: 20vw;
//     left: -6vw;
//   }

//   @media (max-width: 768px) {
//     width: 16vw;
//     left: -4vw;
//   }
// `;
export const BackgroundImage = styled.img`
  height: 35rem;
  position: absolute;
  bottom: 0;
  left: -15rem;
  z-index: 0;

  @media (max-width: 900px) {
    bottom: -8vw;
    height: 40vw;
    left: -20vw;
  }
`;

export const HowItWorksContent = styled(Content)`
  flex: 1;
  background-color: white;
  color: #4b4c5e;

  ${MiddleContainer} {
    margin-top: 5rem;
    display: flex;
    flex-direction: column;
  }
`;

export const HowItWorksHeader = styled.h1`
  font-size: 3.6rem;
  margin-bottom: 4rem;
`;

export const HowItWorksStepsLayout = styled.div`
  display: grid;
  @media (max-width: 899px) {
    grid-template-rows: 1fr 1fr 1fr;
    grid-row-gap: 10rem;
  }
  @media (min-width: 900px) {
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 10rem;
  }
  margin-bottom: 10rem;
`;

export const HowItWorksStepLayout = styled.div`
  display: flex;
  flex-direction: column;

  p {
    font-size: 2rem;
  }

  @media (max-width: 899px) {
    & {
      align-items: center;
    }
    p {
      width: 30rem;
    }
  }
`;

export const HowItWorksImageContainer = styled.div`
  align-self: center;
  display: flex;
  align-items: center;
  margin-bottom: 2rem;

  span {
    font-size: 4rem;
    font-weight: bold;
    color: #ff9e08;
  }

  img {
    height: 16rem;
    width: 16rem;
  }
`;

export const JoinButton = styled(Button)`
  background-color: #65637b;

  @media (max-width: 900px) {
    margin-top: 4rem;
  }
`;
