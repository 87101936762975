import Button from 'components/Button';
import { Row } from 'components/Layout';
import { UserMenu } from 'components/UserMenu';
import { MEMBER_TYPES, useAuthState } from 'context/AuthContext';
import React, { useEffect, useState } from 'react';
import { MdMessage } from 'react-icons/md';
// import { FaBlackberry } from 'react-icons/fa';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { ajax } from 'utils/ajax';
import {
  BrandLogo,
  MessageBadge,
  MessageWrapper,
  NavbarItem,
  NavbarItemMessage,
  NavbarItemsLayout,
  NavbarLayout,
} from './styled';

const Navbar = () => {
  const history = useHistory();
  const location = useLocation();

  const { user, isGettingUser, isResearcher, conversationId } = useAuthState();

  const [unreadConversationCount, setUnreadConversationCount] = useState(0);

  const gettingConversationCount = async () => {
    await ajax('GET', '/conversation/unread-count').then(res => {
      if (res.code === 200) {
        setUnreadConversationCount(res?.data?.unread_chats);
      }
    });
  };

  useEffect(() => {
    if (localStorage.getItem('br-userId')) {
      gettingConversationCount();
    }
  }, [location?.pathname]);

  useEffect(() => {
    if (localStorage.getItem('br-userId') && unreadConversationCount) {
      gettingConversationCount();
    }
  }, [conversationId]);

  return (
    <NavbarLayout>
      {!isGettingUser && (
        <>
          <Row alignItems="center" style={{ flex: 1 }}>
            {user ? (
              <Link to="/" style={{ marginRight: '2rem' }}>
                <BrandLogo src={require('./logo.png')} alt="Big Robin logo" />
              </Link>
            ) : (
              <Link to="/" style={{ marginRight: '2rem' }}>
                <BrandLogo src={require('./logo.png')} alt="Big Robin logo" />
              </Link>
            )}
          </Row>
          <NavbarItemsLayout>
            {user ? (
              <>
                <NavbarItem to="/welcome">Home</NavbarItem>
                {user?.member?.member_type_id === +MEMBER_TYPES.RESEARCHER ? (
                  <NavbarItem to="/projects">Find Projects</NavbarItem>
                ) : (
                  <NavbarItem to="/researchers">Find Researcher</NavbarItem>
                )}
                <NavbarItem to="/my-projects">My Projects</NavbarItem>

                <NavbarItem to="/about">About</NavbarItem>
                <NavbarItemMessage to="/messages">
                  <MessageWrapper>
                    <MdMessage size={'20px'} />
                    {unreadConversationCount > 0 && (
                      <MessageBadge
                        style={{
                          background: isResearcher
                            ? '#4B4C5E'
                            : 'rgb(255, 158, 8)',
                          color: isResearcher ? '#ffffff' : '#ffffff',
                        }}
                      >
                        {unreadConversationCount}
                      </MessageBadge>
                    )}
                  </MessageWrapper>
                </NavbarItemMessage>
                <UserMenu />
              </>
            ) : (
              <>
                <NavbarItem
                  to={{
                    pathname: '/login',
                    state: { isSignup: false, isResearcher: true },
                  }}
                >
                  I'm a Researcher
                </NavbarItem>
                <NavbarItem to="/about">About</NavbarItem>
                <Button
                  outline
                  onClick={() =>
                    history.push({
                      pathname: '/login',
                      state: { isSignup: false, isResearcher: false },
                    })
                  }
                  style={{ padding: '1rem 1.5rem' }}
                >
                  CLIENT LOGIN
                </Button>
              </>
            )}
          </NavbarItemsLayout>
        </>
      )}
    </NavbarLayout>
  );
};

export default Navbar;
