import React from 'react';
import dayjs from 'dayjs';
import Button from 'components/Button';
import {
  AgreementContent,
  AgreementLayout,
  AgreementTitle,
  AgreementSubTitle,
  AgreementTextBlock,
} from '../Agreements/styled';

export const NdaTEmplate = () => {
  const handleClose = () => {
    localStorage.setItem('agreeNda', 'true');
    window.close();
  };

  return (
    <>
      <AgreementContent>
        <AgreementLayout>
          <AgreementTitle>NON-DISCLOSURE AGREEMENT</AgreementTitle>
          <section>
            <AgreementTextBlock>
              This Agreement is between you and any other Big Robin user with
              whom you share or from whom you receive Confidential Information
              as defined below (collectively, the “Parties”). <br /> The Parties
              are considering entering into a contractual relationship whereby
              Researcher will provide services for Member Clients (“Services
              Contract”). The Parties may need to share and disclose information
              that is confidential and proprietary to determine whether to enter
              into a Services Contract and to execute any Services Contract.
            </AgreementTextBlock>
          </section>
          <section>
            <AgreementTextBlock>
              The Parties agree as follows:{' '}
            </AgreementTextBlock>
            <AgreementSubTitle>1. Confidential Information</AgreementSubTitle>
            <AgreementTextBlock>
              The Parties acknowledges that all information, knowledge, or data
              relating to: technical information; format; layout; process; test
              process; questions; formulations and processes; technology;
              product; services; capabilities; product designs; new product
              plans; samples or models; computer programs; costs and financial
              information; pricing methods or strategies; existing and potential
              customers; distribution scheme; market studies, plans, and
              strategies; sources of supply for product components; and human
              resources information, shall be deemed confidential (“Confidential
              Information”) and subject to nondisclosure. Confidential
              Information shall not include information that is known publicly
              or was otherwise known prior to receiving information from the
              other Party.
            </AgreementTextBlock>
          </section>
          <section>
            <AgreementSubTitle>
              2. Use and Disclosure of Confidential Information
            </AgreementSubTitle>
            <AgreementTextBlock>
              The Parties agree to use Confidential Information solely to
              execute and perform any Services Contract and for no other
              purpose. The Parties agree to hold all Confidential Information in
              strict confidence, and shall not, at any time, directly or
              indirectly, by any means or devices whatsoever, copy, divulge,
              disclose, use, or permit the use of any Confidential Information,
              or other data obtained using or based on Confidential Information,
              except upon prior written consent of the other Party
            </AgreementTextBlock>
          </section>
          <section>
            <AgreementSubTitle>
              3. Return or Destruction of Confidential Information
            </AgreementSubTitle>
            <AgreementTextBlock>
              The Parties agree that, upon receiving a request from the other
              Party, they shall return or destroy any and all Confidential
              Information in their possession reduced to tangible medium.
              Notwithstanding the return or destruction of Confidential
              Information, the Parties shall continue to be bound to maintain
              the confidentiality of Confidential Information as provided by
              this Agreement.
            </AgreementTextBlock>
          </section>
          <section>
            <AgreementSubTitle>4. Legal Remedies</AgreementSubTitle>
            <AgreementTextBlock>
              The Parties expressly acknowledge that money damages may not be a
              sufficient remedy for any breach of this Agreement and, in
              addition to any other remedies at law or in equity that the
              Parties may have, the Parties shall be entitled to equitable
              relief, including injunction and specific performance in the event
              of any breach of this Agreement.
            </AgreementTextBlock>
          </section>
          <section>
            <section>
              <AgreementTextBlock>
                If you have any questions about these Terms, please contact us.
              </AgreementTextBlock>
              <AgreementTextBlock>
                Effective: {dayjs(new Date()).format('MMMM DD, YYYY')}
              </AgreementTextBlock>
              <div style={{ width: '100%', margin: '0 auto;' }}>
                <Button onClick={handleClose}>I accept the NDA</Button>
              </div>
            </section>
          </section>
        </AgreementLayout>
      </AgreementContent>
    </>
  );
};
