import { useAuthState } from 'context/AuthContext';
import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { Loading } from './Loading';

export const PrivateRoute = props => {
  const { user, isGettingUser, is_profile_completed } = useAuthState();

  if (isGettingUser) return <Loading />;

  if (!user) return <Redirect to="/login" />;

  if (!is_profile_completed) {
    if (window.location.pathname === '/register') {
      return <Route {...props} />;
    } else if (window.location.pathname === '/welcome') {
      return <Route {...props} />;
    } else {
      return (
        <Redirect
          to={{
            pathname: '/register',
            state: { isPopup: true },
          }}
        />
      );
    }
  }

  return <Route {...props} />;
};
