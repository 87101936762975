import Button from 'components/Button';
import UserCard from 'components/UserCard';
import { CardsLayout } from 'components/UserCard/styled';
import React, { useState, useEffect } from 'react';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import {
  ResearcherCardsLayout,
  ResearchersSectionContent,
  Title,
  RCNavArrow,
} from './styled';
import { ajax } from 'utils/ajax';
import querystring from 'querystring';
import LoadingOverlay from 'react-loading-overlay';

const ResearchersSection = ({ title = 'Discover researchers' }) => {
  const history = useHistory();

  const [disLoader, setDisLoader] = useState(true);
  const [researchers, setResearchers] = useState([]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const MAX_RESEARCHERS_IN_VIEW = 6;

  useEffect(() => {
    ajax(
      'GET',
      `/member/researcher?${querystring.stringify({
        page_size: MAX_RESEARCHERS_IN_VIEW,
        page,
      })}`
    ).then(result => {
      if (result.code === 200) {
        setTotal(result.meta.total_number);
        setResearchers(result.data);
        setDisLoader(false);
      } else {
        setDisLoader(false);
      }
    });
  }, [page]);

  return (
    <ResearchersSectionContent>
      <Title>{title}</Title>
      <ResearcherCardsLayout>
        <RCNavArrow
          onClick={() => setPage(page => page - 1)}
          visible={page > 0}
        >
          <FaChevronLeft />
        </RCNavArrow>
        {disLoader ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              fontSize: '20px',
              margin: '0 auto',
              color: '#ffffff',
            }}
          >
            Loading...
          </div>
        ) : (
          <CardsLayout>
            {researchers.map(researcher => (
              <UserCard
                key={researcher.member.id}
                {...researcher.member}
                industries={researcher.industry}
                specializations={researcher.business_specialization}
                services={researcher.services_offered}
                experience={researcher.fortune_experience}
              />
            ))}
          </CardsLayout>
        )}
        <RCNavArrow
          onClick={() => setPage(page => page + 1)}
          visible={(page + 1) * MAX_RESEARCHERS_IN_VIEW < total}
        >
          <FaChevronRight />
        </RCNavArrow>
      </ResearcherCardsLayout>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Button onClick={() => history.push('/researchers')}>VIEW ALL</Button>
      </div>
    </ResearchersSectionContent>
  );
};

export default ResearchersSection;
