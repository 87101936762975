import React, { useEffect, useState } from 'react';
import ResizeObserver from 'resize-observer-polyfill';
import { StepCircle, StepLadder, StepLayout, StepsLayout } from './styled';

const Steps = ({ steps, current }) => {
  const [width, setWidth] = useState(0);
  const [offset, setOffset] = useState(0);

  useEffect(() => {
    const observer = new ResizeObserver(entries => {
      const { target } = entries[0];

      let isMobile = window.matchMedia('(max-width: 768px)').matches;
      let ladderWidth =
        target
          .querySelector(`[data-step-circle='${steps.length - 1}']`)
          .getBoundingClientRect().left -
        target.querySelector(`[data-step-circle='0']`).getBoundingClientRect()
          .left;

      setWidth(ladderWidth);
      if (isMobile) {
        setOffset(target.getBoundingClientRect().width / 2 - ladderWidth / 2);
      } else {
        setOffset(
          target.querySelector('[data-step-layout="0"]').getBoundingClientRect()
            .width / 2
        );
      }
    });

    observer.observe(document.getElementById('steps-layout'));

    return () => {
      observer.unobserve(document.getElementById('steps-layout'));
    };
  }, [steps.length]);

  return (
    <StepsLayout id="steps-layout">
      <StepLadder style={{ width, left: offset }} />
      {steps.map((step, index) => (
        <StepLayout key={step} data-step-layout={index}>
          <StepCircle data-step-circle={index} active={index <= current} />
          <p>{step}</p>
        </StepLayout>
      ))}
    </StepsLayout>
  );
};

export default Steps;
