import React, {
  useCallback,
  useEffect,
  useState,
  useMemo,
  useRef,
  // Fragment,
} from 'react';
import { useAuthState } from 'context/AuthContext';
import { useFormik } from 'formik';
import { ajax } from 'utils/ajax';
import Input, { LabelLayout } from 'components/Input';
import Dropdown from 'components/Dropdown';
import Datepicker from 'components/Datepicker';
import Textarea from 'components/Textarea';
import FileInput from 'components/FileInput';
import { Column } from 'components/Layout';
import SpecializationDropdown from 'components/SpecializationDropdown';
import {
  ProjectFormContent,
  PageButtonsLayout,
  PageButtonsGroup,
  LeftButton,
  RightButton,
  ProjectContentTitle,
  ProjectContentDesc,
  // LinkedInButton,
  FormRow,
  DateRow,
  CPCheckbox,
  RPCheckbox,
} from './styled';
// import { COUNTRIES } from './country_list';
import { FaSpinner } from 'react-icons/fa';
// import LinkedInPageUser from '../LinkedInPageUser';
import {
  INDUSTRY_VALUES,
  INDUSTRY_LABELS,
  errorMessage,
} from '../../constants';
import DropdownWithName from 'components/DropdownWithName';
import { Autocomplete, TextField } from '@mui/material';
import {
  EDUCATION_ITEM_LABELS,
  EDUCATION_ITEM_VALUES,
  SIZES_OF_BUSINESSES_WORKED_LABELS,
  SIZES_OF_BUSINESSES_WORKED_VALUES,
  STACKHOLDER_ITEM_LABELS,
  STACKHOLDER_ITEM_VALUES,
} from 'pages/ResearcherProfileForm/rpf_constants';
import LoadingIndicator from 'components/LoadingIndicator';

const getOptionValues = options => options.map(({ id }) => id);
// const getCountryOptionValues = () => [
//   'Global',
//   'North America',
//   'Europe',
//   'Middle East',
//   'Africa',
//   'Latin America',
//   'Asia',
//   'Australasia',
// ];
// const getCountryOptionLabels = () => {
//   return {
//     1: 'Global',
//     2: 'North America',
//     3: 'Europe',
//     4: 'Middle East',
//     5: 'Africa',
//     6: 'Latin America',
//     7: 'Asia',
//     8: 'Australasia',
//   };
// };
// const getCountryOptionValues = () => COUNTRIES.map(({ name }) => name);
// const getCountryOptionLabels = () =>
//   COUNTRIES.reduce((options, { name }) => ({ ...options, [name]: name }), {});

const getOptionLabels = options =>
  options.reduce((options, { name, id }) => ({ ...options, [id]: name }), {});

export const ProjectForm = ({
  onFormSubmit,
  onStepChange,
  isEdit = false,
  projectData = null,
  isLoading = false,
  duplicateDetails,
  setIsShowingModal,
  setShowSaveLaterModal,
  setShowingAction,
  setIsOpen,
  setLoading,
  isOpen,
  isComplete,
  isDisable,
  setIsDisable,
  setIsComplete,
  handleProjectPreview,
  setPreviewData,
  setPreviewDataValues,
}) => {
  //const location = useLocation();
  const { user } = useAuthState();
  const initStep = user.member.first_name && !isEdit ? 1 : 0;

  //scroll to error position
  const [isError, setIsError] = useState(false);

  const projectTitle = useRef();
  const requiredExpertise = useRef();
  // const specialization = useRef();
  const industry = useRef();
  const projectStart = useRef();
  const projectEnd = useRef();
  const projectBg = useRef();
  const businessObjective = useRef();
  const businessAction = useRef();
  const keyDeliverables = useRef();

  //const shouldExit = useRef(false);
  const [currentStep, setCurrentStep] = useState(initStep);
  const [options, setOptions] = useState({});

  const [serviceOption, setServiceOption] = useState(false);
  const [serviceOptionObj, setServiceOptionObj] = useState(false);
  const [countryObjList, setCountryObjList] = useState([]);

  useEffect(() => {
    const getCountryList = async () => {
      const subs_res = await ajax('GET', '/country');
      if (subs_res?.code === 200 && subs_res?.data) {
        const listObj =
          subs_res?.data?.length > 0
            ? subs_res?.data.map(item => item?.nicename)
            : [];
        setCountryObjList(listObj);
      }
    };
    getCountryList();
  }, []);

  useEffect(() => {
    if (isOpen === false) {
      ajax('GET', '/member_reference_dropdown').then(res => {
        if (res.code === 200) {
          setOptions(res?.data);
          setPreviewDataValues(res?.data);
          setServiceOption(
            res?.data?.services_offered?.length > 0
              ? res?.data?.services_offered.map(a => a.name)
              : []
          );
          setServiceOptionObj(
            res?.data?.services_offered?.length > 0
              ? res?.data?.services_offered
              : []
          );
        } else if (res?.code === 401) {
          setLoading(false);
          setIsOpen(true);
        }
      });
    }
  }, [isOpen, setIsOpen, setLoading]);

  const getTextAreaMaxLine = () => {
    let isMobile = window.matchMedia('(max-width: 768px)').matches;
    return isMobile ? 5 : 3;
  };
  const [textAreaMaxLine, setTextAreaMaxLine] = useState(getTextAreaMaxLine());

  const fixSpecializationForm = smap => {
    if (smap !== undefined && smap[0]?.name === 'DATA SCIENCE/ML/AI') {
      return [
        {
          name: smap[0]?.name,
          items: [15, 17, 22, 13, 23, 14, 19, 21, 16, 20, 18, 24],
        },
      ];
    } else if (smap !== undefined && smap[1]?.name === 'DATA SCIENCE/ML/AI') {
      return [
        {
          name: 'DATA SCIENCE/ML/AI',
          items: [15, 17, 22, 13, 23, 14, 19, 21, 16, 20, 18, 24],
        },
        {
          name: 'INSIGHTS/MARKET RESEARCH',
          items: [10, 1, 4, 5, 8, 3, 11, 2, 7, 6, 9, 12],
        },
      ];
    } else {
      return smap;
    }
  };

  function getObjKey(obj, value) {
    return Object.keys(obj).find(key => obj[key] === value);
  }

  const {
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: {
      first_name: user.member.first_name || '',
      last_name: user.member.last_name || '',
      address: user.member.address || '',
      address_2: user.member.address_2 || '',
      city: user.member.city || '',
      state: user.member.state || '',
      country: user.member.country || '',
      postal_code: user.member.postal_code || '',
      billing_information: user.member.billing_information || '',
      billing_address: user.member.billing_address || '',
      billing_address_2: user.member.billing_address_2 || '',
      billing_city: user.member.billing_city || '',
      billing_state: user.member.billing_state || '',
      billing_country: user.member.billing_country || '',
      billing_postal_code: user.member.billing_postal_code || '',
      is_same_billing_address: false,
      company_affiliation: user.member.company_affiliation || '',
      contact_number: user.member.contact_number || '',
      contact_email: user.member.contact_email || '',
      is_company_account: user.member.company_type_id === 2,
      company_url: user.member.company_url || '',
      accounts: user.member.accounts || [],
      title: duplicateDetails ? `Copy of ${duplicateDetails?.title}` : '',
      business_needspace: duplicateDetails
        ? duplicateDetails?.business_needspace.map(item => item.id)
        : [],
      industry: duplicateDetails
        ? duplicateDetails?.industry.map(item => item.id)
        : [],
      start_date: duplicateDetails
        ? new Date(duplicateDetails?.start_date)
        : new Date(),
      end_date: duplicateDetails
        ? new Date(duplicateDetails?.end_date)
        : new Date(),
      actual_price: duplicateDetails ? duplicateDetails?.actual_price : 0,
      description: duplicateDetails ? duplicateDetails?.description : '',
      key_deliverables: duplicateDetails
        ? Array.isArray(duplicateDetails?.key_deliverables)
          ? duplicateDetails.key_deliverables?.length > 0 &&
            duplicateDetails.key_deliverables?.map(item => item.id)
          : []
        : [],
      business_objective: duplicateDetails
        ? duplicateDetails?.business_objective
        : '',
      research_objective: duplicateDetails
        ? duplicateDetails?.research_objective
        : '',
      business_action: duplicateDetails
        ? duplicateDetails?.business_action
        : '',
      background: duplicateDetails ? duplicateDetails?.background : '',
      countries_of_interest: duplicateDetails
        ? duplicateDetails?.countries_of_interest
        : [],
      additional_requests: duplicateDetails
        ? duplicateDetails?.additional_requests
        : '',
      key_questions: duplicateDetails ? duplicateDetails?.key_questions : '',
      success_criteria: duplicateDetails
        ? duplicateDetails?.success_criteria
        : '',
      project_milestone: duplicateDetails
        ? duplicateDetails?.project_milestone
        : '',
      services: duplicateDetails
        ? duplicateDetails?.services.map(item => item.name)
        : [],
      techniques: duplicateDetails ? duplicateDetails?.techniques : [],
      years_of_experience: duplicateDetails
        ? duplicateDetails?.years_of_experience
        : 0,
      reference_files: [],
      research_mastery: duplicateDetails
        ? duplicateDetails?.research_mastery
        : '',
      analytics_mastery: duplicateDetails
        ? duplicateDetails?.analytics_mastery
        : '',
      new_account: duplicateDetails ? duplicateDetails?.new_account : '',
      engagement: duplicateDetails ? duplicateDetails?.engagement : 0,
      education: duplicateDetails ? duplicateDetails?.education : 0,
      technical_mastery: duplicateDetails
        ? duplicateDetails?.technical_mastery
        : 0,
      industry_expertise: duplicateDetails
        ? getObjKey(
            STACKHOLDER_ITEM_LABELS,
            duplicateDetails?.industry_expertise
          )
        : '',
      referral_network: duplicateDetails
        ? duplicateDetails?.referral_network
        : 0,
      minimum_researcher_qualification: duplicateDetails
        ? duplicateDetails?.minimum_researcher_qualification
        : '',
      is_public: duplicateDetails ? duplicateDetails?.is_public : 1,
      sizes_of_businesses_worked: duplicateDetails
        ? duplicateDetails?.sizes_of_businesses_worked
        : [],
      fortune_exp: duplicateDetails ? duplicateDetails?.fortune_exp : '',
      other_key_deliverable: duplicateDetails
        ? duplicateDetails?.other_key_deliverable
        : '',
    },
    onSubmit: async data => {
      setFieldValue('key_deliverables', data?.key_deliverables || []);
      data = {
        ...data,
        actual_price: data?.actual_price !== '' ? data?.actual_price : 0,
      };
      // let msgfields = [];
      if (data?.services?.length > 0) {
        let arr = [];
        serviceOptionObj.filter(function(o1) {
          return data?.services.some(function(o2) {
            //  for diffrent we use NOT (!) befor obj2 here
            // id is unnique both array object
            if (o1.name === o2) {
              return arr.push(o1.id);
            }
          });
        });
        data = { ...data, services: arr };
      }

      console.log('data', data);
      setLoading(true);
      // Only Check Required Fields if step is 2 and will submit data as non-draft project
      if (isComplete) {
        setIsComplete(false);
        const error = checkRequiredFields(data, 0);
        if (data.new_account && data.new_account !== '')
          data.accounts.push(data.new_account);
        if (error.error === 0) {
          if (onFormSubmit) {
            data.status = 3; // Submit as project with status 3

            onFormSubmit(data);
          }
        } else {
          const msg = (
            <div>
              <div>Please fill-up the required fields below:</div>
              <div style={{ textAlign: 'center', marginTop: '15px' }}>
                {error.fields.map(item => {
                  return <div>{item}</div>;
                })}
              </div>
            </div>
          );
          if (setShowingAction) {
            setShowingAction('error');
          }
          if (setIsShowingModal) {
            setIsShowingModal(msg);
          }
        }
      } else {
        setIsComplete(false);
        if (data.new_account && data.new_account !== '') {
          data.accounts.push(data.new_account);
        }
        if (onFormSubmit) {
          data.status = 4; // Submit as draft with status 4
          onFormSubmit(data);
        }
      }
    },
  });

  const handleProjectForm = () => {
    if (
      !values?.title ||
      !values?.services.length > 0 ||
      !values?.industry.length > 0 ||
      !values?.start_date ||
      !values?.end_date ||
      !values?.background ||
      !values?.business_objective ||
      !values?.business_action ||
      !values?.key_deliverables.length > 0 ||
      (values?.key_deliverables?.includes(11) && !values?.other_key_deliverable)
    ) {
      setIsError(true);
      if (!values?.title) {
        projectTitle.current.scrollIntoView({ behavior: 'smooth' });
      } else if (!values?.services.length > 0) {
        requiredExpertise.current.scrollIntoView({
          behavior: 'smooth',
        });
      }
      // else if (!values.business_needspace.length > 0) {
      //   specialization.current.scrollIntoView({
      //     behavior: 'smooth',
      //   });
      // }
      else if (!values.industry.length > 0) {
        industry.current.scrollIntoView({
          behavior: 'smooth',
        });
      } else if (!values?.start_date) {
        projectStart.current.scrollIntoView({
          behavior: 'smooth',
        });
      } else if (!values?.end_date) {
        projectEnd.current.scrollIntoView({
          behavior: 'smooth',
        });
      } else if (!values?.background) {
        projectBg.current.scrollIntoView({
          behavior: 'smooth',
        });
      } else if (!values?.business_objective) {
        businessObjective.current.scrollIntoView({
          behavior: 'smooth',
        });
      } else if (!values?.business_action) {
        businessAction.current.scrollIntoView({
          behavior: 'smooth',
        });
      } else if (
        !values?.key_deliverables.length > 0 ||
        (values?.key_deliverables?.includes(11) &&
          !values?.other_key_deliverable)
      ) {
        keyDeliverables.current.scrollIntoView({
          behavior: 'smooth',
        });
      }
      return false;
    } else {
      handleStepChange(currentStep + 1);
    }
  };

  const handleStepChange = step => {
    setCurrentStep(step);
    if (onStepChange) {
      onStepChange(step);
    }
  };

  useEffect(() => {
    if (values.is_same_billing_address) {
      setFieldValue('billing_address', values.address);
      setFieldValue('billing_address_2', values.address_2);
      setFieldValue('billing_city', values.city);
      setFieldValue('billing_state', values.state);
      setFieldValue('billing_country', values.country);
      setFieldValue('billing_postal_code', values.postal_code);
    }
  }, [
    setFieldValue,
    values.address,
    values.address_2,
    values.city,
    values.state,
    values.country,
    values.postal_code,
    values.is_same_billing_address,
  ]);

  useEffect(() => {
    setPreviewData(values);
  }, [values]);

  const checkRequiredFields = (data, errorCnt) => {
    let msgfields = [];
    if (data.is_same_billing_address) {
      data.billing_address = data.address;
      data.billing_address_2 = data.address_2;
      data.billing_city = data.city;
      data.billing_state = data.state;
      data.billing_country = data.country;
      data.billing_postal_code = data.postal_code;
    }
    for (var i in data) {
      switch (true) {
        case i === 'first_name' && !data[i]:
          msgfields.push('First Name');
          errorCnt++;
          break;
        case i === 'last_name' && !data[i]:
          msgfields.push('Last Name');
          errorCnt++;
          break;
        case i === 'address' && !data[i]:
          msgfields.push('Address');
          errorCnt++;
          break;
        case i === 'billing_address' && !data[i]:
          msgfields.push('Billing Address');
          errorCnt++;
          break;
        // case i === 'contact_number' && !data[i]:
        //   setContactNumberErr(true);
        //   msgfields.push('Contact Number');
        //   errorCnt++;
        //   break;
        case i === 'title' && !data[i]:
          msgfields.push('Title');
          errorCnt++;
          break;
        case i === 'services' && data[i].length === 0:
          msgfields.push('Services Required');
          errorCnt++;
          break;
        case i === 'industry' && data[i].length === 0:
          msgfields.push('Industry');
          errorCnt++;
          break;
        case i === 'start_date' && !data[i]:
          msgfields.push('Target Project Start');
          errorCnt++;
          break;
        case i === 'end_date' && !data[i]:
          msgfields.push('Target Project End');
          errorCnt++;
          break;
        case i === 'key_deliverables' && data[i].length === 0:
          msgfields.push('Key Deliverables');
          errorCnt++;
          break;
        case i === 'business_objective' && !data[i]:
          msgfields.push('Business Objective');
          errorCnt++;
          break;
        case i === 'business_action' && !data[i]:
          msgfields.push('Business action from research');
          errorCnt++;
          break;
        case i === 'background' && !data[i]:
          msgfields.push('Background');
          errorCnt++;
          break;

        default:
          break;
      }
    }
    return {
      error: errorCnt,
      fields: msgfields,
    };
  };

  const handleFileChange = (key, files) => {
    setFieldValue(key, files);
  };

  let { optionValues, optionLabels, optionMaps } = useMemo(() => {
    const optionValues = {};
    const optionLabels = {};
    const optionMaps = {};
    // for (const key in options) {
    //   optionValues[key] = getOptionValues(options[key]);
    //   optionLabels[key] = getOptionLabels(options[key]);
    // }
    for (const key in options) {
      switch (key) {
        case 'market_research':
        case 'analytics_research':
        case 'researcher_ml_operations':
          break;
        default: {
          optionValues[key] = getOptionValues(options[key]);
          optionLabels[key] = getOptionLabels(options[key]);
          break;
        }
      }
    }
    optionValues['business_needspace'] = [];
    optionLabels['business_needspace'] = {};
    optionMaps['business_needspace'] = [];

    return { optionValues, optionLabels, optionMaps };

    // return { optionValues, optionLabels };
  }, [options]);

  useEffect(() => {
    function setMaxLines() {
      setTextAreaMaxLine(getTextAreaMaxLine());
    }

    window.addEventListener('resize', setMaxLines);

    return function cleanup() {
      window.removeEventListener('resize', setMaxLines);
    };
  }, []);

  const getTechniquesOptions = useCallback(
    services_offered => {
      const servicesLabels = getOptionLabels(options['services_offered']);
      optionValues['business_needspace'] = [];
      optionLabels['business_needspace'] = [];
      optionMaps['business_needspace'] = [];
      let business_spec_options = [];
      let analyticsMaps = [],
        marketsMaps = [],
        operationsMaps = [];
      if (services_offered.includes('DATA SCIENCE/ML/AI')) {
        business_spec_options = [
          ...business_spec_options,
          ...options['analytics_research'],
        ];
        analyticsMaps = [
          {
            name: servicesLabels['2'],
            items: options['analytics_research'].map(item => item.id),
          },
        ];
      }

      if (services_offered.includes('INSIGHTS/MARKET RESEARCH')) {
        business_spec_options = [
          ...business_spec_options,
          ...options['market_research'],
        ];
        marketsMaps = [
          {
            name: servicesLabels['1'],
            items: options['market_research'].map(item => item.id),
          },
        ];
      }

      if (services_offered.includes('RESEARCH/ML OPERATIONS')) {
        business_spec_options = [
          ...business_spec_options,
          ...options['researcher_ml_operations'],
        ];
        operationsMaps = [
          {
            name: servicesLabels['11'],
            items: options['researcher_ml_operations'].map(item => item.id),
          },
        ];
      }

      optionValues['business_needspace'] = getOptionValues(
        business_spec_options
      );
      optionLabels['business_needspace'] = getOptionLabels(
        business_spec_options
      );

      optionMaps['business_needspace'] = [
        ...analyticsMaps,
        ...marketsMaps,
        ...operationsMaps,
      ];
    },
    [
      optionLabels,
      optionMaps,
      optionValues,
      options,
      setFieldValue,
      values.business_needspace,
    ]
  );

  useEffect(() => {
    if (options && isEdit && projectData && Object.keys(options).length > 0) {
      const services = projectData.services.map(item => +item.id);
      getTechniquesOptions(services);
    }
    // else if (isEdit && projectData) {
    //   const services = projectData?.services.map(item => item.name);
    //   setLoading(true);
    //   getTechniquesOptions(services);
    // }
  }, [options, isEdit, projectData]);

  useEffect(() => {
    if (isEdit && currentStep === 0 && Object.keys(options)?.length > 0) {
      let list = projectData?.services
        ? projectData?.services.map(_ => _.name)
        : [];
      setFieldValue(
        'business_needspace',
        projectData?.business_needspace
          ? projectData?.business_needspace.map(_ => _.id)
          : []
      );
      return getTechniquesOptions(list);
    } else if (
      duplicateDetails !== null &&
      currentStep === 1 &&
      Object.keys(options)?.length > 0
    ) {
      let list = duplicateDetails?.services
        ? duplicateDetails.services.map(_ => _.name)
        : [];
      setFieldValue(
        'business_needspace',
        duplicateDetails?.business_needspace
          ? duplicateDetails?.business_needspace.map(_ => _.id)
          : []
      );
      return getTechniquesOptions(list);
    }
  }, [options]);

  useEffect(() => {
    if (isEdit && projectData) {
      setFieldValue('title', projectData.title || '');
      setFieldValue(
        'business_needspace',
        projectData?.business_needspace
          ? projectData?.business_needspace.map(_ => _.id)
          : []
      );
      setFieldValue(
        'industry',
        projectData.industry.map(item => +item.id) || []
      );
      setFieldValue(
        'start_date',
        new Date(projectData.start_date).getTime() || ''
      );
      setFieldValue('end_date', new Date(projectData.end_date).getTime() || '');
      setFieldValue('actual_price', projectData.actual_price || 0);
      setFieldValue('description', projectData.description || '');
      setFieldValue('business_objective', projectData.business_objective || '');
      setFieldValue('research_objective', projectData.research_objective || '');
      setFieldValue('business_action', projectData.business_action || '');
      setFieldValue('background', projectData.background || '');
      setFieldValue(
        'countries_of_interest',
        projectData.countries_of_interest || []
      );
      setFieldValue(
        'additional_requests',
        projectData.additional_requests || ''
      );
      setFieldValue('key_questions', projectData.key_questions || '');
      setFieldValue('success_criteria', projectData.success_criteria || '');
      setFieldValue(
        'key_deliverables',
        Array.isArray(projectData?.key_deliverables)
          ? projectData?.key_deliverables?.length > 0 &&
              projectData?.key_deliverables?.map(item => item.id)
          : []
      );
      setFieldValue('project_milestone', projectData.project_milestone || '');
      setFieldValue(
        'services',
        projectData.services.map(item => item.name) || []
      );
      setFieldValue(
        'techniques',
        projectData.techniques.map(item => +item.id) || []
      );
      setFieldValue(
        'years_of_experience',
        projectData.years_of_experience || 0
      );
      setFieldValue(
        'reference_files',
        projectData.reference_files && projectData.reference_files.length > 0
          ? projectData.reference_files.map(item => {
              return {
                filename: item['filename'],
                url: item['url'],
                key: item['key'],
                new_upload: false,
              };
            })
          : []
      );
      setFieldValue(
        'research_mastery',
        projectData.research_mastery && projectData.research_mastery.id
          ? projectData.research_mastery.id
          : ''
      );
      setFieldValue(
        'analytics_mastery',
        projectData.analytics_mastery && projectData.analytics_mastery.id
          ? projectData.analytics_mastery.id
          : ''
      );
      setFieldValue('engagement', projectData.engagement);
      setFieldValue('education', projectData.education);
      setFieldValue(
        'industry_expertise',
        projectData?.industry_expertise
          ? getObjKey(STACKHOLDER_ITEM_LABELS, projectData?.industry_expertise)
          : ''
      );
      setFieldValue('technical_mastery', projectData.technical_mastery);
      setFieldValue('referral_network', projectData.referral_network);
      setFieldValue(
        'minimum_researcher_qualification',
        projectData?.minimum_researcher_qualification
          ? projectData.minimum_researcher_qualification
          : ''
      );
      setFieldValue('is_public', projectData.is_public);
      setFieldValue(
        'sizes_of_businesses_worked',
        projectData?.sizes_of_businesses_worked
          ? projectData?.sizes_of_businesses_worked
          : []
      );
      setFieldValue(
        'fortune_exp',
        projectData?.fortune_exp ? projectData?.fortune_exp : ''
      );
      setFieldValue(
        'other_key_deliverable',
        projectData?.other_key_deliverable
          ? projectData?.other_key_deliverable
          : ''
      );
    }
  }, [isEdit, projectData, setFieldValue]);

  const handleServiceChange = e => {
    setFieldValue('services', e.target.value);
    setFieldValue('business_needspace', []);
    getTechniquesOptions(e.target.value);
  };

  return (
    <>
      <ProjectFormContent>
        {currentStep === (isEdit ? -1 : 0) ? (
          <>
            <ProjectContentTitle>
              Step 1: Set up your Account
            </ProjectContentTitle>
            <ProjectContentDesc>
              Completing your client profile will help verify you represent a
              going business and give you access to Big Robin researcher/s best
              suited for your needs.
            </ProjectContentDesc>
            <FormRow>
              {/* <LinkedInPageUser /> */}
              <Input
                label="Company website/url"
                name="company_url"
                value={values.company_url}
                onChange={handleChange}
              />
            </FormRow>
            <FormRow>
              <Input
                label="First Name"
                name="first_name"
                value={values.first_name}
                onBlur={handleBlur}
                onChange={e => {
                  setFieldValue(e.target.name, e.target.value);
                }}
              />
              <Input
                label="Last Name"
                name="last_name"
                value={values.last_name}
                onBlur={handleBlur}
                onChange={e => {
                  setFieldValue(e.target.name, e.target.value);
                }}
              />
            </FormRow>
            <FormRow>
              <Input
                label="Street Address"
                name="address"
                value={values.address}
                onChange={handleChange}
              />
            </FormRow>
            <FormRow>
              <Input
                label="Street Address Line 2 (optional)"
                name="address_2"
                value={values.address_2}
                onChange={handleChange}
              />
            </FormRow>
            <FormRow>
              <Input
                label="City"
                name="city"
                value={values.city}
                onChange={handleChange}
              />
              <Input
                label="State"
                name="state"
                value={values.state}
                onChange={handleChange}
              />
              <Input
                label="Country"
                name="country"
                value={values.country}
                onChange={handleChange}
              />
            </FormRow>
            <FormRow>
              <Input
                label="Zip/Postal Code"
                name="postal_code"
                value={values.postal_code}
                onChange={handleChange}
              />
            </FormRow>
            <FormRow>
              <Input
                label="Billing Street Address"
                name="billing_address"
                value={values.billing_address}
                onChange={handleChange}
                disabled={values.is_same_billing_address}
                checkboxProps={{
                  label: 'Same as Street Address',
                  name: 'is_same_billing_address',
                  value: values.is_same_billing_address,
                  onChange: handleChange,
                }}
              />
            </FormRow>
            <FormRow>
              <Input
                label="Billing Street Address Line 2 (optional)"
                name="billing_address_2"
                value={values.billing_address_2}
                onChange={handleChange}
                disabled={values.is_same_billing_address}
              />
            </FormRow>
            <FormRow>
              <Input
                label="City"
                name="billing_city"
                value={values.billing_city}
                onChange={handleChange}
                disabled={values.is_same_billing_address}
              />
              <Input
                label="State"
                name="billing_state"
                value={values.billing_state}
                onChange={handleChange}
                disabled={values.is_same_billing_address}
              />
              <Input
                label="Country"
                name="billing_country"
                value={values.billing_country}
                onChange={handleChange}
                disabled={values.is_same_billing_address}
              />
            </FormRow>
            <FormRow>
              <Input
                label="Zip/Postal Code"
                name="billing_postal_code"
                value={values.billing_postal_code}
                onChange={handleChange}
                disabled={values.is_same_billing_address}
              />
            </FormRow>
            <FormRow>
              <Column>
                <Input
                  label="Company Affiliation"
                  name="company_affiliation"
                  value={values.company_affiliation}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                <CPCheckbox
                  label="This is a company account"
                  name="is_company_account"
                  value={values.is_company_account}
                  onChange={handleChange}
                />
              </Column>
              <Input
                label="Contact No."
                name="contact_number"
                value={values.contact_number}
                onBlur={handleBlur}
                onChange={e => {
                  setFieldValue(e.target.name, e.target.value);
                }}
              />
              <Input
                label="Contact Email"
                name="contact_email"
                value={values.contact_email}
                onChange={handleChange}
              />
            </FormRow>

            {values.accounts.map((account, index) => {
              return (
                <FormRow key={index}>
                  <Input
                    label={`Account Alias ${index === 0 ? '(Primary)' : ''}`}
                    name={`account[${index}]`}
                    value={account}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    style={{ maxWidth: '300px' }}
                  />
                  <LeftButton
                    outline
                    style={{
                      height: '50px',
                      maxWidth: '230px',
                      top: '35px',
                      position: 'relative',
                    }}
                    onClick={() => {
                      const arr = values.accounts;
                      const start = arr.indexOf(values.accounts[index]);
                      if (index > -1) arr.splice(start, 1);
                      setFieldValue('account', arr);
                    }}
                  >
                    Remove Account
                  </LeftButton>
                </FormRow>
              );
            })}

            {/* <FormRow>
              <Input
                label={`Account Alias ${
                  values.accounts.length === 0 ? '(Primary)' : ''
                }`}
                name="new_account"
                onBlur={handleBlur}
                onChange={handleChange}
                style={{ maxWidth: '300px' }}
                value={values.new_account}
              />
              <LeftButton
                outline
                style={{
                  height: '50px',
                  maxWidth: '220px',
                  top: '35px',
                  position: 'relative',
                }}
                onClick={() => {
                  if (values.new_account !== '') {
                    values.accounts.push(values.new_account);
                    setFieldValue('new_account', '');
                  }
                }}
              >
                + New Account
              </LeftButton>
            </FormRow> */}
          </>
        ) : currentStep === (isEdit ? 0 : 1) ? (
          <>
            <ProjectContentTitle>
              {isEdit
                ? 'Edit Project Description'
                : 'Step 1: Describe your project'}
            </ProjectContentTitle>
            {/* <ProjectContentDesc>
                Please fill in this Project Briefing form or{' '}
                <span onClick={() => {}}>upload your own</span> to get started.
              </ProjectContentDesc> */}
            <ProjectContentDesc />
            <FormRow>
              <div ref={projectTitle}>
                <Input
                  label="Project Title"
                  name="title"
                  value={values.title}
                  onBlur={handleBlur}
                  onChange={e => {
                    setFieldValue(e.target.name, e.target.value);
                  }}
                  error={isError && !values?.title.trim()}
                  errorMessage={errorMessage}
                  required={true}
                />
              </div>
            </FormRow>
            <FormRow>
              <FormRow>
                <div ref={requiredExpertise}>
                  <DropdownWithName
                    required={true}
                    label="Required Expertise"
                    name="services"
                    value={values.services}
                    error={isError && !values?.services.length > 0}
                    errorMessage={errorMessage}
                    onChange={e => {
                      handleServiceChange(e);
                    }}
                    serviceOption={serviceOption || []}
                    onBlur={handleBlur}
                    options={optionValues.services_offered || []}
                    optionLabels={optionLabels.services_offered || []}
                    multi
                  />
                </div>

                {options && (
                  <div>
                    <SpecializationDropdown
                      // required={true}
                      style={{ gridArea: 'business_specialization' }}
                      label="Specialization"
                      name="business_needspace"
                      value={values.business_needspace}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      options={optionValues.business_needspace || []}
                      optionLabels={optionLabels.business_needspace || {}}
                      optionMap={optionMaps.business_needspace || []}
                    />
                  </div>
                )}
              </FormRow>
              <div ref={industry}>
                <Dropdown
                  required={true}
                  label="Industry"
                  name="industry"
                  value={values.industry}
                  onChange={e => {
                    setFieldValue(e.target.name, e.target.value);
                  }}
                  error={isError && !values?.industry.length > 0}
                  errorMessage={errorMessage}
                  onBlur={handleBlur}
                  options={INDUSTRY_VALUES || []}
                  optionLabels={INDUSTRY_LABELS || []}
                  multi
                />
              </div>
            </FormRow>
            <FormRow>
              <DateRow>
                <div ref={projectStart}>
                  <Datepicker
                    required={true}
                    label="Target Project Start"
                    name="start_date"
                    value={values.start_date}
                    onChange={e => {
                      setFieldValue(e.target.name, e.target.value);
                      setFieldValue('end_date', e.target.value);
                    }}
                  />
                  <div>
                    {isError && !values?.start_date ? (
                      <p
                        style={{
                          color: 'red',
                          marginTop: '4px',
                          fontSize: '14px',
                          textAlign: 'left',
                        }}
                      >
                        {errorMessage}
                      </p>
                    ) : null}
                  </div>
                </div>
                <div ref={projectEnd}>
                  <Datepicker
                    required={true}
                    label="Target Project End"
                    name="end_date"
                    value={values.end_date}
                    after={values.start_date}
                    onChange={e => {
                      setFieldValue(e.target.name, e.target.value);
                    }}
                  />
                  <div>
                    {isError && !values?.end_date ? (
                      <p
                        style={{
                          color: 'red',
                          marginTop: '4px',
                          fontSize: '14px',
                          textAlign: 'left',
                        }}
                      >
                        {errorMessage}
                      </p>
                    ) : null}
                  </div>
                </div>
              </DateRow>

              <Input
                label="Budget, if known (USD)"
                name="actual_price"
                // type="number"
                min={0}
                pattern={6}
                value={values.actual_price}
                onChange={e => {
                  setFieldValue(
                    e.target.name,
                    Number(e.target.value.replace(/[^0-9]/g, ''))
                  );
                }}
                onBlur={handleBlur}
              />
            </FormRow>
            <ProjectContentTitle style={{ marginBottom: '10px' }}>
              Project Brief
            </ProjectContentTitle>
            <FormRow>
              <div ref={projectBg}>
                <Textarea
                  name="background"
                  label="Background"
                  maxCharacters={2000}
                  rows={textAreaMaxLine}
                  value={values.background}
                  onChange={e => {
                    setFieldValue(e.target.name, e.target.value);
                  }}
                  error={isError && !values?.background.trim()}
                  errorMessage={errorMessage}
                  required={true}
                />
              </div>
            </FormRow>
            <FormRow>
              <div ref={businessObjective}>
                <Textarea
                  name="business_objective"
                  label="Business Objective"
                  placeHolder="To..."
                  rows={textAreaMaxLine}
                  maxCharacters={2000}
                  value={values.business_objective}
                  onChange={e => {
                    setFieldValue(e.target.name, e.target.value);
                  }}
                  error={isError && !values?.business_objective.trim()}
                  errorMessage={errorMessage}
                  required={true}
                />
              </div>
              <div ref={businessAction}>
                <Textarea
                  name="business_action"
                  label="Business action from research"
                  placeHolder="e.g. Launch product that promises maximum revenue growth vs. competition within 6 months."
                  rows={textAreaMaxLine}
                  maxCharacters={2000}
                  value={values.business_action}
                  onChange={e => {
                    setFieldValue(e.target.name, e.target.value);
                  }}
                  error={isError && !values?.business_action.trim()}
                  errorMessage={errorMessage}
                  required={true}
                />
              </div>
            </FormRow>
            <FormRow>
              <Textarea
                name="research_objective"
                label="Research Objective, if known"
                placeHolder="e.g. To prioritize and understand drivers of preference for new product"
                maxCharacters={2000}
                rows={textAreaMaxLine}
                value={values.research_objective}
                onChange={e => {
                  // setResearchObjErr(false);
                  setFieldValue(e.target.name, e.target.value);
                }}
                // error={researchObjErr}
                // required={true}
              />

              <div ref={keyDeliverables}>
                <Dropdown
                  label="Key Deliverables"
                  name="key_deliverables"
                  value={values.key_deliverables}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  options={optionValues?.method_of_work || []}
                  optionLabels={optionLabels?.method_of_work || []}
                  multi
                  error={isError && !values?.key_deliverables.length > 0}
                  errorMessage={errorMessage}
                  required={true}
                />

                {values?.key_deliverables?.length > 0 &&
                values?.key_deliverables?.includes(11) ? (
                  <div style={{ marginTop: '10px' }}>
                    <Input
                      name="other_key_deliverable"
                      label="Other"
                      placeHolder={'Please describe the other key deliverables'}
                      pattern={50}
                      value={values.other_key_deliverable}
                      onChange={handleChange}
                      error={
                        isError &&
                        values?.key_deliverables?.includes(11) &&
                        !values?.other_key_deliverable.trim()
                      }
                      errorMessage={errorMessage}
                    />
                  </div>
                ) : (
                  ''
                )}
              </div>
              {/* <Textarea
                name="key_deliverables"
                label="Key Deliverables"
                placeHolder={`1.\n2.\n3.`}
                rows={textAreaMaxLine}
                maxCharacters={2000}
                value={values.key_deliverables}
                onChange={handleChange}
              /> */}
            </FormRow>
            <FormRow>
              <Textarea
                name="key_questions"
                label="Key Information/Questions, if known"
                placeHolder={`1.\n2.\n3.`}
                rows={textAreaMaxLine}
                maxCharacters={2000}
                value={values.key_questions}
                onChange={handleChange}
              />
              <Textarea
                name="success_criteria"
                label="Success Criteria, if any"
                placeHolder={`1.\n2.\n3.`}
                rows={textAreaMaxLine}
                maxCharacters={2000}
                value={values.success_criteria}
                onChange={handleChange}
              />
            </FormRow>
            <FormRow>
              <div>
                <LabelLayout>
                  <label>Countries of Interest</label>
                </LabelLayout>
                <Autocomplete
                  id={'countries_of_interest'}
                  multiple
                  getOptionLabel={option => option}
                  name="countries_of_interest"
                  options={countryObjList}
                  onChange={(e, newValue) => {
                    setFieldValue('countries_of_interest', newValue);
                  }}
                  value={values.countries_of_interest}
                  renderInput={params => (
                    <TextField
                      {...params}
                      variant="standard"
                      placeholder="Country"
                      margin="normal"
                      fullWidth
                    />
                  )}
                />
              </div>
              <div>{''}</div>
            </FormRow>
          </>
        ) : currentStep === (isEdit ? 1 : 2) ? (
          <>
            <ProjectContentTitle>
              {isEdit
                ? 'Researcher Requirements'
                : 'Step 2: Researcher Requirements'}
            </ProjectContentTitle>
            {isEdit ? (
              <ProjectContentDesc />
            ) : (
              <ProjectContentDesc>
                Providing specific deliverables and researcher requirements will
                help find the right Robin Researcher match for your needs. If
                you don’t know, not to worry, we’ll match you with someone we
                know will be able to help
              </ProjectContentDesc>
            )}

            <FormRow>
              <Dropdown
                name="technical_mastery"
                label="Functional & Technical Mastery, Fluency in Insights and Analytics (1-5)"
                value={values.technical_mastery}
                onBlur={handleBlur}
                onChange={handleChange}
                options={[1, 2, 3, 4, 5]}
                optionLabels={['0', '1', '2', '3', '4']}
                style={{ maxHeight: 80 }}
              />
              <div>{''}</div>
            </FormRow>
            <div style={{ marginBottom: '10px', fontSize: '16px' }}>
              Competency:{' '}
            </div>
            <FormRow>
              <Dropdown
                name="industry_expertise"
                label="Highest Stakeholder Management Experience"
                value={values.industry_expertise}
                onBlur={handleBlur}
                onChange={handleChange}
                options={STACKHOLDER_ITEM_VALUES || []}
                optionLabels={STACKHOLDER_ITEM_LABELS || []}
                style={{ maxHeight: 80 }}
              />
              <Input
                label="Years of Experience"
                name="years_of_experience"
                // type="number"
                min={0}
                pattern={2}
                value={values.years_of_experience}
                onChange={e => {
                  setFieldValue(
                    e.target.name,
                    Number(e.target.value.replace(/[^0-9]/g, ''))
                  );
                }}
                onBlur={handleBlur}
                errorMessage={
                  'Years of experience should be less than 60 years'
                }
              />
            </FormRow>
            <div style={{ marginBottom: '10px', fontSize: '16px' }}>
              Domain Experience:{' '}
            </div>
            <FormRow>
              <Dropdown
                name="sizes_of_businesses_worked"
                label="Size of Businesses Worked"
                value={values.sizes_of_businesses_worked}
                onBlur={handleBlur}
                onChange={handleChange}
                options={SIZES_OF_BUSINESSES_WORKED_VALUES || []}
                optionLabels={SIZES_OF_BUSINESSES_WORKED_LABELS || []}
                style={{ maxHeight: 80 }}
                multi
              />

              <Dropdown
                name="fortune_exp"
                label="Work Experience"
                value={values.fortune_exp}
                onBlur={handleBlur}
                onChange={handleChange}
                options={[
                  'Worked in a fortune 500 company',
                  'Not worked in a fortune 500 company',
                  'Any',
                ]}
                optionLabels={[
                  'Worked in a fortune 500 company',
                  'Not worked in a fortune 500 company',
                  'Any',
                ]}
                style={{ maxHeight: 80 }}
              />
            </FormRow>

            <FormRow>
              <Dropdown
                name="minimum_researcher_qualification"
                label="Relevant higher education"
                value={values.minimum_researcher_qualification}
                onBlur={handleBlur}
                onChange={handleChange}
                options={EDUCATION_ITEM_VALUES || []}
                optionLabels={EDUCATION_ITEM_LABELS || []}
                style={{ maxHeight: 80 }}
              />
              {/* <div>{''}</div> */}
              <FileInput
                name="reference_files"
                label="References/Other files"
                value={values.reference_files}
                onChange={files => {
                  handleFileChange('reference_files', files);
                }}
                path="/reference_files"
                multiple={true}
                disabled={values?.reference_files.length >= 5}
              />
            </FormRow>
            <FormRow>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div style={{ display: 'inline-flex', marginBottom: '15px' }}>
                  <RPCheckbox
                    type="radio"
                    value={1}
                    label=""
                    name={'is_public'}
                    checked={values.is_public === 1}
                    onChange={() => setFieldValue('is_public', 1)}
                    onBlur={handleBlur}
                  />
                  <p style={{ marginLeft: '12px' }}>
                    RFP open to all Big Robin Researchers
                  </p>
                </div>
                <div style={{ display: 'inline-flex' }}>
                  <RPCheckbox
                    type="radio"
                    value={2}
                    label=""
                    name={'is_public'}
                    checked={values.is_public === 0}
                    onChange={() => setFieldValue('is_public', 0)}
                    onBlur={handleBlur}
                  />
                  <p style={{ marginLeft: '12px' }}>
                    RFP by your Invitation only
                  </p>
                </div>
              </div>
            </FormRow>
          </>
        ) : null}
        <PageButtonsLayout>
          <PageButtonsGroup>
            {(isEdit ? (
              currentStep >= 1
            ) : (
              currentStep > 1
            )) ? (
              <LeftButton
                onClick={() => {
                  handleStepChange(currentStep - 1);
                }}
                backgroundColor="#65637b"
              >
                BACK
              </LeftButton>
            ) : null}
          </PageButtonsGroup>

          <PageButtonsGroup>
            {/* <RightButton outline onClick={handleSubmit}>
                SAVE FOR LATER
              </RightButton> */}
            <RightButton
              onClick={() => {
                // setIsComplete(true);
                // handleSubmit();
                handleProjectPreview();
              }}
            >
              PREVIEW
            </RightButton>
            <RightButton
              onClick={() => {
                handleSubmit(); // Early data submit as draft
                setShowSaveLaterModal(true);
              }}
            >
              SAVE FOR LATER
            </RightButton>
            {currentStep < (isEdit ? 1 : 2) ? (
              <RightButton
                onClick={() => {
                  handleProjectForm();
                }}
              >
                NEXT
              </RightButton>
            ) : isDisable ? (
              <RightButton
                disabled={isDisable}
                onClick={() => {
                  if (isDisable) {
                    setIsDisable(true);
                    // setIsComplete(true);
                    // handleSubmit();
                  } else {
                    return false;
                  }
                }}
              >
                <FaSpinner className="spin" />
                PROCESSING...
              </RightButton>
            ) : (
              <RightButton
                disabled={isDisable}
                onClick={() => {
                  if (!isDisable) {
                    setIsDisable(true);
                    setIsComplete(true);
                    handleSubmit();
                  } else {
                    return false;
                  }
                }}
              >
                {isDisable ? (
                  <>
                    <FaSpinner className="spin" />
                    PROCESSING...
                  </>
                ) : (
                  'PUBLISH'
                )}
              </RightButton>
            )}
          </PageButtonsGroup>
        </PageButtonsLayout>
      </ProjectFormContent>
      {isDisable ? <LoadingIndicator /> : null}
    </>
  );
};
