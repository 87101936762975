import Content from 'components/Content';
import styled from 'styled-components/macro';

export const AgreementContent = styled(Content)`
  margin: 6rem 8rem;

  @media (max-width: 900px) {
    margin: 4rem 0rem;
  }
`;

export const AgreementLayout = styled.article`
  padding: 8rem 8rem;
  background-color: white;

  @media (max-width: 900px) {
    padding: 6rem 4rem;
  }
`;

export const AgreementTitle = styled.h1`
  font-size: 2.4rem;
  text-align: center;
  margin-bottom: 4rem;

  @media (max-width: 900px) {
    font-size: 3rem;
  }
`;

export const AgreementSubTitle = styled.h5`
  font-size: 1.8rem;
  margin-top: 2rem;
  margin-bottom: 2rem;

  @media (max-width: 900px) {
    font-size: 2.4rem;
  }
`;

export const AgreementTextBlock = styled.p`
  font-size: 1.6rem;
  text-align: justify;
  margin-bottom: 2rem;
  line-height: 150%;

  @media (max-width: 900px) {
    font-size: 2rem;
  }

  strong {
    font-weight: bold;
  }

  em {
    text-decoration: italic;
  }

  a {
    color: #ff9e08;
  }
`;

export const AgreementBlockTitle = styled.h6`
  font-size: 1.6rem;
  font-weight: normal;

  @media (max-width: 900px) {
    font-size: 2rem;
  }
`;
