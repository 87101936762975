import Content, { MiddleContainer } from 'components/Content';
import styled from 'styled-components/macro';

export const AboutContent = styled(Content)`
  padding: 10rem 0;
  overflow-x: hidden;

  @media (max-width: 900px) {
    padding: 8rem 0;
  }

  ${MiddleContainer} {
    display: flex;
    align-items: center;

    @media (max-width: 900px) {
      flex-direction: column;
    }
  }
`;

export const TextContainer = styled.div`
  flex: 1;
  color: #4b4c5e;
  z-index: 2;

  h2 {
    font-size: 3.6rem;
    margin-bottom: 2rem;
  }

  p {
    font-size: 2.4rem;
    margin-bottom: 2rem;

    @media (max-width: 900px) {
      font-size: 2.5rem;
      margin-bottom: 2.5rem;
    }
  }
`;

export const BulletPointLayout = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 2rem;

  @media (max-width: 900px) {
    margin-bottom: 3rem;
  }

  &:last-child {
    margin-bottom: 0;
  }

  span {
    margin-left: 1rem;
    font-size: 2rem;

    @media (max-width: 900px) {
      font-size: 2.4rem;
    }
  }
`;

export const ImageContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  position: relative;

  @media (max-width: 900px) {
    margin-top: 8rem;
  }
`;

export const SampleImage = styled.img`
  height: 40rem;
  z-index: 1;

  @media (max-width: 900px) {
    height: auto;
    width: 75vw;
  }
`;

export const HABackgroundImage = styled.img`
  height: 35rem;
  position: absolute;
  top: -7rem;
  right: -30rem;
  z-index: 0;
  transform: rotateY(180deg);

  @media (max-width: 900px) {
    height: 70vw;
    right: -66vw;
    top: -16vw;
  }
`;
