import { createGlobalStyle } from 'styled-components/macro';

const GlobalStyle = createGlobalStyle`
  html {
    @media (max-width: 599px) {
      font-size: 6px;
    }
    @media (min-width: 600px) {
      font-size: 8px;
    }
    @media (min-width: 900px) {
      font-size: 9px;
    }
    @media (min-width: 1200px) {
      font-size: 10px;
    }
    @media (min-width: 1800px) {
      font-size: 12px;
    }

  }

  body {
    margin: 0;
    font-family: 'europa', sans-serif;
    background-color: #F7F7F8;
    font-size: 1.6rem;
    color: #4B4C5E;
  }

  * {
    ::-webkit-scrollbar {
      width: 0.5rem;
    }
    ::-webkit-scrollbar-track {
      background-color: #dadadf;
      opacity: 15%;
      border-radius: 5px 5px;
    }
    ::-webkit-scrollbar-thumb {
      background-color: #ff9e08;
      border-radius: 5px 5px;
    }
    ::-webkit-scrollbar-thumb:hover {
      background-color: #ff9e08;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    margin: 0;
  }

  h1 {
    font-size: 5rem;
  }

  h2 {
    font-size: 4rem;
  }

  h3 {
    font-size: 3.5rem;
  }

  h4 {
    font-size: 3rem;
  }

  h5 {
    font-size: 2.5rem;
  }

  h6 {
    font-size: 2rem;
  }

  input {
    border: 0;
    outline: none;
    background-color: transparent;
  }

  textarea {
    font-family: 'europa', sans-serif;
  }

  button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    outline: none;
    padding: 0;

    transition: filter 0.2s ease;

    &:disabled {
      cursor: default;
      filter: grayscale(0.25);
    }
  }

  #root {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }

  .spin {
    animation: spin 2s infinite linear;
  }
  .react-datepicker{
    font-size: 1.5rem !important;
  }

  .react-datepicker-year-header{
    font-size: 1.5rem !important;
  }

  .subscription-card .react-datepicker-wrapper {
    border: 1px solid #65637b3c;
    border-radius: 8px;
  }

  .errorBox .react-datepicker-wrapper {
    border: 1px solid #ef5350 !important;
  }

  @media (max-width: 900px) {
    .subscription-card .react-datepicker-wrapper {
      // height: 50.5%;
      margin-top: 0.2rem;
    }
    label {
      font-size: 2rem;
    }
  }

  .subscription-card .react-datepicker__input-container {
    position: relative;
    display: inline-block;
    width: 100%;
    padding: 0.8rem 1rem;
  }

  .subscription-card .react-datepicker__input-container input {
    font-size: 1.6rem;
  }

  .subscription-card .react-datepicker__tab-loop * {
    font-size: 1.6rem !important;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(359deg);
    }
  }
`;

export default GlobalStyle;
