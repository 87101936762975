import useBodyScrollLock from 'hooks/useBodyScrollLock';
import React, { useRef } from 'react';
import { createPortal } from 'react-dom';
import { ModalContainer, OverlayBackground } from './styled';

const Modal = ({ visible, setVisible, children, className, persistent }) => {
  const rootRef = useRef(document.getElementById('root'));

  useBodyScrollLock(visible);

  const childrenContainerRef = useRef(null);

  return createPortal(
    <OverlayBackground
      className={className}
      visible={visible}
      onClick={event => {
        if (!persistent)
          if (
            childrenContainerRef.current &&
            !childrenContainerRef.current.contains(event.target)
          ) {
            setVisible(false);
          }
      }}
    >
      <ModalContainer visible={visible} ref={childrenContainerRef}>
        {children}
      </ModalContainer>
    </OverlayBackground>,
    rootRef.current
  );
};

export default Modal;
