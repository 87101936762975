import Button from 'components/Button';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { PageContent } from './styled';

export const ProjectPaymentConfirmed = () => {
  const history = useHistory();
  const continueToMessages = () => {
    history.push('/messages');
  };

  return (
    <PageContent>
      <h1 style={{ fontSize: '3rem', marginBottom: 20 }}>
        {' '}
        Payment Successful! You may now go back to your messages.{' '}
      </h1>
      <Button fill onClick={() => continueToMessages()} style={{ width: 300 }}>
        Proceed to Messages
      </Button>
    </PageContent>
  );
};
