import CheckboxGroup from 'components/CheckboxGroup';
import DropdownPopup from 'components/DropdownPopup';
import { Row, Column } from 'components/Layout';
import { useFormik } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import {
  FaBookOpen,
  FaDollarSign,
  FaLanguage,
  FaRegClock,
  FaStar,
  FaIndustry,
  FaMedal,
  FaUserTie,
} from 'react-icons/fa';
import {
  CheckboxRow,
  CheckboxValue,
  FilterTitle,
  PriceFilterDash,
  PriceInput,
  // RatingResearcherCount,
  RatingValue,
  ResearcherFilterContainer,
  ResearcherFilterRow,
  ResearcherFilterSide,
  ShowAllFilterButton,
  SpecializationDropdownPopup,
  SpecializationDropdownPopupRow,
  ShowMore,
  TwoColumnCheckboxGroup,
} from './styled';

const initialValues = {
  minPrice: 0,
  maxPrice: 0,
  ratings: [],
  industry: [],
  specializations: [],
  mastery: [],
  expertise: [],
  responseTime: [],
  language: [],
};

const LANGUAGES = [
  'English',
  'Arabic',
  'German',
  'Chinese',
  'Japanese',
  'Russian',
  'Spanish',
  'Italian',
  'French',
  'Korean',
  'Portuguese',
  'Hindi',
  'Thai',
  'Greek',
  'Hebrew',
  'Malay',
  'Polish',
  'Punjabi',
  'Tagalog',
  'Danish',
];

const ResearcherFilter = ({
  className,
  onChange,
  onApply,
  onClear,
  hasSelectedSpecialization,
  hasSelectedIndustry,
  options,
}) => {
  const tempFormik = useFormik({ initialValues, onSubmit: () => {} });
  const formik = useFormik({ initialValues, onSubmit: () => {} });
  const [showAllFilters, setShowAllFilters] = useState(false);

  useEffect(() => {
    if (hasSelectedSpecialization) {
      tempFormik.setFieldValue('specializations', [
        ...tempFormik.values.specializations,
        hasSelectedSpecialization.toString(),
      ]);
      formik.setFieldValue('specializations', [
        ...formik.values.specializations,
        hasSelectedSpecialization.toString(),
      ]);
    }
    if (hasSelectedIndustry) {
      tempFormik.setFieldValue('industry', [
        ...tempFormik.values.industry,
        hasSelectedIndustry.toString(),
      ]);
      formik.setFieldValue('industry', [
        ...formik.values.industry,
        hasSelectedIndustry.toString(),
      ]);
    }
  }, []);

  const [languageOptions, setLanguageOptions] = useState(
    LANGUAGES.slice(0, 10)
  );

  const handleChangeVisibility = useCallback(
    names => {
      return (isShowingMenu, { submitted }) => {
        if (!isShowingMenu && !submitted) {
          for (const name of names) {
            if (tempFormik.values[name] !== formik.values[name]) {
              tempFormik.setFieldValue(name, formik.values[name]);
            }
          }
        }
      };
    },
    [formik.values, tempFormik]
  );

  const handleApply = useCallback(
    names => {
      return () => {
        for (const name of names) {
          formik.setFieldValue(name, tempFormik.values[name]);
          onChange(name, tempFormik.values[name]);
        }
        onApply();
      };
    },
    [formik, onApply, onChange, tempFormik.values]
  );
  const handleClear = useCallback(
    names => {
      return () => {
        for (const name of names) {
          tempFormik.setFieldValue(name, initialValues[name]);
          formik.setFieldValue(name, initialValues[name]);
          //onChange(name, initialValues[name]);
          onClear(name);
        }
      };
    },
    [formik, onClear, tempFormik]
  );

  return (
    <ResearcherFilterContainer className={className}>
      <FilterTitle>Filter by:</FilterTitle>
      <Column>
        <ResearcherFilterRow>
          <DropdownPopup
            icon={<FaDollarSign />}
            isHighlighted={
              formik.values.minPrice > 0 || formik.values.maxPrice > 0
            }
            label="Price"
            onChangeVisibility={handleChangeVisibility([
              'minPrice',
              'maxPrice',
            ])}
            onApply={handleApply(['minPrice', 'maxPrice'])}
            onClear={handleClear(['minPrice', 'maxPrice'])}
          >
            <Row wrap="nowrap">
              <PriceInput
                label="Minimum Price"
                type="number"
                name="minPrice"
                leftLabel={<FaDollarSign />}
                value={tempFormik.values.minPrice}
                onChange={e => {
                  let val = e.target.value;
                  // tempFormik.setFieldValue('maxPrice', 0);
                  if (Number(val) && val > -1) {
                    tempFormik.setFieldValue('minPrice', val);
                    onChange('minPrice', Number(val), e.target.checked);
                  } else {
                    return false;
                  }
                }}
              />
              <PriceFilterDash>—</PriceFilterDash>
              <PriceInput
                label="Maximum Price"
                type="number"
                name="maxPrice"
                leftLabel={<FaDollarSign />}
                value={tempFormik.values.maxPrice}
                onChange={e => {
                  let val = e.target.value;
                  if (Number(val) && val > -1) {
                    tempFormik.setFieldValue('maxPrice', val);
                    onChange('maxPrice', Number(val), e.target.checked);
                  } else {
                    return false;
                  }
                }}
              />
            </Row>
          </DropdownPopup>
          <DropdownPopup
            icon={<FaStar />}
            label={`Ratings (${formik.values.ratings.length})`}
            isHighlighted={formik.values.ratings.length > 0}
            onChangeVisibility={handleChangeVisibility(['ratings'])}
            onApply={handleApply(['ratings'])}
            onClear={handleClear(['ratings'])}
          >
            <CheckboxGroup
              options={['5', '4', '3', '2', '1']}
              optionLabels={option => {
                return (
                  <CheckboxRow wrap="nowrap" alignItems="center">
                    <FaStar />
                    <RatingValue>{option}</RatingValue>
                    {/* <RatingResearcherCount>
                      ({Math.floor(Math.random() * 10)} researchers)
                    </RatingResearcherCount> */}
                  </CheckboxRow>
                );
              }}
              name="ratings"
              value={tempFormik.values.ratings}
              onChange={event => {
                tempFormik.handleChange(event);
                onChange('ratings', event.target.value, event.target.checked);
              }}
            />
          </DropdownPopup>
          <DropdownPopup
            icon={<FaIndustry />}
            label={`Industry (${formik.values.industry.length})`}
            isHighlighted={formik.values.industry.length > 0}
            onChangeVisibility={handleChangeVisibility(['industry'])}
            onApply={handleApply(['industry'])}
            onClear={handleClear(['industry'])}
          >
            <CheckboxGroup
              options={options.industry ? options.industry : []}
              optionLabels={option => (
                <span style={{ whiteSpace: 'pre' }}>{option}</span>
              )}
              name="industry"
              value={tempFormik.values.industry}
              // onChange={tempFormik.handleChange}
              onChange={event => {
                tempFormik.handleChange(event);
                onChange('industry', event.target.value, event.target.checked);
              }}
            />
          </DropdownPopup>
          <SpecializationDropdownPopup
            icon={<FaBookOpen />}
            // disabled={hasSelectedSpecialization}
            label={`Specialization (${formik.values.specializations.length})`}
            isHighlighted={formik.values.specializations.length > 0}
            onChangeVisibility={handleChangeVisibility(['specializations'])}
            onApply={handleApply(['specializations'])}
            onClear={handleClear(['specializations'])}
          >
            <SpecializationDropdownPopupRow>
              <Column>
                <h6>Market Research</h6>
                <CheckboxGroup
                  options={
                    options.market_research ? options.market_research : []
                  }
                  optionLabels={option => (
                    <span style={{ whiteSpace: 'pre' }}>{option}</span>
                  )}
                  name="specializations"
                  value={tempFormik.values.specializations}
                  // onChange={tempFormik.handleChange}
                  onChange={event => {
                    tempFormik.handleChange(event);
                    onChange(
                      'specializations',
                      event.target.value,
                      event.target.checked
                    );
                  }}
                />
              </Column>
              <Column>
                <h6>Data Science/AI/ML</h6>
                <CheckboxGroup
                  options={
                    options.analytics_research ? options.analytics_research : []
                  }
                  optionLabels={option => (
                    <span style={{ whiteSpace: 'pre' }}>{option}</span>
                  )}
                  name="specializations"
                  value={tempFormik.values.specializations}
                  // onChange={tempFormik.handleChange}
                  onChange={event => {
                    tempFormik.handleChange(event);
                    onChange(
                      'specializations',
                      event.target.value,
                      event.target.checked
                    );
                  }}
                />
              </Column>
              <Column>
                <h6>Research/ML Operations</h6>
                <CheckboxGroup
                  options={
                    options.researcher_ml_operations
                      ? options.researcher_ml_operations
                      : []
                  }
                  optionLabels={option => (
                    <span style={{ whiteSpace: 'pre' }}>{option}</span>
                  )}
                  name="specializations"
                  value={tempFormik.values.specializations}
                  // onChange={tempFormik.handleChange}
                  onChange={event => {
                    tempFormik.handleChange(event);
                    onChange(
                      'specializations',
                      event.target.value,
                      event.target.checked
                    );
                  }}
                />
              </Column>
            </SpecializationDropdownPopupRow>
          </SpecializationDropdownPopup>
          <DropdownPopup
            icon={<FaMedal />}
            label={`Mastery (${formik.values.mastery.length})`}
            isHighlighted={formik.values.mastery.length > 0}
            onChangeVisibility={handleChangeVisibility(['mastery'])}
            onApply={handleApply(['mastery'])}
            onClear={handleClear(['mastery'])}
          >
            <CheckboxGroup
              options={['5', '4', '3', '2', '1']}
              optionLabels={option => {
                return (
                  <CheckboxRow wrap="nowrap" alignItems="center">
                    <FaMedal />
                    <RatingValue>{option}</RatingValue>
                  </CheckboxRow>
                );
              }}
              name="mastery"
              value={tempFormik.values.mastery}
              onChange={event => {
                tempFormik.handleChange(event);
                onChange('mastery', event.target.value, event.target.checked);
              }}
            />
          </DropdownPopup>
          <DropdownPopup
            icon={<FaUserTie />}
            label={`Expertise (${formik?.values?.expertise.length || 0})`}
            isHighlighted={formik?.values?.expertise.length > 0}
            onChangeVisibility={handleChangeVisibility(['expertise'])}
            onApply={handleApply(['expertise'])}
            onClear={handleClear(['expertise'])}
          >
            <CheckboxGroup
              options={[
                'DATA SCIENCE/ML/AI',
                'INSIGHTS/MARKET RESEARCH',
                'RESEARCH/ML OPERATIONS',
              ]}
              optionLabels={option => {
                return (
                  <CheckboxRow wrap="nowrap" alignItems="center">
                    <RatingValue>{option}</RatingValue>
                    {/* <RatingResearcherCount>
                      ({Math.floor(Math.random() * 10)} researchers)
                    </RatingResearcherCount> */}
                  </CheckboxRow>
                );
              }}
              name="expertise"
              value={tempFormik.values.expertise}
              onChange={event => {
                tempFormik.handleChange(event);
                onChange('expertise', event.target.value, event.target.checked);
              }}
            />
          </DropdownPopup>
        </ResearcherFilterRow>
        {showAllFilters ? (
          <ResearcherFilterRow>
            <DropdownPopup
              icon={<FaRegClock />}
              label={`Response Time (${formik.values.responseTime.length})`}
              isHighlighted={formik.values.responseTime.length > 0}
              onChangeVisibility={handleChangeVisibility(['responseTime'])}
              onApply={handleApply(['responseTime'])}
              onClear={handleClear(['responseTime'])}
            >
              <CheckboxGroup
                options={['14400', '28800', '43200', '86400', '172800']}
                optionLabels={option => {
                  const d = Number(option);
                  var h = Math.floor(d / 3600);
                  var hDisplay =
                    h > 0 ? h + (h === 1 ? ' hour ' : ' hours ') : '';

                  return <CheckboxValue>Within {hDisplay}</CheckboxValue>;
                }}
                name="responseTime"
                value={tempFormik.values.responseTime}
                onChange={event => {
                  tempFormik.handleChange(event);
                  onChange(
                    'responseTime',
                    event.target.value,
                    event.target.checked
                  );
                }}
              />
            </DropdownPopup>
            <DropdownPopup
              icon={<FaLanguage />}
              label={`Language (${formik.values.language.length})`}
              isHighlighted={formik.values.language.length > 0}
              onChangeVisibility={handleChangeVisibility(['language'])}
              onApply={handleApply(['language'])}
              onClear={handleClear(['language'])}
            >
              <TwoColumnCheckboxGroup
                options={options.languages ? options.languages : []}
                name="language"
                value={tempFormik.values.language}
                // onChange={tempFormik.handleChange}
                onChange={event => {
                  tempFormik.handleChange(event);
                  onChange(
                    'language',
                    event.target.value,
                    event.target.checked
                  );
                }}
              />
              <ShowMore
                onClick={() => {
                  setLanguageOptions(
                    languageOptions.length === 10
                      ? LANGUAGES
                      : LANGUAGES.slice(0, 10)
                  );
                }}
              >
                {languageOptions.length === 10 ? 'Show more' : 'Hide all'}
              </ShowMore>
            </DropdownPopup>
            <ShowAllFilterButton
              onClick={() => {
                setShowAllFilters(false);
                formik.resetForm();
                onApply();
              }}
            >
              Clear All Filters
            </ShowAllFilterButton>
          </ResearcherFilterRow>
        ) : null}
      </Column>
      <ResearcherFilterSide>
        {!showAllFilters ? (
          <ShowAllFilterButton
            onClick={() => {
              setShowAllFilters(true);
            }}
          >
            All Filters
          </ShowAllFilterButton>
        ) : null}
      </ResearcherFilterSide>
    </ResearcherFilterContainer>
  );
};

export default ResearcherFilter;
