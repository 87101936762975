import styled, { css } from 'styled-components/macro';
import Content, { MiddleContainer } from 'components/Content';
import Button from 'components/Button';
import Checkbox from 'components/Checkbox';
import {
  RadioDot,
  CheckboxContainer,
  CheckboxLayout,
} from 'components/Checkbox/styled';
import { Row } from 'components/Layout';
import ReactTooltip from 'react-tooltip';

export const ProjectContent = styled(Content)`
  flex: 1;

  ${MiddleContainer} {
    margin-top: 4rem;
    margin-bottom: 4rem;
    padding: 4rem 2rem;

    @media (max-width: 768px) {
      padding: 2rem;
      margin-bottom: 2rem;
    }
  }
`;

export const ProjectTitle = styled.h1`
  margin-top: 3rem;
  font-size: 2.4rem;
  margin-bottom: 1rem;

  @media (max-width: 768px) {
    margin-top: 4rem;
    font-size: 3.5rem;
    margin-bottom: 4rem;
  }
`;

export const ProjectUserDetails = styled.div`
  display: flex;
  margin-bottom: 2rem;

  @media (max-width: 768px) {
    align-items: center;
    margin-bottom: 4rem;
  }

  img {
    height: 2.4rem;
    width: 2.4rem;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 1rem;

    @media (max-width: 768px) {
      height: 5rem;
      width: 5rem;
      margin-right: 1.5rem;
    }
  }

  a {
    color: #65637b;
    margin-right: 1rem;

    @media (max-width: 768px) {
      font-size: 2rem;
      margin-right: 1.5rem;
    }
  }

  span {
    color: #65637b;
    opacity: 0.6;

    @media (max-width: 768px) {
      font-size: 2rem;
    }
  }
`;

export const ProjectActionMenuRow = styled.div`
  background-color: white;
  padding: 2.5rem 4rem 2rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  @media (max-width: 768px) {
    justify-content: center;
  }
`;

export const ProjectActionMenuItem = styled.div`
  position: relative;
  margin-left: 4rem;

  @media (max-width: 768px) {
    margin-left: 1rem;
    margin-right: 1rem;
  }
`;

export const DownloadPDFPopupContainer = styled.div`
  position: absolute;
  bottom: -27rem;
  left: -0.5rem;

  @media (max-width: 900px) {
    left: 1.4rem;
    bottom: -32rem;
  }
`;

export const DownloadPDFPopup = styled.div`
  position: relative;
  width: 21rem;
  height: 24rem;
  background-color: #fff;
  padding: 0.5em 0.5em;
  border-radius: 0.4rem;
  border: 1px solid #dadadf;
  box-shadow: 0px 3px 6px #00000012;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &::before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    bottom: 100%;
    left: 0.5em;
    border: 0.75rem solid transparent;
    border-top: none;

    border-bottom-color: #fff;
    filter: drop-shadow(0 -0.0625rem 0.0625rem #dadadf);
  }

  @media (max-width: 900px) {
    width: 21rem;
    height: 30rem;
  }
`;

export const ProjectActionMenuButton = styled(Button)`
  background-color: transparent;
  border: none;
  color: #65637b9a;
  font-weight: normal;
  font-size: 1.5rem;
  padding: 0.25rem;

  @media (max-width: 768px) {
    font-size: 1.6rem;
  }

  &:hover,
  &:active {
    color: #65637bff;
  }
  &:disabled {
    color: #65637b9a;
  }
  & svg {
    margin-bottom: -0.3rem;
  }
`;

export const DLPDFCheckbox = styled(Checkbox)`
  margin: 1rem 0rem;

  ${RadioDot} {
    background-color: #ff9e08;

    @media (max-width: 768px) {
      min-height: 1.2rem;
      min-width: 1.2rem;
    }
  }

  ${CheckboxContainer} {
    min-height: 1.6rem;
    min-width: 1.6rem;
    border: 1px solid #ff9e08;
    color: #ff9e08;

    @media (max-width: 900px) {
      min-height: 1.5rem;
      min-width: 1.5rem;
      border-radius: 2px;

      ${props =>
        props.type === 'radio' &&
        css`
          border-radius: 50%;
        `}
    }

    & svg {
      @media (max-width: 900px) {
        ${props =>
          props.type !== 'radio' &&
          css`
            font-size: 1.2rem;
          `}
      }
    }
  }

  ${CheckboxLayout} {
    font-size: 1.4rem;
  }
`;

export const ProjectContainer = styled.div`
  background-color: white;
  padding: 2rem 4rem 4rem;
  display: flex;

  @media (max-width: 768px) {
    display: block;
    padding: 0rem;
  }
`;

export const ProjectNavbar = styled.div`
  border-right: 1px solid rgba(75, 76, 94, 0.3);
  padding: 0rem 0 2rem;
  height: 100%;

  @media (max-width: 768px) {
    border: none;
    border-bottom: 1px solid rgba(75, 76, 94, 0.3);
    margin-bottom: 2rem;
  }
`;

export const ProjectNavbarButton = styled.button`
  display: block;
  height: 5.5rem;
  padding: 0 2rem;
  width: 28rem;
  text-align: left;
  font-size: 1.8rem;
  color: #4b4c5e;

  @media (max-width: 768px) {
    width: 100%;
    font-size: 2.4rem;
    padding: 2rem 4rem 5rem;
  }

  ${props =>
    props.active &&
    css`
      font-weight: bold;
      background-color: #f0f0f2;
    `}
`;

export const ProjectDetailsContainer = styled.div`
  flex: 1;
  padding: 1rem 4rem;
  display: grid;
  grid-template-areas:
    'industry service'
    'specialization techniques'
    'start end'
    'budget budget'
    'description description'
    'background background'
    'busiobjective busiobjective'
    'busiaction busiaction'
    'resobjective resobjective'
    'success_criteria success_criteria'
    'countries countries'
    'files files'
    'keyinfo keyinfo'
    'additionalreq additionalreq'
    'milestone milestone';
  row-gap: 2.5rem;
  color: #4b4c5e;

  @media (max-width: 768px) {
    padding: 0rem 4rem;
    grid-template-areas:
      'industry'
      'service'
      'specialization'
      'start'
      'end'
      'budget'
      'budget'
      'description'
      'description'
      'busiobjective'
      'busiobjective'
      'resobjective'
      'resobjective'
      'busiaction'
      'busiaction'
      'background'
      'background'
      'success_criteria'
      'success_criteria'
      'countries'
      'countries'
      'files'
      'files'
      'keyinfo'
      'keyinfo'
      'additionalreq'
      'additionalreq'
      'milestone'
      'milestone';
    row-gap: 2.5rem;
    font-size: 2.4rem;
  }
`;

export const ProjectDetailLabel = styled.div`
  color: rgba(75, 76, 94, 0.7);
  font-size: 1.75rem;
  text-transform: uppercase;

  @media (max-width: 768px) {
    margin-bottom: 0.5rem;
    font-size: 2.1rem;
  }
`;

export const DeleteProjectButton = styled(Button)`
  margin: 1rem;

  @media (max-width: 768px) {
    width: calc(100% - 8rem);
    margin: 4rem;
    padding-top: 1.75rem;
    padding-bottom: 1.75rem;
    font-size: 2rem;
    flex: 1;
  }
`;

export const ShareProjectButton = styled(Button)`
  margin: 1rem;

  @media (max-width: 768px) {
    width: calc(100% - 8rem);
    margin: 4rem;
    padding-top: 1.75rem;
    padding-bottom: 1.75rem;
    font-size: 2rem;
    flex: 1;
  }
`;

export const EditProjectButton = styled(Button)`
  margin: 1rem;

  @media (max-width: 768px) {
    width: calc(100% - 8rem);
    margin: 4rem;
    padding-top: 1.75rem;
    padding-bottom: 1.75rem;
    font-size: 2rem;
    flex: 1;
  }
`;

export const DeliverablesContainer = styled.div`
  flex: 1;
  padding: 1rem 4rem;
  display: grid;
  grid-template-areas:
    'milestone milestone'
    'outline budget'
    'related related';
  row-gap: 2.5rem;
  color: #4b4c5e;

  @media (max-width: 768px) {
    padding: 0rem 4rem;
    grid-template-areas:
      'milestone'
      'milestone'
      'outline'
      'budget'
      'related'
      'related';
    row-gap: 2.5rem;
    font-size: 2.4rem;
  }
`;

export const ProjectDocumentsLabel = styled.div`
  color: rgba(75, 76, 94, 0.7);
  font-size: 1.75rem;
  font-weight: bold;

  @media (max-width: 768px) {
    margin-bottom: 0.5rem;
    font-size: 2.1rem;
  }
`;

export const RequirementsContainer = styled.div`
  flex: 1;
  padding: 1rem 4rem;
  display: grid;
  color: #4b4c5e;
  grid-template-areas:
    'technical_mastery technical_mastery'
    'industry_expertise experience'
    'sizes_of_businesses_worked fortune_exp'
    'minimum_researcher_qualification minimum_researcher_qualification';
  row-gap: 2.5rem;
  color: #4b4c5e;

  @media (max-width: 768px) {
    padding: 0rem 4rem;
    grid-template-areas:
      'technical_mastery'
      'technical_mastery'
      'industry_expertise'
      'industry_expertise'
      'experience'
      'experience'
      'sizes_of_businesses_worked'
      'sizes_of_businesses_worked'
      'fortune_exp'
      'fortune_exp'
      'minimum_researcher_qualification'
      'minimum_researcher_qualification';
    row-gap: 2.5rem;
    font-size: 2.4rem;
  }
`;

export const DocumentDiv = styled.div`
  &:not(:first-child) {
    margin-top: 30px;
  }
`;

export const FilesContainer = styled(Row)`
  display: flex;
  justify-content: flex-start;
  margin-top: 1rem;
  color: #65637b;
  opacity: 0.6;
`;

export const FileIcon = styled.div`
  background: #4b4c5e 0% 0% no-repeat padding-box;
  padding-top: 10px;
  padding-bottom: 10px;

  img {
    object-fit: cover;
    width: 8rem;
    height: 10rem;
    padding-left: 10px;
    padding-right: 10px;
  }

  svg {
    font-size: 100px;
  }
`;

export const FilesMainDiv = styled.a`
  text-align: center;
  max-width: 104px;
  margin-top: 10px;
  &:not(:last-child) {
    margin-right: 30px;
  }
`;

export const FileNameSpan = styled.span`
  font-size: 12px;
  font-weight: bold;
  word-break: break-all;
`;
export const SPReactTooltip = styled(ReactTooltip)`
  max-width: 30%;
  word-wrap: normal;

  @media (max-width: 768px) {
    max-width: 80%;
  }
`;
