import CarouselSection from 'components/CarouselSection';
import LoadingIndicator from 'components/LoadingIndicator';
import ProjectCard from 'components/ProjectCard';
import ResearchersSection from 'components/ResearchersSection';
import Searchbar from 'components/Searchbar';
import faker from 'faker';
import { ajax } from 'utils/ajax';
import { useParams } from 'react-router-dom';
import {
  FilterContainer,
  FiltersLayout,
  SearchbarLayout,
} from 'pages/ResearcherList/styled';
import React, { Fragment, useState, useEffect, useCallback } from 'react';
import {
  FaCaretDown,
  FaGlobe,
  FaMapMarkerAlt,
  FaRegClock,
  FaStar,
} from 'react-icons/fa';
import {
  ProjectsContainer,
  SearchResultsContainer,
  SearchResultsContent,
  ThatsAll,
} from './styled';

const filters = [FaMapMarkerAlt, FaStar, FaRegClock, FaGlobe];

const marketCarousel = [];

while (marketCarousel.length < 20) {
  marketCarousel.push({
    img: faker.image.avatar(),
    title: faker.company.catchPhraseDescriptor(),
  });
}

const SearchResults = () => {
  const params = useParams();
  const [projects, setProjects] = useState([]);
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false);

  const parseSearchResults = results => {
    let tags = [];
    let initialProject = [];
    results.map(result => {
      const project = {
        id: result.id,
        title: result.title,
        description: result.description,
        researcher: {
          name: result.researcher_full_name,
          title: result.role,
        },
        tags: tags,
        price: result.actual_price,
      };

      if (result.research_type !== '') {
        const research_type = result.research_type;
        tags = research_type.split(',');
        project.tags.push(...tags);
      }

      initialProject.push(project);
      return project;
    });

    setProjects(initialProject);
  };

  const handleSearch = useCallback(search => {
    setLoading(true);
    ajax('GET', `/project/search?query=${search}&filter={}`).then(result => {
      setLoading(false);
      if (result.code === 200) {
        parseSearchResults(result.data);
      }
    });
  }, []);

  useEffect(() => {
    setSearch(params.search_input);
    handleSearch(params.search_input);
  }, [handleSearch, params.search_input]);

  return (
    <SearchResultsContainer>
      {loading ? <LoadingIndicator /> : null}
      <SearchResultsContent>
        <h1>Results for</h1>
        <SearchbarLayout>
          <Searchbar
            value={search}
            onChange={setSearch}
            onKeyPress={event => {
              if (event === 'Enter') {
                handleSearch(search);
              }
            }}
          />
          <div>
            <h6>Filter by:</h6>
            <FiltersLayout>
              {filters.map((Filter, index) => (
                <Fragment key={index}>
                  <FilterContainer>
                    <Filter />
                    <FaCaretDown />
                  </FilterContainer>
                </Fragment>
              ))}
            </FiltersLayout>
          </div>
        </SearchbarLayout>
        <h2>
          {projects.length === 0
            ? 'No results to show'
            : 'We found these projects related to your search'}
        </h2>
        <ProjectsContainer>
          {projects.map(project => (
            <ProjectCard key={project.id} {...project} onView={() => {}} />
          ))}
          <ThatsAll>That's all of the results.</ThatsAll>
        </ProjectsContainer>
      </SearchResultsContent>
      <ResearchersSection title="Recommended Researchers" />
      <CarouselSection
        data={marketCarousel}
        title="Explore Market Research"
        color="#36a3b2"
      />
      <CarouselSection
        data={marketCarousel}
        title="Explore Algorithmic Research"
        color="#ff9e08"
      />
    </SearchResultsContainer>
  );
};

export default SearchResults;
