import React, { useState } from 'react';
import {
  MessageConversationItem,
  ConversationItemMessage,
  ActionButtonContainer,
  ConversationContainer,
  ActionButton,
  ViewButtonContainer,
  MessageDate,
} from './styled';
import {
  // FaFile,
  // FaFilePdf,
  // FaFileExcel,
  // FaFilePowerpoint,
  // FaFileWord,
  FaFileImage,
  FaRegCopy,
} from 'react-icons/fa';
import Button from 'components/Button';
import './style.css';
import DownloadIcon from '@mui/icons-material/Download';
import Modal from 'components/Modal';
import cross from './cross.png';
import { RiDeleteBin6Line } from 'react-icons/ri';
import { ajax } from 'utils/ajax';
import { useAuthState } from 'context/AuthContext';
import BrowsePlanModal from 'components/BrowsePlanModal';
const SEND_PROPOSAL = 'send proposal';
const SEND_FINAL_OUTPUT = 'send final output';
const SEND_INVITE = 'invite';

// const files = [
//   {
//     filename: 'proposal version 1.pdf',
//   },
//   {
//     filename: 'proposal version 2.xls',
//   },
//   {
//     filename: 'proposal version 3.jpg',
//   },
//   {
//     filename: 'proposal version 4.ppt',
//   },
//   {
//     filename: 'proposal version 4.docx',
//   },
// ];

const Message = ({
  attachments,
  message,
  name,
  date,
  isSender,
  image,
  created_date,
  chats,
  message_type,
  handleSendMessage,
  hasPleaseRevise,
  project_status_id,
  message_id,
  setLoading,
  ...props
}) => {
  const handleDeleteForMe = async id => {
    setLoading(true);
    await ajax('DELETE', `/message/${id}?for_me=true'`).then(result => {
      if (result.code === 200) {
        props.getConversation(chats, true);
        setDeleteMessagePopup(false);
      }
    });
  };
  const handleDeleteForEveryone = async id => {
    setLoading(true);
    await ajax('DELETE', `/message/${id}`).then(result => {
      if (result.code === 200) {
        props.getConversation(chats, true);
        setDeleteMessagePopup(false);
      }
    });
  };

  const copyCoupon = e => {
    e.stopPropagation();
    var coupon = 'hello';
    navigator.clipboard.writeText(coupon);
    alert(`Coupon code ${coupon} copied to your clipboard`);
  };

  const { isMemberSubscribed } = useAuthState();
  const [isBrowseModal, setIsBrowseModal] = useState(false);

  const [isShowingModal, setIsShowingModal] = useState(null);
  const [deleteMessagePopup, setDeleteMessagePopup] = useState(false);
  // const getFileIcon = (type, name) => {
  //   let fileicon = <FaFile style={{ paddingTop: '3px', marginRight: '6px' }} />;
  //   if (type && type !== '') {
  //     switch (true) {
  //       case type.includes('image'):
  //         fileicon = (
  //           <FaFileImage style={{ paddingTop: '3px', marginRight: '6px' }} />
  //         );
  //         break;
  //       case type.includes('pdf'):
  //         fileicon = (
  //           <FaFilePdf style={{ paddingTop: '3px', marginRight: '6px' }} />
  //         );
  //         break;
  //       case type.includes('doc'):
  //         fileicon = (
  //           <FaFileWord style={{ paddingTop: '3px', marginRight: '6px' }} />
  //         );
  //         break;
  //       case type.includes('excel'):
  //         fileicon = (
  //           <FaFileExcel style={{ paddingTop: '3px', marginRight: '6px' }} />
  //         );
  //         break;
  //       case type.includes('powerpoint'):
  //         fileicon = (
  //           <FaFilePowerpoint
  //             style={{ paddingTop: '3px', marginRight: '6px' }}
  //           />
  //         );
  //         break;
  //       default:
  //         fileicon = (
  //           <FaFile style={{ paddingTop: '3px', marginRight: '6px' }} />
  //         );
  //     }
  //   } else if (name) {
  //     const ext = name
  //       .split('.')
  //       .slice(-1)
  //       .pop();
  //     if (ext) {
  //       switch (ext.toLowerCase()) {
  //         case 'jpg':
  //         case 'jpeg':
  //         case 'png':
  //           fileicon = (
  //             <FaFileImage style={{ paddingTop: '3px', marginRight: '6px' }} />
  //           );
  //           break;
  //         case 'pdf':
  //           fileicon = (
  //             <FaFilePdf style={{ paddingTop: '3px', marginRight: '6px' }} />
  //           );
  //           break;
  //         case 'doc':
  //         case 'docx':
  //           fileicon = (
  //             <FaFileWord style={{ paddingTop: '3px', marginRight: '6px' }} />
  //           );
  //           break;
  //         case 'xls':
  //         case 'xlsx':
  //           fileicon = (
  //             <FaFileExcel style={{ paddingTop: '3px', marginRight: '6px' }} />
  //           );
  //           break;
  //         case 'ppt':
  //         case 'pptx':
  //           fileicon = (
  //             <FaFilePowerpoint
  //               style={{ paddingTop: '3px', marginRight: '6px' }}
  //             />
  //           );
  //           break;
  //         default:
  //           fileicon = (
  //             <FaFile style={{ paddingTop: '3px', marginRight: '6px' }} />
  //           );
  //       }
  //     }
  //   }
  //   return fileicon;
  // };

  return (
    <ConversationContainer>
      <MessageConversationItem isSender={isSender} {...props}>
        {!isSender && image}
        <div>
          <div style={{ display: 'flex' }}>
            <ConversationItemMessage>
              {message !== '' && (
                // <ContextMenuTrigger
                //   id={isSender ? 'sender_menu' : 'receiver_menu'}
                // >
                <div style={{ padding: '1rem' }}>{message}</div>

                // </ContextMenuTrigger>
              )}

              {props.type === SEND_INVITE && (
                <div style={{ padding: '1rem' }}>
                  <div className="namePro">Project Name</div>
                  <div className="proname">
                    {props?.project_name ? props?.project_name : '-'}
                  </div>

                  <ViewButtonContainer
                    isSender={isSender}
                    style={{
                      padding: '8px 8px 8px 8px',
                    }}
                  >
                    <Button
                      backgroundColor="#65637B"
                      style={{
                        marginRight: '15px',
                      }}
                      onClick={() => {
                        if (props.handleActionButtons) {
                          props.handleActionButtons(
                            props.project_id,
                            props.sender_id,
                            'view'
                          );
                        }
                      }}
                    >
                      VIEW MY PROJECT
                    </Button>
                  </ViewButtonContainer>
                </div>
              )}
            </ConversationItemMessage>
            {message !== '' && props?.type !== 'upload file' && (
              <div
                className="deleteBtn"
                style={{ padding: '5px', cursor: 'pointer' }}
                onClick={() => setDeleteMessagePopup(true)}
              >
                <RiDeleteBin6Line />
              </div>
            )}
          </div>
          <div style={{ display: 'flex' }}>
            <ConversationItemMessage>
              {attachments.length > 0 ? (
                props?.type === 'send final output' ||
                props?.type === 'send proposal' ? (
                  <div style={{ width: '26rem' }}>
                    <div
                      className={`propHead ${
                        isSender ? 'greyBackground' : 'orangeBackground'
                      }`}
                    >
                      {props?.type === 'send final output'
                        ? 'Final Output'
                        : 'Project Proposal'}
                    </div>
                    <div className="propContent">
                      <div className="namePro">Project Name</div>
                      <div className="proname">
                        {props?.project_name ? props?.project_name : '-'}
                      </div>
                      {props?.type !== 'send final output' ? (
                        <>
                          <div
                            className="namePro"
                            style={{ paddingTop: '15px' }}
                          >
                            Bid Amoount(USD)
                          </div>
                          <div className="proname">
                            {props?.proposed_amount
                              ? '$' + props?.proposed_amount
                              : '-'}
                          </div>
                        </>
                      ) : (
                        ''
                      )}
                    </div>
                    <hr
                      style={{
                        borderColor: isSender
                          ? '#e7e7e7'
                          : 'rgb(245, 140, 6, 0.3)',
                      }}
                    />

                    <div
                      style={{
                        fontSize: '15px',
                        padding: '8px 14px 14px 14px',
                        borderRadius: '8px',
                        width: 'initial',
                        cursor: 'pointer',
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      <span
                        style={{
                          textDecoration: 'none',
                          color: '#111',
                          wordBreak: 'break-all',
                        }}
                      >
                        <FaFileImage
                          style={{ paddingTop: '3px', marginRight: '6px' }}
                        />
                        <span>{attachments[0].filename}</span>
                      </span>
                      <a
                        href={attachments[0].url}
                        style={{
                          textDecoration: 'none',
                          color: '#111',
                          cursor: 'pointer',
                          marginTop: '2px',
                        }}
                      >
                        <DownloadIcon
                          style={{
                            width: '2rem',
                            height: '2rem',
                            color: '#FF9E08',
                          }}
                        />
                      </a>
                    </div>
                  </div>
                ) : (
                  <div
                    style={{
                      fontSize: '15px',
                      padding: '8px 14px 8px 14px',
                      borderRadius: '8px',
                      width: 'initial',
                      cursor: 'pointer',
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  >
                    <a
                      style={{
                        textDecoration: 'none',
                        color: '#111',
                        wordBreak: 'break-all',
                        maxWidth: '80%',
                      }}
                      href={() => false}
                    >
                      <FaFileImage
                        style={{ paddingTop: '3px', marginRight: '6px' }}
                      />
                      <span>{attachments[0].filename}</span>
                    </a>
                    <a
                      href={attachments[0].url}
                      style={{
                        textDecoration: 'none',
                        color: '#111',
                        cursor: 'pointer',
                        marginTop: '5px',
                        marginLeft: '5px',
                      }}
                    >
                      <DownloadIcon
                        style={{
                          width: '2rem',
                          height: '2rem',
                          color: '#FF9E08',
                        }}
                      />
                    </a>
                  </div>
                )
              ) : (
                ''
              )}
            </ConversationItemMessage>
            {props?.type === 'send final output' ||
            props?.type === 'send proposal' ||
            props?.type === 'upload file' ? (
              <div
                className="deleteBtn"
                style={{ padding: '5px', cursor: 'pointer' }}
                onClick={() => setDeleteMessagePopup(true)}
              >
                <RiDeleteBin6Line />
              </div>
            ) : null}
          </div>
          <MessageDate isSender={isSender}>{created_date}</MessageDate>
        </div>
        {isSender && image}
      </MessageConversationItem>
      {!hasPleaseRevise &&
        !isSender &&
        props?.member_type === 2 &&
        props?.type !== SEND_INVITE && (
          <ActionButtonContainer>
            {project_status_id === 3 && props.type === SEND_PROPOSAL && (
              <>
                <ActionButton
                  outline
                  disabled={props.is_payment_in_progress}
                  style={{
                    marginRight: '10px',
                  }}
                  onClick={() => {
                    isMemberSubscribed
                      ? setIsShowingModal('12345')
                      : setIsBrowseModal(true);
                  }}
                >
                  Approve Proposal
                </ActionButton>
                <ActionButton
                  outline
                  style={{
                    marginRight: '15px',
                  }}
                  disabled={props.is_payment_in_progress}
                  onClick={() =>
                    handleSendMessage(
                      chats,
                      `Please revise your proposal for ${
                        props?.project_name ? props?.project_name : ''
                      }`,
                      message_type
                    )
                  }
                >
                  Please Revise...
                </ActionButton>
              </>
            )}
            {project_status_id !== 1 && props.type === SEND_FINAL_OUTPUT && (
              <>
                <ActionButton
                  outline
                  disabled={
                    props.type === SEND_FINAL_OUTPUT &&
                    props.is_payment_in_progress
                  }
                  style={{
                    marginRight: '10px',
                  }}
                  onClick={() => {
                    if (isMemberSubscribed) {
                      if (props.handleActionButtons) {
                        props.handleActionButtons(
                          props.project_id,
                          props.sender_id,
                          'accept',
                          props?.bid_amount,
                          message_id,
                          props?.senderEmail
                        );
                      }
                    } else {
                      setIsBrowseModal(true);
                    }
                  }}
                >
                  Accept Final Output
                </ActionButton>
                <ActionButton
                  outline
                  style={{
                    marginRight: '15px',
                  }}
                  disabled={
                    props.type === SEND_FINAL_OUTPUT &&
                    props.is_payment_in_progress
                  }
                  onClick={() =>
                    handleSendMessage(
                      chats,
                      `Please revise your final output for ${
                        props?.project_name ? props?.project_name : ''
                      }`,
                      message_type
                    )
                  }
                >
                  Please Revise...
                </ActionButton>
              </>
            )}
          </ActionButtonContainer>
        )}
      <Modal visible={!!isShowingModal} setVisible={setIsShowingModal}>
        <div
          style={{
            backgroundColor: 'white',
            // padding: '2rem',
            flexDirection: 'column',
            justifyContent: 'flex-end',
            width: '300px',
            borderRadius: '10px',
          }}
        >
          <div className="modalHead">
            <div className="modalTxt">Approve Proposal</div>
            <div className="modalCross">
              <img
                src={cross}
                alt="cross-icon"
                className="crossIcon"
                onClick={() => setIsShowingModal(null)}
              />
            </div>
          </div>
          <div className="modalContent">
            <div className="contHead">
              All the other researchers, who shared the proposal for this
              project, will receive a message - Hi ‘researcher name', thank you
              for the proposal you submitted for '
              <strong>{props?.project_name}</strong>'. I will be going with
              another proposal that better fits the project requirements at this
              time. Wishing you luck on winning your next project! –{' '}
              <strong>{props?.receiverName}</strong>
            </div>
          </div>

          <div className="buttonModal">
            <Button
              simple
              onClick={() => setIsShowingModal(null)}
              style={{ margin: '5px' }}
            >
              Cancel
            </Button>
            <Button
              fill
              style={{ margin: '5px' }}
              onClick={event => {
                event.preventDefault();
                if (props.handleActionButtons) {
                  props.handleActionButtons(
                    props.project_id,
                    props.sender_id,
                    'approve',
                    props?.proposed_amount,
                    message_id,
                    props?.senderEmail
                  );
                }
                setIsShowingModal(null);
              }}
            >
              Ok
            </Button>
          </div>
        </div>
      </Modal>
      <Modal visible={deleteMessagePopup} setVisible={setDeleteMessagePopup}>
        <div
          style={{
            backgroundColor: 'white',
            // padding: '2rem',
            flexDirection: 'column',
            justifyContent: 'flex-end',
            width: '300px',
            borderRadius: '10px',
          }}
        >
          <div className="modalHead" style={{ padding: '10px 20px' }}>
            <div className="modalTxt">Delete Message</div>
            <div className="modalCross">
              <img
                src={cross}
                alt="cross-icon"
                className="crossIcon"
                onClick={() => setDeleteMessagePopup(false)}
              />
            </div>
          </div>

          <div
            className="buttonModal"
            style={{
              padding: '30px 20px 20px 20px',
              justifyContent: isSender ? 'space-between' : 'center',
              textAlign: 'center',
            }}
          >
            {isSender && (
              <span
                // simple
                className="everyone_button"
                onClick={() => handleDeleteForEveryone(message_id)}
              >
                Delete for everyone
              </span>
            )}
            <span
              className="me_button"
              onClick={() => handleDeleteForMe(message_id)}
            >
              Delete for me
            </span>
          </div>
        </div>
      </Modal>
      <BrowsePlanModal visible={isBrowseModal} setVisible={setIsBrowseModal} />
    </ConversationContainer>
  );
};

export default Message;
