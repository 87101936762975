import * as yup from 'yup';

export const earlyEalidation = yup.object({
  email: yup
    .string()
    .matches(
      /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w{2,4}([-.]\w+)*$/,
      'Enter a valid email'
    )
    .required('Email is required'),
  firstName: yup.string().required('First Name is required'),
  lastName: yup.string().required('Last Name is required'),
});
