import { CheckboxLayout } from 'components/Checkbox/styled';
import Content, { MiddleContainer } from 'components/Content';
import Button from 'components/Button';
import { InputContainer } from 'components/Input/styled';
import styled from 'styled-components/macro';

export const LoginContent = styled(Content)`
  margin: 4rem 0;

  ${MiddleContainer} {
    display: flex;
    background-color: white;

    @media (max-width: 768px) {
      display: block;
    }
  }
`;

export const LoginBannerContainer = styled.div`
  height: 56rem;
  width: 40%;
  padding: 6rem 0;
  background: linear-gradient(
    to bottom,
    #ff9e08 0%,
    #ff9e08 50%,
    #4b4c5e 50%,
    #4b4c5e 100%
  );
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    display: none;
  }

  @media (max-width: 900px) {
    display: none;
  }

  img {
    transform: scale(1.1);
    width: 100%;

    @media (max-width: 768px) {
      margin-top: -22%;
      transform: scale(0.75);
      width: 100%;
    }
  }
`;

export const LoginBannerTextContainer = styled.div`
  color: white;
  h2 {
    margin: 4rem 0rem;
    font-size: 2.9rem;
    text-align: center;
  }
  h1 {
    font-size: 4.2rem;
  }
`;

export const LoginFormContainer = styled.div`
  flex: 1;
  padding: 4rem 8rem;

  @media (max-width: 768px) {
    padding: 4rem;
  }

  .fb-login-button {
    margin-bottom: 1rem;
  }

  ${InputContainer} {
    margin-bottom: 1rem;

    @media (max-width: 768px) {
      margin-bottom: 2rem;
    }
  }
`;

export const Title = styled.div`
  font-size: 2.5rem;
  font-weight: bold;
  color: #4b4c5e;
  margin-bottom: 4rem;

  @media (max-width: 768px) {
    font-size: 3rem;
  }
`;

export const GoogleLoginButton = styled.button`
  position: relative;
  width: 100%;
  padding: 1rem 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #65637b;
  margin-bottom: 4rem;

  font-size: 1.5rem;
  font-weight: bold;

  @media (max-width: 768px) {
    font-size: 2rem;
    padding: 2rem;
  }

  @media (max-width: 900px) {
    font-size: 1.75rem;
    padding: 1.5rem 2rem;
  }

  svg {
    font-size: 1.75rem;
    position: absolute;
    left: 1.5rem;

    @media (max-width: 768px) {
      font-size: 2rem;
      left: 2rem;
    }
  }
`;

export const DividerLayout = styled.div`
  display: flex;
  align-items: center;
  margin: 2rem 0rem;
`;

export const DividerText = styled.span`
  color: #65637b;
  opacity: 0.7;
  font-size: 1.7rem;
  margin: 0 1rem;

  @media (max-width: 768px) {
    font-size: 2.1rem;
  }
`;

export const DividerBar = styled.div`
  flex: 1;
  height: 1px;
  background-color: #dadadf;
`;

export const OtherFormText = styled.p`
  margin-top: 2rem;
  font-size: 1.5rem;
  color: #65637b;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 2.2rem;
  }

  span {
    cursor: pointer;
    color: #ff9e08;
    &:hover {
      text-decoration: underline;
    }
  }
`;

export const ConditionsText = styled.span`
  font-size: 1.4rem;
  color: #4b4c5e;
  opacity: 0.8;

  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

export const ConditionsLink = styled.span`
  text-decoration: underline;
  cursor: pointer;
`;

export const AccountTypeContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 2rem 0;
  ${CheckboxLayout} {
    color: #4b4c5e;
    font-size: 1.4rem;
    margin-bottom: 1rem;

    @media (max-width: 768px) {
      font-size: 2rem;
    }
  }
`;

export const ErrorMessage = styled.p`
  margin-top: 1rem;
  color: #ef5350;
  font-size: 12px;
  text-align: left;

  @media (max-width: 768px) {
    font-size: 2.2rem;
  }
`;

export const SignUpSuccess = styled.p`
  font-weight: bold;
  margin-top: 1rem;
  text-align: center;
`;

export const SubmitButton = styled(Button)`
  background-color: ${props => (props.isSUResearcher ? '#4b4c5e' : '#ff9e08')};

  @media (max-width: 768px) {
    margin: 1rem 0;
    padding-top: 1.75rem;
    padding-bottom: 1.75rem;
    font-size: 2rem;
    flex: 1;
  }
`;

export const FacebookLoginButton = styled.button`
  position: relative;
  width: 100%;
  padding: 1rem 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #65637b;
  margin-bottom: 4rem;

  font-size: 1.5rem;
  font-weight: bold;

  @media (max-width: 768px) {
    font-size: 2rem;
    padding: 2rem;
  }

  @media (max-width: 900px) {
    font-size: 1.75rem;
    padding: 1.5rem 2rem;
  }

  svg {
    font-size: 1.75rem;
    position: absolute;
    left: 1.5rem;

    @media (max-width: 768px) {
      font-size: 2rem;
      left: 2rem;
    }
  }
`;
