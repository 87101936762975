import Content from 'components/Content';
import Button from 'components/Button';
import { SearchbarContainer } from 'components/Searchbar/styled';
import styled from 'styled-components/macro';
import { Row } from 'components/Layout';

export const HeroBannerContainer = styled(Content)`
  position: relative;
  padding: 10rem 0;
  z-index: 0;

  @media (max-width: 900px) {
    padding: 6rem 0;
  }

  h1 {
    font-size: 3.6rem;
    color: white;
    white-space: pre;
    margin-bottom: 3.5rem;
  }

  ${SearchbarContainer} {
    margin-bottom: 2.5rem;
    max-width: 50%;

    @media (max-width: 900px) {
      max-width: 100%;
      margin-bottom: 5rem;
    }
  }
`;

export const BackgroundImage = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
`;

export const GrayGradientContainer = styled.div`
  position: absolute;
  display: inline-block;
  z-index: -1;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;

  &::after {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;

    content: '';
    display: inline-block;
    background: linear-gradient(
      180deg,
      rgba(101, 99, 123, 0.5) 0%,
      rgba(82, 81, 93, 0.5) 50%,
      rgba(44, 44, 52, 0.5) 100%
    );
  }
`;

export const PopularTopics = styled.p`
  color: white;
  font-size: 1.4rem;
  margin-bottom: 3.5rem;

  @media (max-width: 900px) {
    font-size: 2.1rem;
    margin-bottom: 5.4rem;
  }

  span {
    font-weight: bold;
  }

  a {
    color: white;
    margin-left: 2rem;
    text-shadow: 0px 3px 6px #00000029;
  }
`;

export const HBButtonRow = styled(Row)`
  max-width: 40%;

  @media (max-width: 1200px) {
    max-width: 48%;
  }

  @media (max-width: 900px) {
    flex-direction: column;
    align-items: center;
    max-width: 100%;
  }
`;

export const TellProjectButton = styled(Button)`
  @media (max-width: 900px) {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    width: 50%;
    font-size: 2.3rem;
  }

  @media (max-width: 768px) {
    padding-top: 2rem;
    padding-bottom: 2rem;
    width: 70%;
  }
`;

export const JoinButton = styled(TellProjectButton)`
  background-color: #65637b;

  @media (max-width: 900px) {
    margin-top: 4rem;
  }
`;
