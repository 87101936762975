export const ajax = async (method, endpoint, body) => {
  const headers = {
    'content-type': 'application/json',
  };

  const authToken = localStorage.getItem('br-authToken');

  if (authToken) {
    headers.Authorization = `Bearer ${authToken}`;
  }

  const res = await fetch(`${process.env.REACT_APP_API}${endpoint}`, {
    method: method.toUpperCase(),
    body: JSON.stringify(body),
    headers,
  });
  return await res.json();
};
