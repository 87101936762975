import Button from 'components/Button';
import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { PageContent } from './styled';
import { ajax } from 'utils/ajax';

export const ProjectPaymentFinal = () => {
  const history = useHistory();
  const [itemOne, setItemOne] = useState(0);
  const rating = [1, 2, 3, 4, 5];
  const [loading, setLoading] = useState(false);
  const { member_id, researcher_id } = useParams();
  const getMobileWindow = () => {
    let isMobile = window.matchMedia('(max-width: 768px)').matches;
    return isMobile ? true : false;
  };
  const [isMobile, setIsMobile] = useState(getMobileWindow());

  const continueToMessages = async () => {
    setLoading(true);
    let body = {
      created_by: Number(member_id),
      rating: itemOne,
      researcher_id: Number(researcher_id),
    };

    let res = await ajax('POST', '/member/ratings', body);
    if (res.code === 200) {
      setItemOne(0);
      setLoading(false);
      history.push('/messages');
    }
  };

  return (
    <PageContent>
      <h1 style={{ fontSize: '3rem', marginBottom: 20 }}>
        {' '}
        Payment Successful!{' '}
      </h1>
      <h6 style={{ marginBottom: 20 }}>Rate your researcher</h6>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          margin: '20px auto',
          justifyContent: 'center',
        }}
      >
        {rating.map(item => {
          return (
            <div
              style={{
                // padding: `8px ${item === 10 ? '12px' : '15px'}`,
                height: `${isMobile ? '20px' : '35px'}`,
                width: '40px',
                paddingTop: '7px',
                textAlign: 'center',
                margin: '0 0.5rem',
                border: '1px solid',
                borderRadius: '50%',
                cursor: 'pointer',
                background: item === itemOne ? '#ff9b2d' : '#fff',
                color: item === itemOne ? '#fff' : '#111',
              }}
              onClick={() => setItemOne(item)}
              key={item}
            >
              {item}
            </div>
          );
        })}
      </div>
      {itemOne > 0 && (
        <Button
          fill
          disabled={itemOne === 0 || loading}
          onClick={() => continueToMessages()}
          style={{ width: 350, marginTop: 30 }}
        >
          {loading ? 'Proceeding back to messages' : 'Submit'}
        </Button>
      )}
    </PageContent>
  );
};
