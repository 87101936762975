import React from 'react';
import { format } from 'date-fns';
import { FileNameSpan, FilesMainDiv, ProjectDetailLabel } from 'pages/Project';

export const BriefDetails = props => {
  console.log('props: ', props);

  const openPreviewInNewTab = res => {
    window.open(res, '_blank');
  };

  const getIndustriesNames = id => {
    let name = '';
    const ind = props?.previewDataValues?.industry.find(elem => {
      return elem?.id === id;
    });

    name = ind?.name || '';

    return name;
  };

  const getExpertiseNames = id => {
    let name = '';
    // const ind = props?.previewDataValues?.services_offered.find(elem => {
    //   return elem?.id === id;
    // });

    // name = ind?.name || '';
    name = id;

    return name;
  };

  const getSpecializationNames = id => {
    let name = '';
    let data = [];

    data = [
      ...props?.previewDataValues?.market_research,
      ...props?.previewDataValues?.researcher_ml_operations,
      ...props?.previewDataValues?.analytics_research,
    ];
    const ind = data.find(elem => {
      return elem?.id === id;
    });

    name = ind?.name || '';

    return name;
  };

  const handlePreviewFile = file => {
    console.log(file);
    if (file?.new_upload) {
      const fileURL = URL.createObjectURL(file?.url);
      window.open(fileURL, '_blank');
    } else {
      window.open(file?.url, '_blank');
    }
  };

  const getFiles = files => {
    console.log('files: ', files);

    let files_component = null;
    files_component = files.map((item, index) => {
      return (
        <FilesMainDiv
          onClick={() => handlePreviewFile(item)}
          key={index + 'file'}
          style={{ cursor: 'pointer' }}
        >
          {/* <FileIcon>{getFileIcon(item)}</FileIcon> */}
          <FileNameSpan>{item.filename}</FileNameSpan>
        </FilesMainDiv>
      );
    });
    return files_component;
  };

  return (
    <>
      <div style={{ gridArea: 'industry' }}>
        <ProjectDetailLabel>INDUSTRY</ProjectDetailLabel>
        <div>
          {props.project.industry
            ? props.project.industry.map((id, index) => {
                return <div key={index + 'ind'}>{getIndustriesNames(id)}</div>;
              })
            : '-'}
        </div>
      </div>
      <div style={{ gridArea: 'service' }}>
        <ProjectDetailLabel>Required Expertise</ProjectDetailLabel>
        <div>
          {props.project.services
            ? props.project.services.map((id, index) => {
                return (
                  <div key={index + 'services'}>{getExpertiseNames(id)}</div>
                );
              })
            : '-'}
        </div>
      </div>
      <div style={{ gridArea: 'specialization' }}>
        <ProjectDetailLabel>Specialization</ProjectDetailLabel>
        <div>
          {props.project.business_needspace
            ? props.project.business_needspace.map((id, index) => {
                return (
                  <div key={index + 'special'}>
                    {getSpecializationNames(id)}
                  </div>
                );
              })
            : '-'}
        </div>
      </div>
      {/* <div style={{ gridArea: 'techniques' }}>
        <ProjectDetailLabel>Techniques</ProjectDetailLabel>
        <div>
          {props.project.key_deliverables
            ? props.project.key_deliverables
            : '-'}
        </div>
      </div> */}
      <div style={{ gridArea: 'start' }}>
        <ProjectDetailLabel>TARGET PROJECT START</ProjectDetailLabel>
        <div>
          {props.project.start_date
            ? format(new Date(props.project.start_date), 'MMM d, yyyy')
            : '-'}
        </div>
      </div>
      <div style={{ gridArea: 'end' }}>
        <ProjectDetailLabel>TARGET PROJECT END</ProjectDetailLabel>
        <div>
          {props.project.end_date
            ? format(new Date(props.project.end_date), 'MMM d, yyyy')
            : '-'}
        </div>
      </div>
      <div style={{ gridArea: 'budget' }}>
        <ProjectDetailLabel>BUDGET</ProjectDetailLabel>
        <div>
          {props.project.actual_price ? props.project.actual_price : '-'}
        </div>
      </div>
      {/* <div style={{ gridArea: 'description' }}>
        <ProjectDetailLabel>PROJECT DESCRIPTION</ProjectDetailLabel>
        <div>{props.project.description ? props.project.description : ''}</div>
      </div> */}
      <div style={{ gridArea: 'background' }}>
        <ProjectDetailLabel>BACKGROUND</ProjectDetailLabel>
        <div>{props.project.background ? props.project.background : '-'}</div>
      </div>
      <div style={{ gridArea: 'busiobjective' }}>
        <ProjectDetailLabel>BUSINESS OBJECTIVE</ProjectDetailLabel>
        <div>
          {props.project.business_objective
            ? props.project.business_objective
            : '-'}
        </div>
      </div>
      <div style={{ gridArea: 'busiaction' }}>
        <ProjectDetailLabel>BUSINESS ACTION FROM RESEARCH</ProjectDetailLabel>
        <div>
          {props.project.business_action ? props.project.business_action : '-'}
        </div>
      </div>
      {props.project.research_objective ? (
        <div style={{ gridArea: 'resobjective' }}>
          <ProjectDetailLabel>RESEARCH OBJECTIVE</ProjectDetailLabel>
          <div>
            {props.project.research_objective
              ? props.project.research_objective
              : '-'}
          </div>
        </div>
      ) : (
        ''
      )}
      <div style={{ gridArea: 'success_criteria' }}>
        <ProjectDetailLabel>SUCCESS CRITERIA</ProjectDetailLabel>
        <div>
          {props.project.success_criteria
            ? props.project.success_criteria
            : '-'}
        </div>
      </div>

      <div style={{ gridArea: 'countries' }}>
        <ProjectDetailLabel>COUNTRIES OF INTEREST</ProjectDetailLabel>
        <div>
          {props.project.countries_of_interest ? (
            <>
              {props.project.countries_of_interest?.length > 0
                ? props.project.countries_of_interest.map((country, index) => (
                    <p key={index}>{country}</p>
                  ))
                : '-'}
            </>
          ) : (
            '-'
          )}
        </div>
      </div>

      {/* <div style={{ gridArea: 'files' }}>
        <ProjectDetailLabel>REFERENCES/OTHER FILES</ProjectDetailLabel>
        <div>
          {props.project.reference_files && props.project.reference_files.length
            ? getFiles(props.project.reference_files)
            : 'No uploaded documents.'}
        </div>
      </div> */}
      {/* <div style={{ gridArea: 'keyinfo' }}>
        <ProjectDetailLabel>KEY INFORMATION/QUESTIONS</ProjectDetailLabel>
        <div>
          {props.project.key_questions ? props.project.key_questions : '-'}
        </div>
      </div> */}
      {/* <div style={{ gridArea: 'additionalreq' }}>
        <ProjectDetailLabel>ADDITIONAL REQUESTS</ProjectDetailLabel>
        <div>
          {props.project.additional_requests
            ? props.project.additional_requests
            : ''}
        </div>
      </div> */}
    </>
  );
};
