import Button from 'components/Button';
import Modal from 'components/Modal';
import { RPCheckbox } from 'pages/ResearcherProfileForm';
import React from 'react';
import { useState } from 'react';
import { RiCloseCircleFill } from 'react-icons/ri';

const SubmitConfirmPopup = ({
  visible = false,
  setVisible,
  handleSubmitButton,
}) => {
  const [accurate, setAccurate] = useState('');
  const [confirm, setConfirm] = useState('');
  const [valid, setValid] = useState('');

  return (
    <Modal visible={visible} setVisible={setVisible} persistent>
      <div
        style={{
          backgroundColor: 'white',
          padding: '2rem',
          flexDirection: 'column',
          justifyContent: 'flex-end',
          maxWidth: `${'400px'}`,
          marginLeft: 'auto',
          marginRight: 'auto',
          borderRadius: '12px',
        }}
      >
        <div style={{ padding: '12px 12px', position: 'relative' }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginBottom: '20px',
              fontSize: '2rem',
              fontWeight: '700',
              // maxWidth: '90%',
            }}
          >
            Please Confirm!
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'self-start',
              marginBottom: '20px',
            }}
          >
            <RPCheckbox
              // type="radio"
              label=""
              name={'accurate'}
              value={accurate}
              checked={accurate === 'yes'}
              onChange={() => setAccurate('yes')}
            />
            <p style={{ marginLeft: '5px' }}>
              I certify that, to the best of my knowledge, the information I
              provided is true and accurate.
            </p>
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'self-start',
              marginBottom: '20px',
            }}
          >
            <RPCheckbox
              // type="radio"
              label=""
              name={'confirm'}
              value={confirm}
              checked={confirm === 'yes'}
              onChange={() => setConfirm('yes')}
            />
            <p style={{ marginLeft: '5px' }}>
              I confirm and understand Big Robin reserves the right to verify
              users’ claims, and will suspend or close accounts that are not
              verified, inactive or violate the terms of use of Big Robin.
            </p>
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'self-start',
              marginBottom: '20px',
            }}
          >
            <RPCheckbox
              // type="radio"
              label=""
              name={'valid'}
              value={valid}
              checked={valid === 'yes'}
              onChange={() => setValid('yes')}
            />
            <p style={{ marginLeft: '5px' }}>
              I have read and confirm, if suspended for misconduct, I will not
              be able to work on the Big Robin platform again, and new accounts
              will be closed.
            </p>
          </div>
          <div
            style={{
              position: 'absolute',
              top: '0px',
              right: '0px',
              cursor: 'pointer',
            }}
            onClick={() => setVisible(false)}
          >
            <RiCloseCircleFill color="grey" size={24} />
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginBottom: '10px',
          }}
        >
          <Button
            // outline
            style={{ width: 'fit-content' }}
            backgroundColor={
              !accurate || !confirm || !valid ? 'grey' : '#ff9e08'
            }
            fill
            onClick={handleSubmitButton}
            disabled={!accurate || !confirm || !valid}
          >
            Submit
          </Button>
        </div>
      </div>
    </Modal>
  );
};
export default SubmitConfirmPopup;
