import * as yup from 'yup';

export const validationSchema = yup.object({
  email: yup
    .string()
    .matches(
      /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w{2,4}([-.]\w+)*$/,
      'Enter a valid email'
    )
    .required('Email is required'),
  password: yup
    .string()
    .matches(
      /^(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+~()[*{!}=|]).*$/,
      'Minimum requirements for a valid password - 8 characters, 1 small letter, 1 capital letter, 1 number, 1 special character'
    )
    .required('Password is required'),
});
