import CheckboxGroup from 'components/CheckboxGroup';
import DropdownPopup from 'components/DropdownPopup';
import { Row, Column } from 'components/Layout';
import { useFormik } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import { FaBookOpen, FaDollarSign, FaIndustry } from 'react-icons/fa';
import {
  // CheckboxRow,
  PriceFilterDash,
  PriceInput,
  // RatingValue,
  SpecializationDropdownPopupRow,
} from 'components/ResearcherFilter/styled';
import { ProjectsFilterRow, PSDropdownPopup } from './styled';

import {
  INITIAL_PROJECT_FILTERS,
  // INDUSTRY_EXPERTISE,
  // SPECIALIZATION_MARKET,
  // SPECIALIZATION_ALGORITHMIC,
} from './ProjectsConstants';

const ProjectsFilter = ({
  className,
  filterValues,
  onFilterValueChange,
  options,
  onClear,
}) => {
  const [allow, setAllow] = useState(false);
  const tempFormik = useFormik({
    initialValues: filterValues,
    onSubmit: () => {},
  });
  const formik = useFormik({ initialValues: filterValues, onSubmit: () => {} });

  useEffect(() => {
    localStorage.setItem('allow', false);
    onFilterValueChange(formik.values);
  }, [formik.values]);

  const handleChangeVisibility = useCallback(
    names => {
      return (isShowingMenu, { submitted }) => {
        if (!isShowingMenu && !submitted) {
          for (const name of names) {
            if (tempFormik.values[name] !== formik.values[name]) {
              tempFormik.setFieldValue(name, formik.values[name]);
            }
          }
        }
      };
    },
    [formik.values, tempFormik]
  );

  const handleApply = useCallback(
    names => {
      return () => {
        if (
          names?.includes('minPrice') &&
          (tempFormik.values.minPrice > tempFormik.values.maxPrice ||
            tempFormik.values.minPrice === null ||
            tempFormik.values.maxPrice === null)
        ) {
          setAllow(true);
          localStorage.setItem('allow', true);
        } else {
          setAllow(false);
          localStorage.setItem('allow', false);
          for (const name of names) {
            formik.setFieldValue(name, tempFormik.values[name]);
          }
          onFilterValueChange(formik.values);
        }
      };
    },
    [formik, onFilterValueChange, tempFormik]
  );

  const handleClear = useCallback(
    names => {
      return () => {
        for (const name of names) {
          tempFormik.setFieldValue(name, INITIAL_PROJECT_FILTERS[name]);
          formik.setFieldValue(name, INITIAL_PROJECT_FILTERS[name]);
          onClear(name);
          switch (name) {
            case 'min_budget':
              tempFormik.setFieldValue(name, '');
              formik.setFieldValue(name, '');
              break;
            case 'max_budget':
              tempFormik.setFieldValue(name, '');
              formik.setFieldValue(name, '');
              break;
            case 'industry':
              tempFormik.setFieldValue(name, '');
              formik.setFieldValue(name, '');
              break;
            case 'mastery_score':
              tempFormik.setFieldValue(name, '');
              formik.setFieldValue(name, '');
              break;
            case 'specialization':
              tempFormik.setFieldValue(name, '');
              formik.setFieldValue(name, '');
              break;
            default:
              break;
          }
        }
      };
    },
    [formik, onClear, tempFormik]
  );

  return (
    <ProjectsFilterRow className={className}>
      <DropdownPopup
        icon={<FaDollarSign />}
        isHighlighted={formik.values.minPrice > 0 || formik.values.maxPrice > 0}
        label="Budget"
        onChangeVisibility={handleChangeVisibility(['minPrice', 'maxPrice'])}
        onApply={handleApply(['minPrice', 'maxPrice'])}
        onClear={handleClear(['minPrice', 'maxPrice'])}
      >
        <Row wrap="nowrap">
          <PriceInput
            label="Minimum Budget"
            type="number"
            name="minPrice"
            leftLabel={<FaDollarSign />}
            value={tempFormik.values.minPrice}
            onChange={e => {
              setAllow(false);
              localStorage.setItem('allow', false);
              let val = Number(e.target.value) || 0;
              tempFormik.setFieldValue('minPrice', val);
            }}
          />
          <PriceFilterDash>—</PriceFilterDash>
          <PriceInput
            label="Maximum Budget"
            type="number"
            name="maxPrice"
            leftLabel={<FaDollarSign />}
            value={tempFormik.values.maxPrice}
            onChange={e => {
              setAllow(false);
              localStorage.setItem('allow', false);
              let val = Number(e.target.value) || 0;
              tempFormik.setFieldValue('maxPrice', val);
            }}
          />
        </Row>
        {allow ? (
          <p
            style={{ color: '#ef5350', fontSize: '12px', textAlign: 'center' }}
          >
            Minimum Budget should be less than Maximum Budget
          </p>
        ) : (
          ''
        )}
      </DropdownPopup>
      <DropdownPopup
        icon={<FaIndustry />}
        label={`Industry (${formik.values.industry.length})`}
        isHighlighted={formik.values.industry.length > 0}
        onChangeVisibility={handleChangeVisibility(['industry'])}
        onApply={handleApply(['industry'])}
        onClear={handleClear(['industry'])}
      >
        <CheckboxGroup
          options={options.industry ? options.industry : []}
          optionLabels={option => (
            <span style={{ whiteSpace: 'pre' }}>{option}</span>
          )}
          name="industry"
          value={tempFormik.values.industry}
          // onChange={tempFormik.handleChange}
          onChange={event => {
            tempFormik.handleChange(event);
          }}
        />
      </DropdownPopup>
      <PSDropdownPopup
        icon={<FaBookOpen />}
        label={`Specialization (${formik.values.specialization.length})`}
        isHighlighted={formik.values.specialization.length > 0}
        onChangeVisibility={handleChangeVisibility(['specialization'])}
        onApply={handleApply(['specialization'])}
        onClear={handleClear(['specialization'])}
      >
        <SpecializationDropdownPopupRow>
          <Column>
            <h6>Market Research</h6>
            <CheckboxGroup
              options={options.market_research ? options.market_research : []}
              optionLabels={option => (
                <span style={{ whiteSpace: 'pre' }}>{option}</span>
              )}
              name="specialization"
              value={tempFormik.values.specialization}
              // onChange={tempFormik.handleChange}
              onChange={event => {
                tempFormik.handleChange(event);
              }}
            />
          </Column>
          <Column>
            <h6>Data Science/AI/ML</h6>
            <CheckboxGroup
              options={
                options.analytics_research ? options.analytics_research : []
              }
              optionLabels={option => (
                <span style={{ whiteSpace: 'pre' }}>{option}</span>
              )}
              name="specialization"
              value={tempFormik.values.specialization}
              // onChange={tempFormik.handleChange}
              onChange={event => {
                tempFormik.handleChange(event);
              }}
            />
          </Column>
          <Column>
            <h6>Research/ML Operations</h6>
            <CheckboxGroup
              options={options?.researcher_ml_operations || []}
              optionLabels={option => (
                <span style={{ whiteSpace: 'pre' }}>{option}</span>
              )}
              name="specialization"
              value={tempFormik.values.specialization}
              // onChange={tempFormik.handleChange}
              onChange={event => {
                tempFormik.handleChange(event);
              }}
            />
          </Column>
        </SpecializationDropdownPopupRow>
      </PSDropdownPopup>
      {/* <DropdownPopup
        icon={<FaMedal />}
        label={`Mastery (${formik.values.mastery.length})`}
        isHighlighted={formik.values.mastery.length > 0}
        onChangeVisibility={handleChangeVisibility(['mastery'])}
        onApply={handleApply(['mastery'])}
        onClear={handleClear(['mastery'])}
      >
        <CheckboxGroup
          options={['5', '4', '3', '2', '1']}
          optionLabels={option => {
            return (
              <CheckboxRow wrap="nowrap" alignItems="center">
                <FaMedal />
                <RatingValue>{option}</RatingValue>
              </CheckboxRow>
            );
          }}
          name="mastery"
          value={tempFormik.values.mastery}
          onChange={event => {
            tempFormik.handleChange(event);
          }}
        />
      </DropdownPopup> */}
    </ProjectsFilterRow>
  );
};

export default ProjectsFilter;
