import styled from 'styled-components/macro';
import { Row } from 'components/Layout';
import Button from 'components/Button';
import Checkbox from 'components/Checkbox';
import { CheckboxContainer, RadioDot } from 'components/Checkbox/styled';

export const RPCheckbox = styled(Checkbox)`
  ${CheckboxContainer} {
    min-height: 1.5rem;
    min-width: 1.5rem;
    margin-right: 0;

    @media (max-width: 768px) {
      min-height: 2.2rem;
      min-width: 2.2rem;
    }
  }

  ${RadioDot} {
    min-height: 1.1rem;
    min-width: 1.1rem;

    @media (max-width: 768px) {
      min-height: 1.5rem;
      min-width: 1.5rem;
    }
  }
`;

export const ProjectFormContent = styled.div`
  background-color: white;
  padding: 6rem 8rem;

  @media (max-width: 768px) {
    padding: 4rem 4rem;
  }
`;

export const ProjectContentTitle = styled.h5`
  font-size: 2.2rem;
  color: #4b4c5e;
`;

export const ProjectContentDesc = styled.p`
  font-size: 1.6rem;
  color: #65637b;
  margin-bottom: 2.5rem;

  & span {
    color: #ff9e08;
    cursor: pointer;
    text-decoration: underline;
  }
`;

export const LinkedInButton = styled(Button)`
  margin-bottom: 2rem;

  svg {
    margin-bottom: -0.3rem;
  }

  @media (max-width: 768px) {
    width: 100%;
    padding-top: 1.75rem;
    padding-bottom: 1.75rem;
    font-size: 2rem;
    margin-top: 2rem;
    margin-bottom: 4rem;

    svg {
      font-size: 2.5rem;
      margin-bottom: -0.5rem;
    }
  }
`;

export const FormRow = styled(Row)`
  width: 100%;
  grid-column-gap: 2rem;
  padding-bottom: 1.5rem;

  & > * {
    flex: 1;
  }

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const DateRow = styled(Row)`
  width: 100%;
  grid-column-gap: 2rem;

  & > * {
    flex: 1;
  }

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const CPCheckbox = styled(Checkbox)`
  margin-top: 1rem;

  @media (max-width: 768px) {
    margin-top: 1rem;
    margin-bottom: 2rem;
  }
`;

export const PageButtonsLayout = styled(Row)`
  justify-content: space-between;
  margin-top: 8rem;

  @media (max-width: 768px) {
    margin-top: 2rem;
    flex-direction: column;
  }
`;

export const PageButtonsGroup = styled(Row)`
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const LeftButton = styled(Button)`
  min-width: 12rem;
  text-transform: uppercase;
  margin-right: 3rem;

  @media (max-width: 768px) {
    margin: 1rem 0;
    padding-top: 1.75rem;
    padding-bottom: 1.75rem;
    font-size: 2rem;
    flex: 1;
  }
`;

export const RightButton = styled(Button)`
  min-width: 12rem;
  text-transform: uppercase;
  margin-left: 3rem;

  @media (max-width: 768px) {
    margin: 1rem 0;
    padding-top: 1.75rem;
    padding-bottom: 1.75rem;
    font-size: 2rem;
    flex: 1;
  }
`;
