import { Accordion, AccordionSummary, Typography } from '@mui/material';
import Breadcrumb from 'components/Breadcrumb/Breadcrumb';
import { format } from 'date-fns';
import {
  DeleteProjectButton,
  DeliverablesContainer,
  ProjectActionMenuButton,
  ProjectActionMenuItem,
  ProjectActionMenuRow,
  ProjectContainer,
  ProjectContent,
  ProjectDetailsContainer,
  ProjectNavbar,
  ProjectNavbarButton,
  ProjectTitle,
  ProjectUserDetails,
  RequirementsContainer,
} from 'pages/Project';
import { Deliverables } from 'pages/Project/Deliverables';
import { Details } from 'pages/Project/Details';
import { Requirements } from 'pages/Project/Requirements';
import React from 'react';
import { useState } from 'react';
import { FaArrowLeft, FaFilePdf, FaPencilAlt } from 'react-icons/fa';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { BriefDetails } from './BriefDetails';
import { RequirementsPreview } from './RequirementsPreview';
import { DeliverablesPreview } from './DeliverablesPreview';

const ProjectPreview = ({ handleProjectPreview, props, previewDataValues }) => {
  const [tab, setTab] = useState('Details');

  return (
    <div>
      <ProjectContent>
        <div
          style={{ cursor: 'pointer' }}
          onClick={() => handleProjectPreview()}
        >
          <FaArrowLeft size={12} style={{ marginRight: '10px' }} />
          BACK
        </div>
        {/* <Breadcrumb
          path={[
            { label: 'PREVIEW' },
            { label: props?.title ? props?.title.toUpperCase() : '' },
          ]}
        /> */}
        <ProjectTitle>{props?.title ? props?.title : ''}</ProjectTitle>
        {/* <ProjectUserDetails>
          <img
            src={
              user && user.avatar_link
                ? user.avatar_link
                : require('../../components/UserMenu/default.webp')
            }
            alt={user && user.first_name ? user.first_name : ''}
          />
          <Link to={`${user_id === user.id ? '/register' : '#'}`}>
            {user && user.first_name && user.last_name
              ? user.first_name + ' ' + user.last_name
              : user && user.first_name
              ? user.first_name
              : user && user.last_name
              ? user.last_name
              : ''}
          </Link>
          <span>
            {props.start_date
              ? format(new Date(props.start_date), 'MMM d, yyyy hh:mm a')
              : ''}
          </span>
        </ProjectUserDetails> */}
        {/* <ProjectActionMenuRow>
          <ProjectActionMenuItem>
            <FaFilePdf />
            Download Project Brief
          </ProjectActionMenuItem>
        </ProjectActionMenuRow> */}
        <ProjectContainer>
          <ProjectNavbar>
            <ProjectNavbarButton
              active={tab === 'Details'}
              onClick={() => {
                setTab('Details');
              }}
            >
              Project Details
            </ProjectNavbarButton>
            <ProjectNavbarButton
              active={tab === 'Expected Deliverables'}
              onClick={() => {
                setTab('Expected Deliverables');
              }}
            >
              Expected Deliverables
            </ProjectNavbarButton>
            <ProjectNavbarButton
              active={tab === 'Researcher Requirements'}
              onClick={() => {
                setTab('Researcher Requirements');
              }}
            >
              Researcher Requirements
            </ProjectNavbarButton>

            {/* <div style={{ paddingLeft: '0px' }}>
              <DeleteProjectButton disabled={true}>
                INVITE RESEARCHER
              </DeleteProjectButton>
            </div>
            <div style={{ paddingLeft: '0px' }}>
              <DeleteProjectButton disabled={true}>
                EDIT PROJECT
              </DeleteProjectButton>
            </div>
            <div style={{ paddingLeft: '0px' }}>
              <DeleteProjectButton disabled={true}>
                DUPLICATE PROJECT
              </DeleteProjectButton>
            </div>
            <div style={{ paddingLeft: '0px' }}>
              <DeleteProjectButton disabled={true}>
                DELETE PROJECT
              </DeleteProjectButton>
            </div>
            <div style={{ paddingLeft: '0px' }}>
              <Accordion className="accordianBox">
                <AccordionSummary
                  expandIcon={
                    <ExpandMoreIcon
                      style={{ color: '#FF9E08', fontSize: '2.5rem' }}
                    />
                  }
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>Invited Researchers</Typography>
                </AccordionSummary>
              </Accordion>
            </div>
            <div style={{ paddingLeft: '0px' }}>
              <Accordion className="accordianBox">
                <AccordionSummary
                  expandIcon={
                    <ExpandMoreIcon
                      style={{ color: '#FF9E08', fontSize: '2.5rem' }}
                    />
                  }
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>Proposal shared by</Typography>
                </AccordionSummary>
              </Accordion>
            </div>
            <div style={{ paddingLeft: '0px' }}>
              <Accordion className="accordianBox">
                <AccordionSummary
                  expandIcon={
                    <ExpandMoreIcon
                      style={{ color: '#FF9E08', fontSize: '2.5rem' }}
                    />
                  }
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>Selected Researchers</Typography>
                </AccordionSummary>
              </Accordion>
            </div> */}
          </ProjectNavbar>
          {tab === 'Details' && (
            <ProjectDetailsContainer>
              <BriefDetails
                project={props}
                previewDataValues={previewDataValues}
              />
            </ProjectDetailsContainer>
          )}
          {tab === 'Expected Deliverables' && (
            <DeliverablesContainer>
              <DeliverablesPreview
                project={props}
                previewDataValues={previewDataValues}
              />
            </DeliverablesContainer>
          )}
          {tab === 'Researcher Requirements' && (
            <RequirementsContainer>
              <RequirementsPreview
                project={props}
                projectMastery={props}
                previewDataValues={previewDataValues}
              />
            </RequirementsContainer>
          )}
        </ProjectContainer>
      </ProjectContent>
    </div>
  );
};

export default ProjectPreview;
