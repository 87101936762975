import Breadcrumb from 'components/Breadcrumb';
import { Loading } from 'components/Loading';
// import { ServiceArchitecture } from 'components/ServiceArchitecture';
import React, { useEffect, useState } from 'react';
import {
  // FaFileAlt,
  // FaLink,
  // FaMapMarkerAlt,
  FaStar,
  // FaStarHalf,
} from 'react-icons/fa';
// import { MdBook, MdLink } from 'react-icons/md';
import {
  Redirect,
  useParams,
  // useHistory
} from 'react-router-dom';
import { ajax } from 'utils/ajax';
import researchers from 'utils/researcher-data';
import * as SUserPage from './styled';
import Button from 'components/Button';
// import { Row, Column } from 'components/Layout';
import Textarea from 'components/Textarea';
import { useFormik } from 'formik';

function RateExperience() {
  const [isGettingUser, setIsGettingUser] = useState(true);
  const [user, setUser] = useState(null);
  const params = useParams();
  const { values, handleChange } = useFormik({
    initialValues: {
      recommendation: '',
    },
  });

  const data = {
    title: 'Researcher for FDG Focus Groups in Remote Areas',
    pathlabel: 'Rate Emmanuel',
    header: 'Rate your experience working with Emmanuel',
    subtitle:
      "Tell us and Emman's future colleagues about your working together",
    recommendation:
      "Do you want to recommend Emman to other people? If so, elaborating your researcher's qualities and output can be very helpful in landing his new opportunity.",
  };

  useEffect(() => {
    (async () => {
      const url =
        '/messages/conversation/member/' +
        parseInt(localStorage.getItem('br-userId')) +
        '/researcher/' +
        params.id;
      const response = await ajax('GET', `/member/${params.id}`);
      if (response.code === 200) {
        const convo_response = await ajax('GET', url);
        if (convo_response.code === 200) {
          response.data['conversation_id'] = convo_response.data['id'];
        }
        setUser(response.data);
      } else {
        setUser(researchers[+params.id]);
      }
      setIsGettingUser(false);
    })();
  }, [params.id]);

  if (isGettingUser) return <Loading />;

  if (!user) return <Redirect to="/researchers" />;

  return (
    <SUserPage.PageContent>
      <Breadcrumb
        path={[
          { label: 'RESEARCHERS', to: '/researchers' },
          { label: data.title.toUpperCase() },
          { label: data.pathlabel.toUpperCase() },
        ]}
      />
      <SUserPage.Title>{data.header}</SUserPage.Title>
      <SUserPage.Container>
        <SUserPage.Subtitle> {data.subtitle} </SUserPage.Subtitle>
        <SUserPage.RatingsLayout>
          <SUserPage.RatingsContainer>
            <SUserPage.RatingsTitle>
              <SUserPage.RatingStarsLayout>
                <SUserPage.RatingStarsContainer>
                  <SUserPage.RatingStarsTitleContainer>
                    Quality
                  </SUserPage.RatingStarsTitleContainer>
                  <FaStar /> <FaStar /> <FaStar /> <FaStar /> <FaStar />
                </SUserPage.RatingStarsContainer>
                <SUserPage.RatingStarsContainer>
                  <SUserPage.RatingStarsTitleContainer>
                    Professionalism
                  </SUserPage.RatingStarsTitleContainer>
                  <FaStar /> <FaStar /> <FaStar /> <FaStar /> <FaStar />
                </SUserPage.RatingStarsContainer>
                <SUserPage.RatingStarsContainer>
                  <SUserPage.RatingStarsTitleContainer>
                    Expertise
                  </SUserPage.RatingStarsTitleContainer>
                  <FaStar /> <FaStar /> <FaStar /> <FaStar /> <FaStar />
                </SUserPage.RatingStarsContainer>
                <SUserPage.RatingStarsContainer>
                  <SUserPage.RatingStarsTitleContainer>
                    Timeliness
                  </SUserPage.RatingStarsTitleContainer>
                  <FaStar /> <FaStar /> <FaStar /> <FaStar />
                </SUserPage.RatingStarsContainer>
              </SUserPage.RatingStarsLayout>
            </SUserPage.RatingsTitle>
          </SUserPage.RatingsContainer>
          <SUserPage.RatingsContainer>
            <SUserPage.RecomParagraph>
              {data.recommendation}
            </SUserPage.RecomParagraph>
            <SUserPage.RecomParagraph>
              <Textarea
                label=" "
                maxCharacters={1000}
                name="recommendation"
                value={values.recommendation}
                onChange={handleChange}
              />
            </SUserPage.RecomParagraph>
          </SUserPage.RatingsContainer>
        </SUserPage.RatingsLayout>
        <SUserPage.SubmitRatingButton>
          <Button style={{ margin: '10rem' }}>SUBMIT RATING</Button>
        </SUserPage.SubmitRatingButton>
      </SUserPage.Container>
    </SUserPage.PageContent>
  );
}

export default RateExperience;
