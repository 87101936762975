import Button from 'components/Button';
import styled from 'styled-components/macro';

export const FeedbackButton = styled(Button)`
  position: fixed;
  right: 0;
  top: 60%;
  cursor: pointer;
  min-width: 33px;
  transform: rotate(-180deg);
  border-radius: 0 10px 10px 0;
  padding: 2rem 1rem;

  @media (max-width: 600px) {
    min-width: 24px;
  }
`;
