import React from 'react';
import {
  AgreementContent,
  AgreementLayout,
  AgreementTitle,
  AgreementSubTitle,
  AgreementTextBlock,
  AgreementBlockTitle,
} from '../Agreements/styled';

export const BorderlessTc = () => {
  return (
    <>
      <AgreementContent>
        <AgreementLayout>
          <AgreementTitle>Borderless - our payout partner!</AgreementTitle>
          <section>
            <AgreementTextBlock>
              Borderless is our US-based Researcher payout partner that uses
              bank-grade encryption to move money collected by Stripe (our
              Client-side payment partner) to Researcher’s local banks through
              more efficient and secure bank to bank transfers globally.
            </AgreementTextBlock>
          </section>
          <section>
            <AgreementTextBlock>
              <i>
                borderless™ won Payments Tech Of The Year at the US Fintech
                Awards 2022 -{' '}
                <a
                  href="https://usfintechawards.com/winners-2022/?dm_i=FOB%2C80Z58%2C8PAXXK%2CWU539%2C1"
                  target={'_blank'}
                  style={{ fontSize: '14px' }}
                >
                  learn more
                </a>
              </i>
            </AgreementTextBlock>
            <AgreementTextBlock>
              <i>
                borderless™ won VISA DIRECT at the VISA Everywhere Initiative
                2022 -{' '}
                <a
                  href="https://techcrunch.com/sponsor/visa-everywhere-initiative/north-america-startups-compete-to-transform-the-future-of-fintech-with-visa-everywhere-initiative/?guccounter=1&guce_referrer=aHR0cHM6Ly9nZXRib3JkZXJsZXNzLmNvbS8&guce_referrer_sig=AQAAAHnmYyjfV1jmoXWFOQhpY32ZPrebbg-SvJFmdMFeqSx0LWJa7OH0LIOS9c6aZW2zBpyPkFv4Lnxq3xi2btxEKguxJK8M62MNVT3-jGrWEAYuyBB4Tn4sqGiBQlv7f77HK6FF65OFaLx5R6Vv3h6K3UvheLI3XbVxn4cTWzO2ietC"
                  target={'_blank'}
                  style={{ fontSize: '14px' }}
                >
                  learn more
                </a>
              </i>
            </AgreementTextBlock>
            <AgreementTextBlock>
              Borderless is required to collect, use, encrypt and securely store
              your data to comply with government laws and regulations,
              including global Know Your Customer, Anti-Money Laundering acts,
              and new U.S. IRS freelance income reporting mandates.
            </AgreementTextBlock>
            <AgreementTextBlock>
              <i>
                Learn more about borderless security{' '}
                <a href="https://getborderless.com/security/" target={'_blank'}>
                  here
                </a>
              </i>
            </AgreementTextBlock>
            <AgreementTextBlock>
              And more specifically about the data they collect here:
              <div>
                <div style={{ marginBottom: '8px' }}>
                  <i>
                    <a
                      href="https://getborderless.com/knowledge-base/why-do-i-need-to-add-my-tax-id-number-tin-or-social-security-number/"
                      target={'_blank'}
                    >
                      Why do I need to add my Tax ID Number (TIN) or Social
                      Security Number? | borderless Global Payout Platform{' '}
                    </a>
                  </i>
                </div>
                <div>
                  <i>
                    <a
                      href="https://getborderless.com/knowledge-base/why-do-i-need-to-upload-a-scan-or-photo-of-my-passport-or-drivers-license/"
                      target={'_blank'}
                    >
                      Why do I need to upload a photo of my passport or driver's
                      license? | borderless Global Payout Platform
                    </a>
                  </i>
                </div>
              </div>
            </AgreementTextBlock>
          </section>
        </AgreementLayout>
      </AgreementContent>
    </>
  );
};
