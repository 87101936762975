import { CheckboxLayout } from 'components/Checkbox/styled';
import { InputContainer } from 'components/Input/styled';
import styled from 'styled-components/macro';

export const CheckboxGroupContainer = styled(InputContainer)`
  ${CheckboxLayout} {
    margin-bottom: 1rem;

    @media (max-width: 768px) {
      margin-bottom: 2rem;
    }
  }
`;
