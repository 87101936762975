import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';
import { Row } from 'components/Layout';

export const CarouselSectionContainer = styled.div`
  position: relative;
  margin-bottom: 12rem;
`;

export const Title = styled.h3`
  color: #4b4c5e;
  font-size: 3.5rem;
  margin-bottom: 4rem;

  @media (max-width: 640px) {
    text-align: center;
  }
`;

export const CarouselLayout = styled.div`
  display: grid;
  justify-content: space-between;
  grid-template-columns: 16rem 16rem 16rem 16rem 16rem 16rem;
  grid-row-gap: 4rem;
  grid-column-gap: auto;

  @media (max-width: 1100px) {
    grid-template-columns: 16rem 16rem 16rem 16rem 16rem;
  }

  @media (max-width: 900px) {
    grid-template-columns: 16rem 16rem 16rem 16rem;
  }

  @media (max-width: 640px) {
    grid-template-columns: 16rem 16rem 16rem;
    justify-content: space-evenly;
  }

  @media (max-width: 400px) {
    grid-template-columns: 16rem 16rem;
  }
`;

export const CarouselCard = styled(Link)`
  background-color: ${props => props.color};
  height: 24rem;
  border-radius: 0.75rem;
  position: relative;
  text-decoration: none;

  @media (max-width: 640px) {
    height: 20rem;
  }

  ${Row} {
    padding: 1rem;
    width: calc(100% - 2rem);
    position: absolute;
    bottom: 0;
    align-items: center;
    justify-content: center;
    font-size: 2rem;
    font-weight: bold;
    color: white;
    z-index: 1;
    text-align: center;
  }
`;

export const CarouselCardContent = styled.h5`
  width: 100%;
  height: 50%;

  p {
    padding: 2rem 1rem;
    text-align: center;
    font-size: 1.75rem;
    color: ${props => props.textColor};
    word-wrap: break-word;

    @media (max-width: 640px) {
      padding: 1rem;
      font-size: 1.5rem;
    }
  }
`;

export const CarouselCardImage = styled.div`
  width: 100%;
  height: 50%;
  border-radius: 0.75rem 0.75rem 0 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fafafa;
  box-shadow: 0px 3px 6px #00000029;

  @media (max-width: 640px) {
    background-color: transparent;
    box-shadow: none;
  }

  img {
    height: 80%;
  }
`;
