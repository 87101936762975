import Footer from 'components/Footer';
import Navbar from 'components/Navbar';
import NavbarFooter from 'components/NavbarFooter';
import { PrivateRoute } from 'components/PrivateRoute';
import ScrollToTop from 'components/ScrollToTop';
import { AuthProvider, useAuthState } from 'context/AuthContext';
import Home from 'pages/Home';
import Login from 'pages/Login';
import RegistrationRoute from 'pages/RegistrationRoute';
import Researchers from 'pages/Researchers';
import ResearcherSpecialization from 'pages/ResearcherSpecialization';
import UserPage from 'pages/UserPage/UserPage';
import Welcome from 'pages/Welcome';
import About from 'pages/About';
import { TermsAndCondition, PrivacyPolicy } from 'pages/Agreements';
import Fees from 'pages/Fees/Fees';
import React from 'react';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';
import { ThemeProvider } from 'styled-components/macro';
import GlobalStyle from 'styled/GlobalStyle';
import theme from 'styled/theme';
import { CreateProject } from 'pages/CreateProject';
import { EditProject } from 'pages/EditProject';
import { Messages } from 'pages/Messages';
import { ForgotPassword } from 'pages/ForgotPassword';
import { ResetPassword } from 'pages/ResetPassword';
import Projects from 'pages/Projects';
import MyProjects from 'pages/MyProjects';
import MyProjectsShare from 'pages/MyProjectsShare';
import SearchResults from 'pages/SearchResults';
import { Project } from 'pages/Project';
import { ShareProject } from 'pages/ShareProject';
import ResearcherRating from 'pages/ResearcherRating';
import RateExperience from 'pages/RateExperience';
import { MemberSubscription } from 'pages/MemberSubscription';
import { ProjectPayment } from 'pages/ProjectPayment';
import { ProjectPaymentConfirmed } from 'pages/ProjectPaymentConfirmed';
import { LinkedInPopUp } from 'react-linkedin-login-oauth2';
// import Feedback from 'components/Feedback';
import { ProjectPaymentFinal } from 'pages/ProjectPaymentFinal';
import { NdaTEmplate } from 'pages/ResearcherProfileForm/NdaTEmplate';
import EarlySignUp from 'pages/EarlySignUp';
import ThankYou from 'pages/EarlySignUp/ThankYou';
import Feedback from 'components/Feedback';
import PublicRoute from 'components/PublicRoute';
import { PaymentStatus } from 'pages/PaymentStatus';
import { PaymentSuccess } from 'pages/PaymentSuccess';
import { BorderlessTc } from 'pages/ResearcherProfileForm/BorderlessTc';

const AppRouter = () => {
  const { isProfileCompleted } = useAuthState();

  return (
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <GlobalStyle />
        <Router>
          <ScrollToTop>
            <Navbar />
            <NavbarFooter />
            <Switch>
              <Route exact path="/" component={Home} />
              <PublicRoute exact path="/login" component={Login} />
              <PublicRoute exact path="/early-signup" component={EarlySignUp} />
              <PublicRoute exact path="/thank-you" component={ThankYou} />
              <Route exact path="/about" component={About} />
              <PublicRoute
                exact
                path="/signin-linkedin"
                component={LinkedInPopUp}
              />
              <Route exact path="/fees" component={Fees} />
              <Route
                exact
                path="/terms-and-condition"
                component={TermsAndCondition}
              />
              <Route exact path="/nda" component={NdaTEmplate} />
              <Route exact path="/privacy-policy" component={PrivacyPolicy} />
              <Route exact path="/researchers" component={Researchers} />
              <Route exact path="/researcher/:id" component={UserPage} />
              <Route exact path="/forgot-password" component={ForgotPassword} />
              <Route exact path="/reset-password" component={ResetPassword} />

              <Route
                exact
                path="/researchers/specialization/:group/:slug"
                component={ResearcherSpecialization}
              />

              <Route
                exact
                path="/project/:id/rate"
                component={RateExperience}
              />

              <PrivateRoute
                exact
                path="/register"
                component={RegistrationRoute}
              />

              {/* {!isProfileCompleted ? (
                <PrivateRoute
                  exact
                  path="/register"
                  component={RegistrationRoute}
                />
              ) : (
                <> */}
              <PrivateRoute
                exact
                path="/register"
                component={RegistrationRoute}
              />
              <PrivateRoute exact path="/welcome" component={Welcome} />
              <PrivateRoute
                exact
                path="/researcher/:id/rating"
                component={ResearcherRating}
              />
              <PrivateRoute
                exact
                path="/create-project"
                component={CreateProject}
              />

              <PrivateRoute exact path="/messages" component={Messages} />

              <PrivateRoute exact path="/projects" component={Projects} />
              <PrivateRoute exact path="/project/:id" component={Project} />
              <PrivateRoute
                exact
                path="/project/:id/edit"
                component={EditProject}
              />

              <PrivateRoute
                exact
                path="/project/:id/share"
                component={ShareProject}
              />
              <PrivateRoute exact path="/my-projects" component={MyProjects} />
              <PrivateRoute
                exact
                path="/my-projects-share/:id"
                component={MyProjectsShare}
              />
              <PrivateRoute exact path="/search" component={SearchResults} />
              <PrivateRoute
                exact
                path="/search/:search_input"
                component={SearchResults}
              />
              <PrivateRoute
                exact
                path="/member-subscription/:id/project/:project_id"
                component={MemberSubscription}
              />
              <PrivateRoute
                exact
                path="/member-subscription/:id/:receiver_id"
                component={MemberSubscription}
              />
              <PrivateRoute
                exact
                path="/member-subscription"
                component={MemberSubscription}
              />
              <PrivateRoute
                exact
                path="/project-payment/:project_id/:researcher_id/:type/:message_id"
                component={ProjectPayment}
              />
              <PrivateRoute
                exact
                path="/:member_id/payment-successful-approve/:researcher_id"
                component={ProjectPaymentConfirmed}
              />
              <PrivateRoute
                exact
                path="/payment-status"
                component={PaymentStatus}
              />
              <PrivateRoute
                exact
                path="/payment-success"
                component={PaymentSuccess}
              />
              <PrivateRoute
                exact
                path="/:member_id/payment-successful-accept/:researcher_id"
                component={ProjectPaymentFinal}
              />
              <Route
                exact
                path="/borderless-forgot-change"
                component={BorderlessTc}
              />
              {/* </>
              )} */}
              <Redirect to="/" />
            </Switch>
            <Feedback />
            <Footer />
          </ScrollToTop>
        </Router>
      </AuthProvider>
    </ThemeProvider>
  );
};
export default AppRouter;
