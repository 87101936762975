import React, { useState } from 'react';
import {
  PageTitle,
  PageContainer,
  PageLabel,
  PlanContainer,
  SubmitButton,
  ChoosePlan,
} from './styled';
import { Row } from 'components/Layout';
import PlanCard from 'components/PlanCard';
import ReactTooltip from 'react-tooltip';
import Modal from 'components/Modal';
import Input from 'components/Input';
import { useFormik } from 'formik';
import Button from 'components/Button';
import { ajax } from 'utils/ajax';
import { useHistory, useParams } from 'react-router-dom';
import {
  FaChevronCircleLeft,
  FaCrown,
  FaMedal,
  FaRegWindowClose,
} from 'react-icons/fa';
import moment from 'moment';

export const MembershipPlan = props => {
  const [loading, setLoading] = useState(false);

  const [isPaymentProgressModal, setIsPaymentProgressModal] = useState(false);
  const [confirmNextPlanModal, setConfirmNextPlanModal] = useState(false);
  const [confirmCancelPlanModal, setConfirmCancelPlanModal] = useState(false);
  const [isFirstPaidPlanModal, setIsFirstPaidPlanModal] = useState(false);
  const [isPlanChangedModal, setIsPlanChangedModal] = useState(false);
  const [isWhy, setIsWhy] = useState(false);
  const history = useHistory();
  const params = useParams();
  const [response, setResponse] = useState(null);
  const [planId, setPlanId] = useState();
  const [showModal, setShowModal] = useState(false);
  const [error, setError] = useState(null);
  const [planChange, setPlanChange] = useState({});
  const [isPromoPlan, setIsPromoPlan] = useState(false);
  const [enterprisePlanSteps, setEnterprisePlanSteps] = useState(1);
  const { values, handleChange, handleSubmit, setFieldValue } = useFormik({
    initialValues: {
      promo_code: '',
    },
    onSubmit: async data => {
      setResponse(null);
      setLoading(true);
      try {
        // if (data.promo_code !== 'BIGROBINEXCLUSIVE999') {
        //   setError('Invalid Promo Code');
        // } else {
        setError(null);
        // props.continueToPayment(2);
        const payload = {
          promo_code: data.promo_code,
        };
        const res = await ajax('POST', '/check/promo-code', payload);
        // const res = await ajax(
        //   'POST',
        //   `/member/${params.id}/subscription/${planId}`,
        //   payload
        // );
        if (res?.code === 200) {
          if (res?.data?.is_active === true) {
            let planData = planChange;
            planData['amount'] = 0;
            planData['promo_code'] = data.promo_code;
            props.setSelectedPlan({
              amount: 0,
              promo_code: data.promo_code,
            });
            if (
              props?.currentPlan &&
              props?.selectedPlan?.amount === 999 &&
              !props?.isFreeMembershipPlan
            ) {
              props.requestChangedPlan();
            } else {
              await props.promoCodeSubscription(0, data?.promo_code);
            }
          }
        } else {
          setError(res?.message);
        }
        // }
        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.log(err);
      }
    },
  });

  const continueToSharing = () => {
    if (params.receiver_id) {
      history.push(`/my-projects-share/${params.receiver_id}`);
    } else {
      history.push(`/project/${params.project_id}/share`);
    }
  };

  const handleContinuePromoPlan = async promo_code => {
    if (
      props?.currentPlan &&
      !props?.isFreeMembershipPlan &&
      props?.selectedPlan?.amount === 999
    ) {
      const res = await props?.getChangedPlanData(promo_code);
      if (res.code === 200) {
        setEnterprisePlanSteps(4);
      }
    } else {
      handleSubmit();
    }
  };

  const handleContinuePaidPlan = async () => {
    if (
      props?.currentPlan &&
      !props?.isFreeMembershipPlan &&
      props?.selectedPlan?.amount === 999
    ) {
      const res = await props?.getChangedPlanData();
      if (res.code === 200) {
        setEnterprisePlanSteps(3);
      }
    } else if (
      props?.currentPlan &&
      props?.isFreeMembershipPlan &&
      props?.selectedPlan?.amount === 999
    ) {
      setEnterprisePlanSteps(3);
    } else {
      props.continueToPayment(2);
    }
  };

  const handleFinalConfirm = () => {
    if (props?.isPaymentProgress) {
      setIsPaymentProgressModal(true);
    } else {
      if (
        props?.currentPlan &&
        props?.isFreeMembershipPlan &&
        props?.selectedPlan?.amount === 999
      ) {
        props.continueToPayment(4);
      } else if (props?.currentPlan && props?.selectedPlan?.amount === 999) {
        props.requestChangedPlan();
      } else {
        props.continueToPayment(2);
      }
    }
  };

  const handleContinuePayment = async () => {
    if (props?.isPaymentProgress) {
      setIsPaymentProgressModal(true);
    } else {
      if (props?.currentPlan) {
        if (props?.isFreeMembershipPlan) {
          if (props?.selectedPlan?.amount === 999) {
            setShowModal(true);
          } else {
            setIsFirstPaidPlanModal(true);
          }
        } else {
          if (props?.isFreeMembershipPlan === 222) {
            setIsPlanChangedModal(true);
          }
          if (props?.selectedPlan?.amount !== props?.currentPlan) {
            if (props?.selectedPlan?.amount === 999) {
              setShowModal(true);
            } else {
              const res = await props?.getChangedPlanData();
              if (res.code === 200) {
                setConfirmNextPlanModal(true);
              }
            }
          } else {
            props.continueToPayment(2);
          }
        }
      } else if (props?.selectedPlan?.amount === 999) {
        setShowModal(true);
      } else if (
        props?.selectedPlan &&
        Object.keys(props?.selectedPlan).length !== 0
      ) {
        props.continueToPayment(2);
      }
    }
  };

  // const getFreeTrialButton = () => {
  //   props?.getFreeTrial();
  // };

  return (
    <>
      <PageTitle>Choose Membership Plan</PageTitle>
      <PageContainer>
        <PageLabel>
          Get your FREE first month of unlimited access to top-notch experts and
          unlock the benefits of a full membership.
          <div
            // data-tip
            // data-event="mouseenter click"
            // data-for="feesWhy"
            onClick={() => setIsWhy(true)}
            style={{
              color: '#ff9e08',
              cursor: 'pointer',
              marginLeft: '10px',
            }}
          >
            Why?
          </div>
          {/* <ReactTooltip globalEventOff="mouseout" id="feesWhy"></ReactTooltip> */}
        </PageLabel>

        {!props?.isTrialUsed && (
          <ChoosePlan>Choose a plan to proceed further</ChoosePlan>
        )}

        <PlanContainer>
          {props.plans.map((plan, idx) => {
            return (
              <PlanCard
                title={plan.title}
                amount={plan.amount}
                unit={plan.unit}
                type={plan.type}
                description={plan.description}
                selected={props?.selectedPlan?.id === plan?.id ? true : false}
                onClick={() => {
                  setResponse(null);
                  if (
                    plan.amount === 999 &&
                    props?.selectedPlan?.amount === props?.currentPlan
                  ) {
                    setFieldValue('promo_code', '');
                    setPlanId(plan.id);
                    props.setSelectedPlan(plan);
                    setPlanChange(plan);
                  } else props.setSelectedPlan(plan);
                }}
                key={plan.title}
                currentPlan={plan?.amount === props?.currentPlan}
                recommended={idx === props?.recommendedPlan}
              />
            );
          })}
        </PlanContainer>

        {props?.isTrialUsed === 2 && (
          <Row justifyContent="center">
            <div style={{ marginBottom: '16px', fontSize: '18px' }}>
              <FaMedal size={'13px'} color={'rgb(255, 158, 8)'} />
              <span style={{ marginLeft: '8px' }}>
                Start with your free 30-day trial
              </span>
            </div>
          </Row>
        )}

        {props?.isTrialUsed &&
          !props?.isFreeMembershipPlan &&
          props?.currentPlan &&
          props?.nextRenewDate && (
            <Row justifyContent="center">
              <div style={{ marginBottom: '16px', fontSize: '18px' }}>
                <FaCrown size={'15px'} color={'rgb(255, 158, 8)'} />
                <span style={{ marginLeft: '8px' }}>
                  Your membership will be auto-renewed on {props?.nextRenewDate}
                </span>
              </div>
            </Row>
          )}

        {props?.isFreeMembershipPlan &&
          props?.currentPlan &&
          props?.nextRenewDate && (
            <Row justifyContent="center">
              <div style={{ marginBottom: '16px', fontSize: '18px' }}>
                <FaCrown size={'15px'} color={'rgb(255, 158, 8)'} />
                <span style={{ marginLeft: '8px' }}>
                  Your free trial will end on {props?.nextRenewDate}
                  {props?.nextPlanData?.amount &&
                    `. Then, your upcoming  ${
                      props?.nextPlanData?.amount === 99
                        ? 'Classic plan of $99/month'
                        : props?.nextPlanData?.amount === 499
                        ? 'Premium plan of $499/6 months'
                        : 'Enterprise plan of $999/year'
                    } will begin.`}
                </span>
              </div>
            </Row>
          )}

        {!props?.isFreeMembershipPlan &&
          !props?.currentPlan &&
          props?.nextRenewDate && (
            <Row justifyContent="center">
              <div style={{ marginBottom: '16px', fontSize: '18px' }}>
                <FaCrown size={'15px'} color={'rgb(255, 158, 8)'} />
                <span style={{ marginLeft: '8px' }}>
                  {`=Your upcoming  ${
                    props?.nextPlanData?.amount === 99
                      ? 'Classic plan of $99/month'
                      : props?.nextPlanData?.amount === 499
                      ? 'Premium plan of $499/6 months'
                      : 'Enterprise plan of $999/year'
                  }  payment is in progress.`}
                </span>
              </div>
            </Row>
          )}

        <Row justifyContent="center">
          <SubmitButton
            disabled={!props?.currentPlan}
            style={{
              backgroundColor: '#4B4C5E',
              width: 'fit-content',
              marginRight: '20px',
            }}
            onClick={() => setConfirmCancelPlanModal(true)}
          >
            CANCEL SUBSCRIPTION
          </SubmitButton>
          {!props?.isTrialUsed ? (
            <SubmitButton
              disabled={
                props?.selectedPlan &&
                Object.keys(props?.selectedPlan).length === 0
              }
              onClick={props?.getFreeTrial}
            >
              START WITH YOUR FREE 30-DAY TRIAL
            </SubmitButton>
          ) : (
            <SubmitButton
              disabled={
                (props?.selectedPlan &&
                  Object.keys(props?.selectedPlan).length === 0) ||
                (props?.selectedPlan?.amount === props?.currentPlan &&
                  !props?.isFreeMembershipPlan) ||
                (props?.isFreeMembershipPlan && props?.nextPlanData?.amount)
              }
              onClick={() => {
                handleContinuePayment();
              }}
            >
              CONTINUE
            </SubmitButton>
          )}
        </Row>
      </PageContainer>
      <Modal
        visible={
          // true
          showModal
        }
        setVisible={setShowModal}
      >
        <div
          style={{
            backgroundColor: 'white',
            padding: '2rem',
            flexDirection: 'column',
            justifyContent: 'flex-end',
            maxWidth: '300px',
            minWidth: '300px',
            borderRadius: '12px',
          }}
        >
          <div style={{ position: 'relative', marginBottom: '20px' }}>
            <div
              style={{
                // marginBottom: '10px',
                display: 'flex',
                alignItems: 'center',
                width: 'fit-content',
                cursor: enterprisePlanSteps > 1 ? 'pointer' : 'not-allowed',
              }}
              onClick={() => {
                if (enterprisePlanSteps > 1) {
                  setEnterprisePlanSteps(1);
                }
              }}
            >
              <FaChevronCircleLeft color="#65637B" />
              <span
                style={{
                  marginLeft: '5px',
                  fontSize: '15px',
                  fontWeight: 'bold',
                }}
              >
                ENTERPRISE PLAN
              </span>
            </div>
            <div
              style={{
                position: 'absolute',
                top: '0',
                bottom: '0',
                right: '0',
                cursor: 'pointer',
              }}
              onClick={() => {
                setShowModal(false);
                setEnterprisePlanSteps(1);
              }}
            >
              <FaRegWindowClose color="#65637B" title="Close" />
            </div>
          </div>
          {enterprisePlanSteps === 1 ? (
            <div>
              <div
                style={{
                  textAlign: 'center',
                  color: '#ff9e08',
                  cursor: 'pointer',
                  marginBottom: '10px',
                  border: '1px solid #ff9e08',
                  padding: '6px 12px',
                  borderRadius: '4px',
                  width: 'fit-content',
                }}
                onClick={() => setEnterprisePlanSteps(2)}
              >
                Have a promocode?
              </div>
              <div
                style={{
                  textAlign: 'center',
                  color: '#ff9e08',
                  cursor: 'pointer',
                  border: '1px solid #ff9e08',
                  padding: '6px 12px',
                  borderRadius: '4px',
                  width: 'fit-content',
                }}
                onClick={() => {
                  handleContinuePaidPlan();
                }}
              >
                Continue without promocode
              </div>
            </div>
          ) : enterprisePlanSteps === 2 ? (
            <div>
              <div style={{ marginBottom: '2rem' }}>
                <Input
                  label="Enter Promo Code"
                  name="promo_code"
                  value={values.promo_code}
                  onChange={handleChange}
                />
                {error && (
                  <p style={{ color: 'red', marginTop: '10px' }}>{error}</p>
                )}
              </div>

              <div>
                <Button
                  fill
                  style={{
                    marginBottom: '1rem',
                    backgroundColor: '#ff9e08',
                  }}
                  disabled={!values.promo_code || loading}
                  onClick={() => {
                    handleContinuePromoPlan(values.promo_code);
                  }}
                >
                  Continue
                </Button>
              </div>
            </div>
          ) : enterprisePlanSteps === 3 ? (
            <div>
              <div style={{ marginBottom: '20px', textAlign: 'justify' }}>
                {!isPromoPlan ? (
                  props?.currentPlan && props?.isFreeMembershipPlan ? (
                    <div>
                      This plan will be applicable after the completion of your
                      free 30-day trial period, which is about to expire on{' '}
                      {props?.nextRenewDate}. Your payment will be deducted on
                      the same date. <br /> <br /> Are you sure you want to
                      proceed?
                    </div>
                  ) : props?.currentPlan ? (
                    <div style={{ marginBottom: '2rem', textAlign: 'justify' }}>
                      {props?.changePlanData?.change_type === 'downgrade'
                        ? `In order to update your membership, the remaining amount of $${props?.changePlanData?.refund_amount}, which is the remaining amount after deducting the used amount from $${props?.currentPlan} & the charges for the updated plan will be adjusted with the account associated with xxxxxxxxxxxx${props?.changePlanData?.payment_digits}. Are you sure you want to continue?`
                        : props?.changePlanData?.change_type === 'upgrade'
                        ? `In order to update your membership, the outstanding amount of the outstanding amount of $${props?.changePlanData?.amount_due}, which is the remaining amount after deducting the used amount from $${props?.currentPlan} will be deducted from your card xxxxxxxxxxxx${props?.changePlanData?.payment_digits}. Are you sure you want to continue?`
                        : 'This plan will be applicable from today.'}
                    </div>
                  ) : (
                    ''
                  )
                ) : props?.currentPlan && props?.isFreeMembershipPlan ? (
                  <div>
                    This plan will be applicable after the completion of your
                    free 30-day trial period, which is about to expire on{' '}
                    {props?.nextRenewDate}. <br /> <br /> Are you sure you want
                    to proceed?
                  </div>
                ) : (
                  <div style={{ marginBottom: '2rem', textAlign: 'justify' }}>
                    {'This plan will be applicable from today.'}
                  </div>
                )}
              </div>
              <div>
                <Button
                  fill
                  style={{
                    // marginBottom: '1rem',
                    backgroundColor: '#ff9e08',
                  }}
                  onClick={() => {
                    handleFinalConfirm();
                  }}
                >
                  Continue
                </Button>
              </div>
            </div>
          ) : enterprisePlanSteps === 4 ? (
            <div>
              <div style={{ marginBottom: '20px', textAlign: 'justify' }}>
                {!isPromoPlan ? (
                  props?.currentPlan && props?.isFreeMembershipPlan ? (
                    <div>
                      This plan will be applicable after the completion of your
                      free 30-day trial period, which is about to expire on{' '}
                      {props?.nextRenewDate}. <br /> <br /> Are you sure you
                      want to proceed?
                    </div>
                  ) : props?.currentPlan ? (
                    <div style={{ marginBottom: '2rem', textAlign: 'justify' }}>
                      {props?.changePlanData?.change_type === 'downgrade'
                        ? `In order to update your membership, the remaining amount of $${props?.changePlanData?.refund_amount}, which is the remaining amount after deducting the used amount from $${props?.currentPlan} & the charges for the updated plan will be adjusted with the account associated with xxxxxxxxxxxx${props?.changePlanData?.payment_digits}. Are you sure you want to continue?`
                        : props?.changePlanData?.change_type === 'upgrade'
                        ? `In order to update your membership, the outstanding amount of the outstanding amount of $${props?.changePlanData?.amount_due}, which is the remaining amount after deducting the used amount from $${props?.currentPlan} will be deducted from your card xxxxxxxxxxxx${props?.changePlanData?.payment_digits}. Are you sure you want to continue?`
                        : 'This plan will be applicable from today.'}
                    </div>
                  ) : (
                    ''
                  )
                ) : props?.currentPlan && props?.isFreeMembershipPlan ? (
                  <div>
                    This plan will be applicable after the completion of your
                    free 30-day trial period, which is about to expire on{' '}
                    {props?.nextRenewDate}. <br /> <br /> Are you sure you want
                    to proceed?
                  </div>
                ) : (
                  <div style={{ marginBottom: '2rem', textAlign: 'justify' }}>
                    {'This plan will be applicable from today.'}
                  </div>
                )}
              </div>
              <div>
                <Button
                  fill
                  style={{
                    // marginBottom: '1rem',
                    backgroundColor: '#ff9e08',
                  }}
                  onClick={() => {
                    handleFinalConfirm();
                  }}
                >
                  Continue
                </Button>
              </div>
            </div>
          ) : null}
          {/* <div
            style={{
              textAlign: 'right',
              color: '#ff9e08',
              cursor: 'pointer',
              textDecoration: 'underline',
              marginBottom: '10px',
            }}
            onClick={() => setIsPromoPlan(!isPromoPlan)}
          >
            {isPromoPlan ? "Don't have a promocode?" : 'Have a promocode?'}
          </div>
          <div style={{ marginBottom: '20px', textAlign: 'justify' }}>
            {!isPromoPlan ? (
              props?.currentPlan && props?.isFreeMembershipPlan ? (
                <div>
                  This plan will be applicable after the completion of your free
                  30-day trial period, which is about to expire on{' '}
                  {props?.nextRenewDate}. Your payment will be deducted on the
                  same date. <br /> <br /> Are you sure you want to proceed?
                </div>
              ) : props?.currentPlan ? (
                <div style={{ marginBottom: '2rem', textAlign: 'justify' }}>
                  {props?.changePlanData?.change_type === 'downgrade'
                    ? `In order to update your membership, the remaining amount of $${props?.changePlanData?.refund_amount}, which is the remaining amount after deducting the used amount from $${props?.currentPlan} & the charges for the updated plan will be adjusted with the account associated with xxxxxxxxxxxx${props?.changePlanData?.payment_digits}. Are you sure you want to continue?`
                    : props?.changePlanData?.change_type === 'upgrade'
                    ? `In order to update your membership, the outstanding amount of the outstanding amount of $${props?.changePlanData?.amount_due}, which is the remaining amount after deducting the used amount from $${props?.currentPlan} will be deducted from your card xxxxxxxxxxxx${props?.changePlanData?.payment_digits}. Are you sure you want to continue?`
                    : 'This plan will be applicable from today.'}
                </div>
              ) : (
                ''
              )
            ) : props?.currentPlan && props?.isFreeMembershipPlan ? (
              <div>
                This plan will be applicable after the completion of your free
                30-day trial period, which is about to expire on{' '}
                {props?.nextRenewDate}. <br /> <br /> Are you sure you want to
                proceed?
              </div>
            ) : props?.currentPlan ? (
              <div style={{ marginBottom: '2rem', textAlign: 'justify' }}>
                {'This plan will be applicable from today.'}
              </div>
            ) : (
              ''
            )}
          </div> */}

          {/* {isPromoPlan && (
            <div style={{ marginBottom: '2rem' }}>
              {!response ? (
                <>
                  <Input
                    label="Input Promo Code"
                    name="promo_code"
                    value={values.promo_code}
                    onChange={handleChange}
                  />
                  {error && (
                    <p style={{ color: 'red', marginTop: '10px' }}>{error}</p>
                  )}
                </>
              ) : (
                <>
                  <h6>
                    {response.code === 200 && response?.data?.is_active === true
                      ? 'Successfully subscribed!'
                      : response.message}
                  </h6>
                </>
              )}
            </div>
          )} */}

          {/* <div>
            {!response ? (
              <>
                {isPromoPlan ? (
                  <Button
                    fill
                    style={{
                      marginBottom: '1rem',
                      backgroundColor: '#ff9e08',
                    }}
                    disabled={!values.promo_code || loading}
                    onClick={() => {
                      handleSubmit();
                    }}
                  >
                    Continue
                  </Button>
                ) : (
                  <Button
                    fill
                    style={{
                      marginBottom: '1rem',
                      backgroundColor: '#ff9e08',
                    }}
                    onClick={() => {
                      if (props?.isPaymentProgress) {
                        setIsPaymentProgressModal(true);
                      } else {
                        if (
                          props?.currentPlan &&
                          props?.isFreeMembershipPlan &&
                          props?.selectedPlan?.amount === 999
                        ) {
                          props.continueToPayment(4);
                        } else if (
                          props?.currentPlan &&
                          props?.selectedPlan?.amount === 999
                        ) {
                          props.requestChangedPlan();
                        } else {
                          props.continueToPayment(2);
                        }
                      }
                    }}
                  >
                    Continue
                  </Button>
                )}
              </>
            ) : (
              <Button
                fill
                style={{
                  marginBottom: '1rem',
                  backgroundColor: '#ff9e08',
                }}
                disabled={!values.promo_code}
                onClick={() => {
                  setError('');
                  if (
                    response.code === 200 &&
                    response?.data?.is_active === true
                  )
                    continueToSharing();
                  else setShowModal(false);
                }}
              >
                {response.code === 200 && response?.data?.is_active === true
                  ? 'Proceed to sharing'
                  : 'Ok'}
              </Button>
            )}
          </div> */}
        </div>
      </Modal>
      <Modal
        visible={isPaymentProgressModal}
        setVisible={setIsPaymentProgressModal}
      >
        <div
          style={{
            backgroundColor: 'white',
            padding: '2rem',
            flexDirection: 'column',
            justifyContent: 'flex-end',
            maxWidth: '300px',
            borderRadius: '12px',
          }}
        >
          <div style={{ marginBottom: '2rem', textAlign: 'justify' }}>
            The last payment done by you to purchase{' '}
            <b>
              {props?.currentPlan === 99
                ? 'Classic plan $99/month'
                : props?.currentPlan === 499
                ? 'Premium plan $499/6months'
                : props?.currentPlan === 999
                ? 'Enterprize plan $999/year'
                : '-'}
            </b>{' '}
            is in progress. You can try again after that payment gets completed.
          </div>
          <div style={{ textAlign: 'center' }}>
            <Button
              fill
              style={{
                marginBottom: '1rem',
                backgroundColor: '#ff9e08',
                width: 'fit-content',
              }}
              onClick={() => setIsPaymentProgressModal(false)}
            >
              Close
            </Button>
          </div>
        </div>
      </Modal>
      <Modal visible={isWhy} setVisible={setIsWhy}>
        <div
          style={{
            backgroundColor: 'white',
            padding: '3rem',
            flexDirection: 'column',
            justifyContent: 'flex-end',
            maxWidth: '300px',
            borderRadius: '12px',
          }}
        >
          {/* <div style={{ textAlign: 'justify' }}>
            Please note that you can cancel your membership anytime. However,
            the fees will be non-refundable after the free 30-day trial period.
          </div> */}
          <div style={{ marginBottom: '2rem', textAlign: 'justify' }}>
            Your membership gives you elite access to only the best researchers
            in the world. Also, it lets researchers know you are seriously
            invested in a mutually beneficial collaboration. You can cancel your
            membership anytime. However, fees are non-refundable after the free
            30-day trial period.
          </div>
          <div style={{ textAlign: 'center' }}>
            <Button
              fill
              style={{
                backgroundColor: '#ff9e08',
                width: 'fit-content',
              }}
              onClick={() => setIsWhy(false)}
            >
              Close
            </Button>
          </div>
        </div>
      </Modal>
      <Modal
        visible={confirmNextPlanModal}
        setVisible={setConfirmNextPlanModal}
      >
        <div
          style={{
            backgroundColor: 'white',
            padding: '3rem',
            flexDirection: 'column',
            justifyContent: 'flex-end',
            maxWidth: '300px',
            borderRadius: '12px',
          }}
        >
          <div style={{ marginBottom: '2rem', textAlign: 'justify' }}>
            {props?.changePlanData?.change_type === 'downgrade' &&
            props?.changePlanData?.payment_method
              ? `In order to update your membership, the remaining amount of $${props?.changePlanData?.refund_amount}, which is the remaining amount after deducting the used amount from $${props?.currentPlan} & the charges for the updated plan will be adjusted with the account associated with xxxxxxxxxxxx${props?.changePlanData?.payment_digits}. Are you sure you want to continue?`
              : props?.changePlanData?.change_type === 'upgrade' &&
                props?.changePlanData?.payment_method
              ? `In order to update your membership, the outstanding amount of the outstanding amount of $${props?.changePlanData?.amount_due}, which is the remaining amount after deducting the used amount from $${props?.currentPlan} will be deducted from your card xxxxxxxxxxxx${props?.changePlanData?.payment_digits}. Are you sure you want to continue?`
              : props?.changePlanData?.change_type === 'downgrade'
              ? `In order to update your membership plan, an amount of ${props?.changePlanData?.amount_due} will be charged. Are you sure you want to continue?`
              : 'This plan will be applicable from today.'}
          </div>
          <div style={{ textAlign: 'center' }}>
            <Button
              outline
              style={{
                width: 'fit-content',
              }}
              onClick={() => setConfirmNextPlanModal(false)}
            >
              No
            </Button>
            <Button
              fill
              style={{
                backgroundColor: '#ff9e08',
                width: 'fit-content',
                marginLeft: '20px',
              }}
              onClick={() => props?.requestChangedPlan()}
            >
              Yes
            </Button>
          </div>
        </div>
      </Modal>
      <Modal
        visible={isFirstPaidPlanModal}
        setVisible={setIsFirstPaidPlanModal}
      >
        <div
          style={{
            backgroundColor: 'white',
            padding: '3rem',
            flexDirection: 'column',
            justifyContent: 'flex-end',
            minWidth: '280px',
            maxWidth: '300px',
            borderRadius: '12px',
          }}
        >
          <div style={{ marginBottom: '2rem', textAlign: 'justify' }}>
            The paid plan will be applicable after the completion of your free
            30-day trial period, which is about to expire on{' '}
            {props?.nextRenewDate}. Your payment will be deducted on the same
            date. Are you sure you want to proceed?
          </div>
          <div style={{ textAlign: 'center' }}>
            <Button
              outline
              style={{
                width: 'fit-content',
              }}
              onClick={() => setIsFirstPaidPlanModal(false)}
            >
              No
            </Button>
            <Button
              style={{
                width: 'fit-content',
                marginLeft: '20px',
              }}
              onClick={() => {
                props.continueToPayment(4);
              }}
            >
              Yes
            </Button>
          </div>
        </div>
      </Modal>
      <Modal
        visible={confirmCancelPlanModal}
        setVisible={setConfirmCancelPlanModal}
      >
        <div
          style={{
            backgroundColor: 'white',
            padding: '3rem',
            flexDirection: 'column',
            justifyContent: 'flex-end',
            minWidth: '280px',
            maxWidth: '300px',
            borderRadius: '12px',
          }}
        >
          <div style={{ marginBottom: '2rem', textAlign: 'justify' }}>
            {props?.isFreeMembershipPlan
              ? !props?.nextPlanData?.amount
                ? `Your free 30-day trial will expire on  ${props?.nextRenewDate}. Are you sure you want to cancel the membership?`
                : `Your free 30-day trial will expire on ${
                    props?.nextRenewDate
                  }. Then, your upcoming ${
                    props?.nextPlanData?.amount === 99
                      ? 'Classic plan of $99/month'
                      : props?.nextPlanData?.amount === 499
                      ? 'Premium plan of $499/6 months'
                      : 'Enterprise plan of $999/year'
                  } will begin. 
Cancelling the membership will result in cancellation of both. Are you sure you want to proceed?`
              : `Your membership plan about to expire on ${props?.nextRenewDate}
            will expire now. The fees will be non-refundable. Are you sure you
            want to cancel the membership?`}
          </div>
          <div style={{ textAlign: 'center' }}>
            <Button
              outline
              style={{
                width: 'fit-content',
              }}
              onClick={() => setConfirmCancelPlanModal(false)}
            >
              No
            </Button>
            <Button
              style={{
                width: 'fit-content',
                marginLeft: '20px',
              }}
              onClick={props?.handleCancelSubscription}
            >
              Yes
            </Button>
          </div>
        </div>
      </Modal>
      <Modal visible={isPlanChangedModal} setVisible={setIsPlanChangedModal}>
        <div
          style={{
            backgroundColor: 'white',
            padding: '3rem',
            flexDirection: 'column',
            justifyContent: 'flex-end',
            minWidth: '280px',
            maxWidth: '300px',
            borderRadius: '12px',
          }}
        >
          <div style={{ marginBottom: '2rem', textAlign: 'justify' }}>
            {props?.isFreeMembershipPlan
              ? `Your 30-day free trial about to expire on  ‘${props?.nextRenewDate}’ will expire now. Are you sure you want to cancel the membership?`
              : `Your membership plan about to expire on ‘${props?.nextRenewDate}’
            will expire now. The fees will be non-refundable. Are you sure you
            want to cancel the membership?`}
          </div>
          <div style={{ textAlign: 'center' }}>
            <Button
              outline
              style={{
                width: 'fit-content',
              }}
              onClick={() => setIsPlanChangedModal(false)}
            >
              No
            </Button>
            <Button
              style={{
                width: 'fit-content',
                marginLeft: '20px',
              }}
              onClick={() => props?.continueToPayment(2)}
            >
              Yes
            </Button>
          </div>
        </div>
      </Modal>

      <Modal visible={props?.trialConfirm} setVisible={props?.setTrialConfirm}>
        <div
          style={{
            backgroundColor: 'white',
            padding: '3rem',
            flexDirection: 'column',
            justifyContent: 'flex-end',
            minWidth: '280px',
            maxWidth: '300px',
            borderRadius: '12px',
          }}
        >
          {/* <div style={{ textAlign: 'justify' }}>
            Please note that you can cancel your membership anytime. However,
            the fees will be non-refundable after the free 30-day trial period.
          </div> */}
          <div style={{ marginBottom: '2rem', textAlign: 'justify' }}>
            Your free 30-day trial has been activated. It’ll expire on ‘
            {moment()
              .add(30, 'd')
              .format('DD MMM, YYYY')}
            ’. Enjoy the benefits of being a member!
          </div>
          <div style={{ textAlign: 'center' }}>
            <Button
              outline
              style={{
                width: 'fit-content',
              }}
              onClick={() => {
                window.location.reload();
                // props?.setTrialConfirm(false);
              }}
            >
              Close
            </Button>
            {/* <Button
              style={{
                width: 'fit-content',
                marginLeft: '20px',
              }}
              onClick={props?.handleCancelSubscription}
            >
              Yes
            </Button> */}
          </div>
        </div>
      </Modal>
    </>
  );
};
