import { CheckboxContainer, CheckboxLayout } from 'components/Checkbox/styled';
import { LabelLayout } from 'components/Input/styled';
import useClickOutside from 'hooks/useClickOutside';
import useNodeState from 'hooks/useNodeState';
import React, { useCallback, useLayoutEffect, useState } from 'react';
import { FaCaretDown, FaCheck } from 'react-icons/fa';
import {
  DropdownContainer,
  DropdownLayout,
  DropdownOption,
  DropdownOptionsContainer,
  DropdownValueLayout,
} from 'components/Dropdown/styled';
import {
  SpecializationDropdownHeader,
  SpecializationDropdownContainer,
} from './styled';

const SpecializationDropdown = ({
  name,
  className,
  label,
  optionLabels = {},
  render = value => optionLabels[value] || value,
  options,
  optionMap,
  value,
  onChange,
  onBlur,
  icon,
  disabled,
  style,
  error,
  errorMessage,
  required,
}) => {
  const [isShowingMenu, setIsShowingMenu] = useState(false);
  const [menuStyle, setMenuStyle] = useState({ top: 0, width: 0 });
  const [containerRefCB, containerEl, containerRef] = useNodeState();
  const [areAllSelected, setAllSelected] = useState(false);

  useClickOutside(
    containerRef,
    isShowingMenu,
    useCallback(() => {
      if (onBlur) {
        onBlur({ target: { name } });
      }
      setIsShowingMenu(false);
    }, [name, onBlur])
  );

  useLayoutEffect(() => {
    if (isShowingMenu && containerEl) {
      const box = containerEl.getBoundingClientRect();
      setMenuStyle({ top: box.height, width: box.width });
    }
  }, [containerEl, isShowingMenu]);

  useLayoutEffect(() => {
    setAllSelected(value?.length === options?.length);
  }, [value, options]);

  return (
    <DropdownContainer ref={containerRefCB} className={className} style={style}>
      <select name={name} onChange={onChange} onBlur={onBlur}>
        <option value="" />
        {options.map(option => {
          return (
            <option value={option} key={option}>
              {render(option)}
            </option>
          );
        })}
      </select>
      {label && (
        <LabelLayout>
          <label>
            {label} {required && <sup style={{ color: 'red' }}>*</sup>}
          </label>
        </LabelLayout>
      )}

      <DropdownLayout
        // error={error}
        onClick={() =>
          !disabled && setIsShowingMenu(isShowingMenu => !isShowingMenu)
        }
      >
        {icon || null}
        <DropdownValueLayout>
          {value && `${value?.length || 0} selected`}
        </DropdownValueLayout>
        {!disabled && <FaCaretDown />}
      </DropdownLayout>
      {isShowingMenu && options.length > 0 && (
        <DropdownOptionsContainer style={menuStyle}>
          {options.length > 0 && (
            <SpecializationDropdownContainer>
              <DropdownOption
                key={'Select All'}
                onClick={() => {
                  onChange({
                    target: {
                      name,
                      value: areAllSelected ? [] : options,
                    },
                  });
                  setAllSelected(!areAllSelected);
                }}
              >
                <CheckboxLayout>
                  <CheckboxContainer>
                    {areAllSelected && <FaCheck />}
                  </CheckboxContainer>
                  Select All
                </CheckboxLayout>
              </DropdownOption>
            </SpecializationDropdownContainer>
          )}
          {optionMap &&
            optionMap?.length > 0 &&
            optionMap.map((mapItem, index) => {
              if (mapItem?.items && mapItem?.items.length > 0) {
                return (
                  <>
                    <SpecializationDropdownHeader key={`mapItem-${index}`}>
                      {mapItem.name}
                    </SpecializationDropdownHeader>
                    <SpecializationDropdownContainer>
                      {mapItem?.items.map(option => {
                        const isSelected = value.find(v => v === option);

                        return (
                          <DropdownOption
                            key={option}
                            onClick={() => {
                              if (isSelected) {
                                setAllSelected(false);
                              }
                              onChange({
                                target: {
                                  name,
                                  value: isSelected
                                    ? value.filter(v => v !== option)
                                    : [...value, option],
                                },
                              });
                            }}
                          >
                            <CheckboxLayout>
                              <CheckboxContainer>
                                {isSelected && <FaCheck />}
                              </CheckboxContainer>
                              {render(option)}
                            </CheckboxLayout>
                          </DropdownOption>
                        );
                      })}
                    </SpecializationDropdownContainer>
                  </>
                );
              } else {
                return <></>;
              }
            })}
        </DropdownOptionsContainer>
      )}
      {error && errorMessage ? (
        <p
          style={{
            color: 'red',
            marginTop: '4px',
            fontSize: '14px',
            textAlign: 'left',
          }}
        >
          {errorMessage}
        </p>
      ) : null}
    </DropdownContainer>
  );
};

export default SpecializationDropdown;
