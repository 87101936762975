import Checkbox from 'components/Checkbox';
import React, { useRef, useState, forwardRef } from 'react';
import {
  InputContainer,
  InputLayout,
  LabelLayout,
  LeftLabel,
  RightLabel,
} from './styled';

const Input = forwardRef((props, ref) => {
  const {
    name,
    label,
    className,
    checkboxProps,
    error,
    errorMessage,
    isLink,
    leftLabel,
    rightLabel,
    focusColor,
    style,
    required,
    placeholder,
    styleMsg,
    pattern,
    onKeyPress = () => {},
    endIcon = null,
    handleEndIcon = () => {},
    ...inputProps
  } = props;

  const [isFocused, setIsFocused] = useState(false);

  return (
    <InputContainer className={className} style={style}>
      <LabelLayout>
        <label htmlFor={name}>
          {label} {required && <sup style={{ color: 'red' }}>*</sup>}{' '}
        </label>
        {checkboxProps && <Checkbox {...checkboxProps} />}
      </LabelLayout>

      <InputLayout
      // borderColor={error ? focusColor : isFocused ? focusColor : undefined}
      >
        {leftLabel && (
          <LeftLabel color={isFocused ? focusColor : undefined}>
            {leftLabel}
          </LeftLabel>
        )}
        {isLink ? (
          <a
            href={String(inputProps.value)}
            target="_blank"
            rel="noopener noreferrer"
          >
            {inputProps.value}
          </a>
        ) : (
          <>
            <input
              name={name}
              ref={ref}
              onFocus={event => {
                event.persist();
                setIsFocused(true);
                if (inputProps.onFocus) {
                  inputProps.onFocus(event);
                }
              }}
              maxLength={pattern}
              placeholder={placeholder}
              onBlur={event => {
                event.persist();
                setIsFocused(false);
                if (inputProps.onBlur) {
                  inputProps.onBlur(event);
                }
              }}
              // error={error}
              onKeyPress={event => {
                event.persist();
                onKeyPress(event.key);
              }}
              {...inputProps}
            />
            {endIcon && (
              <span
                onClick={handleEndIcon}
                style={{ padding: '0px 12px', cursor: 'pointer' }}
              >
                {endIcon}
              </span>
            )}
          </>
        )}
        {rightLabel && (
          <RightLabel color={isFocused ? focusColor : undefined}>
            {rightLabel}
          </RightLabel>
        )}
      </InputLayout>

      {error && errorMessage ? (
        <p
          style={{
            color: 'red',
            marginTop: '4px',
            fontSize: '14px',
            textAlign: 'left',
          }}
        >
          {errorMessage}
        </p>
      ) : (
        <p style={styleMsg}>{''}</p>
      )}
    </InputContainer>
  );
});

export default Input;
