import React from 'react';
import {
  AboutContent,
  BulletPointLayout,
  ImageContainer,
  SampleImage,
  TextContainer,
  HABackgroundImage,
} from './styled';

const HomeAbout = () => {
  return (
    <AboutContent>
      <TextContainer>
        <h2>Highly-vetted researchers on-demand</h2>
        <p>
          Big Robin is an online members-only marketplace connecting small
          business and private individuals with best-of-breed researchers,
          consultants and data scientists.
        </p>
        {[
          'Access to ex-bluechip researchers at the fraction of the cost, time and effort.',
          'Strict vetting, quality control and security on the platform',
          'Everything you need from your own insights and analytics department but could not afford...until now.',
        ].map(point => (
          <BulletPointLayout key={point}>
            <img src={require('./assets/check.svg')} alt="" />
            <span>{point}</span>
          </BulletPointLayout>
        ))}
      </TextContainer>
      <ImageContainer>
        <HABackgroundImage
          src={require('./assets/background.png')}
          alt="Big Robin"
        />
        <SampleImage src={require('./assets/sample.png')} alt="" />
      </ImageContainer>
    </AboutContent>
  );
};

export default HomeAbout;
