import React, { useState, useEffect, Fragment } from 'react';
import {
  PageContent,
  BreadcrumbEnd,
  BreadcrumbLayout,
  BreadcrumbLink,
  BreadcrumbMiddle,
  ModalContent,
  ModalMsg,
  ModalButton,
} from './styled';
import { useParams, useHistory } from 'react-router-dom';
// import LoadingIndicator from 'components/LoadingIndicator';
import { ajax } from 'utils/ajax';
import { FaChevronRight } from 'react-icons/fa';
import { Payment } from 'pages/StripePayment';
import { PaymentBreakdown } from 'pages/PaymentBreakdown';
import Modal from 'components/Modal';
import Button from 'components/Button';
import SessionModal from 'components/SessionModal';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import LoadingIndicator from 'components/LoadingIndicator';

export const ProjectPayment = () => {
  const params = useParams();
  const history = useHistory();
  const selectedChat = history.location?.state?.selectedChat
    ? history.location?.state?.selectedChat
    : null;
  const bit_amount = Number(localStorage.getItem('amount'));
  const [project, setProject] = useState({});
  const [paymentID, setPaymentId] = useState(null);
  const [researcher, setResearcher] = useState({});
  const [mobilisation_percent, setMobilisationPercent] = useState(0);
  const [total_cost, setTotalCost] = useState(0);
  const [mobilisation_fee, setMobilisationFee] = useState(0);
  const [remaining_balance, setRemainingBalance] = useState(0);
  const [clientsecret, setClientsecret] = useState(null);
  const [loading, setLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [isModalOpen, setModalOpen] = useState(false);
  const [isModalWarnOpen, setModalWarnOpen] = useState(false);
  const [modalMsg, setModalMsg] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [isSuccess, setSuccess] = useState(false);
  const [stripePromise, setStripePromise] = useState();
  const [isBackButtonClicked, setBackbuttonPress] = useState(false);
  const [paymentStatusData, setPaymentStatusData] = useState({});
  const [breadcrumbs, setBreadcrumbs] = useState([
    { label: 'MESSAGES', to: '/messages' },
    {
      label:
        params.type === 'accept'
          ? 'REMAINING BALANCE BREAKDOWN'
          : 'MOBILISATION FEE BREAKDOWN',
    },
  ]);
  const options = {
    clientSecret: clientsecret,
    locale: 'en-US',
    appearance: {
      theme: 'stripe',
    },
  };

  useEffect(() => {
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener('popstate', onBackButtonEvent);

    //logic for showing popup warning on page refresh
    // window.onbeforeunload = function() {
    //   return 'Data will be lost if you leave the page, are you sure?';
    // };
    return () => {
      window.removeEventListener('popstate', onBackButtonEvent);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const onBackButtonEvent = e => {
    e.preventDefault();
    if (!isBackButtonClicked) {
      setModalMsg(
        'Please do not reload or press ‘back’ button during the process'
      );
      setModalWarnOpen(true);
    }
  };

  useEffect(() => {
    if (
      !bit_amount ||
      bit_amount === '' ||
      !params.type ||
      !params.project_id ||
      !params.researcher_id ||
      !params.message_id
    ) {
      history.push('/messages');
    }
  }, []);

  useEffect(() => {
    if (researcher && researcher.stripe_account_id)
      setStripePromise(
        loadStripe(process.env.REACT_APP_RBN_STRIPE_API_KEY_US, {
          stripeAccount: researcher.stripe_account_id,
        })
      );
  }, [researcher]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const url = '/project/' + params.project_id;
      try {
        const res = await ajax('GET', url);
        if (res.code === 200 && res.data && res.data.project) {
          setTimeout(() => {
            setLoading(false);
          }, 1000);

          const project_res = res.data.project;
          setProject(project_res);
          const user_id = parseInt(localStorage.getItem('br-userId'));
          const subs_url = '/member/' + user_id + '/subscription/active';
          const subs_res = await ajax('GET', subs_url);
          if (subs_res.code === 200 && subs_res.data) {
            const member_subs = subs_res.data;
            const percentage = member_subs['downpayment_percentage'];
            setTotalCost(bit_amount);
            // setTotalCost(project_res['actual_price']);
            setMobilisationPercent(percentage);
            const mobilisation_percent = percentage / 100;
            // const total_mobilisation_fee =
            //   project_res['actual_price'] * (mobilisation_percent + 3 / 100);
            const total_mobilisation_fee = bit_amount * mobilisation_percent;
            setMobilisationFee(parseFloat(total_mobilisation_fee).toFixed(2));
            // const remaining =
            //   project_res['actual_price'] -
            //   total_mobilisation_fee +
            //   (project_res['actual_price'] - total_mobilisation_fee) *
            //     (3 / 100);
            const remaining = bit_amount - total_mobilisation_fee;
            setRemainingBalance(parseFloat(remaining).toFixed(2));
            const res_url = '/member/' + params.researcher_id;
            const res_res = await ajax('GET', res_url);
            if (res_res.code === 200 && res_res.data.member) {
              setResearcher(res_res.data.member);
              setTimeout(() => {
                setLoading(false);
              }, 1000);
            } else if (res_res.code === 401) {
              setTimeout(() => {
                setLoading(false);
              }, 1000);
              setIsOpen(true);
            } else {
              setTimeout(() => {
                setLoading(false);
              }, 1000);
            }
          } else if (subs_res.code === 401) {
            setTimeout(() => {
              setLoading(false);
            }, 1000);
            setIsOpen(true);
          } else {
            setTimeout(() => {
              setLoading(false);
            }, 1000);
            // setRemainingBalance(project_res.actual_price);
            setRemainingBalance(bit_amount);
          }
        } else if (res.code === 401) {
          setTimeout(() => {
            setLoading(false);
          }, 1000);
          setIsOpen(true);
        } else {
          setTimeout(() => {
            setLoading(false);
          }, 1000);
        }
      } catch (err) {
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      }
    })();
  }, [params.project_id, params.researcher_id]);

  useEffect(() => {
    if (currentStep === 2) {
      setBreadcrumbs([
        { label: 'MESSAGES', to: '/messages' },
        {
          label:
            params.type === 'accept'
              ? 'REMAINING BALANCE BREAKDOWN'
              : 'MOBILISATION FEE BREAKDOWN',
          click: () => {
            setCurrentStep(1);
          },
        },
        {
          label:
            params.type === 'accept' ? 'PAY REMAINING BALANCE' : 'PAY DEPOSIT',
        },
      ]);
    } else if (currentStep === 1) {
      setBreadcrumbs([
        { label: 'MESSAGES', to: '/messages' },
        {
          label:
            params.type === 'accept'
              ? 'REMAINING BALANCE BREAKDOWN'
              : 'MOBILISATION FEE BREAKDOWN',
        },
      ]);
    }
  }, [currentStep, params.type]);

  const continueToMessages = () => {
    localStorage.setItem('amount', null);
    setBackbuttonPress(true);
    history.push({
      pathname: '/messages',
      state: { selectedChat: selectedChat },
    });
  };

  const doDirectCharge = async number => {
    const user_id = parseInt(localStorage.getItem('br-userId'));
    if (user_id) {
      const resUser = await ajax('GET', `/member/${user_id}`);
      if (resUser.code === 200) {
        if (researcher.stripe_account_id !== '') {
          const obj = {
            amount:
              params.type === 'accept'
                ? Number(total_cost) - Number(mobilisation_fee) //(16 - 4) * 100
                : Number(mobilisation_fee), // 4 * 100
            description:
              params.type === 'accept'
                ? 'Remaining Balance'
                : 'Mobilisation Fee',
            location: 'string',
            payee_id: Number(params.researcher_id),
            message_id: Number(params.message_id),
            payer_id: user_id,
            status: params.type === 'accept' ? 'full_payment' : 'downpayment',
            type: 'project',
            project_id: Number(params.project_id),
            action: params.type,
            email: resUser?.data?.member?.email,
          };
          const res = await ajax('POST', '/payment/direct_charge', obj);
          if (res.code === 200) {
            setClientsecret(res.data.client_secret);
            setCurrentStep(number);
            setPaymentId(res?.data?.metadata?.payment_id);
          } else {
            setModalMsg(res.message);
            setModalOpen(true);
          }
          return await res;
        } else {
          setModalMsg('Researcher is not connected to Stripe');
          setModalOpen(true);
        }
      }
    }
  };

  return (
    <PageContent>
      {loading ? <LoadingIndicator /> : null}
      <BreadcrumbLayout>
        {breadcrumbs.map((item, index) => (
          <Fragment key={item.label}>
            {index === breadcrumbs.length - 1 ? (
              <BreadcrumbEnd>{item.label}</BreadcrumbEnd>
            ) : item.to ? (
              <BreadcrumbLink to={'#'}>{item.label}</BreadcrumbLink>
            ) : (
              <BreadcrumbMiddle
                // onClick={item.click}
                onClick={() => {}}
              >
                {item.label}
              </BreadcrumbMiddle>
            )}
            {index !== breadcrumbs.length - 1 && <FaChevronRight />}
          </Fragment>
        ))}
      </BreadcrumbLayout>
      {currentStep === 1 && (
        <PaymentBreakdown
          project={project}
          researcher={researcher}
          type={params.type}
          mobilisation_percent={mobilisation_percent}
          mobilisation_fee={mobilisation_fee}
          remaining_balance={remaining_balance}
          moveToNextPage={doDirectCharge}
          bit_amount={bit_amount}
          moveToPreviousPage={() => {
            setModalMsg(
              'Please do not reload or press Cancel button during the process'
            );
            setModalWarnOpen(true);
          }}
        />
      )}
      {currentStep === 2 && (
        <Elements stripe={stripePromise} options={options}>
          <Payment
            page_title={
              params.type === 'accept' ? 'PAY REMAINING BALANCE' : 'PAY DEPOSIT'
            }
            payment_type={params.type}
            member_id={localStorage.getItem('br-userId')}
            researcher_id={params.researcher_id}
            page_description={
              params.type === 'accept'
                ? 'To complete your project, please pay $' +
                  remaining_balance +
                  '.'
                : 'To mobilise ' +
                  researcher.first_name +
                  ' and your project, please pay $' +
                  mobilisation_fee +
                  '. This is ' +
                  mobilisation_percent +
                  '% of your project.'
            }
            rightbutton_label={
              params.type === 'accept' ? 'PAY REMAINING BALANCE' : 'PAY DEPOSIT'
            }
            onCancel={() => {
              // setCurrentStep(1)
              setModalMsg(
                'Please do not reload or press Cancel button during the process'
              );
              setModalWarnOpen(true);
            }}
            clientsecret={clientsecret}
            setPaymentStatusData={setPaymentStatusData}
            setCurrentStep={setCurrentStep}
            paymentID={paymentID}
          />
        </Elements>
      )}

      {currentStep === 3 && (
        <div style={{ marginTop: '2.15rem' }}>
          <div
            style={{
              fontSize: '2.2rem',
              color: '#4b4c5e',
            }}
          >
            Payment Failed!
          </div>
          <div
            style={{
              marginTop: '1.5rem',
              padding: '4rem',
              backgroundColor: 'white',
            }}
          >
            <div
              style={{
                marginBottom: '4rem',
              }}
            >
              Reason: {paymentStatusData?.message}
            </div>

            <Button
              style={{ width: '250px' }}
              onClick={() => {
                setCurrentStep(1);
              }}
            >
              RETRY
            </Button>
          </div>
        </div>
      )}
      <Modal visible={!!isModalOpen} setVisible={setModalOpen}>
        <ModalContent>
          <ModalMsg>{modalMsg}</ModalMsg>
          <ModalButton>
            {isSuccess ? (
              <Button fill onClick={() => continueToMessages()}>
                Proceed to Messages
              </Button>
            ) : (
              <Button fill onClick={() => setModalOpen(false)}>
                Ok
              </Button>
            )}
          </ModalButton>
        </ModalContent>
      </Modal>
      <Modal visible={!!isModalWarnOpen} setVisible={setModalWarnOpen}>
        <ModalContent>
          <ModalMsg>{modalMsg}</ModalMsg>
          <div style={{ display: 'flex' }}>
            <Button
              style={{ padding: '5px', margin: '10px' }}
              fill
              onClick={() => continueToMessages()}
            >
              Proceed to Messages
            </Button>
            <Button
              style={{ padding: '5px', margin: '10px' }}
              simple
              onClick={() => {
                setModalWarnOpen(false);
                window.history.pushState(null, null, window.location.pathname);
                setBackbuttonPress(false);
              }}
            >
              Ok
            </Button>
          </div>
        </ModalContent>
      </Modal>
      <SessionModal
        isOpen={isOpen}
        setModalVisible={setIsOpen}
        message={'Your session has expired.'}
      />
    </PageContent>
  );
};
