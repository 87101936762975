function chunkify(array, chunkSize = 1) {
  return Array.from(
    {
      length: Math.ceil(array.length / chunkSize),
    },
    (_, i) => {
      const start = chunkSize * i;
      return array.slice(start, start + chunkSize);
    }
  );
}

export default chunkify;
