import React from 'react';
import {
  CardInfo,
  CardInfoName,
  CardInfoTitle,
  CardInfoView,
  CardLayout,
} from './styled';

const ResearcherCard = ({
  researcher_id,
  researcher_full_name,
  avatar_link,
  researcher_address,
  className,
}) => {
  return (
    <CardLayout className={className} data-freelancer={researcher_id}>
      <img
        src={avatar_link || require('../UserMenu/default.webp')}
        alt={researcher_full_name}
      />

      <CardInfo>
        <div>
          <CardInfoName>{researcher_full_name}</CardInfoName>
          <CardInfoTitle>{researcher_address}</CardInfoTitle>
          {/* <CardInfoDescription>{address}</CardInfoDescription> */}
        </div>

        <CardInfoView to={`/researcher/${researcher_id}`}>
          View Profile
        </CardInfoView>
      </CardInfo>
    </CardLayout>
  );
};

export default ResearcherCard;
