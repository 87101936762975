import styled from 'styled-components/macro';

export const TextareaContainer = styled.div`
  position: relative;
  overflow: hidden;
  border: 1px solid ${props => (props.error ? '#ef5350' : '#65637b3c')};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  color: #4b4c5e;

  textarea {
    color: #4b4c5e;
    padding: 1rem;
    font-size: 1.6rem;
    border: 0;
    resize: none;
    outline: none;
    width: calc(100% - 2rem);
    height: 40px;

    &::placeholder {
      font-style: normal;
      color: #4b4c5e8a;
    }

    @media (max-width: 768px) {
      font-size: 2.4rem;
    }

    @media (max-width: 600px) {
      height: 38px;
    }
  }
`;

export const TextareaCharacterCount = styled.span`
  font-size: 1.2rem;
  text-align: right;
  padding: 1rem 1rem 0.2rem 1rem;

  @media (max-width: 768px) {
    font-size: 1.8rem;
  }
`;
