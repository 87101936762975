import { MEMBER_TYPES, useAuthState } from 'context/AuthContext';
import React, { useEffect, useState } from 'react';
import MemberRegistration from './MemberRegistration';
import { ResearcherProfileForm } from './ResearcherProfileForm';
import { ajax } from 'utils/ajax';
const RegistrationRoute = () => {
  const { user } = useAuthState();
  const [countryList, setCountryList] = useState([]);
  const [fullCountryList, setFullCountryList] = useState([]);
  const [countryObjList, setCountryObjList] = useState([]);
  useEffect(() => {
    const getCountryList = async () => {
      const subs_res = await ajax('GET', '/country');
      if (subs_res?.code === 200 && subs_res?.data) {
        const list =
          subs_res?.data?.length > 0
            ? subs_res?.data.map(
                item => item?.nicename + ' (' + item?.iso + ')'
              )
            : [];
        const listObj =
          subs_res?.data?.length > 0
            ? subs_res?.data.map(item => {
                return {
                  id: item?.id,
                  name: item?.nicename,
                };
              })
            : [];
        setCountryList(list);
        setCountryObjList(listObj);
        setFullCountryList(subs_res?.data);
      }
    };
    getCountryList();
  }, []);

  if (user.member.member_type_id === +MEMBER_TYPES.MEMBER) {
    return <MemberRegistration />;
  }

  if (user.member.member_type_id === +MEMBER_TYPES.RESEARCHER) {
    return (
      <ResearcherProfileForm
        countryList={countryList}
        fullCountryList={fullCountryList}
        countryObjList={countryObjList}
      />
    );
  }

  return null;
};

export default RegistrationRoute;
