import Content from 'components/Content';
import styled from 'styled-components/macro';
import { Row } from 'components/Layout';
import Button from 'components/Button';
import { InputContainer } from 'components/Input/styled';

export const PageContent = styled(Content)`
  margin: 4rem 0;
`;

export const PageTitle = styled.h1`
  font-size: 2.2rem;
  color: #4b4c5e;
  margin-top: 2.15rem;

  @media (max-width: 768px) {
    font-size: 2.5rem;
    margin-top: 2.5rem;
  }
`;

export const ElementWrapper = styled.div`
  margin-top: 20px;
  max-width: 50%;
  @media (max-width: 768px) {
    max-width: 100%;
  }
`;

export const PageContainer = styled.div`
  margin-top: 1.5rem;

  padding: 4rem;
  background-color: white;

  @media (max-width: 768px) {
    margin-top: 2rem;
  }
`;

export const PageLabel = styled.div`
  letter-spacing: 0px;
  color: #65637b;
  width: 100%;
  display: flex;

  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

export const PageButtonLayout = styled(Row)`
  margin: 4rem 0;
  width: 50%;

  @media (max-width: 768px) {
    margin: 6rem 0 4rem;
    width: 100%;
  }

  ${Button} {
    margin-right: 3rem;
    flex: 1;

    @media (max-width: 768px) {
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
      font-size: 1.75rem;
      margin-right: 1.5rem;
    }

    &:last-child {
      margin-right: 0;
    }
  }
`;

export const IconLayout = styled.div`
  display: flex;
  margin-top: 4rem;
  margin-bottom: 2rem;
`;

export const IconItem = styled.div`
  &:not(:last-child) {
    margin-right: 1rem;
  }
`;

export const FormRow = styled.div`
  min-width: 0;
  display: flex;
  margin-bottom: 3rem;
  flex: 1;
  width: 50%;

  @media (max-width: 768px) {
    width: 100%;
  }

  ${InputContainer} {
    min-width: 0;
    flex: 1;
  }
`;
